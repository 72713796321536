import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


const styles = theme => ({
    form: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    header: {
        height: '15%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    headGridTwoButton: {
        float: 'right',
    },
    content: {
        height: '70%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '10px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

class ChangePassword extends React.Component {
    
    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    }

    render() {
        const { classes } = this.props;
        return (
            <form className={classes.form} onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <Grid container className={classes.header} spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="h5" gutterBottom>
                            Change Password
                        </Typography>
                    </Grid>
                    <Grid item xs={6} >
                        <CloseIcon className={classes.headGridTwoButton} onClick={() => { this.props.onClose() }}/>
                    </Grid>
                </Grid>
                <Container className={classes.content}>
                    <div>
                        <Field name="oldPassword" component={this.renderTextField} label="Old Password" type="password" fullWidth variant="outlined" margin="normal" />
                    </div>
                    <div>
                        <Field name="password" component={this.renderTextField} label="New Password" type="password" fullWidth variant="outlined" margin="normal" />
                    </div>
                    <div>
                        <Field name="repeatPassword" component={this.renderTextField} label="Confirm Password" type="password" fullWidth variant="outlined" margin="normal" />
                    </div>
                </Container>
                <Container className={classes.footer}>
                    <div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"                           
                            margin="normal"
                            color="primary">
                            Confirm
                            </Button>
                    </div>
                </Container>
            </form>
        );
    }
}

const validate = values => {
    const errors = {}

    const requiredFields = [
        'oldPassword',
        'password',
        'repeatPassword',
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}


export default reduxForm({
    form: 'ChangePassword',
    validate
})(withStyles(styles)(ChangePassword))
