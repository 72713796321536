import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { changeHeaderName } from '../../actions/dashboardActions';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { requestCampaign, getCampaignDetailsById, getCampaignBusinessExportToExcel } from '../../actions/campaignActions';


const styles = theme => ({
	container: {
		width: '100%',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(4),
	},
	buttonContainer: {
		width: '100%',
		paddingTop: '10px'
	},
	content: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
	},
	appBarSpacer: theme.mixins.toolbar,
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	formControl: {
		minWidth: 225,
		maxWidth: 300,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	heading: {
		fontSize: "14px",
		fontWeight: 500
	},
	snackClose: {
		padding: theme.spacing(0.5),
	},
    containerin: {
        width: '90%',
        height: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(5),
        paddingRight: theme.spacing(0),
      },
      modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "150px",
        width: "100%",
      },
      tableRow: {
        cursor: 'pointer'
    },
});

let totalCount;

// const names = [
// 	{ label: "Issued", value: "Issued" },
// 	{ label: "Consumed", value: "Consumed" },
// 	{ label: "Settled", value: "PayBackSeller" },
// 	{ label: "Invalid", value: "Invalid" },
// 	{ label: "Expired", value: "Expired" },
// ];

// const rows = [
//     { id:1, campaignID: '#12456366', businessID:'#49859595',businessName:'ABC Sports',noOfCouponsHonoured:100,totalAmtOfCouponsHonoured:40000,settledCoupons:40,settledCouponsAmt:16000,notSettledCoupons:60,notSettledCouponsAmt:24000},
// ];

let dataBusiness = [];


class campaignReport extends Component {
    constructor() {
		super();
		this.state = JSON.parse(window.localStorage.getItem('campaignBusinessConsumerDetails')) || {
			modalOpen: false,
			snackOpen: false,
			sucessMsg: null,
			errorMsg: null,
			regionSearchvalue: "",
			clearSearch: false,
			order: -1,
			orderBy: 'name',
			page: 0,
			rowsPerPage: 10,
			editMode: false,
			regionToEdit: null,
            campaignId: "",
            campaignName: "",
            businessId: "",
            campaignTimeDateRangeEnd: "",
            campaignTimeDateRangeStart: "",
            campaignStatus: "",
            showDownloadIcon: false,
            headers: [
                // { key: 'campaignId', label: 'Campaign ID' },
                // { key: 'campaignState', label: 'campaignState' },
                // { key: 'campaignName', label: 'campaignName' },
                { key: 'businessId', label: 'Business ID' },
                { key: 'businessName', label: 'Business Name' },
                { key: 'honoredCount', label: 'No Of Coupons Honoured' },
                { key: 'honouredAmount', label: 'Total Amt Of Coupons Honoured' },
                { key: 'settledCnt', label: 'Settled Coupons Count'},
                { key: 'settledAmount', label: 'Settled Coupons Amt'},
                { key: 'notSettledCnt', label: 'Not Settled Coupons'},
                { key: 'notSettledAmt', label: 'No tSettled Coupons Amt'},
                { key: 'campId', label: 'Campaign ID'},
                { key: 'campaignName', label: 'Campaign Name' },
                // { key: 'campaignPromo', label: 'Promo Codes'}
            ],
            businessName: "",
		};
	}

    componentDidMount() {
        this.props.changeHeaderName(`Campaign Report - ${this.props.history.campaignCode === "offerCode" ? "Offer Code" : "Promo Code"} Campaign`);
        // this.props.getCampaignDetailsById(this.props.match.params.campaignId)
        this.fetchCampaignBusinessWithFilter();

        window.addEventListener("beforeunload", () => localStorage.removeItem('campaignBusinessConsumerDetails'));
    }
    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    handleModalOpen = () => {
        this.setState({ modalOpen: true });
    };
    handleModalClose = () => {
        this.setState({ modalOpen: false });
    };
    fetchExportToExcel = async () => {
        this.props.requestCampaign();
        let payload = {
            // campaignId: this.state.campaignId,
            // campaignName: this.state.campaignName,
            businessId: this.state.businessId,
            businessName: this.state.businessName,
            // campaignObjId: this.props.campaign.campaignDetails.campaignListData[0]._id,
            // campaignObjId: this.props.campaign.campaignDetails.finalData[0].campaignId,//campData[0].campaignId
            campaignObjId: this.props.campaign.campaignDetails.campData[0]._id,
        }
        await this.props.getCampaignBusinessExportToExcel(payload);
        this.setState({showDownloadIcon:true});
    };
    redirectToCampaignBusinessReport = (obj) => {
        this.props.history.push(`/campaigns/${this.props.campaign.campaignDetails.finalData[0].campaignId}/${obj._id}`); //`/campaigns/${campaignObj._id}/${business._id
    };

    resetFilter = async () => {
        this.setState({
            businessId: "",
            businessName: "",
        }, () => {
            this.fetchCampaignBusinessWithFilter();
        });
        // this.props.getCampaignDetailsById(this.props.match.params.campaignId);
        // this.fetchCampaignBusinessWithFilter();
        // this.setState({showDownloadIcon:false});

    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };
    setPage = (page) => {
        this.setState({ page }, () => this.fetchCampaignBusinessWithFilter());
    };

    handleChangeRowsPerPage = (event) => {
        this.setRowsPerPage(parseInt(event.target.value, 10));
        this.setPage(0);
    };

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    }

    fetchCampaignBusinessWithFilter = async () => {
        this.props.requestCampaign();
        let payload = {
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            businessId: this.state.businessId,
            businessName: this.state.businessName,
        }
        await this.props.getCampaignDetailsById(this.props.match.params.campaignId, payload);
        this.setState({showDownloadIcon: false });

        localStorage.setItem("campaignBusinessConsumerDetails", JSON.stringify(this.state));
    };

    render() {
        const { classes } = this.props;
        // console.log("in level 2--->",this.props);
        // let dataBusiness;
        let name;
        if(this.props.campaign.campaignDetails && this.props.campaign.campaignDetails.finalData){
            dataBusiness = this.props.campaign.campaignDetails.finalData;
            // console.log(dataBusiness)
            name = (this.props.campaign.campaignDetails && this.props.campaign.campaignDetails.campData.length>0 && this.props.campaign.campaignDetails.campData[0].campaignId) ? this.props.campaign.campaignDetails.campData[0].campaignId : "";
        }
        // if(this.props.campaign.campaignDetails.businessCount) {
            totalCount = this.props.campaign.campaignDetails.businessCount;
        // }

        // let name = this.props.campaign.campaignDetails.campaignListData && this.props.campaign.campaignDetails.campaignListData[0].campaignName ? this.props.campaign.campaignDetails.campaignListData[0].campaignId : "";

        // name = "campaign";
        // name = this.props.campaign.campaignDetails && this.props.campaign.campaignDetails.finalData[0].campaignName? this.props.campaign.campaignDetails.finalData[0].campaignName : "";
        if (JSON.stringify(this.props.campaign.campaignDetails)!==JSON.stringify({})){
        return (
        <div className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container}>
                    <Container style={{ marginTop: '24px', marginBottom: '8px' }}>
                        <Typography style={{ fontSize:'20px', fontWeight: 'bold' }}gutterBottom> {this.props.campaign.campaignDetails && this.props.campaign.campaignDetails.campData.length>0 && this.props.campaign.campaignDetails.campData[0].campaignName? this.props.campaign.campaignDetails.campData[0].campaignName : ""} ({this.props.campaign.campaignDetails &&this.props.campaign.campaignDetails.campData.length>0 && this.props.campaign.campaignDetails.campData[0].campaignId? this.props.campaign.campaignDetails.campData[0].campaignId : ""}) </Typography>
                    </Container>
                    <Container style={{ marginTop: '24px', marginBottom: '8px' }}>
                        <Typography style={{ fontSize:'20px', fontWeight: 'bold' }}gutterBottom></Typography>
                          
                    </Container>
                    <Container className={classes.searchPaper}>
                        <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography style={{ fontSize:'17px'}}>Search</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                                    <Grid container spacing={1}>
                                    <Grid item xs={3} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Business ID
                                        </Typography>
                                        <TextField fullWidth margin="dense" name="businessId" value={this.state.businessId} variant="outlined" onChange={this.handleFilterChange} />
                                    </Grid>
                                    <Grid item xs={3} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Business Name
                                    </Typography>
                                        <TextField fullWidth margin="dense" name="businessName" value={this.state.businessName} variant="outlined" onChange={this.handleFilterChange} />
                                    </Grid>
                                    
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={2}>
                                        <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                        >
                                        Reset
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        onClick={this.fetchCampaignBusinessWithFilter}
                                        >
                                        Search
                                        </Button>
                                    </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Container>

                    <Container className={classes.container}>
                        <Paper className={classes.paper}>
                            <Typography style={{marginTop:'10px', marginBottom:'10px'}} variant="subtitle2" id="tableTitle" component="div">
                                Total Business Count: {this.props.campaign.campaignDetails && this.props.campaign.campaignDetails.businessCount  ? this.props.campaign.campaignDetails.businessCount : "0"}  &nbsp; &nbsp; &nbsp; Total Coupons Honoured : {this.props.campaign.campaignDetails && this.props.campaign.campaignDetails.honoredCount  ? this.props.campaign.campaignDetails.honoredCount : "0"}  &nbsp; &nbsp; &nbsp; Total Revenue Generated: {'\u20B9'} {this.props.campaign.campaignDetails && this.props.campaign.campaignDetails.totalRevenue  ? this.props.campaign.campaignDetails.totalRevenue.toFixed(2) : "0"} &nbsp; &nbsp; &nbsp; New Customers: {this.props.campaign.campaignDetails && this.props.campaign.campaignDetails.newConsumers  ? this.props.campaign.campaignDetails.newConsumers : "0"} 

                                <CSVLink
                                    style={{ "marginRight": "28px", "float": "right" }}
                                    headers={this.state.headers}
                                    data={dataBusiness}
                                    filename={name+".csv"}
                                    className="btn btn"
                                    arget="_blank"
                                    onClick={this.fetchExportToExcel}
                                >
                                    <Tooltip title="Export to Excel">
                                        <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                                    </Tooltip>
                                </CSVLink>
                            </Typography>
                                    
                                    {/* <EnhancedTableToolbar totalcount={props.rowData.totalcount} /> */}
                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size="small"
                                    aria-label="enhanced table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            {/* <TableCell>Campaign ID</TableCell> */}
                                            <TableCell>Business ID</TableCell>
                                            <TableCell>Business Name</TableCell>
                                            <TableCell>No. of Coupons Honoured</TableCell>
                                            <TableCell>Total Amt of Honoured Coupons ({'\u20B9'})</TableCell>
                                            <TableCell>Settled Coupons Count</TableCell>
                                            <TableCell>Settled Coupons Amt ({'\u20B9'})</TableCell>
                                            <TableCell>Not Settled Coupons Count</TableCell>
                                            <TableCell>Not Settled Coupons Amt ({'\u20B9'})</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        { 
                                            (this.props.campaign.campaignDetails && this.props.campaign.campaignDetails.finalData) ?
                                                this.props.campaign.campaignDetails.finalData
                                                .map((row, index) => {
                                                    return ( 
                                                    <TableRow
                                                        hover
                                                        className={classes.tableRow}
                                                        key={index}
                                                    >
                                                        {/* <TableCell align="left" className={classes.tableCellSmall} scope="row">{this.props.campaign.campaignDetails.campaignId}</TableCell> */}
                                                        <TableCell align="left" className={classes.tableCellSmall} scope="row" onClick={(e) => this.redirectToCampaignBusinessReport(row,e)}>{row.businessId}</TableCell>
                                                        <TableCell align="left" className={classes.tableCellSmall} scope="row" onClick={(e) => this.redirectToCampaignBusinessReport(row,e)}>{row.businessName}</TableCell>
                                                        <TableCell align="left" className={classes.tableCellSmall} scope="row" onClick={(e) => this.redirectToCampaignBusinessReport(row,e)}>
                                                            {row.honoredCount}
                                                            </TableCell>
                                                        <TableCell align="left" className={classes.tableCellSmall} scope="row" onClick={(e) => this.redirectToCampaignBusinessReport(row,e)}>
                                                            {row.honouredAmount}
                                                            </TableCell>
                                                        <TableCell align="left" className={classes.tableCellSmall} scope="row" onClick={(e) => this.redirectToCampaignBusinessReport(row,e)}>
                                                            {row.settledCnt}
                                                            </TableCell>
                                                        <TableCell align="left" className={classes.tableCellSmall} scope="row" onClick={(e) => this.redirectToCampaignBusinessReport(row,e)}>
                                                            {row.settledAmount}
                                                            </TableCell>
                                                        <TableCell align="left" className={classes.tableCellSmall} scope="row" onClick={(e) => this.redirectToCampaignBusinessReport(row,e)}>
                                                            {row.notSettledCnt}
                                                            </TableCell>
                                                        <TableCell align="left" className={classes.tableCellSmall} scope="row" onClick={(e) => this.redirectToCampaignBusinessReport(row,e)}>
                                                            {row.notSettledAmt}
                                                            </TableCell>
                                                    </TableRow>
                                                            );
                                                        })
                                                : null 
                                        } 
                                                {/* {emptyRows > 0 && (
                                                    <TableRow style={{ height: 33 * emptyRows }}>
                                                        <TableCell colSpan={6} />
                                                    </TableRow>
                                                )}*/} 
                                    </TableBody> 
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </Paper>

                    </Container>
                </Container>
                <Backdrop className={classes.backdrop} open={this.props.campaign.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
        </div>
        ) } else {
           return null
        }
    }
}
const mapStateToProps = state => {
	return {
		coupon: state.coupon,
        campaign: state.campaign,
        business: state.business,
        serviceRequest: state.serviceRequest,
        user: state.auth.user,
        categories: state.categories.categoriesByType
	}
};
export default connect(mapStateToProps, { changeHeaderName, requestCampaign, getCampaignDetailsById, getCampaignBusinessExportToExcel })(withStyles(styles)(campaignReport));