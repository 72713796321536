import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as LOCATIONICON } from '../../images/location of address.svg';
import { ReactComponent as BUSINESSIDSVG } from '../../images/businessIDSvg.svg';
import { ReactComponent as CATEGORYSVG } from '../../images/CategorySvg.svg';
import { ReactComponent as DISTANCESVG } from '../../images/DistanceSvg.svg';
import TextField from "@material-ui/core/TextField";
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import { Container } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { ReactComponent as ADD } from '../../images/AddBusiness.svg';
import { ReactComponent as DELETE } from '../../images/DeleteBusiness.svg';
import Avatar from "@material-ui/core/Avatar";
import { addDeleteBusiness, getOneConsumer } from "../../actions/dashboardActions";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
const _  = require("lodash")


const styles = (theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
        marginTop: "100px",
        width: "100%",
        height: "100%",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        flexDirection: "column",
        width: "25%",
        height: "full",
        position: "fixed",
        top: "5px",
        right: "5px",
        marginLeft: "7px"
    },
    searchbar: {
        height: "30px"
    },

    modalForBusiness: {
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",       
        // flexDirection: "column",              

        height: "300px",
        width: "1000px"
    },
});

function OpenBusinessAsPerAddress(props) {
    const { classes } = props;
    const [isDefaultAddress, setIsDefaultAddress] = useState(false);
    // const [selectedAddress, setSelectedAddress] = useState(false);
    const [displayList, setDisplayBusinessList] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(props.selectedAddressArray[props.selectedAddressIndex])
    const [search, setSearchText] = useState("");
    const [searchBusiness, setBusinessText] = useState("");
    const [searchOfBusinessName, setSearchOfBusinessName] = useState("");
    const [addBusinessModalOpen, setAddBusinessModalOpen] = useState(false)
    const [confirmationOpen, setConfirmationOpen] = useState(false)
    const [businessList, setBusinessList] = useState([]);
    const [businessToDeleteAdd, setBusinessToDeleteAdd] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [isSnackOpen, setIsSnackOpen] = useState(false);    
    const [muiAlert, setMuiAlert] = useState(null);    


    // const dispatch = useDispatch();

    useEffect(() => {
        // getOneConsumer();
        setIsDefaultAddress(isDefaultAddress);
        // setAddBusinessModalOpen(addBusinessModalOpen);
        // setConfirmationOpen(confirmationOpen);
        setBusinessList(businessList);

        // selectedAddress(selectedAddress);  
        
        let tempDisplayList =  selectedAddress.visibleBusinesses.sort((a, b) => {
            return parseFloat(a.distance) - parseFloat(b.distance);
          });       
       

        if (search) {
            tempDisplayList = selectedAddress.visibleBusinesses.map((ele) => {
                if (
                    ele.businessId.toUpperCase().includes(search.toUpperCase()) ||
                    ele.businessName.toUpperCase().includes(search.toUpperCase())
                ) {
                    ele.shouldNotDisplay = false;
                } else ele.shouldNotDisplay = true;
                return ele;
            });
        }
        setDisplayBusinessList(tempDisplayList);

        if (addBusinessModalOpen) {
            setAddBusinessModalOpen(true)
        }
        if (confirmationOpen) {
            setConfirmationOpen(true)
        }
        let getAllBusinessIds = [];

        selectedAddress.visibleBusinesses.map((bus) => {

            getAllBusinessIds.push(bus.businessObjId)
        })
        props.businessList.map((b) => {

            if (getAllBusinessIds.includes(b._id)) {
                b["isBusinessExists"] = true
            }
            else b["isBusinessExists"] = false;
        })



    }, []);

    const onSearchTextChange = (value) => {
        setSearchText(value);

        let tempDisplayList = selectedAddress.visibleBusinesses;

        if (value) {
            let tempDisplayListOnSearch = [];
            selectedAddress.visibleBusinesses.map((ele) => {
                if (
                    ele.businessId.toUpperCase().includes(value.toUpperCase()) ||
                    ele.businessName.toUpperCase().includes(value.toUpperCase())
                ) {
                    ele.shouldNotDisplay = false;
                    tempDisplayListOnSearch.push(ele)
                } else ele.shouldNotDisplay = true;

            });

            setDisplayBusinessList(tempDisplayListOnSearch);
        }
        else
            setDisplayBusinessList(tempDisplayList)
    }
    const addBusinessOnClick = (e) => {
        setAddBusinessModalOpen(true);
        setBusinessText("");
        setBusinessList([]);
        setSearchOfBusinessName("");
    }
    const onClickBusinessList = () => {
        //setBusinessText(text);
        let tempBusinessList = [];

        // console.log("tempBusinessList text ---->", text)

        if (searchBusiness && searchBusiness !== "") {

            props.businessList.map((ele) => {

                setSearchOfBusinessName(searchBusiness);
                if (
                    ele.businessId.toUpperCase().includes(searchBusiness.toUpperCase()) ||
                    ele.businessName.toUpperCase().includes(searchBusiness.toUpperCase())
                ) {
                    ele.shouldNotDisplay = false;


                    tempBusinessList.push(ele);
                } else ele.shouldNotDisplay = true;

            });

            setBusinessList(tempBusinessList);
            // console.log("tempBusinessList in onClickBusinessList --->", tempBusinessList)

        }
        else { setBusinessList([]); setSearchOfBusinessName(""); }
    }

    const openToSaveDelete = (business) => {
        setConfirmationOpen(true);
        setBusinessToDeleteAdd(business);

    }
    const closeAddDelete = () => {
        setConfirmationOpen(false);
        setSearchOfBusinessName("");

    }
    const onSaveButtonClick = async () => {
        let payload = {
            addressId: props.selectedAddressArray[parseInt(props.selectedAddressIndex)].addressId,
            business: businessToDeleteAdd,
            id: props.dashboard.selectedConsumer._id
        }
        props.onSave(payload);
        setConfirmationOpen(false);
        setSearchOfBusinessName("");

    }

    return (
        <div className={classes.modal}>
            <Grid container style={{ color: "black", backgroundColor: "white" }} spacing={3} >
                <Grid item xs={11}>
                    <Typography style={{ color: "black", fontSize: "14px" }}>Business List ({parseInt(displayList.length)})</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography style={{ color: "black" }}> <CloseIcon style={{ paddingRight: "10px" }}
                        className={classes.headGridTwo}
                        onClick={() => {
                            props.onClose();
                        }}
                    /></Typography>
                </Grid>
                <Grid container style={{ backgroundColor: "#14B7DD", height: "32px" }}>
                    <Grid item xs={12}><Typography style={{ color: "white", padding: "4px 0 0 10px", fontSize: "14px" }} gutterBottom>Consumer Details</Typography></Grid>
                </Grid>
                <Grid item xs={12} style={{ color: "#747474", paddingBottom: "2px" }}><Typography style={{ fontSize: "12px" }}> Name: <b style={{ fontSize: "12px" }} >{props.selectedConsumer && props.selectedConsumer[0] ? props.selectedConsumer[0] : ""} {props.selectedConsumer && props.selectedConsumer[1] ? props.selectedConsumer[1] : ""}</b> </Typography></Grid>
                <Grid item xs={6} style={{ color: "#747474" }}><Typography style={{ fontSize: "12px" }}> ID: <b>{props.selectedConsumer && props.selectedConsumer[2] ? props.selectedConsumer[2] : "NA"}</b> </Typography></Grid>
                <Grid item xs={6} style={{ color: "#747474" }}><Typography style={{ fontSize: "12px" }}> Address ID: <b>{selectedAddress && selectedAddress.addressId ? selectedAddress.addressId : "NA"}</b> </Typography></Grid>
                <Grid item xs={12} style={{ color: "#747474" }}><Typography style={{ fontSize: "12px" }}> {<LOCATIONICON color="action" />}    {selectedAddress && selectedAddress.societyName ? selectedAddress.societyName + "," : ""} {selectedAddress && selectedAddress.landmark ? selectedAddress.landmark + "," : ""} {selectedAddress && selectedAddress.area ? selectedAddress.area + "," : ""} {selectedAddress && selectedAddress.pinCode ? selectedAddress.pinCode : ""} </Typography></Grid>
                <div style={{ backgroundColor: "#F5F5F5", margin: "2px 5px 2px 5px", display: "flex", flexDirection: "row", width: "100%", height: "100%", fontsize: "10px" }}>

                    <TextField
                        margin="dense"
                        variant="outlined"
                        placeholder="Business Id / Business Name"
                        value={search}
                        name="BusinessNameID"
                        style={{ height: "100%", marginTop: "0px", width: "80%", fontSize: "10px" }}
                        onChange={(e) => { onSearchTextChange(e.target.value) }}
                        InputProps={{
                            style: { fontSize: "14px" },
                            startAdornment:
                                <SearchIcon style={{ float: 'right', paddingRight: "4px" }} />

                        }}
                    />

                    <Button style={{ backgroundColor: "#14B7DD", width: "20px", marginLeft: "5px", height: "100%", fontSize: "20px", paddingTop: "2px", paddingBottom: "0px" }}
                        type="submit"
                        variant="contained"
                        margin="normal"
                        onClick={() => addBusinessOnClick()}> + </Button>

                </div>
                <Container style={{ height: "calc(100vh - 243px)", overflowY: "scroll", color: "#14B7DD" }} spacing={3}>
                    {displayList.length > 0 ? displayList.map((b, a) => {
                        return (
                            <Card sx={{
                                width: "100%",
                                backgroundColor: "#FFFFFF",
                                border: "0.5px solid #E9E9E9",
                                borderRadius: "10px",
                                // opacity: 1
                            }} style={{ margin: "5px" }}>
                                <CardContent>
                                    <Typography style={{ fontSize: 12, fontWeight: "550" }} color="text.secondary" gutterBottom>
                                        {a < 9 ? `0${(a + 1)}. ${b.businessName}` : `${a + 1}. ${b.businessName}`}
                                    </Typography>
                                    <Typography component="div" style={{ fontSize: "12px" }}>
                                        {<BUSINESSIDSVG color="action" />}  BusinessId: {b.businessId}
                                    </Typography>
                                    <Typography component="div" style={{ fontSize: "12px" }}>
                                        {<CATEGORYSVG color="action" />}  Category: {b.categoryName}
                                    </Typography>
                                    <Typography component="div" style={{ fontSize: "12px" }}>
                                        {<DISTANCESVG color="action" />}  Distance: {b.distance} km
                                    </Typography>
                                </CardContent>
                            </Card>)
                    }) : <Typography style={{ textAlign: "center" }}>No Record Found</Typography>}

                </Container>

            </Grid>
            <Backdrop className={classes.backdrop} open={isFetching}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                anchorOrigin={{
                    vertical: "top", horizontal: "center", zIndex: 2002,
                    color: "#fff",
                }}
                open={isSnackOpen}
                autoHideDuration={3000}
                onClose={() => { setIsSnackOpen(false); }}
                ContentProps={{ "aria-describedby": "message-id" }}
            >
                {muiAlert}
            </Snackbar>
            <Dialog open={addBusinessModalOpen} onClose={() => {
                setAddBusinessModalOpen(false); setBusinessText("");
                setBusinessList([])
            }}>
                <div style={{ width: "430px", height: "auto" }}>
                    <DialogTitle style={{ backgroundColor: "#14B7DD", height: "45px", paddingTop: "9px", fontsize: "20px" }}>
                        Assign/Unassigned Business</DialogTitle>
                    <DialogContent>

                        <Typography variant="subtitle2" gutterBottom>Business Id/Name</Typography>

                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <Grid item xs={10} style={{ height: "65px" }}>

                                <TextField
                                    variant="outlined"
                                    name="BusinessNameID"
                                    value={searchBusiness}
                                    style={{ backgroundColor: "white", width: "90%", paddingBottom: "25px", marginTop: "10px" }}
                                    onChange={(e) => { setBusinessText(e.target.value) }}
                                    InputProps={{ style: { height: "38px" } }}

                                />

                            </Grid>
                            <Grid item xs={1}>

                                <Button style={{ backgroundColor: "#14B7DD", marginTop: "10px", height: "38px", marginLeft: "-21px", width: "100px" }}
                                    type="submit"
                                    variant="contained"
                                    margin="normal"

                                    onClick={(e) => { onClickBusinessList(); }}
                                > search </Button>

                            </Grid>
                        </div>

                        {businessList && businessList !== null && businessList !== undefined && businessList.length > 0 ?
                            <div spacing={3}>
                                {businessList.map((b, a) => {

                                    return (
                                        <Card sx={{
                                            width: "100%",
                                            backgroundColor: "#FFFFFF",
                                            border: "0.5px solid #E9E9E9",
                                            borderRadius: "10px"
                                            // opacity: 1
                                        }} style={{ margin: "5px", height: "40px" }}>
                                            <CardContent style={{ marginLeft: "-24px", display: "flex", flexDirection: "row" }}>
                                                {/* <Typography  component="div" style={{ fontSize: 14, fontWeight: "550", marginLeft: "15px" }} color="text.secondary">
                                        {`<b></b>${b.businessName && b.businessName !== null && b.businessName !== undefined ? b.businessName : ""} ID: ${b.businessId && b.businessId !== null && b.businessId !== undefined ? b.businessId : ""} ${b.areaBelongsTo && b.areaBelongsTo !== undefined && b.areaBelongsTo !== null && b.areaBelongsTo.name ? b.areaBelongsTo.name : ""}`}
                                    </Typography> */}
                                                <Grid item xs={11}>
                                                    <Typography component="div" style={{ marginLeft: "15px", color: "#7E7E7E", fontSize: "12px" }} >
                                                        <b style={{ fontSize: 14, fontWeight: "550", color: "black" }}> {b.businessName && b.businessName !== null && b.businessName !== undefined ? b.businessName : ""} </b>    ID:{b.businessId && b.businessId !== null && b.businessId !== undefined ? b.businessId : ""}     {b.areaBelongsTo && b.areaBelongsTo !== undefined && b.areaBelongsTo !== null && b.areaBelongsTo.name ? b.areaBelongsTo.name : ""}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {b.isBusinessExists === true ?
                                                        <IconButton
                                                            className={classes.icon}
                                                            style={{ float: "right", padding: "0px", height: "9px" }}
                                                            onClick={(e) => { openToSaveDelete(b) }}
                                                        > <Avatar className={classes.lightBlue}> <DELETE color="action" /> </Avatar>

                                                        </IconButton> :
                                                        <IconButton
                                                            className={classes.icon}
                                                            style={{ float: "right", padding: "0px", height: "9px" }}
                                                            onClick={(e) => { openToSaveDelete(b) }}
                                                        >  <Avatar className={classes.lightBlue}> <ADD color="action" /> </Avatar>

                                                        </IconButton>}</Grid>


                                            </CardContent>
                                        </Card>)
                                })}

                            </div>
                            : searchOfBusinessName && searchOfBusinessName !== "" && businessList && businessList !== null && businessList !== undefined && businessList.length === 0 ? <Typography style={{ textAlign: "center", paddingTop: "10px" }}>No Record Found</Typography> : null}

                    </DialogContent>

                </div>
            </Dialog>

            <Dialog open={confirmationOpen} onClose={() => { setConfirmationOpen(false) }} style={{ height: "500px", width: "1500px" }} >
                <div style={{ width: "438px", height: "159px" }}> <DialogTitle className="dialogTitle" style={{ backgroundColor: "#14B7DD", }}>
                    Assign/Unassigned Business Confirmation</DialogTitle>
                    <DialogContent>
                        <Grid item xs={12}>
                            {businessToDeleteAdd.isBusinessExists === true ? <Typography variant="subtitle2" gutterBottom>Are you sure want to Delete {businessToDeleteAdd.businessName} ?</Typography> : <Typography variant="subtitle2" gutterBottom>Are you sure want to Add {businessToDeleteAdd.businessName}?</Typography>}

                        </Grid>



                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={() => { closeAddDelete() }}>
                            No
                        </Button>
                        <Button autoFocus variant="contained" color="primary" onClick={() => {
                            onSaveButtonClick();
                            props.onClose();
                        }} >
                            Yes
                        </Button>
                    </DialogActions></div>

            </Dialog>

        </div>



    );
}
const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        dashboard: state.dashboard,
    };
};
export default connect(mapStateToProps, {
    addDeleteBusiness, getOneConsumer
})(withStyles(styles)(OpenBusinessAsPerAddress));

