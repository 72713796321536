import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { FormControlLabel, Radio, RadioGroup, TableBody, TableCell, TableRow, Table, Checkbox } from '@material-ui/core';
import _ from 'lodash';
import { connect } from "react-redux";
import { updateAddonGroup } from '../../../../actions/addonGroupAction'



const styles = theme => ({
    form: {
        width: '45%',
        height: 'fit-content',
        backgroundColor: "white",
        overflow: "hidden",
        flexGrow: 1,
        border: "2px solid #3d8bc3",
        borderRadius: "5px!important"


    },

    header: {
        height: '8%',
        margin: '0px 0px',
        width: '-webkit-fill-available',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,

    },
    CloseIcon: {
        float: "right",
        color: "white"
    },
    headerText: {
        color: "white",
        fontSize: "20px",
        fontFamily: "serif",
        marginLeft: '10px'
    },

    footer: {
        height: '10%',
        padding: '20px 0px 10px 150px',
        display: 'flex',

    },

    body: {
        fontFamily: "serif",
        padding: "10px 0px"
    },
    bodyMainContainer: {
        padding: "5px 4px"
    },

    dynamicForm: {
        width: '50%',
        height: 'screen!important',
        backgroundColor: "white",
        overflow: "auto",
        flexGrow: 1,
        border: "2px solid #3d8bc3",
        borderRadius: "10px!important", '&::-webkit-scrollbar': {
            width: '1px',
            backgroundColor: 'white',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'white',
            borderRadius: '1px'
        }
    },
    bodyHeader: {
        fontSize: "16px",
        fontFamily: "serif",
        margin: '5px 10px',
        font: 'math'
    },
    stickyForm: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: theme.palette.primary.main,
    },
    defaultColor: {
        color: '#F9AE00',
        fontFamily: 'Open Sans, Semibold',
        fontSize: '14px'
    },
    textColor: {
        color: '#202035',
        fontFamily: 'Open Sans, Semibold',
        fontSize: '14px'
    }



});


class ChangeDefaultModal extends React.Component {
    constructor() {
        super();
        this.state = {
            addonData: null,
            selectionType: null,
            error: ''
        };
        this.inputReference = React.createRef();
    }

    componentDidMount = async () => {
        let existingDefault = this.props.defaultRecords.changeDefault
        if (existingDefault.length) {
            existingDefault.sort((a , b)=> { return b.isDefault - a.isDefault });
            this.setState({ addonData: existingDefault, selectionType: this.props.defaultRecords.selectionType })

        }
    }


    handleDefaultChange = (_id) => {
        let { addonData } = this.state
        for (let index = 0; index < addonData.length; index++) {
            if (addonData[index].isDefault === true) {
                addonData[index].isDefault = false;
                break;
            }
        }
        for (let index = 0; index < addonData.length; index++) {
            if (JSON.stringify(addonData[index]._id) === JSON.stringify(_id)) {
                addonData[index].isDefault = true;
                break;
            }
        }
        this.setState({ addonData: addonData })
    }

    handleDefaultSelection = (_id, event) => {
        let { addonData } = this.state
        for (let index = 0; index < addonData.length; index++) {
            if (JSON.stringify(addonData[index]._id) === JSON.stringify(_id)) {
                addonData[index].isDefault = event.target.checked;
                break;
            }
        }
        this.setState({ addonData: addonData ,  error: '' })
    }


    onSubmit = async() => {
        let hasError = false

        let updatedDefault = this.state.addonData.filter(v => v.isDefault === true).map(e => e._id);
        let existingDefault = this.props.defaultRecords.defaultAddOns;
        let newDefaultRecords = updatedDefault.filter((v) => !existingDefault.includes(v));

        if (this.state.selectionType === 'Multi Selection' && updatedDefault.length !== this.props.defaultRecords.minSelection) {
            this.setState({ error: `Please Select ${this.props.defaultRecords.minSelection} Default Addons to Fullfill Min Selection Criteria` })
            hasError = true
        }

        if (hasError) {
            return
        }

       if (newDefaultRecords.length) {
          
           let payload = {
            addonGroupId: this.props.defaultRecords._id,
            defaultAddOns: updatedDefault
          }
          await this.props.updateAddonGroup(payload);
       }
       this.props.closeChangeDefault()
       
    }


    render() {
        const { classes } = this.props;
        let props = this.props
        let { addonData, selectionType } = this.state
          
        return (
            <div className={addonData && addonData.length > 9 ? classes.dynamicForm : classes.form}>
                <div className={classes.appBarSpacer} >
                    <Grid container className={addonData && addonData.length > 9 ? classes.stickyForm : classes.header} spacing={1}>
                        <Grid item xs={8}>
                            <Typography variant="h5" gutterBottom className={classes.headerText}>
                                Change Default
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <CloseIcon className={classes.CloseIcon} onClick={() => { props.closeChangeDefault() }} />
                        </Grid>
                    </Grid>
                    <Container className={classes.body}>
                        <Typography className={classes.bodyHeader}>You are updating your default addon</Typography>
                        <Table size='small'>
                            <TableBody>
                                {
                                    addonData && addonData.length ? addonData.map((row) => {
                                        return (
                                            <TableRow key={row._id} >
                                                <TableCell style={{ padding: '0px 0px 0px 10px' }}>
                                                    {selectionType === 'Single Selection' ?
                                                        <FormControlLabel name="isDefaultVariationView" checked={row.isDefault} onChange={(e) => this.handleDefaultChange(row._id)} value={row.isDefault} control={<Radio style={{ color: '#F9AE00', padding: '5px 0px 0px 10px' }} />} />
                                                        : <Checkbox
                                                            checked={row.isDefault}
                                                            onClick={(event) => this.handleDefaultSelection(row._id, event)}
                                                        ></Checkbox>
                                                    }
                                                </TableCell>
                                                <TableCell style={{ padding: '0px 0px 0px 0px', minWidth: '270px' }}>
                                                    {row?.service ? <Typography className={row.isDefault ? classes.defaultColor : classes.textColor}>{row.service}</Typography> : 'NA'}
                                                </TableCell>
                                                <TableCell style={{ padding: '0px 0px 0px 10px' }}>
                                                    {`${row.cost?.quantity ? row.cost?.quantity : 'per'} ${row.cost?.unit}`}
                                                </TableCell>
                                                <TableCell style={{ padding: '0px 10px 0px 10px' }}>
                                                    {`${`₹`} ${row.cost?.fixedCost ? row.cost?.fixedCost : 'NA'}`}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }) : null
                                }
                            </TableBody>
                        </Table>

                        <Container>
                            <Typography style={{ color: 'red', margin: '5px', fontSize: '12px' }}>{this.state.error}</Typography>
                        </Container>
                    </Container>
                    <Container style={{ display: "flex", maxWidth: "60px" }}>


                        <Button
                            fullWidth
                            variant="contained"
                            margin="normal"
                            color="default"
                            onClick={() => { props.closeChangeDefault() }}
                            style={{ margin: "10px", padding: '5px 50px' }}
                        >
                            CANCEL
                        </Button>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            margin="normal"
                            style={{ margin: "10px", padding: '5px 50px' }}
                            onClick={this.onSubmit}
                            color="primary">
                            SAVE
                        </Button>

                    </Container>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {

    };
};
export default connect(mapStateToProps, {
    updateAddonGroup
})(withStyles(styles)(ChangeDefaultModal));

