import axios from "axios";
import {
    SET_AREA_MANAGERS,
    SET_AREA_MANAGER_SUCCESS_MESSAGE,
    SET_AREA_MANAGER_ERROR_MESSAGE,
    REQUEST_AREA_MANAGERS
} from "./types";

export const requestAreaManagers = () => {
    return {
        type: REQUEST_AREA_MANAGERS,
        payload: true
    };
};

export const clearMsg = () => async dispatch => {
    let data = {};
    data.msg = null;
    data.ops = null;
    dispatch({ type: SET_AREA_MANAGER_SUCCESS_MESSAGE, payload: data });
    dispatch({ type: SET_AREA_MANAGER_ERROR_MESSAGE, payload: data });
};

export const getAllAreaManagers = (payload) => async dispatch => {
    try {
        let areaManagerData = await axios.get("api/areamanager/all/search", {
            params: payload
        });
        dispatch({ type: SET_AREA_MANAGERS, payload: areaManagerData.data });
    } catch (err) {
        dispatch({ type: SET_AREA_MANAGER_ERROR_MESSAGE, payload: "Failed to get all area managers." });
    }
};

export const addAreaManager = (payload) => async dispatch => {
    try {
        await axios.post("/api/areamanager/", {
            'username': payload.username, 
            'password': payload.password, 
            'firstName': payload.firstName,
            'lastName': payload.lastName,
            'email': payload.email,
            'mobileNumber': payload.mobileNumber,
            'controlledAreas': payload.controlledAreas,
            'role': payload.role
        });
        dispatch({ type: SET_AREA_MANAGER_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Area Manager added successfully." } });
    } catch (err) {
        if (err.response.status === 409) {
            dispatch({ type: SET_AREA_MANAGER_ERROR_MESSAGE, payload: { ops: "ADD", msg: "The username already exists. Please use a different username." } });
        } else {
            dispatch({ type: SET_AREA_MANAGER_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Failed to add Area Manager." } });
        }
    }

    //  try {
    //     await axios.post("/api/areamanager/", payload);
    //     dispatch({ type: SET_AREA_MANAGER_SUCCESS_MESSAGE, payload: { msg: "Area Manager added successfully." } });
    // } catch (err) {
    //     if (err.response.status === 409) {
    //         dispatch({ type: SET_AREA_MANAGER_ERROR_MESSAGE, payload: { ops: "ADD", msg: "The username already exists. Please use a different username." } });
    //     } else {
    //         dispatch({ type: SET_AREA_MANAGER_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Failed to add Area Manager." } });
    //     }
    // }
};

export const updateAreaManager = (payload) => async dispatch => {
    try {
        await axios.patch(`/api/areamanager/${payload._id}`, payload);
        dispatch({ type: SET_AREA_MANAGER_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Area Manager updated successfully." } });
    } catch (err) {
        dispatch({ type: SET_AREA_MANAGER_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Failed to update Area Manager." } });
    }
};
