import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import { fetchEnvs } from '../../actions/authActions';
import { connect } from "react-redux";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import UploadNoticePhoto from './uploadNoticePhoto';
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = theme => ({
    form: {
        width: '140%',
        height: '180%',
        backgroundColor: "white",
        overflow: "auto",
        flexGrow: 1,


    },
    header: {
        height: '15%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    bodyheader: {
        height: '15%',
        margin: '15px 0px 0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',

    },
    content: {
        height: '60%',
        margin: '10px 0px',
        overflow: 'hidden',
    },
    footer: {
        height: '10%',
        margin: '20px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    addText: {
        fontSize: "15px"
    },
    appBarSpacer: theme.mixins.toolbar,
    body: {


    },
    img: {
        display: "flex"
    },
    div2: {
        marginTop: "20px 0px 0px 0px"
    },
    div1: {
        width: "screen",
        padding: "10px 10px 10px 10px",
        borderBottom: "1px solid gray",
        overflow: "auto",
    },
    imgIcon: {
        width: "inherit!important",
        height: "inherit!important",
        padding: "4px",


    },
    imgMessage: {
        width: "inherit!important",
        height: "inherit!important",
        alignItems: "center",
        justifyContent: "center"


    },
    customImageContainerPreview: {
        height: "-webkit-fill-available!important",
        width: "-webkit-fill-available!important",
    },
    gridListIcon: {
        width: 'auto',
        height: "-webkit-fill-available!important",
        background: "#c2aece 0% 0% no-repeat padding-box",
        border: " 1px solid #748A9D1A",
        borderRadius: "5px",
        opacity: "1",
        padding: "4px 4px 4px 4px"
    },

    gridElementIcon: {
        margin: '10px 5px'
    },
    uploadField: {
        display: "inline-flex!important",
        justifyContent: "space!import"
    },
    row1: {
        padding: "8px 8px 8px 8px"
    },
    // uploadAddButton :{
    //     height : "50%",
    //     width : "100%",
    //     marginTop : "100px"
    // },
    uploadAddButton: {
        padding: "10px"
    }



});


class EditNoticeForm extends React.Component {
    constructor() {
        super();
        this.state = {
            noticeName: "",
            role: "",
            noticeType: "",
            message: "",
            isUploading: false,
            selectedPhoto: null,
            typePhoto: "", //imageUrl
            path: "",
            title: "",
            preview: false,
            _id : "",
            notificationBody : ""

        };
        this.inputReference = React.createRef();
    }


    componentDidMount() {
      this.setState({
        noticeName : this.props.data?.noticeName,
        role : this.props.data?.role,
        noticeType : this.props.data?.noticeType,
        path : this.props.data?.photo?.path,
        title : this.props.data?.photo?.title,
        message : this.props.data?.message,
        notificationBody : this.props.data.notificationBody,
        _id : this.props.data?._id
      })
       

    }


    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    updateNoticePhoto = async (files, photoType) => {
        let data = new FormData();
        let folderPath = `Marketplace_Business/Inappnotice/`;
        let temp = files[0].name.split(".");
        let fileName = temp[0] + Math.ceil(Math.random() * 999999) + "." + temp[1];
        data.append("file", files[0]);
        data.append("folder", folderPath);
        data.append(
            "upload_preset",
            this.props.envs.env.cloudinaryBusinessImagesUploadPreset
        );
        let res = await fetch(this.props.envs.env.cloudinaryUploadUrl, {
            method: "POST",
            body: data,
        });
        let file = await res.json();
        if (photoType === "noticephoto") {
            let path = file.secure_url;
            let title = file.original_filename;
            this.setState({ path });
            this.setState({ title });
            this.setState({ isUploading: false, typePhoto: photoType });
        }

    };



    clearData = () => {
        this.setState({
            noticeName: "",
            path: "",
            message: "",
            title: "",
            preview: false,
            isUploading: false,
            selectedPhoto: null,
            notificationBody : ""


        });
    };

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onSubmit = (formValues) => {
        formValues._id = this.state._id
        formValues.noticeName = this.state.noticeName
        formValues.role = this.state.role
        formValues.message = this.state.message
        formValues.path = this.state.path
        formValues.title = this.state.title
        formValues.notificationBody = this.state.notificationBody
        this.props.onSubmitEdit(formValues);

    };
   


    render() {
        const { classes } = this.props;
        var data = this.props.data;
        // console.log(`IN THE PROPS`, this.props.data);
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <div className={classes.appBarSpacer} >
                    <Grid container className={classes.header} spacing={1}>
                        <Grid item xs={8}>
                            <Typography variant="h5" gutterBottom>
                                Edit Notice
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <CloseIcon className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
                        </Grid>
                    </Grid>
                    <Container className={classes.body}>
                        <Grid container rowSpacing={4} className={classes.row1}>
                            <Grid item xs={4}>
                                <Typography variant="h6" gutterBottom>
                                    Notice Id
                                </Typography>
                                <TextField fullWidth margin="dense" name="noticeId" style={{ width: "225px" }} value={data.noticeId} variant="outlined" disabled={true} />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6" gutterBottom>
                                    Notice Name*
                                </Typography>
                                <TextField fullWidth margin="dense" name="noticeName" style={{ width: "225px" }}  value={this.state.noticeName} variant="outlined" onChange={this.handleFilterChange} placeholder="Enter Notice Name" />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6" gutterBottom>
                                    Role
                                </Typography>
                                <FormControl fullWidth margin="dense" variant="outlined">
                                    <InputLabel id="demo-simple-select-standard-label">Select Role</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={this.state.role}
                                        name="role"
                                        label="Select Role"
                                        onChange={this.handleChange}
                                        disabled={true}
                                        style={{ width: "225px" }}
                                    >
                                        <MenuItem value="Shopper" style={{ minHeight: "28px", height: "28px" }}>Shopper</MenuItem>
                                        <MenuItem value="Business" style={{ minHeight: "28px", height: "28px" }}>Business</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6" gutterBottom>
                                    Notice Type
                                </Typography>
                                <FormControl fullWidth margin="dense" variant="outlined">
                                    <InputLabel id="demo-simple-select-standard-label">Select Notice Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={this.state.noticeType}
                                        name="noticeType"
                                        onChange={this.handleChange}
                                        style={{ width: "225px", border: "bold" }}
                                        label="Select Notice Type"
                                        disabled={true}

                                    >
                                        <MenuItem value="Icon Notice" style={{ minHeight: "28px", height: "28px" }}>With Icon</MenuItem>
                                        <MenuItem value="Image Notice" style={{ minHeight: "28px", height: "28px" }}>With Image</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                            { data.noticeType === "Icon Notice" ?   <Grid >
                                    <Typography variant="h6" gutterBottom>
                                        Upload Icon*
                                    </Typography>
                                    <Grid className={classes.uploadField}><TextField margin="dense" name="path" variant="outlined" style={{ width: "225px" }} value={this.state.path && this.state.title ? this.state.title : "Upload Icon"} />
                                        <Grid className={classes.uploadAddButton}>
                                            <UploadNoticePhoto
                                                uploadButtonName="+"
                                                updateNoticePhoto={this.updateNoticePhoto}
                                                photoType="noticephoto"
                                                disabled={this.state.title ? true : false}
                                                env={this.props.env}

                                            />
                                        </Grid>
                                    </Grid>
                                </Grid> : 
                                <Grid >
                                <Typography variant="h6" gutterBottom>
                                    Upload Image*
                                </Typography>
                                <Grid className={classes.uploadField}><TextField margin="dense" name="path" variant="outlined" style={{ width: "225px" }} value={this.state.path && this.state.title ? this.state.title : "Upload Image"} />
                                    <Grid className={classes.uploadAddButton}>
                                        <UploadNoticePhoto
                                            uploadButtonName="+"
                                            updateNoticePhoto={this.updateNoticePhoto}
                                            photoType="noticephoto"
                                            disabled={this.state.title ? true : false}
                                            env={this.props.env}

                                        />
                                    </Grid>
                                </Grid>
                            </Grid> }
                            </Grid>
                        </Grid>
                        {this.state.noticeType === "Icon Notice" ?

                            <Typography variant="h6" gutterBottom className={classes.div2}>
                                Message*
                                <TextField margin="dense" name="message" variant="outlined" id="outlined-multiline-static"
                                    multiline
                                    rows={3} style={{ width: "100%" }} placeholder="Type Your Message Here !" value={this.state.message} onChange={this.handleFilterChange} />
                            </Typography>
                            : ""}
                        {this.state.noticeType === "Image Notice" ?

                            <Typography variant="h6" gutterBottom className={classes.div2}>
                                Notification Body*
                                <TextField margin="dense" name="notificationBody" variant="outlined" id="outlined-multiline-static"
                                    multiline
                                    rows={3} style={{ width: "100%" }} placeholder="Type Your Notification Body Here !" value={this.state.notificationBody} onChange={this.handleFilterChange} />
                            </Typography>
                            : " "}
                        <Container className={classes.div2}>
                            {this.state.noticeType === "Icon Notice" && this.state.path && this.state.message ? <Grid container spacing={2} >
                                <Grid item xs={8}></Grid>
                                <Grid item xs={2}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="default"
                                        onClick={this.clearData}

                                    >
                                        RESET
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={() => { this.setState({ preview: true }) }}
                                        color="primary">
                                        PREVIEW
                                    </Button>
                                </Grid>
                            </Grid> :
                                <Grid container spacing={2} >
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            margin="normal"
                                            color="default"
                                            onClick={this.clearData}


                                        >
                                            RESET
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>

                                        <Button
                                            fullWidth
                                            variant="contained"
                                            margin="normal"
                                            disabled={this.state.noticeType === "Image Notice" && this.state.title && this.state.notificationBody ? false : true}
                                            onClick={() => { this.setState({ preview: true }) }}
                                            color="primary">
                                            PREVIEW
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                        </Container>
                        <Container className={classes.div1} />
                        <Typography variant="h6" gutterBottom>
                            Preview
                        </Typography>
                        {this.state.noticeType === "Icon Notice" ?
                            <Grid item xs={15}>
                                {(this.state.path != "" && this.state.preview === true) ?
                                    <GridList className={classes.gridListIcon}>
                                        <a href={this.state.path} target="_blank" rel="noopener noreferrer" className={classes.imgIcon}>
                                            <img border="0" src={this.state.path} alt="" />
                                        </a>

                                        ))<Typography className={classes.imgMessage}>{this.state.message}</Typography>
                                    </GridList >
                                    : null}

                            </Grid> : null}
                        {this.state.noticeType === "Image Notice" ? <Grid item xs={15}>
                            {(this.state.path != "" && this.state.preview === true) ?
                                <GridList className={classes.gridList}>
                                    <a href={this.state.path} target="_blank" rel="noopener noreferrer">
                                        <img border="0" src={this.state.path} alt="" className={classes.customImageContainerPreview} />
                                    </a>

                                    ))
                                </GridList >
                                : null}

                        </Grid> : null}

                        <Container className={classes.footer}>
                            <Grid container spacing={2} >
                                <Grid item xs={8}></Grid>
                                <Grid item xs={2}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="default"
                                        onClick={() => { this.props.onClose() }}
                                    >
                                        CANCEL
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        disabled={(this.state.noticeType === "Image Notice" && this.state.title && this.state.noticeName && this.state.notificationBody || this.state.noticeType === "Icon Notice" && this.state.title && this.state.message && this.state.noticeName) ? false : true}
                                        color="primary">
                                        SAVE
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                    </Container>
                </div>
            </form>
        );
    }
}

export default reduxForm({
    form: 'EditNoticeForm',
})(withStyles(styles)(EditNoticeForm))