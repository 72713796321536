import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { Grid } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import { lightBlue } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        // fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    icon: {
        height: 32,
    },
    lightBlue: {
        color: theme.palette.getContrastText(lightBlue[50]),
        backgroundColor: lightBlue[50],
        width: theme.spacing(4),
        height: theme.spacing(4),
        cursor: 'pointer',
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    // console.log("Props---->", props);
    // console.log("the referralAmount---->", props.data[0].referredUser.referralAmount)
    return (
        <Grid container justify="flex-start" alignItems="center" >

        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="subtitle2" gutterBottom>
            Referred User
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
            Coupon Amount: {'\u20B9'} { props && props.data && props.data.length && props.data[0].referredUser.referralAmount >= 0 ? props.data[0].referredUser.referralAmount : "--" }
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography>
            Minimum Order Value: {'\u20B9'} { props && props.data && props.data.length && props.data[0].referredUser.minimumOrderValue >= 0 ? props.data[0].referredUser.minimumOrderValue : "--" }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography>
            Coupon Expiry: { props && props.data && props.data.length && props.data[0].referredUser.couponExpiry >= 0 ? props.data[0].referredUser.couponExpiry : "--" } Days
          </Typography>
        </Grid>

        <Grid item xs={12} sm={11}>
            <Divider color="textSecondary"
                    style={{ marginBottom: "5px", marginTop: "10px", marginLeft: "85px", marginRight: "0px"}}
                    display="block"
            /> 
		    </Grid>

        <Grid item xs={12} sm={1}>
        <IconButton className={classes.icon} style={{ float: "right", marginLeft: "30px", marginRight: "30px" }} 
            onClick={() => props.handleEditOpen(props.data[0].referredUser.referralAmount, props.data[0].referredUser.minimumOrderValue, props.data[0].referredUser.couponExpiry, props.data[0].referringUser.referralAmount, props.data[0].referringUser.minimumOrderValue, props.data[0].referringUser.couponExpiry, props.data[0].referralCouponName, props.data[0].referralCouponImage, props.data[0].termsAndConditionsUrl)}
        >
          <Avatar className={classes.lightBlue}>
              <EditIcon color="action" />
          </Avatar>
        </IconButton>
        </Grid>


        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="subtitle2" gutterBottom>
            Referring User
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
            Coupon Amount: {'\u20B9'} {props && props.data && props.data.length && props.data[0].referringUser.referralAmount >= 0 ? props.data[0].referringUser.referralAmount : "--"}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography>
            Minimum Order Value: {'\u20B9'} { props && props.data && props.data.length && props.data[0].referringUser.minimumOrderValue >= 0 ? props.data[0].referringUser.minimumOrderValue : "--" }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography>
            Coupon Expiry: {props && props.data && props.data.length && props.data[0].referringUser.couponExpiry >= 0 ? props.data[0].referringUser.couponExpiry : "--" } Days
          </Typography>
        </Grid>

      </Grid>
    );
}
