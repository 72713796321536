import axios from "axios";
import request from 'superagent';

export const getConsumerTagList = async () => {
    try {
        const response = await axios.get("/api/consumerTag");
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
};


export const deleteconsumerTag = async (payload) => {
    // console.log("payload", payload);
    try {
        const response = await axios.delete(`/api/consumerTag/${payload}`);
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
}

export const updateconsumerTag = async (payload) => {
    // console.log("payload", payload);
    try {
        const response = await axios.patch(`/api/consumerTag/${payload._id}`, payload);
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
}

export const createConsumerTag = async (payload) => {
    // console.log("payload", payload);
    try {
        const response = await axios.post(`/api/consumerTag`, payload);
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
}


