import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  HEADLIST,
  SUBHEADSERVICELIST,
  HEADSERVICELIST,
  BACKBUTTONTEXT,
  CANCELBUTTONTEXT,
  SAVEBUTTONTEXT,
  BACKBUTTONCONFIRMATIONDIALOGTITLE,
  BACKBUTTONCONFIRMATIONDIALOGCONTENT,
  NEXTBUTTONCONFIRMATIONDIALOGTITLE,
  NEXTBUTTONCONFIRMATIONDIALOGCONTENT,
} from "../constants";
import ConfirmationDialogComponent from "./ConfirmationDialogComponent";

function ManageBusinessSequenceDragDrop(props) {
  const { fetchSequence, setSequence, onItemClick, type, onBackClick } = props;
  const [sequenceArray, setSequenceArray] = useState([]);
  const [isSequenceChangeUpdated, setIsSequenceChangeUpdated] = useState(true);
  const [isOpenBackDialog, setIsOpenBackDialog] = useState(false);
  const [isOpenNextDialog, setIsOpenNextDialog] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSequenceArray(fetchSequence());
    setOpen(false)
  }, [fetchSequence]);

  const handleBackDialogOpen = () => {
    setIsOpenBackDialog(true);
  };

  const handleBackDialogClose = () => {
    setIsOpenBackDialog(false);
  };

  const handleBackDialogYesClick = () => {
    handleBackDialogClose();
    onBackClick();
  };

  const handleBackDialogNoClick = () => {
    handleBackDialogClose();
  };

  const handleNextDialogOpen = () => {
    setIsOpenNextDialog(true);
  };

  const handleNextDialogClose = () => {
    setIsOpenNextDialog(false);
  };

  // const handleNextDialogYesClick = () => {
  //   handleNextDialogClose();
  //   onItemClick(selectedItemIndex);
  // };

  const handleNextDialogNoClick = () => {
    handleNextDialogClose();
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    setIsSequenceChangeUpdated(false);
    const items = Array.from(sequenceArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSequenceArray(items);
  };

  const onItemClickHandle = (index) => {
    setSelectedItemIndex(index);
    if (isSequenceChangeUpdated) {
      onItemClick(index);
    } else {
      handleNextDialogOpen();
    }
  };

  const onBackClickHandle = () => {
    if (isSequenceChangeUpdated) {
      onBackClick();
    } else {
      handleBackDialogOpen();
    }
  };

  const onCancelClickHandle = () => {
    setSequenceArray(fetchSequence());
    setIsSequenceChangeUpdated(true);
  };

  const onSaveClickHandle = () => {
    setSequence(sequenceArray);
    // setSequenceArray(fetchSequence());
    setIsSequenceChangeUpdated(true);
    setSequenceArray();
    
    
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Button
        color="primary"
        variant="contained"
        disabled={type === HEADLIST}
        onClick={() => {
          onBackClickHandle();
        }}
      >
        {BACKBUTTONTEXT}
      </Button>
      <Button
        style={{ float: "right", margin: "5px" }}
        variant="contained"
        color="primary"
        disabled={isSequenceChangeUpdated}
        onClick={() => {
          onSaveClickHandle();
          setOpen(true)
        }}
      >
        {SAVEBUTTONTEXT}
      </Button>
      <Button
        style={{ float: "right", margin: "5px" }}
        variant="contained"
        color="primary"
        disabled={isSequenceChangeUpdated}
        onClick={() => {
          onCancelClickHandle();
        }}
      >
        {CANCELBUTTONTEXT}
      </Button>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId={"1"} direction="vertical">
          {(provided) => {
            return (
              <TableContainer>
                <Table>
                  <TableBody
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {sequenceArray && sequenceArray.length > 0 &&
                      sequenceArray.map((ele, index) => {
                        return (
                          <Draggable
                            draggableId={(type === SUBHEADSERVICELIST ||
                            type === HEADSERVICELIST
                              ? ele._id + index
                              : ele.businessId + index
                            ).toString()}
                            index={index}
                            key={
                              type === SUBHEADSERVICELIST ||
                              type === HEADSERVICELIST
                                ? ele._id + index
                                : ele.businessId + index
                            }
                          >
                            {(provided) => (
                              <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {type === SUBHEADSERVICELIST ||
                                type === HEADSERVICELIST ? (
                                  getTableCellForService(ele)
                                ) : (
                                  <TableCell align="left">{ele.name}</TableCell>
                                )}
                                {type === SUBHEADSERVICELIST ||
                                type === HEADSERVICELIST ? null : (
                                  <TableCell align="right">
                                    <ArrowForwardIosIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        onItemClickHandle(index);
                                      }}
                                    />
                                  </TableCell>
                                )}
                              </TableRow>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </TableBody>
                </Table>
               
              </TableContainer>
            );
          }}
        </Droppable>
      </DragDropContext>

      <ConfirmationDialogComponent
        name={"BackButtonConfirmationDialog"}
        handleDialogOpen={handleBackDialogOpen}
        handleDialogClose={handleBackDialogClose}
        handleDialogYesClick={handleBackDialogYesClick}
        handleDialogNoClick={handleBackDialogNoClick}
        isOpenDialog={isOpenBackDialog}
        dialogTitle={BACKBUTTONCONFIRMATIONDIALOGTITLE}
        dialogContent={BACKBUTTONCONFIRMATIONDIALOGCONTENT}
      />
      <ConfirmationDialogComponent
        name={"NextButtonConfirmationDialog"}
        handleDialogOpen={handleNextDialogOpen}
        handleDialogClose={handleNextDialogClose}
        // handleDialogYesClick={handleNextDialogYesClick}
        handleDialogNoClick={handleNextDialogNoClick}
        isOpenDialog={isOpenNextDialog}
        dialogTitle={NEXTBUTTONCONFIRMATIONDIALOGTITLE}
        dialogContent={NEXTBUTTONCONFIRMATIONDIALOGCONTENT}
        noButtonText={"OK"}
      />
      <Backdrop
                  style={{ color : '#fff' ,  zIndex : -1 }}
                  open={open}

                >
                  <CircularProgress color="inherit" />
       </Backdrop>
    </div>
  );
}

const getTableCellForService = (ele) => {
  return (
    <>
      <TableCell align="left">{ele.serviceId}</TableCell>
      <TableCell align="left">{ele.service}</TableCell>
      <TableCell align="left">
        {ele.cost.costType === "Fixed"
          ? "Price : " + ele.cost.fixedCost
          : "Price : " + ele.cost.from + " " + ele.cost.to}
      </TableCell>
      <TableCell align="left">
        {ele.availability.isActive ? "Available" : "Unavailable"}
      </TableCell>
      <TableCell align="left">
        {ele.isEnabled ? "Enabled" : "Disabled"}
      </TableCell>
    </>
  );
};
export default ManageBusinessSequenceDragDrop;
