import axios from "axios";
import { 
    GET_BUSINESS_AGREEMENT_DETAILS_PDF
} from "./types";
export const getBusinessAgreementByBusinessId = (payload) => async (dispatch) => {
    try {
      // console.log("......in ACTION........payload.businessId..........",payload.businessId);
      let result = await axios.get(`api/businessAgreement/${payload.businessId}`);
      // console.log(".......actions...result..",result)
      dispatch({ type: GET_BUSINESS_AGREEMENT_DETAILS_PDF, payload: result.data });
    } catch (err) {
      console.log("Error while fetching business agreement by business id: ", err);
    }
  };