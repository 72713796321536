import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { lightBlue } from '@material-ui/core/colors';
import _ from 'lodash';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { changeHeaderName, getAllConsumers, requestBusinessStats, emptyConsumersList } from '../../actions/dashboardActions';
import { fetchEnvs } from '../../actions/authActions';
import Input from '@material-ui/core/Input';
import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const styles = theme => ({
    Checkbox: {
        fontSize: 20
    },
    grid: {
        width: '100%',
        //display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: "15px",
        paddingRight: "15px",
        margin: '0px 0px',

    },
    deleteBtn: {
        cursor: 'pointer',
        color: 'grey',
        //marginTop: "7px"
    },
    header: {
        height: '10%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: "22px",
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '73%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px'
    },
    modalContainer: {
        width: '100%',
        height: '100%',
        padding: '0px 0px',
        backgroundColor: theme.palette.background.paper,
        margin: '10px 0px',
        overflow: 'auto',
    },
    gridElement: {
        margin: '10px 5px'
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    icon: {
        color: 'white',
    },
    gridList: {
        width: '100%',
        height: '100%'
    },
    mainContainer: {
        width: '80%',
        height: '50%',
        backgroundColor: theme.palette.background.paper,
        padding: '0px 0px',

    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    head: {
        fontWeight: 700,
        fontSize: "12px",
    },
    input: {
        height: 30,
        padding: '0px'
    },
    button: {
        height: 30
    },
    lightBlue: {
        color: theme.palette.getContrastText(lightBlue[50]),
        backgroundColor: lightBlue[50],
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    select: {
        height: '38px'
    },
    listItemText: {
        'margin': '0px'
    },
    buttonGroup: {
        "height": "44px",
        "marginTop": "9px",
        "width": "0px"
    },
    buttonGroupTextFeild: {
        "height": "40px"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

const WhiteTextTypography = withStyles({
    root: {
        color: "#FFFFFF"
    }
})(Typography);

class EditPushNotification extends Component {
    constructor() {
        super();
        this.state = {
            businessFilter: "",
            servicesId: "",
            distance: "",
            title: "",
            photos: {},
            notificationText: "",
            notificationName: "",
            scheduleType: "",
            scheduledDate: "",
            scheduledTime: "",
            timeLine: "",
            daysOfWeek: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            daysOfMonth: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
            weekDays: [],
            monthDays: [],
            recurringStartDate: "",
            recurringEndDate: "",
            error: {}
        };
        this.inputReference = React.createRef();
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }

    fileUploadAction = () => this.inputReference.current.click();
    onFileChange = async event => {
        let files = event.target.files;
        this.updatePhotos(files);
        //this.updateBusinessPhotos(files);
    };
    updatePhotos = async (files) => {
        let data = new FormData();
        let folderPath = `Marketplace_Business/pushNotifications/`;
        let temp = files[0].name.split(".");
        let fileName = temp[0] + Math.ceil(Math.random() * 999999) + "." + temp[1];
        data.append("file", files[0]);
        data.append("folder", folderPath);
        data.append(
            "public_id",
            `/${fileName}`
        );
        data.append(
            "upload_preset",
            this.props.env.cloudinaryBusinessImagesUploadPreset
        );
        let res = await fetch(this.props.env.cloudinaryUploadUrl, {
            method: "POST",
            body: data,
        });

        let file = await res.json();

        let photos = {
            title: "",
            path: file.secure_url,
            public_id: file.public_id,
        };

        this.setState({
            photos
        });
        this.setState({ isUploading: false });
    };
    componentDidUpdate(prevProps) {
        if (this.props.data && ((this.props.data != prevProps.data))) {
            this.setData();
        }
    }
    componentDidMount() {
        this.props.fetchEnvs();
    }
    getRecieverIds = async () => {
        let payload = {
            orderBy: 'createdAt',
            order: Number(-1),
            businessFilter: this.state.businessFilter,
            distance: Number(this.state.distance) * 1000,
            exportToexcel: 1
        };
        await this.props.getAllConsumers(payload);
    }
    updateNotification = async () => {
        await this.getRecieverIds();
        let ids = await this.props.consumerList && this.props.consumerList.exportToExcel ? this.props.consumerList.exportToExcel.map(items => items._id.toString()) : null;
        let payload = {
            id: this.props.data._id,
            data: {
                servicesIds: this.state.serviceId,
                distanceInKM: this.state.distance,
                notificationTitle: this.state.title,
                photo: this.state.photos,
                notificationText: this.state.notificationText,
                notificationName: this.state.notificationName,
                scheduleType: this.state.scheduleType ? this.state.scheduleType : "",
                scheduledDate: this.state.scheduledDate ? this.state.scheduledDate : "",
                scheduledTime: this.state.scheduledTime ? this.state.scheduledTime : "",
                interval: this.state.timeLine ? this.state.timeLine : "",
                daysOfWeek: this.state.weekDays.length > 0 ? this.state.weekDays : [],
                daysOfMonth: this.state.monthDays.length > 0 ? this.state.monthDays : [],
                startDate: this.state.recurringStartDate ? this.state.recurringStartDate : "",
                endDate: this.state.recurringEndDate ? this.state.recurringEndDate : "",
                recieverIds: ids
            }
        }
        await this.props.saveNotification(payload);
        await this.props.onClose();
    }
    setData = async () => {
        await this.setState({
            businessFilter: this.props.data.businessId,
            servicesId: this.props.data.serviceIds,
            distance: this.props.data.distanceInKM,
            title: this.props.data.notificationTitle,
            photos: this.props.data.photo,
            notificationText: this.props.data.notificationText,
            notificationName: this.props.data.notificationName,
            scheduleType: this.props.data.scheduleType ? this.props.data.scheduleType : "",
            scheduledDate: this.props.data.scheduledDate ? moment(this.props.data.scheduledDate).format("yyyy-MM-DD") : "",
            scheduledTime: this.props.data.scheduledTime ? this.props.data.scheduledTime : "",
            timeLine: this.props.data.interval ? this.props.data.interval : "",
            weekDays: this.props.data.daysOfWeek.length > 0 ? this.props.data.daysOfWeek : [],
            monthDays: this.props.data.daysOfMonth.length > 0 ? this.props.data.daysOfMonth : [],
            recurringStartDate: this.props.data.startDate ? moment(this.props.data.startDate).format("yyyy-MM-DD") : "",
            recurringEndDate: this.props.data.endDate ? moment(this.props.data.endDate).format("yyyy-MM-DD") : "",
            error: {}
        });
    }
    render() {
        const { classes } = this.props;
        return (
            <Modal
                className={classes.modal}
                open={this.props.open}
                closeAfterTransition>
                <Container className={classes.mainContainer}>
                    <Grid container className={classes.header} spacing={1} >
                        <Grid item xs={8}>
                            <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
                                EDIT NOTIFICATION
                            </WhiteTextTypography>
                        </Grid>
                        <Grid item xs={4}>
                            <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
                                <CloseIcon className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
                            </WhiteTextTypography>
                        </Grid>
                    </Grid>
                    <Container className={classes.modalContainer}>
                        <Grid container className={classes.grid} spacing={1} justify="center" alignItems="center" >
                            <Grid item xs={4}>
                                <Typography>Business Name/Id *</Typography>
                                <TextField fullWidth margin="dense" name="businessFilter" variant="outlined" value={this.state.businessFilter} error={this.state.error.businessFilter} helperText={this.state.error.businessFilter} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography> Service ID(s)(comma separated)</Typography>
                                <TextField fullWidth margin="dense" name="servicesId" variant="outlined" value={this.state.servicesId} error={this.state.error.servicesId} helperText={this.state.error.servicesId} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Distance (In KM)*</Typography>
                                <TextField fullWidth margin="dense" name="distance" variant="outlined" value={this.state.distance} error={this.state.error.distance} helperText={this.state.error.distance} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Notification Title *</Typography>
                                <TextField fullWidth margin="dense" name="title" variant="outlined" value={this.state.title} error={this.state.error.title} helperText={this.state.error.title} onChange={this.handleChange} />

                                <Typography>Notification Image</Typography>
                                <TextField margin="dense" name="photos" variant="outlined" value={this.state.photos && this.state.photos.path ? this.state.photos.path : "No Image attached"} error={this.state.error.photos} helperText={this.state.error.photos} disabled={true} />
                                <Button style={{ marginTop: 10 }} variant="contained" htmlFor="photos" onClick={this.fileUploadAction} color="secondary" component="span" >
                                    Upload
                                </Button>
                                <input
                                    ref={this.inputReference}
                                    id="photos"
                                    name="photos"
                                    style={{ display: 'none' }}
                                    type={"file"}
                                    onChange={this.onFileChange}
                                    accept="image/x-png,image/jpeg"
                                />

                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Notification Text *</Typography>
                                <TextareaAutosize
                                    name="notificationText"
                                    fullWidth
                                    className={classes.textArea}
                                    rowsMin={5}
                                    label="notificationText"
                                    value={this.state.notificationText}
                                    onChange={this.handleChange}
                                />
                                {this.state.error.notificationText ?
                                    <helperText style={{ color: "red" }}>Please Enter Notification Text </helperText> : null}
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Notification Name</Typography>
                                <TextField fullWidth margin="dense" name="notificationName" label="Notification Name" variant="outlined" value={this.state.notificationName} error={this.state.error.notificationName} helperText={this.state.error.notificationName} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={12} className='row'  >
                                <Grid item xs={3}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Schedule Type
                                    </Typography>

                                    <Typography variant="subtitle2" gutterBottom>
                                        {this.state.scheduleType}
                                    </Typography>
                                </Grid>
                                {/* 
                                {this.state.scheduleType === "Once-Now" ?
                                    <Grid xs={7}></Grid>
                                    : null
                                } */}

                                {this.state.scheduleType === "Once-Schedule" ?
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2" gutterBottom>Scheduled On</Typography>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            name="scheduledDate"
                                            type="date"
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="filled"
                                            error={(this.state.scheduledDate && moment().isAfter(this.state.scheduledDate && moment(this.state.scheduledDate).isAfter(moment()))) ? "Pease Select Valid scheduled Date" : null}
                                            helperText={(this.state.scheduledDate && (moment().isAfter(this.state.scheduledDate) && moment(this.state.scheduledDate).isAfter(moment()))) ? "Pease Select Valid scheduled Date" : null}
                                            value={this.state.scheduledDate}
                                            onChange={this.handleChange}
                                        />
                                    </Grid> : null
                                }
                                {this.state.scheduleType === "Once-Schedule" ?
                                    <Grid item xs={1}></Grid>
                                    : null}
                                {this.state.scheduleType === "Once-Schedule" ?
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2" gutterBottom> Time </Typography>
                                        <TextField
                                            name="scheduledTime"
                                            type="time"
                                            fullWidth
                                            margin="dense"
                                            variant="filled"
                                            value ={this.state.scheduledTime}
                                            onChange={this.handleChange}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                step: 300 // 5 min
                                            }}
                                        />
                                    </Grid>
                                    : null
                                }

                                {this.state.scheduleType === 'Recurring' ?
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Recurring
                                        </Typography>

                                        <FormControl variant="outlined" margin="dense" className={classes.formControl} >
                                            <Select
                                                id="timeLine"
                                                value={this.state.timeLine}
                                                name="timeLine"
                                                onChange={this.handleChange}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem value="Daily" style={{ minHeight: "28px", height: "28px" }}>
                                                    <ListItemText className={classes.listItemText} primary="Daily" />
                                                </MenuItem>
                                                <MenuItem value="Weekly" style={{ minHeight: "28px", height: "28px" }}>
                                                    <ListItemText className={classes.listItemText} primary="Weekly" />
                                                </MenuItem>
                                                <MenuItem value="Monthly" style={{ minHeight: "28px", height: "28px" }}>
                                                    <ListItemText className={classes.listItemText} primary="Monthly" />
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : null}
                                {(this.state.scheduleType === "Recurring" && this.state.timeLine === "Weekly") ?
                                    <Grid item xs={3}>
                                        <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                            <InputLabel id="weeklyDays">Select Days</InputLabel>
                                            <Select
                                                labelId="weeklyDays"
                                                multiple
                                                value={this.state.weekDays}
                                                name="weekDays"
                                                onChange={this.handleChange}
                                                input={<Input />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {this.state.daysOfWeek.map((day) => (
                                                    <MenuItem key={day} value={day} style={{ minHeight: "28px", height: "28px" }}>
                                                        <Checkbox checked={this.state.weekDays.indexOf(day) > -1} />
                                                        <ListItemText primary={day} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : null}
                                {(this.state.scheduleType === "Recurring" && this.state.timeLine === "Monthly") ?
                                    <Grid item xs={3}>
                                        <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                            <InputLabel id="monthlyDays">Select Days</InputLabel>
                                            <Select
                                                labelId="monthlyDays"
                                                multiple
                                                value={this.state.monthDays}
                                                name="monthDays"
                                                onChange={this.handleChange}
                                                input={<Input />}
                                                renderValue={(selected) => selected.join(',')}
                                                MenuProps={MenuProps}
                                            >
                                                {this.state.daysOfMonth.map((day) => (
                                                    <MenuItem key={day} value={day} style={{ minHeight: "28px", height: "28px" }}>
                                                        <Checkbox checked={this.state.monthDays.indexOf(day) > -1} />
                                                        <ListItemText primary={day} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : null}
                                {this.state.scheduleType === "Recurring" ?
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2" gutterBottom> Time </Typography>
                                        <TextField
                                            name="scheduledTime"
                                            type="time"
                                            fullWidth
                                            margin="dense"
                                            variant="filled"
                                            onChange={this.handleChange}
                                            value ={this.state.scheduledTime}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                step: 300 // 5 min
                                            }}
                                        />
                                    </Grid>
                                    : null
                                }
                                {this.state.scheduleType === "Recurring" ?
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2" gutterBottom>Start Date</Typography>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            name="recurringStartDate"
                                            type="date"
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="filled"
                                            // error={(this.state.scheduledDate && moment().isAfter(this.state.scheduledDate && moment(this.state.scheduledDate).isAfter(moment()) )) ? "Pease Select Valid scheduled Date" : null}
                                            // helperText={(this.state.scheduledDate && (moment().isAfter(this.state.scheduledDate) && moment(this.state.scheduledDate).isAfter(moment()))) ? "Pease Select Valid scheduled Date" : null}
                                            value={this.state.recurringStartDate}
                                            onChange={this.handleChange}
                                        />
                                    </Grid> : null
                                }
                                {this.state.scheduleType === "Recurring" ?
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2" gutterBottom>End Date</Typography>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            name="recurringEndDate"
                                            type="date"
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="filled"
                                            // error={(this.state.scheduledDate && moment().isAfter(this.state.scheduledDate && moment(this.state.scheduledDate).isAfter(moment()) )) ? "Pease Select Valid scheduled Date" : null}
                                            // helperText={(this.state.scheduledDate && (moment().isAfter(this.state.scheduledDate) && moment(this.state.scheduledDate).isAfter(moment()))) ? "Pease Select Valid scheduled Date" : null}
                                            value={this.state.recurringEndDate}
                                            onChange={this.handleChange}
                                        />
                                    </Grid> : null
                                }
                            </Grid>
                            <Grid item xs={8}></Grid>
                            <Grid item xs={1}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    margin="normal"
                                    onClick={this.setData}
                                >
                                    Reset
                                </Button>
                            </Grid>
                            {/* <Grid item xs={2}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    margin="normal"
                                    onClick={this.fetchConsumers}
                                >
                                    Preview
                                </Button>
                            </Grid> */}
                            <Grid item xs={1}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    margin="normal"
                                    color="primary"
                                    onClick={this.updateNotification}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        consumerList: state.dashboard.consumerList,
        business: state.business,
        env: state.auth.env
    };
};
export default connect(mapStateToProps, { fetchEnvs, getAllConsumers })(withStyles(styles)(EditPushNotification));