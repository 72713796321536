import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';
import MessageIcon from '@material-ui/icons/Message';

// function descendingComparator(a, b, orderBy) {
//     if (b[orderBy] < a[orderBy]) {
//         return -1;
//     }
//     if (b[orderBy] > a[orderBy]) {
//         return 1;
//     }
//     return 0;
// }

// function getComparator(order, orderBy) {
//     return order === 'desc'
//         ? (a, b) => descendingComparator(a, b, orderBy)
//         : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//     const stabilizedThis = array.map((el, index) => [el, index]);
//     stabilizedThis.sort((a, b) => {
//         const order = comparator(a[0], b[0]);
//         if (order !== 0) return order;
//         return a[1] - b[1];
//     });
//     return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
    { id: "range", disablePadding: false, label: "Duration" },
    { id: "deliveryPartner", disablePadding: false, label: "Delivery Partner" },
    { id: "totalPartnerDeliveries", disablePadding: false, label: "Partner Deliveries" },
    { id: "totalPartnerDeliveryCharges", disablePadding: false, label: "Partner Delivery Charges" },
    { id: "dueStatus", disablePadding: false, label: "Due Status" },
    { id: "settlementNotes", disablePadding: false, label: "Notes" }
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    // const createSortHandler = (property) => (event) => {
    //     onRequestSort(event, property);
    // };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableCellSmall}
                    >

                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Deliveries ({props.total})
            </Typography>

            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={`${props.businessName}SettlmentRecords.csv`}
                        className="btn btn"
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                    :
                    <Button
                        variant="contained"
                        margin="normal"
                        style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                        onClick={props.fetchWithExcelFile}
                    >
                        Export
                    </Button>
                }
            </Typography>
        </Toolbar>
    );
};

/* EnhancedTableToolbar.propTypes = {
    exportToExcel: PropTypes.func.exportToExcel,
    total: PropTypes.number.isRequired
}; */

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px",
        flexDirection: "row"
    },
    tableRow: {
        // cursor: 'pointer'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();

    const handleRequestSort = (event, property) => {
        const isAsc = props.orderBy === property && props.order === 'asc';
        props.setOrder(isAsc ? -1 : 1);
        props.setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };
   
    let headers = [
        { label: 'rangeDate', key: 'range' },
        { label: 'Delivery Partner', key: 'deliveryPartner' },
        { label: 'Total Partner Deliveries', key: 'totalPartnerDeliveries' },
        { label: 'Total Partner Delivery Charges', key: 'totalPartnerDeliveryCharges' },
        { label: 'Due Status', key: 'status' },
        { label: 'Settlement Date', key: 'settlementDate' }
        
    ];

    const getRowSpanForSettleButton = (data, index) =>{
      if(data[index+1] && data[index].rangeStartDate === data[index+1].rangeStartDate && data[index].rangeEndDate === data[index+1].rangeEndDate && ((data[index].deliveryPartner !== 'porter' && data[index+1].deliveryPartner !== 'porter') && (data[index].deliveryPartner !== 'shadowfax' && data[index+1].deliveryPartner !== 'shadowfax'))) {
        return 2;
      }else if(data[index-1] && data[index].rangeStartDate === data[index-1].rangeStartDate && data[index].rangeEndDate === data[index-1].rangeEndDate  && ((data[index].deliveryPartner !== 'porter' && data[index-1].deliveryPartner !== 'porter') && (data[index].deliveryPartner !== 'shadowfax' && data[index-1].deliveryPartner !== 'shadowfax'))) {
        return 0;
      }
      return 1;
    }
    
    let data = props && props.exportToexcel && props.exportToexcel.exportToExcel ;
    data && data.forEach(record => {
        record.settlementDate = (record.settlementDate && record.settlementDate!=="Invalid date" && record.settlementDate!==undefined && record.settlementDate!=="NA")? moment(record.settlementDate).format('DD MMM YYYY') : "NA"
        // record.settlementDate = ( record.settlementDate && record.settlementDate !== "Invalid date" && moment(record.settlementDate)) ? moment( record.settlementDate).format('DD MMM YYYY hh:mm A') : "NA"
        record.range = moment(record.rangeStartDate).format('DD MMM') + "-" + moment(record.rangeEndDate).format('DD MMM YYYY')
        record.status =record.dueStatus? "PAID" : "UNPAID"
        //record.settlementDate = record.settlementDate? moment(record.settlementDate).format('DD MMM YYYY') : "NA"
    });
    // const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.total - props.page * props.rowsPerPage);

    // console.log("props data --->", props.data)
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    total={props.total}
                    headers={headers}
                    data={ (props && props.exportToexcel && props.exportToexcel.exportToExcel) ?  props.exportToexcel.exportToExcel : []}
                    showDownloadIcon={props.showDownloadIcon}
                    fetchWithExcelFile={props.fetchWithExcelFile}
                    businessName={props.businessName.replace( /\s/g, '')}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={props.order}
                            orderBy={props.orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={props.total}
                        />
                        <TableBody>
                            {
                                // .slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                                props.data.map((row, index) => {
                                    // console.log("row --->", row)
                                    // console.log("index --->", index)
                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.serviceRequestId}
                                        >
                                            <TableCell component="th" scope="row" className={classes.tableCellSmall}>
                                                 {moment(row.rangeStartDate).format("DD MMMM ") +"-" +moment.utc(row.rangeEndDate).format(" DD MMMM YYYY")}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.deliveryPartner}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.totalPartnerDeliveries}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.totalPartnerDeliveryCharges}</TableCell>
                                            {row.deliveryPartner && row.deliveryPartner !== null && row.deliveryPartner !== '' && row.deliveryPartner && row.deliveryPartner !== undefined && (row.deliveryPartner === 'porter' || row.deliveryPartner === 'shadowfax') ? <TableCell align="left" className={classes.tableCellSmall}></TableCell> : 
                                            <TableCell align="left" className={classes.tableCellSmall} rowSpan={getRowSpanForSettleButton(props.data, index)}>
                                              {index>0 && props.data[index] && props.data[index - 1] && props.data[index].rangeStartDate === props.data[index - 1].rangeStartDate && props.data[index].rangeEndDate === props.data[index - 1].rangeEndDate &&((props.data[index].deliveryPartner !== 'porter' && props.data[index - 1].deliveryPartner !== 'porter') || (props.data[index].deliveryPartner !== 'shadowfax' && props.data[index - 1].deliveryPartner !== 'shadowfax')) ? null :
                                                (
                                                  <>
                                                    {row.dueStatus ? <Typography style={{ color: "green" }}>Settled on {row.settlementDate ? moment(row.settlementDate).format("DD/MM/YYYY") : "NA"}</Typography> : <Typography style={{ color: "red" }}>Not Settled</Typography>}
                                                    {row.dueStatus ? null
                                                      : (<Button
                                                        variant="contained"
                                                        margin="normal"
                                                        color="primary"
                                                        onClick={
                                                          () => {
                                                            if(props.data[index + 1] && props.data[index].rangeStartDate === props.data[index + 1].rangeStartDate && props.data[index].rangeEndDate === props.data[index + 1].rangeEndDate && ((props.data[index].deliveryPartner !== 'porter' && props.data[index + 1].deliveryPartner !== 'porter') && (props.data[index].deliveryPartner !== 'shadowfax' && props.data[index + 1].deliveryPartner !== 'shadowfax'))){
                                                              props.openSettleModal([row, props.data[index+1]]);
                                                            }else {
                                                              props.openSettleModal([row]);
                                                            }
                                                          }
                                                        }>SETTLE</Button>
                                                      )}
                                                  </>
                                                )}
                                            </TableCell> }
                                            {row.deliveryPartner && row.deliveryPartner !== null && row.deliveryPartner !== '' && row.deliveryPartner && row.deliveryPartner !== undefined && (row.deliveryPartner === 'porter' || row.deliveryPartner === 'shadowfax') ? <TableCell align="left" className={classes.tableCellSmall}></TableCell> : 
                                            <TableCell align="left" className={classes.tableCellSmall} rowSpan={getRowSpanForSettleButton(props.data, index)}>
                                              {index > 0 && props.data[index] && props.data[index - 1] && props.data[index].rangeStartDate === props.data[index - 1].rangeStartDate && props.data[index].rangeEndDate === props.data[index - 1].rangeEndDate && ((props.data[index].deliveryPartner !== 'porter' && props.data[index - 1].deliveryPartner !== 'porter') || (props.data[index].deliveryPartner !== 'shadowfax' && props.data[index - 1].deliveryPartner !== 'shadowfax')) ? null :
                                                <MessageIcon
                                                  margin="normal"
                                                  onClick={() => {
                                                    if(props.data[index+1] && props.data[index].rangeStartDate === props.data[index + 1].rangeStartDate && props.data[index].rangeEndDate === props.data[index + 1].rangeEndDate && ((props.data[index].deliveryPartner !== 'porter' && props.data[index + 1].deliveryPartner !== 'porter') || (props.data[index].deliveryPartner !== 'shadowfax' && props.data[index - 1].deliveryPartner !== 'shadowfax'))){
                                                    props.openNotesModal([row, props.data[index+1]])
                                                    }else {
                                                    props.openNotesModal([row])
                                                    }
                                                  }
                                                  } />
                                              }
                                              {index > 0 && props.data[index] && props.data[index - 1] && props.data[index].rangeStartDate === props.data[index - 1].rangeStartDate && props.data[index].rangeEndDate === props.data[index - 1].rangeEndDate && ((props.data[index].deliveryPartner !== 'porter' && props.data[index - 1].deliveryPartner !== 'porter') || (props.data[index].deliveryPartner !== 'shadowfax' && props.data[index - 1].deliveryPartner !== 'shadowfax')) ? null :
                                                <>{row.settlementNotes && row.settlementNotes.length ? row.settlementNotes.length : "0"}
                                                </>}
                                            </TableCell>}
                                        </TableRow>

                                    );
                                })}
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={props.total}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
