import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import { lightBlue } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";

import {
  setCartLevelDiscount,getBusinessById
} from "../../../actions/businessActions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const styles = (theme) => ({
  Checkbox: {
    fontSize: 20,
  },
  grid: {
    width: "100%",
    //display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    margin: "0px 0px",
  },
  deleteBtn: {
    cursor: "pointer",
    color: "grey",
    //marginTop: "7px"
  },
  header: {
    height: "10%",
    margin: "0px 0px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "22px",
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    height: "73%",
    margin: "10px 0px",
    overflow: "auto",
  },
  footer: {
    height: "10%",
    margin: "0px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headGridTwo: {
    float: "right",
    marginRight: "2px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
  },
  modalContainer: {
    width: "100%",
    height: "100%",
    padding: "0px 0px",
    backgroundColor: theme.palette.background.paper,
    margin: "10px 0px",
    overflow: "auto",
  },
  gridElement: {
    margin: "10px 5px",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  icon: {
    color: "white",
  },
  gridList: {
    width: "100%",
    height: "100%",
  },
  mainContainer: {
    width: "50%",
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    padding: "0px 0px",
  },
  tableCellSmall: {
    fontSize: "12px",
    padding: "6px 0px 6px 16px",
  },
  head: {
    fontWeight: 700,
    fontSize: "12px",
  },
  input: {
    height: 30,
    padding: "0px",
  },
  button: {
    height: 30,
  },
  lightBlue: {
    color: theme.palette.getContrastText(lightBlue[50]),
    backgroundColor: lightBlue[50],
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  select: {
    height: "38px",
    // padding-right: '20px',
    // overflow: 'hidden',
    // display: 'flex',
  },
  listItemText: {
    margin: "0px",
  },
  buttonGroup: {
    height: "44px",
    marginTop: "9px",
    width: "0px",
  },
  buttonGroupTextFeild: {
    height: "40px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  // formControlHead: {
  // 	// minWidth: 120,
  //     // maxWidth: 300,
  // 	overflow: "hidden"
  // }
});

function CartLevelDiscount(props) {
  const [selectedDiscount, setSelectedDiscount] = useState("NO");
  const [discountMappingArrayError, setDiscountMappingArrayError] = useState([]);
  const [discountMappingArray, setDiscountMappingArray] = useState([]);
  const [isBothOffersActive, setIsBothOffersActive] = useState(false);
  const [isServiceOffersAvailable, setIsServiceOffersAvailable] = useState(false);
  const [minimumCartValue, setMinimumCartValue] = useState(null);
  const [minimumCartValueError, setMinimumCartValueError] = useState("");

  const { classes } = props;

  useEffect(() => {
    let discountObj=props.businessData.businessOffers.discount
    if(discountObj.discountMinCartVal)setMinimumCartValue(discountObj.discountMinCartVal)
    if(discountObj.discountWithServiceOffers)setIsBothOffersActive(discountObj.discountWithServiceOffers)
    if(discountObj.isServiceOffersAvailable)setIsServiceOffersAvailable(discountObj.isServiceOffersAvailable)
    if(discountObj.offers.length>0){
      setDiscountMappingArray(discountObj.offers)
      discountObj.offers.map((item,index)=>{
        if(item.isActive===true){
            setSelectedDiscount(index)
        }
      })
    }

  }, [props.businessData]);

  // useEffect(() => {
  //   getBusiness()
  // }, [])

  const validate = async() => {
    let updatedDiscountMappingArrayError = [];
    await discountMappingArray.map(async (item, index) => {
      if (!item.off || item.off === null || item.off === "") {
        updatedDiscountMappingArrayError[index] = "Please Enter valid Amount";
      } else {
        updatedDiscountMappingArrayError[index] = "";
      }
    });
    await setDiscountMappingArrayError(updatedDiscountMappingArrayError);
    if(minimumCartValue===null || minimumCartValue===""){
      setMinimumCartValueError("Please enter valid amount")
    } else {
      setMinimumCartValueError("")
    }
  };

  const getBusiness = () => {
    let payload = {
      businessId: props.businessData._id,
    };
     props.getBusinessById(payload);
  }

  const saveDiscount = async () => {
    let updatedDiscountMappingArrayError = [];
    let updatedMinimumCartValueError = "";

     discountMappingArray.map((item, index) => {
      if (!item.off || item.off === null || item.off === "") {
        updatedDiscountMappingArrayError[index] = "Please Enter valid Amount";
      } else {
        updatedDiscountMappingArrayError[index] = "";
      }
    });
     setDiscountMappingArrayError(updatedDiscountMappingArrayError);
    if(minimumCartValue===null || minimumCartValue===""){
      setMinimumCartValueError("Please enter valid Amount")
      updatedMinimumCartValueError="Please enter valid Amount"
    } else {
      setMinimumCartValueError("")
      updatedMinimumCartValueError=""
    }
    let offerArray = [];
    discountMappingArray.map((item) => {
      let object = {
        discountType: item.discountType,
        isActive: item.isActive,
        maxDiscountCap: item.maxDiscountCap,
        off: item.off,
      };
      offerArray.push(object);
    });

    let payload = {
      discount: {
        isServiceOffersAvailable: isServiceOffersAvailable,
        offers: offerArray,
        discountMinCartVal: minimumCartValue,
        discountWithServiceOffers: isBothOffersActive,
      },
      businessId:props.businessData._id
    };

    let errorCount = updatedDiscountMappingArrayError && updatedDiscountMappingArrayError.filter((ele)=>{
      return ele!==""
    })
    if(errorCount.length===0 && updatedMinimumCartValueError===""){
     await props.setCartLevelDiscount(payload)
     getBusiness()
    }
  };

  const addDiscountMappingArrayItem = () => {
    let discountMappingArrayCopy = discountMappingArray;
    let object = {
      discountType: "PERCENTAGE",
      off: null,
      maxDiscountCap: null,
      isActive: false,
      id: discountMappingArray.length + 1,
    };
    discountMappingArrayCopy.push(object);
    setDiscountMappingArray([...discountMappingArrayCopy]);
    setDiscountMappingArrayError([]);
  };

  const deleteDiscountMappingArrayItem = (index) => {
    let discountMappingArrayCopy = discountMappingArray;
    discountMappingArrayCopy.splice(index, 1);
    setDiscountMappingArray([...discountMappingArrayCopy]);
    setDiscountMappingArrayError([]);
    if (index === selectedDiscount) {
      setSelectedDiscount("NO");
    } else if (index <= selectedDiscount) {
      setSelectedDiscount(selectedDiscount-1);
    }
  };

  const handleDiscountChange = (event, index) => {
    let discountMappingArrayCopy = discountMappingArray;
    discountMappingArrayCopy[index][event.target.name] = event.target.value;
    setDiscountMappingArray([...discountMappingArrayCopy]);
  };

  const handleDiscountChangeRadio = (event, index) => {
    setSelectedDiscount(index);
    let updateDiscountMappingArray = discountMappingArray.map(
      (item, indexNo) => {
        if (index === indexNo) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
        return item;
      }
    );
    setDiscountMappingArray([...updateDiscountMappingArray]);
    setDiscountMappingArrayError([]);
  };

  return (
    <div>
      <Grid style={{ marginTop: "30px" }} item xs={12}>
        <Card>
          <CardContent>
            <Grid
              container
              className={classes.grid}
              spacing={1}
              justify="center"
              alignItems="center"
            >
              <Grid style={{ marginBottom: "20px" }} item xs={12}>
                <Typography variant="subtitle2" display="block" gutterBottom>
                  Discount Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" gutterBottom>
                  Enter Minimum value to enable below discount
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  margin="dense"
                  name="minCartValue"
                  label="Minimum Cart Value"
                  variant="outlined"
                  error={
                    minimumCartValueError
                  }
                  helperText={
                    minimumCartValueError
                  }
                  value={minimumCartValue}
                  onChange={(e) => {
                    setMinimumCartValue(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid style={{ marginTop: "10px" }} item xs={1}>
                <Radio
                  checked={isBothOffersActive}
                  color="primary"
                  onChange={(e) => setIsBothOffersActive(true)}
                  value={"NO"}
                  name="discountType"
                  style={{ fontSize: "12px", float: "left", margin: "0 10px" }}
                />
              </Grid>
              <Grid style={{ marginTop: "10px" }} item xs={11}>
                <label
                  style={{
                    fontSize: "16px",
                    float: "left",
                    marginLeft: "12px",
                  }}
                >
                  Apply both service level and following cart evel discounts
                  combined
                </label>
              </Grid>
              <Grid item xs={1}>
                <Radio
                  checked={!isBothOffersActive}
                  color="primary"
                  onChange={(e) => setIsBothOffersActive(false)}
                  name="discountType"
                  style={{ fontSize: "12px", float: "left", margin: "0 10px" }}
                />
              </Grid>
              <Grid item xs={11}>
                <label
                  style={{
                    fontSize: "16px",
                    float: "left",
                    marginLeft: "12px",
                  }}
                >
                  Remove service level discounts and apply only following cart
                  level discounts
                </label>
              </Grid>
              <Grid style={{ marginTop: "30px" }} item xs={12}>
                <Typography variant="caption" gutterBottom>
                  Select an offer to activate or no offer to deactivate
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Radio
                  checked={"NO" === selectedDiscount}
                  color="primary"
                  onChange={(e) => handleDiscountChangeRadio(e, "NO")}
                  name="discountType"
                  style={{ fontSize: "12px", float: "left", margin: "10px" }}
                />
              </Grid>
              <Grid item xs={8}>
                <label
                  style={{
                    fontSize: "16px",
                    float: "left",
                    marginLeft: "12px",
                    marginTop: "10px",
                  }}
                >
                  No Offer
                </label>
              </Grid>
              <Grid item xs={3}>
                {discountMappingArray.length < 3 ? (
                  <Button
                    style={{
                      float: "right",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    variant="outlined"
                    htmlFor="photos"
                    color="primary"
                    component="span"
                    onClick={() => addDiscountMappingArrayItem()}
                  >
                    ADD
                  </Button>
                ) : null}
              </Grid>
              {discountMappingArray.map((item, index) => {
                return (
                  <React.Fragment key={item.id}>
                    <Grid item xs={1}>
                      <Radio
                        checked={index === selectedDiscount}
                        color="primary"
                        onChange={(e) => handleDiscountChangeRadio(e, index)}
                        value={index}
                        name="discountType"
                        style={{
                          fontSize: "12px",
                          float: "left",
                          margin: "10px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Discount Type *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="discountType"
                          value={discountMappingArray[index].discountType}
                          name="discountType"
                          onChange={(e) => {
                            handleDiscountChange(e, index);
                          }}
                          MenuProps={MenuProps}
                          style={{ margin: "10px" }}
                          className={classes.select}
                        >
                          <MenuItem
                            value="PERCENTAGE"
                            style={{ minHeight: "28px", height: "28px" }}
                          >
                            <ListItemText
                              className={classes.listItemText}
                              primary="PERCENTAGE"
                            />
                          </MenuItem>
                          <MenuItem
                            value="FLAT"
                            style={{ minHeight: "28px", height: "28px" }}
                          >
                            <ListItemText
                              className={classes.listItemText}
                              primary="FLAT"
                            />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        type="number"
                        style={{ marginLeft: "5px", marginRight: "5px" }}
                        margin="dense"
                        name="off"
                        label="Off*"
                        variant="outlined"
                        error={
                          discountMappingArrayError &&
                          discountMappingArrayError[index]
                        }
                        helperText={
                          discountMappingArrayError &&
                          discountMappingArrayError[index]
                        }
                        value={discountMappingArray[index].off}
                        onChange={(e) => {
                          handleDiscountChange(e, index);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      {discountMappingArray[index].discountType ===
                      "FLAT" ? null : (
                        <TextField
                          type="number"
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                          margin="dense"
                          name="maxDiscountCap"
                          label="Max"
                          variant="outlined"
                          value={discountMappingArray[index].maxDiscountCap}
                          onChange={(e) => {
                            handleDiscountChange(e, index);
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={1}>
                      <Button>
                        <Avatar className={classes.lightBlue}>
                          <DeleteIcon
                            color="action"
                            className={classes.deleteBtn}
                            onClick={() =>
                              deleteDiscountMappingArrayItem(index)
                            }
                          />
                        </Avatar>
                      </Button>
                    </Grid>
                  </React.Fragment>
                );
              })}
              <Grid item xs={10}></Grid>
              {/* <Grid item xs={2}>
                <Button
                  style={{ margin: "15px 10px" }}
                  fullWidth
                  variant="contained"
                  onClick={()=>getBusiness()}
                >
                  Reset
                </Button>
              </Grid> */}
              <Grid item xs={2}>
                <Button
                  fullWidth
                  style={{ margin: "15px 10px" }}
                  variant="contained"
                  color="primary"
                  onClick={() => saveDiscount()}
                >
                  SAVE
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}

export default connect(null, {setCartLevelDiscount,getBusinessById})(withStyles(styles)(CartLevelDiscount));
