import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import { lightBlue } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ObjectID } from 'bson';
import _ from 'lodash';
import { couponSettlementById } from '../../actions/campaignActions';
import moment from "moment";
import UploadCampaignPhoto from "./uploadCampaignPhoto";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};
const styles = theme => ({
	Checkbox: {
		fontSize: 20
	},
	grid: {
		width: '100%',
		//display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: "15px",
		paddingRight: "15px",
		margin: '0px 0px',

	},
	deleteBtn: {
		cursor: 'pointer',
		color: 'grey',
		//marginTop: "7px"
	},
	header: {
		height: '10%',
		margin: '0px 0px',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: "22px",
		backgroundColor: theme.palette.primary.main,
	},
	content: {
		height: '73%',
		margin: '10px 0px',
		overflow: 'auto',
	},
	footer: {
		height: '10%',
		margin: '0px 0px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	headGridTwo: {
		float: 'right',
		marginRight: '2px'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0px'
	},
	modalContainer: {
		width: '100%',
		height: '100%',
		padding: '20px',
		backgroundColor: theme.palette.background.paper,

	},
	gridElement: {
		margin: '10px 5px'
	},
	img: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	icon: {
		color: 'white',
	},
	gridList: {
		width: '100%',
		height: '100%'
	},
	mainContainer: {
		width: '40%',
		height: '60%',
		backgroundColor: theme.palette.background.paper,
		padding: '0px 0px',

	},
	tableCellSmall: {
		fontSize: "12px",
		padding: "6px 0px 6px 16px"
	},
	head: {
		fontWeight: 700,
		fontSize: "12px",
	},
	input: {
		height: 30,
		padding: '0px'
	},
	button: {
		height: 30
	},
	lightBlue: {
		color: theme.palette.getContrastText(lightBlue[50]),
		backgroundColor: lightBlue[50],
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
	select: {
		height: '38px'
	},
	listItemText: {
		'margin': '0px'
	},
	buttonGroup: {
		"height": "44px",
		"marginTop": "9px",
		"width": "0px"
	},
	buttonGroupTextFeild: {
		"height": "40px"
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	}
});

const WhiteTextTypography = withStyles({
	root: {
		color: "#FFFFFF"
	}
})(Typography);

class couponSettleAmountModal extends Component {
	constructor() {
		super();
		this.state = {
			confirmCheckBox: false,
			date: "",
			notes: "",
			dateError : "",
			settlementImage: "",
			modalDeleteOpen: false, 
			attachment: "",
		};

	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		if(this.state.dateError){
			this.setState({ dateError : "" });
		}
	}

	componentDidMount(){
        this.setState({attachment:  ""});
    }

	clearData = () => {
		this.setState({
			confirmCheckBox: false,
			date: "",
			notes: "",
			attachment: "",
		})

	}
	save = async () => {
		let flag = await this.validate();
		if (parseInt(flag)){
		let payload = {
			couponSettlementId: this.props.data.couponSettlementId,
			dueStatus: true,
			settlementNote: this.state.notes,
			settlementDate: this.state.date,
			attachment: this.state.attachment,
		}
		await this.props.couponSettlementById(payload);
		this.saveAndClose();
	}
	}
	saveAndClose = () => {
		this.clearData();
		this.props.saveAndClose();
	}
	close = () => {
		this.clearData();
		this.props.onClose();
	}
	handleCheck = (event) => {
		this.setState({ confirmCheckBox: event.target.checked })
	}
	validate = async ()=>{
		let dateError = this.state.dateError;
		if(this.state.date && moment(this.state.date).isAfter(moment())){
		dateError="You can't select date from future";
		await this.setState({dateError});
		return 0;
		}
		return 1 ;
	}
	handleDeleteClose = () => {
        this.setState({ modalDeleteOpen: false });
    }
	handleDeleteOpen = (photo) => {
        this.setState({ modalDeleteOpen: true });
        this.setState({ selectedPhoto: photo });
    }
	updateSettlementPhotos = async (files, photoType) => {
        // console.log("files--->", files," phototype--->", photoType);
        let data = new FormData();
        let folderPath = "Marketplace_Business";
        let temp = files[0].name.split(".");
        let fileName = temp[0] + Math.ceil(Math.random() * 999999) + "." + temp[1];
    
        data.append("file", files[0]);
        data.append("folder", folderPath);
        data.append(
            "upload_preset",
            this.props.envs.env.cloudinaryBusinessImagesUploadPreset
        );
        let res = await fetch(this.props.envs.env.cloudinaryUploadUrl, {
            method: "POST",
            body: data,
        });
        let file = await res.json();
        if (photoType === "CampaignPromoCode") {
        let attachment = file.secure_url;    
        this.setState({ attachment });
        this.setState({ isUploading: false, typePhoto: photoType });
        }
    };
	deleteSettlementPhoto = async (selectedPhoto, photoType) => {    
        if (photoType === "CampaignPromoCode") {
            this.state.attachment = ""
            this.setState({
				attachment: "",
            modalDeleteOpen: false 
            });
        }
    };
	render() {
		const { classes } = this.props;
		// console.log("data in coupon settlement modal--->",this.props)
		return (
			<Modal
				className={classes.modal}
				open={this.props.open}
				closeAfterTransition>
				<Container className={classes.mainContainer}>
					<Grid container className={classes.header} spacing={1} >
						<Grid item xs={8}>
							<WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
								Settle Dues
							</WhiteTextTypography>
						</Grid>
						<Grid item xs={4}>
							<WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
								<CloseIcon className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
							</WhiteTextTypography>
						</Grid>
					</Grid>
					<Container className={classes.modalContainer}>
						<Grid container spacing={1} >
							<Grid item xs={6}>
								<Typography variant="subtitle2" gutterBottom>
									Confirm *
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle2" gutterBottom>
									Settlement Date *
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<FormControl component="fieldset">
									<FormControlLabel
										//={this.state.allowLooseQuantity}
										control={<Checkbox color="primary" onChange={this.handleCheck} />}
										label="Confirm the dues are settled"
										className={classes.Checkbox}
										labelPlacement="end"
									/>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									margin="dense"
									name="date"
									type="date"
									variant="outlined"
									className={classes.textField}
									InputLabelProps={{
										shrink: true,
									}}
									error={this.state.dateError}
									helperText={this.state.dateError}
									value={this.state.date}
									onChange={this.handleChange}
								/>
							</Grid>
							{/* Upload Campaign Image button */}
							<Grid item xs={12}>
                            	<Typography variant="subtitle2" gutterBottom>
									Upload Image
								</Typography>
                        </Grid>
							<Grid item xs={12}>
                            <UploadCampaignPhoto
                                uploadButtonName = "Choose File"
                                updateCampaignPhotos = {this.updateSettlementPhotos}
                                photoType="CampaignPromoCode"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {(this.state.attachment != "" ) ?
                                    <GridList cols={7} cellHeight={80} className={classes.gridList}>
                                        <GridListTile
                                            className={classes.gridElement} 
                                        >
                                            <a href={this.state.attachment} target="_blank" rel="noopener noreferrer">
                                            <img border="0" className={classes.img} src={this.state.attachment} alt="" />
                                            </a>
                                            <GridListTileBar  
                                            titlePosition="bottom"
                                            actionIcon={
                                                <IconButton className={classes.icon} 
                                                onClick={() => this.handleDeleteOpen(this.state.attachment)}
                                                >
                                                <DeleteIcon />
                                                </IconButton>
                                            }
                                            actionPosition="right"
                                            />
                                        </GridListTile>
                                    ))
                                    </GridList >
                            : null}
                        
                        </Grid>
							<Grid item xs={12}>
								<Typography variant="subtitle2" gutterBottom>
									Notes *
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									margin="dense"
									name="notes"
									variant="outlined"
									className={classes.textField}
									InputLabelProps={{
										shrink: true,
									}}
									// error={this.state.error && this.state.error.date}
									// helperText={(this.state.error && this.state.error.date) ? this.state.error.date : null}
									value={this.state.notes}
									onChange={this.handleChange}
								/>
							</Grid>
							<Grid item xs={4}></Grid>
							<Grid item xs={4}>
								<Button
									fullWidth
									style={{ margin: "5px" }}
									variant="contained"
									onClick={() =>{this.props.onClose()}}
								>
									Cancel
								</Button>
							</Grid>
							<Grid item xs={4}>
								<Button
									fullWidth
									disabled={(this.state.confirmCheckBox === true && this.state.date && this.state.notes) ? false : true}
									style={{ margin: "5px" }}
									variant="contained"
									color="primary"
									onClick={this.save}
								>
									Save
								</Button>
							</Grid>
						</Grid>
					</Container>
					<Dialog open={this.state.modalDeleteOpen} onClose={this.handleDeleteClose}>
				<DialogTitle>Delete Photo</DialogTitle>
				<DialogContent>
					<DialogContentText>
					Do you want to delete this photo?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleDeleteClose} color="primary"> No </Button>
					<Button onClick={() => this.deleteSettlementPhoto(this.state.selectedPhoto, this.state.typePhoto)} color="primary" autoFocus> Yes </Button>
				</DialogActions>
				</Dialog>
				</Container>
				
			</Modal>
			
		);
	}
}
const mapStateToProps = (state) => {
	return {
		dashboard: state.dashboard,
		business: state.business,
		user: state.auth.user,
		admin: state.admin,
		categories: state.categories,
		env: state.auth.env,
		campaign: state.campaign,
	};
};
export default connect(mapStateToProps, {
	couponSettlementById
})(withStyles(styles)(couponSettleAmountModal));