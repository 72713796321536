import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        marginTop: '10px',
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MultipleSelect(props) {
    const classes = useStyles();

    const handleChange = (event) => {
        props.handleChange(event);
    };

    return (
        <div>
            <FormControl variant="outlined" fullWidth className={classes.formControl} style={{ marginTop: props.disablePadding ? '0px' : '10px' }}>
                <InputLabel id="demo-mutiple-checkbox-label">{props.label}</InputLabel>
                <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={props.selectedOptions}
                    name={props.name}
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {props.data.map((item) => (
                        <MenuItem key={item._id} value={item.name}>
                            <Checkbox checked={props.selectedOptions.indexOf(item.name) > -1} />
                            <ListItemText primary={item.name} />
                        </MenuItem>
                    ))}
                </Select>
                {
                    !props.avoidErrorMsg ?
                        props.selectedOptions.length < 1 ? <FormHelperText style={{ color: 'red' }}>Required</FormHelperText> : null : null
                }
            </FormControl>
        </div>
    );
}
