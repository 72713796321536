import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import "bootstrap-daterangepicker/daterangepicker.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Badge } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import { lightBlue } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Avatar from "@material-ui/core/Avatar";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { changeHeaderName, updateConsumerAddress, addDeleteBusiness, getOneConsumer } from "../../actions/dashboardActions";
import EditConsumerAddress from "./EditConsumerAddress";
import OpenBusinessAsPerAddress from "./OpenBusinessAsPerAddress";
import { ReactComponent as LINK } from '../../images/Group 33893.svg';


const styles = (theme) => ({
  container: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    height: "100%",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
    display: "flex",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  circle: {
    height: "25px",
    width: "25px",
    backgroundColor: "#52B33A",
    borderRadius: "50%",
    display: "inline-block",
  },
  lightBlue: {
    color: theme.palette.getContrastText(lightBlue[50]),
    backgroundColor: lightBlue[50],
    width: theme.spacing(4),
    height: theme.spacing(4),
    cursor: "pointer",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "150px",
    width: "100%",
  },
});

 function ConsumerAddressDetails(props) {
  const { classes } = props;
  const [isDeleteAddressModalOpen, setIsDeleteAddressModalOpen] = useState(false);
  const [isDeleteDefaultAddressModalOpen, setIsDeleteDefaultAddressModalOpen] = useState(false);
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);
  const [isOpenBusinessList, setIsOpenBusinessList] = useState(false);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
  const [deleteAddressIndex, setDeleteAddressIndex] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [muiAlert, setMuiAlert] = useState(null);
  const [selectedConsumer, setSelectedConsumer] = useState(props.location.state.consumerObj);
  // const [successMsg, setSuccessMsg] = useState(null);
  // const [errMsg, setErrMsg] = useState(props.dashboard.errMsg);
  
  // console.log("this.props on ConsumerAddressDetails ---->",props.dashboard);

  useEffect(() => {
    setIsFetching(true);
    props.changeHeaderName("Consumer Address Details");
    setSelectedConsumer(props.location.state.consumerObj);
    async function fetchData() {
      await props.getOneConsumer({ id: selectedConsumer._id });
    }
    fetchData();
  }, []);
  

  const handleDeleteAddressClick = (e, index) => {
    if (selectedConsumer.address[index].isDefaultAddress === true) {
      setIsDeleteDefaultAddressModalOpen(true);
    } else {
      setIsDeleteAddressModalOpen(true);
      setDeleteAddressIndex(index);
    }
  };

  const handleDeleteAddress = () => {
    let updatedArray = JSON.parse(JSON.stringify(selectedConsumer.address));
    updatedArray.splice(deleteAddressIndex, 1);
    updateAddressArrayForConsumer(updatedArray);
    setIsDeleteAddressModalOpen(false);
  };

  const handleEditAddressClick = (e, index) => {
    setIsEditAddressModalOpen(true);
    setSelectedAddressIndex(index);
  };
  const hadleOpenBusinessList = (e, index) => {
    setIsOpenBusinessList(true);
    setSelectedAddressIndex(index);
  };

  const updateAddressArrayForConsumer = async (addressArray,selectedAddressIndex) => {
    setIsFetching(true);

    const response = await props.updateConsumerAddress({
      id: selectedConsumer._id,
      addressArray: addressArray,
      index: selectedAddressIndex
    });

    await props.getOneConsumer({ id: selectedConsumer._id });
    if (response === "success") {
      setMuiAlert(
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => {
            setIsSnackOpen(false);
          }}
          severity="success"
        >
          {"Address updated successfully"}
        </MuiAlert>
      );
      setIsSnackOpen(true);
    } else {
      setMuiAlert(
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => {
            setIsSnackOpen(false);
          }}
          severity="error"
        >
          {"Error while setting address"}
        </MuiAlert>
      );
      setIsSnackOpen(true);
    }
  };  

  useEffect(() => {
    setIsFetching(false);
    if (props.dashboard.selectedConsumer) {
      setSelectedConsumer(props.dashboard.selectedConsumer);
    }
  }, [props.dashboard.selectedConsumer]);


   const saveAddDeleteBusiness = async (payload) => {
     setIsFetching(true);
     let response = await props.addDeleteBusiness(payload);
     await props.getOneConsumer({ id: selectedConsumer._id });
    // console.log("response in component ------->", response);
     if (response && response.success !== null && response.success !== undefined && response.success !== "") {
      if(response.success === "Business deleted successfully" || response.success ==="Business added successfully"){
        setMuiAlert(
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => {
              setIsSnackOpen(false);
            }}
            severity="success"
          >
            {response.success}
          </MuiAlert>
        );
        setIsSnackOpen(true);
      }
      if(response.success === "Business is not in servicible area") {
        setMuiAlert(
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => {
              setIsSnackOpen(false);
            }}
            severity="error"
          >
            {response.success}
          </MuiAlert>
        );
        setIsSnackOpen(true);
      }
      
     }
     else {
       setMuiAlert(
         <MuiAlert
           elevation={6}
           variant="filled"
           onClose={() => {
             setIsSnackOpen(false);
           }}
           severity="error"
         >
           {"Failed To Update Business List"}
         </MuiAlert>
       );
       setIsSnackOpen(true);
     }

   };
  return (
    <Container className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container className={classes.container} style={{ paddingBottom: "0px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            Consumer Name:
            <b style={{ marginLeft: "5px", marginRight: "20px" }}>
              {selectedConsumer.firstName} {selectedConsumer.lastName}
            </b>
            Consumer ID:
            <b style={{ marginLeft: "5px" }}>{selectedConsumer.consumerId}</b>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={classes.circle} />
            <div style={{ marginLeft: "5px" }}>Your Default Address</div>
          </div>
        </div>

        <Paper className={classes.paper}>
          <TableContainer>
            <Table className={classes.table} aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCellSmall}> Address Type </TableCell>
                  <TableCell className={classes.tableCellSmall}> Society Name </TableCell>
                  <TableCell className={classes.tableCellSmall} scope="row"> House/Flat Number </TableCell>
                  <TableCell className={classes.tableCellSmall}> Street </TableCell>
                  <TableCell className={classes.tableCellSmall}> Landmark </TableCell>
                  <TableCell className={classes.tableCellSmall}> Area </TableCell>
                  <TableCell className={classes.tableCellSmall}> City </TableCell>
                  <TableCell className={classes.tableCellSmall}> Pincode </TableCell>
                  <TableCell className={classes.tableCellSmall}> Latitude/Longitude </TableCell>
                  <TableCell className={classes.tableCellSmall}> AddressId </TableCell>
                  {props.user?.role === "admin" ? <TableCell></TableCell> : null}
                  {props.user?.role === "admin" ? <TableCell></TableCell> : null}
                  {props.user?.role === "admin" ? <TableCell></TableCell> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedConsumer.address.map((item, index) => {
                 
                  const latiude = item?.location?.coordinates[1] || "NA";
                  const longitude = item?.location?.coordinates[0] || "NA";

                  return (
                    <TableRow hover className={classes.tableRow} key={index + item._id + item.isDefaultAddress} >
                      <TableCell
                        className={classes.tableCellSmall}
                        style={{ color: item.isDefaultAddress ? "#52a53d" : "default" }}
                        scope="row"
                      >
                        {item?.addressType || "NA"}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellSmall}
                        style={{ color: item.isDefaultAddress ? "#52a53d" : "default" }}
                        scope="row"
                      >
                        {item?.societyName || "NA"}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellSmall}
                        style={{ color: item.isDefaultAddress ? "#52a53d" : "default" }}
                        scope="row"
                      >
                        {item?.flatNumber || "NA"}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellSmall}
                        style={{ color: item.isDefaultAddress ? "#52a53d" : "default" }}
                        scope="row"
                      >
                        {item?.street || "NA"}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellSmall}
                        style={{ color: item.isDefaultAddress ? "#52a53d" : "default" }}
                        scope="row"
                      >
                        {item?.landmark || "NA"}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellSmall}
                        style={{ color: item.isDefaultAddress ? "#52a53d" : "default" }}
                        scope="row"
                      >
                        {item?.area || "NA"}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellSmall}
                        style={{ color: item.isDefaultAddress ? "#52a53d" : "default" }}
                        scope="row"
                      >
                        {item?.city || "NA"}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellSmall}
                        style={{ color: item.isDefaultAddress ? "#52a53d" : "default" }}
                        scope="row"
                      >
                        {item?.pinCode || "NA"}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellSmall}
                        style={{ color: item.isDefaultAddress ? "#52a53d" : "default" }}
                        scope="row"
                      >
                        {latiude + "/" + longitude}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellSmall}
                        style={{ color: item.isDefaultAddress ? "#52a53d" : "default" }}
                        scope="row"
                      >
                        {item?.addressId || "NA"}
                      </TableCell>
                      {props.user.role === "admin" ? (
                        <TableCell
                          className={classes.tableCellSmall}
                          scope="row"
                        >
                          <Badge badgeContent= {item?.visibleBusinesses ? parseInt(item.visibleBusinesses.length): 0}  max={item?.visibleBusinesses ? parseInt(item.visibleBusinesses.length): 0}  color="error" size="large">
                          <IconButton
                            className={classes.icon}
                            style={{ float: "right", padding: "0px" }}
                            onClick={(e) => { hadleOpenBusinessList(e, index) }}
                          >
                           <Avatar style={{background: "none"}}> <LINK /></Avatar>
                          </IconButton>
                          </Badge>
                        </TableCell>
                       
                      ) : null}
                      {props.user.role === "admin" ? (
                        <TableCell
                          className={classes.tableCellSmall}
                          scope="row"
                        >
                          <IconButton
                            className={classes.icon}
                            style={{ float: "right", padding: "0px" }}
                            onClick={(e) => { handleEditAddressClick(e, index) }}
                          >
                            <Avatar className={classes.lightBlue}> <EditIcon color="action" /> </Avatar>
                          </IconButton>
                        </TableCell>
                      ) : null}
                      {props.user.role === "admin" ? (
                        <TableCell className={classes.tableCellSmall} scope="row">
                          <IconButton
                            className={classes.icon}
                            style={{ float: "right", padding: "0px" }}
                            onClick={(e) => { handleDeleteAddressClick(e, index) }}
                          >
                            <Avatar className={classes.lightBlue}> <DeleteIcon color="action" /> </Avatar>
                          </IconButton>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>

      <Backdrop className={classes.backdrop} open={isFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isSnackOpen}
        autoHideDuration={3000}
        onClose={() => { setIsSnackOpen(false); }}
        ContentProps={{ "aria-describedby": "message-id" }}
      >
        {muiAlert}
      </Snackbar>

      <Modal
        open={isEditAddressModalOpen}
        className={classes.modal}
        onClose={() => setIsEditAddressModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <EditConsumerAddress
          onClose={() => { setIsEditAddressModalOpen(false);}}
          onSave={(addressArray) => {
            updateAddressArrayForConsumer(addressArray, selectedAddressIndex);
            setIsEditAddressModalOpen(false);
          }}
          selectedAddressArray={selectedConsumer.address}
          selectedAddressIndex={selectedAddressIndex}
        />
      </Modal>
      <Modal
        open={isOpenBusinessList}
        className={classes.modal}
        onClose={() => setIsOpenBusinessList(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <OpenBusinessAsPerAddress
          onClose={() => { setIsOpenBusinessList(false);}}
          onSave={(payload) => {
            saveAddDeleteBusiness(payload);
            setIsOpenBusinessList(false);
          }}
          selectedAddressArray={selectedConsumer.address}
          selectedAddressIndex={selectedAddressIndex}
          selectedConsumer={[selectedConsumer.firstName, selectedConsumer.lastName, selectedConsumer.consumerId]}
          businessList = {selectedConsumer.businessList}
        />
      </Modal>

      <Dialog
        open={isDeleteAddressModalOpen}
        onClose={() => setIsDeleteAddressModalOpen(false)}
      >
        <DialogTitle>Delete Address</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to delete the address?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsDeleteAddressModalOpen(false)}
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={() => handleDeleteAddress()}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeleteDefaultAddressModalOpen}
        onClose={() => setIsDeleteDefaultAddressModalOpen(false)}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Delete Address
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            Cannot DELETE default consumer address. Please change the default
            consumer address & try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsDeleteDefaultAddressModalOpen(false)}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    dashboard: state.dashboard,
  };
};

export default connect(mapStateToProps, {
  changeHeaderName,
  updateConsumerAddress,
  getOneConsumer,
  addDeleteBusiness
})(withStyles(styles)(ConsumerAddressDetails));
