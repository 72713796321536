import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Box,
} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea'

const styles = theme => ({
  imageOutermost: {
    width: '100%',
    height: '100%',
    padding: '0px 0px',
  },
  gridElement: {
    height: "80px"
  },
  imageContainer :{
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  icon: {
    color: 'white',
  },
  deleteContainer: {
    position: "absolute",
    color: "white",
    bottom: "20px",
    right: "10px",
    // background: "rgba(0, 0, 0, 0.5)"
  },
  contentContainer: {
    position: "relative",
    padding: "0px",
    margin:"5px",
  },
  uploadContainer: {
    justifyContent: 'center'
  },
  noPhotoContainer: {
    minHeight: "150px",
    minWidth: "150px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding :"5px",
    backgroundColor :"white"   
  },
  cardHeader:{
    backgroundColor:"#DDDDDD",
    fontSize :"1.2rem",
    padding :"8px"
  },
  cardMedia :{
    padding :"15px",
    borderRadius:"20px"
  }
});

class UploadBusinessCardPhotos extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteOpen: false,
      selectedPhoto: null
    };
    this.inputReference = React.createRef();
  }

  fileUploadAction = () => this.inputReference.current.click();
  onFileChange = async event => {
    let files = event.target.files;
    this.props.updateBusinessPhotos(files, this.props.photoType);   
  };

  handleDeleteOpen = (photo) => {
    this.setState({ modalDeleteOpen: true });
    this.setState({ selectedPhoto: photo });
  }

  handleDeleteClose = () => {
    this.setState({ modalDeleteOpen: false });
  }

  deleteBusinessPhoto = () => {
    this.props.deleteBusinessPhoto(this.state.selectedPhoto, this.props.photoType);
    this.setState({ modalDeleteOpen: false });
  }

  render() {
    // console.log("this.props in uploadBusinessCardPhotos --->", this.props)
    let imageListContent = null;

    const { classes, images } = this.props;
    // console.log("images --->",images)
    
    if (images) {
      imageListContent = (         
        
        <div>
          <Card style = {{textAlign:"center",backgroundColor:"#ECEAEC"}}>
            <CardHeader subheader={this.props.title} className={classes.cardHeader}>
            </CardHeader>
            <CardActionArea href={images.path} target="_blank" rel="noopener noreferrer">
            <CardMedia
            className={classes.cardMedia}
              component="img"
              height="150"
              width="150"             
              image={images.path}
              alt=""
            />
             </CardActionArea>
            <CardContent className={classes.contentContainer}>                     
                 {/* <img className={classes.imageContainer} src={images.path} alt="" /> */}
              <IconButton className={classes.deleteContainer} onClick={() => this.handleDeleteOpen(this.props.images)}>
                <DeleteIcon />
              </IconButton>              
            </CardContent>
           
            <CardActions className={classes.uploadContainer}>
              <Button variant="contained" htmlFor="myInput2" onClick={this.fileUploadAction} color="primary" component="span" >
                {this.props.uploadButtonName}
            </Button>
            </CardActions>
          </Card>
        </div>      
      );
      // console.log("imageListContent --->",imageListContent)

        }
    if( (images && images.length === 0) || images === undefined){

      imageListContent = (   
        this.props.title === "Default"?<div>
        <Card style = {{textAlign:"center",backgroundColor:"#ECEAEC"}}>
          <CardHeader subheader={this.props.title} className={classes.cardHeader}></CardHeader>
          <CardActionArea>
          
          <CardMedia
          className={classes.cardMedia}
          component="img"
          height="150"
          image={this.props.defaultImage}
          alt="default image"
        />
        </CardActionArea>
        <CardContent className={classes.contentContainer}>                     
             {/* <img className={classes.imageContainer} src={this.props.defaultImage} alt="" /> */}
          {/* <IconButton className={classes.deleteContainer} onClick={() => this.handleDeleteOpen(this.props.images)}>
            <DeleteIcon />
          </IconButton> */}
          
        </CardContent>
          <CardActions className={classes.uploadContainer}>
            <Button variant="contained" htmlFor="myInput2" onClick={this.fileUploadAction} color="primary" component="span" >
              {this.props.uploadButtonName}
          </Button>
          </CardActions>
        </Card>
      </div>      :      
        
        <div>
          <Card style = {{textAlign:"center", backgroundColor:"#ECEAEC"}}>
            <CardHeader subheader={this.props.title} className={classes.cardHeader}></CardHeader>
            
            <CardMedia
              component="div"
              height="150"
              className={classes.noPhotoContainer}
            >No Photo</CardMedia>
            <CardContent className={classes.contentContainer}>                                 
            {/* <Typography  className={classes.imageContainer}  color="text.secondary">
              No Photo
            </Typography>       */}
            </CardContent>
            <CardActions className={classes.uploadContainer}>
              <Button variant="contained" htmlFor="myInput2" onClick={this.fileUploadAction} color="primary" component="span" >
                {this.props.uploadButtonName}
            </Button>
            </CardActions>
          </Card>
        </div>      
      );    
    }

    return (
      <Grid>
        <Grid className={classes.imageOutermost}>
          <Grid>
           
            <input
              ref={this.inputReference}
              id="myInput2"
              name="Consumer"
              style={{ display: 'none' }}
              type={"file"}
              onChange={this.onFileChange}
              accept="image/x-png,image/jpeg"
            />
          </Grid>
          <Box className={classes.imagecontainer}>
            {imageListContent}
          </Box>
          
        </Grid>
        <Dialog open={this.state.modalDeleteOpen} onClose={this.handleDeleteClose}>
          <DialogTitle>Delete Photo</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete this photo?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
            <Button onClick={() => this.deleteBusinessPhoto()} color="primary" autoFocus> Yes </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

UploadBusinessCardPhotos.propTypes = {
  images: PropTypes.object.isRequired
};

export default (withStyles(styles)(UploadBusinessCardPhotos));
