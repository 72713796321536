import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { changeHeaderName, addTermsAndConditions } from '../../actions/dashboardActions';
import { clearSucessMsg, clearErrorMsg } from '../../actions/adminActions';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddTermsAndConditionsForm from './AddTermsAndConditionsForm';

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  snackClose: {
    padding: theme.spacing(0.5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});


class TermsAndConditions extends Component {

  constructor() {
    super();
    this.state = {
      snackOpen: false,
      sucessMsg: null,
      errorMsg: null
    };
  }

  componentDidMount() {
    this.props.changeHeaderName("Terms & Conditions");
  }

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    this.setState({ sucessMsg: null });
    this.setState({ errorMsg: null });
  }

  addTermsAndConditions = (props) => {
    this.props.addTermsAndConditions(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.admin.sucessMsg !== null) {
      this.setState({ sucessMsg: this.props.admin.sucessMsg })
      this.setState({ snackOpen: true });
      this.props.clearSucessMsg();
    } else if (this.props.admin.errorMsg !== null) {
      this.setState({ errorMsg: this.props.admin.errorMsg })
      this.setState({ snackOpen: true });
      this.props.clearErrorMsg();
    }
  }

  render() {
    const { classes } = this.props;
    let muiAlert = null;

    if (this.state.sucessMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
        {this.state.sucessMsg}
      </MuiAlert>
    } else if (this.state.errorMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
        {this.state.errorMsg}
      </MuiAlert>
    }

    return (
      <Container maxWidth="lg">
        <div className={classes.appBarSpacer} />
        <Container>
          <AddTermsAndConditionsForm onSubmit={this.addTermsAndConditions} />
        </Container>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}>
          {muiAlert}
        </Snackbar>
        <Backdrop className={classes.backdrop} open={this.props.dashboard.isFetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    dashboard: state.dashboard,
    user: state.auth.user,
    admin: state.admin
  }
};
export default connect(mapStateToProps, {
  changeHeaderName,
  addTermsAndConditions,
  clearSucessMsg,
  clearErrorMsg
})(withStyles(styles)(TermsAndConditions));