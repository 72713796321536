import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField'
import SupportNoteChatLayout from './SupportNoteChatLayout';
import Joi from "joi-browser";
import {getServiceRequestDetailsById,addServiceRequestSupportConversation } from '../../actions/serviceRequestActions';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import Avatar from '@material-ui/core/Avatar';
import { lightBlue } from '@material-ui/core/colors';
import { fetchEnvs } from '../../actions/authActions';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import {ReactComponent as SupportSVG} from '../../images/supportNoteSendIcon.svg';
const styles = theme => ({
    header: {
        height: '10%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: "22px",
        backgroundColor: theme.palette.primary.main,
        color:"#FFFFFF"
    },
    content: {
        height: '70%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        width: '100%',
        height: '20%',
        marginTop: '10px',
        // display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto',
        
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px',
    },
    modalContainer: {
        width: '45%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: '0px 0px',
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    head: {
        fontWeight: 700,
        fontSize: "12px",
    },
    lightBlue: {
        color:theme.palette.getContrastText(lightBlue[50]),
        backgroundColor:lightBlue[50],
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    roundedIcon: {
        marginRight:'10px',
        marginLeft:'10px'
    },
});

class SupportNoteView extends Component {
    constructor() {
        super();
        this.state = {
            data: { text: '',
            //attachments: null 
        },
            errors: {},

        };
    }

    schema = {
        text: Joi.string().required().min(1).max(255).label("Type your message"),
    };

    validate = () => {
        const errors = {};
        const { data } = this.state;
        const options = { abortEarly: false };
        const { error } = Joi.validate(data, this.schema, options);
        if (!error) return null;
        for (let item of error.details) {
            errors[item.path[0]] = item.message;
        }
        return errors;
    };

    validateInput = (input) => {
        const { name, value } = input;
        const obj = {
            [name]: value.trim(),
        };
        const schema = {
            [name]: this.schema[name],
        };
        const { error } = Joi.validate(obj, schema);
        if (!error) return "";
        const errorMessage = error.details[0].message;
        return errorMessage;
    };

    handleChange = (e) => {
        const { name, value, type } = e.target;
        const data = { ...this.state.data };
        const errors = { ...this.state.errors };
        const errorMessage = this.validateInput(e.target);
        errors[name] = errorMessage;
        data[name] = value;
        this.setState({ data, errors });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) return;
        this.doSubmit();
    };

    doSubmit = async () => {
        //server call
        await this.props.addServiceRequestSupportConversation(this.state.data, this.props.serviceId, this.props.userRole, this.props.history);
        this.props.getServiceRequestDetailsById(this.props.serviceId);
        this.setState({
            data: { text: '', 
            // attachments: null
         },
            errors: {},
        });
    };

    // onFileChange = event => {
    //     this.updateAttachmentPhotos(event.target.files)
    // };


    // updateAttachmentPhotos = async (files) => {
    //     let data = new FormData();
    //     let folderPath = "Xirify_ServiceRequest/" + this.props.serviceId + '/attachmments';
    //     data.append("file", files[0]);
    //     data.append("folder", folderPath);
    //     data.append(
    //         "upload_preset",
    //         this.props.env.cloudinaryBusinessImagesUploadPreset
    //     );
    //     let res = await fetch(this.props.env.cloudinaryUploadUrl, {
    //         method: "POST",
    //         body: data,
    //     });
    //     let file = await res.json();
    //     let attachmentImage = file.secure_url;
    //     this.state = {
    //         data: { text: '', attachments: [attachmentImage] },
    //         errors: {},
    //     };
    //     this.doSubmit();
    // };

    componentDidMount() {
        this.props.fetchEnvs();
    }

    render() {
        console.log(this.props)
        const { classes, supportconversations } = this.props;
        const { data, errors } = this.state;
        return (
            <Modal
                className={classes.modal}
                open={this.props.open}
                closeAfterTransition>
                <div style={{height:"80%",width:"80%"}}>
                <Container className={classes.modalContainer}>
                    <Grid container className={classes.header} spacing={1}>
                        <Grid item xs={8}>
                            <Typography variant="h5" gutterBottom>
                                SR Notes
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <CloseIcon className={classes.headGridTwo} onClick={() => { 
                                
                              this.props.onClose();
                              this.setState({
                                data: { text: ''},
                                errors: {},
                              });
                            
                              }} />
                        </Grid>
                    </Grid>

                    <Container className={classes.content}>
                        {   
                            supportconversations && supportconversations.length > 0 ?
                                <SupportNoteChatLayout data={supportconversations} />
                                :
                                <Typography variant="subtitle2" gutterBottom>
                                    No messages available to display.
                            </Typography>
                        }
                    </Container>
                    <Divider variant="middle" style={{ width: '100%', margin: '0px', height: '1px' }} />
                    <Container className={classes.footer}>
                        <div className='row'>
                            <Grid item xs={10}>
                                <TextField
                                    style={{ borderBottom: '2px solid #FAFAFA' , margin:"5px"}}
                                    //variant="outlined"
                                    required
                                    fullWidth
                                    id="text"
                                    label="Type your message"
                                    name="text"
                                    multiline={true}
                                    rows={1}
                                    error={ errors.text }
                                    value={data.text}
                                    onChange={this.handleChange} 
                                />
                            </Grid>
                            <Grid item xs={1} className={classes.roundedIcon}><div>
                                <label disabled={(data.text.trim() != '') ? null : true} onClick={(data.text.trim() != '') ? this.doSubmit : null} onKeyDown={(data.text.trim() != '') ? this.doSubmit : null}>
                                     <SupportSVG style={{height:"60px",width:"30px"}} />
                                </label></div>
                            </Grid>   
                            {/* <Grid item xs={1} className={classes.roundedIcon}><div>
                                <input type="file" style={{ display: 'none' }} accept="image/*" id="icon-button-file" onChange={this.onFileChange}/>
                                <label htmlFor="icon-button-file">
                                    <IconButton aria-label="upload picture"  style={{ backgroundColor: '#14b7dd', marginTop:"5px" }} 
                                        component="span">
                                        <AttachFileIcon style={{color: "white" }}/>
                                    </IconButton>
                                </label></div>
                            </Grid> */}
                        </div>
                    </Container>
                </Container>
                </div>
            </Modal>
        );
    }
}


const mapStateToProps = (state) => ({
    data: state, env: state.auth.env

});
const mapDispatchToProps = (dispatch) => ({
    addServiceRequestSupportConversation: (data, serviceId, userRole, history) => dispatch(addServiceRequestSupportConversation(data, serviceId, userRole, history)),
    getServiceRequestDetailsById: (data, serviceId, history) => dispatch(getServiceRequestDetailsById(data, serviceId, history)),
    fetchEnvs
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SupportNoteView));