import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Checkbox,
    Collapse,
    IconButton,
    Typography,
    Button,
    FormControlLabel,
    Switch,
    Avatar,
    Grid,
    Container
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';

import { ReactComponent as CollapsedIcon } from '../../../../images/collapsed.svg';
import { ReactComponent as ExpandedIcon } from '../../../../images/expanded.svg';
import { ReactComponent as LockedIcon } from '../../../../images/Locked.svg';
import { ReactComponent as UnlockedIcon } from '../../../../images/Unlocked.svg';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { ReactComponent as Veg } from '../../../../images/veg.svg';
import { ReactComponent as Egg } from '../../../../images/Egg.svg';
import { ReactComponent as Nonveg } from '../../../../images/Nonveg.svg';
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
    graySection: {
        background: '#DCDCDC',
        borderRadius: '4px 4px 0px 0px',
    },
    whiteScale: {
        height: '60px',
        marginBottom : '5px!important'

    },
    lockedGroup: {
        background: '#ffd1d9'
    },
    paddingA: {
        padding: theme.spacing(1, 0, 0, 2),
    },
    paddingB: {
        padding: theme.spacing(0, 0, 0, 2),
        
    },
    textFormatA: {
        padding: theme.spacing(0, 0, 0, 2),
        textAlign: 'left',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#202035',
        margin: theme.spacing(1),
        width: 'auto',
    },
    textFormatB: {
        padding: theme.spacing(0, 0, 0, 2),
        textAlign: 'left',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#202035',
        margin: theme.spacing(1),
        width: '405px',
    },
    textFormatD:{
        padding: '0px 0px 0px 59px',
        textAlign: 'left',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#202035',
        margin: theme.spacing(1),
        width: '445px',
    },
    textFormatE: {
        margin: '0px 0px 0px 50px',
        textAlign: 'left',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#202035',
        margin: theme.spacing(1),
        width: 'auto',
    },
    flexBox: {
        display: 'flex',
        padding: 0,
    },
    topPadding: {
        marginTop: theme.spacing(1),
    },
    nonePadding: {
        padding: 0,
    },
    buttonPadding: {
        marginTop: theme.spacing(0.5),
    },
    gridA: {
        margin: '10px 10px 0px 80px',
        'text-align': 'left',
        font: 'normal normal 600 14px/17px Open Sans',
        'letter-spacing': '0px',
        width: '410px',
        color: '#525252'
    },
    gridB: {
        margin: '10px 0px 0px 0px',
        'text-align': 'left',
        font: 'normal normal 600 14px/17px Open Sans',
        'letter-spacing': '0px',
        width: '190px',
        color: '#525252'
    },
    gridC: {
        margin: '10px 0px 0px 0px',
        'text-align': 'left',
        font: 'normal normal 600 14px/17px Open Sans',
        'letter-spacing': '0px',
        width: '150px',
        color: '#525252'
    },
    restoreButton: {
        color: '#14B7DD',
        outlineStyle: 'solid',
        outlineWidth: '1px',
        fontSize: '10px'
    },
    defaultButton: {
        color: '#14B7DD',
        outlineStyle: 'solid',
        outlineWidth: '1px',
        fontSize: '10px'
    },
    buttonSpacing: {
        padding: theme.spacing(1, 3, 0, 0),
    },
    buttonSpacing1: {
        padding: theme.spacing(1, 0, 0, 0),
    },
    row: {
        background: '#fafafa',
    },
    cell: {
        padding: '0px 0px 0px 10px', // Left padding for all cells
        textAlign: 'left', // Align cell content left
    },
    cellA: {
        padding: '0px 0px 0px 10px', // Left padding for all cells
        textAlign: 'left', // Align cell content left
        minWidth: '300px',
        display: 'flex!important'
    },
    cellB: {
        padding: '0px 0px 0px 10px', // Left padding for all cells
        textAlign: 'left', // Align cell content left
        minWidth: '100px'
    },
    cellC: {
        padding: '0px 0px 0px 22px', // Left padding for all cells
        textAlign: 'left', // Align cell content left
        minWidth: '300px',
        display: 'flex!important'
    },
    innerRow: {
        filter: 'grayscale(200%)',
        color: '#777', 
        opacity: 0.7, 
        'pointer-events': 'none', 
    },
    descriptionView: {
        padding: '0px 0px 0px 15px',
        outline: 'none'
    },
    textFormatC: {
        padding: theme.spacing(0, 1, 0, 1),
        textAlign: 'left',
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#525252',
        margin: theme.spacing(1),
        width: 'auto',
        font: 'normal normal 600 18px/26px serif',
        'letter-spacing': '0px',
    },
    whiteBg :{
        'background-color': 'white',
    },
    defaultRow: {
        color : 'black',
        fontWeight: 500
    },


}));


const PriceYesButton = () => {
    return (
        <Button
            variant="outlined"
            style={{ color: 'green', outlineStyle: 'solid', outlineWidth: '2px', fontWeight: 'bold', height: '40px' }}
            size="small"
        >
            <Grid container direction="column" alignItems="center">
                <Grid item style={{ margin: '1px 0' }}>Price</Grid>
                <Grid item>Yes</Grid>
            </Grid>
        </Button>
    );
};
const PriceNoButton = () => {
    return (
        <Button
            variant="outlined"
            style={{ color: 'orange', outlineStyle: 'solid', outlineWidth: '2px', fontWeight: 'bold', height: '40px' }}
            size="small"
        >
            <Grid container direction="column" alignItems="center">
                <Grid item style={{ margin: '1px 0' }}>Price</Grid>
                <Grid item>No</Grid>
            </Grid>
        </Button>
    );
};




const AddonGroupTable = (props) => {
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };


    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    {props.addonGroupRecords && props.addonGroupRecords.length ? props.addonGroupRecords.map((row, index) => (
                        <React.Fragment key={row._id}>
                            <TableRow className={row.isLocked === false ? classes.graySection : classes.lockedGroup}>
                                <TableCell className={classes.flexBox}>
                                    <div className={classes.paddingA}>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => props.handleTableOpen(props.openTable === index ? -1 : index)}
                                            style={{  outline: 'none' }}
                                        >
                                            {props.openTable === index ? <ExpandedIcon /> : <CollapsedIcon />}
                                        </IconButton>
                                    </div>
                                    <div className={classes.paddingB}>
                                    { props.role === "areaManager" ?  <Checkbox  checked={row.isDelete} onClick={(event)=> props.handleDeleteSelection(event , row._id)}    />     : null }                             
                                    </div>
                                    <div className={ props.role === "areaManager" ? classes.textFormatB : classes.textFormatD  }>
                                        Group Name:<b> {row.addOnGroupName}</b>
                                    </div>
                                    <div className={classes.textFormatA}>Linked Items:</div>
                                    <div className={classes.buttonPadding}>
                                        <Button size="small" variant="contained" color="primary" disabled={row.linkedServiceItems.length < 1 ? true : false}  onClick={()=> props.handleLinkedItemOpen(row.linkedServiceItems , row._id)}>
                                            {row.linkedServiceItems && row.linkedServiceItems.length ? row.linkedServiceItems.length : '00' }
                                        </Button>
                                    </div>
                                    <div className={classes.textFormatA}>
                                        Group ID: <b>{row.addOnGroupId}</b>  {row.isLocked === true ? <LockedIcon /> : <UnlockedIcon />}
                                    </div>
                                    <div className={classes.textFormatC}>{ row.isEnabled ? 'Enabled' : 'Disabled'}</div>
                                    <div className={classes.topPadding}>
                                    { props.role === "areaManager" ?  <FormControlLabel
                                            control={
                                                <Switch
                                                    size="small"
                                                    checked={row.isEnabled}
                                                    onChange={(e) => props.handleGroupSwitchChange(row)}
                                                    name="isAvailable"
                                                    color={row.isEnabled ? 'primary' : 'secondary'}
                                                />
                                            }
                                        />  : null }
                                    </div>

                                    <div className={classes.nonePadding}>
                                    { props.role === "areaManager" ?  <IconButton className={classes.nonePadding} onClick={() => props.handleEditAddonGroupOpen(row)}>
                                            <Avatar>
                                                <EditIcon color="action" />
                                            </Avatar>
                                        </IconButton>   : null }
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.whiteScale}>
                                <Container className={classes.flexBox}>
                                    <div style={{ height: '10px', width: '10px', margin: '10px 20px 0px 20px' , pointerEvents : 'none'}}>{row.applyPrice === true ? <PriceYesButton /> : <PriceNoButton />}</div>
                                    <div className={classes.gridA}>
                                        <Grid container direction="column">
                                        {row.description === '' ?
                                            <Grid item>Description:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   {'NA'}</Grid> :
                                            <Grid item>Description:   <IconButton className={classes.descriptionView}  style={{  outline: 'none' }} onClick={()=>props.handleDescriptionOpen(row.description)}> <RemoveRedEyeIcon style={{ outline : 'none'}} />  </IconButton>   </Grid> }
                                            <Grid item>Selection Type: {row.selectionType}</Grid>
                                        </Grid>
                                    </div>
                                    <div className={classes.gridB}>
                                        <Grid container direction="column">
                                            <Grid item>Min Selection: {row.minSelection === 0 ? 'NA' : row.minSelection} </Grid>
                                            <Grid item>Max Selection: {row.maxSelection === 0 ? 'NA' : row.maxSelection}</Grid>
                                        </Grid> 
                                    </div>
                                    <div className={classes.gridC}>
                                        <Grid container direction="column">
                                            <Grid item>Total Addons: {row.linkedAddOns && row.linkedAddOns.length ? row.linkedAddOns.length : null}</Grid>
                                            <Grid item>Available:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {row.available}</Grid>
                                        </Grid>
                                    </div>
                                    <div className={classes.buttonSpacing}>
                                        {(row.restore && row.restore.length  && props.role === "areaManager")> 0 ? (
                                            <Button className={classes.restoreButton}  variant="outlined" size="small" onClick={()=>props.handleRestoreOpen(row.restore)}>
                                                Restore
                                            </Button>
                                        ) : <Button style={{  pointerEvents : 'none' , outline :'none'}} ></Button>}
                                    </div>
                                    <div className={classes.buttonSpacing1}>
                                        {(row.selectionType === 'Single Selection' || row.minSelection > 0 ) &&  (row.changeDefault.length && props.role === "areaManager" && row.isLocked === false) &&
                                          ( row.selectionType === 'Single Selection' ?  row.available > 1  : row.available > row.minSelection)
                                        ?
                                         <Button className={classes.defaultButton} variant="outlined" size='small' onClick={()=>props.handleChangeDefault(row)}>Change Default</Button> : null}
                                    </div>

                                </Container>
                            </TableRow>


                            <TableRow >
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={14}>
                                    <Collapse in={props.openTable === index} timeout="auto" unmountOnExit>
                                        <Box
                                            style={{
                                                minHeight: 'auto',
                                                textAlign: 'center',
                                                fontSize: 12,
                                                borderRadius: "8px",
                                                border: "1px solid gray",
                                                margin: "5px 0px 5px 5px",


                                            }}
                                            className={classes.rowBackground}
                                        >
                                            <Paper>
                                            {row.linkedAddOns && row.linkedAddOns.length ?
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow className={classes.row}>
                                                            <TableCell className={classes.cell}></TableCell>
                                                            <TableCell className={classes.cell}></TableCell>
                                                            <TableCell className={classes.cellC}>Addon Name</TableCell>
                                                            <TableCell className={classes.cell}>Addon ID</TableCell>
                                                            <TableCell className={classes.cell}>Quantity</TableCell>
                                                            <TableCell className={classes.cell}>Unit</TableCell>
                                                            <TableCell className={classes.cell}>Price</TableCell>
                                                            <TableCell className={classes.cellB}>Status</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {row.linkedAddOns && row.linkedAddOns.length ? row.linkedAddOns.map((r, index) => {
                                                            return (
                                                                <TableRow
                                                                    key={r._id}
                                                                    className={r.isEnabled === false || r.isDisplay === false ? classes.innerRow : classes.defaultRow}

                                                                >
                                                                    <TableCell className={classes.cell}>
                                                                        {r.isDefault === true ?
                                                                            <Typography style={{ color: '#FFB200', fontStyle: 'Open Sans, Semibold Italic', fontSize: '14px' }}>Default</Typography>
                                                                            : null}
                                                                    </TableCell>
                                                                    <TableCell className={classes.cell}>
                                                                        {r.photos && r.photos[0] && r.photos[0].path ?
                                                                            <Avatar src={r.photos[0].path} variant="square" style={{ height: '25px', width: '25px' }}></Avatar>
                                                                            : <Avatar variant="square" style={{ height: '25px', width: '25px' }}src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                                                    </TableCell>
                                                                    <TableCell className={classes.cellA}>
                                                                        {r.foodType && r.foodType === 'Veg' ?
                                                                            <Avatar style={{ background: "none" , padding : '13px 0px' , height : '31px'}}> <Veg /></Avatar> : null}
                                                                        {r.foodType && r.foodType === 'NonVeg' ?
                                                                            <Avatar style={{ background: "none" , padding : '13px 0px' , height : '31px'}}> <Nonveg /></Avatar> : null}
                                                                        {r.foodType && r.foodType === 'Egg' ?
                                                                            <Avatar style={{ background: "none" , padding : '13px 0px' , height : '31px'}}> <Egg /></Avatar> : null}
                                                                        {
                                                                            r.foodType && r.foodType !== 'Veg' || r.foodType && r.foodType !== 'NonVeg' || r.foodType && r.foodType !== 'Egg' ?
                                                                                r.service ? <Typography style={{ padding: '5px 0px 0px 10px', fontSize: '14px' }}>{r.service}</Typography> : "NA"
                                                                                : r.service ? <Typography style={{ padding: '5px 0px 0px 5px' , fontSize: '14px' }}>{r.service}</Typography> : "NA"
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell className={classes.cell}>{r.serviceId}</TableCell>
                                                                    <TableCell className={classes.cell}>{r.cost?.quantity ? r.cost?.quantity : "NA"}</TableCell>
                                                                    <TableCell className={classes.cell}>{r.cost?.unit ? r.cost?.unit : "NA"}</TableCell>
                                                                    <TableCell className={classes.cell}>{r.cost?.fixedCost ? r.cost?.fixedCost : "NA"}</TableCell>
                                                                    <TableCell className={classes.cellB}>{r.status ? r.status : "NA"}</TableCell>
                                                                </TableRow>
                                                            );
                                                        }) : null}

                                                    </TableBody>
                                                </Table>  : <Typography style={{ margin: "0px 460px" }}>No Records Available</Typography>}
                                            </Paper>

                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    )) : <Typography style={{ margin: "30px 460px" }}>No Records Available</Typography>}
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={props.totalCount}
                        rowsPerPage={props.rowsPerPage}
                        page={props.page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}

                    />
                </TableBody>
            </Table>
            
        </TableContainer>
    );
};

export default AddonGroupTable;




