import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { changeHeaderName, getAllMasterOtp, setCofiguration, updateSplashScreenUrl, setSmsProvider, setExotel, setExotelGlobalConfig, saveOrderCancellationReason, deleteOrderCancellationReason, businessDashboardCronDaily, businessDashboardCronMonthly, businessDashboardCronWeekly, couponExpiryCron, deleteOpenCloseRecords , getCashfreeVendorSplitSettlementData} from '../../actions/dashboardActions';
import { settlementCronJob, clearMsg, couponSettlementCronJob } from '../../actions/servicePartnerDeliveriesAction';
import { clearMessage, getCancellationReasonByRole, callBusinessDashboardCron, callBusinessDashboardCronReset } from '../../actions/dashboardActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import TabPanel from "../utils/TabPanel"
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Modal from '@material-ui/core/Modal';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import { Field, reduxForm } from 'redux-form';
import Divider from '@material-ui/core/Divider';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import DeleteIcon from '@material-ui/icons/Delete';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReferralsDetails from './referralsDetails';
import ReferralAddForm from './referralAddForm';
import ReferralEditForm from './referralEditForm';
import _ from 'lodash';
import { fetchEnvs } from '../../actions/authActions';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import UploadSplashScreen from './UploadSplashScreen'
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import CloseIcon from '@material-ui/icons/Close';
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { lightBlue } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';



// import hu from "date-fns/locale/hu";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// registerLocale('hu', hu);

function getWeekDays(weekStart) {
  // console.log("weekStart---", weekStart);
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(
      moment(weekStart)
        .add(i, 'days')
        .toDate()
    );
  }
  return days;
};

function getWeekRange(date) {
  // console.log("date----", date);
  return {
    from: moment(date)
      .startOf('isoWeek')
      .toDate(),
    to: moment(date)
      .endOf('isoWeek')
      .toDate(),
  };
};

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    // marginTop: '100px',
    marginTop: '40px',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    // height: '100px',
    marginBottom: '25px',
  },
  paperPayment: {
    width: '100%',
    height: '60%',
    backgroundColor: theme.palette.background.paper,
    padding: '0px 0px'
  },
  paper11: {
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    height: '82px',
    marginBottom: '25px',
  },
  emailPaper11: {
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    height: '110px',
    marginBottom: '25px',
  },
  paper2: {
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    height: '170px',
    marginBottom: '25px'
  },
  snackClose: {
    padding: theme.spacing(0.5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "150px",
    width: "100%",
  },
  headGridTwo: {
    float: 'right',
    marginRight: '2px'
  },
  gridList: {
    width: '100%',
    height: '100%'
  },
  gridElement: {
    margin: '10px 5px'
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  icon: {
    color: 'white',
  },
  backdrop: {
    zIndex: 999999999,
    color: "#fff",
  },
  lightBlue: {
    color: theme.palette.getContrastText(lightBlue[50]),
    backgroundColor: lightBlue[50],
    width: theme.spacing(4),
    height: theme.spacing(4),
    cursor: 'pointer'
  },
  icon: {
    height: 32,
    //  width: 32
  },
  mainContainer4: {
    width: '40%',
    height: '60%',
    backgroundColor: theme.palette.background.paper,
    padding: '0px 0px'
    // margin: '10px 0px',
    // overflow: 'auto',
  },
  gridItem: {
    padding: "10px"
  },
  modalContainer4: {
    width: '75%',
    height: '75%',
    backgroundColor: theme.palette.background.paper,
  },
  header4: {
    height: '17%',
    margin: '0px 0px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: "22px",
    backgroundColor: theme.palette.primary.main,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px'
  },
  grid: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: "15px",
    paddingRight: "15px",
    margin: '0px 0px',
  },
  select: {
    height: '38px'
  },
  gridborder: {
    border: "0px solid black",
    borderRadius: "5px",
    alignSelf: 'left',
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginRight: "23px",
    marginLeft: "10px",
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: '15px',
    backgroundColor: 'white'
  },
  // Pickup and Drop section css start
  buttonWidth: {
    width: "97%",
  },
  dividerHorizontal: { 
    backgroundColor: "#FFFFFF", 
    padding: "2px"
   },

  buttonContainer:{
     backgroundColor: "#E8E7E6", 
     paddingTop: "12px" 
    },

  pickDropContainer:{
     backgroundColor: "#E8E7E6", 
     paddingTop: "15px" 
    },
  rupess:{
    borderRadius:"0px",
    marginTop: "1px",
    backgroundColor: "#FFFFFF"
    },
    base:{ 
     paddingLeft: "14px",
     marginTop: "8px"
     },
      //  Pickup and Drop section Css end
  paper3: {
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    height: '82px',
    marginBottom: '25px',
    marginTop:'-25px'
  }, 
});

const splashScreenTimer = [
  '3','4','5','6','7','8','9','10'
]

const baseKM = [
  '1','2','3','4','5','6','7','8','9','10'
]
const daysOfMonth = [
  '1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28'
]

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

class configurations extends Component {

  constructor() {
    super();
    this.state = {
      snackOpen: false,
      sucessMsg: null,
      value: 0,
      date: "",
      coupondate: "",
      deleteOpenCloseDate: "",
      error: {},
      modalOpen: false,
      modalOpenEdit: false,
      enableReferAndEarn: false,
      referralAmountReferred: "",
      minimumOrderValueReferred: "",
      couponExpiryDaysReferred: "",
      referralAmountReferring: "",
      minimumOrderValueReferring: "",
      couponExpiryDaysReferring: "",
      // referralAmountReferredError: false,
      // minimumOrderValueError: false,
      // couponExpiryError: false,
      addReferralData: false,
      type: "",
      editReferrals: false,
      referralData: {},
      configId: null,
      referralCouponImage: "",
      referralCouponName: "",
      termsAndConditionsUrl: "",
      orderByTime: "",
      xirifySupportNo: "",
      orderByTimeError: false,
      exotelSupportError: false,
      phoneValidationError: false,
      splashScreenURL: "",
      modalDeleteOpen: false,
      splashScreenTimerModel : false,
      openCloseDeleteModalOpen: false,
      selectedPhoto: null,
      orderByTimeValidationError: false,
      xirifySupportNumberError: false,
      orderByTimeSpaceError: false,
      xirifySupportSpaceError: false,
      isFetching: false,
      smsProvider: 'GupShup',
      editExotelConfig: false,
      firstMintesArray: [],
      minutes: [],
      firstReminder: "",
      secondReminder: "",
      thirdReminder: "",
      firstRequired: false,
      secondRequired: false,
      thirdRequired: false,
      adminCheckboxFlag: false,
      businessCheckboxFlag: false,
      shopperCheckboxFlag: false,
      role: "",
      activeInactiveToggleFlag: false,
      editReasonFlag: false,
      deleteReasonFlag: false,
      addReasonFlag: false,
      reasonError: false,
      modalReasonDeleteOpen: false,
      reason: "",
      selectedCancallationReason: null,
      reasonEditModalOpen: false,
      reasonEdit: "",
      selectedReason: null,
      reasonEditError: false,
      reasonErrorExists: false,
      reasonEditErrorExists: false,
      promoCodeValidationFlag: false,
      blockCopuonPushNotification: false,
      businessDashboardCron: "",
      fromDate: "",
      toDate: "",
      filterStartWeek: [],
      dailyDateError: false,
      dailyDateRequired: false,
      monthlyDateRequired: false,
      monthlyDateError: false,
      weeklyRequired: false,
      weeklyError: false,
      successCouponExpiryMsg: null,
      errorCouponExpiryMsg: null,
      splashScreenTimer: "",
      emailNotification: "",
      emailNotificationArray:[],
      emailNotificationRequiredError: false,
      emailNotificationValidateError: false,
      emailRepeatError:false,
      baseKM: 1,
      basePrice:"",
      pricePerKM:"",
      basePriceError:false,
      //basePriceValidationError: false,
      baseKMError:false,
      pricePerKMError:false,
      //pricePerKMValidationError: false,
      basePriceSpaceError: false,
      pricePerKMSpaceError: false,
      defaultBusinessRadius: 2,
      defaultBusinessRadiusError: false,
      gracePeriodDays:1,
      paymentCycleDay:1,
      minOrdersOfConsumerForPostPayment: null,
      minOrdersOfConsumerForPostPaymentError: false,
      minOrdersOfConsumerForPostPaymentErrorText: "",
      blockDateIfNotPaid:1,
      monthNames:"",
      paymentCycleError:false,
      savePaymentCycle:false,
      cancelPayment:false,
      isOrderBlockFunction:true
      
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }
  async componentDidMount() {
    // console.log("this.props.dashboard.getMasterOTP --------->", this.props.dashboard.getMasterOTP)
   
    for (let i = 1; i < 60; i++) {
      this.state.minutes.push(i)
    }
    for (let i = 0; i < 60; i++) {
      this.state.firstMintesArray.push(i)
    }
  
    this.setState({ isFetching: true })
    await this.props.fetchEnvs();
    await this.props.changeHeaderName("Configurations");
    await this.props.getAllMasterOtp();
    // if('exotelConfig' in this.props.dashboard.getMasterOTP.data[0] && this.props.dashboard.getMasterOTP.data[0].exotelConfig.length > 0) {
    // console.log('this.props.dashboard.getMasterOTP.data[0]: ', this.props.dashboard.getMasterOTP.data[0]);
    this.setState({
      firstReminder: this.props.dashboard.getMasterOTP.data[0].exotelConfig[0].minute,
      secondReminder: this.props.dashboard.getMasterOTP.data[0].exotelConfig[1].minute,
      thirdReminder: this.props.dashboard.getMasterOTP.data[0].exotelConfig[2].minute,
      xirifySupportNo: this.props.dashboard.getMasterOTP.data[0].xirifySupportNo,
      emailNotificationArray:this.props.dashboard.getMasterOTP.data[0].supportEmailIDs ? this.props.dashboard.getMasterOTP.data[0].supportEmailIDs :[],
      defaultBusinessRadius: this.props.dashboard.getMasterOTP.data[0] && this.props.dashboard.getMasterOTP.data[0].defaultBusinessRadius && this.props.dashboard.getMasterOTP.data[0].defaultBusinessRadius !== undefined ? this.props.dashboard.getMasterOTP.data[0].defaultBusinessRadius : this.state.defaultBusinessRadius,     
      minOrdersOfConsumerForPostPayment: this.props.dashboard.getMasterOTP.data[0] && this.props.dashboard.getMasterOTP.data[0].minOrdersOfConsumerForPostPayment !== undefined ? this.props.dashboard.getMasterOTP.data[0].minOrdersOfConsumerForPostPayment : this.state.minOrdersOfConsumerForPostPayment,  
      defaultBusinessRadius: this.props.dashboard.getMasterOTP.data[0] && this.props.dashboard.getMasterOTP.data[0].defaultBusinessRadius && this.props.dashboard.getMasterOTP.data[0].defaultBusinessRadius !== undefined ? this.props.dashboard.getMasterOTP.data[0].defaultBusinessRadius : this.state.defaultBusinessRadius,
      isOrderBlockFunction: this.props.dashboard.getMasterOTP.data[0] && this.props.dashboard.getMasterOTP.data[0].isOrderBlockFunction
    })
    // }
    if (this.props.dashboard?.getMasterOTP?.data[0]?.splashScreenUrl) {
      this.setState({ splashScreenURL: this.props.dashboard?.getMasterOTP?.data[0]?.splashScreenUrl })
    }
    if(this.props.dashboard?.getMasterOTP?.data[0]?.splashScreenTimer){
      this.setState({splashScreenTimer:this.props.dashboard?.getMasterOTP?.data[0]?.splashScreenTimer})
    }

    this.setState({ isFetching: false })
    if (this.props.dashboard?.getMasterOTP?.data[0]?.orderByTime) { this.setState({ orderByTime: this.props.dashboard?.getMasterOTP?.data[0]?.orderByTime }) } else { this.setState({ orderByTime: "" }) }

    if (this.props.dashboard?.getMasterOTP?.data[0]?.xirifyDeliveryCharges?.basePrice) { this.setState({ basePrice: this.props.dashboard?.getMasterOTP?.data[0]?.xirifyDeliveryCharges?.basePrice }) } else { this.setState({ basePrice: "" }) }

    if (this.props.dashboard?.getMasterOTP?.data[0]?.xirifyDeliveryCharges?.pricePerKM) { this.setState({ pricePerKM: this.props.dashboard?.getMasterOTP?.data[0]?.xirifyDeliveryCharges?.pricePerKM }) } else { this.setState({ pricePerKM: "" }) }

    if (this.props.dashboard?.getMasterOTP?.data[0]?.xirifyDeliveryCharges?.baseKM) { this.setState({ baseKM: this.props.dashboard?.getMasterOTP?.data[0]?.xirifyDeliveryCharges?.baseKM }) } else { this.setState({ baseKM: 1 }) }

    this.setState({ role: "all" });
    const currentDate = new Date();
    console.log("..currentDate",currentDate);
    let months = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];
    let currentMonth = months[currentDate.getMonth()];
    console.log("Current Month:", currentMonth);
    this.setState({monthNames:currentMonth})
    function getLastDayOfCurrentMonth() {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
  
      // Create a date object for the first day of the next month
      const firstDayOfNextMonth = new Date(year, month + 1, 1);
  
      // Subtract one day to get the last day of the current month
      const lastDayOfCurrentMonth = new Date(firstDayOfNextMonth - 1);
  
      return lastDayOfCurrentMonth.getDate();
  }
  
  
  let paymentcycleDate =  getLastDayOfCurrentMonth();
  console.log("paymentcycleDate",paymentcycleDate);
  this.setState({paymentCycleDay:paymentcycleDate})
    await this.props.getCancellationReasonByRole(0);
    
     if(this.props?.dashboard?.getMasterOTP?.data[0]?.consumerBlockDateNumber){
     this.setState({blockDateIfNotPaid:this.props?.dashboard?.getMasterOTP?.data[0]?.consumerBlockDateNumber})
     }else{
      this.setState({blockDateIfNotPaid:1});
     }
     if(this.props?.dashboard?.getMasterOTP?.data[0]?.gracePeriodInDays){
     this.setState({gracePeriodDays:this.props?.dashboard?.getMasterOTP?.data[0]?.gracePeriodInDays})
     } else{
      this.setState({gracePeriodDays:1})
     }
    }

  componentDidUpdate(prevProps, prevState) {
    console.log("this.props.dashboard.minOrderSuccessMsg", this.props.dashboard)


    if (this.props.deliveries.settlementMsg) {
      this.setState({ sucessMsg: this.props.deliveries.settlementMsg })
      this.setState({ snackOpen: true });
      this.props.clearMsg();
    }
    if (this.props.dashboard.couponExpirySuccessmsg) {
      this.setState({ successCouponExpiryMsg: this.props.dashboard.couponExpirySuccessmsg })
      this.setState({ snackOpen: true });
      this.props.clearMessage();
    }
    if (this.props.deliveries.couponSettlementMsg) {
      this.setState({ sucessMsg: this.props.deliveries.couponSettlementMsg })
      this.setState({ snackOpen: true });
      this.props.clearMsg();
    }
    if (this.props.dashboard.orderByTimeSucessMsg) {
      this.setState({ sucessMsg: this.props.dashboard.orderByTimeSucessMsg })
      this.setState({ snackOpen: true });
      this.props.clearMessage();
    }
    if (this.props.dashboard.sucessMsg) {
      this.setState({ sucessMsgCancellationReason: this.props.dashboard.sucessMsg })
      this.setState({ snackOpen: true });
      this.props.clearMessage();
    }
    if (this.props.dashboard.errorMsg) {
      this.setState({ errorMsgCancellationReason: this.props.dashboard.errorMsg })
      this.setState({ snackOpen: true });
      this.props.clearMessage();
    }
    if (this.props.dashboard.businessDashboardSucessMsg) {
      this.setState({ sucessMsgBusinessDashboard: this.props.dashboard.businessDashboardSucessMsg })
      this.setState({ snackOpen: true });
      this.props.clearMessage();
    }
    if (this.props.dashboard.businessDashboardErrorMsg) {
      this.setState({ errorMsgBusinessDashboard: this.props.dashboard.businessDashboardErrorMsg })
      this.setState({ snackOpen: true });
      this.props.clearMessage();
    }
    if (this.props.dashboard.couponExpiryErrorMsg) {
      this.setState({ errorCouponExpiryMsg: this.props.dashboard.couponExpiryErrorMsg })
      this.setState({ snackOpen: true });
      this.props.clearMessage();
    }
    if (this.props.dashboard.deleteOpenCloseRecordsSuccessMsg) {
      this.setState({ sucessMsgDeleteOpenCloseRecords: this.props.dashboard.deleteOpenCloseRecordsSuccessMsg })
      this.setState({ snackOpen: true });
      this.props.clearMessage();
    }
    if (this.props.dashboard.deleteOpenCloseRecordsErrorMsg) {
      this.setState({ errorMsgDeleteOpenCloseRecords: this.props.dashboard.deleteOpenCloseRecordsErrorMsg })
      this.setState({ snackOpen: true });
      this.props.clearMessage();
    }
    if (this.props.dashboard.cashfreeSplitSettlemetSuccessMsg) {
      this.setState({ successCashfreeSplitVendorSettlement: this.props.dashboard.cashfreeSplitSettlemetSuccessMsg })
      this.setState({ snackOpen: true });
      this.props.clearMessage();
    }
    if (this.props.dashboard.cashfreeSplitSettlemetErrorMsg) {
      this.setState({ errorCashfreeSplitVendorSettlement: this.props.dashboard.cashfreeSplitSettlemetErrorMsg })
      this.setState({ snackOpen: true });
      this.props.clearMessage();
    }
    if (this.props.dashboard.minOrderSuccessMsg) {
      this.setState({ minOrderSuccessMsg: this.props.dashboard.minOrderSuccessMsg })
      this.setState({ snackOpen: true });
      this.props.clearMessage();
    }
   
  };

  handleSwitchChange = async (event) => {
    this.setState({ isFetching: true })
    await this.props.setCofiguration({ enablePaymentsViaCashfree: event.target.checked });
    await this.props.getAllMasterOtp();
    this.setState({ isFetching: false })
  };

  handleCashfreeNewApiIntegration = async (event) => {
    this.setState({ isFetching: true })
    await this.props.setCofiguration({ cashfreeNewVersion: event.target.checked });
    await this.props.getAllMasterOtp();
    this.setState({ isFetching: false })
  };
  handleAllowPostPayment = async (event) => {
    this.setState({ isFetching: true })
    await this.props.setCofiguration({ isPostPaymentAllowed: event.target.checked });
    await this.props.getAllMasterOtp();
    this.setState({ isFetching: false })
  };

  handleBlockCouponPushNotification = async (event) => {
    this.setState({ isFetching: true })
    await this.props.setCofiguration({ blockCopuonPushNotification: event.target.checked });
    await this.props.getAllMasterOtp();
    this.setState({ isFetching: false })
  };

  handleExotelSwitch = async (event) => {
    this.setState({ isFetching: true })
    await this.props.setExotel({ isExotel: event.target.checked });
    await this.props.getAllMasterOtp();
    this.setState({ isFetching: false })
  };
  handleExotelSwitchForOSBill = async (event) => {
    this.setState({ isFetching: true })
    await this.props.setExotel({ isExotelOSBillConsumer: event.target.checked });
    await this.props.getAllMasterOtp();
    this.setState({ isFetching: false })
  };

  handleSwitchChangeReferral = async (event) => {
    this.setState({ isFetching: true })
    await this.props.setCofiguration({ enableReferAndEarn: event.target.checked });
    await this.props.getAllMasterOtp();
    this.setState({ isFetching: false })
  };

  addOrderByTime = async () => {
    let hasError = false;
    this.setState({ isFetching: true })
    if (!this.state.orderByTime || this.state.orderByTime.length === 0) {
      hasError = true;
      this.setState({ orderByTimeError: true })
    }

    if (this.state.orderByTime) {
      if (this.state.orderByTime.trim().length === 0) { hasError = true; this.setState({ orderByTimeError: true }) }
      if (!/^[0-9]+$/i.test(this.state.orderByTime.trim())) { hasError = true; this.setState({ orderByTimeValidationError: true }) }
      if (/\s/g.test(this.state.orderByTime)) { hasError = true; this.setState({ orderByTimeSpaceError: true }) }
    }

    if (!hasError) {
      await this.props.setCofiguration({ orderByTime: this.state.orderByTime });
      await this.props.getAllMasterOtp();
      // this.setState({orderByTime:""});
    }
    this.setState({ isFetching: false })

  };
  addEmailNotications = async () => {
    let hasError = false;
    this.setState({ isFetching: true })
    await this.validatEmailNotification()
    if( this.state.emailNotificationRequiredError || this.state.emailNotificationValidateError || this.state.emailRepeatError) {
      hasError = true;
    }
    if(!hasError) {
      let array = this.state.emailNotificationArray;
      array.push(this.state.emailNotification);
      this.setState({emailNotificationRequiredError :false , emailRepeatError:false,emailNotificationValidateError :false ,emailNotificationArray:array})
      await this.props.setCofiguration ({emailNotification :this.state.emailNotification});
      await this.props.getAllMasterOtp();
    }
    this.setState({ isFetching: false ,emailNotification:""})

  }

  addXirifySupportNumber = async () => {
    let hasError = false;
    // this.setState({isFetching:true})
    // if(!this.state.xirifySupportNo || this.state.xirifySupportNo.length==0){
    //   hasError = true;
    //   this.setState({exotelSupportError:true})
    // }

    // if(this.state.xirifySupportNo){
    //   if(this.state.xirifySupportNo.trim().length==0){hasError = true; this.setState({exotelSupportError:true})}
    //   if(!/^[0-9]+$/i.test(this.state.xirifySupportNo.trim())){hasError = true; this.setState({xirifySupportNumberError:true})}
    //   if(/\s/g.test(this.state.xirifySupportNo)){hasError = true; this.setState({orderByTimeSpaceError: true})}
    // }

    if (this.state.xirifySupportNo.trim().length !== 10 && this.state.xirifySupportNo.trim().length !== 0) {
      hasError = true;
      this.setState({ phoneValidationError: true });
    }

    if (!hasError) {
      this.setState({ phoneValidationError: false })
      await this.props.setCofiguration({ xirifySupportNo: this.state.xirifySupportNo });
      await this.props.getAllMasterOtp();
      // this.setState({xirifySupportNo:""});
    }
    this.setState({ isFetching: false })

  };

  handleChangeTab = (event, newValue) => {
    console.log("newvalue==>",newValue)
    this.setState({ value: newValue });
  };

  close = async () => {
    this.setState({
      firstRequired: false,
      secondRequired: false,
      thirdRequired: false,
      editExotelConfig: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      orderByTimeError: false,
      orderByTimeValidationError: false,
      orderByTimeSpaceError: false,
      reasonError: false,
      reasonErrorExists: false,
      reasonEditErrorExists: false,
      // error: {}
    });
    if (!_.isEmpty(this.state.error)) {
      this.setState({ error: {} })
    }
  };
  handleGracePeriodChange = (event) =>{
      this.setState({gracePeriodDays:event.target.value})  
  }
  handlePaymentCycleChange = (event) =>{
      this.setState({paymentCycleDay:event.target.value})  
      this.setState({gracePeriodDays:event.target.value,savePaymentCycle:true,cancelPayment:true})  
  }
  handleBlockDateForPaymentNotDone = (event) =>{
      this.setState({blockDateIfNotPaid:event.target.value,savePaymentCycle:true,cancelPayment:true})  
  }
  handlePaymentCycleChange = (event) =>{
    
    
      const newValue = event.target.value.replace(/\D/, '');
      console.log("newValue",newValue);
      if (newValue !== '' && (parseInt(newValue) < 1 || parseInt(newValue) > 31)) {
        return;
      }    
      this.setState({paymentCycleDay:event.target.value,paymentCycleError:false,savePaymentCycle:true,cancelPayment:true})  
    
  }
  handleEmailNotificationChange = async (event) => {
    this.setState({ emailNotification: event.target.value,emailNotificationRequiredError :false ,emailNotificationValidateError:false,emailRepeatError:false})

  };
  handleEmailDelete = async (email) => {
    let deleteEmail = this.state.emailNotificationArray.filter(i => i !== email);
    this.setState({emailNotificationArray: deleteEmail }); 
    await this.props.setCofiguration ({emailNotificationDelete :email});
    await this.props.getAllMasterOtp();

  }

  handleXirifySupportChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      exotelSupportError: false,
      xirifySupportNumberError: false,
      xirifySupportSpaceError: false
    });
    if (!_.isEmpty(this.state.error)) {
      this.setState({ error: {} })
    }
  };

  handleSnackClose = () => {
    this.setState({
      snackOpen: false,
      sucessMsg: null,
      errorMsg: null,
      sucessMsgBusinessDashboard: null,
      errorMsgBusinessDashboard: null,
      errorCouponExpiryMsg: null,
      successCouponExpiryMsg: null,
      sucessMsgDeleteOpenCloseRecords: null,
      errorMsgDeleteOpenCloseRecords: null,
      successCashfreeSplitVendorSettlement : null,
      errorCashfreeSplitVendorSettlement : null,
      minOrderSuccessMsg: null
    });
  };

  runSettlement = async () => {
    this.setState({ isFetching: true })
    let flag = await this.validate();
    if (parseInt(flag)) {
      let payload = {
        asOnDate: this.state.date
      };
      await this.props.settlementCronJob(payload);
    }
    this.setState({ isFetching: false, date: "" })
  };

  runCouponSettlement = async () => {
    this.setState({ isFetching: true })
    let flag = this.validateCouponDate();
    if (parseInt(flag)) {
      let payload = {
        asOnDate: this.state.coupondate
      };
      await this.props.couponSettlementCronJob(payload);
    }
    this.setState({ isFetching: false, coupondate: "" })
  };

  runCouponExpiry = async () => {
    this.props.callBusinessDashboardCron();
    await this.props.couponExpiryCron();
    this.props.callBusinessDashboardCronReset();
  };

  handleOpenCloseModal = () => {

    this.setState({ handleOpenCloseModal: true }) 
  };  

  closeOpenCloseModal = () => {
    this.setState({ handleOpenCloseModal: false })
  };

  runDeleteOpenCloseRecords = async () => {
    // console.log("---its calling----")
    this.setState({ isFetching: true })
    // let flag = await this.validateDeleteOpenCloseDate();

    let payload = {
      asOnDate: moment(this.state.deleteOpenCloseDate).format("YYYY-MM-DD")
    };
    // console.log("---------------payload----------------", this.state.deleteOpenCloseDate)
    await this.props.deleteOpenCloseRecords(payload);

    this.setState({ isFetching: false, deleteOpenCloseDate: "" });
    this.closeOpenCloseModal();
  };

  validateCouponDate = () => {
    let flag = 1;
    let error = this.state.error;
    if (!this.state.coupondate) {
      error.coupondate = "Please enter date";
      flag = 0;
    }
    this.setState({ error });
    return flag;
  };

  validateDeleteOpenCloseDate = () => {
    let flag = 1;
    let errorForOpenClose = this.state.error;
    let now = new Date();
    let checkDate = new Date(this.state.deleteOpenCloseDate)
    if (!this.state.deleteOpenCloseDate) {
      errorForOpenClose.deleteOpenCloseDate = "Please enter date";
      flag = 0;
    } else if (now < checkDate) {
      errorForOpenClose.deleteOpenCloseDate = "Please select current or previous date";
      flag = 0;
    }
    this.setState({ errorForOpenClose });
    // console.log("----error----", errorForOpenClose.deleteOpenCloseDate)
    if (errorForOpenClose.deleteOpenCloseDate === undefined){
      // console.log("---I am in if condition")
      this.handleOpenCloseModal()}
    return flag;

  };

  validate = () => {
    let flag = 1;
    let error = this.state.error;
    let now = new Date();
    let checkDate = new Date(this.state.date)
    if (!this.state.date) {
      error.date = "Please enter date";
      flag = 0;
    } else if (now < checkDate) {
      error.date = "Please select current or previous date";
      flag = 0;
    }
    this.setState({ error });
    return flag;
  };
  validatEmailNotification = async () => {
    // console.log("---inside validate====")

    if (!this.state.emailNotification) {
      //  console.log("----in emailNotification error----")
      this.setState({ emailNotificationRequiredError: true });

    }
    if (this.state.emailNotification && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.emailNotification)) {
      //  console.log("----in emailNotification error----")
      this.setState({ emailNotificationValidateError: true });

    }
    let existEmail = [];
    let addedEmail = "";
     existEmail = this.props.dashboard.getMasterOTP.data[0].supportEmailIDs ;
     addedEmail = this.state.emailNotification;
     let check = existEmail.includes(addedEmail);
     if (check){
      this.setState({ emailRepeatError : true });
     }
  }

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleEditModalOpen = () => {
    this.setState({ modalOpenEdit: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false, reasonEditModalOpen: false, reasonEditError: false, reasonErrorExists: false, reasonEditErrorExists: false });
  };

  addReferrals = async (payload) => {
    this.setState({ isFetching: true })
    let payloadReferred = {
      referralAmount: Number(payload.referralAmountReferred),
      minimumOrderValue: Number(payload.minimumOrderValueReferred),
      couponExpiry: Number(payload.couponExpiryReferred),
      type: "referredUser",
      enableReferAndEarn: !this.state.enableReferAndEarn,
      referralCouponImage: payload.referralCouponImage ? payload.referralCouponImage : "",
      referralCouponName: payload.referralCouponName ? payload.referralCouponName : "",
      termsAndConditionsUrl: payload.termsAndConditionsUrl ? payload.termsAndConditionsUrl : "",
    }

    let payloadReferring = {
      referralAmount: Number(payload.referralAmountReferring),
      minimumOrderValue: Number(payload.minimumOrderValueReferring),
      couponExpiry: Number(payload.couponExpiryReferring),
      type: "referringUser",
      enableReferAndEarn: !this.state.enableReferAndEarn,
      referralCouponImage: payload.referralCouponImage ? payload.referralCouponImage : "",
      referralCouponName: payload.referralCouponName ? payload.referralCouponName : "",
      termsAndConditionsUrl: payload.termsAndConditionsUrl ? payload.termsAndConditionsUrl : "",
    }
    await this.props.setCofiguration(payloadReferred);
    await this.props.setCofiguration(payloadReferring);
    await this.props.getAllMasterOtp();
    this.setState({ modalOpen: false, enableReferAndEarn: !this.state.enableReferAndEarn, isFetching: false });
  };

  updateReferrals = async (payload) => {
    this.setState({ isFetching: true })
    let payloadReferred = {
      referralAmount: Number(payload.referralAmountReferred),
      minimumOrderValue: Number(payload.minimumOrderValueReferred),
      couponExpiry: Number(payload.couponExpiryReferred),
      type: "referredUser",
      referralCouponImage: payload.referralCouponImage ? payload.referralCouponImage : "",
      referralCouponName: payload.referralCouponName ? payload.referralCouponName : "",
      termsAndConditionsUrl: payload.termsAndConditionsUrl ? payload.termsAndConditionsUrl : "",
    }

    let payloadReferring = {
      referralAmount: Number(payload.referralAmountReferring),
      minimumOrderValue: Number(payload.minimumOrderValueReferring),
      couponExpiry: Number(payload.couponExpiryReferring),
      type: "referringUser",
      referralCouponImage: payload.referralCouponImage ? payload.referralCouponImage : "",
      referralCouponName: payload.referralCouponName ? payload.referralCouponName : "",
      termsAndConditionsUrl: payload.termsAndConditionsUrl ? payload.termsAndConditionsUrl : "",
    }
    await this.props.setCofiguration(payloadReferred);
    await this.props.setCofiguration(payloadReferring);
    await this.props.getAllMasterOtp();
    this.setState({ modalOpen: false, isFetching: false });
  };

  editReferrals = async (referralAmountReferred, minimumOrderValueReferred, couponExpiryReferred, referralAmountReferring, minimumOrderValueReferring, couponExpiryReferring, referralCouponName, referralCouponImage, termsAndConditionsUrl) => {
    let payload = { referralAmountReferred, minimumOrderValueReferred, couponExpiryReferred, referralAmountReferring, minimumOrderValueReferring, couponExpiryReferring, referralCouponName, referralCouponImage, termsAndConditionsUrl }
    this.setState({
      editReferrals: true,
      referralData: payload
    })
    this.handleModalOpen();
  };

  uploadSplashScreen = async (files, photoType) => {
    if (files && files[0]) {
      this.setState({ isFetching: true })
      let data = new FormData();
      let folderPath = "Marketplace_Business";
      let temp = files[0].name.split(".");
      let fileName = temp[0] + Math.ceil(Math.random() * 999999) + "." + temp[1];

      data.append("file", files[0]);
      data.append("folder", folderPath);
      data.append(
        "public_id",
        `SplashScreen/${fileName}`
      );
      data.append(
        "upload_preset",
        this.props.env.cloudinaryBusinessImagesUploadPreset
      );
      let res = await fetch(this.props.env.cloudinaryUploadUrl, {
        method: "POST",
        body: data,
      });
      let file = await res.json();
      if (photoType === "UploadSplashScreenInput") {
        let splashScreenURL = file?.secure_url;
        await this.props.updateSplashScreenUrl({ splashScreenUrl: splashScreenURL });
        this.setState({ splashScreenURL });
        this.setState({ isUploading: false, typePhoto: photoType, isFetching: false });
      }
    }
  };

  editExotelTimeConfiguration = async () => {
    await this.props.getAllMasterOtp();
    this.setState({
      firstReminder: this.props.dashboard.getMasterOTP.data[0].exotelConfig[0].minute,
      secondReminder: this.props.dashboard.getMasterOTP.data[0].exotelConfig[1].minute,
      thirdReminder: this.props.dashboard.getMasterOTP.data[0].exotelConfig[2].minute,
      editExotelConfig: true
    })
  };

  editExotelTimeConfigClose = () => {
    this.setState({ editExotelConfig: false });
  };

  // saveSplashScreen = async ()=>{
  //   this.setState({isFetching:true})
  //   await this.props.updateSplashScreenUrl({splashScreenUrl:this.state.splashScreenURL});
  //   this.setState({isFetching:false})
  // }

  smsProviderHandler = async (event) => {
    this.setState({ smsProvider: event.target.value });
    await this.props.setSmsProvider({ smsProviderFlag: event.target.value })
    await this.props.getAllMasterOtp();
  };

  businessDashboardCronHandler = async (event) => {
    this.setState({ businessDashboardCron: event.target.value, dailyDateError: false, dailyDateRequired: false, monthlyDateRequired: false, monthlyDateError: false, filterStartWeek: [], weeklyRequired: false, weeklyError: false, fromDate: "" });
  };

  handleFilterDailyDateChange = async (event) => {
    // console.log("event in daily cron------>", event);
    this.setState({ fromDate: event, dailyDateError: false, dailyDateRequired: false, monthlyDateRequired: false, monthlyDateError: false, filterStartWeek: [], weeklyRequired: false, weeklyError: false, });
    if (!_.isEmpty(this.state.error)) {
      this.setState({ error: {} })
    }
  };

  handleFilterMonthlyDateChange = async (event) => {
    // console.log("event in monthly cron----->", event);
    this.setState({ fromDate: event, monthlyDateRequired: false, monthlyDateError: false, dailyDateError: false, dailyDateRequired: false, filterStartWeek: [] });
    if (!_.isEmpty(this.state.error)) {
      this.setState({ error: {} })
    }
  };

  handleStartWeekChange = date => {
    // console.log("=======in handleStartWeekChange")
    // console.log("date in handle change---->", date)
    this.setState({
      filterStartWeek: getWeekDays(getWeekRange(date).from), weeklyRequired: false, weeklyError: false, monthlyDateRequired: false, monthlyDateError: false, dailyDateError: false, dailyDateRequired: false
    });
    if (!_.isEmpty(this.state.error)) {
      this.setState({ error: {} })
    }
  };

  handleStartWeekClick = (weekNumber, days, e) => {
    // console.log("=======in handleStartWeekClick");
    // console.log("dayyyys----->", days)
    this.setState({
      filterStartWeek: days, weeklyRequired: false, weeklyError: false, monthlyDateRequired: false, monthlyDateError: false, dailyDateError: false, dailyDateRequired: false
    });
    if (!_.isEmpty(this.state.error)) {
      this.setState({ error: {} })
    }
  };

  handleXirifyChargesSwitch = async (event) => {
    // console.log("HandleSwitchChargesSwitch",event.target.checked)
    this.setState({ isFetching: true })
    await this.props.setCofiguration({ isXirifyDelivery: event.target.checked });
    await this.props.getAllMasterOtp();
    this.setState({ isFetching: false })
  };

  
  handlebasePriceChange = async (event) => {
    this.setState({ basePrice: event.target.value,basePriceError:false,basePriceSpaceError:false })

  };

  handlepricePerKMChange = async (event) => {
    this.setState({ pricePerKM: event.target.value, pricePerKMError:false, pricePerKMSpaceError:false  })

  };
  
  handleDefaultBusinessRadiusChange = async (event) => {
    // console.log("event.target.vakue", event.target.value)
    this.setState({ defaultBusinessRadius: event.target.value, defaultBusinessRadiusError:false })

  };
  handleMinOrdersOfConsumerForPostPaymentChange = async (event) => {
    // console.log("event.target.vakue", event.target.value)
   await this.setState({ minOrdersOfConsumerForPostPayment: event.target.value})

    let hasError = false;
    if(!this.state.minOrdersOfConsumerForPostPayment || this.state.minOrdersOfConsumerForPostPayment == ""){
       hasError = true;
      this.setState({ minOrdersOfConsumerForPostPaymentError: true, minOrdersOfConsumerForPostPaymentErrorText : "Required" })
    }
    if(this.state.minOrdersOfConsumerForPostPayment && !(/^\d+$/.test(this.state.minOrdersOfConsumerForPostPayment))){
       hasError = true;
      this.setState({ minOrdersOfConsumerForPostPaymentError: true, minOrdersOfConsumerForPostPaymentErrorText : "Invalid" })
    }
    
   
    if (!hasError){
      this.setState({minOrdersOfConsumerForPostPaymentError : false, minOrdersOfConsumerForPostPaymentErrorText : ""})
    }

  };

  xirifyCharges = async () => {
    let hasError = false;
    this.setState({ isFetching: true })
    if (!this.state.pricePerKM || this.state.pricePerKM === "") {
      hasError = true;
      this.setState({ pricePerKMError: true })
    }
    if (this.state.pricePerKM && this.state.pricePerKM !== "") {
      // console.log("xirifyChargespricePerKM",this.state.pricePerKM)
      // /^(\d{0,9})(\.\d{1,2})?$/
      if (! /^(?!0)[0-9]*$/.test(this.state.pricePerKM)) { hasError = true; this.setState({ pricePerKMSpaceError: true }) }
    }
    if (!this.state.basePrice || this.state.basePrice === "") {
      hasError = true;
      this.setState({ basePriceError: true })
    }
    if (this.state.basePrice && this.state.basePrice !== "") {
      // console.log("xirifyChargesbasePrice",this.state.basePrice)
      if (! /^(?!0)[0-9]*$/.test(this.state.basePrice)) { hasError = true; this.setState({ basePriceSpaceError: true }) }
    }
   
    let payload = {
        xirifyDeliveryCharges: {
          basePrice: this.state.basePrice ,
          pricePerKM: this.state.pricePerKM ,
          baseKM: this.state.baseKM 
      },
    }
   if(!hasError){
      await this.props.setCofiguration(payload)
      await this.props.getAllMasterOtp();
          }
    this.setState({ isFetching: false})     
  }
  saveDefaultBusinessRadius = async () => {
    let hasError = false;
    this.setState({ isFetching: true })
    if ((this.state.defaultBusinessRadius && !/^([\d]+)(?:\.([\d]{1,3}?))?$/.test(this.state.defaultBusinessRadius)) || this.state.defaultBusinessRadius === ""){     
      this.setState({defaultBusinessRadiusError: true});
      hasError = true;    
   
    };  
    let payload = {defaultBusinessRadius: parseInt(this.state.defaultBusinessRadius)}
   if(!hasError){
      await this.props.setCofiguration(payload)
      await this.props.getAllMasterOtp();
          }
    this.setState({ isFetching: false})     
  }

  closeDetails = () => {
    this.setState({
    basePrice:this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges.basePrice,
    pricePerKM:this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges.pricePerKM,
    baseKM:this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges.baseKM,
    basePriceError:false,
    baseKMError:false,
    pricePerKMError:false,
    basePriceSpaceError:false,
    pricePerKMSpaceError:false
    })
  };
  closeDetailsOfDefaultBusinessRadius = () => {
    this.setState({
    defaultBusinessRadius:this.props.dashboard.getMasterOTP.data[0].defaultBusinessRadius,
    defaultBusinessRadiusError : false
    })
  };
 
  runBusinessDashboardCron = async () => {
    // console.log("======== runBusinessDashboardCron");
    let hasError = false;
    if (this.state.businessDashboardCron === "Weekly") {
      // console.log("==== state filterStartWeek=====", this.state.filterStartWeek); //[0] && [6]

      if (this.state.filterStartWeek.length === 0) {
        hasError = true;
        this.setState({ weeklyRequired: true })
      }

      if (this.state.filterStartWeek.length > 0) {
        let startWeek = this.state.filterStartWeek[0];
        let endWeek = this.state.filterStartWeek[6];
        let currentStartWeekDate = moment().startOf("week").add(1, 'day');
        // console.log("currentStartWeekDate----->", currentStartWeekDate);
        let currentStartWeekTempDate = new Date(moment(currentStartWeekDate).startOf("week"));
        let currentEndWeekDate = moment().endOf("week").add(1, 'day');
        // console.log("currentEndWeekDate----->", currentEndWeekDate);
        let currentEndWeekTempDate = new Date(moment(currentEndWeekDate).endOf("week"));

        let tempCurrentStartWeekDate = moment(startWeek).startOf("week");
        // console.log("tempCurrentStartWeekDate----->", tempCurrentStartWeekDate)
        let rangeCurrentStartWeekTemp = new Date(moment(tempCurrentStartWeekDate).startOf("week"));
        let tempCurrentEndWeekDate = moment(endWeek).endOf("week");
        // console.log("tempCurrentEndWeekDate----->", tempCurrentEndWeekDate);
        let rangeCurrentEndWeekTemp = new Date(moment(tempCurrentEndWeekDate).endOf("week"));

        if (currentStartWeekTempDate <= rangeCurrentStartWeekTemp && currentEndWeekTempDate <= rangeCurrentEndWeekTemp) {
          hasError = true;
          this.setState({ weeklyError: true })
        }
      }


      if (!hasError) {
        this.props.callBusinessDashboardCron();
        let start = this.state.filterStartWeek[0];
        let end = this.state.filterStartWeek[6];
        const ranges = {};
        let tempDate = moment(start).startOf("day");
        // console.log("tempDate------>", tempDate);
        let rangeStartTemp = new Date(moment(tempDate).startOf("day"));
        ranges.rangeStart = new Date(rangeStartTemp);

        let tempEndDate = moment(end).startOf("day");
        // console.log("tempEndDate------>", tempEndDate);
        let rangeEndTemp = new Date(moment(tempEndDate)
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss"));
        ranges.rangeEnd = new Date(rangeEndTemp);
        // console.log(ranges);
        let payload = {
          cronJobtype: "WEEKLY",
          fromDate: ranges.rangeStart,
          // toDate:ranges.rangeEnd
          toDate: moment(ranges.rangeEnd).format('YYYY-MM-DD[T18:29:59.999Z]')
        }

        await this.props.businessDashboardCronWeekly(payload);
        this.setState({ fromDate: "", businessDashboardCron: "", filterStartWeek: [] });
        this.props.callBusinessDashboardCronReset();
      }
    }
    if (this.state.businessDashboardCron === "Daily") {
      let currentDate = moment().startOf("day");
      let currentTempDate = new Date(moment(currentDate).startOf("day"));
      let tempCurrentDate = moment(this.state.fromDate).startOf("day");
      let rangeCurrentStartTemp = new Date(moment(tempCurrentDate).startOf("day"));

      if (!this.state.fromDate) {
        hasError = true;
        this.setState({ dailyDateRequired: true });
      }
      if (currentTempDate <= rangeCurrentStartTemp) {
        hasError = true;
        this.setState({ dailyDateError: true })
      }

      if (!hasError) {
        this.props.callBusinessDashboardCron();
        // console.log(this.state)
        // console.log("from date----->", this.state.fromDate);

        const ranges = {};
        // var dailyDate = new Date(this.state.fromDate);
        // let date = JSON.stringify(dailyDate)
        // date = date.slice(1,11);
        // console.log(date)
        let tempDate = moment(this.state.fromDate).startOf("day");
        // console.log("tempDate------>", tempDate);
        let rangeStartTemp = new Date(moment(tempDate).startOf("day"));
        // console.log("rangeStartTemp------>", rangeStartTemp);
        let rangeEndTemp = new Date(moment(tempDate)
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss"));
        ranges.rangeStart = new Date(rangeStartTemp);
        ranges.rangeEnd = new Date(rangeEndTemp);
        // console.log(ranges);

        let payload = {
          cronJobtype: "DAILY",
          fromDate: ranges.rangeStart,
          // toDate:ranges.rangeEnd
          toDate: moment(ranges.rangeEnd).format('YYYY-MM-DD[T18:29:59.999Z]')
        }

        await this.props.businessDashboardCronDaily(payload);
        this.setState({ fromDate: "", businessDashboardCron: "" });
        this.props.callBusinessDashboardCronReset();
      }
    }
    if (this.state.businessDashboardCron === "Monthly") {
      let currentMonth = moment().startOf("month");
      let currentTempDate = new Date(moment(currentMonth).startOf("day"));
      let tempCurrentMonth = moment(this.state.fromDate).startOf("month");
      let rangeCurrentMonthStartTemp = new Date(moment(tempCurrentMonth).set("date", 1).startOf("day"));

      if (!this.state.fromDate) {
        hasError = true;
        this.setState({ monthlyDateRequired: true });
      }

      if (currentTempDate <= rangeCurrentMonthStartTemp) {
        hasError = true;
        this.setState({ monthlyDateError: true })
      }

      if (!hasError) {
        this.props.callBusinessDashboardCron();
        const ranges = {};
        // var dailyDate = new Date(this.state.fromDate);
        let tempDate = moment(this.state.fromDate).startOf("month");
        // console.log("tempDate------>", tempDate);
        let rangeStartTemp = new Date(moment(tempDate).set("date", 1).startOf("day"));
        // console.log("rangeStartTemp------>", rangeStartTemp);
        let rangeEndTemp = new Date(moment(tempDate)
          // .subtract(1, 'months')
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"));
        ranges.rangeStart = new Date(rangeStartTemp);
        ranges.rangeEnd = new Date(rangeEndTemp);

        // console.log("rangeEndTemp------>", rangeEndTemp);
        // console.log(ranges);
        let payload = {
          cronJobtype: "MONTHLY",
          fromDate: ranges.rangeStart,
          // toDate:ranges.rangeEnd
          toDate: moment(ranges.rangeEnd).format('YYYY-MM-DD[T18:29:59.999Z]')
        }

        await this.props.businessDashboardCronMonthly(payload);
        this.setState({ fromDate: "", businessDashboardCron: "" });
        this.props.callBusinessDashboardCronReset();
      }
    }
  };
  savePaymentDetails = async () => {
   
      let hasError = false;
       this.setState({ isFetching: true })
      // if ((this.state.defaultBusinessRadius && !/^([\d]+)(?:\.([\d]{1,3}?))?$/.test(this.state.defaultBusinessRadius)) || this.state.defaultBusinessRadius === ""){     
      //   this.setState({defaultBusinessRadiusError: true});
      //   hasError = true;    
     
      // };  
      let payload = {paymentCycleDateOfDay : parseInt(this.state.paymentCycleDay),
        consumerBlockDateNumber:parseInt(this.state.blockDateIfNotPaid),
        gracePeriodInDays: parseInt(this.state.gracePeriodDays),
        minOrdersOfConsumerForPostPayment : parseInt(this.state.minOrdersOfConsumerForPostPayment)
      }
     if(!hasError && !this.state.minOrdersOfConsumerForPostPaymentError){
        await this.props.setCofiguration(payload)
        await this.props.getAllMasterOtp();
            }
      this.setState({ isFetching: false})     
    
  }
  handleClosePaymentDetails = async () => {
    let hasError = false;
    await this.setState({
    minOrdersOfConsumerForPostPayment:this.props.dashboard.getMasterOTP.data[0].minOrdersOfConsumerForPostPayment,
    minOrdersOfConsumerForPostPaymentError : false,
    minOrdersOfConsumerForPostPaymentErrorText: ""
    })

      if(!this.state.paymentCycleDay || !this.state.blockDateIfNotPaid || !this.state.gracePeriodDays){
        this.setState({paymentCycleError:true})
        hasError = true;
      }
      
      console.log("haserror",hasError);
      if(!hasError){
      let payload = {paymentCycleDateOfDay : parseInt(this.state.paymentCycleDay),
    consumerBlockDateNumber:parseInt(this.state.blockDateIfNotPaid),
    gracePeriodInDays: parseInt(this.state.gracePeriodDays),}
    
        await this.props.setCofiguration(payload)
        await this.props.getAllMasterOtp();
            
      this.setState({ isFetching: false,savePaymentCycle:false,cancelPayment:false})     
          }
  }

  closePaymentCycleDetails = async ()=>{

     console.log("this.props",this.props?.dashboard?.getMasterOTP?.data[0]?.paymentCycleDateOfDay); 
     console.log("this.props==>",this.props.dashboard.getMasterOTP); 
     if(this.props?.dashboard?.getMasterOTP?.data[0]?.paymentCycleDateOfDay){
      let paymentNumber = this.props.dashboard.getMasterOTP.data[0].paymentCycleDateOfDay;
      let blockNumber = this.props.dashboard.getMasterOTP.data[0].consumerBlockDateNumber;
      let graceNumber = this.props.dashboard.getMasterOTP.data[0].gracePeriodInDays;
      this.setState({paymentCycleDay:paymentNumber,gracePeriodDays:graceNumber,blockDateIfNotPaid:blockNumber});

     }
//       else {
//     let currentDate = new Date();
//     let currentMonth = currentDate.getMonth();
//     let currentYear = currentDate.getFullYear();
    
//     // Create a new Date object for the first day of the previous month
//     let firstDayOfPreviousMonth = new Date(currentYear, currentMonth +1 , 1);
    
//     // Subtract one day to get the last day of the current month
//     let lastDayOfPrevMonth = new Date(firstDayOfPreviousMonth - 86400000); //
//     console.log("..lastDayOfPrevMonth.",lastDayOfPrevMonth);
//     // console.log("..lastDayOfPrevMonth.",lastDayOfPrevMonth.getMonth());
//     let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
// let lastDayMonthString = monthNames[lastDayOfPrevMonth.getMonth()];
// console.log("lastDayMonthString",lastDayMonthString);

//      this.setState({paymentCycleDay:lastDayOfPrevMonth.getDate(),monthNames:lastDayMonthString,gracePeriodDays:1,blockDateIfNotPaid:1});
//      }

  }
  handlePromoCodeValidationFlag = async (event) => {
    // this.setState({ [event.target.name]: event.target.value});
    await this.props.setCofiguration({ promoCodeValidationFlag: event.target.checked })
    await this.props.getAllMasterOtp();
  };
  handleIsOrderBLockFunctionFlag = async (event) => {
    console.log("event.target.value",event.target.checked);
       this.setState({ isOrderBlockFunction: event.target.checked});
    await this.props.setCofiguration({ isOrderBlockFunction: event.target.checked })
    await this.props.getAllMasterOtp();
  };

  handleDeleteOpen = (photo) => {
    this.setState({ modalDeleteOpen: true });
    this.setState({ selectedPhoto: photo });
  };

  handleDeleteClose = () => {
    this.setState({ modalDeleteOpen: false, modalReasonDeleteOpen: false});
  };

  handleDeleteSplashScreenTimer =  () =>{
     this.setState({splashScreenTimerModel:false,splashScreenTimer:this.props.dashboard.getMasterOTP.data[0].splashScreenTimer})
  }

  handleCheckboxClose = () => {
    this.setState({ handleModalCheckBoxAdmin: false, handleModalCheckBoxBusiness: false, handleModalCheckBoxShoppers: false });
  };

  deleteSpashScreen = async (selectedPhoto, photoType) => {
    this.setState({ isFetching: true })
    if (!photoType) { photoType = 'UploadSplashScreenInput' }
    await this.props.updateSplashScreenUrl({ splashScreenUrl: "" });
    if (photoType === "UploadSplashScreenInput") {
      this.setState({
        splashScreenURL: "",
        modalDeleteOpen: false
      });
    }
    this.setState({ isFetching: false })
  };

  setSplashScreenTimer = async()=>{
    let payload={
      splashScreenTimer: parseInt(this.state.splashScreenTimer),
      splashScreenUrl: this.state.splashScreenURL
    }
    await this.props.updateSplashScreenUrl(payload);
    this.setState({splashScreenTimerModel:false});
    await this.props.getAllMasterOtp();
  }

  saveExotelConfig = async () => {
    if (this.state.firstReminder > this.state.secondReminder || this.state.firstReminder === this.state.secondReminder) {
      this.setState({ firstRequired: true })
      return
    } if (this.state.secondReminder > this.state.thirdReminder || this.state.secondReminder < this.state.firstReminder || this.state.secondReminder === this.state.firstReminder || this.state.thirdReminder === this.state.secondReminder) {
      this.setState({ secondRequired: true })
      return
    } if (this.state.thirdReminder < this.state.secondReminder || this.state.thirdReminder < this.state.firstReminder || this.state.thirdReminder === this.state.secondReminder || this.state.thirdReminder === this.state.firstReminder) {
      this.setState({ thirdRequired: true })
      return
    }
    const payload = [
      {
        minute: this.state.firstReminder,
        whichReminder: '1st'
      },
      {
        minute: this.state.secondReminder,
        whichReminder: '2nd'
      },
      {
        minute: this.state.thirdReminder,
        whichReminder: '3rd'
      }
    ]

    await this.props.setExotelGlobalConfig(payload);
    await this.props.getAllMasterOtp();
    this.editExotelTimeConfigClose()
  };

  handleExotelReminders = (event) => {
    this.setState({ firstRequired: false, secondRequired: false, thirdRequired: false })
    this.setState({ [event.target.name]: event.target.value })
  };

  handleSwitchChangeCancellationReason = async (event, reason) => {
    if (reason !== undefined) {
      this.setState({ isFetching: true })
      let payload = {
        reasonsForCancellation: [{
          _id: reason._id,
          isActive: !reason.isActive,
          isAvailableForAdmin: reason.isAvailableForAdmin,
          isAvailableForBusiness: reason.isAvailableForBusiness,
          isAvailableForShoppers: reason.isAvailableForShoppers,
          reason: reason.reason
        }]
      }
      // console.log("Payload before sending into actions---->",payload);
      await this.props.saveOrderCancellationReason(payload);
      await this.props.getAllMasterOtp();
      // await this.props.getCancellationReasonByRole(0);
      if (this.state.role === "admin")
        await this.props.getCancellationReasonByRole(1);
      else if (this.state.role === "business")
        await this.props.getCancellationReasonByRole(2);
      else if (this.state.role === "shoppers")
        await this.props.getCancellationReasonByRole(3);
      else
        await this.props.getCancellationReasonByRole(0);
      this.setState({ isFetching: false });
    } else {
      this.setState({ isFetching: true, activeInactiveToggleFlag: !this.state.activeInactiveToggleFlag })
      this.setState({ isFetching: false })
    }
  };

  functionCall = async () => {
    this.setState({ role: "all" });
    await this.props.getCancellationReasonByRole(0);
  };

  addCancellationReason = () => {
    this.setState({ addReasonFlag: true });
  };

  getCashfreeSettlementRecord = async()=>{
    this.setState({ isFetching: true })
   await this.props.getCashfreeVendorSplitSettlementData()
   this.setState({ isFetching: false })
  };

  saveCancellationReason = async () => {
    // console.log("this.state--->",this.state)
    let hasError = false;

    if (!this.state.reason || this.state.reason.trim().length === 0) {
      hasError = true;
      this.setState({ reasonError: true });
    }

    if (this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data[0].reasonsForCancellation && this.props.dashboard.getMasterOTP.data[0].reasonsForCancellation.length > 0) {
      this.props.dashboard.getMasterOTP.data[0].reasonsForCancellation.forEach(reason => {
        if (reason.reason.trim() === this.state.reason.trim()) { this.setState({ reasonErrorExists: true }); hasError = true; }
      })
    }

    if (!hasError) {
      let trimedReason = this.state.reason.trim();
      let payload = {
        reasonsForCancellation: [{
          isAvailableForAdmin: this.state.adminCheckboxFlag,
          isAvailableForBusiness: this.state.businessCheckboxFlag,
          isAvailableForShoppers: this.state.shopperCheckboxFlag,
          reason: trimedReason,
          isActive: this.state.activeInactiveToggleFlag,
        }]
      }
      // console.log("Payload before sending into actions---->",payload);
      await this.props.saveOrderCancellationReason(payload);
      // await this.props.getAllMasterOtp();

      // await this.props.getCancellationReasonByRole(0);

      if (this.state.role === "admin")
        await this.props.getCancellationReasonByRole(1);
      else if (this.state.role === "business")
        await this.props.getCancellationReasonByRole(2);
      else if (this.state.role === "shoppers")
        await this.props.getCancellationReasonByRole(3);
      else
        await this.props.getCancellationReasonByRole(0);

      this.setState({
        addReasonFlag: false, adminCheckboxFlag: false,
        businessCheckboxFlag: false,
        shopperCheckboxFlag: false,
        activeInactiveToggleFlag: false,
        reasonErrorExists: false,
        reason: "",
      });

    }
  };

  editCancellationReason = async (reasonObj, editedReason) => {
    // console.log("reasonObj--->",reasonObj);
    // console.log("editedReason--->",editedReason);

    let hasError = false;

    if (!this.state.reasonEdit || this.state.reasonEdit.trim().length === 0) {
      hasError = true;
      this.setState({ reasonEditError: true });
    }

    if (this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data[0].reasonsForCancellation && this.props.dashboard.getMasterOTP.data[0].reasonsForCancellation.length > 0) {
      this.props.dashboard.getMasterOTP.data[0].reasonsForCancellation.forEach(reason => {
        if (reason.reason.trim() === editedReason.trim()) { this.setState({ reasonEditErrorExists: true }); hasError = true; }
      })
    }

    if (!hasError) {
      let trimedEditedReason = editedReason.trim();
      let payload = {
        reasonsForCancellation: [{
          reason: trimedEditedReason,
          _id: reasonObj._id,
          isActive: reasonObj.isActive,
          isAvailableForAdmin: reasonObj.isAvailableForAdmin,
          isAvailableForBusiness: reasonObj.isAvailableForBusiness,
          isAvailableForShoppers: reasonObj.isAvailableForShoppers
        }]
      }
      // console.log("Payload before sending into actions---->",payload);

      await this.props.saveOrderCancellationReason(payload);
      // await this.props.getAllMasterOtp();
      // await this.props.getCancellationReasonByRole(0);
      if (this.state.role === "admin")
        await this.props.getCancellationReasonByRole(1);
      else if (this.state.role === "business")
        await this.props.getCancellationReasonByRole(2);
      else if (this.state.role === "shoppers")
        await this.props.getCancellationReasonByRole(3);
      else
        await this.props.getCancellationReasonByRole(0);
      this.handleModalClose();
      this.setState({
        addReasonFlag: false, adminCheckboxFlag: false,
        businessCheckboxFlag: false,
        shopperCheckboxFlag: false,
        activeInactiveToggleFlag: false,
        reason: "", reasonEdit: "", reasonEditErrorExists: false
      });
    }
  };

  handleCheckBoxAdmin = async (reason) => {
    // console.log("reason---->",reason)
    if (reason !== undefined) {
      // console.log("if condition",reason);
      let payload = {
        reasonsForCancellation: [{
          _id: reason._id,
          isAvailableForAdmin: !reason.isAvailableForAdmin,
          isAvailableForBusiness: reason.isAvailableForBusiness,
          isAvailableForShoppers: reason.isAvailableForShoppers,
          reason: reason.reason,
          isActive: reason.isActive,

        }]
      }
      // console.log("Payload before sending into actions---->",payload);

      await this.props.saveOrderCancellationReason(payload);
      await this.props.getAllMasterOtp();
      this.setState({ handleModalCheckBoxAdmin: false, handleModalCheckBoxBusiness: false, handleModalCheckBoxShoppers: false });
      // await this.props.getCancellationReasonByRole(0);
      if (this.state.role === "admin")
        await this.props.getCancellationReasonByRole(1);
      else if (this.state.role === "business")
        await this.props.getCancellationReasonByRole(2);
      else if (this.state.role === "shoppers")
        await this.props.getCancellationReasonByRole(3);
      else
        await this.props.getCancellationReasonByRole(0);
    } else {
      this.setState({ adminCheckboxFlag: !this.state.adminCheckboxFlag });
    }
  };

  handleCheckBoxBusiness = async (reason) => {
    // console.log("reason---->",reason)
    if (reason !== undefined) {
      let payload = {
        reasonsForCancellation: [{
          _id: reason._id,
          isAvailableForBusiness: !reason.isAvailableForBusiness,
          reason: reason.reason,
          isActive: reason.isActive,
          isAvailableForAdmin: reason.isAvailableForAdmin,
          isAvailableForShoppers: reason.isAvailableForShoppers
        }]
      }
      // console.log("Payload before sending into actions---->",payload);

      await this.props.saveOrderCancellationReason(payload);
      await this.props.getAllMasterOtp();
      this.setState({ handleModalCheckBoxAdmin: false, handleModalCheckBoxBusiness: false, handleModalCheckBoxShoppers: false });
      // await this.props.getCancellationReasonByRole(0);
      if (this.state.role === "admin")
        await this.props.getCancellationReasonByRole(1);
      else if (this.state.role === "business")
        await this.props.getCancellationReasonByRole(2);
      else if (this.state.role === "shoppers")
        await this.props.getCancellationReasonByRole(3);
      else
        await this.props.getCancellationReasonByRole(0);
    } else {
      this.setState({ businessCheckboxFlag: !this.state.businessCheckboxFlag });
    }
  };

  handleCheckBoxShopper = async (reason) => {
    // console.log("reason---->",reason)
    if (reason !== undefined) {
      let payload = {
        reasonsForCancellation: [{
          _id: reason._id,
          isAvailableForShoppers: !reason.isAvailableForShoppers,
          isAvailableForBusiness: reason.isAvailableForBusiness,
          reason: reason.reason,
          isActive: reason.isActive,
          isAvailableForAdmin: reason.isAvailableForAdmin,
        }]
      }
      // console.log("Payload before sending into actions---->",payload);

      await this.props.saveOrderCancellationReason(payload);
      await this.props.getAllMasterOtp();
      this.setState({ handleModalCheckBoxAdmin: false, handleModalCheckBoxBusiness: false, handleModalCheckBoxShoppers: false });
      // await this.props.getCancellationReasonByRole(0);
      if (this.state.role === "admin")
        await this.props.getCancellationReasonByRole(1);
      else if (this.state.role === "business")
        await this.props.getCancellationReasonByRole(2);
      else if (this.state.role === "shoppers")
        await this.props.getCancellationReasonByRole(3);
      else
        await this.props.getCancellationReasonByRole(0);
    } else {
      this.setState({ shopperCheckboxFlag: !this.state.shopperCheckboxFlag });
    }
  };

  handleFilterChange = async (event) => {
    this.setState({ [event.target.name]: event.target.value });

    // console.log("from event.target.value",event.target.value);

    // console.log("event.target.name--",event.target.name);
    this.setState({ role: event.target.value });

    if (event.target.value === "all") {
      // console.log("all is selected");
      await this.props.getCancellationReasonByRole(0);
    }
    if (event.target.value === "admin") {
      // console.log("admin is selected");
      await this.props.getCancellationReasonByRole(1);
    }
    if (event.target.value === "business") {
      // console.log("business is selected");
      await this.props.getCancellationReasonByRole(2);
    }
    if (event.target.value === "shoppers") {
      // console.log("shoppers is selected");
      await this.props.getCancellationReasonByRole(3);
    }
    if(event.target.name === 'splashScreenTimer'){
      this.setState({splashScreenTimerModel : true})
      // await this.props.updateSplashScreenUrl({ splashScreenTimer: parseInt(event.target.value) });
    }
  };

  handleEditOpen = (event, cancellationReason) => {
    // console.log("row--->",cancellationReason)
    this.setState({ editReasonFlag: true, reasonEditModalOpen: true, selectedCancallationReason: cancellationReason })
  };

  modalReasonDeleteOpen = (e, reason) => {
    this.setState({ modalReasonDeleteOpen: true, selectedReason: reason });
  };

  handleModalCheckBoxAdmin = (e, reason) => {
    this.setState({ handleModalCheckBoxAdmin: true, selectedReason: reason });
  };

  handleModalCheckBoxBusiness = (e, reason) => {
    this.setState({ handleModalCheckBoxBusiness: true, selectedReason: reason });
  };

  handleModalCheckBoxShoppers = (e, reason) => {
    this.setState({ handleModalCheckBoxShoppers: true, selectedReason: reason });
  };

  handleDeleteOnAdd = async (reason) => {
    if (reason !== undefined) {
      // console.log("if condition of delete",this.state.selectedReason);
      await this.props.deleteOrderCancellationReason(reason._id);
      this.setState({ modalReasonDeleteOpen: false, reasonError: false, addReasonFlag: false, reasonErrorExists: false, reasonEditErrorExists: false });
      await this.props.getAllMasterOtp();
      // await this.props.getCancellationReasonByRole(0);
      if (this.state.role === "admin")
        await this.props.getCancellationReasonByRole(1);
      else if (this.state.role === "business")
        await this.props.getCancellationReasonByRole(2);
      else if (this.state.role === "shoppers")
        await this.props.getCancellationReasonByRole(3);
      else
        await this.props.getCancellationReasonByRole(0);
    } else {
      this.setState({ addReasonFlag: false, reasonError: false, reasonErrorExists: false, reasonEditErrorExists: false });
    }
  };

  handleDeleteReason = () => {
    this.setState({
      addReasonFlag: false, reasonError: false, reasonErrorExists: false, reasonEditErrorExists: false, adminCheckboxFlag: false,
      businessCheckboxFlag: false, shopperCheckboxFlag: false, reason: ""
    });
  };

  handleEditCancellationReasonChange = (event) => {
    this.setState({
      reasonEdit: event.target.value,
      reasonEditError: false,
      reasonErrorExists: false,
      reasonEditErrorExists: false,
    })
  };

  returnColor = (isActive) => {
    // console.log(isActive)
    let backgroundColor = isActive === true ? "#53ABE8" : "#E6EAEC";
    return backgroundColor;

    // if(isActive==true){
    //   // backgroundColor = "#80CBFF";
    //   // backgroundColor = "#5EBCFC";
    //   backgroundColor = "#59B3F1";
    //   return backgroundColor;
    // }
    // if(isActive==false){
    //   backgroundColor = "#E6EAEC";
    //   return backgroundColor;
    // }
  };

  returnTextColor = (isActive) => {
    let color = isActive === true ? "#FFFFFF" : "#000000";
    return color;
  };

  
  render() {
    let muiAlert = null;
    console.log("this.state.minOrderSuccessMsg", this.state.minOrderSuccessMsg)
    if (this.state.sucessMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="info">
        {this.state.sucessMsg}
      </MuiAlert>
    }
    if (this.state.sucessMsgCancellationReason) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
        {this.state.sucessMsgCancellationReason}
      </MuiAlert>
    } else if (this.state.errorMsgCancellationReason) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
        {this.state.errorMsgCancellationReason}
      </MuiAlert>
    }

    if (this.state.sucessMsgBusinessDashboard) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
        {this.state.sucessMsgBusinessDashboard}
      </MuiAlert>
    } else if (this.state.errorMsgBusinessDashboard) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="info">
        {this.state.errorMsgBusinessDashboard}
      </MuiAlert>
    }

    if (this.state.sucessMsgDeleteOpenCloseRecords) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
        {this.state.sucessMsgDeleteOpenCloseRecords}
      </MuiAlert>
    } else if (this.state.errorMsgDeleteOpenCloseRecords) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="info">
        {this.state.errorMsgDeleteOpenCloseRecords}
      </MuiAlert>
    }
    if (this.state.successCouponExpiryMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
        {this.state.successCouponExpiryMsg}
      </MuiAlert>
    } else if (this.state.errorCouponExpiryMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
        {this.state.errorCouponExpiryMsg}
      </MuiAlert>
    }
    if (this.state.successCashfreeSplitVendorSettlement) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
        {this.state.successCashfreeSplitVendorSettlement}
      </MuiAlert>
    } else if (this.state.errorCashfreeSplitVendorSettlement) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
        {this.state.errorCashfreeSplitVendorSettlement}
      </MuiAlert>
    
    } else if (this.state.minOrderSuccessMsg && this.state.minOrderSuccessMsg !== null && this.state.minOrderSuccessMsg == "Consumer status updated successfully") {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
        {this.state.minOrderSuccessMsg}
      </MuiAlert>
    
    } else if ( this.state.minOrderSuccessMsg && this.state.minOrderSuccessMsg !== null && this.state.minOrderSuccessMsg !== "Consumer status updated successfully") {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
        {this.state.minOrderSuccessMsg}
      </MuiAlert>
    }


    const { classes } = this.props;
    const { filterStartWeek } = this.state;
    const startWeekDaysAreSelected = filterStartWeek.length > 0;
    const startmodifiers = {
      selectedRange: startWeekDaysAreSelected && {
        from: filterStartWeek[0],
        to: filterStartWeek[6],
      },
      selectedRangeStart: startWeekDaysAreSelected && filterStartWeek[0],
      selectedRangeEnd: startWeekDaysAreSelected && filterStartWeek[6],
    };
   
    // console.log("this.state---->",this.state);
    return (
      <Container className={classes.container}>
        <Tabs value={this.state.value} onChange={this.handleChangeTab} indicatorColor="primary" textColor="primary" centered={false}>
          <Tab label="Global" style={{ width: "5px" }} />
          <Tab label="Reasons For Order Cancellation" style={{ width: "20%" }} onClick={this.functionCall} />
          <Tab label="Payment" style={{ width: "20%" }}  />
        </Tabs>

        <TabPanel value={this.state.value} index={0} classes={{ root: classes.tab }}>

          {/* Cashfree toggle */}
          <Paper className={classes.paper}>
            <Grid container
              justify="flex-start"
              alignItems="center"
            >

              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle2" gutterBottom>
                  CashFree
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) ? this.props.dashboard.getMasterOTP.data[0].enablePaymentsViaCashfree : false}
                      onChange={(e) => this.handleSwitchChange(e)}
                      color="primary"
                    />
                  }
                  style={{ fontSize: "5px" }}
                  label={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) && this.props.dashboard.getMasterOTP.data[0].enablePaymentsViaCashfree === true ? 'Enabled' : 'Disabled'}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  (This enables / disables Cashfree integration for payment gateway)
                </Typography>
              </Grid>
            </Grid>
          </Paper>


          {/* Cashfree New api integration (v3) toggle */}
          <Paper className={classes.paper}>
            <Grid container
              justify="flex-start"
              alignItems="center"
            >

              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle2" gutterBottom>
                  CashFree New API
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) ? this.props.dashboard.getMasterOTP.data[0].cashfreeNewVersion : false}
                      onChange={(e) => this.handleCashfreeNewApiIntegration(e)}
                      color="primary"
                    />
                  }
                  style={{ fontSize: "5px" }}
                  label={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) && this.props.dashboard.getMasterOTP.data[0].cashfreeNewVersion === true ? 'Enabled' : 'Disabled'}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  (This enables / disables Cashfree New API integration for payment gateway)
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          {/* Exotel toggle */}
          <Paper className={classes.paper}>
            <Grid container
              justify="flex-start"
              alignItems="center"
            >

              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle2" gutterBottom>
                  Exotel
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) ? this.props.dashboard.getMasterOTP.data[0].isExotel : false}
                      onChange={(e) => this.handleExotelSwitch(e)}
                      color="primary"
                    />
                  }
                  style={{ fontSize: "5px" }}
                  label={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) && this.props.dashboard.getMasterOTP.data[0].isExotel === true ? 'Enabled' : 'Disabled'}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  (This enables / disables Exotel integration)
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          {/* Exotel Reminder Configuration */}
          <Paper className={classes.paper}>
            <Grid item xs={12}>
              <Container>
                <Grid container spacing={1} justify="center" alignItems="center">
                  <Grid item xs={9}>
                    Exotel Reminder Configuration
                  </Grid>
                  <Grid item xs={3}>
                    {this.props.user.role === "admin" ?
                      <IconButton className={classes.icon} style={{ float: "right" }}
                        onClick={() => this.editExotelTimeConfiguration()}>
                        <Avatar className={classes.lightBlue}>
                          <EditIcon color="action" />
                        </Avatar>
                      </IconButton>
                      : null}
                  </Grid>
                  {this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].exotelConfig && this.props.dashboard.getMasterOTP.data[0].exotelConfig.map((item) => {
                    return (
                      <Fragment>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={4}>{item.whichReminder} Reminder</Grid>
                        <Grid item xs={4}>after {item.minute} minute/s</Grid>
                        <Grid item xs={2}></Grid>
                      </Fragment>
                    )
                  })}
                </Grid>
              </Container>
            </Grid>
          </Paper>

          {/* Exotel Xirify Support No */}
          <Paper className={classes.paper11}>
            <Grid container justify="flex-start" alignItems="center" >
              <Grid item xs={3}>
                <Typography style={{ margin: "50px" }} variant="subtitle2" gutterBottom>
                  Exotel Xirify Support No
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  margin="dense"
                  name="xirifySupportNo"
                  label="Exotel Xirify Support No"
                  variant="outlined"
                  className={classes.textField}
                  inputProps={{ maxLength: 10, min: 10 }}
                  error={this.state.exotelSupportError ? true : (this.state.phoneValidationError ? true : false)}
                  // helperText={this.state.orderByTimeError ? "Required" : (this.state.orderByTimeValidationError?"Should contain only numbers":false)}
                  // value={this.state.orderByTime ? this.state.orderByTime.trim().replace(/\D/g, ""): ""}
                  value={this.state.xirifySupportNo ? this.state.xirifySupportNo : ""}
                  // defaultValue={ this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].orderByTime ? this.props.dashboard.getMasterOTP.data[0].orderByTime : (this.state.orderByTime ? this.state.orderByTime: (isset($_GET[`${this.props.dashboard.getMasterOTP.data[0].orderByTime}`]) ? $_GET[`${this.props.dashboard.getMasterOTP.data[0].orderByTime}`] : ""))}
                  onChange={this.handleXirifySupportChange}
                />
                {this.state.exotelSupportError ? (
                  <FormHelperText style={{ color: "red" }}>
                    Required
                  </FormHelperText>
                ) : this.state.phoneValidationError ? (
                  <FormHelperText style={{ color: "red" }}>
                    Invalid Mobile Number
                  </FormHelperText>
                ) : null
                }
              </Grid>
              <Grid item xs={1}>
                <Button style={{ margin: "5px" }} variant="contained" color="primary" onClick={this.addXirifySupportNumber} disabled={!this.state.xirifySupportNo ? true : (this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].xirifySupportNo && (this.props.dashboard.getMasterOTP.data[0].xirifySupportNo === this.state.xirifySupportNo) ? true : false)} > Add </Button>
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  (First Exotel call to be received on the mentioned Xirify Support contact No.)
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          {/* Exotel toggle for OSBillConsumer */}
          <Paper className={classes.paper}>
            <Grid container
              justify="flex-start"
              alignItems="center"
            >

              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle2" gutterBottom>
                  Exotel(OSBillConsumer)
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) ? this.props.dashboard.getMasterOTP.data[0].isExotelOSBillConsumer : false}
                      onChange={(e) => this.handleExotelSwitchForOSBill(e)}
                      color="primary"
                    />
                  }
                  style={{ fontSize: "5px" }}
                  label={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) && this.props.dashboard.getMasterOTP.data[0].isExotelOSBillConsumer === true ? 'Enabled' : 'Disabled'}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  (This enables / disables Exotel integration for OSBill generated consumers)
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Grid item xs={1}>
            </ Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" gutterBottom>
                Block All Coupon Push Notification
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) ? this.props.dashboard.getMasterOTP.data[0].blockCopuonPushNotification : false}
                    onChange={(e) => this.handleBlockCouponPushNotification(e)}
                    color="primary"
                  />
                }
                style={{ fontSize: "5px" }}
                label={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) && this.props.dashboard.getMasterOTP.data[0].blockCopuonPushNotification === true ? 'On' : 'Off'}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                ( Applicable only for Offercode Coupons.)
              </Typography>
            </Grid>

          </Paper>

          {/* promocode validation flag */}
          <Paper className={classes.paper}>
            <Grid item xs={1}>
            </ Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" gutterBottom>
                Promocode Validation
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={(this.props?.dashboard?.getMasterOTP?.data?.length > 0 && this.props?.dashboard?.getMasterOTP?.data[0].promoCodeValidationFlag && this.props?.dashboard?.getMasterOTP?.data[0].promoCodeValidationFlag === true) ? true : false}
                    onChange={(e) => this.handlePromoCodeValidationFlag(e)}
                    color="primary"
                  />
                }
                style={{ fontSize: "5px" }}
                label={(this.props?.dashboard?.getMasterOTP?.data?.length > 0 && this.props?.dashboard?.getMasterOTP?.data[0].promoCodeValidationFlag && this.props?.dashboard?.getMasterOTP?.data[0].promoCodeValidationFlag === true) ? "On" : "Off"}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            {/* <RadioGroup row aria-label="position" name="promoCodeValidationFlag" onChange={this.handlePromoCodeValidationFlag} 
            // value={this.state.promoCodeValidationFlag}
            value={(this.props?.dashboard?.getMasterOTP?.data?.length > 0 && this.props?.dashboard?.getMasterOTP?.data[0].promoCodeValidationFlag && this.props?.dashboard?.getMasterOTP?.data[0].promoCodeValidationFlag == true) ? true : false}
            >

              <FormControlLabel value={true} control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>On</Typography>} size="small" />
              <FormControlLabel value={false} control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Off</Typography>} size="small" />

                                        
            </RadioGroup> */}
          </Paper>

          {/* SMS service provider */}
          <Paper className={classes.paper}>
            <Grid item xs={1}>
            </ Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" gutterBottom>
                SMS service provider
              </Typography>
            </Grid>
            <RadioGroup row aria-label="position" name="smsServiceProvider" onChange={this.smsProviderHandler} value={(this.props?.dashboard?.getMasterOTP?.data?.length > 0 && 'smsProviderFlag' in this.props?.dashboard?.getMasterOTP?.data[0] && this.props?.dashboard?.getMasterOTP?.data[0].smsProviderFlag) ? this.props?.dashboard?.getMasterOTP?.data[0]?.smsProviderFlag : this.state.smsProvider}>

              <FormControlLabel value="GupShup" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>GupShup</Typography>} size="small" />

              <FormControlLabel value="Digimiles" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Digimiles</Typography>} size="small" />


            </RadioGroup>
          </Paper>

          {/* To run Business Dashboard cron */}
          <Paper className={classes.paper}>
            <Grid container
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={1}>
              </ Grid>
              <Grid item xs={3}>
                <Typography
                  // style={{ margin: "0px" }} 
                  variant="subtitle2" gutterBottom>
                  Business Dashboard Cron
                </Typography>
              </Grid>
              <Grid item xs={8}>
              </ Grid>

              {/* Daily Cron radio button */}
              <Grid item xs={1}> </Grid>
              <RadioGroup row aria-label="position" name="businessDashboardCron"
                // style={{ margin: "20px" }}
                onChange={this.businessDashboardCronHandler}
                value={this.state.businessDashboardCron}
              >
                <FormControlLabel value="Daily" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Daily</Typography>} size="small" />
              </RadioGroup>

              {/* Weekly Cron Radio Button */}
              <RadioGroup row aria-label="position" name="businessDashboardCron"
                // style={{ margin: "20px" }}
                onChange={this.businessDashboardCronHandler}
                value={this.state.businessDashboardCron}
              >
                <FormControlLabel value="Weekly" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Weekly</Typography>} size="small" />
              </RadioGroup>


              {/* Monthly cron radio button */}
              <RadioGroup row aria-label="position" name="businessDashboardCron"
                // style={{ margin: "20px" }}
                onChange={this.businessDashboardCronHandler}
                value={this.state.businessDashboardCron}
              >
                <FormControlLabel value="Monthly" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Monthly</Typography>} size="small" />
              </RadioGroup>

              {
                this.state.businessDashboardCron && this.state.businessDashboardCron === "Daily"
                  ?
                  <Typography>
                    <DatePicker
                      style={classes.picker}
                      selected={this.state.fromDate}
                      onChange={this.handleFilterDailyDateChange}
                      name="businessCronDailyDate"
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="form-control"
                    />
                    <FormHelperText style={{ color: "red", marginLeft: '10px', marginTop: '0px', marginBottom: '10px' }}> {this.state.dailyDateError ? "Please select past date" : (this.state.dailyDateRequired ? "Required" : null)} </FormHelperText>
                  </Typography>

                  :
                  (
                    this.state.businessDashboardCron && this.state.businessDashboardCron === "Monthly"
                      ?
                      <Typography>
                        <DatePicker
                          selected={this.state.fromDate}
                          onChange={this.handleFilterMonthlyDateChange}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          className="form-control"
                        />
                        <FormHelperText style={{ color: "red", marginLeft: '10px', marginTop: '0px', marginBottom: '10px' }}> {this.state.monthlyDateError ? "Please select past month" : (this.state.monthlyDateRequired ? "Required" : null)} </FormHelperText>
                      </Typography>
                      :
                      (
                        this.state.businessDashboardCron && this.state.businessDashboardCron === "Weekly"
                          ?
                          <Typography>
                            {filterStartWeek.length === 7 && (
                              <div>
                                {moment(filterStartWeek[0]).format('LL')} –{' '}
                                {moment(filterStartWeek[6]).format('LL')}
                              </div>
                            )}
                            <DayPicker
                              filterStartWeek={filterStartWeek}
                              modifiers={startmodifiers}
                              onDayClick={this.handleStartWeekChange}
                              onWeekClick={this.handleStartWeekClick}
                              // className="form"
                              // locale="hu"
                              // calendarStartDay={1}
                              firstDayOfWeek={1}
                            // style={{width:"50%"}}
                            />
                            <FormHelperText style={{ color: "red", marginLeft: '10px', marginTop: '0px', marginBottom: '10px' }}> {this.state.weeklyError ? "Please select past week" : (this.state.weeklyRequired ? "Required" : null)} </FormHelperText>
                          </Typography>
                          :
                          null
                      )
                  )
              }

              {
                this.state.businessDashboardCron && this.state.businessDashboardCron === "Daily" ?
                  <Grid item xs={1}>
                    <Button
                      style={{ margin: "5px" }}
                      variant="contained"
                      color="primary"
                      onClick={this.runBusinessDashboardCron}
                    >
                      Run
                    </Button>
                  </Grid>
                  :
                  (
                    this.state.businessDashboardCron && this.state.businessDashboardCron === "Monthly" ?
                      <Grid item xs={1}>
                        <Button
                          style={{ margin: "5px" }}
                          variant="contained"
                          color="primary"
                          onClick={this.runBusinessDashboardCron}
                        >
                          Run
                        </Button>
                      </Grid>
                      :
                      (
                        this.state.businessDashboardCron && this.state.businessDashboardCron === "Weekly" ?
                          <Grid item xs={1}>
                            <Button
                              style={{ margin: "5px" }}
                              variant="contained"
                              color="primary"
                              onClick={this.runBusinessDashboardCron}
                            >
                              Run
                            </Button>
                          </Grid>
                          : null
                      )
                  )
              }
            </Grid>
          </Paper>
          {/* Below is to set default radius of businesses */}
          <Paper className={classes.paper11}>
            <Grid item xs={3}>
              <Typography style={{ margin: "30px" }} variant="subtitle2" gutterBottom >
                Default Business Radius </Typography>
            </Grid>

            <Grid item xs={2} style={{ flexBasis: "0px", marginTop: "10px" }}>
              <TextField                
                margin="dense"
                className={classes.rupess}
                variant="outlined"
                style={{ width: "80px" }}
                value={this.state.defaultBusinessRadius}
                name="defaultBusinessRadius"
                onChange={this.handleDefaultBusinessRadiusChange}
                error = {(!this.state.defaultBusinessRadius  ? "Required" : (this.state.defaultBusinessRadius === 0 || (!/^([\d]+)(?:\.([\d]{1,3}?))?$/.test(this.state.defaultBusinessRadius)))) ?  "Invalid" : null}
                helperText={ (!this.state.defaultBusinessRadius ? "Required" : (this.state.defaultBusinessRadius === 0 || (!/^([\d]+)(?:\.([\d]{1,3}?))?$/.test(this.state.defaultBusinessRadius)))) ?  "Invalid" : null}
              />

              {/* {!this.state.defaultBusinessRadius ? (
                  <FormHelperText style={{ color: "red" }}>
                    Required
                  </FormHelperText>
                ) : (
                  this.state.defaultBusinessRadiusError ? (
                    <FormHelperText style={{ color: "red" }}>
                      Invalid 
                    </FormHelperText>
                  ) : null
                   
                )
                } */}

            </Grid>
            <Grid item xs={2}>
              <Typography style={{ margin: "15px" }} variant="subtitle2" gutterBottom>
                Km
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                style={{ margin: "5px" }} 
                variant="contained"
                component="span"
                onClick={this.closeDetailsOfDefaultBusinessRadius}
                disabled={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) && (this.props.dashboard.getMasterOTP.data[0].defaultBusinessRadius === this.state.defaultBusinessRadius) ? true : false}
              >
                CANCEL
              </Button>
              {/* <Button variant="contained" component="span" onClick={this.close} style={{ margin: "5px" }} fullWidth> Cancel </Button> */}
            </Grid>
            <Grid item xs={1}>
              <Button
                autoFocus
                style={{ margin: "5px", marginLeft: "-60px" }} color="primary"
                variant="contained"
                component="span"
                onClick={this.saveDefaultBusinessRadius}
                disabled={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) && (this.props.dashboard.getMasterOTP.data[0].defaultBusinessRadius === this.state.defaultBusinessRadius) ? true : false}
               
              >
                SAVE
              </Button>
            </Grid>
          </Paper>
          {/* To add Email forNotification after we place an order */}
          <Paper className={classes.emailPaper11}>
            <Grid container justify="flex-start" alignItems="center" >
              <Grid item >
                <Typography style={{ margin: "20px" }} variant="subtitle2" gutterBottom>
                  Email Notification
                </Typography>
              </Grid>
              <Grid item >
                <TextField
                  fullWidth
                  margin="dense"
                  name="orderByTime"
                  label="Emails"
                  variant="outlined"
                  className={classes.textField}                 
                  error={this.state.emailNotificationRequiredError ? true : (this.state.emailNotificationValidateError ? true : (this.state.emailRepeatError ?true :false))}
                  value={this.state.emailNotification ? this.state.emailNotification : ""}
                  // defaultValue={ this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].orderByTime ? this.props.dashboard.getMasterOTP.data[0].orderByTime : (this.state.orderByTime ? this.state.orderByTime: (isset($_GET[`${this.props.dashboard.getMasterOTP.data[0].orderByTime}`]) ? $_GET[`${this.props.dashboard.getMasterOTP.data[0].orderByTime}`] : ""))}
                  onChange={this.handleEmailNotificationChange}
                />
                {this.state.emailNotificationRequiredError ? (
                  <FormHelperText style={{ color: "red" }}>
                    Required
                  </FormHelperText>
                ) : (
                  this.state.emailNotificationValidateError ? (
                    <FormHelperText style={{ color: "red" }}>
                      Enter Correct EmailId 
                    </FormHelperText>
                  ) :(
                    this.state.emailRepeatError ? (
                      <FormHelperText style={{ color: "red" }}>
                        Email is already exist.
                      </FormHelperText>
                    ) : null
                  ) 
                )
                }
              </Grid>
              
              <Grid item xs={1}>
                <Button style={{ margin: "5px" }} variant="contained" color="primary" 
                  disabled={this.state.emailNotificationArray.length >= 2}
                onClick={this.addEmailNotications} 
                > Add </Button>
              </Grid>

              <Grid item  xs={8}>  {this.state.emailNotificationArray.length >= 2? (
                            <FormHelperText align="right"  style={{ color: "grey", marginTop:"5px" ,marginLeft:"155px"}}>
                              Note : (Max 2 emails can be added)
                            </FormHelperText>
                          ) : null}
                     </Grid>
            </Grid>
           
              {this.state.emailNotificationArray.length > 0 ?
              
              <Grid item xs={12} sm={9}>
                <div style={{ borderRadius: '2px', padding: '15px', marginTop: '10px' }}>
                  {
                    this.state.emailNotificationArray.map((email, i) => {
                      return (
                        < Chip
                          className={classes.chip}
                          label={email}
                          key={email}
                          variant="outlined"
                          deleteIcon={<DeleteIcon />}
                          color="primary"
                          onDelete={() => this.handleEmailDelete(email)}
                        />
                      );
                    })
                  }
                </div>
              </Grid>
              :
              <Grid item xs={12} sm={9} style={{marginTop:'10px'}}> Emails have not added</Grid>
        }     
         
            
          </Paper>
          {/* Delivery Partner Settlement Job */}
          <Paper className={classes.paper}>
            <Grid container
              justify="flex-start"
              alignItems="center"
            >

              <Grid item xs={3}>
                <Typography style={{ margin: "10px" }} variant="subtitle2" gutterBottom>
                  Delivery Partner Settlement Job
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  margin="dense"
                  name="date"
                  label="Date"
                  type="date"
                  variant="outlined"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={this.state.error && this.state.error.date}
                  helperText={(this.state.error && this.state.error.date) ? this.state.error.date : null}
                  value={this.state.date}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  style={{ margin: "5px" }}
                  variant="contained"
                  color="primary"
                  onClick={this.runSettlement}
                >
                  Run
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  (Selected date generates settlement for previous 15 days cycle)
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          {/* To run Coupon Settlement Job */}
          <Paper className={classes.paper}>
            <Grid container
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography style={{ margin: "10px" }} variant="subtitle2" gutterBottom>
                  Coupon Settlement Job
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  margin="dense"
                  name="coupondate"
                  label="Date"
                  type="date"
                  variant="outlined"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={this.state.error && this.state.error.coupondate}
                  helperText={(this.state.error && this.state.error.coupondate) ? this.state.error.coupondate : null}
                  value={this.state.coupondate}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  style={{ margin: "5px" }}
                  variant="contained"
                  color="primary"
                  onClick={this.runCouponSettlement}
                >
                  Run
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  (Selected date generates settlement for previous 1 month)
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          {/* To set Delivery Order By Time */}
          <Paper className={classes.paper11}>
            <Grid container justify="flex-start" alignItems="center" >
              <Grid item xs={3}>
                <Typography style={{ margin: "50px" }} variant="subtitle2" gutterBottom>
                  Delivery Order By Time
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  margin="dense"
                  name="orderByTime"
                  label="Minutes"
                  variant="outlined"
                  className={classes.textField}
                  inputProps={{ maxLength: 2 }}
                  error={this.state.orderByTimeError ? true : (this.state.orderByTimeValidationError ? true : (this.state.orderByTimeSpaceError ? true : false))}
                  // helperText={this.state.orderByTimeError ? "Required" : (this.state.orderByTimeValidationError?"Should contain only numbers":false)}
                  // value={this.state.orderByTime ? this.state.orderByTime.trim().replace(/\D/g, ""): ""}
                  value={this.state.orderByTime ? this.state.orderByTime : ""}
                  // defaultValue={ this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].orderByTime ? this.props.dashboard.getMasterOTP.data[0].orderByTime : (this.state.orderByTime ? this.state.orderByTime: (isset($_GET[`${this.props.dashboard.getMasterOTP.data[0].orderByTime}`]) ? $_GET[`${this.props.dashboard.getMasterOTP.data[0].orderByTime}`] : ""))}
                  onChange={this.handleChange}
                />
                {this.state.orderByTimeError ? (
                  <FormHelperText style={{ color: "red" }}>
                    Required
                  </FormHelperText>
                ) : (
                  this.state.orderByTimeValidationError ? (
                    <FormHelperText style={{ color: "red" }}>
                      Should contain only numbers
                    </FormHelperText>
                  ) : (
                    this.state.orderByTimeSpaceError ? (
                      <FormHelperText style={{ color: "red" }}>
                        Should not contain spaces
                      </FormHelperText>
                    ) : null
                  )
                )}
              </Grid>
              <Grid item xs={1}>
                <Button style={{ margin: "5px" }} variant="contained" color="primary" onClick={this.addOrderByTime} disabled={!this.state.orderByTime ? true : (this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].orderByTime && (this.props.dashboard.getMasterOTP.data[0].orderByTime === this.state.orderByTime) ? true : false)} > Add </Button>
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  (Delivery Order By Time in Minutes)
                </Typography>
              </Grid>
            </Grid>
          </Paper>
           <Paper className={classes.paper11}>
            <Grid item xs={3}>
              <Typography style={{ margin: "30px" }} variant="subtitle2" gutterBottom >
                Xirify Delivery</Typography>
                </Grid>
              <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) ? this.props.dashboard.getMasterOTP.data[0].isXirifyDelivery : false}
                    onChange={(e) => this.handleXirifyChargesSwitch(e)}
                    color="primary"
                  />
                }
                label={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) && this.props.dashboard.getMasterOTP.data[0].isXirifyDelivery === true ? 'On' : 'Off'}
              />
            </Grid>
          </Paper>

         


            {/* SET CHARGES FOR XIRIFY DELIVERY */}
            {this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length > 0 && this.props.dashboard.getMasterOTP.data[0].isXirifyDelivery ?
            <Paper className={classes.paper3}>
            <Grid container justify="flex-start" alignItems="center" >

            <Grid item xs={3}>
                <Typography variant="subtitle2" style={{ margin: "30px" }} gutterBottom>
                  Set charges for Xirify Delivery
                </Typography>
              </Grid>
              <Grid item xs={2} style={{flexBasis:"0px",marginTop: "10px"}}>
                 <TextField
                 margin="dense"
                    className={classes.rupess}
                    variant="outlined"
                    style={{ width: "80px" }}
                    // value="₹"
                     value= {this.state.basePrice}
                     name="basePrice"
                     onChange={this.handlebasePriceChange} 
                     error={this.state.basePriceError ? true :  (this.state.basePriceSpaceError ? true :false )}
                     InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}

                  />
                  
                  {this.state.basePriceError ? (
                  <FormHelperText style={{ color: "red" }}>
                    Required
                  </FormHelperText>
                ) : (
                    this.state.basePriceSpaceError ? (
                      <FormHelperText style={{ color: "red" }}>
                        Invalid
                      </FormHelperText>
                    ) : null
                  )
                }
                  </Grid>
              <Grid item xs={2}>
                <Typography style={{ margin: "15px" }} variant="subtitle2" gutterBottom>
                  Base Price for
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} style={{ marginLeft: "-50px",flexBasis:"0px" }}>
                <FormControl fullWidth margin="dense" variant="outlined">
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="fronpm run devm"
                    value={this.state.baseKM}
                    name="baseKM"
                    onChange={this.handleFilterChange}
                    style={{ width: "80px",height: "39px" }}
                  >
                    {baseKM.map((index) => {
                      return (
                        <MenuItem key={index} value={index} style={{ minHeight: "28px", height: "28px" }}>
                          <Typography noWrap>{index.toUpperCase()}</Typography>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
              <Typography style={{ margin: "15px" }} variant="subtitle2" gutterBottom>
                  KM
                </Typography>
              </Grid>
              <Grid item xs={2} style={{flexBasis:"0px",marginTop: "10px"}} >
                  <TextField
                    margin="dense"
                    className={classes.rupess}
                    variant="outlined"
                    style={{ width: "80px", alignItems:"center" }}
                    value={this.state.pricePerKM}
                    name="pricePerKM"
                    onChange={this.handlepricePerKMChange} 
                    error={this.state.pricePerKMError ? true :(this.state.pricePerKMSpaceError ? true:false)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>}}
                     />
                          
                {this.state.pricePerKMError ? (
                  <FormHelperText style={{ color: "red" }}>
                    Required
                  </FormHelperText>
                ) : (
                this.state.pricePerKMSpaceError ? (
                  <FormHelperText style={{ color: "red" }}>
                    Invalid
                  </FormHelperText>
                  ) : null
                )
                }
              </Grid>
              <Grid item xs={1}  >
              <Typography style={{ margin: "15px" }} variant="subtitle2" gutterBottom>
                  Per KM
                </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Button
                      style={{ margin: "5px",padding:"5px"}}
                      variant="contained"
                      component="span" 
                      onClick={this.closeDetails}
                      disabled={(!this.state.basePrice ? true : (this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges && (this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges.basePrice === this.state.basePrice) ? true : false)) && (!this.state.baseKM ? true : (this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges && (this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges.baseKM === this.state.baseKM) ? true : false)) && (!this.state.pricePerKM ? true : (this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges && (this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges.pricePerKM === this.state.pricePerKM) ? true : false)) }
                    >
                      CANCEL
                    </Button>
                    {/* <Button variant="contained" component="span" onClick={this.close} style={{ margin: "5px" }} fullWidth> Cancel </Button> */}
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      style={{ margin: "5px",padding:"5px" }}
                      variant="contained"
                      color="primary"
                      onClick={this.xirifyCharges}
                      disabled={(!this.state.basePrice ? true : (this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges && (this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges.basePrice === this.state.basePrice) ? true : false)) && (!this.state.baseKM ? true : (this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges && (this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges.baseKM === this.state.baseKM) ? true : false)) && (!this.state.pricePerKM ? true : (this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges && (this.props.dashboard.getMasterOTP.data.length && this.props.dashboard.getMasterOTP.data[0].xirifyDeliveryCharges.pricePerKM === this.state.pricePerKM) ? true : false)) }
                    >
                     SAVE
                    </Button>
                  </Grid>
            </Grid>
          </Paper>:null}
         {/* SET CHARGES FOR XIRIFY DELIVERY END */}

          <Paper className={classes.paper}>
            <Grid container
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle2" gutterBottom>
                  Check Coupon Expiry
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Button
                  style={{ margin: "5px" }}
                  variant="contained"
                  color="primary"
                  onClick={this.runCouponExpiry}
                >
                  Run
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  (Expires only valid coupon "Expiry Date" less than current date)
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          {/* To manually run cashfree vendor split settlement */}
          <Paper className={classes.paper}>
            <Grid container
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <Typography  variant="subtitle2" gutterBottom>
                  Cashfree Vendor Split Settlement
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Button
                  style={{ margin: "5px" }}
                  variant="contained"
                  color="primary"
                  onClick={() => { this.getCashfreeSettlementRecord() }}
                >
                  Run
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  (Will generate Cashfree Vendor Split Settlement)
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Grid container
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography style={{ margin: "10px" }} variant="subtitle2" gutterBottom>
                  Delete Business Open/Close records
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  margin="dense"
                  name="deleteOpenCloseDate"
                  label="Date"
                  type="date"
                  variant="outlined"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={this.state.error && this.state.error.deleteOpenCloseDate}
                  helperText={(this.state.error && this.state.error.deleteOpenCloseDate) ? this.state.error.deleteOpenCloseDate : null}
                  value={this.state.deleteOpenCloseDate}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  style={{ margin: "5px" }}
                  variant="contained"
                  color="primary"
                  disabled={!this.state.deleteOpenCloseDate}
                  onClick={this.validateDeleteOpenCloseDate}
                >
                  Run
                </Button>
                <Dialog open={this.state.handleOpenCloseModal} onClose={this.closeOpenCloseModal}>
                  <DialogTitle>Delete Open/Close Records</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to HARD DELETE the Business Open/Close records.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.closeOpenCloseModal} color="primary"> No </Button>
                    <Button onClick={() => this.runDeleteOpenCloseRecords()} color="primary" autoFocus> Yes </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  (Hard DELETE all previous Business Open/Close records to the selected date)
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          {/* Referrals */}
          {
            (this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length &&
              ((!this.props.dashboard.getMasterOTP.data[0].referredUser && !this.props.dashboard.getMasterOTP.data[0].referringUser) || (this.props.dashboard.getMasterOTP.data[0].referredUser.referralAmount == null && this.props.dashboard.getMasterOTP.data[0].referredUser.minimumOrderValue == null && this.props.dashboard.getMasterOTP.data[0].referredUser.couponExpiry == null && this.props.dashboard.getMasterOTP.data[0].referringUser.referralAmount == null && this.props.dashboard.getMasterOTP.data[0].referringUser.minimumOrderValue == null && this.props.dashboard.getMasterOTP.data[0].referringUser.couponExpiry == null))
              && (!this.props.dashboard.getMasterOTP.data[0].enableReferAndEarn || this.props.dashboard.getMasterOTP.data[0].enableReferAndEarn === false)
            )
              //this.state.enableReferAndEarn == false 
              // && 'no data for it in the database'
              ?
              <Paper className={classes.paper}>
                <Grid container
                  justify="flex-start"
                  alignItems="center"
                >

                  <Grid item xs={1}></Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2" gutterBottom>
                      Referrals
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) ? this.props.dashboard.getMasterOTP.data[0].enableReferAndEarn : false}
                          // onChange={(e) => this.handleSwitchChangeReferral(e)}
                          onClick={this.handleModalOpen}
                          color="primary"
                        />
                      }
                      style={{ fontSize: "5px" }}
                      label={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) && this.props.dashboard.getMasterOTP.data[0].enableReferAndEarn === true ? 'On' : 'Off'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      (This enables / disables Consumer App Referrals)
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              :
              <Paper className={classes.paper2}>
                <Grid container
                  justify="flex-start"
                  alignItems="center"
                >

                  <Grid item xs={1}></Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2" gutterBottom>
                      Referrals
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) ? this.props.dashboard.getMasterOTP.data[0].enableReferAndEarn : false}
                          onChange={(e) => this.handleSwitchChangeReferral(e)}
                          // onClick={this.handleModalOpen}
                          color="primary"
                        />
                      }
                      style={{ fontSize: "5px" }}
                      label={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) && this.props.dashboard.getMasterOTP.data[0].enableReferAndEarn === true ? 'On' : 'Off'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      (This enables / disables Consumer App Referrals)
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider color="textSecondary"
                      style={{ marginBottom: "15px", marginTop: "15px", marginLeft: "10px", marginRight: "10px" }}
                      display="block"
                    />
                  </Grid>
                  <ReferralsDetails
                    data={this.props.dashboard.getMasterOTP.data}
                    handleEditOpen={this.editReferrals}
                  />

                </Grid>
              </Paper>
          }

          {/* Upload Splash Screen for shopping app */}

          <Paper className={classes.paper}>
            <Grid container
              justify="flex-start"
              alignItems="center"
            >

              <Grid item xs={12} sm={4} >
                <Typography style={{ margin: "10px", display: "flex", justifyContent: "center" }} variant="subtitle2" gutterBottom>
                  Splash Screen Timer
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2}>
                <FormControl fullWidth margin="dense" variant="outlined">
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="fronpm run devm"
                    value={this.state.splashScreenTimer}
                    name="splashScreenTimer"
                    onChange={this.handleFilterChange}
                    style={{ width: "80px" }}
                  >
                    {splashScreenTimer.map((index) => {
                      return (
                        <MenuItem key={index} value={index} style={{ minHeight: "28px", height: "28px" }}>
                          <Typography noWrap>{index.toUpperCase()}</Typography>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} sm={4}>
                <Typography>
                  (2 Sec default network time is configured)
                </Typography>
              </Grid>

              <Grid item xs={6} sm={4}>
                <Typography style={{ margin: "10px", display: "flex", justifyContent: "center" }} variant="subtitle2" gutterBottom>
                  Upload Splash Screen
                </Typography>
              </Grid>
              <Grid item xs={9} sm={2}>
                <UploadSplashScreen
                  uploadButtonName="UPLOAD"
                  uploadSplashScreen={this.uploadSplashScreen}
                  photoType="UploadSplashScreenInput"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {(this.state.splashScreenURL !== "") ?
                  <GridList className={classes.gridList}>
                    <GridListTile
                      className={classes.gridElement}
                    // key={images._id}
                    >
                      <a href={this.state.splashScreenURL} target="_blank" rel="noopener noreferrer">
                        <img border="0" className={classes.img} src={this.state.splashScreenURL} alt="" />
                      </a>
                      <GridListTileBar
                        titlePosition="bottom"
                        actionIcon={
                          <IconButton className={classes.icon}
                            onClick={() => this.handleDeleteOpen(this.state.splashScreenURL)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                        actionPosition="right"
                      />
                    </GridListTile>
                  </GridList >
                  : null}
              </Grid>
              {/* <Grid xs={3}>
                  <Button
                    style={{ margin: "5px" }}
                    variant="contained"
                    color="primary"
                    onClick={this.saveSplashScreen}
                    disabled={this.state.splashScreenURL===""}
                  >
                    Save Splash Screen
                  </Button>
                </Grid> */}
              {/* <Grid xs={3}></Grid> */}
            </Grid>
          </Paper>
          <Modal
            className={classes.modal}
            open={this.state.modalOpen}
            onClose={this.handleModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {!this.state.editReferrals ?
              <ReferralAddForm
                onClose={this.handleModalClose}
                onSubmit={this.addReferrals}
                configurationsProps={this.props.dashboard.getMasterOTP.data}
                envs={this.props}
                images={this.state.referralCouponImage}
              />
              :
              <ReferralEditForm
                referralData={this.state.referralData}
                onSubmit={this.updateReferrals}
                onClose={this.handleModalClose}
                initialValues={this.state.referralData}
                configurationsProps={this.props.dashboard.getMasterOTP.data}
                envs={this.props}
                images={this.props.dashboard.getMasterOTP.data[0].referralCouponImage}
              />
            }
          </Modal>
        </TabPanel>

        {/* To add reasons for order cancellation */}
        <TabPanel value={this.state.value} index={1} classes={{ root: classes.tab }}>
          <Divider
            color="textSecondary"
            style={{ marginBottom: "15px", marginTop: "0px", marginLeft: "10px", marginRight: "10px", paddingTop: "0px" }}
            display="block"
          />

          <Grid container justify="flex-start" alignItems="center" style={{ paddingBottom: "17px", marginLeft: "10px", paddingLeft: "12px" }} >
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="Role-label"> Role </InputLabel>
                <Select
                  labelId="Role-label"
                  name="role"
                  value={this.state.role}
                  onChange={this.handleFilterChange}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value={"all"}
                    style={{ minHeight: "28px", height: "28px" }}
                  >
                    <ListItemText primary="All" />
                  </MenuItem>
                  <MenuItem
                    value={"admin"}
                    style={{ minHeight: "28px", height: "28px" }}
                  >
                    <ListItemText primary="Admin" />
                  </MenuItem>
                  <MenuItem
                    value={"business"}
                    style={{ minHeight: "28px", height: "28px" }}
                  >
                    <ListItemText primary="Business" />
                  </MenuItem>
                  <MenuItem
                    value={"shoppers"}
                    style={{ minHeight: "28px", height: "28px" }}
                  >
                    <ListItemText primary="Shoppers" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}> </Grid>
            <Grid item xs={3}>
              <Button style={{ margin: "5px", width: "80px", height: "40px" }} variant="contained" color="primary" align="right" onClick={this.addCancellationReason} disabled={this.state.addReasonFlag} > Add </Button>
              <Button style={{ margin: "5px", width: "80px", height: "40px" }} variant="contained" color="primary" align="right" onClick={this.saveCancellationReason} disabled={!this.state.addReasonFlag} > Save </Button>
            </Grid>
          </Grid>


          {/* Adding new reason */}
          {
            this.state.addReasonFlag ?
              <Grid container justify="flex-start" alignItems="center" style={{ fontSize: "13px", paddingTop: "10px", paddingBottom: "3px", paddingLeft: "12px" }}>
                <Checkbox
                  // onClick={(event) => this.handleCheckBoxAdmin(event)}
                  onClick={() => this.handleCheckBoxAdmin()}
                  value={this.state.adminCheckboxFlag}
                  name="adminCheck"
                  checked={this.state.adminCheckboxFlag}
                  style={{
                    paddingRight: "3px",
                    transform: "scale(0.9)"
                  }}
                />
                Admin

                <Checkbox
                  // onClick={(event) => this.handleCheckBoxBusiness(event)}
                  onClick={() => this.handleCheckBoxBusiness()}
                  value={this.state.businessCheckboxFlag}
                  name="businessCheck"
                  checked={this.state.businessCheckboxFlag}
                  style={{
                    paddingRight: "3px",
                    transform: "scale(0.9)"
                  }}
                />
                Business

                <Checkbox
                  // onClick={(event) => this.handleCheckBoxShopper(event)}
                  onClick={() => this.handleCheckBoxShopper()}
                  value={this.state.shopperCheckboxFlag}
                  name="shopperCheck"
                  checked={this.state.shopperCheckboxFlag}
                  style={{
                    paddingRight: "3px",
                    transform: "scale(0.9)"
                  }}
                />
                Shoppers

                <TextField
                  // fullWidth
                  onChange={this.handleChange}
                  margin="dense"
                  name="reason"
                  // label="Reason"
                  placeholder="Reason"
                  variant="outlined"
                  multiline={true}
                  style={{ width: "53.5%", paddingLeft: "10px", paddingRight: "23px" }}
                  // error={this.state.reasonError}
                  // helperText={this.state.reasonError?"Required":false}
                  error={this.state.reasonError ? this.state.reasonError : (this.state.reasonErrorExists ? this.state.reasonErrorExists : false)}
                  helperText={this.state.reasonError ? "Required" : (this.state.reasonErrorExists ? "Order Cancellation Reason already exists" : null)}
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      // onChange={(e) => this.handleSwitchChangeCancellationReason(e)}
                      color="primary"
                      disabled
                    />
                  }
                  style={{ fontSize: "5px", transform: "scale(0.9)", marginRight: "1px", paddingRight: "0px" }}
                />
                <IconButton className={classes.icon} disabled
                  // hidden={this.props.user.role==='admin' ? true : false}
                  // onClick={(e) => this.handleEditOpen(e, path)}
                  style={{
                    opacity: "0.4",
                    transform: "scale(0.9)",
                    marginRight: "1px",
                    paddingLeft: "10px"
                  }}
                >
                  <Avatar className={classes.lightBlue}>
                    <EditIcon color="action" />
                  </Avatar>
                </IconButton>
                <IconButton className={classes.icon}
                  // hidden={this.props.user.role==='admin' ? true : false}
                  onClick={this.handleDeleteReason}
                  style={{ transform: "scale(0.9)", paddingLeft: "10px", marginLeft: "1px" }}
                >
                  <Avatar className={classes.lightBlue}>
                    <DeleteIcon color="action" />
                  </Avatar>
                </IconButton>
              </Grid>
              : null
          }

          {/* the data already present for order cancellation */}
          {
            this.props.dashboard.getAdminOrderCancellationReasons && this.props.dashboard.getAdminOrderCancellationReasons.reasonForBusinessData && this.props.dashboard.getAdminOrderCancellationReasons.reasonForBusinessData.length > 0 ?
              this.props.dashboard.getAdminOrderCancellationReasons.reasonForBusinessData.map((reason, index) => {
                // console.log("reason---->",reason)
                return (
                  <Grid container justify="flex-start" alignItems="center" style={{ fontSize: "13px", paddingTop: "10px", paddingBottom: "3px", paddingLeft: "12px" }}>
                    <Checkbox
                      // onClick={(event) => this.handleCheckBoxAdmin(event,reason.reasonsForCancellation)}
                      onClick={(event) => this.handleModalCheckBoxAdmin(event, reason.reasonsForCancellation)}
                      value={reason.reasonsForCancellation.isAvailableForAdmin}
                      name="adminCheck"
                      checked={reason.reasonsForCancellation.isAvailableForAdmin}
                      style={{
                        paddingRight: "3px",
                        transform: "scale(0.9)"
                      }}
                    />
                    Admin

                    <Checkbox
                      // onClick={(event) => this.handleCheckBoxBusiness(event,reason.reasonsForCancellation)}
                      onClick={(event) => this.handleModalCheckBoxBusiness(event, reason.reasonsForCancellation)}
                      value={reason.reasonsForCancellation.isAvailableForBusiness}
                      name="businessCheck"
                      checked={reason.reasonsForCancellation.isAvailableForBusiness}
                      style={{
                        paddingRight: "3px",
                        transform: "scale(0.9)"
                      }}
                    />
                    Business

                    <Checkbox
                      // onClick={(event) => this.handleCheckBoxShopper(event,reason.reasonsForCancellation)}
                      onClick={(event) => this.handleModalCheckBoxShoppers(event, reason.reasonsForCancellation)}
                      value={reason.reasonsForCancellation.isAvailableForShoppers}
                      name="shopperCheck"
                      checked={reason.reasonsForCancellation.isAvailableForShoppers}
                      style={{
                        paddingRight: "3px",
                        transform: "scale(0.9)"
                      }}
                    />
                    Shoppers

                    {
                      reason.reasonsForCancellation.isActive === true ?
                        <Typography
                          className={classes.gridborder}
                          // InputLabelProps={{
                          //     shrink: true,
                          // }}
                          // variant="outlined" 
                          gutterBottom style={{ width: "50%", paddingLeft: "28px", paddingRight: "23px", backgroundColor: this.returnColor(reason.reasonsForCancellation.isActive), color: this.returnTextColor(reason.reasonsForCancellation.isActive) }}
                        >
                          {reason.reasonsForCancellation.reason}
                        </Typography>
                        :
                        <Typography
                          className={classes.gridborder}
                          // InputLabelProps={{
                          //     shrink: true,
                          // }}
                          // variant="outlined" 
                          gutterBottom style={{ width: "50%", paddingLeft: "28px", paddingRight: "23px", backgroundColor: this.returnColor(reason.reasonsForCancellation.isActive) }}
                        >
                          {reason.reasonsForCancellation.reason}
                        </Typography>
                    }
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          key={`reasonActiveSwitch_${reason.reasonsForCancellation._id}_${index}`}
                          checked={reason.reasonsForCancellation.isActive === true}
                          onChange={(e) => this.handleSwitchChangeCancellationReason(e, reason.reasonsForCancellation)}
                          color="primary"
                        />
                      }
                      style={{
                        fontSize: "5px",
                        transform: "scale(0.9)",
                        marginRight: "1px",
                        paddingRight: "0px"
                      }}
                    />
                    <IconButton className={classes.icon}
                      // hidden={this.props.user.role==='admin' ? true : false}
                      onClick={(e) => this.handleEditOpen(e, reason.reasonsForCancellation)}
                      style={{
                        transform: "scale(0.9)",
                        marginRight: "1px",
                        paddingLeft: "10px"
                      }}
                    >
                      <Avatar className={classes.lightBlue}>
                        <EditIcon color="action" />
                      </Avatar>
                    </IconButton>
                    <IconButton className={classes.icon}
                      // hidden={this.props.user.role==='admin' ? true : false}
                      onClick={(e) => { this.modalReasonDeleteOpen(e, reason.reasonsForCancellation) }}
                      style={{ transform: "scale(0.9)", paddingLeft: "10px", marginLeft: "1px" }}
                    >
                      <Avatar className={classes.lightBlue}>
                        <DeleteIcon color="action" />
                      </Avatar>
                    </IconButton>
                  </Grid>
                );
              })
              : null
          }

        </TabPanel>
       

        <TabPanel value={this.state.value} index={2} classes={{ root: classes.tab }}>

          <Paper className={classes.paperPayment}>
          <Grid item xs={12}>
              <Container style={{borderBottom: "dashed 1px grey"}}>
                <Grid container
                  justify="flex-start"
                  alignItems="center"                  
                >

                  <Grid item xs={5} >
                    <Typography style={{ padding: "10px"}} variant="subtitle2" gutterBottom>
                     <b>Allow Post Payment</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}  style={{ paddingLeft: "16px"}}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={(this?.props?.dashboard?.getMasterOTP?.data?.length) ? this.props.dashboard.getMasterOTP.data[0].isPostPaymentAllowed : false}
                      onChange={(e) => this.handleAllowPostPayment(e)}
                      color="primary"
                    />
                  }
                  style={{ fontSize: "5px" }}
                  label={(this?.props?.dashboard?.getMasterOTP?.data?.length && this?.props?.dashboard?.getMasterOTP?.data?.[0].isPostPaymentAllowed === true)  ? 'Yes' : 'No'}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography style={{ margin: "10px" }} variant="subtitle2" gutterBottom>
                Order Block function                </Typography>
              </Grid>
              <Grid item xs={3}  style={{ paddingLeft: "16px"}}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={(this.props?.dashboard?.getMasterOTP?.data?.length > 0 && this.props?.dashboard?.getMasterOTP?.data[0].isOrderBlockFunction && this.props?.dashboard?.getMasterOTP?.data[0].isOrderBlockFunction === true) ? true : false}
                    onChange={(e) => this.handleIsOrderBLockFunctionFlag(e)}
                    color="primary"
                  />
                }
                style={{ fontSize: "5px" }}
                label={(this.props?.dashboard?.getMasterOTP?.data?.length > 0 && this.props?.dashboard?.getMasterOTP?.data[0].isOrderBlockFunction && this.props?.dashboard?.getMasterOTP?.data[0].isOrderBlockFunction === true) ? "Yes" : "No"}
              />
            </Grid>
                  
                </Grid>
              </Container>
            </Grid>
          <Grid item xs={12}>
              <Container>
                <Grid container
                  justify="flex-start"
                  alignItems="center"
                >

                  <Grid item xs={5}>
                    <Typography style={{ padding: "10px" }} variant="subtitle2" gutterBottom>
                      Set minimum of orders for shoppers to become eligible for post payment
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
              <TextField                
                margin="dense"
                className={classes.rupess}
                variant="outlined"
                style={{ padding: "10px" }}
                value={this.state.minOrdersOfConsumerForPostPayment}
                name="minOrdersOfConsumerForPostPayment"
                onChange={this.handleMinOrdersOfConsumerForPostPaymentChange}
                error = {this.state.minOrdersOfConsumerForPostPaymentError}
                helperText={this.state.minOrdersOfConsumerForPostPaymentErrorText}
                disabled = {this?.props?.dashboard?.getMasterOTP?.data?.length && this.props.dashboard.getMasterOTP.data[0].isPostPaymentAllowed ? false : true }
              />
            </Grid>
                  <Grid item xs={5} >
                    <Typography style={{ margin: "10px" }}>
                      Orders
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12}>
              <Container>
                <Grid container
                  justify="flex-start"
                  alignItems="center"
                >

                  <Grid item xs={5}>
                    <Typography style={{ margin: "10px" }} variant="subtitle2" gutterBottom>
                      Payment Cycle Date
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{ margin: "10px" }} variant="subtitle2" gutterBottom>
                      {"("} {this.state.paymentCycleDay}{"-"} {this.state.monthNames} {")"}
                    </Typography>
                  </Grid>

                  <Grid item xs={5} >
                    <Typography style={{ margin: "10px" }}>
                      Last day of each month
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12}>
              <Container>
                <Grid container
                  justify="flex-start"
                  alignItems="center"
                >

                  <Grid item xs={5}>
                    <Typography style={{ margin: "10px" }} variant="subtitle2" gutterBottom>
                      Select Consumer Block Date if Payment Not Done
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="fronpm run devm"
                        value={this.state.blockDateIfNotPaid}
                        name="GracePeriodDays"
                        onChange={this.handleBlockDateForPaymentNotDone}
                        disabled={this.state.isOrderBlockFunction ? false : true}
                        style={{ width: "80px", height: "39px", margin: "10px" }}
                      >
                        {daysOfMonth.map((index) => {
                          return (
                            <MenuItem key={index} value={index} style={{ minHeight: "28px", height: "28px" }}>
                              <Typography noWrap>{index.toUpperCase()}</Typography>
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={5}>
                    <Typography style={{ margin: "10px" }}>
                      Block Date
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12}>
              <Container>
                <Grid container
                  justify="flex-start"
                  alignItems="center"
                >

                  <Grid item xs={5}>
                    <Typography style={{ margin: "10px" }} variant="subtitle2" gutterBottom>
                      Grace Period in Days                </Typography>
                  </Grid>
                  <Grid item xs={2}  >
                    <FormControl fullWidth margin="dense" variant="outlined">
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="fronpm run devm"
                        value={this.state.gracePeriodDays}
                        name="GracePeriodDays"
                        onChange={this.handleGracePeriodChange}
                        style={{ width: "80px", height: "39px", margin: "10px" }}
                        disabled={this.state.isOrderBlockFunction ? false : true}

                      >
                        {daysOfMonth.map((index) => {
                          return (
                            <MenuItem key={index} value={index} style={{ minHeight: "28px", height: "28px" }}>
                              <Typography noWrap>{index.toUpperCase()}</Typography>
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={5}>
                    <Typography style={{ margin: "10px" }} >
                      Days
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12}>
              <Container>
                <Grid container
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={8}>
                    <Typography>

                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      style={{ margin: "5px" }}
                      variant="contained"
                      component="span"
                      onClick={this.handleClosePaymentDetails}
                    // disabled={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) && (this.props.dashboard.getMasterOTP.data[0].defaultBusinessRadius === this.state.defaultBusinessRadius) ? true : false}
                    >
                      CANCEL
                    </Button>
                    {/* <Button variant="contained" component="span" onClick={this.close} style={{ margin: "5px" }} fullWidth> Cancel </Button> */}
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      autoFocus
                      style={{ margin: "5px", marginLeft: "-60px" }}
                      color="primary"
                      variant="contained"
                      component="span"
                      onClick={this.savePaymentDetails}
                    // disabled={(this.props && this.props.dashboard && this.props.dashboard.getMasterOTP && this.props.dashboard.getMasterOTP.data && this.props.dashboard.getMasterOTP.data.length) && (this.props.dashboard.getMasterOTP.data[0].defaultBusinessRadius === this.state.defaultBusinessRadius) ? true : false}

                    >
                      SAVE
                    </Button>
                  </Grid>
                </Grid>
              </Container>

            </Grid>
          </Paper>




        </TabPanel>
  
  
        <Modal
          className={classes.modal}
          open={this.state.editExotelConfig}
          onClose={this.close}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Container className={classes.mainContainer4}>
            <Grid container className={classes.header4} spacing={1} >
              <Grid item xs={8}>
                <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
                  Exotel Reminder Configuration
                </WhiteTextTypography>
              </Grid>
              <Grid item xs={4}>
                <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
                  <CloseIcon className={classes.headGridTwo} onClick={() => { this.close() }} />
                </WhiteTextTypography>
              </Grid>
            </Grid>

            <Container className={classes.modalContainer4}>
              <Grid container className={classes.grid} spacing={1} justify="center" alignItems="center" >
                <Grid item xs={6}>
                  1st Reminder
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl} >
                    <Select labelId="demo-simple-select-outlined-label" id="day" value={this.state.firstReminder} name="firstReminder" onChange={this.handleExotelReminders} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                      {this.state.firstMintesArray.map((index) => {
                        return (
                          <MenuItem value={index} style={{ minHeight: "28px", height: "28px" }}>
                            <Typography noWrap>{index}</Typography>
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                {this.state.firstRequired &&
                  <FormHelperText style={{ color: "red", paddingLeft: '20px' }}>
                    The reminders must be in increasing order of time.
                  </FormHelperText>
                }

                <Grid item xs={6}>
                  2nd Reminder
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl} >
                    <Select labelId="demo-simple-select-outlined-label" id="day" value={this.state.secondReminder} name="secondReminder" onChange={this.handleExotelReminders} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                      {this.state.minutes.map((index) => {
                        return (
                          <MenuItem value={index} style={{ minHeight: "28px", height: "28px" }}>
                            <Typography noWrap>{index}</Typography>
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                {this.state.secondRequired &&
                  <FormHelperText style={{ color: "red", paddingLeft: '20px' }}>
                    The reminders must be in increasing order of time.
                  </FormHelperText>
                }
                <Grid item xs={6}>
                  3rd Reminder
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl} >
                    <Select labelId="demo-simple-select-outlined-label" id="day" value={this.state.thirdReminder} name="thirdReminder" onChange={this.handleExotelReminders} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                      {this.state.minutes.map((index) => {
                        return (
                          <MenuItem value={index} style={{ minHeight: "28px", height: "28px" }}>
                            <Typography noWrap>{index}</Typography>
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                {this.state.thirdRequired ?
                  <FormHelperText style={{ color: "red", paddingLeft: '20px' }}>
                    The reminders must be in increasing order of time.
                  </FormHelperText> : null
                }
                <Grid item xs={12}> <Divider color="textSecondary" style={{ marginBottom: "5px", marginTop: "10px" }} display="block" /> </Grid>
                <Grid item xs={6} className={classes.gridItem} ></Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <Button variant="contained" component="span" onClick={this.close} style={{ margin: "5px" }} fullWidth> Cancel </Button>
                </Grid>
                <Grid item xs={3} className={classes.gridItem} >
                  <Button variant="contained" color="primary" component="span" onClick={this.saveExotelConfig} style={{ margin: "5px" }} fullWidth > Save </Button>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </Modal>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
          ContentProps={{ 'aria-describedby': 'message-id' }}
        >
          {muiAlert}
        </Snackbar>

        <Dialog open={this.state.modalDeleteOpen} onClose={this.handleDeleteClose}>
          <DialogTitle>Delete Splash Screen</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
            <Button onClick={() => this.deleteSpashScreen(this.state.selectedPhoto, this.state.typePhoto)} color="primary" autoFocus> Yes </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.splashScreenTimerModel} onClose={this.handleDeleteClose}>
          <DialogTitle> Splash Screen Timer</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to Set Splash Screen Timer?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteSplashScreenTimer} color="primary"> No </Button>
            <Button onClick={() => this.setSplashScreenTimer(this.state.splashScreenTimer)} color="primary" autoFocus> Yes </Button>
          </DialogActions>
        </Dialog>


        {/* Edit the cancellation order reason */}
        <Dialog open={this.state.reasonEditModalOpen} onClose={this.handleModalClose} aria-labelledby="form-dialog-title" >
          <DialogTitle className="dialogTitle" style={{ backgroundColor: "#14B7DD" }}>
            Edit Reason for Order Cancellation
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle2" gutterBottom>
              Reason for Order Cancellation
            </Typography>
            <TextField
              required
              autoFocus
              margin="dense"
              id="reason"
              placeholder="Reason"
              fullWidth
              defaultValue={this.state.selectedCancallationReason != null ? this.state.selectedCancallationReason.reason : null}
              style={{ width: "500px" }}
              onChange={this.handleEditCancellationReasonChange}
              // error={this.state.reasonEditError}
              // helperText={this.state.reasonEditError?"Required":null}
              error={this.state.reasonEditError ? this.state.reasonEditError : (this.state.reasonEditErrorExists ? this.state.reasonEditErrorExists : false)}
              helperText={this.state.reasonEditError ? "Required" : (this.state.reasonEditErrorExists ? "Order Cancellation Reason already exists" : null)}
            />
            <DialogContentText>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleModalClose} variant="contained" color="secondary">Close</Button>
            <Button
              disabled={!(this.state.reasonEdit)}
              onClick={() => this.editCancellationReason(this.state.selectedCancallationReason, this.state.reasonEdit)}
              variant="contained" color="primary">Save</Button>
          </DialogActions>
        </Dialog>

        {/* Deleting the cancellation reason */}
        <Dialog open={this.state.modalReasonDeleteOpen} onClose={this.handleDeleteClose}>
          <DialogTitle>Delete Reason for Order Cancellation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteClose} color="primary" autoFocus> No </Button>
            <Button onClick={() => this.handleDeleteOnAdd(this.state.selectedReason)} color="primary"> Yes </Button>
          </DialogActions>
        </Dialog>


        {/* Confirmation dialog when clicked on the checkbox */}
        <Dialog open={this.state.handleModalCheckBoxAdmin} onClose={this.handleCheckboxClose}>
          <DialogTitle>Update Reason for Order Cancellation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to {this.state.selectedReason && this.state.selectedReason.isAvailableForAdmin === true ? "uncheck 'Admin' for " : "check 'Admin' for "} this reason?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCheckboxClose} color="primary"> Cancel </Button>
            <Button onClick={() => this.handleCheckBoxAdmin(this.state.selectedReason)} color="primary" autoFocus> Save </Button>
          </DialogActions>
        </Dialog>

        {/* Confirmation dialog when clicked on the checkbox */}
        <Dialog open={this.state.handleModalCheckBoxBusiness} onClose={this.handleCheckboxClose}>
          <DialogTitle>Update Reason for Order Cancellation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to {this.state.selectedReason && this.state.selectedReason.isAvailableForBusiness === true ? "uncheck 'Business' for " : "check 'Business' for "} this reason?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCheckboxClose} color="primary"> Cancel </Button>
            <Button onClick={() => this.handleCheckBoxBusiness(this.state.selectedReason)} color="primary" autoFocus> Save </Button>
          </DialogActions>
        </Dialog>

        {/* Confirmation dialog when clicked on the checkbox */}
        <Dialog open={this.state.handleModalCheckBoxShoppers} onClose={this.handleCheckboxClose}>
          <DialogTitle>Update Reason for Order Cancellation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to {this.state.selectedReason && this.state.selectedReason.isAvailableForShoppers === true ? "uncheck 'Shoppers' for " : "check 'Shoppers' for "} this reason?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCheckboxClose} color="primary"> Cancel </Button>
            <Button onClick={() => this.handleCheckBoxShopper(this.state.selectedReason)} color="primary" autoFocus> Save </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
                    anchorOrigin={{vertical: 'top',horizontal: 'center'}}
                    open={this.state.snackOpen}
                    autoHideDuration={2000}
                    onClose={this.handleSnackClose}
                    ContentProps={{'aria-describedby': 'message-id'}}>
                        {muiAlert}
                </Snackbar>

        <Backdrop className={classes.backdrop} open={this.state.isFetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    dashboard: state.dashboard,
    user: state.auth.user,
    admin: state.admin,
    deliveries: state.deliveries,
    env: state.auth.env
  }
};
export default connect(mapStateToProps, {
  changeHeaderName,
  getAllMasterOtp,
  setCofiguration,
  setExotel,
  settlementCronJob,
  couponSettlementCronJob,
  couponExpiryCron,
  deleteOpenCloseRecords,
  clearMsg,
  fetchEnvs,
  clearMessage,
  updateSplashScreenUrl,
  setSmsProvider,
  setExotelGlobalConfig,
  saveOrderCancellationReason,
  deleteOrderCancellationReason,
  getCancellationReasonByRole,
  businessDashboardCronDaily,
  businessDashboardCronMonthly,
  businessDashboardCronWeekly,
  callBusinessDashboardCron, callBusinessDashboardCronReset , getCashfreeVendorSplitSettlementData
})(withStyles(styles)(configurations));