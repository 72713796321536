import React, { Component } from "react";
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import UploadReferralPhoto from "./uploadReferralPhoto";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import DeleteIcon from '@material-ui/icons/Delete';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
      marginTop: '100px',
      width: '100%',
      height: '100%',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: '0px 0px',
      display: 'flex',
      alignItems: 'center',
      height: '60px',
      marginBottom: '25px'
    },
    paper2: {
      padding: '0px 0px',
      display: 'flex',
      alignItems: 'center',
      height: '150px',
      marginBottom: '25px'
    },
    snackClose: {
      padding: theme.spacing(0.5),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px",
      width: "100%",
    },
    form: {
    //   width: '70%',
    //   height: '170%',
        // width: '100%',
        // height: '100%',
        marginTop: "300px",
        width: '70%',
        height: '200%',

    },
    header: {
    //   height: '9%',
    height: '7%',
      margin: '0px 0px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
  },
  headGridTwo: {
    float: 'right',
    marginRight: '2px'
  },
  content: {
    height: '60%',
    // margin: '10px 0px',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
      width: '100%',
      height:'100%'
  },

  gridElement: {
      margin: '10px 5px'
  },

  img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
  },
  icon: {
      color: 'white',
  },

});

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

class referralAddForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snackOpen: false,
            sucessMsg: null,
            value: 0,
            date: "",
            error: {},
            modalOpen: false, 
            enableReferAndEarn: false,
            referralAmountReferred: "",
            minimumOrderValueReferred: "",
            couponExpiryDaysReferred: "",
            referralAmountReferring: "",
            minimumOrderValueReferring: "",
            couponExpiryDaysReferring: "",
            referralCouponName: "",
            referralAmountError: false,
            minimumOrderValueError: false,
            couponExpiryError: false,
            addReferralData: false,
            type: "",
            editReferrals: false,
            referralData:{},
            referralCouponImage: "",
            modalDeleteOpen: false,
            selectedPhoto: null,
            typePhoto: "", //imageUrl
            termsAndConditionsUrl: "",
        }
    }

    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    }

    onSubmit = formValues => {
        // console.log("form values--->", formValues);
        formValues.referralCouponImage = this.state.referralCouponImage;
        this.props.onSubmit(formValues);
    }

    handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value
          // error: {}
        });
        if (!_.isEmpty(this.state.error)) {
                this.setState({ error: {} })
            }
      }

    handleDeleteOpen = (photo) => {
        this.setState({ modalDeleteOpen: true });
        this.setState({ selectedPhoto: photo });
    }
    handleDeleteClose = () => {
        this.setState({ modalDeleteOpen: false });
    }

    componentDidMount(){
        this.setState({
            referralCouponImage: this.props.configurationsProps[0].referralCouponImage ? this.props.configurationsProps[0].referralCouponImage : "",
        });
    }

    updateReferralPhotos = async (files, photoType) => {
        let data = new FormData();
        let folderPath = "Marketplace_Business";
        let temp = files[0].name.split(".");
        let fileName = temp[0] + Math.ceil(Math.random() * 999999) + "." + temp[1];
    
        data.append("file", files[0]);
        data.append("folder", folderPath);
        data.append(
            "public_id",
            `${this.props.configurationsProps[0]._id}/${fileName}`
        );
        data.append(
            "upload_preset",
            this.props.envs.env.cloudinaryBusinessImagesUploadPreset
        );
    
        let res = await fetch(this.props.envs.env.cloudinaryUploadUrl, {
            method: "POST",
            body: data,
        });
    
        let file = await res.json();    
        // let payload = {};
        if (photoType === "Referral") {
        //   this.props.configurationsProps[0].imageUrl.push({
        //     title: "",
        //     path: file.secure_url,
        //     public_id: file.public_id,
        //   });

        // let imageUrl = {
        //     title: "",
        //     path: file.secure_url,
        //     public_id: file.public_id,
        // }

        let referralCouponImage = file.secure_url;    
        this.setState({ referralCouponImage });
        this.setState({ isUploading: false, typePhoto: photoType });
    
        //   payload = {
        //     businessId: this.props.businessDetails._id,
        //     action: "uploaded",
        //     data: {
        //       $push: {
        //         imageUrl: {
        //           title: "",
        //           path: file.secure_url,
        //           public_id: file.public_id,
        //         },
        //       },
        //     },
        //   };
        }
        // this.props.updateBusinessPhotos(payload);
    };

    deleteReferralPhoto = async (selectedPhoto, photoType) => {    
        if (photoType === "Referral") {
            // this.props.configurationsProps[0].imageUrl = this.props.configurationsProps[0].imageUrl !== selectedPhoto
            this.props.configurationsProps[0].referralCouponImage = ""
    
        this.setState({
            referralCouponImage: this.props.configurationsProps[0].referralCouponImage,
            modalDeleteOpen: false 
        });
        }
    };

    render() {
        const { classes } = this.props;
        // console.log("this.props in add form", this.props)

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                
                <Grid container className={classes.header} spacing={3}>
                <Grid item xs={8}>
                    <WhiteTextTypography variant="h6" color="inherit" gutterBottom >
                        Referrals Configuration
                    </WhiteTextTypography>
                </Grid>
                <Grid item xs={4}>
                    <WhiteTextTypography variant="h6" color="inherit" gutterBottom >
                        <CloseIcon
                            className={classes.headGridTwo}
                            onClick={() => { this.props.onClose() }}
                        />
                    </WhiteTextTypography>
                </Grid>
                </Grid>

                <Container className={classes.content}>
                    <Grid container className={classes.grid} spacing={1} justify="center" alignItems="center" >
                        {/* For Referred User */}
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            <Typography>Referred User</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Amount *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Minimum Order Value *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Expiry (Days) *
                            </Typography>
                        </Grid>
                        <Grid></Grid>

                        {/* TextFields */}
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="referralAmountReferred" label="Coupon Amount *" variant="outlined"
                                component={this.renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="minimumOrderValueReferred" label="Minimum Order Value *" variant="outlined" component={this.renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="couponExpiryReferred" label="Coupon Expiry (Days) *" inputProps={{ maxLength: 3 }} variant="outlined" component={this.renderTextField}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}></Grid>

                        {/* For Referring User */}
                        <Grid item xs={12}>
                            <Typography>Referring User</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Amount *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Minimum Order Value *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Expiry (Days) *
                            </Typography>
                        </Grid>

                        {/* TextFields */}
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="referralAmountReferring" label="Coupon Amount *" variant="outlined" component={this.renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="minimumOrderValueReferring" label="Minimum Order Value *" variant="outlined" component={this.renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="couponExpiryReferring" label="Coupon Expiry (Days) *" inputProps={{ maxLength: 3 }} variant="outlined" component={this.renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}><Typography variant="subtitle2" gutterBottom>
                                
                            </Typography></Grid>

                        {/* Text field for referral coupon name */}
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Referral Coupon Name *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                T&C *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="referralCouponName" label="Referral Coupon Name *" variant="outlined" component={this.renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="termsAndConditionsUrl" label="Terms And Conditions *" variant="outlined" component={this.renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}></Grid>

                        {/* Upload Referral Image button */}
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            <UploadReferralPhoto
                                uploadButtonName = "UPLOAD REFERRAL PHOTO"
                                updateReferralPhotos = {this.updateReferralPhotos}
                                photoType="Referral"
                                // deleteReferralPhoto={this.deleteReferralPhoto}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {(this.state.referralCouponImage !== "" ) ?
                                    <GridList cols={7} cellHeight={80} className={classes.gridList}>
                                        <GridListTile
                                            className={classes.gridElement} 
                                            // key={images._id}
                                        >
                                            <a href={this.state.referralCouponImage} target="_blank" rel="noopener noreferrer">
                                            <img border="0" className={classes.img} src={this.state.referralCouponImage} alt="" />
                                            </a>
                                            <GridListTileBar  
                                            titlePosition="bottom"
                                            actionIcon={
                                                <IconButton className={classes.icon} 
                                                onClick={() => this.handleDeleteOpen(this.state.referralCouponImage)}
                                                >
                                                <DeleteIcon />
                                                </IconButton>
                                            }
                                            actionPosition="right"
                                            />
                                        </GridListTile>
                                    ))
                                    </GridList >
                            : null}
                        
                        </Grid>

                        {/* <Grid item xs={12}>
                            {(this.state.imageUrl != null) ?
                                    <GridList cols={7} cellHeight={80} className={classes.gridList}>
                                        <GridListTile
                                            className={classes.gridElement} 
                                            // key={images._id}
                                        >
                                            <a href={this.state.imageUrl.path} target="_blank" rel="noopener noreferrer">
                                            <img border="0" className={classes.img} src={this.state.imageUrl.path} alt="" />
                                            </a>
                                            <GridListTileBar  
                                                
                                            titlePosition="bottom"
                                            actionIcon={
                                                <IconButton className={classes.icon} 
                                                // onClick={() => this.handleDeleteOpen(images)}
                                                >
                                                <DeleteIcon />
                                                </IconButton>
                                            }
                                            actionPosition="right"
                                            />
                                        </GridListTile>
                                    ))
                                    </GridList >
                            : null}
                        
                        </Grid> */}

                        <Grid item xs={12}>
                            <Divider
                                color="textSecondary"
                                style={{ marginBottom: "5px", marginTop: "10px" }}
                                display="block"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                style={{ margin: "5px" }}
                                fullWidth
                                variant="contained"
                                onClick={() => { this.props.onClose() }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                fullWidth
                                style={{ margin: "5px" }}
                                variant="contained"
                                color="primary"
                                // onClick={this.addReferrals}
                                type="submit"
                            >
                                SAVE
                            </Button>
                        </Grid>
                    </Grid>
                </Container>

                <Dialog open={this.state.modalDeleteOpen} onClose={this.handleDeleteClose}>
                <DialogTitle>Delete Photo</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    Do you want to delete this photo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
                    <Button onClick={() => this.deleteReferralPhoto(this.state.selectedPhoto, this.state.typePhoto)} color="primary" autoFocus> Yes </Button>
                </DialogActions>
                </Dialog>
            </form>
        );
    }
}

const validate = values => {
    const errors = {}
    const requiredFields = [
        'referralAmountReferred',
        'minimumOrderValueReferred',
        'couponExpiryReferred',
        'referralAmountReferring',
        'minimumOrderValueReferring',
        'couponExpiryReferring',
        'referralCouponName',
        'termsAndConditionsUrl'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });

    if (values.referralCouponName) {
        // if ( !/^[A-Za-z]+$/i.test(values.referralCouponName)) {
        //     errors.username = 'Enter Valid Referral Coupon Name'
        // }
        // if (/^[0-9]+$/i.test(values.referralCouponName) || !/^[A-Za-z]+$/i.test(values.referralCouponName) ) {
        //     errors.referralCouponName = 'Enter Valid Referral Coupon Name';
        // }
        if (!/^[A-Za-z0-9\x20]+$/i.test(values.referralCouponName) ) {
            errors.referralCouponName = 'Enter Valid Referral Coupon Name';
        }
    }
    if (values.referralAmountReferred) {
        if (values.referralAmountReferred < 1 || !/^[0-9]+$/i.test(values.referralAmountReferred) ) {
            errors.referralAmountReferred = 'Invalid Amount'
        }
    }
    if (values.referralAmountReferring) {
        if (values.referralAmountReferring < 1 || !/^[0-9]+$/i.test(values.referralAmountReferring) ) {
            errors.referralAmountReferring = 'Invalid Amount'
        }
    }
    if (values.minimumOrderValueReferred) {
        if (values.minimumOrderValueReferred < 1 || !/^[0-9]+$/i.test(values.minimumOrderValueReferred) ) {
            errors.minimumOrderValueReferred = 'Invalid Amount'
        }
    }
    if (values.minimumOrderValueReferring) {
        if (values.minimumOrderValueReferring < 1 || !/^[0-9]+$/i.test(values.minimumOrderValueReferring) ) {
            errors.minimumOrderValueReferring = 'Invalid Amount'
        }
    }
    if (values.couponExpiryReferred) {
        if (values.couponExpiryReferred < 1 || !/^[0-9]+$/i.test(values.couponExpiryReferred) ) {
            errors.couponExpiryReferred = 'Invalid Days Entered'
        }
    }
    if (values.couponExpiryReferring) {
        if (values.couponExpiryReferring < 1 || !/^[0-9]+$/i.test(values.couponExpiryReferring) ) {
            errors.couponExpiryReferring = 'Invalid Days Entered'
        }
    }

    return errors
}

export default reduxForm({
    form: 'ReferralAddForm',
    validate
})(withStyles(styles)(referralAddForm))
