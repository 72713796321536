import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';    
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';

// function descendingComparator(a, b, orderBy) {
//     if (b[orderBy] < a[orderBy]) {
//         return -1;
//     }
//     if (b[orderBy] > a[orderBy]) {
//         return 1;
//     }
//     return 0;
// }

const headCells = [
    { id: 'business.businessName', disablePadding: true, label: 'Business Name' },
    { id: 'business.businessId', disablePadding: true, label: 'businessId' },
    { id: 'requestsCount', disablePadding: true, label: 'Service Request Count' },
    { id: 'totalAmount', disablePadding: false, label: 'Amount' },
    { id: 'ConsumerCount', disablePadding: false, label: 'ConsumerCount' }
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableCellSmall}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
              User/Order Growth Report
            </Typography>

            <Typography component="div">
            {props.showDownloadIcon ?
                <CSVLink
                    data={props.data}
                    headers={props.headers}
                    filename={"business_Performance_report.csv"}
                    target="_blank"
                >
                    <Tooltip title="Export to Excel">
                        <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                    </Tooltip>
                </CSVLink>
                :
                    <Button
                        variant="contained"
                        margin="normal"
                        style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                        onClick={props.setDownloadIcon}
                    >
                        Export
                    </Button>
                }
            </Typography>
        </Toolbar>
        
    );
};
const EnhancedTableToolbar2 = (props) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
        <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
          {props.businessName}({props.businessId})
        </Typography>
    </Toolbar>

    );
};

// EnhancedTableToolbar.propTypes = {
//     exportToExcel: PropTypes.func.exportToExcel,
//     total: PropTypes.number.isRequired
// };

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    tableRow: {
        // cursor: 'pointer'
    },
    container: {
        maxHeight: 440,
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    // Generating excel report Data..
    let headers = [
        { key: 'Date', label: 'Date' },
        { key: 'Requests', label: 'Requests' },
        { key: 'amount', label: 'Amount' },
        { key: 'Consumers', label: 'Consumers' }
    ];

    // const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.data.total - props.page * props.rowsPerPage);
     if(props.data.total ){
        if(props.data.exportToExcel[0].Date !== 'Total')
        {
            props.data.exportToExcel.splice(0,0,props.data.total);
        }
     }
     let data = props.data.exportToExcel;
    data && data.length>0 && data.map(element => {

      element.Date = element.Date ? element.Date : "Total"
      element.Requests = element.Requests ?element.Requests : "NA"
      element.amount = element.Amount ? element.Amount.toFixed(2) : "NA"
      element.Consumers =  element.Consumers ?  element.Consumers : "NA" 
    }); 
    return ( 
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                 headers={headers} 
                 data={data } 
                 showDownloadIcon={props.showDownloadIcon} 
                 setDownloadIcon={props.setDownloadIcon}
                
                />
                <EnhancedTableToolbar2 businessName={props.data.businessName} businessId={props.data.businessId} />
                
                <TableContainer component={Paper}>
            <Table className={classes.table}
              aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Request</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Consumers</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow className={classes.tableRow} hover  button={true} >
                      <TableCell className={classes.tableCellSmall} component="th" scope="row" >
                       Total
                      </TableCell>
                      <TableCell className={classes.tableCellSmall} component="th" scope="row" >
                       {props.data.total.Requests}
                      </TableCell>
                      <TableCell className={classes.tableCellSmall} component="th" scope="row" >
                      {props.data.total.Amount.toFixed(2)}
                      </TableCell>
                      <TableCell className={classes.tableCellSmall} component="th" scope="row" >
                      {props.data.total.Consumers}
                      </TableCell>
                      </TableRow>
              {props.data.report.map((record)=>
              <TableRow className={classes.tableRow} hover  button={true} >
                      <TableCell className={classes.tableCellSmall} component="th" scope="row" >
                       {record.Date}
                      </TableCell>
                      <TableCell className={classes.tableCellSmall} component="th" scope="row" >
                       {record.Requests}
                      </TableCell>
                      <TableCell className={classes.tableCellSmall} component="th" scope="row" >
                      {record.Amount.toFixed(2)}
                      </TableCell>
                      <TableCell className={classes.tableCellSmall} component="th" scope="row" >
                      {record.Consumers}
                      </TableCell>
                      </TableRow>
              )}
              </TableBody>

              </Table>
          </TableContainer>
                {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={props.data.total}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                /> */}
            </Paper>
        </div>
    );
}

