import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import moment from 'moment';
import Button from '@material-ui/core/Button';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'business.businessName', disablePadding: true, label: 'Business Name' },
    { id: 'business.category', disablePadding: true, label: 'Category' },
    { id: 'requestsCount', disablePadding: true, label: 'Service Request Count' },
    { id: 'totalAmount', disablePadding: false, label: 'Amount' }
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableCellSmall}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Top Performing Businesses ({props.total})
            </Typography>
            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"business_report.csv"}
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                    :
                    <Button
                        variant="contained"
                        margin="normal"
                        style={{backgroundColor:"green",color:"white",fontFamily:"bold"}}
                        onClick={props.fetchBusinessReportsWithExcel}
                    >
                        Export
                </Button>
                }
            </Typography>
        </Toolbar>
    );
};

/* EnhancedTableToolbar.propTypes = {
    exportToExcel: PropTypes.func.exportToExcel,
    total: PropTypes.number.isRequired
}; */

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    tableRow: {
        // cursor: 'pointer'
    },
    container: {
        maxHeight: 440,
      },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    // const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let getDaysArray = function (s, e) {
        for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
            if (new Date(d).toISOString().slice(0, 10) === new Date().toISOString().slice(0, 10)) {
                a.push(new Date(d).toISOString().slice(0, 10));
                break;
            }
            a.push(new Date(d).toISOString().slice(0, 10));

        } return a;
    };
    let dayList = getDaysArray(new Date(props.reportStartDate), new Date(props.reportEndDate));
    dayList.shift();

    let days = [];
    let subHeaders = [];
    for (let index = 0; index < dayList.length; index++) {
        let day = dayList[index];
        let date = day.split("-")[2];
        let month = day.split("-")[1];
        days.push(date + " " + months[Number(month)]);
        subHeaders.push("Requests");
        subHeaders.push("Amount");
    }

    /* const handleRequestSort = (event, property) => {
        const isAsc = props.orderBy === property && props.order === 'asc';
        props.setOrder(isAsc ? -1 : 1);
        props.setOrderBy(property);
    }; */

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    // Generating excel report Data..
    let headers = [
        { key: 'businessName', label: 'Business Name' },
        { key: 'category', label: 'Category' },
        { key: 'createdAt', label: 'Onboarded On' }
    ];

    let reportDataToExport = [];
    for (let i = 0; i < props.data.exportToExcel.length; i++) {
        const business = props.data.exportToExcel[i];
        let report = {};
        let totalRequestCountToExport = 0;
        let totalAmountToExport = 0;
        report.businessName = business._id;
        report.category = business.businessDetails.category;
        report.createdAt = moment(business.businessDetails.createdAt).format('DD MMM YYYY hh:mm A');

        let sortedReportArrayToExport = business.dayByDayReport.sort((a, b) => new Date(a.requestDate) - new Date(b.requestDate));
        for (let index = 0; index < dayList.length; index++) {
            let matched = false;
            let day = dayList[index];
            let date = day.split("-")[2];
            let month = day.split("-")[1];
            for (let j = 0; j < sortedReportArrayToExport.length; j++) {
                const element = sortedReportArrayToExport[j];

                if (day === element.requestDate) {
                    matched = true;
                    totalRequestCountToExport = totalRequestCountToExport + Number(element.countOfTheDay);
                    totalAmountToExport = totalAmountToExport + element.amountOfTheDay;

                    let headerDate = date + " " + months[Number(month)];
                    report[headerDate + " | Requests"] = element.countOfTheDay;
                    report[headerDate + " | Amount"] = element.amountOfTheDay;
                }
            }
            if (!matched) {
                let headerDate = date + " " + months[Number(month)];
                report[headerDate + " | Requests"] = 0;
                report[headerDate + " | Amount"] = 0;
            }
        }
        report.totalRequestCountToExport = totalRequestCountToExport;
        report.totalAmountToExport = totalAmountToExport;

        reportDataToExport.push(report);
    }

    for (let index = 0; index < dayList.length; index++) {
        let day = dayList[index];
        let date = day.split("-")[2];
        let month = day.split("-")[1];
        headers.push({ key: date + " " + months[Number(month)] + " | Requests", label: date + " " + months[Number(month)] + " | Requests" });
        headers.push({ key: date + " " + months[Number(month)] + " | Amount", label: date + " " + months[Number(month)] + " | Amount" });
    }

    headers.push({ key: "totalRequestCountToExport", label: "Total Requests" });
    headers.push({ key: "totalAmountToExport", label: "Total Amount" });

    let data = reportDataToExport;
    //--
    const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.data.total - props.page * props.rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar total={props.data.total} headers={headers} data={data} fetchBusinessReportsWithExcel={props.fetchBusinessReportsWithExcel} showDownloadIcon={props.showDownloadIcon}/>
                <TableContainer className={classes.container}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        //aria-label="enhanced table"
                        stickyHeader aria-label="sticky table"
                    >
                        {/* <EnhancedTableHead
                            classes={classes}
                            order={props.order}
                            orderBy={props.orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={props.data.total}
                        /> */}

                        <TableHead>
                            <TableRow>
                                <TableCell rowSpan={2} >
                                    Business Name
                                </TableCell>
                                <TableCell rowSpan={2} >
                                    Category
                                </TableCell>
                                <TableCell rowSpan={2} >
                                    Onboarded On
                                </TableCell>
                                <TableCell colSpan={2} style={{ textAlign: "center", background: "#d5dada", borderStyle: "groove", borderWidth: "2px" }}>
                                    Total
                                </TableCell>
                                {
                                    days.map((day, index) => {
                                        return (
                                            <TableCell key={index} colSpan={2} style={{ textAlign: "center", background: "#d5dada", borderStyle: "groove", borderWidth: "2px" }}>
                                                {day}
                                            </TableCell>
                                        );
                                    })
                                }
                            </TableRow>
                            <TableRow>
                                {
                                    subHeaders.map((subHeader, index) => {
                                        return (
                                            <TableCell key={index} style={{ textAlign: "center", fontSize: "12px", background: subHeader === "Requests" ? "#c1c1c1" : "#bdbaba", top: 40 }}>
                                                {subHeader}
                                            </TableCell>
                                        );
                                    })
                                }
                                <TableCell style={{ textAlign: "center", fontSize: "12px", background: "#c1c1c1", top: 40 }}>
                                    Requests
                                </TableCell>
                                <TableCell style={{ textAlign: "center", fontSize: "12px", background: "#bdbaba", top: 40 }}>
                                    Amount
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(props.data.data, getComparator(props.order, props.orderBy))
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    let reportData = [];
                                    let totalRequestCount = 0;
                                    let totalAmount = 0;
                                    let sortedReportArray = row.dayByDayReport.sort((a, b) => new Date(a.requestDate) - new Date(b.requestDate));
                                    for (let index = 0; index < dayList.length; index++) {
                                        let matched = false;
                                        let day = dayList[index];
                                        for (let j = 0; j < sortedReportArray.length; j++) {
                                            const element = sortedReportArray[j];
                                            if (day === element.requestDate) {
                                                matched = true;
                                                totalRequestCount = totalRequestCount + Number(element.countOfTheDay);
                                                totalAmount = totalAmount + element.amountOfTheDay;
                                                let request = {
                                                    key: "Requests",
                                                    value: element.countOfTheDay
                                                };
                                                reportData.push(request);
                                                let amount = {
                                                    key: "Amount",
                                                    value: element.amountOfTheDay
                                                };
                                                reportData.push(amount);
                                            }
                                        }
                                        if (!matched) {
                                            let request = {
                                                key: "Requests",
                                                value: 0
                                            };
                                            reportData.push(request);
                                            let amount = {
                                                key: "Amount",
                                                value: 0
                                            };
                                            reportData.push(amount);
                                        }
                                    }

                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row._id}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" >
                                                {row._id}
                                            </TableCell>
                                            <TableCell align="left" >{row.businessDetails.category}</TableCell>
                                            <TableCell align="left" >{moment(row.businessDetails.createdAt).format('DD MMM YYYY hh:mm A')}</TableCell>
                                            <TableCell align="left" style={{ textAlign: "center", background: "#c1c1c1" }} >{totalRequestCount}</TableCell>
                                            <TableCell align="left" style={{ textAlign: "center", background: "#bdbaba" }}>{totalAmount  ?  totalAmount.toFixed(2): 0} </TableCell>
                                            {
                                                reportData.map((report, reportIndex) => {
                                                    return (
                                                        <TableCell key={reportIndex} style={{ background: report.key === "Requests" ? "#c1c1c1" : "#bdbaba", textAlign: "center" }} align="left" >{report.value  ? report.value.toFixed(2): 0}</TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={props.data.total}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
