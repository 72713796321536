import React from "react";
import { Component } from "react";
import Typography from '@material-ui/core/Typography';
import {changeHeaderName} from '../../actions/dashboardActions';
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddExpenseForm from './AddExpenseForm';
import Modal from '@material-ui/core/Modal';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";
import { addExpense,getAllexpenses,requestExpense,requestExpenseReset,updateExpense,deleteExpense,clearMsg } from "../../actions/expenseAction";
import ExpenseTable from './ExpenseTable';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';


const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(4),
        display: 'flex'
    },
    formControl: {
        minWidth: 225,
        maxWidth: 300,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    },
    error: {
        color: "red"
    },
    box: {
        width: "50%",
        display: "inherit",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fafafa",
        borderRadius: "8px",
        justifyContent: 'space-around',
        boxShadow: "0px 1px 1px -1px"
    },
});
class Expense extends Component {
    constructor(props){
        super(props);
            this.state = {
                handleExpenseModelOpen: false,
                handleExpenseModelClose: false,
                expenseModel: false,
                editFlag: false,
                editExpenseModel: false,
                deleteFlag: false,
                invoiceNumber : "",
                invoiceDate : "",
                fromDate : "",
                toDate : "",
                expenseName : "",
                vendorName : "",
                description : "",
                GST : false,
                totalInvoiceAmount : null,
                heads : "",
                preparexportToExcel: false,
                page: 0,
                rowsPerPage: 10,
                order: -1,
                orderBy: 'createdAt',
                selectedRowEdit: null,
                selectedRow: null,
                successMsg:null,
                errorMsg:null,
                snackOpen: false,
                warnings: {
                    expenseFromDateErr: "",
                    expenseToDateErr: ""
                  }
            };
            this.addExpense = this.addExpense.bind(this);
            this.updateExpense = this.updateExpense.bind(this);
    }
           
    componentDidMount() {
        this.props.changeHeaderName("Expenses");
        this.fetchFilter();
        this.setState({ errorMsg: null });
		this.setState({ successMsg: null });
        // this.getPgRecords();
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('this.props',this.props)
		if ((prevState.successMsg === null && this.state.successMsg) ||
			(prevState.errorMsg === null && this.state.errorMsg)) {
			this.setState({ snackOpen: true });
		}
		if (prevState.successMsg !== this.state.successMsg) {
			this.fetchFilter();
		}
	}

    
	handleSnackClose = () => {
		// this.props.clearMsg();
		this.setState({
			snackOpen: false,
			errorMsg: null,
			successMsg: null
		});
		this.fetchFilter();
	}

    fetchFilter = async () => {
        let flag = true;
        let warnings= {
            expenseFromDateErr: "",
            expenseToDateErr: ""
          }     
        if(!this.state.fromDate && this.state.toDate){
            // console.log('---------',this.state.fromDate)
            flag = false
            // console.log('flag',flag)
            warnings.expenseFromDateErr = "Required Expense start Date";
        }else if(this.state.fromDate && !this.state.toDate){
            flag = false
            warnings.expenseToDateErr = "Required Expense end Date";
        }else if (new Date(this.state.fromDate) > new Date(this.state.toDate)) {
            flag = false
            warnings.expenseFromDateErr = "Expense start date should be less than Expense end date";
            // console.log('flag222',flag)
          } else{
            flag = true
          } 
          this.setState({ warnings });
          if(flag){
            this.props.requestExpense();
            this.setState({ warningText: "" });
        let payload = {
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            invoiceNumber : this.state.invoiceNumber.trim(),
            fromDate : this.state.fromDate,
            toDate : this.state.toDate,
            expenseName : this.state.expenseName.trim(),
            vendorName : this.state.vendorName.trim(),
            description : this.state.description.trim(),
            GST : this.state.GST,
            totalInvoiceAmount : this.state.totalInvoiceAmount,
            expenseHeads : this.state.heads,
            exportToExcel: 0, 
        } 
        await this.props.getAllexpenses(payload);
        this.setState({ preparexportToExcel: false });
        this.props.requestExpenseReset();
    }
    };


    fetchExpenseExcel = async () => {
        this.props.requestExpense();
        let payload = {
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            invoiceNumber : this.state.invoiceNumber.trim(),
            fromDate : this.state.fromDate,
            toDate : this.state.toDate,
            expenseName : this.state.expenseName.trim(),
            description : this.state.description.trim(),
            totalInvoiceAmount : this.state.totalInvoiceAmount,
            expenseHeads : this.state.heads,
            exportToExcel: 1
        }
        await this.props.getAllexpenses(payload);
        this.setState({ preparexportToExcel: true });
        this.props.requestExpenseReset();
    };

    handleExpenseModelOpen = () => { this.setState({ expenseModel: true, editFlag: false }); };

    handleExpenseModelClose = () => { this.setState({ expenseModel: false, editFlag: false }); };

    handleSettlementModalClose = () => {this.setState({ expenseModel: false, editExpenseModel: false, editFlag: false }); };

    setPage = (page) => {this.setState({ page }, () => this.fetchFilter()); };

    setRowsPerPage = (rowsPerPage) => {this.setState({ rowsPerPage }, () => this.fetchFilter());};

    handleDeleteOpen = (e,row) => {
        this.setState({ deleteFlag: true, selectedRow: row }); };

    handleDeleteClose = () => {this.setState({ deleteFlag: false, selectedRow: null, expenseModel: false, editFlag: false, selectedRowEdit: null }); };

    resetFilter = async () => {
        this.setState({
           invoiceNumber : "",
           fromDate : "",
           toDate : "",
           expenseName : "",
           vendorName : "",
           GST : false,
           totalInvoiceAmount : "",
           heads : "",
           warnings: {
            expenseFromDateErr: "",
            expenseToDateErr: ""
          }  
        }, () => {
            this.fetchFilter();
        });
    };

    
    handleFilterChange = (event) => { 
        this.setState({ [event.target.name]: event.target.value });
    };

    handleEditOpen = (e,editRow) => { 
        this.setState({ editFlag: true, selectedRowEdit: editRow, expenseModel: true }); 
    };

    addExpense = async (addData) => {
        await this.props.addExpense(addData);
        this.fetchFilter();
        this.setState({ expenseModel: false, editFlag: false, selectedRowEdit: null, deleteFlag: false, selectedRow: null });
        this.setState({ successMsg: "Expense added successfully" })
        this.setState({ snackOpen: true });
    };

    updateExpense = async (editData) => {
        await this.props.updateExpense(editData);
        this.fetchFilter();
        this.setState({ expenseModel: false, editFlag: false, selectedRowEdit: null, deleteFlag: false, selectedRow: null });
        this.setState({ successMsg: "Expense updated successfully" })
        this.setState({ snackOpen: true });
    };

    handleDeleteExpense = async() => {
        let payload={
            expenseId: this.state.selectedRow._id
        }
        await this.props.deleteExpense(payload)
        this.fetchFilter();
        this.setState({ deleteFlag: false, selectedRow: null});
        this.setState({ successMsg: "Expense deleted successfully" })
        this.setState({ snackOpen: true }); 
    };

    handleDateChange = (event) =>{
        this.setState({
            [event.target.name] : event.target.value
        })
    }
 
render() {
// console.log('----------->>>',this.props)
// console.log('this.state',this.state)

const { classes } = this.props;

let muiAlert = null;

		if (this.state.successMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.successMsg}
			</MuiAlert>
		} else if (this.state.errorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.errorMsg}
			</MuiAlert>
		}

return (
            <Container className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container className={classes.container} style={{ paddingBottom: '0px' }}>
            <Grid item xs={2} style={{marginLeft:"auto"}}>
                 <Button type="submit" fullWidth variant="contained" margin="normal" color="primary" onClick={this.handleExpenseModelOpen} >
                     ADD NEW
                 </Button>
             </Grid>
                {/* </Grid> */}
            <ExpansionPanel style={{marginTop: "30px"}}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                <Typography className={classes.heading}>Search / Filter Report</Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                                <Grid container spacing={3}>    
                                    <Grid item xs={3}>
                                        <TextField fullWidth margin="dense" name="invoiceNumber" label="Invoice Number" value={this.state.invoiceNumber} variant="outlined" onChange={this.handleFilterChange} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField fullWidth margin="dense" name="expenseName" label="Name" value={this.state.expenseName} variant="outlined" onChange={this.handleFilterChange} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControl fullWidth margin="dense" variant="outlined">
                                                <InputLabel id="demo-simple-select-standard-label">Heads</InputLabel>
                                                <Select labelId="demo-simple-select-standard-label" name="heads" id="demo-simple-select-standard" value={this.state.heads} onChange={this.handleFilterChange} label="source"  >
                                                    <MenuItem value="Shopper Acquisition cost" style={{ minHeight: "28px", height: "28px" }}>Shopper Acquisition cost</MenuItem>
                                                    <MenuItem value="Shopper Retention Cost" style={{ minHeight: "28px", height: "28px" }}>Shopper Retention Cost</MenuItem>
                                                    <MenuItem value="Merchant Acquisition Cost" style={{ minHeight: "28px", height: "28px" }}>Merchant Acquisition Cost</MenuItem>
                                                    <MenuItem value="Merchant Retention Cost" style={{ minHeight: "28px", height: "28px" }}>Merchant Retention Cost</MenuItem>
                                                    <MenuItem value="CSR" style={{ minHeight: "28px", height: "28px" }}>CSR</MenuItem>
                                                    <MenuItem value="Branding" style={{ minHeight: "28px", height: "28px" }}>Branding</MenuItem>
                                                </Select>
                                        </FormControl>
                                    </Grid>  

                                    <Grid item xs={3}>
                                        <TextField fullWidth margin="dense" name="totalInvoiceAmount" label="Total Invoice Amount" value={this.state.totalInvoiceAmount} variant="outlined" onChange={this.handleFilterChange} />
                                    </Grid>

                                    <Grid item xs={3}>
                                    <Typography style={{marginLeft: "20px"}}>Expense Start Date</Typography>
                                    <TextField fullWidth margin="dense" type="date" name="fromDate" value={this.state.fromDate} variant="outlined" onChange={this.handleDateChange}  />
                  {this.state.warnings.expenseFromDateErr && <Typography gutterBottom style={{ color: "red" }}>{this.state.warnings.expenseFromDateErr} </Typography>}
                                    </Grid>

                                    <Grid item xs={3}>
                                    <Typography style={{marginLeft: "20px"}}>Expense End Date</Typography>
                                    <TextField fullWidth margin="dense" type="date" name="toDate" value={this.state.toDate} variant="outlined" onChange={this.handleDateChange}  />
                  {this.state.warnings.expenseToDateErr && <Typography gutterBottom style={{ color: "red" }}>{this.state.warnings.expenseToDateErr} </Typography>}
                                    </Grid>
                                  
                                    <Grid item xs={9}></Grid>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={2}>
                                        <Button type="submit" fullWidth variant="contained" margin="normal" onClick={this.resetFilter} >
                                            Reset
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button type="submit" fullWidth variant="contained" margin="normal" color="primary"  onClick={
                                                ()=>this.setPage(0)
                                            }>
                                            Search
                                        </Button>
                                    </Grid> 
                                </Grid>
                            </ExpansionPanelDetails>                  
        </ExpansionPanel>
    </Container>

    <Container className={classes.container}>
                        {this.props && this.props.expenseList ? 
                                <ExpenseTable
                                data={this.props.expenseList.expenseList}
                                totalCount={this.props.expenseList.total}
                                exportToExcel={this.props.expenseList.exportToExcel}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                fetchExpenseExcel={this.fetchExpenseExcel}
                                handleExpenseModelOpen={this.handleExpenseModelOpen}
                                handleExpenseModelClose={this.handleExpenseModelClose}    
                                handleDeleteClose={this.handleDeleteClose}
                                handleEditOpen={(e,editRow)=>this.handleEditOpen(e,editRow)}
                                handleDeleteOpen={this.handleDeleteOpen}
                                deleteFlag={this.state.deleteFlag}
                                handleDeleteExpense={this.handleDeleteExpense}
                                selectedRowEdit={this.state.selectedRowEdit}
                                showDownloadIcon={this.state.preparexportToExcel}
                                />
                            : 
                              <div>
                            No table data available
                             </div>
                        }
                    </Container>

                    <Modal 
                        open={this.state.expenseModel}
                        className={classes.modal}
                        onClose={this.handleExpenseModelClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    > 
                        <AddExpenseForm 
                            onClose={this.handleExpenseModelClose}
                            open={this.state.expenseModel}
                            edit={this.state.editFlag}
                            expenseData={this.props.expenseList.expenseList}
                            addExpense={(e,addData)=>this.addExpense(e,addData)}
                            updateExpense={(e,editData)=>this.updateExpense(e,editData)}
                            selectedRowEdit={this.state.selectedRowEdit}
                        />
                    </Modal>

                    <Snackbar
                        anchorOrigin={{vertical: 'top',horizontal: 'center'}}
                        open={this.state.snackOpen}
                        autoHideDuration={2000}
                        onClose={this.handleSnackClose}
                        ContentProps={{'aria-describedby': 'message-id'}}>
                            {muiAlert}
                    </Snackbar>
                    <Backdrop className={classes.backdrop} open={this.props.expenseList.isFetching}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
   
</Container>

                    
     );
   }
}

const mapStateToProps = state => {
    return {
        expenseList : state.expenseList.expenseList,
        env: state.auth.env
    }
};

export default connect(mapStateToProps, { changeHeaderName,addExpense,getAllexpenses,requestExpense,requestExpenseReset,updateExpense,deleteExpense})(withStyles(styles)(Expense));


