import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';
import MessageIcon from '@material-ui/icons/Message';

const headCells = [
    { id: 'campaignId', disablePadding: false, label: 'Campaign ID' },
    { id: 'campaignName', disablePadding: false, label: 'Campaign Name' },
    { id: 'sponsoredBy', disablePadding: false, label: 'Sponsored By' },
    { id: 'category', disablePadding: false, label: 'Category' },
    // { id: 'businessId', disablePadding: false, label: 'Business ID' },
    // { id: 'businessName', disablePadding: false, label: 'Business Name' },
    { id: 'duration', disablePadding: false, label: 'Duration' },
    { id: 'noOfCouponsHonoured', disablePadding: false, label: 'No. Of Coupons Honoured' },
    { id: 'totalAmtOfHounouredCoupons', disablePadding: false, label: 'Total Amt. Of Hounoured Coupons (\u20B9)' },
    { id: 'dueStatus', disablePadding: false, label: 'Due Status' },
    { id: 'uploadedImage', disablePadding: false, label: 'Uploaded Image' },
    { id: 'notes', disablePadding: false, label: 'Notes' },
];

function EnhancedTableHead(props) {
    const {classes} = props;

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        className={classes.tableCellSmall}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
            Business Coupon Settlement Report ({props.total})
            </Typography>

            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"couponsettlementdata.csv"}
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                    : <Button
                        variant="contained"
                        margin="normal"
                        style={{ color: "white", fontFamily: "bold" }}
                        color="primary"
                        onClick={props.fetchCouponSettlementWithExcelFile}
                    >
                        Export
        </Button>
                }
            </Typography>
        </Toolbar>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        minWidth: 102,
    },
    tableCellSmall: {
        fontSize: "13px",
        padding: "6px 0px 6px 16px",
        width:'100px',
        textAlign: 'center',
    },
    img:{
        // paddingRight: "2px",
        paddingBottom:"15px",
        textAlign: 'center',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        fontSize: "13px",
    },
    tableRow: {
        cursor: 'pointer'
    },
    deleteBtn: {
        cursor: 'pointer',
        color: 'grey',
        marginTop: "7px"
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    
    tableCellSmall1: {
        fontSize: "13px",
        
        width:'150px'
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    let headers = [
        { key: 'campId', label: 'Campaign ID' },
        { key: 'campName', label: 'Campaign Name' },
        { key: 'sponsoredBy', label: 'Sponsored By' },
        { key: 'category', label: 'Category' },
        { key: 'businessList[0].businessId', label: 'Business ID' },
        { key: 'businessList[0].businessName', label: 'Business Name' },
        // { key: 'business.businessId', label: 'Business ID' },
        // { key: 'business.businessName', label: 'Business Name' },
        { key: 'settlementDuration', label: 'Duration' },
        { key: 'totalHonouredCoupons', label: 'No. Of Coupons Honoured' },
        { key: 'totalAmountOfHonouredCoupons', label: 'Total Amt. Of Hounoured Coupons (\u20B9)' },
        { key: 'status', label: 'Due Status' },
        { key: 'settlementdate', label: 'Settlement Date' },
        { key: 'attachment', label: 'Uploaded Image' }
    ];

    const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.fullData.total - props.page * props.rowsPerPage);

    // console.log("props in campaignsCouponSettlementbusinesstable----->",props)
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar 
                headers={headers} 
                total={props.fullData.total}
                data={props.exportToexcel}
                showDownloadIcon={props.showDownloadIcon}
                fetchCouponSettlementWithExcelFile={props.fetchCouponSettlementWithExcelFile}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            rowCount={props.fullData.total}
                        />
                        <TableBody>
                            {
                                props.data && props.data.map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            tabIndex={-1}
                                            key={index+row._id+index}
                                            // key={index}
                                        >
                                            <TableCell align="left"  className={classes.tableCellSmall} style={{width: '200x'}}>
                                                {row.campaignId !== undefined ? row.campaignId.campaignId : "NA"}
                                                {/* {row.campaign && row.campaign.length > 0 && row.campaign!=undefined ? row.campaign[0].campaignId : "NA"} */}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row"  className={classes.tableCellSmall} style={{width: '200x'}}>
                                                {row.campaignId !== undefined ? row.campaignId.campaignName : "NA"}
                                                {/* {row.campaign && row.campaign.length > 0 && row.campaign!=undefined ? row.campaign[0].campaignName : "NA"} */}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} style={{width: '200x'}}>{row.campaignId!==undefined ? row.campaignId.sponsoredBy : "Xirify"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} style={{width: '200x'}}>{row.category}</TableCell>
                                            {/* <TableCell align="left"  className={classes.tableCellSmall} style={{width: '200x'}}>
                                                {row.businessId.businessId} */}
                                                {/* {row.business && row.business!=undefined ? row.business.businessId : "NA"} */}
                                            {/* </TableCell> */}
                                            {/* <TableCell align="left" className={classes.tableCellSmall} style={{width: '200x'}}>
                                                {row.businessId.businessName} */}
                                                {/* {row.business && row.business!=undefined ? row.business.businessName : "NA"} */}
                                            {/* </TableCell> */}
                                            <TableCell align="left" className={classes.tableCellSmall} style={{width: '200x'}}>
                                                {/* {moment.utc(row.rangeStartDate).format("DD MMM ") +"-" +moment(row.rangeEndDate).format(" DD MMM YYYY")} */}
                                                {moment.utc(row.rangeStartDate).format("MMM-YYYY ")}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} style={{width: '200x'}}>{row.totalHonouredCoupons}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} style={{width: '200x'}}>{row.totalAmountOfHonouredCoupons}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} style={{width: '200x'}}>
                                                {row.dueStatus ? <Typography style ={{color:"green"}}>
                                                    Settled on {row.settlementDate ? moment(row.settlementDate).format("DD/MM/YYYY"):"NA"}
                                                    </Typography> : <Typography style ={{color:"red"}}>Not Settled</Typography>}

                                                                    {row.dueStatus ? null
                                                                        : 
                                                                        <Button
                                                                            variant="contained"
                                                                            margin="normal"
                                                                            color="primary"
                                                                            onClick={() => props.openSettleModal(row)}
                                                                        >
                                                                            SETTLE
                                                                        </Button>}
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableCellSmall1} style={{width: '250x'}}>
                                                {row.attachment && row.attachment!==null && row.attachment.length !== 0 && row.attachment!=='' && row.attachment!==undefined  && typeof(row.attachment) !== "string"  && row.attachment.length>0 ? 
                                                    (
                                                        row.attachment.map((photo)=>{
                                                            if(photo.path){
                                                                return(
                                                                    <a 
                                                                    href={photo.path} 
                                                                    target="_blank" rel="noopener noreferrer">
                                                                    <img 
                                                                        src={photo.path}
                                                                        alt="Coupon Settlement Attachment" width="68" height="50" className={classes.img}
                                                                    />
                                                                </a>
                                                                )
                                                            }else{
                                                                return(
                                                                    <span>NA</span>
                                                                )
                                                            }
                                                        })
                                                    )
                                                    : "NA"
                                                }
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} style={{width: '200x'}}>
                                                <MessageIcon 
                                                    margin="normal"
                                                    onClick={() => props.openNotesModal(row)}
                                                />
                                                { row.settlementNotes && row.settlementNotes.length ? row.settlementNotes.length : "0" }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={props.fullData.total}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}