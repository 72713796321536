import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { useEffect, useRef } from 'react'

const useStyles = makeStyles(theme => ({
    container: {
        bottom: 0
    },
    bubbleContainer: {
        width: "100%",
        display: "grid"
    },
    bubble: {
        border: "0.5px solid #FAFAFA",
        borderRadius: "10px",
        margin: "5px",
        padding: "10px",
        display: "inline-block",
        background:"#FAFAFA"
    }
}));

const SupportNoteChatLayout = (props) => {
    const classes = useStyles();

    // for (let index = 0; index < props.data.length; index++) {
    //     if (props.data[index].userType === "admin") {
    //         props.data[index].direction = "";
    //     } 
    // }

    const messageRef = useRef();
    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest'
                })
        }
    })

    // function titleCase(str) {
    //     return str
    //         .split(' ')
    //         .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    //         .join(' ');
    // }

    const chatBubbles = props.data.map((obj, i = 0) => (
        <div className={`${classes.bubbleContainer} ${obj.direction}`} key={i} ref={messageRef}>
            <div key={i++} className={classes.bubble} >
                { obj.text && obj.text!=null && obj.text!=undefined ? obj.text.split("\n").map((i,key) => {
                    return <div key={key} >{i}</div>;
                    }) : null
                }
            </div>
            
                    <div style={{ display: "flex" }}>
                        <Typography variant="caption" style={{ marginLeft: "15em",marginRight: "7px" }} gutterBottom>
                            {obj.supportPersonName}
                        </Typography>
                        <Typography variant="caption" style={{ marginRight: "7px" }} display="block" gutterBottom>
                            {moment(obj.time).format('DD MMM YYYY hh:mm A')}
                        </Typography>
                    </div>
                    {/* // <div style={{ display: "flex", flexFlow: "row-reverse" }}>
                    //     <Typography variant="caption" style={{ marginRight: "7px" }} display="block" gutterBottom>
                    //         {obj.userType == "admin" ? obj.role ? "("+titleCase(obj.role)+")" : "" : "(Business)"} {obj.supportPersonName}
                    //     </Typography>
                    //     <Typography variant="caption" style={{ marginRight: "7px" }} display="block" gutterBottom>
                    //         {moment(obj.time).format('DD MMM YYYY hh:mm A')}
                    //     </Typography>
                    // </div>  */}

        </div>
    ));
    return <div className={classes.container}>{chatBubbles}</div>;
};

export default SupportNoteChatLayout;

