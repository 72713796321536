import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { useEffect, useRef } from 'react'

const useStyles = makeStyles(theme => ({
    container: {
        bottom: 0
    },
    bubbleContainer: {
        width: "100%",
        display: "grid"
    },
    bubble: {
        border: "0.5px solid black",
        borderRadius: "10px",
        margin: "5px",
        padding: "10px",
        display: "inline-block"
    }
}));

const ChatLayout = (props) => {
    const classes = useStyles();

    for (let index = 0; index < props.data.length; index++) {
        if (props.data[index].userType === "consumer") {
            props.data[index].direction = "left";
        } else {
            props.data[index].direction = "right";
        }
    }

    const messageRef = useRef();
    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest'
                })
        }
    })

    function titleCase(str) {
        return str
            .split(' ')
            .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }

    const chatBubbles = props.data.map((obj, i = 0) => (
        <div className={`${classes.bubbleContainer} ${obj.direction}`} key={i} ref={messageRef}>
            <div key={i++} className={classes.bubble}>
                {
                    obj.attachments.map((atchmt, index) => (
                        <a href={atchmt} key={index} target="_blank" rel="noopener noreferrer">
                            <img src={atchmt} alt="Service details" width="400" height="250" />
                        </a>

                    ))
                }
                {/* <div className={classes.button}>{obj.text}</div> */}
                {/* <div className={classes.button} multiline rows={2}>{"obj.text\nabcd"}</div> */}
                { obj.text && obj.text!==null && obj.text!==undefined ? obj.text.split("\n").map((i,key) => {
                    return <div key={key}>{i}</div>;
                    }) : null
                }
            </div>
            {
                obj.direction === "left" ?
                    <div style={{ display: "flex" }}>
                        <Typography variant="caption" style={{ marginRight: "7px" }} gutterBottom>
                            {obj.senderName} (Consumer)
                        </Typography>
                        <Typography variant="caption" style={{ marginRight: "7px" }} display="block" gutterBottom>
                            {moment(obj.time).format('DD MMM YYYY hh:mm A')}
                        </Typography>
                    </div> :
                    <div style={{ display: "flex", flexFlow: "row-reverse" }}>
                        <Typography variant="caption" style={{ marginRight: "7px" }} display="block" gutterBottom>
                            {obj.userType === "admin" ? obj.role ? "("+titleCase(obj.role)+")" : "" : "(Business)"} {obj.senderName}
                        </Typography>
                        <Typography variant="caption" style={{ marginRight: "7px" }} display="block" gutterBottom>
                            {moment(obj.time).format('DD MMM YYYY hh:mm A')}
                        </Typography>
                    </div>
            }

        </div>
    ));
    return <div className={classes.container}>{chatBubbles}</div>;
};

export default ChatLayout;
