import axios from "axios";
import request from 'superagent';

export const getBusinessTagList = async () => {
    try {
        const response = await axios.get("/api/businessTag");
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
};


export const deletebusinessTag = async (payload) => {
    // console.log("payload", payload);
    try {
        const response = await axios.delete(`/api/businessTag/${payload}`);
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
}

export const updatebusinessTag = async (payload) => {
    // console.log("payload", payload);
    try {
        const response = await axios.patch(`/api/businessTag/${payload._id}`, payload);
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
}

export const createBusinessTag = async (payload) => {
    // console.log("payload", payload);
    try {
        const response = await axios.post(`/api/businessTag`, payload);
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
}
