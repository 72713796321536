import {
  REQUEST_BUSINESS_ITEMTAG_LOADER,
  REQUEST_BUSINESS_ITEMTAG_LOADER_FALSE,
  GET_BUSINESS_ITEMTAG_REPORT,
  GET_BUSINESS_ITEMTAG_REPORT_OF_EXPORT,
} from "../actions/types";

const INITIAL_STATE = {
 
  isFetching: false,
  businessItemTagList : [],
  businessItemTagListCount : 0,
  exportToExcel : [],
  page: 0,
  skip: 0,
  limit: 10,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_BUSINESS_ITEMTAG_LOADER:
      return { ...state, isFetching: action.payload };
    case REQUEST_BUSINESS_ITEMTAG_LOADER_FALSE:
      return { ...state, isFetching: action.payload };
    case GET_BUSINESS_ITEMTAG_REPORT:
      return {
        ...state,
        businessItemTagList: action.payload,
       
      };
    case GET_BUSINESS_ITEMTAG_REPORT_OF_EXPORT:
      return {
        ...state,
        exportToExcel: action.payload,
      };

    default:
      return state;
  }
}
