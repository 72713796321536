import{
    DELETE_EXPENSE_ERROR_MESSAGE,
    DELETE_EXPENSE_SUCCESS_MESAGE,
    GET_EXPENSE,
    REQUEST_EXPENSE,
    SET_EXPENSE_ERROR_MESSAGE,
    SET_EXPENSE_SUCCESS_MESSAGE,
    UPDATE_EXPENSE_ERROR_MESSAGE,
    UPDATE_EXPENSE_SUCCESS_MESSAGE,

}from '../actions/types';

const INITIAL_STATE = {
    page: 0,
    skip: 0,
    limit: 10,
    sucessMsg: null,
    errorMsg: null,
    ops: "",
    isFetching: false,
    exportToExcel: [],
    expenseList:[],
    deleteExpense: [],
    isFetching: false
}

export default function (state = INITIAL_STATE, action) {
    switch(action.type){
        case GET_EXPENSE:
            return{
                ...state,
                expenseList: action.payload
            }
        case REQUEST_EXPENSE:
            return { ...state, isFetching: action.payload 
            }
        case SET_EXPENSE_ERROR_MESSAGE:
            return{
                ...state, errorMsg: action.payload.msg,isFetching: false
            }
         case SET_EXPENSE_SUCCESS_MESSAGE:
             return{
                 ...state, sucessMsg: action.payload.msg,isFetching: false
             }
             case UPDATE_EXPENSE_ERROR_MESSAGE:
         return{
             ...state, errorMsg: action.payload.msg,isFetching: false
         }
         case UPDATE_EXPENSE_SUCCESS_MESSAGE:
         return{
             ...state, sucessMsg: action.payload.msg,isFetching: false
         }
         case DELETE_EXPENSE_SUCCESS_MESAGE:
            return{
                ...state, sucessMsg: action.payload,isFetching: false
            }
        case DELETE_EXPENSE_ERROR_MESSAGE:
            return{
                ...state, errorMsg: action.payload,isFetching: false
            }
        default:
            return state;
    }
}