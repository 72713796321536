import * as actions from "../actions/types";

const INITIAL_STATE = {
  page: 0,
  skip: 0,
  limit: 10,
  campaignListData: [],
  couponSettlementBusinessListData: [],
  couponConsumerBusinessCampaignDetails: {},
  campaignDetails: {},
  sucessMsg: null,
  errorMsg: null,
  ops: "",
  isFetching: false,
  campaignExcelRecords: [],
  couponsettlementExcelRecords: {},
  campaignBusinessExcelRecords: {},
  campaignBusinessCustomerCouponExcelRecords: [],
  // campaignBusinessCustomerCouponExcelRecords: {},
  setUpdateCampainStatus: "",
  addCouponSettlementMsg: "",
  setCouponSettlementNotesMsg: "",
  setPromocode: {},
  couponSettlementRecords: {},
  couponSettlementNotesById: [],
  getCouponSummaryReport:{},
  getCouponSummaryReportExcel:[],
  shoppersListCampaign: [],
  shoppersCampaign: [],
};
  
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
      case actions.REQUEST_CAMPAIGN:
        return { ...state, isFetching: action.payload };
      case actions.REQUEST_SHOPPERS:
        return { ...state, isFetching: action.payload };
      case actions.REQUEST_COUPON_SETTLEMENT:
        return { ...state, isFetching: action.payload };
      case actions.GET_ALL_CAMPAIGN_LIST:
        return { ...state, campaignListData: action.payload, isFetching: true };
      case actions.GET_ALL_CAMPAIGN_LIST_COMPLETED:
        return { ...state, campaignListData: action.payload, isFetching: false };
      case actions.GET_SHOPPERS_LIST:
        return { ...state, shoppersListData: action.payload, isFetching: false };
      
      case actions.GET_ALL_COUPON_SETTLEMENT_BUSINESS_LIST:
        return { ...state, couponSettlementBusinessListData: action.payload, isFetching: true };

      case actions.GET_ALL_COUPON_SETTLEMENT_BUSINESS_LIST_COMPLETED:
        return { ...state, couponSettlementBusinessListData: action.payload, isFetching: false };
      
      case actions.CAMPAIGN_DOWNLOAD_EXCEL:
        return {...state, campaignExcelRecords: action.payload, isFetching: true};
  
      case actions.CAMPAIGN_DOWNLOAD_EXCEL_COMPLETED:
        return {...state, campaignExcelRecords: action.payload, isFetching: false};

      case actions.COUPONSETTLEMENT_DOWNLOAD_EXCEL:
        return {...state, couponsettlementExcelRecords: action.payload, isFetching: true};
    
      case actions.COUPONSETTLEMENT_DOWNLOAD_EXCEL_COMPLETED:
        return {...state, couponsettlementExcelRecords: action.payload, isFetching: false};

      case actions.CAMPAIGN_BUSINESS_DOWNLOAD_EXCEL:
        return {...state, campaignBusinessExcelRecords: action.payload, isFetching: true};

      case actions.CAMPAIGN_BUSINESS_DOWNLOAD_EXCEL_COMPLETED:
        return {...state, campaignBusinessExcelRecords: action.payload, isFetching: false};
        
      case actions.CAMPAIGN_BUSINESS_CUSTOMER_COUPON_DOWNLOAD_EXCEL:
        return {...state, campaignBusinessCustomerCouponExcelRecords: action.payload, isFetching: true};
        
      case actions.CAMPAIGN_BUSINESS_CUSTOMER_COUPON_DOWNLOAD_EXCEL_COMPLETED:
        return {...state, campaignBusinessCustomerCouponExcelRecords: action.payload, isFetching: false};
       
      case actions.SET_CAMPAIGN_UPDATE:
        return { ...state,isFetching: false, setUpdateCampainStatus: action.payload,sucessMsg: action.payload.msg,
          ops: action.payload.ops,
          };
      
      case actions.SET_PROMOCODE:
        return { ...state, setPromocode: action.payload };

        case actions.SET_ALL_CAMPAIGN_LIST_SUCCESS_MESSAGE:
          return {
              ...state,
              sucessMsg: action.payload.msg,
              ops: action.payload.ops,
              // isFetching: false
          };
      case actions.SET_ALL_CAMPAIGN_LIST_ERROR_MESSAGE:
          return {
              ...state,
              errorMsg: action.payload.msg,
              ops: action.payload.ops,
              // isFetching: false
          };
      case actions.SET_ALL_COUPON_SETTLEMENT_BUSINESS_LIST_SUCCESS_MESSAGE:
        return {
            ...state,
            sucessMsg: action.payload.msg,
            ops: action.payload.ops,
            // isFetching: false
        };
      case actions.SET_ALL_COUPON_SETTLEMENT_BUSINESS_LIST_ERROR_MESSAGE:
        return {
            ...state,
            errorMsg: action.payload.msg,
            ops: action.payload.ops,
            // isFetching: false
        };
      case actions.SET_ALL_COUPON_SETTLEMENT_LIST_SUCCESS_MESSAGE:
        return {
            ...state,
            sucessMsg: action.payload.msg,
            ops: action.payload.ops,
            // isFetching: false
        };
      case actions.SET_ALL_COUPON_SETTLEMENT_LIST_ERROR_MESSAGE:
        return {
            ...state,
            errorMsg: action.payload.msg,
            ops: action.payload.ops,
            // isFetching: false
        };
      // case actions.SET_CAMPAIGN_UPDATE:
      //   return {
      //     ...state,
      //     sucessMsg: action.payload.msg,
      //     ops: action.payload.ops,
      //     isFetching: false,
      //   };
      case actions.SET_CAMPAIGN_DETAILS:
        return {
            ...state,
            campaignDetails: action.payload,
            isFetching: false
        };
      case actions.SET_COUPON_CONSUMER_BUSINESS_CAMPAIGN_DETAILS:
        return { ...state,
          couponConsumerBusinessCampaignDetails: action.payload,
          isFetching: false 
        };
      case actions.GET_COUPON_SETTLEMENT_RECORDS:
        return {
          ...state,
          couponSettlementRecords: action.payload,
        };
      case actions.GET_COUPON_SETTLEMENT_NOTES:
        return {
          ...state,
          couponSettlementNotesById: action.payload,
        };
      case actions.SET_COUPON_SETTLEMENT:
        return {
          ...state,
          addCouponSettlementMsg: action.payload,
        };
      case actions.SET_COUPON_SETTLEMENT_NOTES:
        return {
          ...state,
          setCouponSettlementNotesMsg: action.payload,
        };
      case actions.GET_COUPON_SUMMARY_REPORT:
        return {
          ...state,
          getCouponSummaryReport: action.payload, isFetching: false
        };
      case actions.GET_COUPON_SUMMARY_REPORT_EXCEL:
        return {
          ...state,
          getCouponSummaryReportExcel: action.payload.data, isFetching: false
        };
      default:
        return state;
    }
}
  