import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { getBusinessListAwaitingApproval,searchAwaitingApproval,getUsersRecords,getRegionsRecords, updateBusinessWithArea, clearMsg ,requestApprovedBusinessRecords, requestApprovedBusinessRecordsFalse,addPendingBusinessTag} from "../../../actions/businessActions";
import { changeHeaderName, showPendingBusinessDetails } from "../../../actions/dashboardActions";
import { getAreasByRegions } from "../../../actions/areaActions";
import { connect } from "react-redux";
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
// import SearchIcon from '@material-ui/icons/Search';
// import InputBase from '@material-ui/core/InputBase';
// import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import AssignAreaForm from './AssignAreaForm';
import Modal from '@material-ui/core/Modal';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from "@material-ui/icons/Add";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { getBusinessTagList} from "../../../actions/businessTagAction";


const styles = theme => ({
  content: {
    width: '100%',
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  table: {
    minWidth: 650,
  },
  head: {
    fontWeight: 700
  },
  tableRow: {
    cursor: 'pointer'
  },
  searchPaper: {
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '25px',
  },
  clearButton: {
    marginLeft: '25px'
  },
  assignAreaModalContainer: {
    width: '40%',
    height: '95%',
    backgroundColor: theme.palette.background.paper,
    padding: '0px 0px',
  },
  filter :{
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: "#fff",
    marginBottom: "50px"
  },
box: {
  width: "50%",
  display: "inherit",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fafafa",
  borderRadius: "8px",
  justifyContent: 'space-around',
  boxShadow: "0px 1px 1px -1px"
},
tableCellSmall: {
  fontSize: "12px",
  padding: "6px 0px 6px 16px"
},
button: {
  // width: "110px",
  borderRadius:"30px",
  fontSize:"12px",
  marginRight:'10px',
  height:"30px",
  marginLeft:'5px',
  marginTop:'5px'
},

 businessTagsContainer:{
  display:'flex',
  width:'max-content',
  maxWidth:'380px',
  flexWrap:'wrap',
 },
 chip: {
  margin: theme.spacing(0.5),
  borderRadius: '20px',
  backgroundColor: '#F5F5F5'
},
modalBusinessTag: {
  display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   paddingLeft:'400px',
   paddingRight:'400px',
   position:'fixed',
   
},
headerBar: {
color: "white",
backgroundColor: theme.palette.primary.main,
},
headGridTwoButton: {
float: "right",
marginTop: "2px",
},
form: {
margin: "5% 10%",
backgroundColor: theme.palette.background.paper,
},  
dialogcontent: {
margin: "10px 0px",
overflow:'visible'

},
footer: {
height: "5%",
margin: "10px 0px 10px 0px",
display: "flex",
flexDirection:"row",
alignItems: "center",
justifyContent: "space-around",
},
});

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    getContentAnchorEl: null,

};


function uD(regions){
  console.log("regions",regions)
  var myVar4 = regions.join(',');

  let payload ={
    regionList:myVar4,
    businessStatus:"AWAITING_APPROVAL"
  }
  return payload
  // this.props.getUsersRecords(payload)
}

function usersData(regions,users,webRole){
 
  let selectedUsers = [];
let selectedAccountM=[];
if(webRole.role === "regionalHead" || webRole.role === "admin"){
  regions.length >0 && regions.map((r)=>{
    users.length>0 &&  users.map((u)=>{
      u.businessDocId.length>0 && u.businessDocId !=null && u.businessDocId.map((busDocId)=>{
        if(busDocId.regionBelongsTo === r){
          selectedUsers.push(u)
        }
      })
  
      })
    })
}else if(webRole.role === "areaManager"){
  
  users.length>0 &&  users.map((u)=>{
   if( u.businessDocId.length>0 && u.businessDocId !=null){
    selectedUsers.push(u)
   }
  })

}

  selectedAccountM = Array.from(new Set(selectedUsers));
  // console.log("selectedAccountM...",selectedAccountM);
  return selectedAccountM
}
   
  
class PendingBusiness extends Component {

  constructor() {
    super();
    this.state = JSON.parse(window.localStorage.getItem('pendingBusinessDetails')) || {
      skip: 0,
      limit: 10,
      businessId : "",
      rowsPerPage: 10,
      rows: [],
      showDownloadIcon: false,
      order: -1,
      pageNumber : 0,
      regionFilter:[],
      accountManagerFilter:"",
      accountManagerFilterError:false,
      areaRoleCheck:false,
      isBusinessTagModalOpen:false,
      selectedBusinessId:"",
      selectedBusinessTags:[],
      businessTags:[],
      filteredBusinessTags:[]
    };
  }

 async  componentDidMount() {
     this.fetchBusinessWithFilter()
     this.props.changeHeaderName("Pending Approval Business");
     let areaRole = this.props.user.role
     let payload ={
       businessStatus:"AWAITING_APPROVAL"
     }
     if(areaRole == "areaManager"){
      this.setState({areaRoleCheck:true})
      await this.props.getUsersRecords(payload)
    }else {
       await this.props.getRegionsRecords();
       this.setState({areaRoleCheck:false})
     }
     this.fetchBusinessTags();
    //  console.log("....this.state.areaROleCheck",this.state.areaRoleCheck);
     window.addEventListener("beforeunload", () => localStorage.removeItem('pendingBusinessDetails'));
    //  this.props.getRegionsUsersRecords();
  }


  fetchBusinessWithFilter =async ()=>{
    if(this.state.regionFilter.length !==0){
      if(!this.state.accountManagerFilter){
        this.setState({accountManagerFilterError :true});
      
      }
   }
    var myVar4 = this.state.regionFilter.join(', ');
    let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
    let payload =  {
      order: Number(this.state.order),
      skip: this.state.pageNumber * this.state.limit,
      limit: this.state.limit,
      businessId: this.state.businessId.trim(),
      accountManagerFilter:this.state.accountManagerFilter,
      region:myVar4,
      filteredBusinessTags:filteredBusinessTagsVar
      }
       await this.props.getBusinessListAwaitingApproval(payload);
       this.setState({showDownloadIcon:false});
       this.props.requestApprovedBusinessRecordsFalse();
       localStorage.setItem("pendingBusinessDetails", JSON.stringify(this.state));
     }

     fetchBusinessTags=async()=>{
      const response=await getBusinessTagList();
      if(response){
        this.setState({businessTags:response.data})
      }else{
        this.setState({businessTags:[]})
      }
      
  }
 
     resetFilter = async ()=>{
      //  this.props.getBusinessListAwaitingApproval();
       this.setState({
         pageNumber:0,
         businessId : "",
         regionFilter:[],
         accountManagerFilter:"",
         accountManagerFilterError:false,
         filteredBusinessTags:[]
       },
       () => {
         this.fetchBusinessWithFilter();
       }
     ); 
     }


     setPage = (page) => {
      this.setState({ pageNumber: page }, () => this.fetchBusinessWithFilter());
    };

  handleListItemClick = (busObj) => {
    this.props.showPendingBusinessDetails(busObj);
    this.props.history.push("/PendingBusinessDetails");
  }


    handleChangePage = async (event, newPage) => {
      var skip;
      await this.setState({ pageNumber: newPage });
      skip = this.state.limit * newPage;
      this.setState({ skip: skip });
     await  this.fetchBusinessWithFilter();
  };

;
  handleChangeRowsPerPage = event => {
    this.setState({ pageNumber: 0 });
    this.setState({ limit: event.target.value });
    this.setState({ skip: 0 });

    this.props.getBusinessListAwaitingApproval(0, event.target.value);
  };

  handleSearchChange = (event) => {
    this.setState({ value: event.target.value });
  }

  handleFilterChange = async (event) => {
    if(event.target.name === "accountManagerFilter"){
      let accountString = event.target.value
      this.setState({accountManagerFilterError :false,
      accountManagerFilter:accountString})
    }else if(event.target.name === "regionFilter"){
      this.setState({
        regionFilter:event.target.value,
        accountManagerFilter:""
      })
      if(this.state.regionFilter.length >=0){
        console.log("inside event handler")
        await this.setState({
          regionFilter: event.target.value
        })
        let user = uD(this.state.regionFilter)
        await this.props.getUsersRecords(user)
      }
    }
    else {
      this.setState({ [event.target.name]: event.target.value, invalidTimeFlag: false });
    }
}

  handleUsersData = async (user) => {
      await this.props.getUsersRecords(uD(this.state.regionFilter))
    }

  assignAreaModalOpen = (business) => {
    this.props.getAreasByRegions({ regionids: business.regionBelongsTo._id });
    this.setState({
      assignAreaModalOpen: true,
      selectedBusiness: business
    });
  }

  assignAreaModalClose = () => {
    this.setState({
      assignAreaModalOpen: false
    })
  }

  assignArea = (props) => {
    this.props.updateBusinessWithArea(props);
    this.setState({
      assignAreaModalOpen: false,
      selectedBusiness: null
    });
  }

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    this.setState({ assignAreaModalOpen: false });
    this.setState({ sucessMsg: null });
    this.setState({ errorMsg: null });
    // this.fetchAreaManagersByQuery();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.business.sucessMsg) {
      this.setState({ sucessMsg: this.props.business.sucessMsg })
      this.setState({ snackOpen: true });
      this.setState({ assignAreaModalOpen: false });
      // this.props.getBusinessListAwaitingApproval(this.state.skip, this.state.limit);
      this.fetchBusinessWithFilter();
      this.props.clearMsg();
    } else if (this.props.business.errorMsg) {
      this.setState({ errorMsg: this.props.business.errorMsg })
      this.setState({ snackOpen: true });
      this.props.clearMsg();
    }
  }
  handleClickAddTag=(id,selectedBusinessTags)=>{
    this.setState({ isBusinessTagModalOpen:true});
    this.setState({ selectedBusinessId:id});
    if(selectedBusinessTags){
      const selectedBusinessTagsNames=selectedBusinessTags.map((eachSelectedBusinessTag)=>eachSelectedBusinessTag.name);
      this.setState({ selectedBusinessTags:selectedBusinessTagsNames});
    }
   
    
  }

  handleBusinessTagModalClose=()=>{
    this.setState({ isBusinessTagModalOpen:false});
        this.setState({ selectedBusinessTags:[]});

  }
  handleSelectedTags=(e)=>{
    this.setState({ selectedBusinessTags:e.target.value});
  }
  handleAddTagSubmit=async (id)=>{
    let finalSelectedBusinessTag=this.state.businessTags?.filter((eachBusinessTag)=>this.state.selectedBusinessTags.includes(eachBusinessTag.name));
    if(finalSelectedBusinessTag){
      let finalSelectedBusinessTagIds=finalSelectedBusinessTag?.map((eachfinalselectedBusinessTag)=>eachfinalselectedBusinessTag._id);
      this.props.addPendingBusinessTag(id,finalSelectedBusinessTagIds)
      this.setState({ isBusinessTagModalOpen:false});
    }
    
  }
  handleDeleteBusinessTag=(businessId,id,businessTags)=>{
    let finalSelectedBusinessTag=businessTags.filter((eachBusinessTag)=>eachBusinessTag._id!==id);
            let finalSelectedBusinessTagIds=finalSelectedBusinessTag.map((eachfinalselectedBusinessTags)=>eachfinalselectedBusinessTags._id);
        this.props.addPendingBusinessTag(businessId,finalSelectedBusinessTagIds)  
  }

  render() {
    const { classes } = this.props;
    // console.log("this.props",this.props)
    // let clearSearchButton = null;
    let muiAlert = null;
    let dataRegion =[];
    dataRegion = this.props.business.regionsData;
    let usersDataforAM = usersData(this.state.regionFilter,this.props.business.usersData,this.props.user)

    if (this.state.sucessMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
        {this.state.sucessMsg}
      </MuiAlert>
    } else if (this.state.errorMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
        {this.state.errorMsg}
      </MuiAlert>
    }

    return (
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container className={classes.container}>
          <Container className={classes.filter}
           style={{ paddingBottom: "0px" }}>

          <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Search / Filter</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails onKeyDown={this.enterPressed}>
              <Grid container spacing={1}>
                {/* <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="businessId"
                    label="Business Id / Name"
                    value={this.state.businessId}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                </Grid> */}
                <Grid item xs={3} >
                  <Typography variant="subtitle2" gutterBottom>
                      Business Id / Name
                    </Typography>
                    <TextField
                    fullWidth
                    margin="dense"
                    name="businessId"
                    label="Business Id / Name"
                    value={this.state.businessId}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                  </Grid>
                {
                      !this.state.areaRoleCheck ?
                    
                  <div className={classes.box}>
                  <Grid item xs={5} >
                  <Typography variant="subtitle2" gutterBottom>
                      Region
                    </Typography>
                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                            <InputLabel  id="Regions">Select Region</InputLabel>
                                            <Select
                                                labelId="Regions"
                                                multiple
                                                value={this.state.regionFilter}
                                                name="regionFilter"
                                                style ={{width: "225px"}}
                                                 onChange={this.handleFilterChange}
                                                 onSelect={this.handleUsersData}
// 
                                                input={<Input />}
                                                renderValue={(selected) =>
                                                  {
                                                    let nameArray=[];
                                                      selected.map((m)=>{
                                                      dataRegion.map((d)=>{
                                                        if(d._id === m){
                                                          if(nameArray.length=== 0)
                                                          nameArray.push(d.name);
                                                          else
                                                          nameArray.push( ", "+d.name)
                                                        }
                                                      })
                                                     
                                                    })
                                                      return nameArray}}
                                                MenuProps={MenuProps}
                                            >
                                                {dataRegion.map((region) => (
                                                    <MenuItem key={region._id} value={region._id} style={{ maxWidth: "100%", overflow:"hidden", 
                                                    wordWrap:"normal", whiteSpace:"normal" }}>
                                                        <Checkbox  checked={this.state.regionFilter.indexOf(region._id) > -1 } 
                                                        />
                                                        <ListItemText  primary={region.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            
                                        </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="subtitle2" gutterBottom> Account Manager </Typography>
                    <FormControl fullWidth margin="dense" variant="outlined">
                      {/* <InputLabel id="demo-simple-select-standard-label">Business OPEN Time</InputLabel> */}
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="fronpm run devm"
                        value={this.state.accountManagerFilter}
                        name="accountManagerFilter"
                        onChange={this.handleFilterChange}
                        // label="Business OPEN Time"
                        style={{ width: "225px" }}
                      >
                        {usersDataforAM.map((user) => {
                              return (
                                <MenuItem key={user} value={user.username} style={{ minHeight: "28px", height: "28px" }}>
                                  <Typography noWrap 
                               >{user.firstName}{" "}{user.lastName}{"("}{user.username}{")"}</Typography>
                                </MenuItem>
                              )
                        })}
                      </Select>
                      {this.state.accountManagerFilterError ? <Typography gutterBottom style={{ color: "red" }}>{"Select Account Manager"} </Typography>
                      : null
                 }
                    </FormControl>
                  </Grid>

                  </div>
                  :
                  
                  <Grid item xs={3}>
                  <Typography variant="subtitle2" gutterBottom> Account Manager </Typography>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    {/* <InputLabel id="demo-simple-select-standard-label">Business OPEN Time</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="fronpm run devm"
                      value={this.state.accountManagerFilter}
                      name="accountManagerFilter"
                      onChange={this.handleFilterChange}
                      // label="Business OPEN Time"
                      style={{ width: "225px" }}
                    >
                      {usersDataforAM.map((user) => {
                            return (
                              <MenuItem key={user} value={user.username} style={{ minHeight: "28px", height: "28px" }}>
                                <Typography noWrap 
                             >{user.firstName}{" "}{user.lastName}{"("}{user.username}{")"}</Typography>
                              </MenuItem>
                            )
                      })}
                    </Select>
                    {this.state.accountManagerFilterError ? <Typography gutterBottom style={{ color: "red" }}>{"Select Account Manager"} </Typography>
                    : null
               }
                  </FormControl>
                </Grid>
                }
                    <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom> Business Tags</Typography>
                          <FormControl variant="outlined" fullWidth className={classes.formControl} >
                              <InputLabel id="demo-mutiple-checkbox-label">Select Business Tags</InputLabel>
                              <Select
                                  labelId="demo-mutiple-checkbox-label"
                                  id="demo-mutiple-checkbox"
                                  multiple
                                  value={this.state.filteredBusinessTags}
                                  name="filteredBusinessTags"
                                  onChange={this.handleFilterChange}
                                  input={<Input />}
                                  renderValue={(selected) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                  >
                                  {this.state. businessTags.map((eachBusinessTagObj) => (
                                      <MenuItem key={eachBusinessTagObj._id} value={eachBusinessTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                          <Checkbox checked={this.state.filteredBusinessTags.indexOf(eachBusinessTagObj.name) > -1} />
                                          <ListItemText primary={eachBusinessTagObj.name} />
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                      </Grid> 

                <Grid item xs={12}></Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    onClick={this.resetFilter}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    color="primary"
                    onClick={
                      () => this.setPage(0)
                     
                    }
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
            </ExpansionPanel>
          
          </Container>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell>Business Name</TableCell>
                  <TableCell>Business Id</TableCell>
                  <TableCell>Owner Name</TableCell>
                  <TableCell>Contact Number</TableCell>
                  <TableCell>Category Type</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Pin Code</TableCell>
                  <TableCell>Region</TableCell>
                  <TableCell>Area</TableCell>
                  <TableCell>Area Code</TableCell>
                  <TableCell>Account Manager</TableCell>
                  <TableCell>Tags</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.business.busAwaitApprovalList.map((busObj, index )=> {
                  let ownerName = busObj.ownerId ?
                    busObj.ownerId.firstName + " " + busObj.ownerId.lastName : 'NOT AVAILABLE';
                  let categoryTypeName = busObj.categoryType ? busObj.categoryType.name : 'NOT AVAILABLE';
                  let categoryName = busObj.category ? busObj.category.name : 'NOT AVAILABLE';
                  // console.log("......userIsActive",busObj.userIsActive);
                  // if(busObj.userIsActive){
                  //   // accManager =(busObj.accountManagerDetails && busObj.accountManagerDetails.length>0 && busObj.accountManagerDetails!=null )? busObj.accountManagerDetails[busObj.accountManagerDetails.length-1].userName:"--"
                  //   accManager = busObj.accountManagerDetails.userName
                  // }else{
                  //   accManager = "--"
                  // }
                  let accManager = "";
                  let accManagerUsername = "";
                  
                    if( Array.isArray(busObj.accountManagerDetails) ){ 
                      if(busObj.accountManagerDetails.length === 0) {
                        accManager= "NA";
                        accManagerUsername="" }
                    }else if(busObj.accountManagerDetails && busObj.accountManagerDetails != null){ accManager = busObj.accountManagerDetails.firstName +" " + busObj.accountManagerDetails.lastName ;
                      accManagerUsername = "(" + busObj.accountManagerDetails.userName + ")" };
                 
                  return (
                    <TableRow className={classes.tableRow} hover key={index} button="true" >
                      <TableCell component="th" scope="row" onClick={(e) => this.handleListItemClick(busObj, e)}>
                        {busObj.businessName}
                      </TableCell>
                      <TableCell onClick={(e) => this.handleListItemClick(busObj, e)}>{busObj.businessId}</TableCell>
                      <TableCell onClick={(e) => this.handleListItemClick(busObj, e)}>{ownerName}</TableCell>
                      <TableCell onClick={(e) => this.handleListItemClick(busObj, e)}>{busObj.primaryContactNumber}</TableCell>
                      <TableCell onClick={(e) => this.handleListItemClick(busObj, e)}>{categoryTypeName}</TableCell>
                      <TableCell onClick={(e) => this.handleListItemClick(busObj, e)}>{categoryName}</TableCell>
                      <TableCell onClick={(e) => this.handleListItemClick(busObj, e)}>{busObj.address.pinCode}</TableCell>
                      <TableCell onClick={(e) => this.handleListItemClick(busObj, e)}>{busObj.regionBelongsTo && busObj.regionBelongsTo.name ? busObj.regionBelongsTo.name : ''}</TableCell>
                      <TableCell>
                        <Typography variant="body2" gutterBottom>
                          {busObj.areaBelongsTo && busObj.areaBelongsTo.name ? busObj.areaBelongsTo.name : "Not Assigned"}
                        </Typography>
                        {
                          this.props.user && this.props.user.role === 'regionalHead' ?
                            <EditIcon onClick={(e) => this.assignAreaModalOpen(busObj, e)} /> : null
                        }

                      </TableCell>
                      <TableCell onClick={(e) => this.handleListItemClick(busObj, e)}>{busObj.areaBelongsTo && busObj.areaBelongsTo.areaCode ? busObj.areaBelongsTo.areaCode : "--"}</TableCell>
                      <TableCell align="left" className={classes.unclickableTableCellSmall1}> {  accManager ? accManager + " " + accManagerUsername: "--"} </TableCell>
                      <TableCell align="left" className={classes.tableCellSmall}>
                          <Grid className={classes.businessTagsContainer}>
                                {busObj.businessTagIds && busObj.businessTagIds.map((eachSelectedBusinessTag)=>this.props.user && this.props.user?.role !== 'regionalHead'?<Chip
                                  className={classes.chip}
                                  label={eachSelectedBusinessTag.name}
                                  key={eachSelectedBusinessTag._id}
                                  variant="outlined"
                                  deleteIcon={<CancelIcon color="primary"/>}
                                  onDelete={() =>this.handleDeleteBusinessTag(busObj._id,eachSelectedBusinessTag._id,busObj.businessTagIds)}
                                />:<Chip
                                className={classes.chip}
                                label={eachSelectedBusinessTag.name}
                                key={eachSelectedBusinessTag._id}
                                variant="outlined"
                              />)}
                                {this.props.user && this.props.user?.role === 'regionalHead' && (busObj?.businessTagIds?.length===0 || busObj?.businessTagIds===undefined || busObj?.businessTagIds===null)?<Typography className={classes.tableCellSmall}>No Tags Assigned</Typography>:null}

                                {this.props.user && this.props.user.role !== 'regionalHead'?<Button
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  // startIcon={<AddIcon />}
                                  onClick={()=>this.handleClickAddTag(busObj._id,busObj.businessTagIds)}
                                >
                                  <AddIcon style={{fontSize:'18px'}}/>
                                <Typography style={{fontSize:'12px',}}>ADD TAG</Typography>
                                </Button>:null}
                          </Grid>
                      </TableCell>
                      </TableRow>
                  )
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={this.props.business.busAwaitApprovalListCount}
                    rowsPerPage={this.state.limit}
                    page={this.state.pageNumber}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    style={{overflow: 'unset'}}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          <Modal
            className={classes.modal}
            open={this.state.assignAreaModalOpen}
            onClose={this.assignAreaModalClose}
            closeAfterTransition>
            <Container className={classes.assignAreaModalContainer}>
              <AssignAreaForm
                onSubmit={this.assignArea}
                onClose={this.assignAreaModalClose}
                selectedBusiness={this.state.selectedBusiness}
                areas={this.props.areasByRegions} />
            </Container>
          </Modal>
        </Container>

            <Dialog
              className={classes.modalBusinessTag}
              open={this.state.isBusinessTagModalOpen}
              onClose={this.handleBusinessTagModalClose}
              closeAfterTransition
              fullWidth={true}
              maxWidth={false}
              PaperProps={{
                style: {
                  width: '350px',
                  height: '240px',
                },
              }}
            >
              <DialogTitle className={classes.headerBar} id="form-dialog-title">
                <div>
                Assign Business Tag/s
                  <CloseIcon
                    className={classes.headGridTwoButton}
                    onClick={this.handleBusinessTagModalClose}
                  />
                </div>
              </DialogTitle>
              <DialogContent className={classes.dialogcontent}>
              <FormControl variant="outlined" fullWidth className={classes.formControl} >
                
                          <InputLabel id="mutiple-select-label">
                          Select Business Tag
                            </InputLabel>
                            <Select
                              label="Select Business Tags"
                              labelId="mutiple-select-label"
                              multiple
                              value={this.state.selectedBusinessTags}
                              onChange={this.handleSelectedTags}
                              renderValue={(selected) => selected.join(",")}
                              MenuProps={MenuProps}
                                style={ {
                                  width: '300px',
                                }}
                            
                            >
                              {this.state.businessTags?.length && this.state.businessTags?.map((eachBusinessTag, i) => (
                                <MenuItem key={eachBusinessTag.name} value={eachBusinessTag.name}>
                                  <ListItemIcon>
                                    <Checkbox
                                      checked={
                                        this.state.selectedBusinessTags.indexOf(eachBusinessTag.name) >
                                        -1
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={eachBusinessTag.name} />
                                </MenuItem>
                              ))}
                            </Select>
                </FormControl>
                </DialogContent>
                <DialogActions  >
                <Container className={classes.footer}>
                  <div>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      margin="normal"
                      onClick={this.handleBusinessTagModalClose}
                    >
                      cancel
                    </Button>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      ///style={{ padding: "20px" }}
                      margin="normal"
                      color="primary"
                      onClick={()=>this.handleAddTagSubmit(this.state.selectedBusinessId)}
                    >
                      save
                    </Button>
                  </div>
                </Container>
                </DialogActions>
                
            
            </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}>
          {muiAlert}
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    business: state.business,
    dashboard: state.dashboard,
    user: state.auth.user,
    areasByRegions: state.area.areasByRegions,
    region:state.region.regions
  }
};

export default connect(mapStateToProps,
  {
    getBusinessListAwaitingApproval,
    changeHeaderName,
    showPendingBusinessDetails,
    searchAwaitingApproval,
    getAreasByRegions,
    updateBusinessWithArea,
    clearMsg,
    getUsersRecords,
    getRegionsRecords,
    addPendingBusinessTag,
    // getRegionsUsersRecords,
    requestApprovedBusinessRecords, requestApprovedBusinessRecordsFalse
  })(withStyles(styles)(PendingBusiness));