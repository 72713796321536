import React from 'react';
import { reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const styles = theme => ({
    form: {
        width: '100%',
        height: '100%',
    },
    footer: {
        height: '7%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        marginTop: theme.spacing(3)
    },
    textArea: {
        marginTop: '5px',
        height: "100%",
        width: "100%"
    },
    textVersion: {
        width: "100%"
    }
});

class AddTermsAndConditionsForm extends React.Component {
    constructor() {
        super();
        this.state = {
            appname: '',
            isActive: 'No',
            htmlString: '',
            version: ''
        };
    }

    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    }

    renderTextAreaField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextareaAutosize
                rowsMin={10}
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    }

    onSubmit = formValues => {
        if (this.state.htmlString && this.state.appname && this.state.isActive && this.state.version) {
            formValues.isActive = this.state.isActive === 'No' ? false : true;
            formValues.type = this.state.appname;
            formValues.htmlString = this.state.htmlString;
            formValues.version = this.state.version
            this.props.onSubmit(formValues);
            this.setState({
                appname: '',
                isActive: 'No',
                htmlString: '',
                version: ''
            })
        }
    }

    handleChange = (e) => {
        let value = e.target.value.trim();
        if (value) {
            this.setState({
                [e.target.name]: e.target.value
            });
        } else {
            this.setState({
                [e.target.name]: ""
            });
        }

    }

    render() {
        const { classes } = this.props;

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <Container className={classes.content}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} >
                            <FormControl variant="outlined" fullWidth className={classes.formControl} margin="normal">
                                <InputLabel htmlFor="outlined-age-native-simple">App Name</InputLabel>
                                <Select
                                    native
                                    value={this.state.appname}
                                    onChange={this.handleChange}
                                    label="App Name"
                                    inputProps={{
                                        name: 'appname',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    <option value="serviceprovider">Business</option>
                                    <option value="consumer">Consumer</option>
                                </Select>
                                {
                                    !this.state.appname ? <FormHelperText error>Required</FormHelperText> : null
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} >
                            <FormControl variant="outlined" fullWidth className={classes.formControl} margin="normal">
                                <InputLabel htmlFor="outlined-age-native-simple">Is Active</InputLabel>
                                <Select
                                    native
                                    value={this.state.isActive}
                                    onChange={this.handleChange}
                                    label="isActive"
                                    inputProps={{
                                        name: 'isActive',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </Select>
                                {
                                    !this.state.isActive ? <FormHelperText error>Required</FormHelperText> : null
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Field name="version" component={this.renderTextField} label="Version" fullWidth variant="outlined" margin="normal" /> */}
                            <TextField name="version" label="Version" value={this.state.version} variant="outlined" onChange={this.handleChange} className={classes.textVersion} />
                            {
                                !this.state.version ? <FormHelperText error>Required</FormHelperText> : null
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Field name="htmlString" component={this.renderTextAreaField} label="Terms & Conditions" fullWidth variant="outlined" margin="normal" className={classes.textArea} /> */}
                            <TextareaAutosize name="htmlString" value={this.state.htmlString} onChange={this.handleChange} rowsMin={10} placeholder="Terms & Conditions" className={classes.textArea} />
                            {
                                !this.state.htmlString ? <FormHelperText error>Required</FormHelperText> : null
                            }
                        </Grid>
                    </Grid>
                </Container>
                <Container className={classes.footer}>
                    <div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            margin="normal"
                            color="primary">
                            Submit
                        </Button>
                    </div>
                </Container>
            </form>
        );
    }
}

const validate = values => {
    const errors = {}

    const requiredFields = [
        'htmlString',
        'version'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'AddTermsAndConditionsForm',
    validate
})(withStyles(styles)(AddTermsAndConditionsForm))
