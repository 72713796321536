import axios from "axios";
import {
    SET_FAQ_LIST,
    SET_FAQ_SUCCESS_MESSAGE,
    SET_FAQ_ERROR_MESSAGE,
    REQUEST_FAQS
} from "./types";

export const requestFaqs = () => {
    return {
        type: REQUEST_FAQS,
        payload: true
    };
};

export const clearMsg = () => async dispatch => {
    let data = {};
    data.msg = null;
    data.ops = null;
    dispatch({ type: SET_FAQ_SUCCESS_MESSAGE, payload: data });
    dispatch({ type: SET_FAQ_ERROR_MESSAGE, payload: data });
};

//Get all the FAQs.
export const getFaqsByUsertype = (userType) => async dispatch => {
    const response = await axios.get(`/api/faqs/user/${userType}`);
    dispatch({ type: SET_FAQ_LIST, payload: response.data });
};

// Add a FAQ
export const addFaq = (payload) => async dispatch => {
    let data = {};
    try {
        await axios.post("/api/faqs", {
            "qanda": {
                question: payload.question,
                answer: payload.answer
            },
            usertype: payload.userType,
            isdeleted: false,
            description: "Test FAQ"
        });
        data.msg = "FAQ added successfully";
        data.ops = "ADD";
        dispatch({ type: SET_FAQ_SUCCESS_MESSAGE, payload: data });
    } catch (err) {
        console.log("Error while adding FAQ : ", err);
        data.msg = "Failed to add FAQ";
        data.ops = "ADD";
        dispatch({ type: SET_FAQ_ERROR_MESSAGE, payload: "Failed to add FAQ" });
    }
};

// Edit FAQ
export const editFaq = (payload) => async dispatch => {
    let data = {};
    try {
        await axios.patch("/api/faqs/" + payload.faqId, {
            "qanda": {
                question: payload.question,
                answer: payload.answer
            },
            usertype: payload.userType,
            adminId: payload.userId
        });

        data.msg = "FAQ edited successfully";
        data.ops = "EDIT";
        dispatch({ type: SET_FAQ_SUCCESS_MESSAGE, payload: data });
    } catch (err) {
        console.log("Error while editing FAQ : ", err);
        data.msg = "Failed to edit FAQ";
        data.ops = "EDIT";
        dispatch({ type: SET_FAQ_ERROR_MESSAGE, payload: "Failed to edit FAQ" });
    }
};

// Delete FAQ
export const deleteFaq = (faqId, userId) => async dispatch => {
    let data = {};
    try {
        await axios.delete(`/api/faqs/${faqId}`, {
            data: {
                adminId: userId
            }
        });

        data.msg = "FAQ deleted successfully";
        data.ops = "DELETE";
        dispatch({ type: SET_FAQ_SUCCESS_MESSAGE, payload: data });
    } catch (err) {
        console.log(err);
        data.msg = "Failed to delete FAQ";
        data.ops = "DELETE";
        dispatch({ type: SET_FAQ_ERROR_MESSAGE, payload: "Failed to delete FAQ" });
    }
};
