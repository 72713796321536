import axios from "axios";
import {
    SET_REGIONAL_HEAD,
    SET_REGIONAL_HEADS,
    SET_REGION_HEAD_SUCCESS_MESSAGE,
    SET_REGION_HEAD_ERROR_MESSAGE,
    REQUEST_REGIONAL_HEADS
} from "./types";

export const requestRegionalHeads = () => {
    return {
        type: REQUEST_REGIONAL_HEADS,
        payload: true
    };
};

export const clearMsg = () => async dispatch => {
    let data = {};
    data.msg = null;
    data.ops = null;
    dispatch({ type: SET_REGION_HEAD_SUCCESS_MESSAGE, payload: data });
    dispatch({ type: SET_REGION_HEAD_ERROR_MESSAGE, payload: data });
};

export const addRegionalHead = (payload) => async dispatch => {
    try {
        await axios.post("/api/regionalhead", payload);
        dispatch({ type: SET_REGION_HEAD_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Region head added successfully." } });
    } catch (err) {
        if (err.response.status === 409) {
            dispatch({ type: SET_REGION_HEAD_ERROR_MESSAGE, payload: { ops: "ADD", msg: "The username already exists. Please use a different username." } });
        } else {
            dispatch({ type: SET_REGION_HEAD_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Failed to add region head." } });
        }
    }
};

export const getAllRegionalHeads = (payload) => async dispatch => {
    try {
        let regionalHeadData = await axios.get("/api/regionalhead/all/search", {
            params: payload
        });
        dispatch({ type: SET_REGIONAL_HEADS, payload: regionalHeadData.data });
        // dispatch({ type: SET_REGION_HEAD_SUCCESS_MESSAGE, payload: "Regional heads fetched successfully." });
    } catch (err) {
        dispatch({ type: SET_REGION_HEAD_ERROR_MESSAGE, payload: "Failed to get all regional heads." });
    }
};

export const updateRegionalHead = (payload) => async dispatch => {
    try {
        await axios.patch(`/api/regionalhead/${payload._id}`, payload);
        dispatch({ type: SET_REGION_HEAD_SUCCESS_MESSAGE, payload: { ops: "UPDATE", msg: "RegionalHead updated successfully." } });
    } catch (err) {
        dispatch({ type: SET_REGION_HEAD_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: "Failed to update RegionalHead" } });
    }
};

export const getRegionalHeadById = (regionalHeadId) => async dispatch => {
    try {
        let regionalHead = await axios.get(`/api/regionalhead/${regionalHeadId}`);
        dispatch({ type: SET_REGIONAL_HEAD, payload: regionalHead.data });
    } catch (err) {
        dispatch({ type: SET_REGION_HEAD_ERROR_MESSAGE, payload: { ops: "GET", msg: "Failed to get Regional Head." } });
    }
};

