import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';

import AddRegionForm from './AddRegionForm';
import EditRegionForm from './EditRegionForm';
import RegionTable from './RegionTable';
import { changeHeaderName } from '../../actions/dashboardActions';
import {
	requestRegions,
	getAllRegions,
	updateRegion,
	addRegion,
	clearMsg,
	searchRegions
} from '../../actions/regionActions';

const styles = theme => ({
	appBarSpacer: theme.mixins.toolbar,
	table: {
		minWidth: 650,
	},
	head: {
		fontWeight: 700
	},
	button: {
		marginTop: theme.spacing(3),
		float: 'right'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0px'
	},
	addModalContainer: {
		width: '50%',
		height: '50%',
		backgroundColor: theme.palette.background.paper,
		padding: '0px 0px',
	},
	editModalContainer: {
		width: '50%',
		height: '80%',
		backgroundColor: theme.palette.background.paper,
		padding: '0px 0px',
	},
	snackClose: {
		padding: theme.spacing(0.5),
	},
	searchBox: {
		width: '30%',
		marginTop: '24px'
	},
	clearButton: {
		marginLeft: '25px'
	},
	content: {
		width: '100%',
		height: '100%',
	},
	container: {
		width: '100%',
		height: '100%',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	list: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	searchPaper: {
		// padding: '0px 0px',
		display: 'flex',
		alignItems: 'center',
		// justifyContent: 'space-between'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
});


class Region extends Component {
	constructor() {
		super();
		this.state = {
			modalOpen: false,
			snackOpen: false,
			sucessMsg: null,
			errorMsg: null,
			regionSearchvalue: "",
			clearSearch: false,
			order: -1,
			orderBy: 'name',
			page: 0,
			rowsPerPage: 10,
			editMode: false,
			regionToEdit: null
		};
	}

	componentDidMount() {
		this.props.changeHeaderName("Manage Regions");
		this.fetchRegionsByQuery();
	}

	handleModalOpen = () => {
		this.setState({ modalOpen: true });
	}

	handleModalClose = () => {
		this.state.regionToEdit && this.state.regionToEdit.tempReferAndEarn && delete this.state.regionToEdit.tempReferAndEarn;
		this.setState({ modalOpen: false, editMode: false, });
	}

	openEditRegionModal = (region) => {
		this.setState({ editMode: true, regionToEdit: region, modalOpen: true });
	}

	handleSnackClose = () => {
		this.setState({ snackOpen: false });
		this.setState({ modalOpen: false });
		this.setState({ sucessMsg: null });
		this.setState({ errorMsg: null, editMode: false });
        this.fetchRegionsByQuery();
		//this.props.getAllRegions();
	}

	fetchRegionsByQuery = () => {
		this.props.requestRegions();
		let payload = {
			orderBy: this.state.orderBy,
			order: Number(this.state.order),
			skip: this.state.page * this.state.rowsPerPage,
			limit: this.state.rowsPerPage,
			searchkey: this.state.regionSearchvalue.trim()
		}
		if (this.state.regionSearchvalue) {
			this.props.searchRegions(payload);
		} else {
			this.props.getAllRegions(payload);
		}
	}

	handleSearchChange = (event) => {
		this.setState({ regionSearchvalue: event.target.value });
	}

	handleSearchSubmit = (event) => {
		event.preventDefault();
		if (this.state.regionSearchvalue.trim()) {
			this.setState({
				clearSearch: true,
				page:0
			}, () => this.fetchRegionsByQuery());
		} else {
			this.setState({ clearSearch: false,page:0 }, () => this.fetchRegionsByQuery());
		}
	}

	handleClearButton = () => {
		this.setState({
			page: 0,
			regionSearchvalue: "",
			clearSearch: false
		}, () => this.fetchRegionsByQuery())
	}

	addRegion = (props) => {
		this.props.addRegion(props);
	}

	updateRegion = (payload) => {
		this.props.updateRegion(payload);
	}

	setOrder = (order) => {
		this.setState({ order }, () => this.fetchRegionsByQuery());
	}

	setOrderBy = (orderBy) => {
		this.setState({ orderBy }, () => this.fetchRegionsByQuery());
	}

	setPage = (page) => {
		this.setState({ page }, () => this.fetchRegionsByQuery());
	}

	setRowsPerPage = (rowsPerPage) => {
		this.setState({ rowsPerPage });
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.region.sucessMsg) {
			this.setState({ sucessMsg: this.props.region.sucessMsg })
			this.setState({ snackOpen: true });
			this.setState({ modalOpen: false });
			this.props.clearMsg();
			//this.props.getAllRegions();
			this.fetchRegionsByQuery();
		} else if (this.props.region.errorMsg) {
			this.setState({ errorMsg: this.props.region.errorMsg })
			this.setState({ snackOpen: true });
			this.props.clearMsg();
		}
	}

	render() {
		const { classes } = this.props;
		let muiAlert = null;

		if (this.state.sucessMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.sucessMsg}
			</MuiAlert>
		} else if (this.state.errorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.errorMsg}
			</MuiAlert>
		}

		let clearSearchButton = null;

		if (this.state.clearSearch) {
			clearSearchButton = <Button variant="contained"
				color="primary"
				onClick={() => this.handleClearButton()}
				className={classes.clearButton}>
				Clear
            </Button>
		}
		return (
			<div className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container className={classes.container}>
					<Grid container spacing={3} style={{ alignItems: 'center' }}>
						<Grid item xs={8}>
							<Container className={classes.searchPaper}>
								<Paper>
									<form onSubmit={this.handleSearchSubmit}>
										<InputBase
											margin="dense"
											placeholder="Search"
											value={this.state.regionSearchvalue}
											style={{ width: '240px', margin: '0px 10px' }}
											onChange={this.handleSearchChange}
										/>
										<IconButton type="submit">
											<SearchIcon style={{ float: 'right' }} />
										</IconButton>
									</form>
								</Paper>
								{clearSearchButton}
							</Container>
						</Grid>
						<Grid item xs={4} >
							<Button
								variant="contained"
								color="primary"
								startIcon={<AddIcon />}
								onClick={() => this.handleModalOpen()}
								style={{ "marginRight": "28px", "float": "right" }}
							>
								Add Region
          				</Button>
						</Grid>
					</Grid>
					<Modal
						className={classes.modal}
						open={this.state.modalOpen}
						onClose={this.handleModalClose}
						closeAfterTransition>
						{
							this.state.editMode ?
								<Container className={classes.editModalContainer}>
									<EditRegionForm initialValues={_.pick(this.state.regionToEdit, '_id', 'name', 'pincode', 'regionCode', 'referAndEarn', 'referralAmount', 'tempReferAndEarn','referralMinOrder')} onSubmit={this.updateRegion} onClose={this.handleModalClose} />
								</Container>
								:
								<Container className={classes.addModalContainer}>
									<AddRegionForm onSubmit={this.addRegion} onClose={this.handleModalClose} />
								</Container>
						}

					</Modal>

					<Container className={classes.container}>
						{
							this.props.region.regions && this.props.region.regions.data ?
								<RegionTable
									rowData={this.props.region.regions.data}
									// order={this.state.order === 1 ? 'asc' : 'desc'}
									// orderBy={this.state.orderBy}
									page={this.state.page}
									rowsPerPage={this.state.rowsPerPage}
									setOrder={this.setOrder}
									setOrderBy={this.setOrderBy}
									setPage={this.setPage}
									setRowsPerPage={this.setRowsPerPage}
									updateRegion={this.updateRegion}
									addRegion={this.addRegion}
									openEditRegionModal={this.openEditRegionModal}
									history={this.props.history}
								/>
								: null
						}
					</Container>
				</Container>

				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={this.state.snackOpen}
					autoHideDuration={6000}
					onClose={this.handleSnackClose}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}>
					{muiAlert}
				</Snackbar>

				<Backdrop className={classes.backdrop} open={this.props.region.isFetching}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		region: state.region,
	}
};
export default connect(mapStateToProps, {
	changeHeaderName,
	requestRegions,
	getAllRegions,
	updateRegion,
	addRegion,
	clearMsg,
	searchRegions
})(withStyles(styles)(Region));