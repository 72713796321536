import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const styles = theme => ({
    form: {
        width: '100%',
        height: '100%',
    },
    header: {
        height: '10%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '78%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '7%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
});

class EditReleaseForm extends React.Component {
    constructor() {
        super();
        this.state = {
            appname: '',
            platform: '',
            force: 'No',
            releasedOn: '',
            isClearAppData: 'No',
            isClearAppDataFlag: false,
            toggleSwitchChange: false
        };
    }

    componentDidMount() {
        this.setState({
            appname: this.props.initialValues.appname,
            platform: this.props.initialValues.platform,
            force: this.props.initialValues.force ? 'Yes' : 'No',
            isClearAppData: this.props?.initialValues?.isClearAppData ? 'Yes' : 'No',
            isClearAppDataFlag: this.props?.initialValues?.isClearAppData ? true : false
        })
    }

    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    }

    renderDateField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                type="date"
                error={touched && invalid}
                helperText={touched && error}
                InputLabelProps={{
                    shrink: true,
                }}
                {...input}
                {...custom}
            />
        );
    }

    onSubmit = formValues => {
        formValues._id = this.props.initialValues._id;
        if (this.state.force && this.state.appname && this.state.platform) {
            formValues.force = this.state.force === 'No' ? false : true;
            formValues.isClearAppData = this.state.isClearAppData === 'No' ? false : true;
            formValues.appname = this.state.appname;
            formValues.platform = this.state.platform;
            this.props.onSubmit(formValues)
        }
    }

    handleModeChange = event => {
        this.setState({
            selectedMode: event.target.value
        });
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleIsDataFlushSwitchChange = (event) => {
        this.setState({ toggleSwitchChange: true, [event.target.name]: event.target.value });
    };

    handleToggleSwitchClose = () => {
        // this.setState({ toggleSwitchChange: false, isClearAppData: this.props?.initialValues?.isClearAppData ? 'Yes' : 'No' });
        this.setState({ toggleSwitchChange: false, isClearAppData: this.state?.isClearAppDataFlag ? 'Yes' : 'No' });
    };

    handleToggleSwitchCloseYes = () => {
        this.setState({ toggleSwitchChange: false, isClearAppDataFlag: !this.state.isClearAppDataFlag });
    };

    render() {
        const { classes } = this.props;

        // console.log(this.state);

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <Grid container className={classes.header} spacing={1}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom>
                            Edit Release
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
                    </Grid>
                </Grid>
                <Container className={classes.content}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} >
                            <FormControl variant="outlined" fullWidth className={classes.formControl} margin="normal">
                                <InputLabel htmlFor="outlined-age-native-simple">App Name</InputLabel>
                                <Select
                                    native
                                    value={this.state.appname}
                                    onChange={this.handleChange}
                                    label="App Name"
                                    inputProps={{
                                        name: 'appname',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    <option value="Business">Business</option>
                                    <option value="Consumer">Consumer</option>
                                </Select>
                                {
                                    !this.state.appname ? <FormHelperText error>Required</FormHelperText> : null
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} >
                            <FormControl variant="outlined" fullWidth className={classes.formControl} margin="normal">
                                <InputLabel htmlFor="outlined-age-native-simple">Platform</InputLabel>
                                <Select
                                    native
                                    value={this.state.platform}
                                    onChange={this.handleChange}
                                    label="platform"
                                    inputProps={{
                                        name: 'platform',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    <option value="android">Android</option>
                                    <option value="ios">iOS</option>
                                </Select>
                                {
                                    !this.state.platform ? <FormHelperText error>Required</FormHelperText> : null
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl component="fieldset" margin="normal">
                                <FormLabel component="legend">Force Upgrade</FormLabel>
                                <RadioGroup aria-label="Force Upgrade" name="force" value={this.state.force} onChange={this.handleChange} style={{ display: "inline" }}>
                                    <FormControlLabel value='Yes' control={<Radio />} label="Yes" />
                                    <FormControlLabel value='No' control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl component="fieldset" margin="normal">
                                <FormLabel component="legend">Clear App Data</FormLabel>
                                <RadioGroup aria-label="Clear App Data" name="isClearAppData" value={this.state.isClearAppData} 
                                // onChange={this.handleChange}
                                onChange={(e) => this.handleIsDataFlushSwitchChange(e)}  
                                style={{ display: "inline" }}>
                                    <FormControlLabel value='Yes' control={<Radio />} label="Yes" />
                                    <FormControlLabel value='No' control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <Field name="versioncode" component={this.renderTextField} label="Version" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={5}>
                            <Field name="releasedOn" component={this.renderDateField} label="Release Date" onChange={this.handleChange} fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={12}>
                            <Field name="appurl" component={this.renderTextField} label="Url" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={12}>
                            <Field name="remark" component={this.renderTextField} label="Remark" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={12}>
                            <Field name="description" component={this.renderTextField} label="Description" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                    </Grid>
                </Container>
                <Container className={classes.footer}>
                    <div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            margin="normal"
                            color="primary">
                            Save
                        </Button>
                    </div>
                </Container>
                <Dialog open={this.state.toggleSwitchChange} onClose={this.handleToggleSwitchClose}>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure to {this.state?.isClearAppData === 'Yes' ? "ON" : "OFF"} the Data Flush?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleToggleSwitchClose} color="primary"> No </Button>
                        <Button onClick={this.handleToggleSwitchCloseYes} color="primary" autoFocus> Yes </Button>
                    </DialogActions>
                </Dialog>
            </form>
        );
    }
}

const validate = values => {
    const errors = {}

    const requiredFields = [
        'appname',
        'platform',
        'versioncode',
        'appurl',
        'remark',
        'description',
        'releasedOn'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (values.releasedOn && !moment(values.releasedOn, 'YYYY-MM-DD', true).isValid()) {
        errors.releasedOn = 'Invalid date.'
    }
    return errors
}

export default reduxForm({
    form: 'EditReleaseForm',
    validate
})(withStyles(styles)(EditReleaseForm))
