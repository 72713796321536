import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import _ from 'lodash';
import { connect } from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { ReactComponent as UNLINK } from '../../../../images/Unlink.svg';
import { ReactComponent as DisabledUnlink } from '../../../../images/DisabledUnlink.svg';
import { ReactComponent as Veg } from '../../../../images/veg.svg';
import { ReactComponent as Egg } from '../../../../images/Egg.svg';
import { ReactComponent as Nonveg } from '../../../../images/Nonveg.svg';
import { IconButton , Avatar } from "@material-ui/core";
import {  requestAddonRecords , requestAddonRecordsFalse  , getLinkedItemsByAddonId , unlinkLinkedItems} from '../../../../actions/addonsAction';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function EnhancedTableHead() {
  
    return (
      <TableHead>
          <TableRow>
          <TableCell> </TableCell>
          <TableCell>Item Id</TableCell>
          <TableCell>Item Name</TableCell>
          <TableCell>Cost</TableCell>
          <TableCell>Unlink</TableCell>
        </TableRow>
      </TableHead>
    );
  }

const styles = theme => ({
    form: {
        marginTop : "6%",
        marginLeft : "30%",
        width: '45%',
        height: 'fit-content',
        backgroundColor: "white",
        overflow: "hidden",
        flexGrow: 1,
        border: "2px solid #3d8bc3",
        borderRadius: "10px!important"
    },
    formDynamic: {
        marginTop : "6%",
        marginLeft : "30%",
        width: '45%',
        height: '80%',
        backgroundColor: "white",
        overflow: "auto",
        flexGrow: 1,
        border: "2px solid #3d8bc3",
        borderRadius: "10px!important"
    },
    header: {
        height: '8%',
        margin: '0px 0px',
        width: '-webkit-fill-available',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,

    },
    CloseIcon: {
        float: "right",
        color: "white"
    },
    headerText: {
        color: "white",
        fontSize: "20px",
        fontFamily: "serif"
    },
    table :{
        padding : "35px 0px 0px 0px",
        overflow : "auto"
    },
    stickyForm: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: theme.palette.primary.main,
    },
});


class LinkedItemsModal extends React.Component {
    constructor() {
        super();
        this.state = {
           
        };
      
    }

   
     componentDidMount = async() =>{
        await this.fetchLinkedItemsByAddonId()
     }


    fetchLinkedItemsByAddonId = async () => {
        let payload = {
          id   : this.props.addonObjectId,
        };
        await this.props.getLinkedItemsByAddonId(payload)
      };

      handleUnlinkItem= async(_id)=>{
          let payload = {
              addonId:  this.props.addonObjectId,
              linkedItemId: _id
          }

          await this.props.unlinkLinkedItems(payload)
          await this.fetchLinkedItemsByAddonId()
      }

    render() {
        const { classes } = this.props;
        let addOnServiceItemParents = this.props.addons.linkedItemData.data?.addOnServiceItemParents
        
        return (
                <div className={ addOnServiceItemParents && addOnServiceItemParents.length < 10 ? classes.form : classes.formDynamic }>
                <div className={classes.appBarSpacer} >
                    <Grid container className={addOnServiceItemParents && addOnServiceItemParents.length < 10 ? classes.header : classes.stickyForm} spacing={1}>
                        <Grid item xs={8}>
                            <Typography variant="h5" gutterBottom className={classes.headerText}>
                                Linked Items
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <CloseIcon className={classes.CloseIcon} onClick={() => { this.props.handleLinkedItemsClose() }} />
                        </Grid>
                    </Grid>
                    <Container className={classes.table}>
                        <TableContainer >
                       
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size="small"
                                aria-label="a dense table"
                            >
                                 <EnhancedTableHead />
                                <TableBody>
                               {addOnServiceItemParents && addOnServiceItemParents.length  ?  addOnServiceItemParents.map((row)=>{
                                      return(
                                        <TableRow>
                                         <TableCell style={{ padding : "0px"}}>
                                         {row.foodType && row.foodType === 'Veg' ?
                                         <Avatar style={{ background : "none"}}> <Veg /></Avatar> : null }
                                         {row.foodType && row.foodType === 'NonVeg' ?
                                         <Avatar style={{ background : "none"}}> <Nonveg /></Avatar> : null }
                                         {row.foodType && row.foodType === 'Egg' ?
                                         <Avatar style={{ background : "none"}}> <Egg /></Avatar> : null }
                                         </TableCell>
                                       <TableCell align='left'>{row.serviceId}</TableCell>
                                       <TableCell align='left'>{row.service}</TableCell>
                                       <TableCell align='left'>{row.cost?.fixedCost}</TableCell>
                                       {this.props.role === "areaManager" ? <TableCell style={{ padding: "0px 0px 0px 10px" }}>
                                            <IconButton
                                              style={{
                                                float: "center",
                                                padding: "0px",
                                                background: "none"

                                              }}
                                             onClick={() => { this.handleUnlinkItem(row._id) }}
                                            >
                                              <Avatar style={{
                                                background: "none"

                                              }}>
                                                <UNLINK />
                                              </Avatar>
                                            </IconButton>
                                          </TableCell> : <TableCell>
                                          <IconButton
                                                      style={{
                                                          float: "center",
                                                          padding: "0px",
                                                          background: "#F2F2F2",
                                                          pointerEvents : 'none'
                                                      }}
                                                  >
                                                      <Avatar style={{
                                                          background: "none"

                                                      }}>
                                                          <DisabledUnlink />
                                                      </Avatar>
                                                  </IconButton>
                                            </TableCell>}
                                      </TableRow>
                                      )
                               })   : <Typography>No Records Available</Typography> }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>


                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
       addons : state.addons
    };
};
export default connect(mapStateToProps, {
    requestAddonRecords , requestAddonRecordsFalse , getLinkedItemsByAddonId , unlinkLinkedItems
})(withStyles(styles)(LinkedItemsModal));