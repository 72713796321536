import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField'
import { Checkbox } from "@material-ui/core";
// import MuiAlert from '@material-ui/lab/Alert';
import FormHelperText from "@material-ui/core/FormHelperText";
// import ChatLayout from './ChatLayout';
// import Joi from "joi-browser";
import { addServiceRequestXirifyAdjustment ,getServiceRequestDetailsById} from '../../actions/serviceRequestActions';
import { lightBlue } from '@material-ui/core/colors';
// import { fetchEnvs } from '../../actions/authActions';
// import IconButton from '@material-ui/core/IconButton';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import InputLabel from "@material-ui/core/InputLabel";
// import Switch from '@material-ui/core/Switch';
// import _ from 'lodash';


const styles = theme => ({
    header: {
        height: '10%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: "22px",
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '70%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        width: '100%',
        height: '20%',
        marginTop: '25px',
        // display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px'
    },
    modalContainer: {
        width: '45%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: '0px 0px',
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    head: {
        fontWeight: 700,
        fontSize: "12px",
    },
    lightBlue: {
        color: theme.palette.getContrastText(lightBlue[50]),
        backgroundColor: lightBlue[50],
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    roundedIcon: {
        marginRight: '10px',
        marginLeft: '10px'
    },
});

class XirifyAdjustmentView extends Component {
    constructor() {
        super();
        this.state = {
            amount: null,
            addSub: "",
            colorChange: false,
            description: "",
            showToBusiness: false,
            showToShopper: false,
            error: {},
            amountError:"",
            hasError:false,
            addDisable:false,
            selection: ""
        };
    }

    close = () => {
        this.clearData();
        this.props.onClose();
    };

    clearData = () => {
        this.setState({
            description: "",
            amount:null,
            addSub: "",
            showToBusiness:false,
            showToShopper:false,
            descriptionError:"",
            addSubError:"",
            amountSub:"",
            hasError:false,
            error:{},
            addDisable:false,
            selection: ""
        })
    }

    validate = async () => {
            // console.log("---inside validate====")
        let error = this.state.error
        if(!this.state.description){
             error.description = "Required"
            //  console.log("----in description error----")
             this.setState({hasError:true});
             
        }
        if(!this.state.addSub){
            // console.log("----in addsub error----")
            error.addSub = "Required"
            this.setState({hasError:true});
        }
        if(!this.state.amount){
            // console.log("----in amount error----")
            error.amount = "Required"
            this.setState({hasError:true});
        }
        if((!this.state.showToBusiness || !this.state.showToShopper )){
            error.selection = "Select atleast one option of above"
            this.setState({hasError:true});
        }
        if((this.state.showToBusiness || this.state.showToShopper )){
            error.selection = ""
            this.setState({hasError: false});
        }
    

        this.setState({ error })
     
    }


    handleChange = (event) => {
       // this.setState({ [event.target.name]: event.target.value });
        // if (!isEmpty(this.state.error)) {
		// 	this.setState({ error: {} })
		// }
        if(event.target.name === "amount"){
            
            // console.log(".....inside amount...",this.state.amount);
            this.setState({amount:event.target.value})
            this.setState({error:{}});
        
        }
           if (event.target.name === "description"){
            this.setState({description:event.target.value})
            this.setState({error:{}});
            // console.log(".....inside description...",this.state.description);

           }
           if (event.target.name === "addSub"){
            this.setState({addSub:event.target.value})
            this.setState({error:{}});

            // console.log(".....inside addSub...",this.state.addSub);

           }
           
        
    }
    
    handleBusinessCheck=(event)=>{
        this.setState({showToBusiness :event.target.checked});
    }
    handleShopperCheck=(event)=>{
        this.setState({showToShopper :event.target.checked});
    }


    addServiceRequestXirifyAdjustmentFetch = async () => {
         
      
       await this.validate();
        if(this.state.error.description || this.state.error.addSub || this.state.error.amount || this.state.error.selection){         
            // this.setState({error:{}});
             this.setState({hasError:false})
            return
        }

        let payload = {
            id: this.props.serviceId,
            amount:this.state.addSub === "add" ? Number(this.state.amount) : -(Number(this.state.amount)),
            description: this.state.description,
            showToBusiness: this.state.showToBusiness,
            showToShopper: this.state.showToShopper
        }
        this.setState({addDisable:true})
        await this.props.addServiceRequestXirifyAdjustment(payload);       
        await this.props.getServiceRequestDetailsById(payload.id);  

        this.close();  
    }
   
    render() {
        const { classes } = this.props;
       console.log("this.state ---->", this.state)
        return (
            <Modal
                className={classes.modal}
                open={this.props.open}
                closeAfterTransition>
                <Container className={classes.modalContainer}>

                    <Grid container className={classes.header} spacing={1}>
                        <Grid item xs={8}>
                            <Typography variant="h5" align="right" gutterBottom>
                                Xirify Adjustment
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <CloseIcon className={classes.headGridTwo} onClick={() => {

                                this.props.onClose();
                              
                            }} />
                        </Grid>
                    </Grid>
                    <Container className={classes.footer}>
                        <div className='row'>
                            <Grid item xs={12}>
                                <Typography align="center" gutterBottom>
                                    Select Add(+) for additional charges.
                                </Typography>
                                <Typography align="center" gutterBottom>
                                    Select Subtract(-) to deduct
                                </Typography>
                            </Grid>
                        </div>
                    </Container>

                    <Container className={classes.footer}>
                        <div className='row' >

                            <Grid item xs={12} sm={4} >
                                <Typography variant="subtitle2" gutterBottom>
                                    Description
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} >
                                <Typography variant="subtitle2" gutterBottom>
                                    Add/Subtract
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Amount(₹)
                                </Typography>
                            </Grid>
                        </div>
                        <div className='row'>
                            <Grid item xs={10} sm={3} >
                                <TextField margin="dense" name="description" variant="outlined"
                                    placeholder="Enter Text"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                    //  error={this.state.error} 
                                    // helperText={this.state.error.description}
                                    
                                   />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={10} sm={3}>
                                <FormControl margin="dense" variant="outlined" className={classes.formControl}>
                                    <Select
                                        value={this.state.addSub}
                                        placeholder="select"
                                        name="addSub"
                                        onChange={this.handleChange}
                                        // error={this.state.error} 
                                        //  helperText={this.state.error.addSub}

                                    >
                                        <MenuItem value="add">Add(+)</MenuItem>
                                        <MenuItem value="subtract">Subtract(-)</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={10} sm={1} >
                                
                               { this.state.addSub === "add" ?
                                <TextField margin="dense" disabled="true" variant="outlined" style={{ backgroundColor: "lightgreen", color: "white" }} value="+" align="center" 
                                /> :   this.state.addSub === "subtract" ?
                                <TextField margin="dense" disabled="true" variant="outlined" style={{ backgroundColor: "red", color: "white" }} value="-" align="center"
                                /> :
                                <TextField margin="dense" disabled="true" variant="outlined" style={{ backgroundColor: "gray" }} align="center"
                                />
                               }
                                {/* {this.state.addSub == "add" ?
                                    <TextField margin="dense" variant="outlined" style={{ backgroundColor: "red", color: "white" }} value="+" align="center" 
                                    /> :
                                    <TextField margin="dense" variant="outlined" style={{ backgroundColor: "lightgreen", color: "white" }} value="-" align="center"
                                    />} */}
                            </Grid>
                            {(this.state.addSub === "subtract"||this.state.addSub === "add" )? 
                            <Grid item xs={10} sm={2} >
                                <TextField margin="dense" name="amount" variant="outlined"
                                 pattern="[0-9]"
                                 inputmode="numeric"
                                    placeholder="₹"
                                    value={this.state.amount}
                                    onChange={this.handleChange}
                                    // error={this.state.error}
                                    // // error={this.state.error} 
                                    // helperText={this.state.error.amount}
                                     />
                            </Grid>
                            :
                            <Grid item xs={10} sm={2} >
                            <TextField margin="dense" name="amount" variant="outlined"
                                placeholder="₹"
                                disabled="true" />
                        </Grid>
                          } 

                         <Grid item xs={4}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.error.description ?"Required":null} </FormHelperText></Grid>

                         <Grid item xs={4}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.error.addSub ?"Required":null} </FormHelperText></Grid>

                        <Grid item xs={4}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> 
                        { 
                         this.state.addSub === "" ? this.state.error.amount = "" :
                        //  !this.state.description ? this.state.error.description = "requied"
                        //  :
                        !this.state.amount ? this.state.error.amount =" Required" : ! /^(\d{0,9})(\.\d{1,2})?$/.test(this.state.amount) ?
                        this.state.error.amount ="Invalid amount" :null
                        //  !this.state.amount ? this.state.error.amount =" Required" : ! /^(\d{0,9})(\.\d{1,2})?$/.test(this.state.amount) ?
                        //  this.state.error.amount ="Invalid amount" :  ((this.props.serviceRequest.serviceRequestDetails.amount - (this.props.serviceRequest.serviceRequestDetails.couponAmount ? this.props.serviceRequest.serviceRequestDetails.couponAmount :0) ) <= this.state.amount && this.state.description && this.state.addSub == "subtract" ) ?                 
                        //  this.state.error.amount= "Amount should not be greater than or equal to Total Amount"  :null 
                         }                            
                            </FormHelperText></Grid>
                        
                        </div>
                    </Container>
                    <Container  className={classes.footer}>
                    <Grid item xs={12} sm={4}>
                    <FormControl >  
										<FormControlLabel
                                        
											control={<Checkbox color="primary" defaultChecked={this.state.showToBusiness} onChange={this.handleBusinessCheck}
                                              />
                                            }
                                            label="Show to Business"
											className={classes.Checkbox}
											labelPlacement="end"
										/>
                                      
									</FormControl>
                    </Grid>
                     
                    <Grid item xs={12} sm={4}>
                    <FormControl >  
										<FormControlLabel
                                        
											control={<Checkbox color="primary" defaultChecked={this.state.showToBusiness} onChange={this.handleShopperCheck}
                                              />
                                            }
                                            label="Show to Shopper"
											className={classes.Checkbox}
											labelPlacement="end"
										/>
                                      
									</FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                   {(!this.state.showToBusiness && !this.state.showToShopper && this.state.error.selection ) ?
                   <Typography style={{color: "red"}}>
                    {this.state.error.selection}
                   </Typography> : ""}
                    </Grid>
                    </Container>
                    <Container className={classes.footer}>
                        <div className='row'>
                            <Grid item xs={3}>
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <Button
                                    style={{ margin: "2px" }}
                                    fullWidth
                                    variant="contained"
                                    onClick={this.close}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={1} ></Grid>
                            <Grid item xs={4} sm={3}>
                                <Button
                                    fullWidth
                                    style={{ margin: "2px" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.addServiceRequestXirifyAdjustmentFetch}
                                    disabled={this.state.addDisable}
                                >
                                    SAVE
                                </Button>
                            </Grid>
                        </div>
                    </Container>

                </Container>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => ({
   // serviceRequest: state.serviceRequest,
   data: state,
  
});

export default connect(mapStateToProps, { addServiceRequestXirifyAdjustment,getServiceRequestDetailsById })(withStyles(styles)(XirifyAdjustmentView));
