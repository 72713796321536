import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { changeHeaderName, getAllServiceProviders, requestBusinessStats, addServiceProviderConversation } from '../../actions/dashboardActions';
import ServiceProviderTable from './ServiceProviderTable';
import ConversationView from "./ConversationView";
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import _ from 'lodash'; 
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const statusArr = ['Hot Lead', 'Cold Lead', 'Dormant','Not Applicable','Call Not Answered'];
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        minWidth: 225,
        maxWidth: 300,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    },
    select: {
        '&:focus': {
          backgroundColor: 'inherit', // Or any other color you prefer
        },
      },
    
});


class ServiceProvider extends Component {
    constructor(props) {
        super(props);
        this.state = JSON.parse(window.localStorage.getItem('serviceProviderDetails')) || {
            order: -1,
            orderBy: 'createdAt',
            page: 0,
            rowsPerPage: 10,
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            firstName: "",
            lastName: "",
            email: "",
            primaryMobileNumber: "",
            ranges: { },
            preparexportToExcel: false,
            modalOpen: false ,
            SPobj : { },
            openEditInfo: false,
            editSP: { },
            city: "",
            area: "",
            pincode: "",
            status: "",
            cityFilter: "",
            areaFilter: "",
            pincodeFilter: "",
            statusFilter: [], 
            businessName: "",
            conversationsFilter: "",
            pincodeError: ""
        }
    }

    componentDidMount() {
        this.props.changeHeaderName("Service Providers");
        this.fetchServiceProvidersByQuery();
        window.addEventListener("beforeunload", () => localStorage.removeItem('serviceProviderDetails'));
    }

    fetchServiceProvidersByQuery = async () => {
        this.props.requestBusinessStats();
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            email: this.state.email.trim(),
            primaryMobileNumber: this.state.primaryMobileNumber.trim(),
            cityFilter: this.state.cityFilter.trim(),
            areaFilter: this.state.areaFilter.trim(),
            pincodeFilter: this.state.pincodeFilter.trim(),
            statusFilter: this.state.statusFilter,
            businessName: this.state.businessName.trim(),
            conversationsFilter: this.state.conversationsFilter,
            exportToexcel: 0,
        }
        console.log("this.states ---->", this.state)
        if (!this.state.pincodeError) {
            await this.props.getAllServiceProviders(payload);
            if (this.state.preparexportToExcel) {
                await this.setState({ preparexportToExcel: false });
            }
        }
        
        localStorage.setItem("serviceProviderDetails", JSON.stringify(this.state));
    }

    fetchServiceProvidersByQueryExcelFile = async () => {
        this.props.requestBusinessStats();
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            email: this.state.email.trim(),
            primaryMobileNumber: this.state.primaryMobileNumber.trim(),
            cityFilter: this.state.cityFilter.trim(),
            areaFilter: this.state.areaFilter.trim(),
            pincodeFilter: this.state.pincodeFilter.trim(),
            statusFilter: this.state.statusFilter,
            businessName: this.state.businessName.trim(),
            conversationsFilter: this.state.conversationsFilter,
            exportToexcel: 1,

        }
        await this.props.getAllServiceProviders(payload);
        this.setState({ preparexportToExcel: true });

    }

    setOrder = (order) => {
        this.setState({ order }, () => this.fetchServiceProvidersByQuery());
    }

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetchServiceProvidersByQuery());
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetchServiceProvidersByQuery());
    }
    
    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    }

    handleFilterChange = async (event) => {
        this.setState({ [event.target.name]: event.target.value });
        console.log("this.state.pincodeFilter --->", this.state.pincodeFilter)

        if (this.state.pincodeFilter && !(/^\d+$/.test(this.state.pincodeFilter)) ){
           await this.setState({ pincodeError: "Please enter valid pincode"});
         }
         else {this.setState({pincodeError: ""})
         console.log("this.states in handle change else --->", this.state)}
    }
    submitInfo = async () =>{
        let hasError = false;
        if (this.state.pincode && !(/^[1-9][0-9]{5}$/.test(this.state.pincode))){
           this.setState({pincodeError: "Please enter valid pincode"})
           hasError = true;
        }
        if (!hasError) {
            let payload = {
                city : this.state.city,
                area : this.state.area,
                pincode : this.state.pincode,
                status: this.state.status,
                serviceProviderId : this.state.editSP._id,
                }    
               await this.props.addServiceProviderConversation(payload);
               this.setState({openEditInfo: false, city: "", area: "", pincode: "", status: ""},  () => this.fetchServiceProvidersByQuery())
        
        }
      }

  resetFilter = () => {
        this.setState({
            order: -1,
            orderBy: 'createdAt',
            page: 0,
            rowsPerPage: 10,
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            firstName: "",
            lastName: "",
            email: "",
            primaryMobileNumber: "",
            preparexportToExcel: false,
            cityFilter: "",
            areaFilter: "",
            pincodeFilter: "",     
            businessName: "",
            statusFilter: [],
            conversationsFilter: "",
            ranges : { },
            pincodeError: ""
        }, () => {
            this.fetchServiceProvidersByQuery();
        })
    }

    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        // picker.endDate.add(1, "days");
        this.setState({
            // filterStartDate: picker.startDate.format('DD MMM YYYY'),
            // filterEndDate: picker.endDate.format('DD MMM YYYY')
            filterStartDate: picker.startDate.format(),
            filterEndDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ dateRange: chosenLabel });
    }

    enterPressed = (event) => {
        let code = event.keyCode;
        if (code === 13) {
            event.preventDefault();
            this.fetchServiceProvidersByQuery();
        }
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }
    openConversationModal = (SPobj) => {
        if(!SPobj.conversations){SPobj["conversations"] = []}
		this.setState({ modalOpen: true, SPobj : SPobj });
	};
    openEditInfo = (SPobj) => {       
		this.setState({ openEditInfo: true, editSP: SPobj, city : SPobj?.city ? SPobj.city : "", area : SPobj?.area ? SPobj.area : "", pincode : SPobj?.pincode ? SPobj.pincode : "" , status:  SPobj?.status ? SPobj.status : "" })

        }
    closeDetailsModal = () => {
        this.setState({ openEditInfo: false});
    }
    closeConversationModal = () => {
        this.setState({ modalOpen: false});
    }
    
    render() {
        const { classes } = this.props;
        console.log("this.state ---->", this.state)
        
        return (
            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Search Service Providers</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="firstName" label="First Name" value={this.state.firstName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="lastName" label="Last Name" value={this.state.lastName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="primaryMobileNumber" label="Mobile Number" value={this.state.primaryMobileNumber} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="email" label="Email Id" value={this.state.email} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={{
                                            'Today': [moment(), moment()],
                                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]

                                        }}
                                        onApply={this.handleDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                        
                                    >
                                        <TextField fullWidth margin="dense" name="createdAt" label = "Registration Date Range" value={this.state.dateRange} variant="outlined" style={{width: "228px"}} />
                                    </DateRangePicker>
                                </Grid> 
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="cityFilter" label="City" value={this.state.cityFilter} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="areaFilter" label="Area" value={this.state.areaFilter} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="pincodeFilter" label="Pincode" 
                                    error={(this.state.pincodeFilter && !(/^\d+$/.test(this.state.pincodeFilter))) ? "Please enter valid Pincode" : null} 
                                    helperText={(this.state.pincodeFilter && !(/^\d+$/.test(this.state.pincodeFilter))) ? "Please enter valid Pincode" : null}
                                    value={this.state.pincodeFilter} variant="outlined" onChange={async(e)=>(this.handleFilterChange(e))} />
                                </Grid>
                                <Grid item xs={3}>
                                    {/* <TextField fullWidth margin="dense" name="statusFilter" label="Status" value={this.state.statusFilter} variant="outlined" onChange={this.handleFilterChange} /> */}
                                    <FormControl
                                         fullWidth
                                         margin="dense"
                                         variant="outlined"
                                        
                                    >
                                        <InputLabel>Status</InputLabel>
                                         <Select                                              
                                                multiple
                                                label = "Status"
                                                labelId="demo-mutiple-checkbox-label"
                                                id="demo-mutiple-checkbox"
                                                value={this.state.statusFilter}
                                                name="statusFilter"
                                                onChange={this.handleFilterChange}
                                                renderValue={(selected) => selected.join(', ')}
                                                classes={{ select: classes.select }}
                                                                 
                                            >
                                                {statusArr.map((status) => (
                                                    <MenuItem key={status} value={status} style={{ minHeight: "28px", height: "28px" }}>
                                                        <Checkbox checked={this.state.statusFilter.indexOf(status) > -1} />
                                                        <ListItemText primary={status} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="businessName" label="Business Name" value={this.state.businessName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">Sales Notes</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="conversationsFilter"
                                        value={this.state.conversationsFilter}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Sales Notes"                                       
                                    >
                                        <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Yes </MenuItem>
                                        <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>

                                <Grid item xs={8}>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        onClick={
                                            ()=>this.setPage(0)
                                           // this.fetchServiceProvidersByQuery
                                        }
                                        disabled = {this.state.pincodeFilter && !(/^\d+$/.test(this.state.pincodeFilter))}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                <Container className={classes.container}>
                    {
                        this.props.serviceProvidersList && this.props.serviceProvidersList.data ?
                            <ServiceProviderTable
                                data={this.props.serviceProvidersList}
                                exportToexcel={this.props.serviceProvidersList.exportToexcel}
                                order={this.state.order === 1 ? 'asc' : 'desc'}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                currentStatus={this.state.currentStatus}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                handleFilterChange={this.handleFilterChange}
                                history={this.props.history}
                                fetchServiceProvidersByQueryExcelFile={this.fetchServiceProvidersByQueryExcelFile}
                                showDownloadIcon={this.state.preparexportToExcel}
                                openConversationModal={this.openConversationModal}
                                openEditInfo={this.openEditInfo}
                                user = {this.props.user}

                            />
                            : null
                    }
                </Container>
                <ConversationView
                    serviceProviderDetails = {this.state.SPobj}
                    supportconversations={this.state.SPobj?.conversations}
                    serviceProviderId={this.state.SPobj?._id}
                    open={this.state.modalOpen}
                    onClose={this.closeConversationModal}

                />
    
                <Dialog open={this.state.openEditInfo} onClose={this.closeDetailsModal} aria-labelledby="form-dialog-title">

                    {this.state?.editSP?.firstName || this.state?.editSP?.lastName ?
                        <DialogTitle style={{ backgroundColor: "#14B7DD", height: "auto", paddingTop: "9px", fontsize: "5px", display: "flex", flexDirection: "column" }}>
                            Edit Service Provider Details   ({this.state?.editSP?.firstName ? this.state.editSP.firstName : ""} {this.state?.editSP?.lastName ? this.state.editSP.lastName : ""})

                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="close"
                                onClick={this.closeDetailsModal}
                                sx={{
                                    position: "absolute",
                                    top: 8,
                                    color: "black"
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle> :
                        <DialogTitle style={{ backgroundColor: "#14B7DD", height: "auto", paddingTop: "9px", fontsize: "5px" }}>
                            Edit Service Provider Details
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="close"
                                onClick={this.closeDetailsModal}
                                sx={{
                                    position: "absolute",
                                    top: 8,
                                    color: "black"
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                    }


                    <Container style={{ paddingTop: "20px" }}>
                        <div style={{ display: 'flex', flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" style={{ padding: "20px" }} gutterBottom>
                                        City
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} style={{ height: "65px" }}>
                                    <TextField margin="dense" name="city" placeholder= "City" value={this.state.city} variant="outlined" style={{ backgroundColor: "white", width: "90%", paddingBottom: "25px", marginTop: "10px" }} onChange={this.handleFilterChange}
                                    />
                                </Grid>

                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" style={{ padding: "20px" }} gutterBottom>
                                        Area
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} style={{ height: "65px" }}>
                                    <TextField margin="dense" name="area"  placeholder= "Area" value={this.state.area} variant="outlined" style={{ backgroundColor: "white", width: "90%", paddingBottom: "25px", marginTop: "10px" }} onChange={this.handleFilterChange}
                                    />
                                </Grid>

                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" style={{ padding: "20px" }} gutterBottom>
                                        Pincode
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} style={{ height: "65px" }}>
                                    <TextField margin="dense" name="pincode" placeholder="Pincode" value={this.state.pincode} variant="outlined" style={{ backgroundColor: "white", width: "90%", paddingBottom: "25px", marginTop: "10px" }} error={this.state.pincode && !(/^[1-9][0-9]{5}$/.test(this.state.pincode)) ? "Please enter valid Pincode" : null} 
                                     helperText={this.state.pincode && !(/^[1-9][0-9]{5}$/.test(this.state.pincode)) ? "Please enter valid Pincode" : null} onChange={this.handleFilterChange}
                                    />
                                </Grid>

                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" style={{ padding: "20px" }} gutterBottom>
                                        Status
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} style={{ height: "65px" }}>
                                    <FormControl
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                    >
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            labelId=""
                                            name="status"
                                            value={this.state.status}
                                            onChange={this.handleFilterChange}
                                            // MenuProps={this.MenuProps}
                                            style={{ height: "40px", width: "90%" }}
                                            label = "Status"                                            

                                        // disabled={this.state.paymentMode !== "CashFree"}
                                        >

                                            <MenuItem
                                                value={"HOTLEAD"}
                                                style={{ minHeight: "28px", height: "28px" }}
                                            >
                                                <ListItemText primary="Hot Lead" />
                                            </MenuItem>
                                            <MenuItem
                                                value={"COLDLEAD"}
                                                style={{ minHeight: "28px", height: "28px" }}
                                            >
                                                <ListItemText primary="Cold Lead" />
                                            </MenuItem>
                                            <MenuItem
                                                value={"DORMANT"}
                                                style={{ minHeight: "28px", height: "28px" }}
                                            >
                                                <ListItemText primary="Dormant" />
                                            </MenuItem>
                                            <MenuItem
                                                value={"NOTAPPLICABLE"}
                                                style={{ minHeight: "28px", height: "28px" }}
                                            >
                                                <ListItemText primary="Not Appplicable" />
                                            </MenuItem>
                                            <MenuItem
                                                value={"CALLNOTANSWERED"}
                                                style={{ minHeight: "28px", height: "28px" }}
                                            >
                                                <ListItemText primary="Call Not Answered" />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </div>
                        </div>
                    </Container>
                    <DialogActions>
                        <Button onClick={this.closeDetailsModal} variant="contained" color="secondary">
                            CANCEL
                        </Button>
                        <Button disabled={this.state.city == this.state.editSP?.city && this.state.area == this.state.editSP?.area && this.state.pincode == this.state.editSP?.pincode && this.state.status == this.state.editSP?.status} onClick={this.submitInfo} variant="contained" color="primary">
                            SAVE
                        </Button>
                    </DialogActions>
                </Dialog>
                <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.dashboard.isFetching,
        serviceProvidersList: state.dashboard.serviceProvidersList,
        user: state.auth.user
    }
};

export default connect(mapStateToProps, { changeHeaderName, getAllServiceProviders, requestBusinessStats, addServiceProviderConversation })(withStyles(styles)(ServiceProvider));