import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { changeHeaderName } from '../../actions/dashboardActions';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeliveriesReportsTable from './DeliveriesReportTable'
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import DeliveriesStatusMultiSelectDropdown from './DeliveriesStatusMultiSelectDropdown';
import { servicePartnerDeliveries , requestDelivery , requestDeliveryFalse , exportServicePartnerDeliveries } from '../../actions/servicePartnerDeliveriesAction'
import { getAllMasterOtp } from '../../actions/dashboardActions';
import { fetchEnvs } from '../../actions/authActions';
import { getConsumerTagList} from "../../actions/consumerTagAction";
import { getBusinessTagList} from "../../actions/businessTagAction";


const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        backgroundColor: '#fff'
    },
    table: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    content: {
        flexGrow: 1,
        height: '100%',
        width: '100%',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        minWidth: 225,
        maxWidth: 300,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    }
});

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class deliveriesReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            business: "",
            consumerFirstName: "",
            consumerLastName: "",
            consumerId: "",
            requestId: "",
            dateRange: "",
            taskcreatedFrom: "",
            taskcreatedTo: "",
            deliveryPartner: "",
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            status: [],
            exportToexcel: false,
            consumerTags:[],
            businessTags:[],
            filteredConsumerTags:[],
            filteredBusinessTags:[]
        };
    }

    componentDidMount() {
        this.props.fetchEnvs();
        this.props.changeHeaderName("Deliveries Report");
        this.fetchDeliveriesReport();
        this.props.getAllMasterOtp();
        this.fetchConsumerTags();
        this.fetchBusinessTags();
    }

    fetchDeliveriesReport = async () => {
        this.props.requestDelivery();

        let deliveryStatuses = [];

        if(this.state.status && this.state.status.length > 0) {
            for (let s=0; s<this.state.status.length; s++) {
                
                let status = this.state.status[s];
                // console.log(status);

                if(status.toUpperCase() === 'RUNNER ACCEPTED') {
                    deliveryStatuses.push(status);
                    deliveryStatuses.push('runner_accepted');
                } else if(status.toUpperCase() === 'REACHED FOR PICKUP') {
                    deliveryStatuses.push(status);
                    deliveryStatuses.push('reached_for_pickup');
                } else if(status.toUpperCase() === 'PICKUP COMPLETE') {
                    deliveryStatuses.push(status);
                    deliveryStatuses.push('pickup_complete');
                } else if(status.toUpperCase() === 'OUT FOR DELIVERY') {
                    deliveryStatuses.push(status);
                    deliveryStatuses.push('started_for_delivery');
                } else if(status.toUpperCase() === 'REACHED FOR DELIVERY') {
                    deliveryStatuses.push(status);
                    deliveryStatuses.push('reached_for_delivery');
                } else if(status.toUpperCase() === 'DELIVERED') {
                    deliveryStatuses.push(status);
                    deliveryStatuses.push('order_end_job');
                } else {
                    deliveryStatuses.push(status);
                }
            }
        }
        let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');

        let payload = {
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            business: this.state.business.trim(),
            consumerId: this.state.consumerId.trim(),
            consumerFirstName: this.state.consumerFirstName.trim(),
            consumerLastName: this.state.consumerLastName.trim(),
            requestId: this.state.requestId.trim(),
            taskcreatedFrom: this.state.taskcreatedFrom,
            taskcreatedTo: this.state.taskcreatedTo,
            // status: this.state.status,
            status: deliveryStatuses,
            deliveryPartner: this.state.deliveryPartner,
            exportToExcel: 0,
            filteredConsumerTags:filteredConsumerTagsVar,
            filteredBusinessTags:filteredBusinessTagsVar
        }
        // console.log(payload)
        await this.props.servicePartnerDeliveries(payload);
              this.props.requestDeliveryFalse()
        if (this.state.exportToexcel) {
            this.setState({ exportToexcel: false });
        }
    }


    fetchNotificationWithExcelFile = async () => {
        this.props.requestDelivery();

        let deliveryStatuses = [];

        if(this.state.status && this.state.status.length > 0) {
            for (let s=0; s<this.state.status.length; s++) {
                
                let status = this.state.status[s];
                // console.log(status);

                if(status.toUpperCase() === 'RUNNER ACCEPTED') {
                    deliveryStatuses.push(status);
                    deliveryStatuses.push('runner_accepted');
                } else if(status.toUpperCase() === 'REACHED FOR PICKUP') {
                    deliveryStatuses.push(status);
                    deliveryStatuses.push('reached_for_pickup');
                } else if(status.toUpperCase() === 'PICKUP COMPLETE') {
                    deliveryStatuses.push(status);
                    deliveryStatuses.push('pickup_complete');
                } else if(status.toUpperCase() === 'OUT FOR DELIVERY') {
                    deliveryStatuses.push(status);
                    deliveryStatuses.push('started_for_delivery');
                } else if(status.toUpperCase() === 'REACHED FOR DELIVERY') {
                    deliveryStatuses.push(status);
                    deliveryStatuses.push('reached_for_delivery');
                } else if(status.toUpperCase() === 'DELIVERED') {
                    deliveryStatuses.push(status);
                    deliveryStatuses.push('order_end_job');
                } else {
                    deliveryStatuses.push(status);
                }
            }
        }
        let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');

        let payload = {
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            business: this.state.business.trim(),
            consumerId: this.state.consumerId.trim(),
            consumerFirstName: this.state.consumerFirstName.trim(),
            consumerLastName: this.state.consumerLastName.trim(),
            requestId: this.state.requestId.trim(),
            taskcreatedFrom: this.state.taskcreatedFrom,
            taskcreatedTo: this.state.taskcreatedTo,
            // status: this.state.status,
            status: deliveryStatuses,
            deliveryPartner: this.state.deliveryPartner,
            exportToExcel: 1,
            filteredConsumerTags:filteredConsumerTagsVar,
            filteredBusinessTags:filteredBusinessTagsVar
        }
        await this.props.exportServicePartnerDeliveries(payload);
        this.setState({ exportToexcel: true });
        this.props.requestDeliveryFalse()
    }

    fetchConsumerTags=async()=>{
        const response=await getConsumerTagList();
        if(response){
        this.setState({consumerTags:response.data})
    }else{
        this.setState({consumerTags:[]})
      }
    }

    fetchBusinessTags=async()=>{
        const response=await getBusinessTagList();
        if(response){
          this.setState({businessTags:response.data})
        }else{
          this.setState({businessTags:[]})
        }
        
    }

    SearchDeliveriesReports = async () => {
        await this.setState({
            page: 0,
            rowsPerPage: this.state.rowsPerPage
        });
        await this.fetchDeliveriesReport();
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetchDeliveriesReport());
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    }

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleTaskCreatedOnDateRangeChange = (event, picker) => {
        let taskCreatedTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        // picker.endDate.add(1, "days");
        this.setState({
            // taskcreatedFrom: picker.startDate.format('DD MMM YYYY'),
            // taskcreatedTo: picker.endDate.format('DD MMM YYYY')
            taskcreatedFrom: picker.startDate.format(),
            taskcreatedTo: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : taskCreatedTimeRange;
        this.setState({
            dateRange: chosenLabel
        });
    }

    resetFilter = () => {
        this.setState({
            page: 0,
            rowsPerPage: 10,
            business: "",
            consumerId: "",
            consumerFirstName: "",
            consumerLastName: "",
            requestId: "",
            deliveryPartner : "",
            taskcreatedFrom: "",
            taskcreatedTo: "",
            dateRange: "",
            // status: "",
            status: [],
            filteredConsumerTags:[],
            filteredBusinessTags:[]
        }, () => {
            this.fetchDeliveriesReport();
        })
    }

    enterPressed = (event) => {
        let code = event.keyCode;
        if (code === 13) {
            event.preventDefault();
            this.fetchDeliveriesReport();
        }
    }

    render() {
        const { classes } = this.props;
        // console.log("this.props in deliveries rport----->",this.props)
        return (
            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Search / Filter Report</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={4}>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="business" label="Business ID / Name" value={this.state.business} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerId" label="Consumer ID" value={this.state.consumerId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerFirstName" label="Consumer First Name" value={this.state.consumerFirstName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerLastName" label="Consumer Last Name" value={this.state.consumerLastName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="requestId" label="Request ID" value={this.state.requestId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <DeliveriesStatusMultiSelectDropdown
                                        handleFilterChange={this.handleFilterChange}
                                        status={this.state.status}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={this.state.ranges}
                                        onApply={this.handleTaskCreatedOnDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" label="Task Created On" value={this.state.dateRange} style={{ width: "225px" }} variant="outlined" />
                                    </DateRangePicker>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth margin="dense" variant="outlined" className={classes.formControl}>
                                        <InputLabel id="delivery-partner-label">Delivery Partner</InputLabel>
                                        <Select
                                            labelId="select-delivery-partner-label"
                                            id="select-delivery-partner-id"
                                            value={this.state.deliveryPartner}
                                            name="deliveryPartner"
                                            onChange={this.handleFilterChange}
                                            label="Delivery Partner"
                                        >
                                            <MenuItem value="dunzo">dunzo</MenuItem>
                                            <MenuItem value="borzo">borzo</MenuItem>
                                            <MenuItem value="porter">Porter</MenuItem>
                                            <MenuItem value="shadowfax">Shadowfax</MenuItem>
                                            <MenuItem value="xirify_delivery">Xirify Delivery</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={3}>
                                {/* <Typography variant="subtitle2" gutterBottom> Select Consumer Tags</Typography> */}
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Consumer Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredConsumerTags}
                                            name="filteredConsumerTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {this.state.consumerTags?.length && this.state.consumerTags?.map((eachConsumerTagObj) => (
                                                <MenuItem key={eachConsumerTagObj._id} value={eachConsumerTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredConsumerTags.indexOf(eachConsumerTagObj.name) > -1} />
                                                    <ListItemText primary={eachConsumerTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> 
                                <Grid item xs={3}>
                                    {/* <Typography variant="subtitle2" gutterBottom> Select Business Tags</Typography> */}
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Business Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredBusinessTags}
                                            name="filteredBusinessTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {this.state. businessTags.map((eachBusinessTagObj) => (
                                                <MenuItem key={eachBusinessTagObj._id} value={eachBusinessTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredBusinessTags.indexOf(eachBusinessTagObj.name) > -1} />
                                                    <ListItemText primary={eachBusinessTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        onClick={this.SearchDeliveriesReports}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                <Container className={classes.container}>
                    {
                        this.props.deliveries.deliveriesList && this.props.deliveries.deliveriesList.data && this.props.deliveries.deliveriesList.data.length > 0 ?
                            <DeliveriesReportsTable
                                data={this.props.deliveries.deliveriesList}
                                allData={this.props}
                                exportToExcel={this.props.deliveries.exportToExcel}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                status={this.state.status}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                fetchNotificationWithExcelFile={this.fetchNotificationWithExcelFile}
                                showDownloadIcon={this.state.exportToexcel}
                            />
                            : null
                    }
                </Container>
                <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.deliveries.isFetching,
        deliveries: state.deliveries,
        dashboard: state.dashboard,
    }
};

export default connect(mapStateToProps, { changeHeaderName, servicePartnerDeliveries, getAllMasterOtp, fetchEnvs ,requestDelivery  , requestDeliveryFalse , exportServicePartnerDeliveries})(withStyles(styles)(deliveriesReports));