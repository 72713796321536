import React from 'react'
import { Input } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  container: {
    padding: '0px',
  },
  inputLabel : {
    color: 'black',
  },
  avatarGrey: {
    width: theme.spacing(10),
    height: theme.spacing(6),
  },
  avatarWhite: {
    width: theme.spacing(10),
    height: theme.spacing(6),
    backgroundColor : theme.palette.primary.main,
  }
});

class UploadIcon extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      inputFile: null
    };

    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    this.setState({ inputFile: null });
  }

  onChange(e) {
    const { input: { onChange } } = this.props
    let file = e.target.files[0];
    this.setState({ inputFile: file });
    onChange(file);   
  }

  render() {
    const { classes } = this.props;
    let inputFile = null;
    let iconImage = null;
    
    // Input file is required during Add and OPTIONAL for edit
    if (this.props.type === "edit") {
      inputFile = <Input type="file" inputProps={{accept:".svg"}} onChange={this.onChange} placeholder="White Icon" disableUnderline/>
    } else {
      inputFile = <Input type="file" inputProps={{accept:".svg"}} onChange={this.onChange} required placeholder="White Icon" disableUnderline/>
    }
    
    if (this.state.inputFile) {
      if (this.props.label === "Grey Icon") {
        iconImage= <img src={URL.createObjectURL(new Blob([this.state.inputFile], {type:'image/svg+xml'}))} 
                      alt="Grey Icon" className={classes.avatarGrey}/>
      } else if(this.props.label === "White Icon") {
        iconImage= <img src={URL.createObjectURL(new Blob([this.state.inputFile], {type:'image/svg+xml'}))} 
                      alt="Grey Icon" className={classes.avatarWhite}/>
      } else {
        iconImage= <img src={URL.createObjectURL(new Blob([this.state.inputFile], {type:'image/svg+xml'}))} 
                      alt="Coloured Icon" className={classes.avatarWhite}/>
      }
    }

    return (
      <Container className={classes.container}>
        <Grid container direction="row"> 
            <Typography variant="subtitle1" gutterBottom>{this.props.label}</Typography>
        </Grid>
        <Grid container direction="row">
            {inputFile}
        </Grid>
        <Grid container direction="row" style={{ marginTop: '10px' }}>
            {iconImage}
        </Grid>
      </Container>
    )
  }
}

export default (withStyles(styles)(UploadIcon))