import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { lightBlue } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const headCells = [
    { id: 'settlementId', disablePadding: true, label: 'Settlement ID' },
    { id: 'vendorId', disablePadding: true, label: 'Cashfree Vendor ID / Business ID' },
    { id: 'transactionId', label: 'Transaction ID' },
    { id: 'totalTransactionAmount', disablePadding: false, label: 'Total Transaction Amount (₹)' },
    { id: 'serviceCharge', disablePadding: false, label: 'Service Charge (₹)' },
    { id: 'serviceTax', disablePadding: false, label: 'Service Tax' },
    { id: 'settlementAmount', disablePadding: false, label: 'Settlement Amount (₹)' },
    { id: 'adjustedAmount', label: 'Adjusted Amount (₹)' },
    { id: 'netSettled', disablePadding: false, label: 'Net Settled Amount (₹)' },
    { id: 'transactionFrom', disablePadding: false, label: 'Transaction From' },
    { id: 'transactionTo', disablePadding: false, label: 'Transaction To' },
    { id: 'settledOn', disablePadding: false, label: 'Settled On' },
    { id: 'utrNo', label: 'UTR No' },
    { id: 'status', label: 'Status' },
    { id: 'source', label: 'Source' },
    { id: 'edit', label: '' },
    { id: 'delete', label: '' }
];

function EnhancedTableHead(props) {
    const { classes } = props;

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        className={classes.tableCellSmall}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
    emailExportButton: {
        backgroundColor: "green", 
        color: "white", 
        fontFamily: "bold", 
        marginRight: "100px", 
        width: "75%"
    }
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Settlements ({props.total ? props.total : 0})
            </Typography>

            {
                props.user.role === 'areaManager' ? 
                    <Typography component="div">
                        <Button
                            variant="contained"
                            margin="normal"
                            className={classes.emailExportButton}
                            // onClick={props.emailReportExcel}
                            onClick={props.emailReportExcelFlag}
                        >
                            Email Report
                        </Button>
                    </Typography>
                : null
            }

            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={props.businessId+"_vendor_split_settlement.csv"}
                        className="btn btn"
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                    :
                    <Button
                        variant="contained"
                        margin="normal"
                        style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                        onClick={props.fetchVendorSplitSettlementExcel}
                    >
                        Export
                    </Button>
                }
            </Typography>
        </Toolbar>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    tableRow: {
        // cursor: 'pointer'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    lightBlue: {
        color: theme.palette.getContrastText(lightBlue[50]),
        backgroundColor: lightBlue[50],
        width: theme.spacing(4),
        height: theme.spacing(4),
        cursor: 'pointer'
    },
}));

export default function EnhancedTable(props) {
    // console.log("props in table ====>", props);
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    const handleDeleteOpen = (e,row) => { props.handleDeleteOpen(e,row) };

    const handleEditOpen = (e,row) => { props.handleEditOpen(e,row) };

    const handleDeleteClose = () => { props.handleDeleteClose() };

    const handleDeleteVendorSettlement = () => { props.handleDeleteVendorSettlement() };

    let headers = [
        { key: 'settlementId', label: 'Settlement ID' },
        { key: 'vendorsId', label: 'Cashfree Vendor ID / Business ID' },
        { key: 'transactionId', label: 'Transaction ID' },
        { key: 'totalTransactionAmount', label: 'Total Transaction Amount' },
        { key: 'serviceCharge', label: 'Service Charge' },
        { key: 'serviceTax', label: 'Service Tax' },
        { key: 'settlementAmount', label: 'Settlement Amount' },
        { key: 'adjustedAmount', label: 'Adjusted Amount' },
        { key: 'netSettledAmount', label: 'Net Settled' },
        { key: 'transactionFromDate', label: 'Transaction From' },
        { key: 'transactionToDate', label: 'Transaction To' },
        { key: 'settledOnDate', label: 'Settled On' },
        { key: 'utrNo', label: 'UTR No' },
        { key: 'status', label: 'Status' },
        { key: 'source', label: 'Source' }
    ];
    let data = props.exportData;

    data && data.forEach(exportData => {
        exportData.vendorsId = exportData.cashfreeVendorId && exportData.cashfreeVendorId !== null && exportData.cashfreeVendorId !== undefined && exportData.cashfreeVendorId !== "" && exportData.source && exportData.source !== null && exportData.source !=undefined && exportData.source !== "" &&  exportData.source.toLowerCase() === "cashfree" ? exportData.cashfreeVendorId : (exportData.businessId ? exportData.businessId : "NA");
        exportData.transactionFromDate = exportData.transactionFrom && exportData.transactionFrom !== null && exportData.transactionFrom !== undefined && exportData.transactionFrom !=="" ? moment(exportData.transactionFrom).format('DD MMM YYYY hh:mm A') : "";
        exportData.transactionToDate = exportData.transactionTo && exportData.transactionTo !== null && exportData.transactionTo !== undefined && exportData.transactionTo !=="" ? moment(exportData.transactionTo).format('DD MMM YYYY hh:mm A') : "";
        exportData.settledOnDate = exportData.settledOn && exportData.settledOn !== null && exportData.settledOn !== undefined && exportData.settledOn !=="" ? moment(exportData.settledOn).format('DD MMM YYYY hh:mm A') : "";
        exportData.source = exportData.source === "cashfree" ? "CashFree": (exportData.source === "manual" ?"Xirify Systems Pvt.Ltd.":exportData.source);
    });


    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    total={props.totalCount}
                    headers={headers}
                    data={data}
                    showDownloadIcon={props.showDownloadIcon}
                    // showEmailReportDownloadIcon={props.showEmailReportDownloadIcon}
                    fetchVendorSplitSettlementExcel={props.fetchVendorSplitSettlementExcel}
                    // emailReportExcel={props.emailReportExcel}
                    emailReportExcelFlag={props.emailReportExcelFlag}
                    businessId={props.businessId}
                    user={props.user}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            rowCount={props.totalCount}
                        />
                        <TableBody>
                            {(
                                props.data && props.data.length > 0) ? props.data
                                    .map((row, index) => {
                                        return (
                                            <TableRow hover className={classes.tableRow} key={index}>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{row.settlementId ? row.settlementId : "NA"}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{row.cashfreeVendorId && row.cashfreeVendorId !== null && row.cashfreeVendorId !== undefined && row.cashfreeVendorId !== "" && row.source && row.source !== null && row.source !==undefined && row.source !=="" &&  row.source.toLowerCase() === "cashfree" ? row.cashfreeVendorId : (row.businessId ? row.businessId : "NA")}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{row.transactionId && row.transactionId !== null && row.transactionId !== undefined && row.transactionId !== "" ? row.transactionId : "NA"}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{row.totalTransactionAmount ? row.totalTransactionAmount : 0.00}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{row.serviceCharge ? row.serviceCharge : 0.00}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{row.serviceTax ? row.serviceTax : 0.00}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{row.settlementAmount ? row.settlementAmount : 0.00}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{row.adjustedAmount ? row.adjustedAmount : 0.00}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{row.netSettledAmount ? row.netSettledAmount : 0.00}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{row.transactionFrom ? (moment(row.transactionFrom).utcOffset("+05:30").format("DD MMM YYYY hh:mm A")) : "NA"}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{row.transactionTo ? (moment(row.transactionTo).utcOffset("+05:30").format("DD MMM YYYY hh:mm A")) : "NA"}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{row.settledOn ? (moment(row.settledOn).utcOffset("+05:30").format("DD MMM YYYY hh:mm A")) : "NA"}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{row.utrNo ? row.utrNo : "NA"}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{row.status ? row.status : "--"}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >{ row.source && row.source === "cashfree"?"Cashfree": row.source && row.source === "manual"?"Xirify Systems Pvt.Ltd.": row.source}</TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row" >
                                                    {(props.user.role === "admin" && row.source && row.source !== null && row.source !==undefined && row.source !=="" && row.source.toLowerCase() === "manual")?
                                                    <IconButton className={classes.icon}
                                                        onClick={(e) => handleEditOpen(e, row)}
                                                    ><Avatar className={classes.lightBlue}><EditIcon color="action" /></Avatar>
                                                    </IconButton> :null}               
                                                </TableCell>
                                                <TableCell className={classes.tableCellSmall} scope="row"> 
                                                    {(props.user.role === "admin" && row.source && row.source !== null && row.source !==undefined && row.source !=="" &&  row.source.toLowerCase() === "manual")?
                                                    <IconButton className={classes.icon} 
                                                        onClick={(e)=>{handleDeleteOpen(e,row)}}
                                                    > <Avatar className={classes.lightBlue}> <DeleteIcon color="action" /> </Avatar>
                                                    </IconButton>:null}                             
                                            </TableCell>
                                            </TableRow>)
                                    })
                                    : null
                                } 
                        </TableBody> 
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={props.totalCount ? props.totalCount : 0}
                    rowsPerPage={props.rowsPerPage ? props.rowsPerPage : 0}
                    page={props.page ? props.page : 0}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

                <Dialog open={props.deleteFlag} onClose={handleDeleteClose} >
                    <DialogTitle>Delete Vendor Settlement</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        Do you want to delete the selected vendor settlement data?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteClose} color="primary" autoFocus> No </Button>
                        <Button onClick={handleDeleteVendorSettlement} color="primary"> Yes </Button>
                    </DialogActions>
                </Dialog> 
            </Paper>
        </div>
    );
}