import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Modal from "@material-ui/core/Modal";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import BulkUpload from '../../../common/FileUploader';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import CreateAddonGroup from "./CreateAddonGroup";
import { clearAddonGroupMsg, getAllAddOnGroups, updateAddonGroup , deleteAddonGroup,uploadAddOnGroup, downloadAddOnGroup} from '../../../../actions/addonGroupAction'
import { requestGroupRecords, requestGroupRecordsFalse } from '../../../../actions/variationsAction'
import AddonGroupTable from "./AddonGroupTable";
import ChangeDefaultModal from "./ChangeDefaultModal";
import RestoreModal from "./RestoreModal";
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import Radio from '@material-ui/core/Radio';
import LinkedItemToAddonGroup from "../ManageAddon/LinkedItemToAddonGroup";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const styles = (theme) => ({

  container: {
    width: "100%",
    float: "left",
    padding: "5px 45px 0px 0px",
  },
  table: {
    marginTop: "10px",
    minWidth: 706,
  },
  content: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  heading: {
    fontSize: "14px",
    fontWeight: 800,
    marginleft: "4px",
  },
  divA: {
    width: "774px",
    display: "flex",
    alignItems: "center!important",
    paddingTop: "5px",
    height : 'screen!important'
  },
  addonGroupTable: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  changeDefaultModal: {
    height: "100%",
    width: "600px",
    display: "flex",
    padding: '25px 50px 25px 50px'
  },
  restoreModal : {
    height: "100%",
    width: "700px",
    display: "flex",
    padding: '25px 50px 25px 50px'
  },




});

class AddonGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      rows: [],
      modalOpen: false,
      snackOpen: false,
      sucessMsg: null,
      errorMsg: null,
      addonGroupForm: {},
      handleOpen: null,
      descriptionOpen: false,
      descriptionData: null,
      groupEditFlag: false,
      dataToSet: null,
      openChangeDefault: false,
      defaultRecords: null,
      openRestore : false,
      restoreRecords : null,
      isItemLinked : "",
      groupId : "",
      isEnabled : "",
      isLocked : "",
      applyPrice : "",
      addonGroupRecords : [],
      deleteAddonGroup : [],
      isAddonGroupDelete : false,
      linkedServiceItems:[],
      addonGroupId : null,
      openlinkedServiceItems : false,
      headers: [
        { key: 'addOnGroupId', label: 'XirifyAddOnGroupId' },
        { key: 'addOnGroupName', label: 'XirifyAddOnGroupName' },
        { key: 'description', label: 'XirifyAddOnGroupDescription' },
        { key: 'singleSelectionType', label: 'SingleSelection' },
        { key: 'multiSelectionType', label: 'MultiSelection' },
        { key: 'minSelection', label: 'MinSelections' },
        { key: 'maxSelection', label: 'MaxSelections' },
        { key: 'applyPrice', label: 'ApplyPrice' },
        { key: 'isEnabled', label: 'GroupIsEnable' },
        { key: 'defaultAddOns', label: 'DefaultAddonId' },
        { key: 'linkedAddOns', label: 'LinkedAddonId' },
        { key: 'linkedServiceItems', label: 'LinkedItems' }       
    ],
    };
  }

  componentDidMount = async () => {
    await this.fetchAddonGroupRecords();

  }



  fetchAddonGroupRecords = async () => {
    this.props.requestGroupRecords()
    let payload = {
      id: this.props.businessDetails._id,
      skip: this.state.page * this.state.rowsPerPage,
      limit: this.state.rowsPerPage,
      isItemLinked : this.state.isItemLinked,
      groupId : this.state.groupId,
      isEnabled : this.state.isEnabled,
      isLocked : this.state.isLocked,
      applyPrice : this.state.applyPrice,
      
    };
    await this.props.getAllAddOnGroups(payload)
    this.setState({ showDownloadIcon: false });
    this.props.requestGroupRecordsFalse()
  };

  resetFilter = async () => {
    this.setState(
      {
        modalOpen: false,
        snackOpen: false,
        sucessMsg: null,
        errorMsg: null,
        createAddonGroupOpen: false,
        page: 0,
        groupEditFlag: false,
        dataToSet: null,
        openChangeDefault: false,
        defaultRecords: null,
        openRestore : false,
        restoreRecords : null,
        isItemLinked : "",
        groupId : "",
        isEnabled : "",
        isLocked : "",
        applyPrice : "",
        deleteAddonGroup : [],
        isAddonGroupDelete:false,
        linkedServiceItems :[],
        addonGroupId : null,
        openlinkedServiceItems : false,
        showDownloadIcon: false
      },
      () => {
        this.fetchAddonGroupRecords();
      }
    );
  };

  enterPressed = (event) => {
    let code = event.keyCode;
    if (code === 13) {
      event.preventDefault();
    }
  };

  setPage = (page) => {
    this.setState({ page }, () => this.fetchAddonGroupRecords());
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage }, () => this.fetchAddonGroupRecords());
  };

  handleFilterChange = async (event) => {  
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.addonGroup.sucessMsg) {
      this.setState({ sucessMsg: this.props.addonGroup.sucessMsg });
      this.setState({ snackOpen: true });
      this.props.clearAddonGroupMsg();
    } else if (this.props.addonGroup.errorMsg) {
      this.setState({ errorMsg: this.props.addonGroup.errorMsg });
      this.setState({ snackOpen: true });
      this.props.clearAddonGroupMsg();
    }
  };

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    this.setState({ modalOpen: false });
    this.setState({ sucessMsg: null });
    this.setState({ errorMsg: null });
    this.setState({ editMode: false });

  };

  handleCreateAddonGroupOpen = () => {
    this.setState({ createAddonGroupOpen: true })
  }
  handleCreateAddonGroupClose = () => {
    this.fetchAddonGroupRecords()
    this.setState({ createAddonGroupOpen: false, groupEditFlag: false, dataToSet: null, })
  }

  handleTableOpen = (value) => {
    this.setState({ openTable: value })
  };
  handleDescriptionOpen = (value) => {
    this.setState({ descriptionOpen: true, descriptionData: value })
  }
  handleDescriptionClose = () => {
    this.setState({ descriptionOpen: false })
  }
  bulkUploadForAddOnGroup = async (file) => {
    let payload = {
      businessId: this.props.businessDetails._id,
      file: file
    }
    await this.props.uploadAddOnGroup(payload);
    await this.fetchAddonGroupRecords()
  }
  handleEditAddonGroupOpen = (value) => {
    this.setState({ groupEditFlag: true, dataToSet: value, createAddonGroupOpen: true })
  }
  handleGroupSwitchChange = async (value) => {
    let payload = {
      addonGroupId: value._id,
      isEnabled: !value.isEnabled
    }
    await this.props.updateAddonGroup(payload);
    this.fetchAddonGroupRecords();
  }
  handleChangeDefault = (value) => {
    this.setState({ openChangeDefault: true, defaultRecords: value })
  }
  closeChangeDefault = async () => {
    this.setState({ openChangeDefault: false });
    await this.fetchAddonGroupRecords();
  }
  handleRestoreOpen = (value) => {
    this.setState({ openRestore: true, restoreRecords: value })
  }
  handleRestoreClose = async () => {
    this.setState({ openRestore: false, restoreRecords: null });
    await this.fetchAddonGroupRecords();
  }

  fetchExportToExcel = async () => {
    let payload = {
      businessId: this.props.businessDetails._id,
      data: {
        orderBy: this.state.orderBy,
        order: this.state.order,        
        isItemLinked : this.state.isItemLinked,
        groupId : this.state.groupId,
        isEnabled : this.state.isEnabled,
        isLocked : this.state.isLocked,
        applyPrice : this.state.applyPrice     }
    }
    await this.props.downloadAddOnGroup(payload);
    this.setState({ showDownloadIcon: true });
  }
  handleFilterChange = (event)=>{
   this.setState({[event.target.name] : event.target.value})
  }

  handleDeleteSelection = (event , _id) =>{
    const  addonGroupRecords  = this.props.addonGroup.addonGroupRecords.data
    for (let index = 0; index < addonGroupRecords.length; index++) {
        if (JSON.stringify(addonGroupRecords[index]._id) === JSON.stringify(_id)) {
            addonGroupRecords[index].isDelete = event.target.checked;
            break;
        }
    }
    const deleteIds = addonGroupRecords.filter(element=> element.isDelete === true ).map(value=> value._id);
    this.setState({ deleteAddonGroup : deleteIds})
  }

  handleGroupDelete = async()=>{
    const { deleteAddonGroup } = this.state
    if (deleteAddonGroup.length) {
        let payload = {
          businessId   :  this.props.businessDetails._id,
          deleteIdsArr :   deleteAddonGroup
        };

        await this.props.deleteAddonGroup(payload);
        await this.fetchAddonGroupRecords();

    }
    this.setState({ isAddonGroupDelete : false})
  };

  handleDeleteDialogOpen = async()=>{
     this.setState({ isAddonGroupDelete : true})
  }
  handleDeleteDialogClose = async()=>{
     this.setState({ isAddonGroupDelete : false , });
     await this.fetchAddonGroupRecords();
  };

  handleLinkedItemOpen =(linkedServiceItems , _id)=>{
    this.setState({ openlinkedServiceItems : true ,  linkedServiceItems : linkedServiceItems , addonGroupId : _id})
  }
  closeLinkedItemToGroup = async()=>{
    this.setState({ openlinkedServiceItems : false ,  linkedServiceItems : [] , addonGroupId : null});
    await this.fetchAddonGroupRecords();
  }



  render() {
    const { classes } = this.props;
    let muiAlert = null;
    if (this.state.sucessMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="success"
        >
          {this.state.sucessMsg}
        </MuiAlert>
      );
    } else if (this.state.errorMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="error"
        >
          {this.state.errorMsg}
        </MuiAlert>
      );
    }

    return (

      <Container className={classes.container}>

        <Grid item xs={12} sm={12}>
          <Container>

            {this.props.role === "areaManager" ?
              <Button
                style={{ float: "right", margin: "5px 130px 0px 5px" }}
                variant="contained"
                onClick={this.handleDeleteDialogOpen}
                color="primary"
                disabled={this.state.deleteAddonGroup.length > 0 ? false : true}

              >
                Delete
              </Button> : null}
          </Container>
          {this.props.role === "areaManager" ?
            <BulkUpload handleFile={this.bulkUploadForAddOnGroup} buttonName="Bulk Upload" />
            : null}
          {this.state.showDownloadIcon ?
            <CSVLink
              style={{ float: "right", margin: "10px" }}
              headers={this.state.headers}
              data={this.props.addonGroup.addonsDownloadToExcel}
              filename={this.props.businessDetails.businessName + " addongroup.csv"}
              className="btn btn"
              target="_blank"
            >
              <Tooltip title="Export to Excel">
                <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
              </Tooltip>
            </CSVLink>
            :
           this.props.role === "areaManager" ? 
           <Button
           style={{ float: "right", margin: "5px" }}
           variant="contained"
           color="primary"
           onClick={this.fetchExportToExcel}
         >
           DOWNLOAD
         </Button>
            :
            <Button
            style={{marginLeft: "900px"}}
            variant="contained"
            color="primary"
            onClick={this.fetchExportToExcel}
          >
            DOWNLOAD
          </Button>
          }

          <Container>
            {this.props.role === "areaManager" ?
              <Button
                style={{ float: "right", margin: "5px 5px 0px 5px" }}
                variant="contained"
                color="primary"
                onClick={() => this.handleCreateAddonGroupOpen()}

              >
                CREATE ADDON GROUP
              </Button> : null}
            <Modal
              open={this.state.createAddonGroupOpen}
              onClose={this.handleCreateAddonGroupClose}
            >
              <Container className={classes.divA}>
                <CreateAddonGroup
                  businessDetails={this.props.businessDetails}
                  handleCreateAddonGroupClose={this.handleCreateAddonGroupClose}
                  fetchAddonGroupRecords={this.fetchAddonGroupRecords}
                  groupEditFlag={this.state.groupEditFlag}
                  dataToSet={this.state.dataToSet}
                />
              </Container>

            </Modal>
          </Container>


        </Grid>
        <Container
          className={classes.container}
          style={{ paddingBottom: "0px" }}
        >
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Search/Filters</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails onKeyDown={this.enterPressed}>
              <Grid container spacing={1}>

                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="groupId"
                    label="Group Id / Group Name"
                    value={this.state.groupId}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">
                      Price
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      name="applyPrice"
                      label="Price"
                      value={this.state.applyPrice}
                      onChange={this.handleFilterChange}
                    >
                      <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Yes </MenuItem>
                      <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">
                     Is Locked 
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      name="isLocked"
                      label="Is Locked"
                      value={this.state.isLocked}
                      onChange={this.handleFilterChange}
                    >
                      <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Locked </MenuItem>
                      <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>UnLocked</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">
                    Is Enabled
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      name="isEnabled"
                      label="Is Enabled"
                      value={this.state.isEnabled}
                      onChange={this.handleFilterChange}
                    >
                      <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Enabled </MenuItem>
                      <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>Disabled</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                    Linked Items With Addon Group
                    </Typography>
                    <RadioGroup row aria-label="position" name="isItemLinked" onChange={this.handleFilterChange} value={this.state.isItemLinked} >
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>All</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                </Grid>             
               
                <Grid item xs={5}></Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    onClick={this.resetFilter}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    color="primary"
                    onClick={
                      () => this.setPage(0)

                    }
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <Container className={classes.addonGroupTable}>
            <AddonGroupTable
              handleTableOpen={this.handleTableOpen}
              openTable={this.state.openTable}
              addonGroupRecords={this.props.addonGroup.addonGroupRecords.data}
              totalCount={this.props.addonGroup.addonGroupRecords.totalCount}
              page={this.state.page}
              rowsPerPage={this.state.rowsPerPage}
              setPage={this.setPage}
              setRowsPerPage={this.setRowsPerPage}
              handleDescriptionOpen={this.handleDescriptionOpen}
              handleEditAddonGroupOpen={this.handleEditAddonGroupOpen}
              handleGroupSwitchChange={this.handleGroupSwitchChange}
              handleChangeDefault={this.handleChangeDefault}
              handleRestoreOpen={this.handleRestoreOpen}
              handleDeleteSelection={this.handleDeleteSelection}
              handleLinkedItemOpen={this.handleLinkedItemOpen}
              role={this.props.user.role}
            />
          </Container>
          <Modal
            open={this.state.openChangeDefault}
            onClose={this.closeChangeDefault}
            closeAfterTransition
          >
            {
              <Container className={classes.changeDefaultModal}>
                <ChangeDefaultModal
                  closeChangeDefault={this.closeChangeDefault}
                  defaultRecords={this.state.defaultRecords}
                />
              </Container>
            }
          </Modal>
          <Modal
            open={this.state.openRestore}
            onClose={this.handleRestoreClose}
            closeAfterTransition
          >
            {
              <Container className={classes.restoreModal}>
                <RestoreModal
                  handleRestoreClose={this.handleRestoreClose}
                  restoreRecords={this.state.restoreRecords}
                />
              </Container>
            }
          </Modal>

          <Modal
          open={this.state.openlinkedServiceItems}
          className={classes.groupModal}
          onClose={this.closeLinkedItemToGroup}
          closeAfterTransition
        >
          <LinkedItemToAddonGroup
            linkedServiceItems={this.state.linkedServiceItems}
            addonGroupId={this.state.addonGroupId}
            role={this.props.user.role}
            closeLinkedItemToGroup={this.closeLinkedItemToGroup}
          />
        </Modal>

        </Container>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={2000}
          onClose={this.handleSnackClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          {muiAlert}
        </Snackbar>

        <Dialog
          open={this.state.isAddonGroupDelete}
          onClose={this.handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete AddonGroup</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to Delete Addon Group ?<br></br>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteDialogClose} color="primary">
              No
            </Button>
            <Button onClick={() => this.handleGroupDelete()} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.descriptionOpen}
          onClose={() => this.handleDescriptionClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Group Description</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.descriptionData}<br></br>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleDescriptionClose()} color="primary" variant='outlined'>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          className={classes.backdrop}
          open={this.props.variation.isFetching}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    addonGroup: state.addonGroup,
    variation: state.variation,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  clearAddonGroupMsg, getAllAddOnGroups, requestGroupRecords, requestGroupRecordsFalse, updateAddonGroup , deleteAddonGroup,uploadAddOnGroup, downloadAddOnGroup
})(withStyles(styles)(AddonGroup));
