import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
    form: {
        width: '100%',
        height: '100%',
    },
    header: {
        height: '15%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '60%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    }
});

class EditAreaForm extends React.Component {
    constructor(props) {
        super();
        this.state = {
            selectedRegion: "",
            isRegionSeleted: true
        };
    }

    componentDidMount() {
        this.setState({
            selectedRegion: this.props.initialValues.regionData._id
        })
    }

    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    }

    onSubmit = formValues => {
        formValues._id = this.props.initialValues._id;
        if (this.state.selectedRegion) {
            this.setState({
                isRegionSeleted: true
            });
            formValues.regionid = this.state.selectedRegion;
            this.props.onSubmit(formValues);
        } else {
            this.setState({
                isRegionSeleted: false
            });
        }
    }

    handleRegionChange = event => {
        this.setState({
            selectedRegion: event.target.value
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <Grid container className={classes.header} spacing={3}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom>
                            Edit Area
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
                    </Grid>
                </Grid>
                <Container className={classes.content}>
                    <Grid container spacing={3}>
                        <Grid item xs={6} style={{ paddingTop: '28px' }}>
                            <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="outlined-age-native-simple">Select Region</InputLabel>
                                <Select
                                    native
                                    value={this.state.selectedRegion}
                                    onChange={this.handleRegionChange}
                                    label="Select Region"
                                    inputProps={{
                                        name: 'region',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option value="">Select Region</option>
                                    {
                                        this.props.regions.map(region => {
                                            return (
                                                <option value={region._id}>{region.name}</option>
                                            )
                                        })
                                    }
                                </Select>
                                {
                                    !this.state.isRegionSeleted ? <FormHelperText style={{ color: 'red' }}>Required</FormHelperText> : null
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="name" component={this.renderTextField} label="Area Name" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="areaCode" component={this.renderTextField} disabled label="Area Code " fullWidth variant="outlined" margin="normal" />
                        </Grid>
                    </Grid>
                </Container>
                <Container className={classes.footer}>
                    <Grid container spacing={2} >
                        <Grid item xs={8}></Grid>
                        <Grid item xs={2}>
                            <Button
                                fullWidth
                                variant="contained"
                                margin="normal"
                                color="default"
                                onClick={() => { this.props.onClose() }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                margin="normal"
                                color="primary">
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        );
    }
}

const validate = values => {
    const errors = {}

    const requiredFields = [
        'name'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });

    return errors
}

export default reduxForm({
    form: 'EditAreaForm',
    validate
})(withStyles(styles)(EditAreaForm))
