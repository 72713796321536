import React, { Component } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../utils/TabPanel";
import { CSVLink } from "react-csv";
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Categories from "../Categories/Categories";
import Specialities from "../Specialities/Specialities";
import Cuisines from "../Cuisine/Cuisine";

const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
});
class CategoryTabs extends Component {
    constructor() {
        super();
        this.state = {
            value: 0,
            headerName: 'Category Type',
            showExcel: false,
            categoriesData: {},
            categoriesList: []
        };
    }

    componentDidMount() {
        axios.get(`/api/categories/getAll`)
            .then(res => res.data)
            .then(data => this.setState({ categoriesList: data }));
    }

    showExcel = async () => {
        this.setState({ showExcel: true });
    }

    setCategoriesProps = (data) => {
        this.setState({ categoriesData: data });
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ value: newValue });
        this.setState({ headerName: (newValue === 0) ? "Category Type" : (newValue === 1) ? "Speciality" : "Cuisine" })

    };



    render() {
        const { classes } = this.props;
        // console.log("this.props-----------", this.props);
        let headers = [
            { label: 'Category Type', key: 'categoryType.name' },
            { label: 'Business Name', key: 'businessName' },
            { label: 'Business Id', key: 'businessId' },
            { label: 'Business Status', key: 'businessStatus' },
            { label: 'Category', key: 'category.name' },
            { label: 'Sub-Category', key: 'subCategories.name' },
            {label: 'Speciality', key: 'speciality'},
            {label: 'Cuisine', key: 'cuisine'} 
            // { label: 'businessName', key: 'businessName' },

        ];
        // console.log("this.state.categoriesData", this.state.categoriesData);
        return (
            <Container maxWidth="lg">
                <div className={classes.appBarSpacer} />
                <Grid container
                    style={{
                        direction: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginTop: '24px',
                        marginBottom: '8px', "paddingLeft": "30px"
                    }}>
                    <Grid item xs={8}>
                        {/* <Container style={{ marginTop: '24px', marginBottom: '8px' }}> */}
                        <Typography color="textPrimary" variant="h5" gutterBottom>{this.state.headerName}</Typography>
                        {/* </Container> */}
                    </Grid>
                    <Grid item xs={4} style={{ "paddingLeft": "70px" }}>
                        {this.state.showExcel ?
                            (this.state.categoriesData.categories && this.state.categoriesList ?
                                <CSVLink
                                    data={this.state.categoriesList}
                                    headers={headers}
                                    filename={"categories.csv"}
                                    className="btn btn"
                                    target="_blank"
                                >
                                    <Tooltip title="Export to Excel">
                                        <img alt="Export To Excel" src="../../../images/exportToExcel.png" width="25" height="25" />
                                    </Tooltip>
                                </CSVLink>
                                : null)
                            :
                            <Button variant="contained" color="secondary"
                                onClick={() => this.showExcel()}>
                                Export Business List
                            </Button>
                        }
                    </Grid>
                </Grid>
                <Tabs
                    value={this.state.value}
                    onChange={this.handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="CATEGORY TYPE" />
                    <Tab label="SPECIALITY" />
                    <Tab label="CUISINE" />
                    {/* <Tab label="CATEGORY" /> */}
                </Tabs>
                <TabPanel value={this.state.value} index={0}>
                    <Categories setCategoriesProps={this.setCategoriesProps} />
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    <Specialities attrs={{
                        changeHeaderName: this.state.categoriesData.changeHeaderName
                        // clearMsg: this.state.categoriesData.clearMsg,
                    }} />
                </TabPanel>
                <TabPanel value={this.state.value} index={2}>
                    <Cuisines attrs={{
                        changeHeaderName: this.state.categoriesData.changeHeaderName
                        // clearMsg: this.state.categoriesData.clearMsg,
                    }} />
                </TabPanel>
            </Container>



        )

    }
}

export default withStyles(styles)(CategoryTabs);