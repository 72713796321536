import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

import { changeHeaderName } from '../../actions/dashboardActions';
import { requestBusinesses, getMonthlyBusinessReport } from '../../actions/businessActions';
import BusinessReportTable from './BusinessReportTable';

const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    content: {
        flexGrow: 1,
        width: '100%',
        height: '100%',
        overflow: 'auto'
    },
    appBarSpacer: theme.mixins.toolbar,
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    }
});

class BusinessReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: -1,
            orderBy: '_id',
            page: 0,
            rowsPerPage: 10,
            year: 2020,
            month: 9,
            reportStartDate: "",
            reportEndDate: "",
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            exportToexcel: false
        };
    }

    componentDidMount() {
        this.props.changeHeaderName("Business Report");
        this.fetchBusinessReports();
    }

    fetchBusinessReports = () => {
        this.props.requestBusinesses();
        let startDay = this.state.filterStartDate;
        let endDay = this.state.filterEndDate;
        if (!this.state.filterStartDate || !this.state.filterEndDate) {
            let date = new Date(), y = date.getFullYear(), m = date.getMonth();
            startDay = new Date(y, m, 1);
            endDay = new Date(y, m + 1, 0);
        }

        this.setState({
            reportStartDate: startDay,
            reportEndDate: endDay
        });

        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            filterStartDate: startDay,
            filterEndDate: endDay,
            exportToexcel : 0
        }
        this.props.getMonthlyBusinessReport(payload);
        if(this.state.exportToexcel){
            this.setState({exportToexcel:false});
        }
    }
    fetchBusinessReportsWithExcel = () => {
        this.props.requestBusinesses();
        let startDay = this.state.filterStartDate;
        let endDay = this.state.filterEndDate;
        if (!this.state.filterStartDate || !this.state.filterEndDate) {
            let date = new Date(), y = date.getFullYear(), m = date.getMonth();
            startDay = new Date(y, m, 1);
            endDay = new Date(y, m + 1, 0);
        }

        this.setState({
            reportStartDate: startDay,
            reportEndDate: endDay
        });

        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            filterStartDate: startDay,
            filterEndDate: endDay,
            exportToexcel : 1
        }
        this.props.getMonthlyBusinessReport(payload);
        this.setState({exportToexcel:true});
    }
    setOrder = (order) => {
        this.setState({ order }, () => this.fetchBusinessReports());
    }

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetchBusinessReports());
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetchBusinessReports());
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    }

    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        picker.endDate.add(1, "days");
        this.setState({
            filterStartDate: picker.startDate.format('DD MMM YYYY'),
            filterEndDate: picker.endDate.format('DD MMM YYYY')
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ dateRange: chosenLabel });
    }

    resetFilter = () => {
        this.setState({
            order: -1,
            orderBy: 'requestsCount',
            page: 0,
            rowsPerPage: 10,
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            reportStartDate: "",
            reportEndDate: ""
        }, () => {
            this.fetchBusinessReports();
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Search Business Report</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={3} style={{ alignItems: 'center' }}>
                                <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={this.state.ranges}
                                        onApply={this.handleDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="orderDates" label="Service Request Date Range" value={this.state.dateRange} style={{ width: "225px" }} variant="outlined" />
                                    </DateRangePicker>
                                </Grid>
                                <Grid item xs={5}>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        onClick={
                                            ()=>this.setPage(0)
                                           // this.fetchBusinessReports
                                        }

                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                <Container className={classes.container}>
                    {
                        this.props.businessReports && this.props.businessReports.data ?
                            <BusinessReportTable
                                data={this.props.businessReports}
                                order={this.state.order === 1 ? 'asc' : 'desc'}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                currentStatus={this.state.currentStatus}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                reportStartDate={this.state.reportStartDate}
                                reportEndDate={this.state.reportEndDate}
                                fetchBusinessReportsWithExcel={this.fetchBusinessReportsWithExcel}
                                showDownloadIcon={this.state.exportToexcel}
                            />
                            : null
                    }
                </Container>
                <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.business.isFetching,
        businessReports: state.business.businessReports
    }
};

export default connect(mapStateToProps, { changeHeaderName, requestBusinesses, getMonthlyBusinessReport })(withStyles(styles)(BusinessReport));