import axios from "axios";
import {

    SET_VARIATION_ADD_SUCCESS_MESSAGE,
    SET_VARIATION_ADD_ERROR_MESSAGE,
    GET_ALL_VARIATIONS,
    GET_ITEMS_TO_LINK_VARIATION_GROUP,
    SET_VARIATIONs_LINK_SUCCESS_MESSAGE,
    SET_VARIATIONs_LINK_ERROR_MESSAGE,
    SET_UPDATE_SUCCESS_MESSAGE,
    SET_UPDATE_ERROR_MESSAGE,
    SET_UPDATE_VARIATION_FLAG_SUCCESS_MESSAGE,
    SET_UPDATE_VARIATION_FLAG_ERROR_MESSAGE,
    SET_DELETE_VARIATIONS_SUCCESS_MESSAGE,
    SET_DELETE_VARIATIONS_ERROR_MESSAGE,
    REQUEST_GROUP_RECORDS,
    REQUEST_GROUP_RECORDS_FALSE,
    UNSET_LINKED_ADDONS_SUCCESS,
    UNSET_LINKED_ADDONS_ERROR,
    GET_ALL_VARIATION_GROUPS_TO_LINK_CATALOG_ITEMS



} from "./types";

export const clearMsg = () => async dispatch => {  
  let data = {};
  data.msg = null;
  data.ops = null;
  dispatch({ type: SET_VARIATION_ADD_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: SET_VARIATION_ADD_ERROR_MESSAGE, payload: data });
  dispatch({ type: SET_VARIATIONs_LINK_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: SET_UPDATE_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: SET_UPDATE_ERROR_MESSAGE, payload: data });
  dispatch({ type: SET_UPDATE_VARIATION_FLAG_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: SET_UPDATE_VARIATION_FLAG_ERROR_MESSAGE, payload: data });
  dispatch({ type: SET_UPDATE_VARIATION_FLAG_ERROR_MESSAGE, payload: data });
  dispatch({ type: SET_DELETE_VARIATIONS_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: SET_DELETE_VARIATIONS_ERROR_MESSAGE, payload: data });
  dispatch({ type: UNSET_LINKED_ADDONS_ERROR, payload: data });
  dispatch({ type: UNSET_LINKED_ADDONS_SUCCESS, payload: data });
};

export const createVariationGroup = (payload) => async (dispatch) => {

  try {
    let response = await axios.post("/api/variations/createvariationgroup", payload);
    dispatch({ type: SET_VARIATION_ADD_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Variation  Group Created Succesfully." } });
  } catch (err) {
    dispatch({ type: SET_VARIATION_ADD_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Failed to Create Variation Group" } });
  }
};

export const getAllVariationGroups = (payload) => async (dispatch) => {
  try {
    const response = await axios.get("/api/variations/getAllVariationGroups/business/" + payload.id , {
      params: payload,
    });
    dispatch({ type: GET_ALL_VARIATIONS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_ALL_VARIATIONS, payload: error });
  }
};


export const getItemsToLinkVariationGroup = (payload) => async (dispatch)=>{
  try {

    const response = await axios.get("/api/variations/getItemsToLinkGroup/business/" + payload.id, {
      params : payload.data
    });
    dispatch({ type: GET_ITEMS_TO_LINK_VARIATION_GROUP, payload: response.data });

    
  } catch (error) {
    dispatch({ type: GET_ITEMS_TO_LINK_VARIATION_GROUP, payload: error });
  }
};

export const linkVariationsToGroup = (payload) => async (dispatch) => {

  try {
    let response = await axios.post("/api/variations/linkVariationsToGroup", payload);
    dispatch({ type: SET_VARIATIONs_LINK_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Variations Added To  Group Succesfully." } });
  } catch (err) {
    dispatch({ type: SET_VARIATIONs_LINK_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Failed To Add Variations To Group" } });
  }
};
export const updateVariationGroup = (payload) => async (dispatch) => {

  try {
    let response = await axios.patch("/api/variations/updatevariationgoup/variationGroup/" + payload.id , payload );
    dispatch({ type: SET_UPDATE_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Variation Group Updated Successfully." } });
  } catch (err) {
    dispatch({ type: SET_UPDATE_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Failed To Update Variation Group" } });
  }
};
export const updateVariationsFlag = (payload) => async (dispatch) => {

  try {
    let response = await axios.patch("/api/variations/upadteVariations/flag" , payload );
    dispatch({ type: SET_UPDATE_VARIATION_FLAG_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Variation Flag Updated Successfully." } });
  } catch (err) {
    dispatch({ type: SET_UPDATE_VARIATION_FLAG_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Failed To Update Variation Flag" } });
  };
}
export const deleteGroupWithVariations = (payload) => async (dispatch) => {

  try {
    let response = await axios.delete("/api/variations/deletevariations/variationGroup",{
      params : payload
    } );
    dispatch({ type: SET_DELETE_VARIATIONS_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: payload.message } });
  } catch (err) {
    dispatch({ type: SET_DELETE_VARIATIONS_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Error" } });
  }
}

export const requestGroupRecords = () => {
	return {
		type: REQUEST_GROUP_RECORDS,
		payload: true
	};
};

export const requestGroupRecordsFalse = () => {
	return {
		type: REQUEST_GROUP_RECORDS_FALSE,
		payload: false
	};
}

export const unlinkLinkedItems = (payload) => async (dispatch) => {
  let response = {};
  try {
    response = await axios.patch(`/api/addons/unlinkLinkedItems`, payload
    );
    dispatch({type: UNSET_LINKED_ADDONS_SUCCESS,  payload: { ops: "ADD", msg: "Unlinked Succesfully." }});
  } catch (err) {
    dispatch({ type: UNSET_LINKED_ADDONS_ERROR, payload: { ops: "ADD", msg: "Failed to Unlink Record" } });
  }
};

export const variationGroupsToLinkCatalogItem = (payload) => async (dispatch) => {
  try {
    const response = await axios.get("/api/variations/allvariationgroups/business/" ,{
      params : payload
    });
    dispatch({ type: GET_ALL_VARIATION_GROUPS_TO_LINK_CATALOG_ITEMS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_ALL_VARIATION_GROUPS_TO_LINK_CATALOG_ITEMS, payload: error });
  }
};
