import axios from "axios";
import {
    SET_AREAS,
    SET_AREA_SUCCESS_MESSAGE,
    SET_AREA_ERROR_MESSAGE,
    REQUEST_AREAS,
    SET_AREAS_BY_REGIONS
} from "./types";

export const requestAreas = () => {
    return {
        type: REQUEST_AREAS,
        payload: true
    };
};

export const clearMsg = () => async dispatch => {
    let data = {};
    data.msg = null;
    data.ops = null;
    dispatch({ type: SET_AREA_SUCCESS_MESSAGE, payload: data });
    dispatch({ type: SET_AREA_ERROR_MESSAGE, payload: data });
};

export const getAllAreas = (payload) => async dispatch => {
    try {
        let areasData = await axios.get("/api/area/search", {
            params: payload
        });
        dispatch({ type: SET_AREAS, payload: areasData.data });
        dispatch({ type: SET_AREA_SUCCESS_MESSAGE, payload: "Areas fetched successfully." });
    } catch (err) {
        dispatch({ type: SET_AREA_ERROR_MESSAGE, payload: "Failed to get all areas." });
    }
};

export const searchAreas = (payload) => async dispatch => {
    try {
        let areasData = await axios.get("/api/area/searchkey/search", {
            params: payload
        });
        dispatch({ type: SET_AREAS, payload: areasData.data });
        dispatch({ type: SET_AREA_SUCCESS_MESSAGE, payload: "Areas fetched successfully." });
    } catch (err) {
        dispatch({ type: SET_AREA_ERROR_MESSAGE, payload: "Failed to get all areas." });
    }
};

export const addArea = (payload) => async dispatch => {
    try {
        await axios.post("/api/area/", payload);
        dispatch({ type: SET_AREA_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Area added successfully." } });
    } catch (err) {
        dispatch({ type: SET_AREA_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Failed to add region." } });
    }
};

export const updateArea = (payload) => async dispatch => {
    try {
        await axios.patch(`/api/area/${payload._id}`, payload);
        dispatch({ type: SET_AREA_SUCCESS_MESSAGE, payload: { ops: "UPDATE", msg: "Area updated successfully." } });
    } catch (err) {
        dispatch({ type: SET_AREA_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: "Failed to update area" } });
    }
};

export const getAreasByRegions = (payload) => async dispatch => {
    try {
        payload.skip = 0;
        payload.limit = 0;

        // old code - but not working
        // let areasData = await axios.get("/api/area/search", {
        //     params: payload
        // });

        let areasData = await axios.get("/api/area/search/searchbyarea", {
            params: payload
        });
        dispatch({ type: SET_AREAS_BY_REGIONS, payload: areasData.data.data });
        // dispatch({ type: SET_AREA_SUCCESS_MESSAGE, payload: "Area fetched successfully." });
    } catch (err) {
        dispatch({ type: SET_AREA_ERROR_MESSAGE, payload: "Failed to fetch areas." });
    }
};
