import axios from "axios";
import {

  SET_CREATE_ADDON_SUCCESS_MESSAGE,
  SET_CREATE_ADDON_ERROR_MESSAGE,
  GET_ALL_ADDONS_LIST,
  UPDATE_ADDON_SUCCESS_MESSAGE,
  UPDATE_ADDON_ERROR_MESSAGE,
  REQUEST_ADDON_RECORDS,
  REQUEST_ADDON_RECORDS_FALSE,
  UNSET_LINKED_ITEMS_SUCCESS,
  UNSET_LINKED_ITEMS_ERROR,
  SET_DELETE_ADDON_SUCCESS_MESSAGE,
  SET_DELETE_ADDON_ERROR_MESSAGE,
  GET_ALL_ADDONS_LIST_TOLINK_ITEM,
  GET_ALL_ITEMS_LIST_TOLINK_ADDON,
  LINK_ADDON_TO_ITEM_SUCCESS,
  LINK_ADDON_TO_ITEM_ERROR,
  GET_ALL_ITEMS_LINKED_WITH_ADDONS,
  GET_LINKEDITEM_BY_ADDON_ID,
  UNLINK_ADDON_FROM_ITEM_SUCCESS,
  UNLINK_ADDON_FROM_ITEM_ERROR,
  LINKED_ADDON_TO_ITEM_UPDATE_SUCCESS,
  LINKED_ADDON_TO_ITEM_UPDATE_ERROR,
  GET_LINKED_ADDON_BY_ITEMID,
  UPLOAD_ADDON_ITEM,
  ADDON_BULKUPLOAD_SUCCESS_MESSAGE,
  ADDON_BULKUPLOAD_SUCCESS_ERROR,
  REQUEST_ADDONS_DOWNLOAD_TOEXCEL,
  ADDONS_DOWNLOAD_TOEXCEL_COMPLETED


} from "./types";

export const clearMsg = () => async dispatch => {  
  let data = {};
  data.msg = null;
  data.ops = null;
  dispatch({ type: SET_CREATE_ADDON_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: SET_CREATE_ADDON_ERROR_MESSAGE, payload: data });
  dispatch({ type: UPDATE_ADDON_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: UPDATE_ADDON_ERROR_MESSAGE, payload: data });
  dispatch({ type: SET_DELETE_ADDON_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: SET_DELETE_ADDON_ERROR_MESSAGE, payload: data });
  dispatch({ type: LINK_ADDON_TO_ITEM_SUCCESS, payload: data });
  dispatch({ type: LINK_ADDON_TO_ITEM_ERROR, payload: data });
  dispatch({ type: UNLINK_ADDON_FROM_ITEM_SUCCESS, payload: data });
  dispatch({ type: UNLINK_ADDON_FROM_ITEM_ERROR, payload: data });
  dispatch({ type: LINKED_ADDON_TO_ITEM_UPDATE_SUCCESS, payload: data });
  dispatch({ type: LINKED_ADDON_TO_ITEM_UPDATE_ERROR, payload: data });
  dispatch({ type: ADDON_BULKUPLOAD_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: ADDON_BULKUPLOAD_SUCCESS_ERROR, payload: data });
};

export const createAddon = (payload) => async (dispatch) => {

  try {
    await axios.post("/api/services/" + payload.id, payload.data);
    dispatch({ type: SET_CREATE_ADDON_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Addon Created Succesfully." } });
  } catch (err) {
    dispatch({ type: SET_CREATE_ADDON_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Failed to Create Addon" } });
  }
};

export const getAllAddonList = (payload) => async (dispatch) => {
  try {
    const response = await axios.get("/api/addons/getAllAddons/business/" + payload.id , {
      params: payload,
    });
    dispatch({ type: GET_ALL_ADDONS_LIST, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_ALL_ADDONS_LIST, payload: error });
  }
};

export const updateAddon = (payload) => async (dispatch) => {
  let response = {};
  try {
    response = await axios.patch(
      `/api/services/${payload.serviceId}`,
      payload.data
    );
    dispatch({type: UPDATE_ADDON_SUCCESS_MESSAGE,  payload: { ops: "ADD", msg: "Addon Updated Succesfully." }});
  } catch (err) {
    dispatch({ type: UPDATE_ADDON_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Failed to Update Addon" } });
  }
};

export const requestAddonRecords = () => {
	return {
		type: REQUEST_ADDON_RECORDS,
		payload: true
	};
};

export const requestAddonRecordsFalse = () => {
	return {
		type: REQUEST_ADDON_RECORDS_FALSE,
		payload: false
	};
};

export const unlinkLinkedItems = (payload) => async (dispatch) => {
  let response = {};
  try {
    response = await axios.patch(`/api/addons/unlinkLinkedItems`, payload
    );
    dispatch({type: UNSET_LINKED_ITEMS_SUCCESS,  payload: { ops: "ADD", msg: "Unlinked Succesfully." }});
  } catch (err) {
    dispatch({ type: UNSET_LINKED_ITEMS_ERROR, payload: { ops: "ADD", msg: "Failed to Unlink Record" } });
  }
};
export const deleteAddons = (payload) => async (dispatch) => {

  try {
    let response = await axios.delete("/api/addons/deleteAddons",{
      params : payload
    } );
    dispatch({ type: SET_DELETE_ADDON_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Addon Deleted Successfully." } });
  } catch (err) {
    dispatch({ type: SET_DELETE_ADDON_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Failed To Delete Addon" } });
  }
}

export const getAddonsToLinkItem = (payload) => async (dispatch) => {
  try {
    const response = await axios.get("/api/addons/getAddonsToLinkItem", {
      params: payload,
    });
    dispatch({ type: GET_ALL_ADDONS_LIST_TOLINK_ITEM, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_ALL_ADDONS_LIST_TOLINK_ITEM, payload: error });
  }
}
export const getItemsToLinkAddon = (payload) => async (dispatch) => {
  try {
    const response = await axios.get("/api/addons/getItemsToLinkAddon", {
      params: payload,
    });
    dispatch({ type: GET_ALL_ITEMS_LIST_TOLINK_ADDON, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_ALL_ITEMS_LIST_TOLINK_ADDON, payload: error });
  }
}
export const linkAddonsToItems = (payload) => async (dispatch) => {
  try {
    const response = await axios.patch("/api/addons/linkAddonsToItems", payload);
    dispatch({type: LINK_ADDON_TO_ITEM_SUCCESS,  payload: { ops: "ADD", msg: "Addon Linked To Item Succesfully." }});
     
  } catch (error) {
    dispatch({type: LINK_ADDON_TO_ITEM_ERROR,  payload: { ops: "ADD", msg: "Failed To Link Addon To Item" }});

  }
  
}

export const getAllItemsLinkedWithAddons = (payload) => async (dispatch) => {
  try {
    const response = await axios.get("/api/addons/getAllItemsLinkedToAddon/business/" + payload.id , {
      params: payload,
    });
    dispatch({ type: GET_ALL_ITEMS_LINKED_WITH_ADDONS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_ALL_ITEMS_LINKED_WITH_ADDONS, payload: error });
  }
};
export const getLinkedItemsByAddonId = (payload) => async (dispatch) => {
  try {
    const response = await axios.get("/api/addons/getLinkedItemsByAddonId/addon/" + payload.id );
    dispatch({ type: GET_LINKEDITEM_BY_ADDON_ID, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_LINKEDITEM_BY_ADDON_ID, payload: error });
  }
};

export const unlinkAddonsFromItem = (payload) => async (dispatch) => {
  try {
    const response = await axios.patch("/api/addons/unlinkAddonByItemId", payload);
    dispatch({type: UNLINK_ADDON_FROM_ITEM_SUCCESS,  payload: { ops: "ADD", msg: "Addons Unlinked from Item Succesfully." }});
     
  } catch (error) {
    dispatch({type: UNLINK_ADDON_FROM_ITEM_ERROR,  payload: { ops: "ADD", msg: "Failed To Unlink Addon from Item" }});

  }
}

  export const updateAddonLinkedToItem = (payload) => async (dispatch) => {
    try {
      const response = await axios.patch("/api/addons/updateLinkedAddon/item/" + payload.id, payload);
      dispatch({type: LINKED_ADDON_TO_ITEM_UPDATE_SUCCESS,  payload: { ops: "ADD", msg: "Item Updated Successfully." }});
       
    } catch (error) {
      dispatch({type: LINKED_ADDON_TO_ITEM_UPDATE_ERROR,  payload: { ops: "ADD", msg: "Failed To Update Item" }});
  
    }
    
  }

  export const getLinkedAddonsByItemId = (payload) => async (dispatch) => {
    try {
      const response = await axios.get("/api/addons/getLinkedAddonsByItemId/item/" + payload.id );
      dispatch({ type: GET_LINKED_ADDON_BY_ITEMID, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_LINKED_ADDON_BY_ITEMID, payload: error });
    }
  };
  

  export const uploadAddons = (payload) => async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_ADDON_ITEM, payload: true });
  
      const formData = new FormData();
      formData.append("file", payload.file);
      await axios.post(
        `/api/services/excel/upload/addons/${payload.businessId}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      dispatch({ type: UPLOAD_ADDON_ITEM, payload: false });
      dispatch({
        type: ADDON_BULKUPLOAD_SUCCESS_MESSAGE,
        payload: { ops: "Bulk Upload", msg: "Addons uploaded successfully." },
      });
    } catch (err) {
      dispatch({ type: UPLOAD_ADDON_ITEM, payload: false });
      dispatch({
        type: ADDON_BULKUPLOAD_SUCCESS_ERROR,
        payload: { ops: "Bulk Upload", msg: "Failed to upload Addons." },
      });
    }
  };

  export const downloadAddons = (payload) => async (dispatch) => {
   
    dispatch({ type:  REQUEST_ADDONS_DOWNLOAD_TOEXCEL});
    const response = await axios.get("/api/services/excel/download/addons/" + payload.businessId,
    { params: payload });
    dispatch({ type: ADDONS_DOWNLOAD_TOEXCEL_COMPLETED, payload: response.data.exportToExcel });
    
  }



