import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FormControlLabel } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';

const styles = (theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
        width: '100%'
    },
    formControl: {
        minWidth: 120,
        maxWidth: 300,
    },
    heading: {
        fontSize: "17px",
        fontWeight: 500
    },
    root: {
        minWidth: 750,
    },
    paper: {
        marginTop: theme.spacing(2),
        width: "100%",
        marginBottom: theme.spacing(2),
    }
});

const foodType = ['Veg','NonVeg','Egg','NA','Blank']
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
class ManageService extends Component {
    constructor() {
        super();
        this.state = {
            serviceName: "",
            head: "",
            subhead: "",
            availability: "",
            isEnabled: "",
            isDisplay: "",
            isDiscountEnabled: "",
            itemTags: "",
            isAddonLinked : "",
            variations : "",
            variationGroupName : "",
            foodType: []
        };
    }

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });

    }

    resetFilter = async () => {
        this.setState({
            serviceName: "",
            head: "",
            subhead: "",
            availability: "",
            isEnabled: "",
            isDisplay: "",
            isDiscountEnabled: "",
            itemTags: "",
            isAddonLinked : "",
            variations : "",
            variationGroupName : "",
            foodType: []
        })
        await this.props.clearFilter()
        await this.props.getServices();
    }
    fetchServicesByQuery = async() => {
        let payload = {
            service: this.state.serviceName.trim(),
            head: this.state.head,
            subhead: this.state.subhead,
            availability: this.state.availability,
            isEnabled: this.state.isEnabled,
            isDisplay: this.state.isDisplay,
            isDiscountEnabled: this.state.isDiscountEnabled,
            itemTags: this.state.itemTags,
            variationGroupName : this.state.variationGroupName.trim(),
            variations : this.state.variations,
            isAddonLinked : this.state.isAddonLinked,
            foodType: this.state.foodType
        }
        // console.log(`The payload`, payload)
        await  this.props.getServices(payload);
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.paper}>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Search/Filter</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                        <Grid container spacing={3} style={{ alignItems: "center" }}>
                            {/* <Grid item xs={12}> */}
                            <Grid item xs={3}>
                                <TextField fullWidth margin="dense" name="serviceName" label="Item Name /Id" value={this.state.serviceName} variant="outlined" onChange={this.handleFilterChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="head-label">Head</InputLabel>
                                    <Select
                                        labelId="head-label"
                                        id="demo-simple-select-standard"
                                        label="Head"
                                        value={this.state.head}
                                        name="head"
                                        onChange={this.handleFilterChange}
                                    >
                                        <MenuItem value="" disabled>
                                            Select Head
                                        </MenuItem>
                                        {this.props.data ?
                                            this.props.data.map((head) =>
                                                <MenuItem key={head._id} value={head._id} style={{ minHeight: "28px", height: "28px" }}>
                                                    {head.name}
                                                </MenuItem>
                                            ) : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>

                                <FormControl fullWidth margin="dense" variant="outlined">
                                    <InputLabel id="demo-simple-select-standard-label">
                                        Sub-Head
                                    </InputLabel>
                                    <Select
                                        labelId="subhead-label"
                                        value={this.state.subhead}
                                        name="subhead"
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Sub-Head"
                                    >
                                        <MenuItem value="" disabled>
                                            Select SubHead
                                        </MenuItem>
                                        {this.props.data ?
                                            this.props.data.map((subhead) =>
                                                subhead._id === this.state.head && subhead.children ?
                                                    subhead.children.map((subhead) => {
                                                        return (
                                                            <MenuItem key={subhead._id} value={subhead._id} style={{ minHeight: "28px", height: "28px" }}>
                                                             {subhead.name}
                                                            </MenuItem>
                                                        )
                                                    }) : null
                                            )
                                            : null
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">Item Tags</InputLabel>
                                    <Select
                                        id="demo-simple-select-standard-label"
                                        name="itemTags"
                                        value={this.state.itemTags}
                                        onChange={this.handleFilterChange}
                                        label="Item Tags"
                                    >
                                        <MenuItem value={"bestSeller"} style={{ minHeight: "28px", height: "28px" }}>Best Seller </MenuItem>
                                        <MenuItem value={"newArrival"} style={{ minHeight: "28px", height: "28px" }}>New</MenuItem>
                                        <MenuItem value={"mustTry"} style={{ minHeight: "28px", height: "28px" }}>Must Try</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth margin="dense" name="variationGroupName" label="Variation Group Name" value={this.state.variationGroupName} variant="outlined" onChange={this.handleFilterChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">Availability</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="availability"
                                        value={this.state.availability}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Availability"
                                    >
                                        <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Available </MenuItem>
                                        <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>Unavailable</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard">Is Enabled</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="isEnabled"
                                        value={this.state.isEnabled}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Is Enabled"
                                    >
                                        <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Enabled</MenuItem>
                                        <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>Disabled </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard">Hide/Display</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="isDisplay"
                                        value={this.state.isDisplay}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Hide/Display"
                                    >
                                        <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>Hide </MenuItem>
                                        <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Display</MenuItem>
                                        
                                    </Select>
                                </FormControl>
                            </Grid>
                            {this.props.isDiscountSection ?
                            <Grid item xs={3}>
                               
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="isDiscountEnabled-label">Is Discount Enabled</InputLabel>
                                        <Select
                                            labelId="isDiscountEnabled-label"
                                            name="isDiscountEnabled"
                                            value={this.state.isDiscountEnabled}
                                            onChange={this.handleFilterChange}
                                            id="demo-simple-select-standard"
                                            label="Is Discount Enabled"
                                           
                                        >
                                            <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Enabled</MenuItem>
                                            <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>Disabled</MenuItem>
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                     : null}

                            <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard">Food Type</InputLabel>
                                    <Select
                                                labelId="demo-mutiple-checkbox-label"
                                                id="demo-mutiple-checkbox"
                                                multiple
                                                value={this.state.foodType}
                                                name="foodType"
                                                onChange={this.handleFilterChange}
                                                input={<Input />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                        >
                                        {foodType.map((foodType) => (
                                                    <MenuItem key={foodType} value={foodType} style={{ minHeight: "28px", height: "28px" }}>
                                                        <Checkbox checked={this.state.foodType.indexOf(foodType) > -1} />
                                                        <ListItemText primary={foodType} />
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>

                                <Fragment>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Items With Variation
                                    </Typography>
                                    <RadioGroup row aria-label="position" name="variations" onChange={this.handleFilterChange} value={this.state.variations} >
                                        <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>All</Typography>} size="small" />
                                        <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                                        <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                                    </RadioGroup>
                                </Fragment>

                            </Grid>
                            <Grid item xs={3}>

                                <Fragment>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Items With Addon
                                    </Typography>
                                       <RadioGroup row aria-label="position" name="isAddonLinked" onChange={this.handleFilterChange} value={this.state.isAddonLinked} >                                   
                                        <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>All</Typography>} size="small" />
                                        <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                                        <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                                    </RadioGroup>
                                </Fragment>

                            </Grid>

                            <Grid item xs={3}></Grid>
                            <Grid item xs={8}></Grid>
                    
                            <Grid item xs={2}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    margin="normal"
                                    onClick={this.resetFilter}
                                > Reset
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    margin="normal"
                                    color="primary"
                                    onClick={this.fetchServicesByQuery}
                                >
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Paper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dashboard: state.dashboard,
        business: state.business,
        user: state.auth.user,
        admin: state.admin,
        categories: state.categories,
        env: state.auth.env
    };
};
export default connect(mapStateToProps, {})(withStyles(styles)(ManageService));
