import axios from "axios";

import {
    GET_EXPENSE,
    FETCH_EXPENSE,
    REQUEST_EXPENSE,
    REQUEST_EXPENSES_RESET,
    SET_EXPENSE_ERROR_MESSAGE,
    SET_EXPENSE_SUCCESS_MESSAGE,
    UPDATE_EXPENSE_ERROR_MESSAGE,
    UPDATE_EXPENSE_SUCCESS_MESSAGE,
    DELETE_EXPENSE_ERROR_MESSAGE,
    DELETE_EXPENSE_SUCCESS_MESAGE,
    GET_EXPENSE_ERROR_MESSAGE
}from './types';

export const clearMsg = () => async (dispatch) => {
  let data = {};
  data.msg = null;
  data.ops = null;
  dispatch({ type: SET_EXPENSE_ERROR_MESSAGE, payload: data });
  dispatch({ type: SET_EXPENSE_ERROR_MESSAGE, payload: data });
  dispatch({ type: UPDATE_EXPENSE_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: UPDATE_EXPENSE_ERROR_MESSAGE, payload: data });
  dispatch({ type: DELETE_EXPENSE_SUCCESS_MESAGE, payload: data });
  dispatch({ type: DELETE_EXPENSE_ERROR_MESSAGE, payload: data });
};

export const getAllexpenses = (payload) => async(dispatch) =>{
    try{
        // console.log('payload-------------->',payload)
        let expenseList = await axios.get("api/expense",{params: payload})
        // console.log('expenseList==========>',expenseList)
    dispatch({type: GET_EXPENSE, payload: expenseList.data });
    }catch (err) {
        dispatch({type: GET_EXPENSE_ERROR_MESSAGE, payload: {msg:'Failed to get all expenses'}})
    }
}

export const addExpense = (payload) => async(dispatch) =>{
    try{
        // console.log('payload addExpense',payload)
        let response = await axios.post("api/expense/createExpense",payload);
        dispatch({type: SET_EXPENSE_SUCCESS_MESSAGE , payload :{msg: 'Expense added successfully'} })
    }catch(err){
        dispatch({ type: SET_EXPENSE_ERROR_MESSAGE, payload :{msg:'Failed to add expense'}})
    }
}

export const updateExpense = (payload) => async (dispatch) =>{
    try{
        // console.log('payload updateExpense',payload)
        let response = await axios.patch(`/api/expense/${payload.expenseId}`,payload.data);
        dispatch({ type:UPDATE_EXPENSE_SUCCESS_MESSAGE, payload: {msg:"Expense data updated successfully"}});
    }catch(err){
        console.log('error while updating ',err);
        dispatch({type:UPDATE_EXPENSE_ERROR_MESSAGE, payload: {msg:"Failed to update expense"}})
    }
}

export const requestExpense = () => {
    return {
      type: REQUEST_EXPENSE,
      payload: true,
    };
  };

export const requestExpenseReset = () => {
   return {
     type: REQUEST_EXPENSES_RESET,
     payload: false,
   };
 };

 export const deleteExpense = (payload) => async (dispatch) => {
    try{
      // console.log("------ payload in action of delete-----",payload);
        let response = await axios.delete(`/api/expense/${payload.expenseId}`)
      // console.log("==== response of delete data====",response);
        dispatch({type:DELETE_EXPENSE_SUCCESS_MESAGE, payload: {msg:"Expense data deleted successfully"}});
    }catch (err) {
        console.log("Error while deleting : ", err);
        dispatch({ type: DELETE_EXPENSE_SUCCESS_MESAGE, payload: {msg:"Failed to delete expense"}})
      }
 }

 



