import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';

import MultiSelectDropDown from './MultiSelectDropDown';

const styles = theme => ({
    form: {
        width: '100%',
        height: '100%',
    },
    header: {
        height: '10%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '75%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '4%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    }
});

class AddRegionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assignedRegions: [],
            selectedRegions: []
        }
    }

    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    }

    componentDidMount() {
        let assignedRegionsTemp = [];
        let selectedRegionsTemp = [];
        if (this.props.regions && this.props.initialValues && this.props.initialValues.controlledRegions) {
            this.props.initialValues.controlledRegions.forEach(region => {
                let regionTemp = this.props.regions.find(element => element._id === region._id);
                assignedRegionsTemp.push(regionTemp.name);
                selectedRegionsTemp.push(regionTemp._id);
            });
            this.setState({
                assignedRegions: assignedRegionsTemp,
                selectedRegions: selectedRegionsTemp
            })
        }
    }

    onSubmit = formValues => {
        let assignedRegionsTemp = [];
        if (this.state.selectedRegions.length > 0) {
            this.state.selectedRegions.forEach(region => {
                let regionTemp = this.props.regions.find(element => element._id.toLowerCase() === region.toLowerCase());
                // regionTemp ? assignedRegionsTemp.push(regionTemp._id) : assignedRegionsTemp = [];
                assignedRegionsTemp.push(regionTemp._id);
            });
        }
        formValues.controlledRegions = assignedRegionsTemp;
        formValues._id = this.props.initialValues._id;
        this.props.onSubmit(formValues);
    }

    assignRegions = (event) => {
        this.setState({ assignedRegions: event.target.value });
        let selectedRegions = [];
        if( event.target.value.length>0){
        for (let i = 0; i < this.props.regions.length; i++) {
            for (let j = 0; j < event.target.value.length; j++) {
                const element = event.target.value[j];
                if (element.toLowerCase() === this.props.regions[i].name.toLowerCase()) {
                    selectedRegions.push(this.props.regions[i]._id)
                }
            }
        }
    }
        this.setState({
            selectedRegions: selectedRegions,
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <Grid container className={classes.header} spacing={3}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom>
                            Edit Regional Head
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
                    </Grid>
                </Grid>
                <Container className={classes.content}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Field name="firstName" component={this.renderTextField} label="First Name" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="lastName" component={this.renderTextField} label="Last Name" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="email" component={this.renderTextField} disabled label="Email" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="mobileNumber" component={this.renderTextField} label="Contact Number" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="username" component={this.renderTextField} disabled label="Username" fullWidth variant="outlined" margin="normal" />
                        </Grid>

                        <Divider variant="middle" style={{ width: '100%', margin: '0px', height: '2px' }} />
                        <Grid item xs={12}>
                            <MultiSelectDropDown
                                regions={this.props.regions}
                                assignedRegions={this.state.assignedRegions}
                                assignRegions={this.assignRegions}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <Container className={classes.footer}>
                    <Grid container spacing={2} >
                        <Grid item xs={8}></Grid>
                        <Grid item xs={2}>
                            <Button
                                fullWidth
                                variant="contained"
                                margin="normal"
                                color="default"
                                onClick={() => { this.props.onClose() }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                margin="normal"
                                color="primary">
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        );
    }
}

const validate = values => {
    const errors = {}

    const requiredFields = [
        'firstName',
        'lastName',
        'primaryMobileNumber'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });

    if (values.firstName && !/^[a-z ]+$/i.test(values.firstName)) {
        errors.firstName = 'firstName should have only alphabets'
    }

    if (values.lastName && !/^[a-z ]+$/i.test(values.lastName)) {
        errors.lastName = 'lastName should have only alphabets'
    }

    if (values.primaryMobileNumber && !/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/i.test(values.primaryMobileNumber)) {
        errors.primaryMobileNumber = 'Invalid mobile number'
    }

    return errors
}

export default reduxForm({
    form: 'AddRegionForm',
    validate
})(withStyles(styles)(AddRegionForm))
