import {
    SET_AREAS,
    SET_AREA_SUCCESS_MESSAGE,
    SET_AREA_ERROR_MESSAGE,
    REQUEST_AREAS,
    SET_AREAS_BY_REGIONS
} from "../actions/types";

const INITIAL_STATE = {
    areas: {},
    areasByRegions: [],
    sucessMsg: null,
    errorMsg: null,
    ops: "",
    isFetching: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REQUEST_AREAS:
            return { ...state, isFetching: action.payload };
        case SET_AREAS:
            return { ...state, areas: action.payload, isFetching: false };
        case SET_AREAS_BY_REGIONS:
            return { ...state, areasByRegions: action.payload, isFetching: false };
        case SET_AREA_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case SET_AREA_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        default:
            return state;
    }
}