import {

    SET_CREATE_ADDON_SUCCESS_MESSAGE,
    SET_CREATE_ADDON_ERROR_MESSAGE,
    GET_ALL_ADDONS_LIST,
    UPDATE_ADDON_SUCCESS_MESSAGE,
    UPDATE_ADDON_ERROR_MESSAGE,
    REQUEST_ADDON_RECORDS,
    REQUEST_ADDON_RECORDS_FALSE,
    UNSET_LINKED_ITEMS_SUCCESS,
    UNSET_LINKED_ITEMS_ERROR,
    SET_DELETE_ADDON_SUCCESS_MESSAGE,
    SET_DELETE_ADDON_ERROR_MESSAGE,
    GET_ALL_ADDONS_LIST_TOLINK_ITEM,
    GET_ALL_ITEMS_LIST_TOLINK_ADDON,
    LINK_ADDON_TO_ITEM_SUCCESS,
    LINK_ADDON_TO_ITEM_ERROR,
    GET_ALL_ITEMS_LINKED_WITH_ADDONS,
    GET_LINKEDITEM_BY_ADDON_ID,
    UNLINK_ADDON_FROM_ITEM_SUCCESS,
    UNLINK_ADDON_FROM_ITEM_ERROR,
    LINKED_ADDON_TO_ITEM_UPDATE_SUCCESS,
    LINKED_ADDON_TO_ITEM_UPDATE_ERROR,
    GET_LINKED_ADDON_BY_ITEMID,
    UPLOAD_ADDON_ITEM,
    ADDON_BULKUPLOAD_SUCCESS_MESSAGE,
    ADDON_BULKUPLOAD_SUCCESS_ERROR,
    REQUEST_ADDONS_DOWNLOAD_TOEXCEL,
    ADDONS_DOWNLOAD_TOEXCEL_COMPLETED,
    
  
  

} from "../actions/types";

const INITIAL_STATE = {
    sucessMsg: null,
    errorMsg: null,
    addonList: [],
    addonRecordstoLink : [],
    itemRecordstoLink  : [],
    linkedRecords      : [],
    linkedItemData     : [],
    linkedAddonData    : [],  
    isFetching: false,
    ops: "",
    page: 0,
    skip: 0,
    limit: 10,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case SET_CREATE_ADDON_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case SET_CREATE_ADDON_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false

            };
        case GET_ALL_ADDONS_LIST:
            return {
                ...state,
                addonList: action.payload,

            };
        case GET_ALL_ADDONS_LIST_TOLINK_ITEM:
            return {
                ...state,
                addonRecordstoLink: action.payload,

            };
        case GET_ALL_ITEMS_LIST_TOLINK_ADDON:
            return {
                ...state,
                itemRecordstoLink: action.payload,

            };
        case UPDATE_ADDON_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case UPDATE_ADDON_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false

            };
        case REQUEST_ADDON_RECORDS:
            return { ...state, isFetching: action.payload };
        case REQUEST_ADDON_RECORDS_FALSE:
            return { ...state, isFetching: action.payload };
        case UNSET_LINKED_ITEMS_SUCCESS:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case UNSET_LINKED_ITEMS_ERROR:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false

            };
        case SET_DELETE_ADDON_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case SET_DELETE_ADDON_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false

            };
        case LINK_ADDON_TO_ITEM_SUCCESS:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case LINK_ADDON_TO_ITEM_ERROR:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false

            };
        case UNLINK_ADDON_FROM_ITEM_SUCCESS:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case UNLINK_ADDON_FROM_ITEM_ERROR:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false

            };
        case LINKED_ADDON_TO_ITEM_UPDATE_SUCCESS:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case LINKED_ADDON_TO_ITEM_UPDATE_ERROR:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false

            };
        case GET_ALL_ITEMS_LINKED_WITH_ADDONS:
                return {
                ...state,
                linkedRecords : action.payload,
    
            };
        case GET_LINKEDITEM_BY_ADDON_ID:
                return {
                ...state,
                linkedItemData : action.payload,
    
            };
        case GET_LINKED_ADDON_BY_ITEMID:
                return {
                ...state,
                linkedAddonData : action.payload,
    
            };
        case UPLOAD_ADDON_ITEM:
        return { ...state, isFetching: action.payload };

        case ADDON_BULKUPLOAD_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case ADDON_BULKUPLOAD_SUCCESS_ERROR:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false

            };
        case REQUEST_ADDONS_DOWNLOAD_TOEXCEL:
            return { ...state, addonsDownloadToExcel : action.payload, isFetching: true };
        case ADDONS_DOWNLOAD_TOEXCEL_COMPLETED:
            return { ...state, addonsDownloadToExcel : action.payload, isFetching: false }; 

        default:
            return state;
    }
}