import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import moment from 'moment';

const headers = [
  { id: "dateTime", disablePadding: false, label: "Date Time" },
  { id: "app", disablePadding: false, label: "App" },
  { id: "otpStatus", disablePadding: false, label: "OTP Status" },
  { id: "mobileNumber", disablePadding: false, label: "Mobile Number" },
  { id: "otp", disablePadding: false, label: "OTP" }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCellSmall}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableCellSmall: {
    // fontSize: "12px",
    padding: "6px 0px 6px 16px",
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = props.orderBy === property && props.order === "asc";
    props.setOrder(isAsc ? -1 : 1);
    props.setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  /* const emptyRows =
    props.rowsPerPage -
    Math.min(
      props.rowsPerPage,
      props.rowData.totalcount - props.page * props.rowsPerPage
    ); */

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={props.order}
              orderBy={props.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.rowData.totalcount}
            />
            <TableBody>
              {
                props.rowData && props.rowData.data ?
                  props.rowData.data.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        className={classes.tableRow}
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        <TableCell align="left" className={classes.tableCellSmall}>
                          {moment(row.updatedAt).format('DD MMM YYYY hh:mm A')}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCellSmall}>
                          {row.userType === "consumer" ? "Consumer" : "Business"}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCellSmall} style={{ color: row.otpVerified ? 'green' : 'red' }}>
                          {row.otpVerified ? "Verified" : "Not Verified"}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCellSmall}>
                          {row.mobileNumber}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCellSmall}>
                          {row.otp}
                        </TableCell>
                      </TableRow>
                    );
                  }) : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          component="div"
          count={props.rowData.totalcount}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
