import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import Backdrop from '@material-ui/core/Backdrop';
// import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import { changeHeaderName } from '../../actions/dashboardActions';
import { requestBusinesses, getBusinessPerformanceReport } from '../../actions/businessActions';
import BusinessPerformanceReportTable from './BusinessPerformanceReportTable';
import _ from 'lodash';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    content: {
        flexGrow: 1,
        height: '100%',
        // overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    }
});

function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
        days.push(
            moment(weekStart)
                .add(i, 'days')
                .toDate()
        );
    }
    return days;
}

function getWeekRange(date) {
    return {
        from: moment(date)
            .startOf('week')
            .toDate(),
        to: moment(date)
            .endOf('week')
            .toDate(),
    };
}

class UniqueCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: -1,
            orderBy: '_id',
            page: 0,
            rowsPerPage: 10,
            filterStartWeek: [],
            filterEndWeek: [],
            timeLine: [],
            businessNameId: "",
            selectedDate: "",
            setSelectedDate: "",
            fromDate: "",
            toDate: "",
            startDate:"",
            endDate:"",
            showDownloadIcon: false,
            error: {},
        };
    }

    componentDidMount() {
        this.props.changeHeaderName("User/Order Growth");
    }

    fetchBusinessPerformanceReports = async () => {
        this.props.requestBusinesses();

        let fromDate;
        let toDate;

        if (this.state.timeLine === "Daily" || this.state.timeLine === "Monthly" || this.state.timeLine === "Yearly") {
            fromDate = moment(this.state.fromDate, "YYYY/MM/DD").toDate()
        }
        if (this.state.timeLine === "Weekly") {
            fromDate = this.state.filterStartWeek[0]
        }
        if (this.state.timeLine === "Daily") {
            toDate = moment(this.state.toDate, "YYYY/MM/DD").toDate()
        }
        if (this.state.timeLine === "Monthly") {
            toDate = moment(this.state.toDate, "YYYY/MM/DD").endOf('month')._d
        }
        if (this.state.timeLine === "Yearly") {
            toDate = moment(this.state.toDate, "YYYY/MM/DD").endOf('year')._d
        }
        if (this.state.timeLine === "Weekly") {
            toDate = this.state.filterEndWeek[this.state.filterEndWeek.length - 1]
        }
       
        await this.validate();

        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            BusinessFilter: this.state.businessNameId,
            timeline: this.state.timeLine,
            toDate: toDate,
            fromDate: fromDate
        }
       
        if (!(this.state.error.businessNameId) && !(this.state.error.timeLine) && !(this.state.error.fromDate) &&  !(this.state.error.toDate) ) {
            await this.props.getBusinessPerformanceReport(payload);
            if (this.state.showDownloadIcon){
                this.setState({showDownloadIcon:false})
            }            
        }
    }

    setOrder = (order) => {
        this.setState({ order }, () => this.fetchBusinessPerformanceReports());
    }

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetchBusinessPerformanceReports());
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetchBusinessPerformanceReports());
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    }

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            toDate: "",
            fromDate: "",
            filterEndWeek: [],
            error: {},
        },
        );
    }

    handleStartWeekChange = date => {
        this.setState({
            filterStartWeek: getWeekDays(getWeekRange(date).from),
        });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    };

    handleStartWeekClick = (weekNumber, days, e) => {
        this.setState({
            filterStartWeek: days,
        });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    };

    handleEndWeekChange = date => {
        this.setState({
            filterEndWeek: getWeekDays(getWeekRange(date).from),
        });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    };

    handleEndWeekClick = (weekNumber, days, e) => {
        this.setState({
            filterEndWeek: days,
        });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    };



    validate = async () => {
        let error = this.state.error;
        if (this.state.businessNameId.trim() === "") {
            error.businessNameId = "Required"
        }
        if(this.state.timeLine.length ===0) {
            error.timeLine="Required"
        }
        if (this.state.timeLine === "Weekly") {
            if (!this.state.filterStartWeek.length) {
                error.fromDate = "Required"
            }
            if (!this.state.filterEndWeek.length) {
                error.toDate = "Required"
            }

        } else {
            if (!this.state.fromDate) {
                error.fromDate = "Required"
            }
            if (!this.state.toDate) {
                error.toDate = "Required"

            }
        }
        if(!(this.state.startDate)){
            error.startDate="Required"
        }
        if(!(this.state.endDate)){
            error.endDate="Required"
        }
        await this.setState({ error })
    }

    handleFilterFromYearChange = (event) => {
        this.setState({ fromDate: event });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }
    handleFilterToYearChange = (event) => {
        this.setState({ toDate: event });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }
    handleFilterFromDateChange = (event) => {
        this.setState({ fromDate: event });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }
    handleFilterToDateChange = (event) => {
        this.setState({ toDate: event });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }
    setDownloadIcon = ()=>{
        this.setState({showDownloadIcon : true}) ;
    }
   
    handleFilterFromMonthChange = (event) => {
        this.setState({ fromDate: event });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }
    handleFilterToMonthChange = (event) => {
        this.setState({ toDate: event });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }

    resetFilter = () => {
        this.setState({
            order: -1,
            orderBy: 'requestsCount',
            page: 0,
            rowsPerPage: 10,
            businessNameId: "",
            timeLine: [],
            filterStartWeek: [],
            filterEndWeek: [],
            toDate: "",
            fromDate: "",
            selectedDate: "",
            setSelectedDate: "",
            error: {}
        });
        let payload = {
            reset: true
        };
        this.props.getBusinessPerformanceReport(payload);
    }

    render() {
        const { classes } = this.props;
        const timeLines = ["Daily", "Weekly", "Monthly", "Yearly"]

        const { filterStartWeek, filterEndWeek } = this.state;

        const startWeekDaysAreSelected = filterStartWeek.length > 0;
        const endWeekDaysAreSelected = filterEndWeek.length > 0;

        const startmodifiers = {
            selectedRange: startWeekDaysAreSelected && {
                from: filterStartWeek[0],
                to: filterStartWeek[6],
            },
            selectedRangeStart: startWeekDaysAreSelected && filterStartWeek[0],
            selectedRangeEnd: startWeekDaysAreSelected && filterStartWeek[6],
        };

        const endmodifiers = {
            selectedRange: endWeekDaysAreSelected && {
                from: filterEndWeek[0],
                to: filterEndWeek[6],
            },
            selectedRangeStart: endWeekDaysAreSelected && filterEndWeek[0],
            selectedRangeEnd: endWeekDaysAreSelected && filterEndWeek[6],
        };        
        return (
            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Search / Filter Report</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={3} style={{ alignItems: 'center' }}>
                                <Grid item xs={3}>
                                    <TextField
                                        required
                                        fullWidth margin="dense"
                                        name="businessNameId"
                                        label="Business Id/Business Name"
                                        value={this.state.businessNameId}
                                        variant="outlined"
                                        error={this.state.error.businessNameId}
                                        helperText={this.state.error.businessNameId}
                                        onChange={this.handleFilterChange}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth margin="dense" variant="outlined" >
                                        <InputLabel id="demo-simple-select-standard-label">Timeline *</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="timeLine"
                                            value={this.state.timeLine}
                                            name="timeLine"
                                            onChange={this.handleChange}
                                            MenuProps={MenuProps}
                                            label="Timline"
                                            className={classes.select}
                                        >
                                            {timeLines.map((timeLine) => {
                                                return (
                                                    <MenuItem value={timeLine} key={timeLine} style={{ minHeight: "28px", height: "28px" }}>
                                                        <ListItemText className={classes.listItemText} primary={timeLine} />
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    {this.state.error.timeLine ?
                                            <helperText style={{ color: "red" }}>Please Select Timeline </helperText> : null}
                                </Grid>
                                {this.state.timeLine === "Daily" ?
                                    <Grid item xs={3}>
                                        <label>Select From Date *</label>
                                        <DatePicker
                                            style={classes.picker}
                                            selected={this.state.fromDate}
                                            onChange={this.handleFilterFromDateChange}
                                            name="filterStartDate"
                                            dateFormat="dd/MM/yyyy"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className="form-control"
                                        />
                                        {this.state.error.fromDate ?
                                            <helperText style={{ color: "red" }}>Required </helperText> : null}
                                    </Grid>
                                    :
                                    this.state.timeLine === "Yearly" ?
                                        <Grid item xs={3}>
                                            <label>Select From Year *</label>
                                            <DatePicker
                                                style={classes.picker}
                                                selected={this.state.fromDate}
                                                onChange={this.handleFilterFromYearChange}
                                                name="filterStartYear"
                                                dateFormat="yyyy"
                                                showYearPicker
                                                className="form-control"
                                            />
                                            {this.state.error.fromDate ?
                                                <helperText style={{ color: "red" }}>Required </helperText> : null}
                                        </Grid>
                                        : this.state.timeLine === "Monthly" ?
                                            <Grid item xs={3}>
                                                <label>Select From Month *</label>
                                                <DatePicker
                                                    selected={this.state.fromDate}
                                                    onChange={this.handleFilterFromMonthChange}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                    className="form-control"

                                                />
                                                {this.state.error.fromDate ?
                                                    <helperText style={{ color: "red" }}>Required </helperText> : null}
                                            </Grid> : this.state.timeLine === "Weekly" ?
                                                <Grid item xs={3}>
                                                    <label>Select From Week *</label>
                                                    {filterStartWeek.length === 7 && (
                                                        <div>
                                                            {moment(filterStartWeek[0]).format('LL')} –{' '}
                                                            {moment(filterStartWeek[6]).format('LL')}
                                                        </div>
                                                    )}
                                                    <DayPicker
                                                        filterStartWeek={filterStartWeek}
                                                        modifiers={startmodifiers}
                                                        onDayClick={this.handleStartWeekChange}
                                                        onWeekClick={this.handleStartWeekClick}
                                                        className="form-control"
                                                    />
                                                    {this.state.error.fromDate ?
                                                        <helperText style={{ color: "red" }}>Required </helperText> : null}
                                                </Grid> : 
                                                <Grid item xs={3}>
                                                    <TextField fullWidth margin="dense" name="select start date" label="Select start date *" variant="outlined" />
                                                    {this.state.error.startDate ?
                                                        <helperText style={{ color: "red" }}>Required </helperText> : null}
                                                </Grid>
                                }
                                {this.state.timeLine === "Daily" ?
                                    <Grid item xs={3}>
                                        <label>Select To Date *</label>
                                        <DatePicker
                                            style={classes.picker}
                                            selected={this.state.toDate}
                                            onChange={this.handleFilterToDateChange}
                                            name="filterEndDate"
                                            dateFormat="dd/MM/yyyy"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className="form-control"
                                        />
                                        {this.state.error.toDate ?
                                            <helperText style={{ color: "red" }}>Required </helperText> : null}
                                    </Grid>
                                    : this.state.timeLine === "Yearly" ?
                                        <Grid item xs={3}>
                                            <label>Select To Year *</label>
                                            <DatePicker
                                                selected={this.state.toDate}
                                                onChange={this.handleFilterToYearChange}
                                                name="filterEndYear"
                                                dateFormat="yyyy"
                                                showYearPicker
                                                className="form-control"
                                            />
                                            {this.state.error.toDate ?
                                                <helperText style={{ color: "red" }}>Required </helperText> : null}
                                        </Grid>
                                        : this.state.timeLine === "Monthly" ?
                                            <Grid item xs={3}>
                                                <label>Select To Month *</label>
                                                <DatePicker
                                                    selected={this.state.toDate}
                                                    onChange={this.handleFilterToMonthChange}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                    className="form-control"
                                                />
                                                {this.state.error.toDate ?
                                                    <helperText style={{ color: "red" }}>Required </helperText> : null}
                                            </Grid>
                                            : this.state.timeLine === "Weekly" ?
                                                <Grid item xs={3}>
                                                    <label>Select To Week *</label>
                                                    {filterEndWeek.length === 7 && (
                                                        <div>
                                                            {moment(filterEndWeek[0]).format('LL')} –{' '}
                                                            {moment(filterEndWeek[6]).format('LL')}
                                                        </div>
                                                    )}
                                                    <DayPicker
                                                        filterEndWeek={filterEndWeek}
                                                        modifiers={endmodifiers}
                                                        onDayClick={this.handleEndWeekChange}
                                                        onWeekClick={this.handleEndWeekClick}
                                                        className="form-control"
                                                    />
                                                    {this.state.error.toDate ?
                                                        <helperText style={{ color: "red" }}>Required </helperText> : null}
                                                </Grid>
                                                :
                                                <Grid item xs={3}>
                                                    <TextField fullWidth margin="dense" name="select end date" label="Select end date *" variant="outlined" />
                                                    {this.state.error.endDate ?
                                                        <helperText style={{ color: "red" }}>Required </helperText> : null}
                                                </Grid>
                                }
                                <Grid item xs={8}>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        onClick={
                                            ()=>this.setPage(0)
                                            //this.fetchBusinessPerformanceReports
                                        }
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                <Container className={classes.container}>
                    {
                        this.props.businessPerformanceReport && this.props.businessPerformanceReport.report && this.props.businessPerformanceReport.report.length > 0 ?
                            <BusinessPerformanceReportTable
                                data={this.props.businessPerformanceReport}
                                order={this.state.order === 1 ? 'asc' : 'desc'}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                               
                                showDownloadIcon={this.state.showDownloadIcon}
                                setDownloadIcon={this.setDownloadIcon}
                            />
                            : null
                    }
                </Container>
                {/* <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop> */}
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        businessPerformanceReport: state.business.businessPerformanceReport,
        
    }
};

export default connect(mapStateToProps, {
    changeHeaderName,
    requestBusinesses,
    getBusinessPerformanceReport
})(withStyles(styles)(UniqueCustomer));