  
import axios from "axios";

const setAuthToken = token => {
  if (token) {
    // Apply authorization token to every request if logged in
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
    axios.defaults.headers['Access-Control-Allow-Origin'] = "*";
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;