import React from 'react';
import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import NativeSelect from '@material-ui/core/NativeSelect';

const styles = theme => ({
    form: {
        width: '100%',
        height: '100%',
    },
    header: {
        height: '10%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '70%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    divider: {
        width: '100%',
        margin: '0px'
    }
});

function addList  (selectedArea,areas){
    let area = areas;
    let areaManager=[];
    for (let i=0; i<area.length;i++){
      
      if(selectedArea === area[i]._id ){
           for(let j=0; j<area[i].areaManagersData.length;j++){
            
            if(area[i].areaManagersData[j].isActive === true){
                 areaManager.push(area[i].areaManagersData[j]);
            }              
           }
        }
    }
  return areaManager
}

class ContractForm extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedArea: "",
            areaManagerNameList:[],
            selectedAreaManger:"",
        };
    }

    onSubmit = formValues => {
        //  console.log("......this .props in on submit",this.props);
        if(this.props.selectedBusiness.accountManagerDetails === undefined ) { 
                this.setState({accountManagerError:false})
        } else if(this.props.selectedBusiness.accountManagerDetails.accountManager === this.state.selectedAreaManger){
            this.setState({accountManagerError:true})
        }
        if(this.state.accountManagerError === false){
        if (this.state.selectedArea && this.state.selectedAreaManger) {
            formValues.businessId = this.props.selectedBusiness._id;
            formValues.areaBelongsTo = this.state.selectedArea;
            formValues.accountManager = this.state.selectedAreaManger;           
            this.props.onSubmit(formValues)
        }}
    
    }
    handleAreaChange = (event) => {      
        this.setState({
            selectedArea: event.target.value,
            selectedAreaManger: "",
            accountManagerError:false
        })
    }
        

   
    
    handleAreaMangerChange = (event) => {
        this.setState({
                    selectedAreaManger: event.target.value,
                    accountManagerError:false             
               })
        let previousData = "";
        let currentData = "";
     previousData = (this.props.selectedBusiness.accountManagerDetails && this.props.selectedBusiness.accountManagerDetails !=null) ?
     this.props.selectedBusiness.accountManagerDetails.accountManager :"";
      currentData = event.target.value;
      previousData === currentData ?
         this.setState({accountManagerError:true})
        :           this.setState({accountManagerError:false})

        if(this.props.selectedBusiness.areaBelongsTo && this.props.selectedBusiness.areaBelongsTo.name){
            // console.log("...p",this.props.selectedBusiness.areaBelongsTo._id);
            // console.log("....s",this.state.selectedArea);
        if(this.props.selectedBusiness.areaBelongsTo._id !== this.state.selectedArea) 
          this.setState({accountManagerError:false}) 
        }
    }

    /* static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.selectedBusiness && nextProps.selectedBusiness.areaBelongsTo) {
            return { selectedArea: nextProps.selectedBusiness.areaBelongsTo._id };
        }
    } */

    componentDidMount() {
        // console.log("assignareaform props---->", this.props)
        if (this.props.selectedBusiness.areaBelongsTo && this.props.selectedBusiness.areaBelongsTo.name) {
            this.setState({
                selectedArea: this.props.selectedBusiness.areaBelongsTo._id
            })
        }
        if(this.props.selectedBusiness.userIsActive){
        if (this.props.selectedBusiness.accountManagerDetails && this.props.selectedBusiness.accountManagerDetails !=null) {
            // let accountManagerLength =  this.props.selectedBusiness.accountManagerDetails.length
            this.setState({
                selectedAreaManger: this.props.selectedBusiness.accountManagerDetails.accountManager
            })
        }
    } else{
        this.setState({
            selectedAreaManger: ""
        })
    }
    
    
    }


    render() {
        const { classes } = this.props;
        let accountManagerCall=[];
        if(this.state.selectedArea){
            accountManagerCall = addList(this.state.selectedArea ,this.props.areas);
        }
        // console.log("=====this.state[[[",this.state);
        // console.log("....this.props....",this.props);
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <Grid container className={classes.header} spacing={1}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom>
                            Assign Area
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
                    </Grid>
                </Grid>
                <Container className={classes.content}>
                    {/* <div className={classes.root}> */}
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                Business Details
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                Shop Name
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {this.props.selectedBusiness.businessName}
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                Business Id
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {this.props.selectedBusiness.businessId}
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                Shop Number
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {this.props.selectedBusiness.address.shopNo}
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                Category Type
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {this.props.selectedBusiness.categoryType.name}
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                Street Name
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {this.props.selectedBusiness.address.street}
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                Category
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {this.props.selectedBusiness.category.name}
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                Landmark
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {this.props.selectedBusiness.address.landmark}
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                Owner Name
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {this.props.selectedBusiness.ownerId.firstName + " " + this.props.selectedBusiness.ownerId.lastName}
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                State
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {this.props.selectedBusiness.address.state}
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                Contact Number
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {this.props.selectedBusiness.ownerId.primaryMobileNumber}
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                Pin Code
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {this.props.selectedBusiness.address.pinCode}
                        </Grid>

                        <Divider variant="middle" className={classes.divider} />

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                Assign Area
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                Region
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {this.props.selectedBusiness.regionBelongsTo.name}
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                Region Code
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {this.props.selectedBusiness.regionBelongsTo.regionCode}
                        </Grid>
                       
                        </Grid>
                        <Grid container spacing={2}>
                        <Grid item xs={6}>                                           
                            <FormControl  fullWidth>
                                <InputLabel shrink htmlFor="age-native-label-placeholder">
                               <b> Select Area </b>                        
                                </InputLabel>
                                <NativeSelect
                                    value={this.state.selectedArea}
                                    onChange={this.handleAreaChange}
                                    inputProps={{
                                        name: ' Select Area',
                                        id: 'age-native-label-placeholder',
                                    }}
                                >
                                   
                                    {this.state.selectedArea ? this.state.selectedArea : <option value=""></option>}
                                    {
                                        this.props.areas && this.props.areas.length > 0 &&
                                        this.props.areas.map((area, i) => {
                                           
                                            return (
                                                <option  name={area.name} value={area._id}>{area.name}</option>
                                            )
                                        })
                                    }                                  
                                </NativeSelect>
                                {
                                    !this.state.selectedArea ? <FormHelperText error>Required</FormHelperText> : null
                                }                               
                            </FormControl>                           
                        </Grid>
                        <Grid item xs={6}>
                       
                        {
                            this.state.selectedArea === "" ?                        

                            <FormControl disabled="true" fullWidth>
                                <InputLabel shrink htmlFor="age-native-label-placeholder1">
                                <b>Account Manager </b>
                                </InputLabel>     
                                <NativeSelect
                              value={this.state.selectedAreaManger}
                              onChange={this.handleAreaMangerChange}
                              inputProps={{
                                  name: ' Account Manager',
                                  id: 'age-native-label-placeholder1',
                              }}
                          >
                            
                              {this.state.selectedAreaManger ? this.state.selectedAreaManger :
                                 <option value=""></option>}
                              {
                                 accountManagerCall && accountManagerCall.length>0 
                                 && accountManagerCall.map((accountManager)=>{
                                    return  <option name={accountManager.username} value={accountManager._id}>{accountManager.firstName}{" "}{accountManager.lastName}{"("}{accountManager.username}{")"}</option>
                                 })
                                     
                              }                                  
                          </NativeSelect>                   
                            
                            </FormControl>
                          :
                          <FormControl  fullWidth>
                          <InputLabel shrink htmlFor="age-native-label-placeholder1">
                          <b>Account Manager </b>
                          </InputLabel>
                          <NativeSelect
                              value={this.state.selectedAreaManger}
                              onChange={this.handleAreaMangerChange}
                              inputProps={{
                                  name: ' Account Manager',
                                  id: 'age-native-label-placeholder1',
                              }}
                          >
                            
                              {this.state.selectedAreaManger ? this.state.selectedAreaManger :
                                 <option value=""></option>}
                              {
                                 accountManagerCall && accountManagerCall.length>0 
                                 && accountManagerCall.map((accountManager)=>{
                                    return  <option name={accountManager.username} value={accountManager._id}>{accountManager.firstName}{" "}{accountManager.lastName}{"("}{accountManager.username}{")"}</option>
                                 })
                                     
                              }                                  
                          </NativeSelect>
                          {
                              (this.state.selectedArea && !this.state.selectedAreaManger) ? <FormHelperText error>Required</FormHelperText> : null
                          }
                           {
                              (this.state.accountManagerError) ? <FormHelperText error>It is an existing Account Manager</FormHelperText> : null
                          }
                                                         
                      </FormControl>
                       }    
                        </Grid>
                        
                    </Grid>
                </Container>
                <Container className={classes.footer}>
                    <div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            margin="normal"
                            color="primary">
                            Submit
                        </Button>
                    </div>
                </Container>
            </form>
        );
    }
}

const validate = values => {
    const errors = {}

    const requiredFields = [

    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'ContractForm',
    validate
})(withStyles(styles)(ContractForm))
