import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { lightBlue } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createAddon, updateAddon } from '../../../../actions/addonsAction';
import { getCategoryTree } from "../../../../actions/categoriesActions";
import { ObjectID } from 'bson';
import _ from 'lodash';
import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const styles = theme => ({
    Checkbox: {
        fontSize: 20
    },
    grid: {
        width: '100%',
        //display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        paddingLeft: "15px",
        paddingRight: "15px",
        margin: '0px 0px',

    },
    deleteBtn: {
        cursor: 'pointer',
        color: 'grey',
        //marginTop: "7px"
    },
    header: {
        height: '10%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: "22px",
        backgroundColor: theme.palette.primary.main,
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px'
    },
    modalContainer: {
        width: '100%',
        height: '100%',
        padding: '0px 0px',
        backgroundColor: theme.palette.background.paper,
        margin: '10px 0px',
        overflow: 'auto',
    },
    gridElement: {
        margin: '10px 5px'
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    icon: {
        color: 'white',
    },
    gridList: {
        width: '100%',
        height: '100%'
    },
    mainContainer: {
        width: '60%',
        height: '80%',
        backgroundColor: theme.palette.background.paper,
        padding: '0px 0px',

    },
    lightBlue: {
        color: theme.palette.getContrastText(lightBlue[50]),
        backgroundColor: lightBlue[50],
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    select: {
        height: '38px',
        // padding-right: '20px',
        // overflow: 'hidden',
        // display: 'flex',

    },
    listItemText: {
        'margin': '0px'
    },
    buttonGroup: {
        "height": "44px",
        "marginTop": "9px",
        "width": "0px"
    },
    buttonGroupTextFeild: {
        "height": "40px"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    newarrival: {
        borderRadius: "3px",
        alignSelf: 'center',
        paddingLeft: "10px",
        paddingRight: "10px",
        // paddingTop:"10px",
        // paddingBottom:"10px",
        marginRight: "10px",
        backgroundColor: "#FFA347",
        // fontColor: "#FFFFFF"
    },
    musttry: {
        borderRadius: "3px",
        alignSelf: 'center',
        paddingLeft: "10px",
        paddingRight: "10px",
        // paddingTop:"10px",
        // paddingBottom:"10px",
        marginRight: "10px",
        // backgroundColor: "#FF4767",
        backgroundColor: "#FF8197",
        // fontColor: "#FFFFFF"
    },
    bestseller: {
        borderRadius: "3px",
        alignSelf: 'center',
        paddingLeft: "10px",
        paddingRight: "10px",
        // paddingTop:"10px",
        // paddingBottom:"10px",
        marginRight: "10px",
        backgroundColor: "#AF7BFF",
        // fontColor: "#FFFFFF"
    }
});

const WhiteTextTypography = withStyles({
    root: {
        color: "#FFFFFF"
    }
})(Typography);

function timeCheck(from, to) {
    let a = from;
    let b = to;
    let fromCheck = a.split(":");
    let toCheck = b.split(":");
    let f = new Date(parseInt("2001", 10), (parseInt("01", 10)) - 1, parseInt("01", 10), parseInt(fromCheck[0], 10), parseInt(fromCheck[1], 10));
    let t = new Date(parseInt("2001", 10), (parseInt("01", 10)) - 1, parseInt("01", 10), parseInt(toCheck[0], 10), parseInt(toCheck[1], 10));
    let df = f.valueOf();
    let dt = t.valueOf();

    if (df < dt)
        return false;
    // {console.log("b is greater");}
    else
        return true;
    // console.log("a is greater");
};

function convertTime12to24(time12h) {
    let convertedTime = moment(time12h, 'hh:mm A').format('HH:mm');
    return convertedTime;
};

class ManageCreateUpdateAddon extends Component {
    constructor() {
        super();
        this.state = {
            serviceName: "",
            sequenceNo: 1,
            availability: true,
            isEnabled: true,
            isDisplay: true,
            description: "",
            petPoojaItemId: "",
            petPoojaVariationId: "",
            showFoodType: null,
            foodType: "NA",
            _id: null,
            costType: "",
            amountFrom: null,
            amountTo: null,
            amount: null,
            unit: "",
            quantity: "",
            gstConf: null,
            allowLooseQuantity: false,
            contact: null,
            photos: [],
            modalDeleteOpen: false,
            selectedPhoto: null,
            Holidays: [],
            workingDays: [],
            days: [
                { index: 0, day: "Monday" }, { index: 1, day: "Tuesday" }, { index: 2, day: "Wednesday" }, { index: 3, day: "Thursday" }, { index: 4, day: "Friday" }, { index: 5, day: "Saturday" }, { index: 6, day: "Sunday" }
            ],
            selectedHead: {},
            copyBusinessTiming: false,
            error: {},
            gstError: null,
            subcategory: null,
            isUploading: false,
            discountMappingArray: [],
            selectedDiscount: "NO",
            holidaysRequiredError: [],
            dayRequiredError: [],
            holidaysError: [],
            fromRequiredError: [],
            toRequiredError: [],
            fromError: [],
            toError: [],
            fromTimeOverlap: [],
            toTimeOverlap: [],
            invalidTime: [],
            holidayWorkingDayError: [],
            from: [
                { index: 0, from: "12:00 am" }, { index: 1, from: "12:30 am" }, { index: 2, from: "01:00 am" }, { index: 3, from: "01:30 am" },
                { index: 4, from: "02:00 am" }, { index: 5, from: "02:30 am" }, { index: 6, from: "03:00 am" }, { index: 7, from: "03:30 am" },
                { index: 8, from: "04:00 am" }, { index: 9, from: "04:30 am" }, { index: 10, from: "05:00 am" }, { index: 11, from: "05:30 am" },
                { index: 12, from: "06:00 am" }, { index: 13, from: "06:30 am" }, { index: 14, from: "07:00 am" }, { index: 15, from: "07:30 am" },
                { index: 16, from: "08:00 am" }, { index: 17, from: "08:30 am" }, { index: 18, from: "09:00 am" }, { index: 19, from: "09:30 am" },
                { index: 20, from: "10:00 am" }, { index: 21, from: "10:30 am" }, { index: 22, from: "11:00 am" }, { index: 23, from: "11:30 am" },
                { index: 24, from: "12:00 pm" }, { index: 25, from: "12:30 pm" }, { index: 26, from: "01:00 pm" }, { index: 27, from: "01:30 pm" },
                { index: 28, from: "02:00 pm" }, { index: 29, from: "02:30 pm" }, { index: 30, from: "03:00 pm" }, { index: 31, from: "03:30 pm" },
                { index: 32, from: "04:00 pm" }, { index: 33, from: "04:30 pm" }, { index: 34, from: "05:00 pm" }, { index: 35, from: "05:30 pm" },
                { index: 36, from: "06:00 pm" }, { index: 37, from: "06:30 pm" }, { index: 38, from: "07:00 pm" }, { index: 39, from: "07:30 pm" },
                { index: 40, from: "08:00 pm" }, { index: 41, from: "08:30 pm" }, { index: 42, from: "09:00 pm" }, { index: 43, from: "09:30 pm" },
                { index: 44, from: "10:00 pm" }, { index: 45, from: "10:30 pm" }, { index: 46, from: "11:00 pm" }, { index: 47, from: "11:30 pm" },
            ],
            to: [
                { index: 0, from: "12:00 am" }, { index: 1, from: "12:30 am" }, { index: 2, from: "01:00 am" }, { index: 3, from: "01:30 am" },
                { index: 4, from: "02:00 am" }, { index: 5, from: "02:30 am" }, { index: 6, from: "03:00 am" }, { index: 7, from: "03:30 am" },
                { index: 8, from: "04:00 am" }, { index: 9, from: "04:30 am" }, { index: 10, from: "05:00 am" }, { index: 11, from: "05:30 am" },
                { index: 12, from: "06:00 am" }, { index: 13, from: "06:30 am" }, { index: 14, from: "07:00 am" }, { index: 15, from: "07:30 am" },
                { index: 16, from: "08:00 am" }, { index: 17, from: "08:30 am" }, { index: 18, from: "09:00 am" }, { index: 19, from: "09:30 am" },
                { index: 20, from: "10:00 am" }, { index: 21, from: "10:30 am" }, { index: 22, from: "11:00 am" }, { index: 23, from: "11:30 am" },
                { index: 24, from: "12:00 pm" }, { index: 25, from: "12:30 pm" }, { index: 26, from: "01:00 pm" }, { index: 27, from: "01:30 pm" },
                { index: 28, from: "02:00 pm" }, { index: 29, from: "02:30 pm" }, { index: 30, from: "03:00 pm" }, { index: 31, from: "03:30 pm" },
                { index: 32, from: "04:00 pm" }, { index: 33, from: "04:30 pm" }, { index: 34, from: "05:00 pm" }, { index: 35, from: "05:30 pm" },
                { index: 36, from: "06:00 pm" }, { index: 37, from: "06:30 pm" }, { index: 38, from: "07:00 pm" }, { index: 39, from: "07:30 pm" },
                { index: 40, from: "08:00 pm" }, { index: 41, from: "08:30 pm" }, { index: 42, from: "09:00 pm" }, { index: 43, from: "09:30 pm" },
                { index: 44, from: "10:00 pm" }, { index: 45, from: "10:30 pm" }, { index: 46, from: "11:00 pm" }, { index: 47, from: "11:30 pm" },
                { index: 48, from: "11:59 pm" }
            ],
            servicesPackagingCharges: null,
            newArrival: false,
            mustTry: false,
            excludeItemTags: false,
            descriptionError: false
        };
        this.inputReference = React.createRef();
    }

    fileUploadAction = () => this.inputReference.current.click();


    handleChange = (event) => {
		if (event.target.name === "description") { this.setState({ [event.target.name]: event.target.value, descriptionError: false}); }
        this.setState({ [event.target.name]: event.target.value });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    };

    handleGSTChange = (event) => {
        if (event.target.value === '') {
            this.setState({ gstConf: null, gstError: null });
        } else {
            this.setState({ gstConf: { gst: event.target.value } });
            if (!this.props.businessDetails.gstin) {
                this.setState({ gstError: 'Please add GST No. in business settings to enable GST on items' });
            }
        }
    };

    hasGstFields = () => {
        return this.state.gstConf.gst != null || this.state.gstConf.sgst != null || this.state.gstConf.cgst != null || this.state.gstConf.igst != null
    };

    handleGSTComponentChange = (event) => {
        this.setState({ gstConf: { ...this.state.gstConf, [event.target.name]: event.target.value } });
    };


    handleSwitchChange = (event) => {
        this.setState({
            availability: event.target.checked
        })
    };

    handleIsUnableSwitchChange = (event) => {
        this.setState({
            isEnabled: event.target.checked
        })
    };
    handleIsDisplayChange = (event) => {
        this.setState({
            isDisplay: event.target.checked
        })
    };
    handleIsNewArrivalSwitchChange = (event) => {
        this.setState({ newArrival: event.target.checked });
    };

    handleIsMustTrySwitchChange = (event) => {
        this.setState({ mustTry: event.target.checked });
    };

    handleExcludeItemTags = (event) => {
        this.setState({ excludeItemTags: event.target.checked });
    };

    handleCheck = (event) => {
        this.setState({ allowLooseQuantity: event.target.checked })
    };

    handleChangeSubCategory = async (event) => {
        let subcategory = await this.props.categories.categories.find(subCategory => subCategory._id === event.target.value)
        this.setState({
            subcategory: subcategory._id.toString(),
            showFoodType: subcategory.foodType
        });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    };




    addDiscountMappingArrayItem = () => {
        let discountMappingArray = this.state.discountMappingArray;
        let object = {
            discountType: "PERCENTAGE",
            off: null,
            maxDiscountCap: null,
            isActive: false,
            id: discountMappingArray.length + 1

        }
        discountMappingArray.push(object)
        this.setState({ discountMappingArray, error: {} })
    };

    deleteDiscountMappingArrayItem = (index) => {
        let discountMappingArray = this.state.discountMappingArray;
        discountMappingArray.splice(index, 1);
        this.setState({ discountMappingArray, error: {} })
        let selectedDiscount = this.state.selectedDiscount;
        if (index === selectedDiscount) {
            this.setState({ selectedDiscount: "NO" })
        } else if (index <= selectedDiscount) {
            this.setState({ selectedDiscount: --selectedDiscount })
        }
    };

    handleDiscountChange = (event, index) => {
        let discountMappingArray = this.state.discountMappingArray;
        discountMappingArray[index][event.target.name] = event.target.value;
        this.setState({ discountMappingArray, error: {} })
    };

    handleDiscountChangeRadio = (event, index) => {
        let discountMappingArray = this.state.discountMappingArray;
        let selectedDiscount = index
        let updateDiscountMappingArray = discountMappingArray.map((item, indexNo) => {
            if (index === indexNo) {
                item.isActive = true
            } else {
                item.isActive = false
            }
            return item
        })
        this.setState({ error: {}, selectedDiscount, discountMappingArray: updateDiscountMappingArray })
    };

    deleteHoliday = (value) => {
        let holidays = this.state.Holidays;
        let holidaysRequiredError = this.state.holidaysRequiredError;
        let holidaysError = this.state.holidaysError;
        let holidayWorkingDayError = this.state.holidayWorkingDayError;

        let index = holidays.indexOf(value);

        holidays.splice(index, 1);
        holidaysRequiredError.splice(index, 1);
        holidaysError.splice(index, 1);
        holidayWorkingDayError.splice(index, 1);

        this.setState({ holidays, holidaysRequiredError, holidaysError, holidayWorkingDayError })
    };

    deleteWorkingHrs = (value) => {
        let workingDays = this.state.workingDays;
        let dayRequiredError = this.state.dayRequiredError;
        let fromRequiredError = this.state.fromRequiredError;
        let toRequiredError = this.state.toRequiredError;
        let fromError = this.state.fromError;
        let toError = this.state.toError;
        let invalidTime = this.state.invalidTime;
        let fromTimeOverlap = this.state.fromTimeOverlap;
        let toTimeOverlap = this.state.toTimeOverlap;


        let index = workingDays.indexOf(value)
        workingDays = workingDays.filter((link) => link._id !== value._id);
        delete dayRequiredError[index];
        delete fromRequiredError[index];
        delete toRequiredError[index];
        delete fromError[index];
        delete toError[index];
        delete invalidTime[index];
        delete fromTimeOverlap[index];
        delete toTimeOverlap[index];

        this.setState({ workingDays, dayRequiredError, toRequiredError, fromRequiredError, toError, fromError, invalidTime, fromTimeOverlap, toTimeOverlap })
    };

    validate = async () => {
        let error = this.state.error
        let decimalValueRegex = /^[1-9]\d*(\.\d+)?$/;
        var regExcheckAmount;
        var regExcheckFromAmount;
        var regExcheckToAmount;
        var discountMappingArrayError = [];
        // var discountError = [];
        // var flatError = [];
        if (!this.state.serviceName || this.state.serviceName.trim() === "") {
            error.serviceName = "Required"
        }
        if (this.state.costType === "Fixed") {
            if (!this.state.amount) {
                error.amount = "Please Enter Valid Amount"
            }
            if (!this.state.unit) {
                error.unit = "Please Select Unit"
            }
            regExcheckAmount = await decimalValueRegex.test(this.state.amount);
            if (!regExcheckAmount) {
                error.amount = "Please Enter valid Amount"
            }
        }
        if (this.state.costType === "Approx") {

            if (!this.state.amountFrom) {
                error.amountFrom = "Please Enter Valid Amount"
            }
            if (!this.state.amountTo) {
                error.amountTo = "Please Enter Valid Amount"
            }
            if (!this.state.unit) {
                error.unit = "Please Select Unit"
            }
            if (this.state.amountFrom && this.state.amountTo) {
                if (Number(this.state.amountFrom) >= Number(this.state.amountTo)) {
                    error.amountFrom = "Invalid Amount Range"
                    error.amountTo = "Invalid Amount Range"
                }
                regExcheckFromAmount = await decimalValueRegex.test(this.state.amountFrom);
                regExcheckToAmount = await decimalValueRegex.test(this.state.amountTo);
                if (!regExcheckFromAmount) {
                    error.amountFrom = "Please Enter valid Amount"
                }
                if (!regExcheckToAmount) {
                    error.amountTo = "Please Enter valid Amount"
                }
            }
        }
        if (!this.state.costType) {
            if (!this.state.unit) {
                error.costType = "Please Select Cost Type"
            }
        }

    

        if (!this.state.subcategory && (this.props.categories.categories && this.props.categories.categories.length > 0)) {
            error.subcategory = "Please Select Sub-Category"
        }
        if (this.state.discountMappingArray.length > 0) {
            // var regExcheckOffAmount;
            // var regExcheckMaxAmount;
            this.state.discountMappingArray.map(async (item, index) => {
                if (!item.off || item.off === null || item.off === "") {
                    discountMappingArrayError[index] = "Please Enter valid Amount"
                }
                else if (item.discountType === 'PERCENTAGE' && Number(item.off) >= 100) {
                    discountMappingArrayError[index] = "Please Enter valid Amount"
                }
                else if (item.discountType === 'FLAT' && Number(item.off) > Number(this.state.amount)) {
                    discountMappingArrayError[index] = "Please Enter valid Amount"
                }
                else {
                    discountMappingArrayError[index] = ""
                }
            })
            error.discountMappingArrayError = discountMappingArrayError;

        }

        if ((this.state.servicesPackagingCharges)) {
            if (!(/^(\d{1,2})(\.\d{1,2})?$/.test(this.state.servicesPackagingCharges))) {
                error.servicesPackagingCharges = "Please enter valid Item Packaging Charges "
            }
        }

        this.setState({ error })
    };

    addAddon = async () => {
        // console.log(this.state)
        if (this.state._id) {
            this.generateId();
        }
        let hasError = false;
        let holidaysRequiredError;
        let holidaysError;
        let fromRequiredError;
        let toRequiredError;
        let fromError;
        let toError;
        let invalidTime;
        let dayRequiredError;
        let holidayWorkingDayError;
        let fromTimeOverlap;
        let toTimeOverlap;
        let convertedFrom;
        let convertedTo;
        let invalidToTimeCheck;
        let holidays = [];
        // let from = [];
        // let to = [];
        // var variable;
        // var string = "";
        let time = {}
        let wrkhrs = [];
        this.state.Holidays.map((holiday) => {
            holidays.push(holiday.day)
        })
        if (this.state.Holidays.length > 0) {
            for (let k = 0; k < this.state.Holidays.length; k++) {
                //for required
                if (this.state.Holidays[k].day.length === 0) {
                    hasError = true;
                    holidaysRequiredError = this.state.holidaysRequiredError;
                    holidaysRequiredError[k] = true;
                    this.setState({ holidaysRequiredError })
                }

                //to check duplicate holiday
                for (let j = k + 1; j < this.state.Holidays.length; j++) {
                    if (this.state.Holidays[k].day === this.state.Holidays[j].day) {
                        hasError = true;
                        holidaysError = this.state.holidaysError;
                        holidaysError[j] = true; holidaysError[k] = true;
                        this.setState({ holidaysError });
                    }
                }
            }
        }
        //gst validations
        // if (this.state.costType === 'Fixed' && this.state.gstConf && this.state.gstConf.gst !== null) {

        //     if (this.state.gstConf && !this.props.businessDetails.gstin) {
        //         this.setState({ gstError: 'Please add GST No. in business settings to enable GST on items' });
        //         hasError = true;
        //     } else if ((this.state.gstConf.igst !== null && this.state.gstConf.igst !== 0) &&
        //         ((this.state.gstConf.cgst !== null && this.state.gstConf.cgst !== 0) || (this.state.gstConf.sgst !== null && this.state.gstConf.sgst !== 0))) {
        //         this.setState({ gstError: 'Either IGST or CGST & SGST should be entered' });
        //         hasError = true;
        //     } else if ((this.state.gstConf.igst !== null & this.state.gstConf.igst !== 0) && this.state.gstConf.igst !== this.state.gstConf.gst) {
        //         this.setState({ gstError: 'IGST should be same as GST' });
        //         hasError = true;
        //     } else if ((this.state.gstConf.igst === null || this.state.gstConf.igst === 0) && (parseFloat(this.state.gstConf.sgst) + parseFloat(this.state.gstConf.cgst)) !== this.state.gstConf.gst) {
        //         this.setState({ gstError: 'Total GST should be equal to summation of SGST and CGST' });
        //         hasError = true;
        //     }
        // }
        if (this.state.costType === 'Fixed' && this.state.gstConf && this.state.gstConf.gst !== null) {

            if (this.state.gstConf && !this.props.businessDetails.gstin) {
                this.setState({ gstError: 'Please add GST No. in business settings to enable GST on items' });
                hasError = true;
            } else if ((this.state.gstConf.igst) && ((this.state.gstConf.cgst) || (this.state.gstConf.sgst))) {
                this.setState({ gstError: 'Either IGST or CGST & SGST should be entered' });
                hasError = true;
            } else if (this.state.gstConf.igst && parseFloat(this.state.gstConf.igst) !== parseFloat(this.state.gstConf.gst)) {
                this.setState({ gstError: 'IGST should be same as GST' });
                hasError = true;
            } else if (!this.state.gstConf.igst && (parseFloat(this.state.gstConf.sgst) + parseFloat(this.state.gstConf.cgst)) !== parseFloat(this.state.gstConf.gst)) {
                this.setState({ gstError: 'Total GST should be equal to summation of SGST and CGST' });
                hasError = true;
            }
        }
        if (this.state.workingDays && this.state.workingDays.length > 0) {
            for (let i = 0; i < this.state.workingDays.length; i++) {
                // for required error
                if (this.state.workingDays[i].day.length === 0) { hasError = true; dayRequiredError = this.state.dayRequiredError; dayRequiredError[i] = true; this.setState({ dayRequiredError }); }
                if (this.state.workingDays[i].from.length === 0) { hasError = true; fromRequiredError = this.state.fromRequiredError; fromRequiredError[i] = true; this.setState({ fromRequiredError }); }
                if (this.state.workingDays[i].to.length === 0) { hasError = true; toRequiredError = this.state.toRequiredError; toRequiredError[i] = true; this.setState({ toRequiredError }); }

                // to check for invalid timing (to should be immediate future time)
                if (this.state.workingDays[i].to.length > 0 && this.state.workingDays[i].from.length > 0) {
                    convertedFrom = convertTime12to24(this.state.workingDays[i].from);
                    convertedTo = convertTime12to24(this.state.workingDays[i].to);
                    invalidToTimeCheck = timeCheck(convertedFrom, convertedTo); //whenevr a is greater, show to time is invalid value
                    if (invalidToTimeCheck) { hasError = true; invalidTime = this.state.invalidTime; invalidTime[i] = true; this.setState({ invalidTime }); }
                }



                //to check duplicate from and to timings
                for (let j = i + 1; j < this.state.workingDays.length; j++) {
                    if (this.state.workingDays[i].day === this.state.workingDays[j].day) {
                        //to check duplicate from time
                        if (this.state.workingDays[i].from === this.state.workingDays[j].from) { hasError = true; fromError = this.state.fromError; fromError[j] = true; fromError[i] = true; this.setState({ fromError }); }

                        //to check duplicate to time
                        if (this.state.workingDays[i].to === this.state.workingDays[j].to) { hasError = true; toError = this.state.toError; toError[j] = true; toError[i] = true; this.setState({ toError }); }
                    }
                }

                //to check if working day and holiday is duplicate
                if (this.state.Holidays.length > 0) {
                    for (let k = 0; k < this.state.Holidays.length; k++) {
                        if (this.state.workingDays[i].day === this.state.Holidays[k].day) { hasError = true; holidayWorkingDayError = this.state.holidayWorkingDayError; holidayWorkingDayError[k] = true; this.setState({ holidayWorkingDayError }); }
                    }
                }

            }

        }
        //to check for overlapping of time
        if (this.state.workingDays && this.state.workingDays.length > 1) {
            for (let i = 0; i < this.state.workingDays.length; i++) {
                for (let j = i + 1; j < this.state.workingDays.length; j++) {
                    if (this.state.workingDays[i].day === this.state.workingDays[j].day) {
                        let convertedFromOverlap = convertTime12to24(this.state.workingDays[j].from);
                        let convertedToOverlap = convertTime12to24(this.state.workingDays[j].to);
                        let convertedToPreviousOverlap = convertTime12to24(this.state.workingDays[i].to);
                        let convertedFromPreviousOverlap = convertTime12to24(this.state.workingDays[i].from);

                        let fromInvalidTimeCheckWithFrom = timeCheck(convertedFromOverlap, convertedFromPreviousOverlap);
                        let fromInvalidTimeCheckWithFrom1 = timeCheck(convertedFromPreviousOverlap, convertedFromOverlap);
                        let fromInvalidTimeCheckWithTo = timeCheck(convertedToPreviousOverlap, convertedFromOverlap);
                        // let fromInvalidTimeCheckWithTo1 = timeCheck(convertedFromOverlap, convertedToPreviousOverlap);
                        let toInvalidTimeCheckWithFrom = timeCheck(convertedToOverlap, convertedFromPreviousOverlap);
                        // let toInvalidTimeCheckWithFrom1 = timeCheck(convertedFromPreviousOverlap, convertedToOverlap);
                        let toInvalidTimeCheckWithTo = timeCheck(convertedToPreviousOverlap, convertedToOverlap);
                        let toInvalidTimeCheckWithTo1 = timeCheck(convertedToOverlap, convertedToPreviousOverlap);

                        if (fromInvalidTimeCheckWithFrom && fromInvalidTimeCheckWithTo) { hasError = true; fromTimeOverlap = this.state.fromTimeOverlap; fromTimeOverlap[j] = true; this.setState({ fromTimeOverlap }); }
                        if (toInvalidTimeCheckWithFrom && toInvalidTimeCheckWithTo) { hasError = true; toTimeOverlap = this.state.toTimeOverlap; toTimeOverlap[j] = true; this.setState({ toTimeOverlap }); }

                        if (fromInvalidTimeCheckWithFrom1 && toInvalidTimeCheckWithTo1) { hasError = true; fromTimeOverlap = this.state.fromTimeOverlap; fromTimeOverlap[i] = true; toTimeOverlap = this.state.toTimeOverlap; toTimeOverlap[i] = true; this.setState({ fromTimeOverlap, toTimeOverlap }); }
                    }
                }
            }
        }


        this.state.workingDays.map((day) => {
            if (day.from && day.to) {
                time = {
                    _id: day._id,
                    day: day.day
                }
                time.from = day.from
                time.to = day.to

                wrkhrs.push(time)
            }
        })
        await this.validate();


        if (hasError) {
            return
        }

        const getUserRole = (data) => {

            let findUser = data.find(i => {
                let associatedBusiness = i.associatedBusiness.find(j => j.businessId === this.props.business.businessMembersDetails.businessId && j.role === 'superVendor');
                if (associatedBusiness) {
                    return i;
                }
            });
            return findUser._id;
        }

        let regExcheckDescription =/[a-zA-Z0-9_@.!%^$*:<>~=/()#&+-][a-zA-Z0-9_@.!%^$*:<>~=/()#&+-]*$/.test(this.state.description)
        if(this.state.description === ""){
			this.setState({ descriptionError : false});
		  }else if (!regExcheckDescription) {
            hasError= true;
            this.setState({ descriptionError : true});
          }

        let payload = {
            id: this.props.businessDetails._id,
            data: {
                service: this.state.serviceName,
                description: this.state.description,
                cost: {
                    costType: this.state.costType,
                    from: this.state.amountFrom,
                    to: this.state.amountTo,
                    fixedCost: this.state.amount,
                    unit: this.state.unit,
                    quantity: this.state.quantity
                },
                allowLooseQuantity: this.state.allowLooseQuantity,
                foodType: this.state.foodType,
                availability: {
                    isActive: this.state.availability,
                    holidays: holidays,
                    workingHrs: wrkhrs
                },
                isEnabled: this.state.isEnabled,
                isDisplay: this.state.isDisplay,
                contactId: this.state.contact ? this.state.contact : getUserRole(this.props.business.businessMembersDetails.data),
                photos: this.state.photos,
                serviceOffers: {
                    discount: {
                        offers: []
                    }
                },
                businessId: this.props.businessDetails._id,
                subcategory: this.state.subcategory,
                servicesPackagingCharges: this.state.servicesPackagingCharges,
                mustTry: this.state.mustTry,
                newArrival: this.state.newArrival,
                excludeItemTags: this.state.excludeItemTags,
                isAddOn: true
            }
        }
        if (this.props.businessDetails.categoryType.name.toLowerCase() === 'shopping') {
            payload.data.petPoojaItemId = this.state.petPoojaItemId;
            payload.data.petPoojaVariationId = this.state.petPoojaVariationId;
        }
        if (this.state.costType.toLowerCase() === 'fixed' && this.state.gstConf) {
            console.log(" it is fixed and has gstConf");
            // console.log(this.state.gstConf)
            if ((this.state.gstConf.gst != null && this.state.gstConf.sgst != null && this.state.gstConf.cgst != null) || this.state.gstConf.igst != null) {
                console.log("inside if");
                console.log("gst values are set");
                payload.data.cost.gstConf = {};
                payload.data.cost.gstConf.gst = this.state.gstConf.gst ? parseFloat(this.state.gstConf.gst) : 0;
                payload.data.cost.gstConf.sgst = this.state.gstConf.sgst ? parseFloat(this.state.gstConf.sgst) : 0;
                payload.data.cost.gstConf.cgst = this.state.gstConf.cgst ? parseFloat(this.state.gstConf.cgst) : 0;
                payload.data.cost.gstConf.igst = this.state.gstConf.igst ? parseFloat(this.state.gstConf.igst) : 0;
            }
            else {
                console.log("inside else");
                console.log("gst values are null");
                payload.data.cost.gstConf = null;
            }
        } else {
            console.log(" it is not fixed and no gstConf");
            payload.data.cost.gstConf = null;
        }

        if (this.state.discountMappingArray.length > 0) {
            let offerArray = []
            this.state.discountMappingArray.map(item => {
                let object = {
                    discountType: item.discountType,
                    isActive: item.isActive,
                    maxDiscountCap: item.maxDiscountCap,
                    off: item.off
                }
                offerArray.push(object)
            })
            payload.data.serviceOffers.discount.offers = offerArray
        }

        if (this.state._id) {
            payload.data._id = this.state._id;
        }

        var isDiscountError = null;
        if (this.state.error.discountMappingArrayError) {
            isDiscountError = this.state.error.discountMappingArrayError.some((item) => {
                return item == "Please Enter valid Amount"
            })
        }
        if (!this.state.error.serviceName && !this.state.error.amount && !this.state.error.amountFrom && !this.state.error.amountTo
            && !this.state.error.unit && !this.state.error.costType && !this.state.error.subcategory &&
            !isDiscountError && !this.state.error.servicesPackagingCharges && !this.state.descriptionError) {
            if (this.props.edit) {
                if (this.state.mustTry === true || this.state.newArrival === true)
                    payload.data.bestSeller = false;
                this.updateAddon(payload.data);
            } else {
                await this.props.createAddon(payload);
            }
            this.close();
            this.setState({newArrival: false, mustTry: false, excludeItemTags: false });
            this.props.fetchAddonListFilter();
        }
        this.setState({ holidaysRequiredError: [], holidaysError: [], dayRequiredError: [], fromRequiredError: [], toRequiredError: [], fromError: [], toError: [], invalidTime: [], holidayWorkingDayError: [], fromTimeOverlap: [], toTimeOverlap: [] });
    };

    updateAddon = async (data) => {

        let query = {
            sequence: this.state.headSubHeadSequenceMappingArray
        };
        if (data.service !== this.props.data.service) {
            query.service = data.service
        }
        if ((data.availability.holidays !== this.props.data.availability.holidays) || (data.availability.isActive !== this.props.data.availability.isActive) || (data.availability.workingHrs !== this.props.data.availability.workingHrs)) {
            query.availability = data.availability
        }
        if (!this.props.data.isEnabled || (data.isEnabled !== this.props.data.isEnabled)) {
            query.isEnabled = data.isEnabled
        }
        if (!this.props.data.isDisplay || (data.isDisplay !== this.props.data.isDisplay)) {
            query.isDisplay = data.isDisplay
        }
        if (data.description !== this.props.data.description) {
            query.description = data.description
        }
        if (data.petPoojaItemId !== this.props.data.petPoojaItemId) {
            query.petPoojaItemId = data.petPoojaItemId;
        }
        if (data.petPoojaVariationId !== this.props.data.petPoojaVariationId) {
            query.petPoojaVariationId = data.petPoojaVariationId;
        }
        if (data.foodType !== this.props.data.foodType) {
            query.foodType = data.foodType
        }
        if ((data.cost.costType !== this.props.data.cost.costType) || (data.cost.from !== this.props.data.cost.from) || (data.cost.to !== this.props.data.cost.to) || (data.cost.fixedCost !== this.props.data.cost.fixedCost) ||
            (data.cost.unit !== this.props.data.cost.unit) || (data.cost.quantity !== this.props.data.cost.quantity)) {
            query.cost = data.cost
        }
        if (!_.isEqual(data.cost.gstConf, this.props.data.cost.gstConf)) {
            query.cost = data.cost;
        }
        if (data.allowLooseQuantity !== this.props.data.allowLooseQuantity) {
            query.allowLooseQuantity = data.allowLooseQuantity
        }
        if ((this.props.data.subcategory && (data.subcategory !== this.props.data.subcategory._id.toString())) || !this.props.data.subcategory) {
            query.subcategory = data.subcategory
        }
        if (data.servicesPackagingCharges !== this.props.data.servicesPackagingCharges) {
            query.servicesPackagingCharges = data.servicesPackagingCharges
        }

        query.photos = data.photos

        if (this.props.data.contactId && (data.contactId !== this.props.data.contactId._id)) {
            query.contactId = data.contactId
        }

        if (!this.props.data.excludeItemTags || (data.excludeItemTags !== this.props.data.excludeItemTags)) {
            query.excludeItemTags = data.excludeItemTags;
            query.mustTry = data.mustTry;
            query.newArrival = data.newArrival;
        }

        query.serviceOffers = data.serviceOffers;
        let payload = {
            serviceId: this.props.data._id,
            data: query
        }

        await this.props.updateAddon(payload);
        this.props.fetchAddonListFilter();

    };

    clearData = () => {
        this.setState({
            serviceName: "",
            sequenceNo: 1,
            sequenceLimit: 1,
            availability: true,
            isEnabled: true,
            description: "",
            petPoojaItemId: "",
            petPoojaVariationId: "",
            foodType: "NA",
            _id: null,
            costType: "",
            amountFrom: null,
            amountTo: null,
            amount: null,
            unit: "",
            quantity: "",
            gstConf: null,
            allowLooseQuantity: false,
            contact: "",
            photos: [],
            modalDeleteOpen: false,
            selectedPhoto: null,
            Holidays: [],
            workingDays: [],
            copyBusinessTiming: false,
            subcategory: null,
            error: {},
            gstError: null,
            discountMappingArray: [],
            selectedDiscount: "NO",
            holidaysRequiredError: [],
            holidaysError: [],
            dayRequiredError: [],
            fromRequiredError: [],
            toRequiredError: [],
            fromError: [],
            toError: [],
            invalidTime: [],
            holidayWorkingDayError: [],
            fromTimeOverlap: [],
            toTimeOverlap: [],
            servicesPackagingCharges: null,
            newArrival: false,
            mustTry: false,
            excludeItemTags: false,
            descriptionError: false
        });
    };

    close = () => {
        this.clearData();
        this.props.onClose();
    };

    handleDeleteOpen = (photo) => {
        this.setState({ modalDeleteOpen: true });
        this.setState({ selectedPhoto: photo });
    };

    handleDeleteClose = () => {
        this.setState({ modalDeleteOpen: false });
    };

    deleteBusinessPhoto = () => {
        this.deletePhoto(this.state.selectedPhoto);
        this.setState({ modalDeleteOpen: false });
    };

    onFileChange = async event => {
        let files = event.target.files;
        this.updatePhotos(files);
    };

    updatePhotos = async (files, photoType) => {
        this.setState({ isUploading: true });
        if (this.props.edit) {
            this.setState({ _id: this.props.data._id })
        } else {
            this.generateId()
        }
        let data = new FormData();
        let folderPath = `Marketplace_Business/${this.props.businessDetails._id}/services/${this.state._id}`;

        let temp = files[0].name.split(".");
        let fileName = temp[0] + Math.ceil(Math.random() * 999999) + "." + temp[1];

        data.append("file", files[0]);
        data.append("folder", folderPath);
        data.append(
            "public_id",
            `${this.props.businessDetails._id}/${fileName}`
        );
        data.append(
            "upload_preset",
            this.props.env.cloudinaryBusinessImagesUploadPreset
        );

        let res = await fetch(this.props.env.cloudinaryUploadUrl, {
            method: "POST",
            body: data,
        });

        let file = await res.json();

        let photos = this.state.photos;
        photos.push({
            title: "",
            path: file.secure_url,
            public_id: file.public_id,
        });

        this.setState({
            photos
        });
        this.setState({ isUploading: false });
    };


    deletePhoto = async (selectedPhoto) => {
        let photos = this.state.photos
        photos = this.state.photos.filter(
            (photo) => photo.path !== selectedPhoto.path
        );
        this.setState({
            photos
        });
    };

    handleChangeWorkingDay = (event) => {
        let exist = 0;
        let workingDays = this.state.workingDays;
        if (!exist) {
            for (var i = 0; i < workingDays.length; i++) {
                if (workingDays[i]._id === event.target.name._id) {
                    workingDays[i].day = event.target.value;
                    let dayRequiredError = this.state.dayRequiredError;
                    if (event.target.value.length > 0) {
                        dayRequiredError[i] = false;
                    }
                    else {
                        dayRequiredError[i] = true;
                    }
                    this.setState({ workingDays, dayRequiredError });
                }
            }
        }
    };

    handleChangeWorkingDayFromTime = (event) => {
        let workingDays = this.state.workingDays
        let fromRequiredError = this.state.fromRequiredError;
        let fromError = this.state.fromError;
        let fromTimeOverlap = this.state.fromTimeOverlap;

        for (var i = 0; i < workingDays.length; i++) {
            if (workingDays[i]._id === event.target.name._id) {
                workingDays[i].from = event.target.value;
                if (event.target.value.length > 0) { fromRequiredError[i] = false; fromError[i] = false; fromTimeOverlap[i] = false; } else { fromRequiredError[i] = true; }
            }
        }
        this.setState({ workingDays, fromRequiredError, fromError, fromTimeOverlap })
    };

    handleChangeWorkingDayToTime = (event) => {
        let workingDays = this.state.workingDays
        let toRequiredError = this.state.toRequiredError;
        let toError = this.state.toError;
        let invalidTime = this.state.invalidTime;
        let toTimeOverlap = this.state.toTimeOverlap;
        for (var i = 0; i < workingDays.length; i++) {
            if (workingDays[i]._id === event.target.name._id) {
                workingDays[i].to = event.target.value;

                if (event.target.value.length > 0) { toRequiredError[i] = false; toError[i] = false; invalidTime[i] = false; toTimeOverlap[i] = false; } else { toRequiredError[i] = true; }
            }
        }
        this.setState({ workingDays, toRequiredError, toError, invalidTime, toTimeOverlap })
    };

    copyBusinessTiming = (event) => {
        this.setState({ [event.target.name]: event.target.checked })
        event.target.checked ?
            this.setBusinessTime()
            : this.setState({
                Holidays: [],
                workingDays: []
            })
    };

    setBusinessTime = () => {
        this.setState({
            Holidays: [],
            workingDays: []
        })
        let time = {};
        // let from = [];
        // let to = [];
        // let string = "";
        // let variable;
        let wrkhrs = [];
        let id;
        let data = {};
        let Holidays = [];
        this.props.businessDetails.availability.workingHrs.map((day) => {
            time = {
                _id: day._id,
                day: day.day
            }
            time.from = day.from
            time.to = day.to
            wrkhrs.push(time)
        })
        this.props.businessDetails.availability.holidays.map((holiday) => {
            // id = new ObjectID();
            id = new ObjectID().toHexString();
            data = {
                _id: id,
                day: holiday
            }
            Holidays.push(data)
        })
        this.setState({
            Holidays: Holidays,
            workingDays: wrkhrs
        })
    };

    handleChangeHoliDay = (event) => {
        let exist = 0;
        let Holidays = this.state.Holidays
        if (!exist) {
            for (var i = 0; i < Holidays.length; i++) {
                if (Holidays[i]._id === event.target.name) {
                    Holidays[i].day = event.target.value;
                    let holidaysRequiredError = this.state.holidaysRequiredError;
                    let holidaysError = this.state.holidaysError;
                    let holidayWorkingDayError = this.state.holidayWorkingDayError;
                    if (event.target.value.length > 0) {
                        holidaysRequiredError[i] = false;
                        holidaysError[i] = false;
                        holidayWorkingDayError[i] = false;
                    } else {
                        holidaysRequiredError[i] = true;
                    }
                    this.setState({ Holidays, holidaysRequiredError, holidayWorkingDayError })
                }
            }
        }
    };

    addWorkingDay = () => {
        let workingDays = this.state.workingDays;
        const id = new ObjectID().toHexString();
        let day = {
            _id: id,
            day: "",
            from: "",
            to: ""
        }
        workingDays.push(day)
        this.setState({ workingDays })
    };

    addHoliday = () => {
        let Holidays = this.state.Holidays;
        const id = new ObjectID().toHexString();
        let data = {
            _id: id,
            day: ""
        }
        Holidays.push(data)
        this.setState({ Holidays })
    };

    setData = async () => {
        let Holidays = [];
        let data = {}
        let id;
        // let index;
        let time = {};
        // let from = [];
        // let to = [];
        // let string = "";
        // let variable;
        let wrkhrs = [];
        let subcategoryFoodType = await ((this.props.categories.categories) && (this.props.categories.categories.length > 0) && (this.props.data.subcategory)) ? this.props.categories.categories.find(subCategory => subCategory._id.toString() === this.props.data.subcategory._id.toString()) : this.props.dashboard.setSelectedBusiness.category;
        this.props.data.availability.holidays.map((holiday) => {
            id = new ObjectID();
            data = {
                _id: id,
                day: holiday
            }
            Holidays.push(data)
        })
        this.props.data.availability.workingHrs.map((day) => {
            time = {
                _id: day._id,
                day: day.day
            }
            time.from = day.from;
            time.to = day.to;
            wrkhrs.push(time)
        })

        let discountMappingArray = [];
        if (this.props.data.serviceOffers.discount.offers.length > 0) {
            this.props.data.serviceOffers.discount.offers.map((item, index) => {
                let object = {
                    discountType: item.discountType,
                    off: item.off,
                    maxDiscountCap: item.maxDiscountCap,
                    isActive: item.isActive,
                }
                discountMappingArray.push(object)
                if (item.isActive) {
                    this.setState({ selectedDiscount: index })
                }
            })
            this.setState({ discountMappingArray })
        }

        this.setState({
            serviceName: this.props.data.service,
            sequenceNo: 1,
            availability: this.props.data.availability.isActive,
            isEnabled: this.props.data.isEnabled ? this.props.data.isEnabled : false,
            isDisplay: this.props.data.isDisplay ? this.props.data.isDisplay : false,
            description: this.props.data.description,
            petPoojaItemId: this.props.data.petPoojaItemId ? this.props.data.petPoojaItemId : "",
            petPoojaVariationId: this.props.data.petPoojaVariationId ? this.props.data.petPoojaVariationId : "",
            foodType: this.props.data.foodType ? this.props.data.foodType : "NA",
            costType: this.props.data.cost.costType,
            amountFrom: this.props.data.cost.from,
            amountTo: this.props.data.cost.to,
            amount: this.props.data.cost.fixedCost,
            unit: this.props.data.cost.unit,
            quantity: this.props.data.cost.quantity,
            gstConf: this.props.data.cost.gstConf,
            allowLooseQuantity: this.props.data.allowLooseQuantity,
            contact: this.props.data.contactId && this.props.data.contactId._id ? this.props.data.contactId._id : null,
            photos: this.props.data.photos,
            selectedPhoto: null,
            workingDays: wrkhrs,
            Holidays: Holidays,
            subcategory: this.props.data.subcategory && this.props.data.subcategory._id ? this.props.data.subcategory._id.toString() : null,
            showFoodType: subcategoryFoodType.foodType,
            discountMappingArray: discountMappingArray,
            servicesPackagingCharges: this.props.data.servicesPackagingCharges,
            newArrival: this.props.data.newArrival !== undefined && this.props.data.newArrival ? this.props.data.newArrival : false,
            mustTry: this.props.data.mustTry !== undefined && this.props.data.mustTry ? this.props.data.mustTry : false,
            excludeItemTags: this.props.data.excludeItemTags !== undefined && this.props.data.excludeItemTags ? this.props.data.excludeItemTags : false,
        })
    };

    componentDidUpdate(prevProps) {
        if (this.props.edit && (this.props.edit !== prevProps.edit)) {
            this.setData()
        }
    };

    generateId = () => {
        const id = new ObjectID()
        this.setState({ _id: id })
    };

    componentDidMount() {
        this.setBusinessTime();
        this.setState({ showFoodType: this.props.dashboard.setSelectedBusiness.category && this.props.dashboard.setSelectedBusiness.category.foodType })
        this.props.getCategoryTree({ _id: this.props.dashboard.setSelectedBusiness.category._id, type: 'category' });
        // let payload = { businessId: this.props.businessDetails._id }
        this.setState({workingDays:[]})
		this.setState({Holidays:[]})

    };

    render() {

        const { classes } = this.props;
        const units = ['Kg', 'Number', 'Liter', 'Gram', 'ml', 'Dozen', 'Serving','Plate/s','Piece/s','Box','Pack', 'Installation', 'Visit', 'Portion','Inch','Session','Coupon','Service','Class','Unit','Bowl','Glass','Mtr','Cup','Bunch','Bottle','Cm','Subscription','Pound','Pages','Bag/s'];
        let imageListContent = null;
        let sgstAmount = 0, cgstAmount = 0, igstAmount = 0, finalAmount = 0;
        if (this.state.gstConf && this.state.costType === 'Fixed') {
            let itemAmount = this.state.amount;
            let activeDiscount = this.state.discountMappingArray.find((discountItem) => discountItem.isActive === true);
            if (activeDiscount) {
                if (activeDiscount.discountType === 'FLAT') {
                    itemAmount = itemAmount - activeDiscount.off;
                } else {
                    //percentage
                    let discountAmount = (activeDiscount.off / 100) * itemAmount;
                    if (activeDiscount.maxDiscountCap && discountAmount > activeDiscount.maxDiscountCap) {
                        discountAmount = activeDiscount.maxDiscountCap;
                    }
                    itemAmount = itemAmount - discountAmount;
                }
            }
            sgstAmount = ((this.state.gstConf.sgst || 0) / 100) * itemAmount;
            cgstAmount = ((this.state.gstConf.cgst || 0) / 100) * itemAmount;
            igstAmount = ((this.state.gstConf.igst || 0) / 100) * itemAmount;
            finalAmount = (parseFloat(itemAmount) + parseFloat(sgstAmount) + parseFloat(cgstAmount) + parseFloat(igstAmount)).toFixed(2);
            if (isNaN(finalAmount)) finalAmount = 0;

        }
        if (this.state.isUploading) {
            imageListContent = (
                <Backdrop className={classes.backdrop} open={this.state.isUploading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            );
        } else {
            if (this.state.photos.length > 0) {
                imageListContent = (
                    <GridList cols={7} cellHeight={80} className={classes.gridList}>
                        {this.state.photos.map(img => (
                            <GridListTile
                                className={classes.gridElement} key={img._id}>
                                <a href={img.path} target="_blank" rel="noopener noreferrer">
                                    <img border="0" className={classes.img} src={img.path} alt="" />
                                </a>
                                <GridListTileBar
                                    titlePosition="bottom"
                                    actionIcon={
                                        <IconButton className={classes.icon} onClick={() => this.handleDeleteOpen(img)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                    actionPosition="right"
                                />
                            </GridListTile>
                        ))
                        }
                    </GridList >
                );
            } else {
                imageListContent = <span>
                    <Typography>
                        No Photos Available
                    </Typography>
                </span>;
            }
        }

        return (
            < Modal
                className={classes.modal}
                open={this.props.open}
                closeAfterTransition >
                <Container className={classes.mainContainer}>
                    <Grid container className={classes.header} spacing={1} >
                        <Grid item xs={8}>
                            {this.props.edit === true ?
                                <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
                                    Edit Addon
                                </WhiteTextTypography> : <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
                                    Create Addon
                                </WhiteTextTypography>}
                        </Grid>
                        <Grid item xs={4}>
                            <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
                                <CloseIcon className={classes.headGridTwo} onClick={() => { this.close() }} />
                            </WhiteTextTypography>
                        </Grid>
                    </Grid>
                    <Container className={classes.modalContainer}>
                        <Grid container className={classes.grid} spacing={1} alignItems="center" >
                            <Grid item xs={12} sm={6} >
                                <Typography variant="subtitle2" gutterBottom>
                                    Category
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                {this.props.categories.categories && this.props.categories.categories.length > 0 ?
                                    <Typography variant="subtitle2" gutterBottom>
                                        Sub-Category *
                                    </Typography>
                                    : null}
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <Typography variant="subtitle1" gutterBottom>
                                    {this.props.dashboard.setSelectedBusiness.category.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                {this.props.categories.categories && this.props.categories.categories.length > 0 ?
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <Select
                                            id="subcategory"
                                            value={this.state.subcategory ? this.state.subcategory : ""}
                                            name="subcategory"
                                            onChange={this.handleChangeSubCategory}
                                            MenuProps={MenuProps}
                                            className={classes.select}
                                        >
                                            <MenuItem value="" disabled>
                                                Select subCategory
                                            </MenuItem>
                                            {this.props.categories.categories && this.props.categories.categories.length > 0 && this.props.categories.categories.map((subCategory) =>
                                                <MenuItem key={subCategory._id} value={subCategory._id} style={{ minHeight: "28px", height: "28px" }}>
                                                    <ListItemText className={classes.listItemText} primary={subCategory.name} />
                                                </MenuItem>
                                            )}
                                        </Select>
                                        {
                                            this.state.error.subcategory ? <FormHelperText error>{this.state.error.subcategory}</FormHelperText> : null
                                        }
                                    </FormControl>
                                    : null}
                            </Grid>
                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px" }}
                                    display="block"
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={6} >
                                {
                                    this.props.edit
                                        ?
                                        this.state.excludeItemTags === true
                                            ?
                                            <Grid style={{ display: "flex", alignItems: "center" }} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography></Grid>
                                            :
                                            this.state.mustTry === true
                                                ?
                                                <Grid style={{ display: "flex", alignItems: "center" }} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography><div style={{ marginRight: "5px", marginLeft: "5px", color: "white" }} className={classes.musttry}>Must Try</div></Grid>
                                                :
                                                (
                                                    this.state.newArrival === true
                                                        ?
                                                        <Grid style={{ display: "flex", alignItems: "center" }} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography><div style={{ marginRight: "5px", marginLeft: "5px", color: "white" }} className={classes.newarrival}>New</div></Grid>
                                                        :
                                                        null

                                                )
                                        :
                                        this.state.excludeItemTags === true
                                            ?
                                            <Grid style={{ display: "flex", alignItems: "center" }} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography></Grid>
                                            :
                                            this.state.mustTry === true
                                                ?
                                                <Grid style={{ display: "flex", alignItems: "center" }} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography><div style={{ marginRight: "5px", marginLeft: "5px", color: "white" }} className={classes.musttry}>Must Try</div></Grid>
                                                :
                                                (
                                                    this.state.newArrival === true
                                                        ?
                                                        <Grid style={{ display: "flex", alignItems: "center" }} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography><div style={{ marginRight: "5px", marginLeft: "5px", color: "white" }} className={classes.newarrival}>New</div></Grid>
                                                        :
                                                        null
                                                )
                                }
                            </Grid> */}
                           <Grid item xs={12} sm={6} >
								{
									this.props.edit 
									?
										this.state.excludeItemTags === true
											?
												<Grid style={{display:"flex", alignItems:"center"}} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography></Grid> 
											: 
												this.state.mustTry === true 
													? 
														<Grid style={{display:"flex", alignItems:"center"}} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography><div style={{marginRight:"5px", marginLeft:"5px", color:"white"}} className={classes.musttry}>Must Try</div></Grid> 
													: 
														( 
															this.state.newArrival === true 
															? 
																<Grid style={{display:"flex", alignItems:"center"}} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography><div style={{marginRight:"5px", marginLeft:"5px", color:"white"}} className={classes.newarrival}>New</div></Grid>
															: 
																( 
																	this.state.bestSeller 
																	? 
																		<Grid style={{display:"flex", alignItems:"center"}} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography><div style={{marginRight:"5px", marginLeft:"5px", color:"white"}} className={classes.bestseller}>Best Seller</div></Grid> 
																	: 
																		<Grid style={{display:"flex", alignItems:"center"}} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography></Grid> 
																)
														) 
									: 
										this.state.excludeItemTags === true
										?
											<Grid style={{display:"flex", alignItems:"center"}} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography></Grid> 
										: 
											this.state.mustTry === true 
												? 
													<Grid style={{display:"flex", alignItems:"center"}} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography><div style={{marginRight:"5px", marginLeft:"5px", color:"white"}} className={classes.musttry}>Must Try</div></Grid> 
												: 
													( 
														this.state.newArrival === true 
														? 
															<Grid style={{display:"flex", alignItems:"center"}} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography><div style={{marginRight:"5px", marginLeft:"5px", color:"white"}} className={classes.newarrival}>New</div></Grid>
														: 
															( 
																this.state.bestSeller 
																? 
																	<Grid style={{display:"flex", alignItems:"center"}} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography><div style={{marginRight:"5px", marginLeft:"5px", color:"white"}} className={classes.bestseller}>Best Seller</div></Grid> 
																: 
																	<Grid style={{display:"flex", alignItems:"center"}} item ><Typography variant="subtitle2" gutterBottom>Service Name * </Typography></Grid> 
															)
													) 
								}
							</Grid>
                            <Grid item xs={12} sm={2} >
                                <Typography variant="subtitle2" gutterBottom>
                                    Enable / Disable
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={12} sm={2}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Availability
                                </Typography>
                            </Grid> */}
                            <Grid item xs={12} sm={2}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Hide / Display
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="serviceName" label="Service Name *" variant="outlined" value={this.state.serviceName} error={this.state.error.serviceName} helperText={this.state.error.serviceName} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ alignItems: "left", justify: "center", alignContent: "center" }}  >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            checked={this.state.isEnabled}
                                            onChange={(e) => this.handleIsUnableSwitchChange(e)}
                                            color="primary"
                                        />
                                    }
                                    style={{ fontSize: "5px" }}
                                    label={this.state.isEnabled ? 'Enable' : 'Disable'}
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={2} style={{ alignItems: "left" }} >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            checked={this.state.availability}
                                            onChange={(e) => this.handleSwitchChange(e)}
                                            color="primary"
                                        />
                                    }
                                    style={{ fontSize: "5px" }}
                                    label={this.state.availability ? 'Available' : 'Unavailable'}
                                />
                            </Grid> */}
                            <Grid item xs={12} sm={2} style={{ alignItems: "left" }} >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            checked={this.state.isDisplay}
                                            onChange={(e) => this.handleIsDisplayChange(e)}
                                            color="primary"
                                        />
                                    }
                                    style={{ fontSize: "5px" }}
                                    label={this.state.isDisplay ? 'Display' : 'Hide'}
                                />
                            </Grid>
                            {this.state.showFoodType ?
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Description
                                    </Typography>
                                </Grid>
                                :
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Description
                                    </Typography>
                                </Grid>}

                            {this.state.showFoodType ?
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Food Type
                                    </Typography>
                                </Grid>
                                :
                                null}
                            {this.state.showFoodType ?
                                <Grid item xs={4}>
                                    <TextField fullWidth margin="dense" name="description" label="Description" variant="outlined" value={this.state.description} onChange={this.handleChange} />
									<Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px'}}> {this.state.descriptionError? "Enter valid description": null} </FormHelperText></Grid>
                                </Grid>
                                :
                                <Grid item xs={12}>
                                    <TextField fullWidth margin="dense" name="description" label="Description" variant="outlined" value={this.state.description} onChange={this.handleChange} />
									<Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px'}}> {this.state.descriptionError? "Enter valid description": null} </FormHelperText></Grid>
                                </Grid>
                            }
                            {this.state.showFoodType ?
                                <Grid item xs={8} >
                                    <RadioGroup row aria-label="position" name="foodType" onChange={this.handleChange} value={this.state.foodType}>
                                        <FormControlLabel style={{ fontSize: "12px" }} value="Veg" control={<Radio color="primary" />} label="Veg" />
                                        <FormControlLabel style={{ fontSize: "12px" }} value="NonVeg" control={<Radio color="primary" />} label="Non-Veg" />
                                        <FormControlLabel style={{ fontSize: "12px" }} value="Egg" control={<Radio color="primary" />} label="Egg" />
                                        <FormControlLabel style={{ fontSize: "12px" }} value="NA" control={<Radio color="primary" />} label="NA" />
                                        <FormControlLabel style={{ fontSize: "12px" }} value="Blank" control={<Radio color="primary" />} label="Blank" />
                                    </RadioGroup>
                                </Grid> : null}

                            <Grid item xs={12}>
                                <Grid style={{ marginBottom: "10px", marginTop: "10px" }}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Item Tag
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                size="small"
                                                checked={this.state.newArrival}
                                                onChange={(e) => this.handleIsNewArrivalSwitchChange(e)}
                                                color="primary"
                                            />
                                        }
                                        style={{ fontSize: "5px" }}
                                        label={"New"}
                                        disabled={this.state.excludeItemTags ? true : (this.state.mustTry ? true : false)}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                size="small"
                                                checked={this.state.mustTry}
                                                onChange={(e) => this.handleIsMustTrySwitchChange(e)}
                                                color="primary"
                                            />
                                        }
                                        style={{ fontSize: "5px" }}
                                        label={"Must Try"}
                                        disabled={this.state.excludeItemTags ? true : (this.state.newArrival ? true : false)}
                                    />
                                    <Checkbox
                                        onClick={(event) => this.handleExcludeItemTags(event)}
                                        value={this.state.excludeItemTags}
                                        name="excludeItemTags"
                                        checked={this.state.excludeItemTags}
                                        style={{ paddingRight: "3px", marginBottom: "8px" }}
                                    />
                                    Exclude Item Tags
                                </Grid>
                            </Grid>

                            {this.props.businessDetails.categoryType.name.toLowerCase() === 'shopping' ?
                                <>
                                    <Grid item xs={5}>
                                        <TextField fullWidth margin="dense" name="petPoojaItemId" label="Pet Pooja Item ID" variant="outlined" value={this.state.petPoojaItemId} onChange={this.handleChange} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField fullWidth margin="dense" name="petPoojaVariationId" label="Pet Pooja Variation ID" variant="outlined" value={this.state.petPoojaVariationId} onChange={this.handleChange} />
                                    </Grid>
                                </>
                                :
                                null
                            }
                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px" }}
                                    display="block"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Cost
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-outlined-label">Cost Type *</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="costType"
                                        value={this.state.costType}
                                        name="costType"
                                        onChange={this.handleChange}
                                        MenuProps={MenuProps}
                                        style={{ margin: "10px" }}
                                        className={classes.select}
                                    >
                                        <MenuItem value="Approx" style={{ minHeight: "28px", height: "28px" }}>
                                            <ListItemText className={classes.listItemText} primary="Approx" />
                                        </MenuItem>
                                        <MenuItem value="Fixed" style={{ minHeight: "28px", height: "28px" }}>
                                            <ListItemText className={classes.listItemText} primary="Fixed" />
                                        </MenuItem>

                                    </Select>
                                    {
                                        this.state.error.costType ? <FormHelperText error>{this.state.error.costType}</FormHelperText> : null
                                    }
                                </FormControl>
                            </Grid>

                            {this.state.costType === "Approx" ?
                                <Grid item xs={3}>
                                    <TextField style={{ marginLeft: "2px", marginRight: "2px" }} margin="dense" name="amountFrom" label="From*" variant="outlined" error={this.state.error.amountFrom} helperText={this.state.error.amountFrom} value={this.state.amountFrom} onChange={this.handleChange} />
                                </Grid>
                                :
                                null
                            }
                            {this.state.costType === "Fixed" ?
                                <Grid item xs={3}>

                                    <TextField style={{ marginLeft: "2px", marginRight: "2px" }} margin="dense" name="amount" label="Amount*" variant="outlined" value={this.state.amount} error={this.state.error.amount} helperText={this.state.error.amount} onChange={this.handleChange} />

                                </Grid>
                                : null}
                            {this.state.costType === "Fixed" ?
                                <Grid item xs={3}>

                                    <TextField style={{ marginLeft: "2px", marginRight: "2px" }} margin="dense" name="quantity" label="Quantity" variant="outlined" value={this.state.quantity} onChange={this.handleChange} />

                                </Grid>
                                : null}
                            {this.state.costType !== "Fixed" && this.state.costType !== "Approx" ?
                                <Grid item xs={3}>
                                </Grid>
                                : null}
                            {this.state.costType === "Approx" ?
                                <Grid item xs={3}>
                                    <TextField style={{ marginLeft: "2px", marginRight: "2px" }} margin="dense" name="amountTo" label="To*" variant="outlined" value={this.state.amountTo} error={this.state.error.amountTo} helperText={this.state.error.amountTo} onChange={this.handleChange} />
                                </Grid>
                                :
                                null}
                            <Grid item xs={3}>
                                {this.state.costType === "Fixed" || this.state.costType === "Approx" ?
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-simple-select-outlined-label">Unit*
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="unit"
                                            value={this.state.unit}
                                            name="unit"
                                            onChange={this.handleChange}
                                            MenuProps={MenuProps}
                                            style={{ margin: "10px" }}
                                            className={classes.select}
                                        >
                                            {units.map((unit) =>
												<MenuItem key={unit} value={unit} style={{ minHeight: "28px", height: "28px" }}>
													<ListItemText className={classes.listItemText} primary={unit} />
												</MenuItem>
											)}
                                        </Select>
                                        {
                                            this.state.error.unit ? <FormHelperText error>{this.state.error.unit}</FormHelperText> : null
                                        }
                                    </FormControl>
                                    : null}
                            </Grid>
                            {this.state.costType === "Fixed" ?
                                <Grid item xs={4}>
                                    <FormControl component="fieldset">
                                        <FormControlLabel
                                            //={this.state.allowLooseQuantity}
                                            control={<Checkbox color="primary" defaultChecked={this.state.allowLooseQuantity} onChange={this.handleCheck} />}
                                            label="Allow sale in loose quantity"
                                            className={classes.Checkbox}
                                            labelPlacement="end"
                                        />
                                    </FormControl>
                                </Grid>
                                :
                                null}
                            {(this.state.costType !== "Fixed" && this.state.costType !== "Approx") ?
                                <Grid item xs={3}></Grid>
                                : null}
                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                />
                            </Grid>
                            {this.state.costType === 'Fixed' &&
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            GST
                                        </Typography>
                                    </Grid>
                                    {this.state.gstError && <div style={{ color: 'red', margin: '10px' }}>{this.state.gstError}</div>
                                    }
                                    <Grid container direction='column' >
                                        <Grid container xs={12} justify="space-between" alignItems="center">
                                            <Grid item xs={6}>
                                                <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                                    <InputLabel id="gstConf">N/A</InputLabel>
                                                    <Select
                                                        labelId="gstConf"
                                                        id="gst"
                                                        value={this.state.gstConf ? this.state.gstConf.gst : ''}
                                                        name="gstConf.gst"
                                                        onChange={this.handleGSTChange}
                                                        MenuProps={MenuProps}
                                                        style={{ margin: "10px" }}
                                                        className={classes.select}
                                                    >
                                                        <MenuItem value="" style={{ minHeight: "28px", height: "28px" }}>
                                                            <ListItemText className={classes.listItemText} primary="N/A" />
                                                        </MenuItem>
                                                        <MenuItem value="5" style={{ minHeight: "28px", height: "28px" }}>
                                                            <ListItemText className={classes.listItemText} primary="5%" />
                                                        </MenuItem>
                                                        <MenuItem value="12" style={{ minHeight: "28px", height: "28px" }}>
                                                            <ListItemText className={classes.listItemText} primary="12%" />
                                                        </MenuItem>
                                                        <MenuItem value="18" style={{ minHeight: "28px", height: "28px" }}>
                                                            <ListItemText className={classes.listItemText} primary="18%" />
                                                        </MenuItem>
                                                        <MenuItem value="28" style={{ minHeight: "28px", height: "28px" }}>
                                                            <ListItemText className={classes.listItemText} primary="28%" />
                                                        </MenuItem>

                                                    </Select>

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                {this.state.gstConf && this.state.gstConf.gst !== '' && <Button style={{ float: "right", fontSize: "12px", fontWeight: "bold" }}
                                                    variant="outlined" htmlFor="photos" color="primary" component="span" onClick={() => this.handleGSTChange({ target: { value: '' } })}>
                                                    RESET
                                                </Button>}
                                            </Grid>
                                        </Grid>
                                        {
                                            this.state.gstConf && this.hasGstFields() &&
                                            <Grid item xs={8}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '95%', marginLeft: '10px' }}>
                                                    <TextField style={{ width: '35%' }} margin="dense" type="number" name="sgst" label="SGST %*" variant="outlined" value={this.state.gstConf ? this.state.gstConf.sgst : ''} error={this.state.error.sgst} helperText={this.state.error.sgst} onChange={this.handleGSTComponentChange} />
                                                    <TextField style={{ width: '35%' }} margin="dense" disabled variant="outlined" value={'₹ ' + sgstAmount.toFixed(2)} helperText={this.state.error.sgst} />
                                                </div>
                                            </Grid>
                                        }{
                                            this.state.gstConf && this.hasGstFields() &&
                                            <Grid item xs={8}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '95%', marginLeft: '10px' }}>
                                                    <TextField style={{ width: '35%' }} margin="dense" type="number" name="cgst" label="CGST %*" variant="outlined" value={this.state.gstConf ? this.state.gstConf.cgst : ''} error={this.state.error.cgst} helperText={this.state.error.cgst} onChange={this.handleGSTComponentChange} />
                                                    <TextField style={{ width: '35%' }} margin="dense" disabled variant="outlined" value={'₹ ' + cgstAmount.toFixed(2)} helperText={this.state.error.igst} />
                                                </div>
                                            </Grid>
                                        }
                                        {
                                            this.state.gstConf && this.hasGstFields() &&
                                            <Grid item xs={8}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '95%', marginLeft: '10px' }}>
                                                    <TextField style={{ width: '35%' }} margin="dense" type="number" name="igst" label="IGST %*" variant="outlined" value={this.state.gstConf ? this.state.gstConf.igst : ''} error={this.state.error.igst} helperText={this.state.error.igst} onChange={this.handleGSTComponentChange} />
                                                    <TextField style={{ width: '35%' }} margin="dense" disabled variant="outlined" value={'₹ ' + igstAmount.toFixed(2)} helperText={this.state.error.sgst} />
                                                </div>
                                            </Grid>
                                        }

                                        {
                                            this.state.gstConf && this.hasGstFields() &&
                                            <Grid item xs={8}>
                                                <div style={{ alignItems: 'center', width: '95%', margin: '10px' }}>
                                                    Final Cost: <span style={{ fontWeight: 'bold' }}>{'₹ ' + finalAmount}</span></div>
                                            </Grid>
                                        }
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider color="textSecondary"
                                            style={{ marginBottom: "5px", marginTop: "10px" }}
                                            display="block"
                                        />
                                    </Grid>
                                </>
                            }

                            <Grid item xs={12}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Item Packaging Charges
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth margin="dense" name="servicesPackagingCharges" label="Item packaging charges" variant="outlined" value={this.state.servicesPackagingCharges}
                                    error={this.state.error.servicesPackagingCharges} helperText={this.state.error.servicesPackagingCharges}
                                    onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Discounts
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                {this.state.discountMappingArray.length < 3 ? <Button style={{ float: "right", fontSize: "12px", fontWeight: "bold" }}
                                    variant="outlined" htmlFor="photos" color="primary" component="span" onClick={this.addDiscountMappingArrayItem}>
                                    ADD
                                </Button> : null}
                            </Grid>
                            <Grid item xs={1}>
                                <Radio
                                    checked={"NO" === this.state.selectedDiscount}
                                    color="primary"
                                    onChange={(e) => this.handleDiscountChangeRadio(e, "NO")}
                                    value={"NO"}
                                    name="discountType"
                                    style={{ fontSize: "12px", float: "left", margin: "10px" }}
                                />
                            </Grid>
                            <Grid item xs={11}>
                                <label style={{ fontSize: "16px", float: "left", marginLeft: "12px", marginTop: "10px" }}>No Offer</label>
                            </Grid>
                            {this.state.discountMappingArray.map((item, index) => {
                                return (
                                    <React.Fragment key={item.id}>
                                        <Grid item xs={1}>
                                            <Radio
                                                checked={index === this.state.selectedDiscount}
                                                color="primary"
                                                onChange={(e) => this.handleDiscountChangeRadio(e, index)}
                                                value={index}
                                                name="discountType"
                                                style={{ fontSize: "12px", float: "left", margin: "10px" }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                                <InputLabel id="demo-simple-select-outlined-label">Discount Type *</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="discountType"
                                                    value={this.state.discountMappingArray[index].discountType}
                                                    name="discountType"
                                                    onChange={(e) => { this.handleDiscountChange(e, index) }}
                                                    MenuProps={MenuProps}
                                                    style={{ margin: "10px" }}
                                                    className={classes.select}
                                                >
                                                    <MenuItem value="PERCENTAGE" style={{ minHeight: "28px", height: "28px" }}>
                                                        <ListItemText className={classes.listItemText} primary="PERCENTAGE" />
                                                    </MenuItem>
                                                    <MenuItem value="FLAT" style={{ minHeight: "28px", height: "28px" }}>
                                                        <ListItemText className={classes.listItemText} primary="FLAT" />
                                                    </MenuItem>

                                                </Select>
                                                {
                                                    this.state.error.discountType ? <FormHelperText error>{this.state.error.discountType}</FormHelperText> : null
                                                }
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField type="number" style={{ marginLeft: "5px", marginRight: "5px" }} margin="dense" name="off" label="Off*" variant="outlined" error={this.state.error.discountMappingArrayError && this.state.error.discountMappingArrayError[index]}
                                                helperText={this.state.error.discountMappingArrayError && this.state.error.discountMappingArrayError[index]} value={this.state.discountMappingArray[index].off} onChange={(e) => { this.handleDiscountChange(e, index) }} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            {this.state.discountMappingArray[index].discountType === "FLAT" ? null : <TextField type="number" style={{ marginLeft: "5px", marginRight: "5px" }} margin="dense" name="maxDiscountCap" label="Max" variant="outlined"
                                                value={this.state.discountMappingArray[index].maxDiscountCap} onChange={(e) => { this.handleDiscountChange(e, index) }} />}
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Button>
                                                <Avatar className={classes.lightBlue}>
                                                    <DeleteIcon color="action" className={classes.deleteBtn}
                                                        onClick={() => this.deleteDiscountMappingArrayItem(index)} />
                                                </Avatar>
                                            </Button>
                                        </Grid>
                                    </React.Fragment>
                                )
                            })}
                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Timings
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Button style={{ float: "right", fontSize: "12px", fontWeight: "bold" }} variant="outlined" htmlFor="photos" color="primary" component="span" onClick={this.addWorkingDay}>
                                    ADD
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button htmlFor="photos" color="primary" component="span" style={{ float: "right", fontSize: "12px", fontWeight: "bold" }} variant="outlined" onClick={this.addHoliday} >
                                    ADD HOLIDAY
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.copyBusinessTiming} onChange={this.copyBusinessTiming} name="copyBusinessTiming" />}
                                    label="Copy Business Timing"
                                />
                            </Grid>
                            {this.state.workingDays.map((index, indexNo) => {
                                return (
                                    <Grid container direction="row" key={"workingdays_" + index} justify="center" alignItems="center">
                                        <Grid item xs={4}>
                                            <FormControl variant="outlined" fullWidth className={classes.formControl} error={this.state.dayRequiredError[indexNo] ? true : false} >
                                                <InputLabel id="demo-simple-select-outlined-label">Days</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="day"
                                                    value={index.day}
                                                    name={index}
                                                    onChange={this.handleChangeWorkingDay}
                                                    MenuProps={MenuProps}
                                                    style={{ margin: "10px" }}
                                                    className={classes.select}
                                                >
                                                    {this.state.days.map((index) => {
                                                        return (
                                                            <MenuItem key={index} value={index.day} style={{ minHeight: "28px", height: "28px" }}>
                                                                <ListItemText className={classes.listItemText} primary={index.day} />
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>

                                            <FormControl variant="outlined" fullWidth className={classes.formControl} error={this.state.fromRequiredError[indexNo] ? true : (this.state.fromError[indexNo] ? true : (this.state.fromTimeOverlap[indexNo] ? true : false))} >
                                                <Select labelId="demo-simple-select-outlined-label" id="from" value={index.from} name={index} onChange={this.handleChangeWorkingDayFromTime} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                                                    {this.state.from.map((index) => {
                                                        return (
                                                            <MenuItem key={index} value={index.from} style={{ minHeight: "28px", height: "28px" }}>
                                                                <Typography noWrap>{index.from.toUpperCase()}</Typography>
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl variant="outlined" fullWidth className={classes.formControl} error={this.state.toRequiredError[indexNo] ? true : (this.state.toError[indexNo] ? true : (this.state.invalidTime[indexNo] ? true : (this.state.toTimeOverlap[indexNo] ? true : false)))} >
                                                <Select labelId="demo-simple-select-outlined-label" id="to" value={index.to} name={index} onChange={this.handleChangeWorkingDayToTime} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                                                    {this.state.to.map((index) => {
                                                        return (
                                                            <MenuItem key={index} value={index.from} style={{ minHeight: "28px", height: "28px" }}>
                                                                <Typography noWrap>{index.from.toUpperCase()}</Typography>
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Button
                                            //onClick={this.handleDisableDialogOpen}
                                            >
                                                <Avatar className={classes.lightBlue}>
                                                    <DeleteIcon color="action" className={classes.deleteBtn} onClick={() => this.deleteWorkingHrs(index)} />
                                                </Avatar>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4}><FormHelperText style={{ color: "red", marginLeft: '10px', marginTop: '0px', marginBottom: '10px' }} id="my-helper-text">{this.state.dayRequiredError[indexNo] ? "Required" : null}</FormHelperText></Grid>
                                        <Grid item xs={4}><FormHelperText style={{ color: "red", marginLeft: '10px', marginTop: '0px', marginBottom: '10px' }} id="my-helper-text"> {this.state.fromRequiredError[indexNo] ? "Required" : (this.state.fromError[indexNo] ? "Duplicate Timing" : (this.state.fromTimeOverlap[indexNo] ? "There is time overlap" : null))}</FormHelperText></Grid>
                                        <Grid item xs={3}><FormHelperText style={{ color: "red", marginLeft: '10px', marginTop: '0px', marginBottom: '10px' }} id="my-helper-text"> {this.state.toRequiredError[indexNo] ? "Required" : (this.state.toError[indexNo] ? "Duplicate Timing" : ((this.state.invalidTime[indexNo] ? "Invalid value" : (this.state.toTimeOverlap[indexNo] ? "There is time overlap" : null))))} </FormHelperText></Grid>
                                        <Grid item xs={1}></Grid>
                                    </Grid>
                                )
                            })}
                            {this.state.Holidays.map((index, indexNumber) => {
                                return (

                                    <Grid container direction="row" key={index + index._id} justify="center" alignItems="center">
                                        <Grid item xs={4}>
                                            <FormControl variant="outlined" fullWidth className={classes.formControl} error={this.state.holidaysRequiredError[indexNumber] ? true : (this.state.holidaysError[indexNumber] ? true : (this.state.holidayWorkingDayError[indexNumber] ? true : false))}>
                                                <InputLabel id="Subhead-label">Days</InputLabel>
                                                <Select
                                                    labelId="Subhead-label"
                                                    id="day"
                                                    value={index.day}
                                                    name={index._id}
                                                    onChange={this.handleChangeHoliDay}
                                                    MenuProps={MenuProps}
                                                    style={{ margin: "10px" }}
                                                    className={classes.select}
                                                >
                                                    {this.state.days.map((index) => {
                                                        return (
                                                            <MenuItem key={index} value={index.day} style={{ minHeight: "28px", height: "28px" }}>
                                                                <ListItemText className={classes.listItemText} primary={index.day} />
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                CLOSED
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button
                                            //onClick={this.handleDisableDialogOpen}
                                            >
                                                <Avatar className={classes.lightBlue}>
                                                    <DeleteIcon color="action" className={classes.deleteBtn} onClick={() => this.deleteHoliday(index)} />
                                                </Avatar>

                                            </Button>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={4}><FormHelperText style={{ color: "red", marginLeft: '10px', marginTop: '0px', marginBottom: '10px' }}>{this.state.holidaysRequiredError[indexNumber] ? "Required" : (this.state.holidaysError[indexNumber] ? "Duplicate holiday" : (this.state.holidayWorkingDayError[indexNumber] ? "Working day cannot be a holiday" : false))}</FormHelperText></Grid>
                                        <Grid item xs={8}></Grid>
                                    </Grid>
                                )
                            })}

                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Tag Contact
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                    <Select
                                        id="contact"
                                        value={this.state.contact}
                                        name="contact"
                                        onChange={this.handleChange}
                                        MenuProps={MenuProps}
                                        style={{ marginLeft: "5px", marginRight: "5px" }}
                                        className={classes.select}
                                    >
                                        {this.props.business.businessMembersDetails && this.props.business.businessMembersDetails.data ?
                                            this.props.business.businessMembersDetails.data.map((contact) =>

                                                <MenuItem key={contact._id} value={contact._id} style={{ minHeight: "28px", height: "28px" }}>
                                                    <ListItemText className={classes.listItemText} primary={contact.firstName + " " + contact.lastName} />
                                                </MenuItem>
                                            ) : null
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Photos
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>

                                <Button style={{ float: "right", fontSize: "12px", fontWeight: "bold" }} variant="outlined" htmlFor="photos" onClick={this.fileUploadAction} color="primary" component="span" >
                                    Upload
                                </Button>
                                <input
                                    ref={this.inputReference}
                                    id="photos"
                                    name="photos"
                                    style={{ display: 'none' }}
                                    type={"file"}
                                    onChange={this.onFileChange}
                                    accept="image/x-png,image/jpeg"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {imageListContent}
                            </Grid>
                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                />
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    style={{ margin: "5px" }}
                                    fullWidth
                                    variant="contained"
                                    onClick={this.close}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    fullWidth
                                    style={{ margin: "5px" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.addAddon}
                                >
                                    SAVE
                                </Button>
                            </Grid>
                            <Dialog open={this.state.modalDeleteOpen} onClose={this.handleDeleteClose}>
                                <DialogTitle>Delete Photo</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Do you want to delete this photo?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
                                    <Button onClick={() => this.deleteBusinessPhoto()} color="primary" autoFocus> Yes </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </Container>
                </Container>
            </Modal >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        addons: state.addons,
        dashboard: state.dashboard,
        business: state.business,
        user: state.auth.user,
        admin: state.admin,
        categories: state.categories,
        env: state.auth.env
    };
};
export default connect(mapStateToProps, {
    createAddon,
    updateAddon,
    getCategoryTree,

})(withStyles(styles)(ManageCreateUpdateAddon));