import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';

const headCells = [
    { id: 'businessId', disablePadding: false, label: 'Business ID' },
    { id: 'businessName:', disablePadding: false, label: 'Business Name' },
    { id: 'notSettledCouponsCount', disablePadding: false, label: 'Not Settled Coupons Count' },
    { id: 'notSettledCouponsAmt', disablePadding: false, label: 'Not Settled Coupons Amt (\u20B9)' },
    { id: 'settledCouponsCount', disablePadding: false, label: 'Settled Coupons Count' },
    { id: 'settledCouponsAmt', disablePadding: false, label: 'Settled Coupons Amt (\u20B9)' },
    { id: 'paymentIds', disablePadding: false, label: 'Payment IDs' },
];

function EnhancedTableHead(props) {
    const {classes} = props;

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        className={classes.tableCellSmall}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
            Business Coupon Settlement Report ({props.totalCount})
            </Typography>

            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"campaign_summary.csv"}
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                    : <Button
                        variant="contained"
                        margin="normal"
                        style={{ color: "white", fontFamily: "bold" }}
                        color="primary"
                        onClick={props.fetchCampaignSummaryExcelFile}
                    >
                        Export
        </Button>
                }
            </Typography>
        </Toolbar>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        minWidth: 102,
    },
    tableCellSmall: {
        fontSize: "13px",
        padding: "6px 0px 6px 16px"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        fontSize: "13px",
    },
    tableRow: {
        cursor: 'pointer'
    },
    deleteBtn: {
        cursor: 'pointer',
        color: 'grey',
        marginTop: "7px"
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    tableCellSmall: {
        // fontSize: "13px",
        // padding: "6px 0px 6px 10px", //TRBL
        // width: '100%',
        textAlign: 'left',
        // marginRight:"25px",
        // marginLeft:"25px",
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    const redirectToBusinessCampaignCouponCategoryReport = (obj) => {
        // console.log("in console of redirect------>",obj)
        // props.history.push(`/campaignsCouponSettlementBusiness`);
        props.history.push({pathname:`/campaignsCouponSettlementBusiness`, state:{businessId:obj.businessId,businessName:obj.businessName}});
        // props.history.push(`/campaignsCouponSettlement/${obj.businessID}`); //`/campaignsCouponSettlement/${business._id
    };

    let headers = [
        { key: 'businessID', label: 'Business ID' },
        { key: 'businessName', label: 'Business Name' },
        { key: 'notSettledCoupons', label: 'Not Settled Coupons Count' },
        { key: 'notSettledCouponsAmt', label: 'Not Settled Coupons Amt' },
        { key: 'settledCoupons', label: 'Settled Coupons Count' },
        { key: 'settledCouponsAmt', label: 'Settled Coupons Amt' },
        { key: 'paymentIds', label: 'Payment IDs' },
    ];

    let data = []
    props.exportToExcel && props.exportToExcel.forEach(element => {
        let item = element
        item.businessID=item.businessId;
        item.settledCoupons=item.settledCnt;
        item.settledCouponsAmt=item.settledAmount;
        item.notSettledCoupons=item.notSettledCnt;
        item.notSettledCouponsAmt=item.notSettledAmt;
        item.paymentIds="";
        if(item.phonePe){
            item.paymentIds= item.paymentIds.concat("Phonepe : ",item.phonePe+"\n")
        }
        if(item.amazonPay){
            item.paymentIds= item.paymentIds.concat("AmazonPay : ",item.amazonPay+"\n")
        }
        if(item.googlePay){
            item.paymentIds= item.paymentIds.concat("GooglePay : ",item.googlePay+"\n")
        }
        if(item.paytm){
            item.paymentIds= item.paymentIds.concat("Paytm : ",item.paytm)
        }
        data.push(item)
    });

    let totalCount = props.totalCount;
    const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.totalCount - props.page * props.rowsPerPage);
    
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar 
                headers={headers}
                totalCount={props.totalCount}
                data={data}
                showDownloadIcon={props.showDownloadIcon}
                fetchCampaignSummaryExcelFile={props.fetchCampaignSummaryExcelFile}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            rowCount={props.totalCount}
                        />
                        <TableBody>
                            {
                                props.data && props.data.map((row, index) => {
                                    let paymentIdArray=[]
                                    if(row.phonePe) paymentIdArray.push(<p style={{margin:"5px"}}>{"Phonepe : "+row.phonePe}</p>);
                                    if(row.amazonPay) paymentIdArray.push(<p style={{margin:"5px"}}>{"AmazonPay : "+row.amazonPay}</p>);
                                    if(row.googlePay) paymentIdArray.push(<p style={{margin:"5px"}}>{"GooglePay : "+row.googlePay}</p>);
                                    if(row.paytm) paymentIdArray.push(<p style={{margin:"5px"}}>{"Paytm : "+row.paytm}</p>);

                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            tabIndex={-1}
                                            key={row._id}
                                            // key={index}
                                        >
                                            <TableCell align="left" onClick={(e) => redirectToBusinessCampaignCouponCategoryReport(row,e)} className={classes.tableCellSmall} >{row.businessID}</TableCell>
                                            <TableCell component="th" id={labelId} scope="row" onClick={(e) => redirectToBusinessCampaignCouponCategoryReport(row,e)} className={classes.tableCellSmall} >
                                                {row.businessName}
                                            </TableCell>
                                            <TableCell align="left" onClick={(e) => redirectToBusinessCampaignCouponCategoryReport(row,e)} className={classes.tableCellSmall} >{row.notSettledCoupons}</TableCell>
                                            <TableCell align="left" onClick={(e) => redirectToBusinessCampaignCouponCategoryReport(row,e)} className={classes.tableCellSmall} >{row.notSettledCouponsAmt}</TableCell>
                                            <TableCell align="left" onClick={(e) => redirectToBusinessCampaignCouponCategoryReport(row,e)} className={classes.tableCellSmall}>{row.settledCoupons}</TableCell>
                                            <TableCell align="left" onClick={(e) => redirectToBusinessCampaignCouponCategoryReport(row,e)} className={classes.tableCellSmall} >{row.settledCouponsAmt}</TableCell>
                                            <TableCell align="left" onClick={(e) => redirectToBusinessCampaignCouponCategoryReport(row,e)} className={classes.tableCellSmall} >{<>{paymentIdArray.length>0?paymentIdArray.map(ele=>ele):" -"}</>}</TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}