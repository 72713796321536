import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';

import Checkbox from '@material-ui/core/Checkbox';

const headCells = [
    { key: 'businessId', disablePadding: false,label: 'Business ID' },
    { key: 'businessName',disablePadding: false, label: 'Business Name' },
    { key: 'serviceId',disablePadding: false, label: 'Service ID' },
    { key: 'service',disablePadding: false, label: 'Service Name' },    //
    // { key: 'status', label: 'Business Status' },
    { key: 'description',disablePadding: true, label:'Item Description'},   
    { key: 'UtcDay',disablePadding: false, label: 'Item Action Day' },
    { key: 'openUtcTime',disablePadding: false, label: 'Item Action Time (IST)' },
    // { key: 'openUtcTime', label: 'Business Open Time' },
    // { key: 'closeUtcTime Time', label: 'Business Close Time' },
    // { key: 'resumeBusinessDate', label: 'Business Resume Date' },
    // { key: 'resumeBusinessTime', label: 'Business Resume Time (IST)' },
    { key: 'actionStatus',disablePadding: false, label: 'Action Status' },
    { key: 'createdAt',disablePadding: false, label: 'Create Time (IST)' },
    { key: 'updatedAt',disablePadding: false, label: 'Update Time (IST)' },
];

function EnhancedTableHead(props) {
    const {classes,numSelected, totalCount, onSelectAllClick} = props;
    
    
    return (
        <TableHead>
            <TableRow>
            <TableCell padding="checkbox">
                <Checkbox
                    indeterminate={numSelected > 0 && numSelected < totalCount}
                    checked={totalCount > 0 && numSelected === totalCount}
                    onChange={onSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                />
            </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        className={classes.tableCellSmall}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Items Availability Logs ({props.totalCount})
            </Typography>

            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"Items_Availability_Logs.csv"}
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                : <Button
                    variant="contained"
                    margin="normal"
                    style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                    onClick={props.fetchCallIconHitRecordsExcel}
                    >
                    Export
                </Button>
                } 
            </Typography> 
        </Toolbar>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableCellSmall: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px"
    },
    tableRow: {
        // cursor: 'pointer'
    },
    tableCellSmall13: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", 
        width: '100%',
        textAlign: 'left',
        marginRight:"25px",
        marginLeft:"25px",
        maxWidth: 300,
        minWidth:155,
        
    },
    tableCellSmall14: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px",
        width: '100%',
        textAlign: 'left',
        marginRight:"25px",
        marginLeft:"25px"
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    // 
    // 

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    let headers = [
        { key: 'businessId', label: 'Business ID' },
        { key: 'businessName', label: 'Business Name' },
        { key: 'serviceId', label: 'Service ID' },
        { key: 'service', label: 'Service Name' },    //
        // { key: 'status', label: 'Business Status' },
        { key: 'description', label: 'Description' }, 
        { key: 'day', label: 'Items Action Day' },
        { key: 'from', label: 'Items Action Time (IST)' }, 
               // { key: 'openUtcTime', label: 'Business Open Time' },
        // { key: 'closeUtcTime Time', label: 'Business Close Time' },
        // { key: 'resumeBusinessDate', label: 'Business Resume Date' },
        // { key: 'resumeBusinessTime', label: 'Business Resume Time (IST)' },
        { key: 'actionStatus', label: 'Action Status' },
        { key: 'createdAt', label: 'Create Time (IST)' },
        { key: 'updatedAt', label: 'Update Time (IST)' },
    ];

    let data = []
    props.exportToExcel && props.exportToExcel.forEach(element => {

        let fromValue;

        if ('availability' in element && 'from' in element.availability.workingHrs && element.availability.workingHrs.from &&
            (element.actionStatus === "TO_BE_AVAILABLE" || element.actionStatus === "AVAILABLE")) {
            fromValue = element.availability.workingHrs.from;
        } else if ('availability' in element && 'to' in element.availability.workingHrs && element.availability.workingHrs.to &&
            (element.actionStatus === "TO_BE_UNAVAILABLE" || element.actionStatus === "UNAVAILABLE")) {
            fromValue = element.availability.workingHrs.to;
        } else {
            fromValue = 'NA';
        }
    
    data.push({
        businessId : element.businessId,
            businessName : element.businessName,          
            serviceId:element.serviceId,
            service:element.service, 
            description:element.description?element.description:"---",
            // status = item.status,
            day : 'availability' in element && 'day' in element.availability.workingHrs && element.availability.workingHrs.day && element.availability.workingHrs.day,
            from: fromValue,
            // resumeBusinessDate = item.resumeBusinessDate,
            // resumeBusinessTime = item.resumeBusinessTime,
            actionStatus : element.actionStatus,
            createdAt : element.createdAt,
            updatedAt : element.updatedAt
    })           
    });

    let totalCount = props.totalCount;
    const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.totalCount - props.page * props.rowsPerPage);

    const isSelected = (_id) => props.selectedItemsAvailabilityIds.indexOf(_id) !== -1;
    const handleCheckBox = (event, _id) => {
        const selectedIndex = props.selectedItemsAvailabilityIds.indexOf(_id);
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(props.selectedItemsAvailabilityIds, _id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(props.selectedItemsAvailabilityIds.slice(1));
        } else if (selectedIndex === props.selectedItemsAvailabilityIds.length - 1) {
          newSelected = newSelected.concat(props.selectedItemsAvailabilityIds.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            props.selectedItemsAvailabilityIds.slice(0, selectedIndex),
            props.selectedItemsAvailabilityIds.slice(selectedIndex + 1),
          );
        }
        props.setSelected(newSelected);
      };
    
      const handleSelectAllClick = (event) => {
        props.setSelected([]);
      };
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar 
                    headers={headers}
                    totalCount={props.totalCount}
                    data={data}
                    showDownloadIcon={props.showDownloadIcon}
                    fetchCallIconHitRecordsExcel={props.fetchCallIconHitRecordsExcel}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            rowCount={props.totalCount}
                            numSelected={props.selectedItemsAvailabilityIds.length}
                            onSelectAllClick={handleSelectAllClick}
                            totalCount={props.totalCount}
                        />
                        <TableBody>
                            {
                                props.data && props.data.map((row, index) => {
                                    const isItemSelected = isSelected(row._id)
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                onClick={(event) => handleCheckBox(event, row._id)}
                                                checked={isItemSelected}
                                                aria-checked={isItemSelected}
                                                selectedCouponIds={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall3} >{row.businessId}</TableCell>
                                            {/* <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall} >{row.orderId && row.orderId!=null && row.orderId!="" ? row.orderId : "NA"}</TableCell> */}
                                            <TableCell align="left" className={classes.tableCellSmall3} title={row.businessName} >{row.businessName && row.businessName.length < 27 ? row.businessName : `${row.businessName.slice(0,27)}...`}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall3}>{row.serviceId}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall13} title={row.service} >{row.service && row.service.length < 20 ? row.service : `${row.service.slice(0,20)}...`}</TableCell> 
                                            <TableCell align="left" className={classes.tableCellSmall} title={row.description}>
                                                {(() => {
                                                    if (!row.description) {
                                                    return "----";
                                                    } else if (row.description.length > 20) {
                                                    return `${row.description.slice(0, 20)}...`;
                                                    } else {
                                                    return row.description;
                                                    }
                                                })()}
                                                </TableCell>
                                            {/* <TableCell align="left" className={classes.tableCellSmall} title={row.description}>{!row.description ? "----":row.description.length >20 ? `${row.description.slice(0,20)}...` :row.description}</TableCell>                                   */}
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.availability.workingHrs && 'day' in row.availability.workingHrs ? row.availability.workingHrs.day : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall12}>
                                                {(() => {
                                                    if (
                                                    row.availability.workingHrs &&
                                                    'from' in row.availability.workingHrs &&
                                                    (row.actionStatus === "TO_BE_AVAILABLE" || row.actionStatus === "AVAILABLE")
                                                    ) {
                                                    return row.availability.workingHrs.from;
                                                    } else if (
                                                    row.availability.workingHrs &&
                                                    'to' in row.availability.workingHrs &&
                                                    (row.actionStatus === "TO_BE_UNAVAILABLE" || row.actionStatus === "UNAVAILABLE")
                                                    ) {
                                                    return row.availability.workingHrs.to;
                                                    } else {
                                                    return 'NA';
                                                    }
                                                })()}
                                                </TableCell>

                                            {/* <TableCell align="left"className={classes.tableCellSmall12} >{row.availability.workingHrs && 'from' in  row.availability.workingHrs && row.actionStatus === "TO_BE_AVAILABLE" || row.actionStatus === "AVAILABLE" ? row.availability.workingHrs.from : row.availability.workingHrs && 'to' in row.availability.workingHrs && row.actionStatus === "TO_BE_UNAVAILABLE" || row.actionStatus === "UNAVAILABLE" ? row.availability.workingHrs.to : 'NA'}</TableCell> */}
                                            {/* <TableCell align="left" className={classes.tableCellSmall} >{row.oldValues && row.oldValues.to ? row.oldValues.to : "NA"}</TableCell> */}
                                            {/* <TableCell align="left" className={classes.tableCellSmall} >{row.resumeBusinessDate ? row.resumeBusinessDate : "NA"}</TableCell> */}
                                            {/* <TableCell align="left" className={classes.tableCellSmall} >{row.resumeBusinessTime ? row.resumeBusinessTime : "NA"}</TableCell> */}
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.actionStatus ? row.actionStatus : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall13}>{row.createdAt ? row.createdAt : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall13}>{row.updatedAt ? row.updatedAt: "NA"}</TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}