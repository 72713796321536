import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

import { changeHeaderName } from '../../actions/dashboardActions';
import { requestRegions, getRegionDetailsById } from '../../actions/regionActions';

const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        // height: '100%',
        overflow: 'auto',
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    divider: {
        width: '100%',
        margin: '0px'
    }
});

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.changeHeaderName("Region Details");
        this.props.requestRegions();
        this.props.getRegionDetailsById(this.props.match.params.regionId)
    }

    render() {
        const { classes } = this.props;

        return (
            <Container>
                <div className={classes.appBarSpacer} />
                <Container className={classes.content}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Typography variant="caption" gutterBottom>
                                Region
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                {this.props.region.regionDetails.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption" gutterBottom>
                                Region Code
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                {this.props.region.regionDetails.regionCode}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="button" gutterBottom>
                                Regional Heads ({this.props.region.regionDetails &&
                                    this.props.region.regionDetails.regionalHeadsData ?
                                    this.props.region.regionDetails.regionalHeadsData.length : 0})
                            </Typography>
                            <Grid item xs={12} style={{ display: "flex" }}>
                                {
                                    this.props.region.regionDetails &&
                                    this.props.region.regionDetails.regionalHeadsData &&
                                    this.props.region.regionDetails.regionalHeadsData.map((regionHead, index) => {
                                        return (
                                            <Grid item xs={4} style={{ marginTop: "15px" }} key={index}>
                                                <Typography variant="subtitle2" gutterBottom style={{ display: "flex", alignItems: "center" }}>
                                                    <Avatar alt="Name" src="" className={classes.small} /> &nbsp;
                                                    {regionHead.firstName} {regionHead.lastName}
                                                </Typography>
                                                <Typography variant="subtitle2" gutterBottom>
                                                    Email : {regionHead.email}
                                                </Typography>
                                                <Typography variant="subtitle2" gutterBottom>
                                                    Contact : {regionHead.mobileNumber}
                                                </Typography>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                        {/* <Divider variant="middle" className={classes.divider} /> */}
                        <Grid item xs={12}>
                            <Typography variant="button" gutterBottom>
                                Areas ({this.props.region.regionDetails &&
                                    this.props.region.regionDetails.areasData ? this.props.region.regionDetails.areasData.length : 0})
                            </Typography>
                            <Grid item xs={12}>
                                {
                                    this.props.region.regionDetails &&
                                    this.props.region.regionDetails.areasData &&
                                    this.props.region.regionDetails.areasData.map((area, index) => {
                                        return (
                                            <Grid item xs={12} style={{ marginTop: "15px" }} key={index}>
                                                <Typography variant="subtitle2" gutterBottom>
                                                    {area.name}
                                                </Typography>
                                                <Typography variant="caption" gutterBottom>
                                                    Area Managers ({area.areamanagers ? area.areamanagers.length : 0})
                                                </Typography>
                                                <Grid item xs={12} style={{ display: "flex" }}>
                                                    {
                                                        area.areamanagers.map((am, i) => {
                                                            return (
                                                                <Grid item xs={4} style={{ marginTop: "15px" }} key={i}>
                                                                    <Typography variant="subtitle2" gutterBottom style={{ display: "flex", alignItems: "center" }}>
                                                                        <Avatar alt="Name" src="" className={classes.small} /> &nbsp;
                                                                    {am.firstName} {am.lastName}
                                                                    </Typography>
                                                                    <Typography variant="subtitle2" gutterBottom>
                                                                        Email : {am.email}
                                                                    </Typography>
                                                                    <Typography variant="subtitle2" gutterBottom>
                                                                        Contact : {am.mobileNumber}
                                                                    </Typography>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                                <Divider variant="middle" className={classes.divider} />
                                            </Grid>

                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>

                <Backdrop className={classes.backdrop} open={this.props.region.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        region: state.region
    }
};

export default connect(mapStateToProps, { changeHeaderName, requestRegions, getRegionDetailsById })(withStyles(styles)(Dashboard));