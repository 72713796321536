import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';

import { changeHeaderName, requestBusinessStats, getServiceProviderDetailsById } from '../../actions/dashboardActions';

const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        // height: '100%',
        overflow: 'auto',
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    divider: {
        width: '100%',
        margin: '0px'
    },
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.changeHeaderName("Service Provider Details");
        this.props.requestBusinessStats();
        this.props.getServiceProviderDetailsById(this.props.match.params.serviceProviderId)
    }

    render() {
        const { classes } = this.props;
        
        return (
            <Container>
                <div className={classes.appBarSpacer} />
                <Container className={classes.content}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Typography variant="caption" gutterBottom>
                                Service Provider
                            </Typography>
                            <Typography variant="h6" gutterBottom style={{ display: "flex", alignItems: "center" }}>
                                <Avatar alt="Name" src={this.props.serviceProviderDetails.photo} className={classes.small} /> &nbsp;
                                {this.props.serviceProviderDetails.firstName} {this.props.serviceProviderDetails.lastName}
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                Email : {this.props.serviceProviderDetails.email}
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                Contact : {this.props.serviceProviderDetails.primaryMobileNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="button" gutterBottom>
                                Businessess ({this.props.serviceProviderDetails &&
                                    this.props.serviceProviderDetails.associatedBusiness && this.props.serviceProviderDetails.associatedBusiness.length ?
                                    this.props.serviceProviderDetails.associatedBusiness.length : 0 })
                            </Typography>
                            <Grid container spacing={3}>
                                {
                                    this.props.serviceProviderDetails &&
                                    this.props.serviceProviderDetails.associatedBusiness &&
                                    this.props.serviceProviderDetails.associatedBusiness.map((business, index) => {
                                    
                                            let contractEndDate = "";
                                            if(business.businessId.businessAgreementObjId && business.businessId.businessAgreementObjId !== null){
                                                contractEndDate = business.businessId.businessAgreementObjId.endDate;
                                            } else {
                                                let activeContract = business.businessId.contracts.filter(contract => {
                                                    return contract.isActive;
                                                });
                                                if (activeContract && activeContract.length > 0) {
                                                    contractEndDate = activeContract[0].endDate;
                                                }
                                            }
                                           
                                            return (
                                                <Grid item xs={4} key={index}>
                                                    <Card className={classes.root} variant="outlined">
                                                        <CardContent>
                                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                                {business.businessId.businessId}
                                                            </Typography>
                                                            <Typography variant="subtitle2" component="h2" style={{ fontSize: "16px" }}>
                                                                {business.businessId.businessName}
                                                            </Typography>
                                                            <Typography variant="body2" className={classes.pos} color="textSecondary">
                                                                Contact : {business.businessId.primaryContactNumber}
                                                            </Typography>
                                                            <Typography variant="body2" component="p">
                                                                Contract End Date : {contractEndDate ? moment(contractEndDate).format('DD MMM YYYY') : "Not Set"}
                                                                <br />
                                                                Region : {business.businessId.regionBelongsTo.name}
                                                                <br />
                                                                Area : {
                                                                    business.businessId.areaBelongsTo ?
                                                                        business.businessId.areaBelongsTo.name : "Not Set"
                                                                }
                                                                <br />
                                                                Role: {business.role && business.role == "businessAdmin" ? "Admin" : business.role && business.role == "businessMember" ? "Staff" : business.role && business.role == "superVendor" ? "Super Admin" : ""}
                                                                <br />
                                                                Status: {business.status && business.status == "ACTIVE" ? "Active" : business.status && business.status == "INACTIVE" ? "Inactive" : ""}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            )
                                        
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>

                <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.dashboard.isFetching,
        serviceProviderDetails: state.dashboard.serviceProviderDetails
    }
};

export default connect(mapStateToProps, { changeHeaderName, requestBusinessStats, getServiceProviderDetailsById })(withStyles(styles)(Dashboard));