import React,{useState,useEffect} from "react";
import PropTypes, { element } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { lightBlue } from '@material-ui/core/colors';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
const headers = [
    { id: "photo", disablePadding: false, label: "" },
    { id: "serviceId", disablePadding: false, label: "ServiceId" },
    { id: "service", disablePadding: false, label: "Service" },
    { id: "head", disablePadding: false, label: "Heads & Subheads" },
    { id: "cost", disablePadding: false, label: "Cost" },
    { id: "availabilty", disablePadding: false, label: "Availability" },
    { id: "isEnabled", disablePadding: false, label: "Is Enabled" },
    { id: "discountEnable", disablePadding: false, label: "Is Discount Enabled" },
    { id: "editAndDeleteButton", disablePadding: false, label: "" }
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, totalCount, role, numSelected, onSelectAllClick, rowsPerPage } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
            {
                role === "areaManager" ?
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && (numSelected < rowsPerPage && numSelected !== totalCount)}
                            checked={rowsPerPage > 0 && (numSelected === rowsPerPage || numSelected === totalCount)}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                    </TableCell> : null
            }
                {headers.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableCellSmall}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        marginTop: theme.spacing(2),
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        // fontSize: "12px",
        padding: "2px 2px 2px 0px",
        fontWeight: 500,
        height: "48px",
        

    },
    tableCellSmall1: {
        // fontSize: "12px",
        padding: "2px 0px 2px 0px",
    },
    lightBlue: {
        color: theme.palette.getContrastText(lightBlue[50]),
        backgroundColor: lightBlue[50],
        width: theme.spacing(4),
        height: theme.spacing(4),
        cursor: 'pointer'
    },
    icon: {
        height: 32,
        //  width: 32
    },
    tableBottomBorder: {
        borderBottom: "none",
        minWidth: 3,
        padding: "6px 24px 6px 0px"
    }
}));

export default function EnhancedTable(props) {

    const [rowData,setRowData]=useState([]);
    
    useEffect(() => {
        if(props.isDiscountDisabledFilter){
            let filteredArray = props.rowData.filter((ele)=>{
                var arr = [];
                arr= ele.serviceOffers.discount.offers.filter((item)=>{
                    return item.isActive
                })
                return arr.length===0
                }
            )
            setRowData(filteredArray)
        } else {
            setRowData(props.rowData)
        }
    }, [props.rowData,props.isDiscountDisabledFilter]);

    const classes = useStyles();
    let selectedServices = props.selected;
    let selectAll = false;
    const handleRequestSort = (event, property) => {
        const isAsc = props.orderBy === property && props.order === "asc";
        props.setOrder(isAsc ? -1 : 1);
        props.setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };


    const handleSelectAllClick = async (event) => {
        if (event.target.checked) {
            const newSelecteds = rowData.map((n) => n._id);
            props.setSelected(newSelecteds);
            return;
        }
        props.setSelected([]);
    };


    function handleClick(event, _id) {
        if (event.target.checked === true) {
            selectedServices.push(_id);
        } else {
            selectedServices = selectedServices.filter(id => id !== _id)
        }
        props.setSelected(selectedServices);
    }

    const isSelected = (_id) => props.selected.indexOf(_id) !== -1;
    return (
        // <div className={classes.root}>
        <Paper className={classes.paper}>
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        order={props.order}
                        orderBy={props.orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.totalCount}
                        selected={props.selected}
                        totalCount={props.totalCount}
                        numSelected={props.selected.length}
                        rowsPerPage={props.rowsPerPage}
                        onSelectAllClick={handleSelectAllClick}
                        role={props.role}
                    />
                    <TableBody>
                        {
                            rowData.map((row, index) => {
                                const isItemSelected = isSelected(row._id)
                                const labelId = `enhanced-table-checkbox-${index}`;
                                var discount=[];
                                var isDiscountActive=false;
                                if(row.serviceOffers && row.serviceOffers.discount && row.serviceOffers.discount.offers){
                                    discount= row.serviceOffers.discount.offers
                                }
                                if(discount.length>0){
                                    var arr = []
                                    arr= discount.filter((item)=>{
                                        return item.isActive
                                    })
                                    if (arr.length>0){
                                        isDiscountActive=true
                                    }
                                }
                                if (row.heads && row.heads.length > 0) {
                                    return (

                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            {props.role === "areaManager" ?
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={(event) => handleClick(event, row._id)}
                                                        checked={isItemSelected}
                                                        aria-checked={isItemSelected}
                                                        selected={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell> : null}

                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                {row.photos[0] && row.photos[0].path ?
                                                    <Avatar style={{ marginRight: "16px", marginLeft: "16px" }} src={row.photos[0].path} variant="square" ></Avatar>
                                                    : <Avatar style={{ marginRight: "16px", marginLeft: "16px" }} variant="square" src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg" ></Avatar>}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                {row.serviceId}
                                            </TableCell>
                                            <TableCell align="left" style={{ paddingRight: "16px" }} className={classes.tableCellSmall}>
                                                {row.service}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                <Table
                                                    aria-labelledby="tableTitle"
                                                    size="small"
                                                    aria-label="enhanced table">
                                                    <TableHead>
                                                    </TableHead>
                                                    <TableBody>
                                                        {row.heads.map((head, i) => {
                                                            // let subH = row.subHeads && row.subHeads.length > 0 && row.subHeads.find(sub => sub.parentId === head._id);
                                                            let subH = row.subHeads && row.subHeads.length > 0 && row.subHeads.find(sub => sub !== null && sub !== undefined && sub.parentId === head._id);
                                                            if (!subH) {
                                                                subH = {
                                                                    name: 'NA'
                                                                }
                                                            }
                                                            return (<TableRow>
                                                                <TableCell align="left" className={classes.tableBottomBorder}>{head.name}</TableCell>
                                                                <TableCell align="left" className={classes.tableBottomBorder}>{subH.name}</TableCell>
                                                            </TableRow>)
                                                        }
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                {row.cost.costType === "Approx" ? `${row.cost.from} - ${row.cost.to}` : row.cost.fixedCost}
                                            </TableCell>
                                            <TableCell align="left" className={classes.unclickableTableCellSmall}>
                                                {
                                                    props.role === "areaManager" ?
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    size="small"
                                                                    checked={row.availability.isActive}
                                                                    onChange={(e) => props.handleSwitchChange(e, row)}
                                                                    name="availability"
                                                                    color="primary"
                                                                />
                                                            }
                                                            style={{ fontSize: "10px", color: row.availability.isActive ? 'black' : 'red' }}
                                                            label={row.availability.isActive ? 'Available' : 'Unavailable'}
                                                        /> :
                                                        row.availability.isActive ? 'Available' : 'Unavailable'
                                                }
                                            </TableCell>
                                            <TableCell align="left" className={classes.unclickableTableCellSmall}>
                                                {
                                                    props.role === "areaManager" ?
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    size="small"
                                                                    checked={row.isEnabled}
                                                                    onChange={(e) => props.handleSwitchChange(e, row)}
                                                                    name="isEnabled"
                                                                    color="primary"
                                                                />
                                                            }
                                                            style={{ fontSize: "10px", color: row.isEnabled ? 'black' : 'red' }}
                                                            label={row.isEnabled ? 'Yes' : 'No'}
                                                        /> :
                                                        row.isEnabled ? 'Enabled' : 'Disabled'
                                                }
                                            </TableCell>
                                            <TableCell align="left" style={{ width:"2px", color: isDiscountActive? 'black' : 'red'}} className={classes.tableCellSmall}>
                                                {isDiscountActive?"Yes":"No"}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableCellSmall}>
                                                {props.role === "areaManager" ?
                                                    <IconButton className={classes.icon} style={{ float: "right" }} onClick={() => props.handleEditOpen(row)}>
                                                        <Avatar className={classes.lightBlue}>
                                                            <EditIcon color="action" />
                                                        </Avatar>
                                                    </IconButton> : <Avatar className={classes.lightBlue}> <VisibilityIcon onClick={(e) => props.handleListItemClick(row, e)} color="action" /></Avatar>}
                                            </TableCell>
                                        </TableRow>

                                    );
                                } else {
                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            {props.role === "areaManager" ?
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={(event) => handleClick(event, row._id)}
                                                        checked={isItemSelected}
                                                        aria-checked={isItemSelected}
                                                        selected={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell> : null}
                                                
                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                {row.photos[0] && row.photos[0].path ?
                                                    <Avatar style={{ marginRight: "16px", marginLeft: "16px" }} src={row.photos[0].path} variant="square"  ></Avatar>
                                                    : <Avatar style={{ marginRight: "16px", marginLeft: "16px" }} variant="square" src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                {row.serviceId}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                {row.service}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                <Table>
                                                    <TableHead>
                                                    </TableHead>
                                                    <TableBody>

                                                        <TableRow>
                                                            <TableCell align="left" className={classes.tableBottomBorder} >NA</TableCell>
                                                            <TableCell align="left" className={classes.tableBottomBorder} >NA</TableCell>

                                                        </TableRow>

                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                {(row.cost && (row.cost.costType === "Approx")) ? `${row.cost.from} - ${row.cost.to}` : (row.cost && row.cost.fixedCost?row.cost.fixedCost:"")}
                                            </TableCell>
                                            <TableCell align="left" className={classes.unclickableTableCellSmall}>
                                                {
                                                    props.role === "areaManager" ?
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    size="small"
                                                                    checked={row.availability.isActive}
                                                                    onChange={(e) => props.handleSwitchChange(e, row)}
                                                                    name="availability"
                                                                    color="primary"
                                                                />
                                                            }
                                                            style={{ fontSize: "10px", color: row.availability.isActive ? 'black' : 'red' }}
                                                            label={row.availability.isActive ? 'Available' : 'Unavailable'}
                                                        />
                                                        :
                                                        row.availability.isActive ? 'Available' : 'Unavailable'
                                                }
                                            </TableCell>
                                            <TableCell align="left" className={classes.unclickableTableCellSmall}>
                                                {
                                                    props.role === "areaManager" ?
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    size="small"
                                                                    checked={row.isEnabled}
                                                                    onChange={(e) => props.handleSwitchChange(e, row)}
                                                                    name="isEnabled"
                                                                    color="primary"
                                                                />
                                                            }
                                                            style={{ fontSize: "10px", color: row.isEnabled ? 'black' : 'red' }}
                                                            label={row.isEnabled ? 'Yes' : 'No'}
                                                        />
                                                        :
                                                        row.isEnabled ? 'Enabled' : 'Disabled'
                                                }
                                            </TableCell>
                                            <TableCell  style={{ paddingRight: "16px", paddingLeft: "16px", color: isDiscountActive ? 'black' : 'red'}} className={classes.tableCellSmall}>
                                                {isDiscountActive?"Yes":"No"}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableCellSmall}>
                                                {props.role === "areaManager" ?
                                                    <IconButton className={classes.icon} style={{ float: "right" }} onClick={() => props.handleEditOpen(row)}>
                                                        <Avatar className={classes.lightBlue}>
                                                            <EditIcon color="action" />
                                                        </Avatar>
                                                    </IconButton> : <Avatar className={classes.lightBlue}> <VisibilityIcon onClick={(e) => props.handleListItemClick(row, e)} color="action" /></Avatar>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
                component="div"
                count={props.totalCount}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
        // </div>
    );
}
