import axios from "axios";

import {
  REQUEST_BUSINESS_ITEMTAG_LOADER,
  REQUEST_BUSINESS_ITEMTAG_LOADER_FALSE,
  GET_BUSINESS_ITEMTAG_REPORT,
  GET_BUSINESS_ITEMTAG_REPORT_OF_EXPORT,
} from "./types";

export const requestBusinessItemTagLoader = () => {
  return {
    type: REQUEST_BUSINESS_ITEMTAG_LOADER,
    payload: true,
  };
};

export const requestBusinessItemTagLoaderFalse = () => {
  return {
    type: REQUEST_BUSINESS_ITEMTAG_LOADER_FALSE,
    payload: false,
  };
};

// Get all the records of Business Item Tag Report
export const getBusinessItemTagRecords = (payload) => async (dispatch) => {
  try {
    const response = await axios.get("/api/businessItemTag/getAllData", {
      params: payload,
    });
    dispatch({ type: GET_BUSINESS_ITEMTAG_REPORT, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_BUSINESS_ITEMTAG_REPORT, payload: error });
  }
};

export const exportBusinessItemTag = (payload) => async (dispatch) => {
  try {
    const response = await axios.get(
      "/api/businessItemTag/exportAllBusinessItemTagReport",
      {
        params: payload,
      }
    );
    dispatch({
      type: GET_BUSINESS_ITEMTAG_REPORT_OF_EXPORT,
      payload: response.data.exportToExcel,
    });
  } catch (error) {
    dispatch({ type: GET_BUSINESS_ITEMTAG_REPORT_OF_EXPORT, payload: error });
  }
};
