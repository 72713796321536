

import axios from "axios";
import {
  PATCH_REPORTED_SUCCESS_MESSAGE,
  PATCH_REPORTED_ERROR_MESSAGE,
  INVITES_STATE_CHG_SUCCESS,
  INVITES_STATE_CHG_ERR,
  SET_INVITES_SUCCESS_MESSAGE,
  SET_INVITES_ERROR_MESSAGE,
  SET_INVITES,
  REQUEST_INVITES
} from "./types";

export const clearMsg = () => async dispatch => {
  let data = {};
  data.msg = null;
  data.ops = null;
  dispatch({ type: SET_INVITES_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: SET_INVITES_ERROR_MESSAGE, payload: data });
};

export const requestInvites = () => {
  return {
    type: REQUEST_INVITES,
    payload: true
  };
};



export const clearInvitesMsg = () => {
  return {
    type: INVITES_STATE_CHG_SUCCESS,
    payload: null
  };
};

export const clearBusiErrorMsg = () => {
  return {
    type: INVITES_STATE_CHG_ERR,
    payload: null
  };
};

export const clearSucessMsg = () => {
  return {
    type: PATCH_REPORTED_SUCCESS_MESSAGE,
    payload: null
  };
};

export const clearErrorMsg = () => {
  return {
    type: PATCH_REPORTED_ERROR_MESSAGE,
    payload: null
  };
};

export const getInvites = (payload) => async dispatch => {
  try {
    dispatch({ type: REQUEST_INVITES, payload: true });
    let invites = await axios.get(`api/invite/referrals`, { params: payload });
    dispatch({
      type: SET_INVITES, payload: invites.data
    });
    dispatch({ type: REQUEST_INVITES, payload: false });
  } catch (err) {
    console.log("Referrals Error==========>", err);
    dispatch({
      type: SET_INVITES_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: "Error while fetching Referrals." }
    });
  }
};