import {
    SET_NOTICE_APP_SUCCESS_MESSAGE,
    SET_NOTICE_APP_ERROR_MESSAGE,
    GET_ALL_NIOTICES,
    GET_ALL_EXPORT_NOTICES,
    GET_UPDATE_FLAG_SUCCESS_MESSAGE,
    GET_UPDATE_FLAG_ERROR_MESSAGE,
    GET_UPDATE_NOTICE_SUCCESS_MESSAGE,
    GET_UPDATE_NOTICE_ERROR_MESSAGE,
    GET_DELETED_NOTICE_SUCCESS_MESSAGE,
    GET_DELETED_NOTICE_ERROR_MESSAGE
} from "../actions/types";

const INITIAL_STATE = {
    sucessMsg: null,
    errorMsg: null,
    ops: "",
    isFetching: false,
    noticesList: [],
    exportToExcel: [],
    page: 0,
    skip: 0,
    limit: 10,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case SET_NOTICE_APP_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case SET_NOTICE_APP_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case GET_UPDATE_FLAG_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case GET_UPDATE_FLAG_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case GET_UPDATE_NOTICE_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case GET_UPDATE_NOTICE_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case   GET_DELETED_NOTICE_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case GET_DELETED_NOTICE_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case GET_ALL_NIOTICES:
            return {
                ...state,
                noticesList: action.payload,

            };
        case GET_ALL_EXPORT_NOTICES:
            return {
                ...state,
                exportToExcel: action.payload
            };

        default:
            return state;
    }
}