import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

import { changeHeaderName } from '../../actions/dashboardActions';
import { requestInvites, getInvites } from '../../actions/inviteActions';
import InvitesTable from './InvitesTable';

const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    content: {
        flexGrow: 1,
        height: '100%',
        // overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

class Invites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: -1,
            orderBy: 'dateOfInvite',
            page: 0,
            rowsPerPage: 10,
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            referralCode: "",
            firstName: "",
            lastName: "",
            InviteeMobile: "",
            InvitedByName: "",
            InvitedByMobile: "",
            CouponIssuedStatus: "",
            preparexportToExcel: false,
        };
    }

    componentDidMount() {
        this.props.changeHeaderName("Referral Report");
        this.fetchInvites();
    }

    fetchInvites = async () => {
        this.props.requestInvites();
        let couponStatus = typeof this.state.CouponIssuedStatus == "boolean"?this.state.CouponIssuedStatus:null;
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            mobileNumber: this.state.InviteeMobile.trim(),
            referredByName: this.state.InvitedByName.trim(),
            InvitedByMobile: this.state.InvitedByMobile.trim(),
            referralCode: this.state.referralCode.trim(),
            CouponIssuedStatus: couponStatus,
            exportToexcel: 0,
        }
        await this.props.getInvites(payload);
        if (this.state.preparexportToExcel) {
            await this.setState({ preparexportToExcel: false });
        }
    }

    fetchInvitesExcelFile = async () => {
        this.props.requestInvites();
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            mobileNumber: this.state.InviteeMobile.trim(),
            referredByName: this.state.InvitedByName.trim(),
            InvitedByMobile: this.state.InvitedByMobile.trim(),
            referralCode: this.state.referralCode.trim(),
            CouponIssuedStatus: this.state.CouponIssuedStatus,
            exportToexcel: 1,
        }
        await this.props.getInvites(payload);
        await this.setState({ preparexportToExcel: true });
    }


    setOrder = (order) => {
        this.setState({ order }, () => this.fetchInvites());
    }

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetchInvites());
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetchInvites());
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage }, () => this.fetchInvites());
    }

    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        // picker.endDate.add(1, "days");
        this.setState({
            // filterStartDate: picker.startDate.format('DD MMM YYYY'),
            // filterEndDate: picker.endDate.format('DD MMM YYYY')
            filterStartDate: picker.startDate.format(),
            filterEndDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ dateRange: chosenLabel });
    }

    resetFilter = () => {
        this.setState({
            order: -1,
            orderBy: 'dateOfInvite',
            page: 0,
            rowsPerPage: 10,
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            firstName: "",
            lastName: "",
            InviteeMobile: "",
            InvitedByName: "",
            InvitedByMobile: "",
            referralCode: "",
            CouponIssuedStatus: null
        }, () => {
            this.fetchInvites();
            let payload = {
                orderBy: this.state.orderBy,
                order: Number(this.state.order),
                skip: this.state.page * this.state.rowsPerPage,
                limit: this.state.rowsPerPage,
                filterStartDate: this.state.filterStartDate,
                filterEndDate: this.state.filterEndDate,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                mobileNumber: this.state.InviteeMobile,
                referredByName: this.state.InvitedByName,
                InvitedByMobile: this.state.InvitedByMobile,
                referralCode: this.state.referralCode,
                CouponIssuedStatus: this.state.CouponIssuedStatus
            }
            this.props.getInvites(payload);
        })
    }

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    
    ITEM_HEIGHT = 48;
    ITEM_PADDING_TOP = 8;
    MenuProps = {
        PaperProps: {
            style: {
                maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    render() {
        const { classes } = this.props;
        return (
            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Search Referrals</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={3} style={{ alignItems: 'center' }}>
                                <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={this.state.ranges}
                                        onApply={this.handleDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" label="Referrals Date Range" value={this.state.dateRange} style={{ width: "225px" }} variant="outlined" />
                                    </DateRangePicker>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="InvitedByName" label="Referring User Name" value={this.state.InvitedByName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="firstName" label="Referred User First Name" value={this.state.firstName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="lastName" label="Referred User Last Name" value={this.state.lastName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="referralCode" label="Referral Code" value={this.state.referralCode} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="InviteeMobile" label="Referred User Mobile No." value={this.state.InviteeMobile} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl variant="filled" fullWidth className={classes.formControl} >
                                        <InputLabel id="CouponIssuedStatus-label">Coupon Issued Status</InputLabel>
                                        <Select
                                            labelId="CouponIssuedStatus-label"
                                            name="CouponIssuedStatus"
                                            value={this.state.CouponIssuedStatus}
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            MenuProps={this.MenuProps}
                                        >
                                            <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>
                                                <ListItemText primary="Yes" />
                                            </MenuItem>
                                            <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>
                                                <ListItemText primary="No" />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        // onClick={this.fetchInvites}
                                        onClick={()=>this.setPage(0)}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                <Container className={classes.container}>
                    {
                        this.props.invites && this.props.invites.data ?
                            <InvitesTable
                                data={this.props.invites}
                                exportToexcel={this.props.invites.exportToexcel}
                                order={this.state.order === 1 ? 'asc' : 'desc'}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                currentStatus={this.state.currentStatus}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                history={this.props.history}
                                fetchInvitesExcelFile={this.fetchInvitesExcelFile}
                                showDownloadIcon={this.state.preparexportToExcel}
                            />
                            : null
                    }
                </Container>
                <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.invites.isFetching,
        invites: state.invites.invites
    }
};

export default connect(mapStateToProps, { changeHeaderName, requestInvites, getInvites })(withStyles(styles)(Invites));