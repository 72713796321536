import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { changeHeaderName } from '../../actions/dashboardActions';
import {  updateReview } from '../../actions/businessActions';
import { reviewReport, requestServiceRequests } from "../../actions/serviceRequestActions";
import ReviewReportTable from './ReviewReportTable';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Input from '@material-ui/core/Input';
import { getConsumerTagList} from "../../actions/consumerTagAction";
import { getBusinessTagList} from "../../actions/businessTagAction";
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        backgroundColor: '#fff'
    },
    table: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    content: {
        flexGrow: 1,
        height: '100%',
        width: '100%',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        minWidth: 225,
        maxWidth: 300,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    }
});

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
  },
  getContentAnchorEl: null,
};

class reviewReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: -1,
            orderBy: 'time',
            page: 0,
            rowsPerPage: 10,
            businessId: "",
            businessName: "",
            consumerId: "",
            consumerFirstName: "",
            consumerLastName: "",
            serviceRequestId: "",
            // fromDate: "",
            // toDate: "",
            preparexportToExcel: false,
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            consumerTags:[],
            businessTags:[],
            filteredConsumerTags:[],
            filteredBusinessTags:[]
        };
    }

    componentDidMount() {
        this.props.changeHeaderName("Reviews & Ratings Report");
        this.fetch();
        this.fetchConsumerTags();
        this.fetchBusinessTags();
    }

    fetch = async () => {
        this.props.requestServiceRequests();
        let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            businessId: this.state.businessId,
            businessName: this.state.businessName,
            consumerId: this.state.consumerId,
            consumerFirstName: this.state.consumerFirstName.trim(),
            consumerLastName: this.state.consumerLastName.trim(),
            serviceRequestId: this.state.serviceRequestId,
            // fromDate: this.state.fromDate ? (moment(this.state.fromDate).format('YYYY-MM-DD, 00:00:00')) : null,
            // toDate: this.state.toDate ? (moment(this.state.toDate).format('YYYY-MM-DD, 23:59:59')) : null,
            exportToexcel: 0,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            filteredConsumerTags:filteredConsumerTagsVar,
            filteredBusinessTags:filteredBusinessTagsVar
        }
        await this.props.reviewReport(payload);
        if (this.state.preparexportToExcel) {
           this.setState({ preparexportToExcel: false });
        }
    }

    fetchConsumerTags=async()=>{
        const response=await getConsumerTagList();
        if(response){
        this.setState({consumerTags:response.data})
    }else{
        this.setState({consumerTags:[]})
      }
    }

    fetchBusinessTags=async()=>{
        const response=await getBusinessTagList();
        if(response){
          this.setState({businessTags:response.data})
        }else{
          this.setState({businessTags:[]})
        }
        
    }




    fetchExcelFile = async () => {
        this.props.requestServiceRequests();
        let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            businessId: this.state.businessId,
            businessName: this.state.businessName,
            consumerId: this.state.consumerId,
            consumerFirstName: this.state.consumerFirstName.trim(),
            consumerLastName: this.state.consumerLastName.trim(),
            serviceRequestId: this.state.serviceRequestId,
            fromDate: this.state.fromDate ? (moment(this.state.fromDate).format('YYYY-MM-DD[T00:00:00.000Z]')) : null,
            toDate: this.state.toDate ? (moment(this.state.toDate).format('YYYY-MM-DD[T23:59:59.999Z]')) : null,
            exportToexcel: 1,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            filteredConsumerTags:filteredConsumerTagsVar,
            filteredBusinessTags:filteredBusinessTagsVar
        }
        await this.props.reviewReport(payload);
       this.setState({ preparexportToExcel: true });
    }


    setOrder = (order) => {
        this.setState({ order }, () => this.fetch());
    }

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetch());
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetch());
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    }

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    resetFilter = () => {
        this.setState({
            order: -1,
            orderBy: 'updatedAt',
            page: 0,
            rowsPerPage: 10,
            businessId: "",
            businessName: "",
            consumerId: "",
            consumerFirstName: "",
            consumerLastName: "",
            serviceRequestId: "",
            fromDate: "",
            toDate: "",
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            preparexportToExcel: false,
            filteredConsumerTags:[],
            filteredBusinessTags:[]
        }, () => {
            this.fetch();
        })
    }
    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        // picker.startDate.add(1, "days");
        this.setState({
            // filterStartDate: picker.startDate.format('DD MMM YYYY'),
            // filterEndDate: picker.endDate.format('DD MMM YYYY')
            filterStartDate: picker.startDate.format(),
            filterEndDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ dateRange: chosenLabel });
    }

    enterPressed = (event) => {
        let code = event.keyCode;
        if (code === 13) {
            event.preventDefault();
            this.fetch();
        }
    }
    handleReviewSwitchChange= async (payload)=>{
       await this.props.updateReview(payload);
       await this.fetch();
    }
    render() {
        const { classes } = this.props;
        return (
            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Filter Reviews</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={4}>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="businessId" label="Business Id" value={this.state.businessId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="businessName" label="Business Name" value={this.state.businessName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerId" label="Consumer Id" value={this.state.consumerId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerFirstName" label="Consumer First Name" value={this.state.consumerFirstName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerLastName" label="Consumer Last Name" value={this.state.consumerLastName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="serviceRequestId" label="Service Request Id" value={this.state.serviceRequestId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                {/* <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        name="fromDate"
                                        label="From Date"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={(this.state.fromDate && this.state.toDate && moment(this.state.fromDate).isAfter(this.state.toDate)) ? "Pease Select Valid From time" : null}
                                        helperText={(this.state.fromDate && this.state.toDate && moment(this.state.fromDate).isAfter(this.state.toDate)) ? "Pease Select Valid Date" : null}
                                        value={this.state.fromDate}
                                        onChange={this.handleFilterChange}
                                    />
                                </Grid> */}
                                {/* <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        name="toDate"
                                        label="To Date"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={(this.state.fromDate && this.state.toDate && moment(this.state.fromDate).isAfter(this.state.toDate)) ? "Pease Select Valid From time" : null}
                                        helperText={(this.state.fromDate && this.state.toDate && moment(this.state.fromDate).isAfter(this.state.toDate)) ? "Pease Select Valid Date" : null}
                                        value={this.state.toDate}
                                        disabled={!this.state.fromDate}
                                        onChange={this.handleFilterChange}
                                    />
                                </Grid> */}

                                <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={this.state.ranges}
                                        onApply={this.handleDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="orderDates" label="Review Report Date Range" value={this.state.dateRange}  style={{ width: "260px" }}variant="outlined" />
                                    </DateRangePicker>
                                </Grid>
                                <Grid item xs={3} style={{marginLeft:"2px"}}>
                                {/* <Typography variant="subtitle2" gutterBottom> Select Consumer Tags</Typography> */}
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Consumer Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredConsumerTags}
                                            name="filteredConsumerTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {this.state.consumerTags?.length && this.state.consumerTags?.map((eachConsumerTagObj) => (
                                                <MenuItem key={eachConsumerTagObj._id} value={eachConsumerTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredConsumerTags.indexOf(eachConsumerTagObj.name) > -1} />
                                                    <ListItemText primary={eachConsumerTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> 
                                <Grid item xs={3}>
                    {/* <Typography variant="subtitle2" gutterBottom> Select Business Tags</Typography> */}
                          <FormControl variant="outlined" fullWidth className={classes.formControl} >
                              <InputLabel id="demo-mutiple-checkbox-label">Select Business Tags</InputLabel>
                              <Select
                                  labelId="demo-mutiple-checkbox-label"
                                  id="demo-mutiple-checkbox"
                                  multiple
                                  value={this.state.filteredBusinessTags}
                                  name="filteredBusinessTags"
                                  onChange={this.handleFilterChange}
                                  input={<Input />}
                                  renderValue={(selected) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                  >
                                  {this.state.businessTags?.length && this.state.businessTags?.map((eachBusinessTagObj) => (
                                      <MenuItem key={eachBusinessTagObj._id} value={eachBusinessTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                          <Checkbox checked={this.state.filteredBusinessTags.indexOf(eachBusinessTagObj.name) > -1} />
                                          <ListItemText primary={eachBusinessTagObj.name} />
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                                </Grid>
                               
                                <Grid item xs={12}></Grid>
                                <Grid item xs={8}></Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        onClick={
                                            ()=>this.setPage(0)
                                            // this.fetch
                                        }
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                <Container className={classes.table}>
                    {
                        this.props.serviceRequest && this.props.serviceRequest.reviewReport && this.props.serviceRequest.reviewReport.data && this.props.serviceRequest.reviewReport.total > 0 ?
                            <ReviewReportTable
                                data={this.props.serviceRequest.reviewReport}
                                exportToexcel={this.props.serviceRequest.reviewReport.exportToexcel}
                                order={this.state.order === 1 ? 'asc' : 'desc'}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                handleFilterChange={this.handleFilterChange}
                                history={this.props.history}
                                fetchExcelFile={this.fetchExcelFile}
                                showDownloadIcon={this.state.preparexportToExcel}
                                handleReviewSwitchChange={this.handleReviewSwitchChange}
                            />
                            : null
                    }
                </Container>
                <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.serviceRequest.isFetching,
        consumerList: state.dashboard.consumerList,
        serviceRequest: state.serviceRequest,
        admin: state.admin,
        business: state.business
    }
};

export default connect(mapStateToProps, { changeHeaderName, reviewReport, updateReview, requestServiceRequests})(withStyles(styles)(reviewReports));