import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import { ReactComponent as UNLINK } from '../../../../images/Unlink.svg';
import { ReactComponent as DisabledUnlink } from '../../../../images/DisabledUnlink.svg';
import { ReactComponent as Veg } from '../../../../images/veg.svg';
import { ReactComponent as Egg } from '../../../../images/Egg.svg';
import { ReactComponent as Nonveg } from '../../../../images/Nonveg.svg';
import { IconButton, Avatar } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { Checkbox } from '@material-ui/core';
import { unLinkItemFromAddonGroup } from '../../../../actions/addonGroupAction'


const styles = theme => ({
    form: {
        width: '45%',
        height: 'fit-content',
        backgroundColor: "white",
        overflow: "hidden",
        flexGrow: 1,
        border: "2px solid #3d8bc3",
        borderRadius: "5px!important",
        margin: ' 24px 400px 20px 400px',
    },

    header: {
        height: '8%',
        margin: '0px 0px',
        width: '-webkit-fill-available',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,

    },

    CloseIcon: {
        float: "right",
        color: "white"
    },

    headerText: {
        color: "white",
        fontSize: "20px",
        fontFamily: "serif",
        marginLeft: '10px'
    },

    footer: {
        height: '10%',
        padding: '20px 0px 10px 150px',
        display: 'flex',

    },

    body: {
        fontFamily: "serif",
        padding: "10px 0px"
    },
    bodyMainContainer: {
        padding: "5px 4px"
    },

    dynamicForm: {
        width: '45%',
        height: '90%',
        backgroundColor: "white",
        overflow: "auto",
        flexGrow: 1,
        border: "2px solid #3d8bc3",
        borderRadius: "10px!important", '&::-webkit-scrollbar': {
            width: '1px',
            backgroundColor: 'white',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'white',
            borderRadius: '1px'
        },
        margin: '20px 400px 20px 400px',
    },
    bodyHeader: {
        fontSize: "16px",
        fontFamily: "serif",
        margin: '5px 10px',
        font: 'math'
    },
    stickyForm: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: theme.palette.primary.main,
    },
    defaultColor: {
        color: '#F9AE00',
        fontFamily: 'Open Sans, Semibold',
        fontSize: '14px'
    },
    textColor: {
        color: '#202035',
        fontFamily: 'Open Sans, Semibold',
        fontSize: '14px'
    },
    bodyHeader: {
        padding: '2px 0px 0px 20px'
    }



});

function EnhancedTableHead(props) {

    return (
        <TableHead>
            <TableRow>
                <TableCell align='right' > </TableCell>
                <TableCell align='left' >Item Id</TableCell>
                <TableCell align='left' >Item Name</TableCell>
                <TableCell align='left' >Cost</TableCell>
              { props.role === "areaManager" ?  <TableCell align='left' ><Checkbox onChange={(event) => props.handleSelectAll(event)} /></TableCell> : <TableCell></TableCell>}
            </TableRow>
        </TableHead>
    );
}


class LinkedItemToAddonGroup extends React.Component {
    constructor() {
        super();
        this.state = {
            linkedServiceItems: []
        };
        this.inputReference = React.createRef();
    }

    componentDidMount = async () => {
     this.setData()
    }


    setData = () => {
        let { linkedServiceItems } = this.props
        if (linkedServiceItems && linkedServiceItems.length) {
            linkedServiceItems.forEach(element => element.isSelected = false)
            this.setState({ linkedServiceItems: linkedServiceItems })
        }
    }


    handleItemSelection = (event, _id) => {
        let { linkedServiceItems } = this.state
        for (let index = 0; index < linkedServiceItems.length; index++) {
            if (JSON.stringify(linkedServiceItems[index]._id) === JSON.stringify(_id)) {
                linkedServiceItems[index].isSelected = event.target.checked;
                break;
            }
        }
        this.setState({ linkedServiceItems: linkedServiceItems })
    }

    handleSelectAll = (event) => {
        let { linkedServiceItems } = this.state
        if (event.target.checked) {
            linkedServiceItems.forEach(element => element.isSelected = true)
            this.setState({ linkedServiceItems: linkedServiceItems })
        } else {
            linkedServiceItems.forEach(element => element.isSelected = false)
            this.setState({ linkedServiceItems: linkedServiceItems })
        }
    }


    onSubmit = async () => {
        const selectedIds = this.state.linkedServiceItems.filter(value => value.isSelected === true).map(value => value._id);
        if (selectedIds.length) {
            let payload ={
                addonGroupId : this.props.addonGroupId,
                itemIds : selectedIds
        }
            await this.props.unLinkItemFromAddonGroup(payload)
        }
        this.props.closeLinkedItemToGroup()
    }


    render() {
        const { classes } = this.props;
        const { linkedServiceItems } = this.state

        return (
            <div className={linkedServiceItems && linkedServiceItems.length > 9 ? classes.dynamicForm : classes.form}>
                <div className={classes.appBarSpacer} >
                    <Grid container className={linkedServiceItems && linkedServiceItems.length > 9 ? classes.stickyForm : classes.header} spacing={1}>
                        <Grid item xs={8}>
                            <Typography variant="h5" gutterBottom className={classes.headerText}>
                                Linked Items
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <CloseIcon className={classes.CloseIcon} onClick={() => { this.props.closeLinkedItemToGroup() }} />
                        </Grid>
                    </Grid>
                    <Container className={classes.body}>
                        <Typography className={classes.bodyHeader}>Please Select Items to Unlink from Addon Group</Typography>
                        <Table size='small'>
                            <EnhancedTableHead
                                handleSelectAll={this.handleSelectAll}
                                role={this.props.role}

                            />
                            <TableBody>
                                {linkedServiceItems && linkedServiceItems.length ? linkedServiceItems.map((row) => {
                                    return (
                                        <TableRow key={row._id}>
                                            <TableCell style={{ padding: "0px" }}>
                                                {row.foodType && row.foodType === 'Veg' ?
                                                    <Avatar style={{ background: "none" }}> <Veg /></Avatar> : null}
                                                {row.foodType && row.foodType === 'NonVeg' ?
                                                    <Avatar style={{ background: "none" }}> <Nonveg /></Avatar> : null}
                                                {row.foodType && row.foodType === 'Egg' ?
                                                    <Avatar style={{ background: "none" }}> <Egg /></Avatar> : null}
                                            </TableCell>
                                            <TableCell align='left'>{row.serviceId}</TableCell>
                                            <TableCell align='left' style={{ minWidth: '320px!important' }}>{row.service}</TableCell>
                                            <TableCell align='left'>{row.cost?.fixedCost}</TableCell>
                                            {this.props.role === "areaManager" ?

                                                <TableCell style={{ padding: "0px 0px 0px 14px" }}>
                                                    <Checkbox checked={row.isSelected} onClick={(event) => this.handleItemSelection(event, row._id)} />
                                                </TableCell> : <TableCell></TableCell>}
                                        </TableRow>
                                    )
                                }) : <Typography>No Records Available</Typography>}
                            </TableBody>
                        </Table>
                    </Container>
                    <Container style={{ display: "flex", maxWidth: "60px" }}>

                        <Button
                            fullWidth
                            variant="contained"
                            margin="normal"
                            color="default"
                            onClick={() => { this.props.closeLinkedItemToGroup() }}
                            style={{ margin: "10px", padding: '5px 50px' }}>
                            CANCEL
                        </Button>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            margin="normal"
                            style={{ margin: "10px", padding: '5px 50px' }}
                            onClick={this.onSubmit}
                            color="primary">
                            SAVE
                        </Button>
                    </Container>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {

    };
};
export default connect(mapStateToProps, {
    unLinkItemFromAddonGroup
})(withStyles(styles)(LinkedItemToAddonGroup));

