import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Avatar from '@material-ui/core/Avatar';
import { changeHeaderName } from '../../actions/dashboardActions';
import { getAllServiceRequests, requestServiceRequests, exportToExcel,itemReportToExcel,findAllConversations } from '../../actions/serviceRequestActions';
import { getCategoriesByType } from '../../actions/categoriesActions';
import ServiceRequestsTable from './Table';
import RequestStatusMultiSelectDropdown from './RequestStatusMultiSelectDropdown';
import PaymentStatusMultiSelectDropdown from './PaymentStatusMultiSelectDropdown';
import CategoriesMultiSelectDropdown from './CategoriesMultiSelectDropdown';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import { getConsumerTagList} from "../../actions/consumerTagAction";
import { getBusinessTagList} from "../../actions/businessTagAction";
import Modal from '@material-ui/core/Modal';
import ConversationsTable from './ConversationsTable';


const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        minWidth: 225,
        maxWidth: 300,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    },
    asap: {
        height: "25px",
        width: "25px",
        backgroundColor: "#000000",
        borderRadius: "50%",
        display: "inline-block",
      },
    customerPrefferedTime: {
        height: "25px",
        width: "25px",
        backgroundColor: "#2AB3F7",
        borderRadius: "50%",
        display: "inline-block",
      },
    businessRescheduledTime: {
        height: "25px",
        width: "25px",
        backgroundColor: "#F8B051",
        borderRadius: "50%",
        display: "inline-block",
      },
    scheduleLater: {
        height: "25px",
        width: "25px",
        backgroundColor: "#70C87E",
        borderRadius: "50%",
        display: "inline-block",
      },
    preOrders: {
        height: "25px",
        width: "25px",
        backgroundColor: "#C7709B",
        borderRadius: "50%",
        display: "inline-block",
      },
      preparationTimer: {
        height: "25px",
        width: "25px",
        backgroundColor: "#FF0000",
        borderRadius: "50%",
        display: "inline-block",
      }
});

const deliveryType = [ 'dunzo', 'borzo', 'Porter', 'Shadowfax', 'Xirify Delivery', 'self'];
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class ServiceRequests extends Component {
    constructor(props) {
        super(props);
            this.state = JSON.parse(window.localStorage.getItem('serviceRequestDetails')) || {

            order: -1,
            orderBy: 'creationDate',
            page: 0,
            // rowsPerPage: 10,
            rowsPerPage: 50,
            serviceRequestId: "",
            creationDate: "",
            currentStatus: [],
            paymentStatus: [],
            selectedCategories: [],
            filteredCategoryIds: [],
            // businessId: "",
            // businessName: "",
            businessFilter:"",
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            ranges: { },
            // consumerFirstName: "",
            // consumerLastName: "",
            consumerFilter:"",
            consumerMobileNumber:"",
            consumerId: "",
            preparexportToExcel: false,
            preparItemReportToExcel: false,
            couponCode:"",
            pnCode:"",
            serviceMode:"",
            deliveryType:[],
            advancePaid:"",
            adjustment : "",
            acceptedBy:"",
            consumerTags:[],
            businessTags:[],
            filteredConsumerTags:[],
            filteredBusinessTags:[],
            isAdvancePaymentDoneToBusiness: "",
            modalOpen: false,
            modalClose:false,
            conversationsflag: this.props.serviceRequest.serviceRequests.conversationsflag,
            supportConversations:"",
            prePaymentOrders : "",
            refundApplied: "",
            manualDelivery : ""
            
        }
    }
    componentDidMount() {
        this.props.changeHeaderName("Service Requests");
        this.fetchOrdersByQuery();
        this.props.getCategoriesByType("category");
        this.fetchConsumerTags();
        this.fetchBusinessTags();
        this.state.modalClose = this.props?.serviceRequest?.serviceRequests?.conversationsflag 

        window.addEventListener("beforeunload", () => localStorage.removeItem('serviceRequestDetails'));
    }

    fetchOrdersByQuery = async () => {
        let deliveryArr = this.state.deliveryType;
		let deliveryfinalArray = deliveryArr.map((value) => value === "Xirify Delivery" ? "xirify_delivery" : (value === 'Porter' ? 'porter' : (value === 'Shadowfax' ? 'shadowfax' : value)));
        let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
        this.props.requestServiceRequests();
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            serviceRequestId: this.state.serviceRequestId.trim(),
            // businessId: this.state.businessId.trim(),
            // businessName: this.state.businessName.trim(),
            businessName:this.state.businessFilter.trim(),
            currentStatus: this.state.currentStatus,
            paymentStatus: this.state.paymentStatus,
            categories: this.state.filteredCategoryIds,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            // consumerFirstName: this.state.consumerFirstName.trim(),
            // consumerLastName: this.state.consumerLastName.trim(),
            consumerName: this.state.consumerFilter.trim(),
            consumerMobileNumber:this.state.consumerMobileNumber.trim(),
            consumerId: this.state.consumerId.trim(),
            couponCode:this.state.couponCode.trim(),
            pnCode:this.state.pnCode,
            exportToexcel: 0,
            itemReportToExcel:0,
            serviceMode:this.state.serviceMode,
            deliveryType: deliveryfinalArray,
            advancePaid:this.state.advancePaid,
            isAdvancePaymentDoneToBusiness:this.state.isAdvancePaymentDoneToBusiness,
            adjustment : this.state.adjustment,
            acceptedBy : this.state.acceptedBy,
            filteredConsumerTags:filteredConsumerTagsVar,
            filteredBusinessTags:filteredBusinessTagsVar,
            supportConversations:this.state.supportConversations,
            prePaymentOrders:this.state.prePaymentOrders,
            refundApplied:this.state.refundApplied,
            manualDelivery:this.state.manualDelivery
        }
        await this.props.getAllServiceRequests(payload);
        if (this.state.preparexportToExcel || this.state.preparItemReportToExcel) {
            this.setState({ preparexportToExcel: false,preparItemReportToExcel:false });
        }
        localStorage.setItem("serviceRequestDetails", JSON.stringify(this.state));
    }


    fetchOrdersByQueryExcelFile = async () => {
        let deliveryArr = this.state.deliveryType;
        let deliveryfinalArray = deliveryArr.map((value) => value === "Xirify Delivery" ? "xirify_delivery" : (value === 'Porter' ? 'porter' : (value === 'Shadowfax' ? 'shadowfax' : value)));
        let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
            
        this.props.requestServiceRequests();
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            serviceRequestId: this.state.serviceRequestId.trim(),
            // businessId: this.state.businessId.trim(),
            // businessName: this.state.businessName.trim(),
            businessName:this.state.businessFilter.trim(),
            currentStatus: this.state.currentStatus,
            paymentStatus: this.state.paymentStatus,
            categories: this.state.filteredCategoryIds,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            // consumerFirstName: this.state.consumerFirstName.trim(),
            // consumerLastName: this.state.consumerLastName.trim(),
            consumerName: this.state.consumerFilter.trim(),
            consumerMobileNumber:this.state.consumerMobileNumber.trim(),
            consumerId: this.state.consumerId.trim(),
            couponCode:this.state.couponCode.trim(),
            pnCode:this.state.pnCode,
            serviceMode:this.state.serviceMode,
            deliveryType: deliveryfinalArray,
            advancePaid:this.state.advancePaid,
            isAdvancePaymentDoneToBusiness:this.state.isAdvancePaymentDoneToBusiness,
            adjustment : this.state.adjustment,
            acceptedBy : this.state.acceptedBy,
            exportToexcel: 1,
            itemReportToExcel:0,
            filteredConsumerTags:filteredConsumerTagsVar,
            filteredBusinessTags:filteredBusinessTagsVar,
            supportConversations: this.state.supportConversations,
            prePaymentOrders:this.state.prePaymentOrders,
            refundApplied:this.state.refundApplied,
            manualDelivery:this.state.manualDelivery
        }
        await this.props.exportToExcel(payload);
       // await this.props.getAllServiceRequests(payload);
         this.setState({ preparexportToExcel: true ,preparItemReportToExcel:false});

    }
    fetchOrdersByQueryItemReportExcelFile = async () => {
        let deliveryArr = this.state.deliveryType;
        let deliveryfinalArray = deliveryArr.map((value) => value === "Xirify Delivery" ? "xirify_delivery" : (value === 'Porter' ? 'porter' : (value === 'Shadowfax' ? 'shadowfax' : value)));
        let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
            
        this.props.requestServiceRequests();
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            serviceRequestId: this.state.serviceRequestId.trim(),
            // businessId: this.state.businessId.trim(),
            // businessName: this.state.businessName.trim(),
            businessName:this.state.businessFilter.trim(),
            currentStatus: this.state.currentStatus,
            paymentStatus: this.state.paymentStatus,
            categories: this.state.filteredCategoryIds,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            // consumerFirstName: this.state.consumerFirstName.trim(),
            // consumerLastName: this.state.consumerLastName.trim(),
            consumerName: this.state.consumerFilter.trim(),
            consumerMobileNumber:this.state.consumerMobileNumber.trim(),
            consumerId: this.state.consumerId.trim(),
            couponCode:this.state.couponCode.trim(),
            pnCode:this.state.pnCode,
            serviceMode:this.state.serviceMode,
            deliveryType: deliveryfinalArray,
            advancePaid:this.state.advancePaid,
            isAdvancePaymentDoneToBusiness:this.state.isAdvancePaymentDoneToBusiness,
            adjustment : this.state.adjustment,
            acceptedBy : this.state.acceptedBy,
            itemReportToExcel: 1,
            exportToExcel:0,
            filteredConsumerTags:filteredConsumerTagsVar,
            filteredBusinessTags:filteredBusinessTagsVar,
            supportConversations: this.state.supportConversations,
            prePaymentOrders:this.state.prePaymentOrders,
            refundApplied:this.state.refundApplied,
            manualDelivery:this.state.manualDelivery
        }
        await this.props.itemReportToExcel(payload);
       // await this.props.getAllServiceRequests(payload);
         this.setState({ preparItemReportToExcel: true });

    }

    fetchConsumerTags=async()=>{
        const response=await getConsumerTagList();
        if(response){
        this.setState({consumerTags:response.data})
    }else{
        this.setState({consumerTags:[]})
      }
    }

    fetchBusinessTags=async()=>{
        const response=await getBusinessTagList();
        if(response){
          this.setState({businessTags:response.data})
        }else{
          this.setState({businessTags:[]})
        }
        
    }

    setOrder = (order) => {
        this.setState({ order }, () => this.fetchOrdersByQuery());
    }

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetchOrdersByQuery());
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetchOrdersByQuery());
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    }

    handleFilterChange = (event) => {
        if (event.target.name === "categories") {
            // console.log("---------",event.target.value);
            // console.log("+++++++++",event.target.name);
            let filteredCategoryIds = [];
            for (let i = 0; i < this.props.categories.length; i++) {
                for (let j = 0; j < event.target.value.length; j++) {
                    const element = event.target.value[j];
                    if (element.toLowerCase() === this.props.categories[i].name.toLowerCase()) {
                        filteredCategoryIds.push(this.props.categories[i]._id)
                    }
                }
            }
            this.setState({
                selectedCategories: event.target.value,
                filteredCategoryIds: filteredCategoryIds
            });
        } else if(event.target.name === "serviceMode"){
            if(event.target.value === 'PICKUP'){
                this.setState({deliveryType:[]})
            }
            // else if(event.target.value === 'DELIVERY'){
            //     this.setState({deliveryType:[]})
            // }
            this.setState({ [event.target.name]: event.target.value });
        }
        else if(event.target.name === "serviceMode"){
            if(event.target.value === 'dunzo' && event.target.value === 'DELIVERY' && event.target.value === 'INITIATED'){
                this.setState({deliveryType:[]})
            }
        }
        else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    resetFilter = () => {
        this.setState({
            order: -1,
            orderBy: 'creationDate',
            page: 0,
            rowsPerPage: 50,
            serviceRequestId: "",
            creationDate: "",
            currentStatus: [],
            paymentStatus: [],
            selectedCategories: [],
            filteredCategoryIds: [],
            // businessId: "",
            // businessName: "",
            businessFilter:"",
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            // consumerFirstName: "",
            // consumerLastName: "",
            consumerFilter:"",
            consumerMobileNumber:"",
            consumerId: "",
            couponCode: "",
            pnCode: "",
            serviceMode:"",
            advancePaid:"",
            deliveryType:[],
            adjustment : "",
            acceptedBy : "",
            filteredConsumerTags:[],
            filteredBusinessTags:[],
            isAdvancePaymentDoneToBusiness: "",
            supportConversations:"",
            prePaymentOrders: "", 
            refundApplied: "",
            manualDelivery : ""
        }, () => {
            this.fetchOrdersByQuery();
        })
    }

    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        // picker.startDate.add(1, "days");
        this.setState({
            // filterStartDate: picker.startDate.format('DD MMM YYYY'),
            // filterEndDate: picker.endDate.format('DD MMM YYYY')
            filterStartDate: picker.startDate.format(),
            filterEndDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ dateRange: chosenLabel });
    }

    enterPressed = (event) => {
        let code = event.keyCode;
        if (code === 13) {
            event.preventDefault();
            this.fetchOrdersByQuery();
        }
    }
    handleModalOpen = () => {
        this.setState({ modalOpen: true });
    };

    handleModalClose = () => {
        this.setState({ modalOpen: false ,modalClose:true,conversationsflag:true});
    };
    render() {
        // console.log("...this.props",this.props)
        let currentStatusFinalArr = this.state.currentStatus
        let currentStatusFinal 
        // console.log("------------>",currentStatusFinalArr);
        if(((currentStatusFinalArr.includes('CREATED')) &&  (currentStatusFinalArr.includes('INITIATED')) && ((currentStatusFinalArr.includes('ACCEPTED')) || (currentStatusFinalArr.includes('REJECTED')) || (currentStatusFinalArr.includes('IN_PROGESS'))  || (currentStatusFinalArr.includes('CANCELLED'))  || (currentStatusFinalArr.includes('COMPLETED'))  || (currentStatusFinalArr.includes('OUT_FOR_DELIVERY'))  || (currentStatusFinalArr.includes('REJECTED_BY_CONSUMER'))  || (currentStatusFinalArr.includes('DELIVERY_DONE'))  || (currentStatusFinalArr.includes('READY_FOR_PICKUP'))  || (currentStatusFinalArr.includes('PICKUP_DONE'))) ) && !this.state.serviceMode){
            currentStatusFinal =false;
            // console.log(currentStatusFinal)
        } else if(((currentStatusFinalArr.includes('CREATED')) &&  (currentStatusFinalArr.includes('INITIATED'))) && !this.state.serviceMode){
            currentStatusFinal =true;
            // console.log(currentStatusFinal)
        }else if(((currentStatusFinalArr.includes('CREATED')) ||  (currentStatusFinalArr.includes('INITIATED'))) && !this.state.serviceMode){
            currentStatusFinal =true;
            // console.log(currentStatusFinal)
        }else if(((currentStatusFinalArr.includes('CREATED')) ||  (currentStatusFinalArr.includes('INITIATED'))) && !this.state.serviceMode){
            currentStatusFinal =true;
            // console.log(currentStatusFinal)
        }else{
            currentStatusFinal = false
        }


        let currentStatusCond;
            // console.log('---------',currentStatusCond);
        let currentStatusArr = this.state.currentStatus;
            // console.log("++++++",currentStatusArr)
            if(
               (currentStatusArr.includes('ACCEPTED') || (currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('ACCEPTED')) || (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('ACCEPTED'))||
              (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('ACCEPTED')))
              && (this.state.serviceMode === 'DELIVERY') ){
                currentStatusCond = false;
              }
              else if(
                ( currentStatusArr.includes('REJECTED') ||(currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('REJECTED')) || (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('REJECTED'))||
              (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('REJECTED')))
              && this.state.serviceMode === 'DELIVERY'
              ){
              currentStatusCond = false;
              }else if(
                (currentStatusArr.includes('IN_PROGRESS') || (currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('IN_PROGRESS')) || (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('IN_PROGRESS'))||
              (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('IN_PROGRESS')))
              && this.state.serviceMode === 'DELIVERY'
              ){
              currentStatusCond = false;
              }else if(
                ( currentStatusArr.includes('CANCELLED') ||(currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('CANCELLED')) || (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('CANCELLED'))||
              (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('CANCELLED')))
              && this.state.serviceMode === 'DELIVERY'
              ){
              currentStatusCond = false;
              }else if(
                ( currentStatusArr.includes('COMPLETED') ||(currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('COMPLETED')) || (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('COMPLETED'))||
              (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('COMPLETED')))
              && this.state.serviceMode === 'DELIVERY'
              ){
              currentStatusCond = false;
              }else if(
                (currentStatusArr.includes('OUT_FOR_DELIVERY') || (currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('OUT_FOR_DELIVERY')) || (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('OUT_FOR_DELIVERY'))||
              (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('OUT_FOR_DELIVERY')))
              && this.state.serviceMode === 'DELIVERY'
              ){
              currentStatusCond = false;
              }else if(
                ( currentStatusArr.includes('REJECTED_BY_CONSUMER') ||(currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('REJECTED_BY_CONSUMER')) || (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('REJECTED_BY_CONSUMER'))||
              (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('REJECTED_BY_CONSUMER')))
              && this.state.serviceMode === 'DELIVERY'
              ){
              currentStatusCond = false;
              }else if(
                ( currentStatusArr.includes('DELIVERY_DONE') ||(currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('DELIVERY_DONE')) || (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('DELIVERY_DONE'))||
              (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('DELIVERY_DONE')))
              && this.state.serviceMode === 'DELIVERY'
              ){
              currentStatusCond = false;
              }else if(
                ( currentStatusArr.includes('READY_FOR_PICKUP') || (currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('READY_FOR_PICKUP')) || (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('READY_FOR_PICKUP'))||
              (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('READY_FOR_PICKUP')))
              && this.state.serviceMode === 'DELIVERY'
              ){
              currentStatusCond = false;
              }else if(
                ( currentStatusArr.includes('PICKUP_DONE') ||(currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('PICKUP_DONE')) || (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('PICKUP_DONE'))||
              (currentStatusArr.includes('CREATED')&&currentStatusArr.includes('INITIATED')&&currentStatusArr.includes('PICKUP_DONE')))
              && this.state.serviceMode === 'DELIVERY'
              ){
              currentStatusCond = false;
              }
              else if(this.state.serviceMode === 'DELIVERY' && currentStatusArr.length === 0) {
                currentStatusCond = false;
              }
              else  {
                currentStatusCond = true;
              }


    

        const { classes } = this.props;
        console.log("this.props is for pincode====",this.props);
        return (
        
            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Search Service Requests</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={3}>
                                {/* <Grid item xs={12}> */}
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="serviceRequestId" label="Service Request Id" value={this.state.serviceRequestId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <RequestStatusMultiSelectDropdown
                                        handleFilterChange={this.handleFilterChange}
                                        currentStatus={this.state.currentStatus}
                                    />
                                </Grid>
                                {/* <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="businessId" label="Business Id" value={this.state.businessId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="businessName" label="Business Name" value={this.state.businessName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid> */}

                                <Grid item xs={3} >
                                <TextField fullWidth margin="dense" name="businessFilter" label="Business Name/Id" style={{ width: "225px" }} value={this.state.businessFilter} variant="outlined" onChange={this.handleFilterChange} />
                                         </Grid>

                                <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={{
                                            'Today': [moment(), moment()],
                                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                        }}
                                        onApply={this.handleDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="orderDates" label="Service Request Date Range" value={this.state.dateRange} style={{ width: "225px" }} variant="outlined" />
                                    </DateRangePicker>
                                </Grid>
                                <Grid item xs={3}>
                                    <PaymentStatusMultiSelectDropdown
                                        handleFilterChange={this.handleFilterChange}
                                        paymentStatus={this.state.paymentStatus}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <CategoriesMultiSelectDropdown
                                        handleFilterChange={this.handleFilterChange}
                                        categories={this.props.categories}
                                        selectedCategories={this.state.selectedCategories}
                                    />
                                </Grid>
                                {/* <Grid item xs={3}></Grid> */}
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="couponCode" label="Coupon Code" value={this.state.couponCode} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerId" label="Consumer Id" value={this.state.consumerId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                {/* <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerFirstName" label="Consumer First Name" value={this.state.consumerFirstName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerLastName" label="Consumer Last Name" value={this.state.consumerLastName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid> */}

                                
                                <Grid item xs={3} >
                                <TextField fullWidth margin="dense" name="consumerFilter" label="Consumer Name" style={{ width: "225px" }} value={this.state.consumerFilter} variant="outlined" onChange={this.handleFilterChange} />
                                         </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerMobileNumber" label="Consumer Mobile No" value={this.state.consumerMobileNumber} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="pnCode" label="Pin Code" value={this.state.pnCode} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl variant="filled" fullWidth className={classes.formControl} >
                                        <InputLabel id="CouponIssuedStatus-label">Service Mode</InputLabel>
                                        <Select
                                            labelId="service-mode"
                                            name="serviceMode"
                                            value={this.state.serviceMode}
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            MenuProps={this.MenuProps}
                                        >
                                            <MenuItem value={'DELIVERY'} style={{ minHeight: "28px", height: "28px" }}>
                                                <ListItemText primary="DELIVERY" />
                                            </MenuItem>
                                            <MenuItem value={'PICKUP'} style={{ minHeight: "28px", height: "28px" }}>
                                                <ListItemText primary="PICKUP" />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        variant="filled"
                                        fullWidth
                                        className={classes.formControl}
                                    >
                                        <InputLabel id="deliveryType">Delivery Type</InputLabel>
                                        { this.state.serviceMode === "DELIVERY" ? 
                                            <Select
                                                labelId="demo-mutiple-checkbox-label"
                                                id="demo-mutiple-checkbox"
                                                multiple
                                                value={this.state.deliveryType}
                                                name="deliveryType"
                                                onChange={this.handleFilterChange}
                                                input={<Input />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                                disabled= {currentStatusCond ? true : false}
                                            >
                                                {deliveryType.map((deliveryType) => (
                                                    <MenuItem key={deliveryType} value={deliveryType} style={{ minHeight: "28px", height: "28px" }}>
                                                        <Checkbox checked={this.state.deliveryType.indexOf(deliveryType) > -1} />
                                                        <ListItemText primary={deliveryType} />
                                                    </MenuItem>
                                                ))}
                                            </Select>  :
                                            (this.state.serviceMode === "PICKUP"? 
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    multiple
                                                    value={this.state.deliveryType}
                                                    name="deliveryType"
                                                    onChange={this.handleFilterChange}
                                                    input={<Input />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                    disabled={this.state.serviceMode === "PICKUP" } 
                                                >
                                                </Select> 
                                            :
                                            
                                            (((this.state.currentStatus === "CREATED") || (this.state.currentStatus === "INITIATED") ||  (currentStatusFinalArr.includes('CREATED') && currentStatusFinalArr.includes('INITIATED'))) ? 
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    multiple
                                                    value={this.state.deliveryType}
                                                    name="deliveryType"
                                                    onChange={this.handleFilterChange}
                                                    input={<Input />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                    disabled= {currentStatusFinal ? true : false}
                                                >
                                                    {deliveryType.map((deliveryType) => (
                                                        <MenuItem key={deliveryType} value={deliveryType} style={{ minHeight: "28px", height: "28px" }}>
                                                            <Checkbox checked={this.state.deliveryType.indexOf(deliveryType) > -1} />
                                                            <ListItemText primary={deliveryType} />
                                                        </MenuItem>
                                                    ))}
                                                </Select> 
                                                :
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    multiple
                                                    value={this.state.deliveryType}
                                                    name="deliveryType"
                                                    onChange={this.handleFilterChange}
                                                    input={<Input />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    {deliveryType.map((deliveryType) => (
                                                        <MenuItem key={deliveryType} value={deliveryType} style={{ minHeight: "28px", height: "28px" }}>
                                                            <Checkbox checked={this.state.deliveryType.indexOf(deliveryType) > -1} />
                                                            <ListItemText primary={deliveryType} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            ))
                                        }
                                    </FormControl>
                                </Grid>
                                

                            <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">Advance Payment Req</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="advancePaid"
                                        value={this.state.advancePaid}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Advance Payment Req"
                                    >
                                        <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Yes </MenuItem>
                                        <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                                <Grid item xs={3}>
                                    <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-simple-select-standard-label">Advance Payment Done</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard"
                                            name="isAdvancePaymentDoneToBusiness"
                                            value={this.state.isAdvancePaymentDoneToBusiness}
                                            onChange={this.handleFilterChange}
                                            id="demo-simple-select-standard"
                                            label="Advance Payment Done"
                                            disabled={this.state.advancePaid == false}
                                        >
                                            <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Yes </MenuItem>
                                            <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">Adjustments</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="adjustment"
                                        value={this.state.adjustment}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Adjustments"
                                    >
                                        <MenuItem value={'business'} style={{ minHeight: "28px", height: "28px" }}>Business </MenuItem>
                                        <MenuItem value={'xirify'} style={{ minHeight: "28px", height: "28px" }}>Xirify</MenuItem>
                                        <MenuItem value={'both'} style={{ minHeight: "28px", height: "28px" }}>Both (Business & Xirify)</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">Accepted By</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="acceptedBy"
                                        value={this.state.acceptedBy}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Accepted By"
                                    >
                                        <MenuItem value={'admin'} style={{ minHeight: "28px", height: "28px" }}>Admin</MenuItem>
                                        <MenuItem value={'serviceprovider'} style={{ minHeight: "28px", height: "28px" }}>Business</MenuItem>
                                        <MenuItem value={'Petpooja'} style={{ minHeight: "28px", height: "28px" }}>Petpooja</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                {/* <Typography variant="subtitle2" gutterBottom> Select Consumer Tags</Typography> */}
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Consumer Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredConsumerTags}
                                            name="filteredConsumerTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {this.state.consumerTags?.length && this.state.consumerTags?.map((eachConsumerTagObj) => (
                                                <MenuItem key={eachConsumerTagObj._id} value={eachConsumerTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredConsumerTags.indexOf(eachConsumerTagObj.name) > -1} />
                                                    <ListItemText primary={eachConsumerTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> 
                                <Grid item xs={3}>
                    {/* <Typography variant="subtitle2" gutterBottom> Select Business Tags</Typography> */}
                          <FormControl variant="outlined" fullWidth className={classes.formControl} >
                              <InputLabel id="demo-mutiple-checkbox-label">Select Business Tags</InputLabel>
                              <Select
                                  labelId="demo-mutiple-checkbox-label"
                                  id="demo-mutiple-checkbox"
                                  multiple
                                  value={this.state.filteredBusinessTags}
                                  name="filteredBusinessTags"
                                  onChange={this.handleFilterChange}
                                  input={<Input />}
                                  renderValue={(selected) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                  >
                                  {this.state. businessTags.map((eachBusinessTagObj) => (
                                      <MenuItem key={eachBusinessTagObj._id} value={eachBusinessTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                          <Checkbox checked={this.state.filteredBusinessTags.indexOf(eachBusinessTagObj.name) > -1} />
                                          <ListItemText primary={eachBusinessTagObj.name} />
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>                          
                                </Grid>
                                <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">Support Notes</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="supportConversations"
                                        value={this.state.supportConversations}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Support Notes"                                       
                                    >
                                        <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Yes </MenuItem>
                                        <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">PrePayment Orders</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="prePaymentOrders"
                                        value={this.state.prePaymentOrders}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="PrePayment Orders"                                       
                                    >
                                        <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Yes </MenuItem>
                                        <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">Refund Applied</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="refundApplied"
                                        value={this.state.refundApplied}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Refund Applied"                                       
                                    >
                                        <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Yes </MenuItem>
                                        <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">Manual Delivery</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="manualDelivery"
                                        value={this.state.manualDelivery}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Manual Delivery"                                       
                                    >
                                        <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Yes </MenuItem>
                                        <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        onClick={
                                            ()=>this.setPage(0)
                                            //this.fetchOrdersByQuery
                                        }
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                <Grid container spacing={3}>
                    <Grid style={{display:"flex", alignItems:"center"}} item ><div style={{marginRight:"5px",}} className={classes.asap} /><Typography display={"inline"} className={classes.heading}>ASAP</Typography></Grid>
                    <Grid style={{display:"flex", alignItems:"center"}} item ><div style={{marginRight:"5px",}} className={classes.customerPrefferedTime} /><Typography display={"inline"} className={classes.heading}>Customer preferred time</Typography></Grid>
                    <Grid style={{display:"flex", alignItems:"center"}} item ><div style={{marginRight:"5px",}} className={classes.businessRescheduledTime} /><Typography display={"inline"} className={classes.heading}>Business rescheduled time</Typography></Grid>
                    <Grid style={{display:"flex", alignItems:"center"}} item ><div style={{marginRight:"5px",}} className={classes.scheduleLater} /><Typography display={"inline"} className={classes.heading}>Schedule later</Typography></Grid>
                    <Grid style={{display:"flex", alignItems:"center"}} item ><div style={{marginRight:"5px",}} className={classes.preOrders} /><Typography display={"inline"} className={classes.heading}>Pre-orders</Typography></Grid>
                    <Grid style={{display:"flex", alignItems:"center"}} item ><div style={{marginRight:"5px",}} className={classes.preparationTimer} /><Typography display={"inline"} className={classes.heading}>Preparation time applied</Typography></Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} align='right'>
                            { ['admin', 'areaManager'].includes(this.props.user.role) ?  
                                <Button
                                    variant="contained"
                                    color= {this.state.conversationsflag ? 'red': 'primary'}
                                    onClick={this.handleModalOpen}
                                    style={{ marginRight: "10px", textTransform: "none"}}
                                >
                                MESSAGE (Consolidated Shoppers & Business)
                                </Button> 
                            : null}
                        </Grid>
                        
                    </Grid>
                    
                </Grid>

                </Container>
                <Modal 
                    open={this.state.modalOpen}
                    className={classes.modal}
                    onClose={this.handleModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                        <ConversationsTable 
                        onClose={this.handleModalClose}
                        
                        />
                </Modal>
                <Container className={classes.container}>
                    {
                        this.props.serviceRequest.serviceRequests && this.props.serviceRequest.serviceRequests.data ?
                            <ServiceRequestsTable
                                data={this.props.serviceRequest.serviceRequests}
                                exportToexcel={this.props.serviceRequest.exportToExcel}
                                itemReportToExcel={this.props.serviceRequest.itemReportToExcel}
                                order={this.state.order === 1 ? 'asc' : 'desc'}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                currentStatus={this.state.currentStatus}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                handleFilterChange={this.handleFilterChange}
                                history={this.props.history}
                                fetchOrdersByQueryExcelFile={this.fetchOrdersByQueryExcelFile}
                                fetchOrdersByQueryItemReportExcelFile={this.fetchOrdersByQueryItemReportExcelFile}
                                showDownloadIcon={this.state.preparexportToExcel}
                                showDownloadIconForItemReport={this.state.preparItemReportToExcel}
                            />
                            : null
                    }
                </Container>
                <Backdrop className={classes.backdrop} open={this.props.serviceRequest.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
                }
}

const mapStateToProps = state => {
    return {
        serviceRequest: state.serviceRequest,
        categories: state.categories.categoriesByType,
        user: state.auth.user
    }
};



export default connect(mapStateToProps, { changeHeaderName, requestServiceRequests, getAllServiceRequests, getCategoriesByType, exportToExcel,itemReportToExcel,findAllConversations })(withStyles(styles)(ServiceRequests));