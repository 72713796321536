import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import 'bootstrap-daterangepicker/daterangepicker.css';
import CloseIcon from "@material-ui/icons/Close";
import Container from "@material-ui/core/Container";
import TableContainer from "@material-ui/core/TableContainer";
import { ReactComponent as BusinessConv } from "../../images/BusinessConv.svg";
import { ReactComponent as ShopperConv } from "../../images/ShopperConv.svg";
import { ReactComponent as XirifyConv } from "../../images/XirifyConv.svg";
import moment from 'moment';
import { useDispatch } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { findAllConversations } from "../../actions/serviceRequestActions";
import Avatar from '@material-ui/core/Avatar';


const styles = (theme) => ({
    paper: {
      padding: "0px 0px",
      display: "flex",
      alignItems: "center",
      height: "60px",
      marginBottom: "25px",
    },
    snackClose: {
      padding: theme.spacing(0.5),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '24px',
      width: '60%',
      height: '80%',
      overflowX: 'auto',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    },
    header: {
      width: '100%',
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
    },
    headGridTwo: {
      float: "right",
      marginRight: "2px",
    },
    content: {
      width: '100%',
      overflow: "auto",
      backgroundColor: theme.palette.background.paper,
      paddingBottom: '20px'
    },
    headerTwo: {
      paddingLeft: '15px',
      paddingRight: '8px',
      margin: '15px 10px 0px 0px',
      display: 'flex',
      flexDirection: 'column',
      flex: 'center',
      alignItems: 'left',
      justifyContent: 'left',
      fontSize: '14px',
      backgroundColor: '#F9F9F9',
    },
    gridBC: {
      backgroundColor :'#F3F3F3',
      paddingLeft:'15px',
      paddingTop:'5px',
      paddingBottom:'5px',
      paddingRight:'8px',
      margin: '0px 10px 0px 0px',
    },
    row: {
      "& > td": {
        borderBottom: 'none'
      }
    },
    icon: {
      // backgroundColor: "#FBFBFB",
      // marginTop: "18px",
      // position: 'relative',
      // left: 5,
      // marginBottom: "26px",
      flexDirection: 'column',
      flex: 'center',
      alignItems: 'left',
      justifyContent: 'left',
    }
});

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

function ConversationsTable( props ) {
  console.log(props)
  const { classes } = props;
  const [isFetching, setIsFetching] = useState(false);
  const [messagesList, setMessagesList] = useState([]);
  const [count, setCount] = useState(0);
  const [disableLoadMore , setDisableLoadMore] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsFetching(true);
    fetchConversations();
    // setDisableLoadMore(false)
    setIsFetching(false);
  }, [count]); 

  const fetchConversations = () => {
    dispatch(findAllConversations(count));
    
  }

  useEffect(() => {
    if (props.serviceRequest.allConversations && props.serviceRequest.allConversations.length > 0 && count > 0) {
      setMessagesList(prevMessages => [...prevMessages, ...props.serviceRequest.allConversations]); 
    } else  if (props.serviceRequest.allConversations && props.serviceRequest.allConversations.length > 0 && count == 0) {
  setMessagesList(props.serviceRequest.allConversations)
    }
    if (props.serviceRequest.allConversations && props.serviceRequest.allConversations.length < 50) {
      setDisableLoadMore(true)
    }else{
      console.log("out")
      setDisableLoadMore(false)
    }
  }, [props.serviceRequest]);  

  const loadMore = () => { 
    const newCount = count + 1; 
    setCount(newCount);  
    console.log("props.serviceRequest.allConversations.length",props.serviceRequest.allConversations.length)
   
  }
console.log("count",count)
  return (
    <div className={classes.modal}>
      <Grid container className={classes.header} spacing={3}>
        <Grid item xs={8}>
          <WhiteTextTypography variant="h6" color="inherit" gutterBottom> Message (Consolidated Shoppers & Business) </WhiteTextTypography>
        </Grid>
        <Grid item xs={4}>
          <WhiteTextTypography variant="h6" color="inherit" gutterBottom>
            <CloseIcon
              className={classes.headGridTwo}
              onClick={() => { props.onClose() }}
            />
          </WhiteTextTypography>
        </Grid>
      </Grid>

      <Container className={classes.content}>
        <Grid item xs={12}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <TableBody>
                  {
                    messagesList && messagesList.length > 0 && messagesList.map((value, index) => {
                      return (
                          <TableRow
                            tabIndex={-1}
                            key={index}
                            className={classes.row}
                          >
                            <TableCell>
                              <Grid container spacing={1} >
                                <Grid item xs={12} sm={1} className={classes.icon}>
                                  {
                                    value.userType == 'consumer' ? 
                                      <ShopperConv /> :
                                            value.userType == 'admin' ?
                                                <XirifyConv /> : <BusinessConv />
                                  }
                                </Grid>
                                <Grid  item xs={12} sm={11}>
                                  <Grid container spacing={1} className={classes.headerTwo}>
                                    {value.attachments ?
                                    <Grid item xs={12} sm={12}>
                                    <Avatar  src={value.attachments} variant="square" style={{ width: '200px', height: '200px' }} ></Avatar>
                                    </Grid>
                                    :
                                    <Grid item xs={12} sm={12}>
                                      <Typography gutterBottom style={{fontSize:14}}><b>{value.text}</b></Typography>
                                    </Grid>
                                     }
                                  </Grid>
                                
                                  <Grid container spacing={1} className={classes.gridBC}>
                                    <Grid item xs={12} sm={5}>
                                      <Typography gutterBottom  style={{fontSize:13,color:"#737373"}}>{value.senderName}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} align="right">
                                      <Typography gutterBottom  style={{fontSize:13,color:"#737373"}}> Order ID: {value.serviceRequestId} </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} align="right">
                                      <Typography gutterBottom  style={{fontSize:13,color:"#737373"}}>{moment(value.time).format('DD MMM YYYY h:mm A')}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={5}></Grid>
        { 
          messagesList && messagesList.length > 0 ?
            <Grid item xs={2} style={{ marginBottom: "20px" }}>
              <Button
                type="submit" fullWidth variant="contained" margin="normal"
                color="primary"
                style={{ borderRadius: "15px", opacity: 1, position: "relative", left: 280, top: 20 }}
                disabled = {disableLoadMore ? true:false}
                onClick={() => { loadMore() }}
              >
                Load more
              </Button>
            </Grid> 
          : null
        }
        <Grid item xs={5}></Grid>

      </Container>
      
      <Backdrop className={classes.backdrop} open={isFetching}> <CircularProgress color="inherit" /> </Backdrop>
    </div>
  );
};

const mapStateToProps = state => {
	return {
		serviceRequest: state.serviceRequest,
    user: state.auth.user,
	}
};

export default connect(mapStateToProps, null)(withStyles(styles)(ConversationsTable));
