import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { FormControlLabel, Radio, RadioGroup, TableBody, TableCell, TableRow, Table, Checkbox } from '@material-ui/core';
import _ from 'lodash';
import { connect } from "react-redux";
import { restoreAddons } from '../../../../actions/addonGroupAction'



const styles = theme => ({
    form: {
        width: '45%',
        height: 'fit-content',
        backgroundColor: "white",
        overflow: "hidden",
        flexGrow: 1,
        border: "2px solid #3d8bc3",
        borderRadius: "5px!important"


    },

    header: {
        height: '8%',
        margin: '0px 0px',
        width: '-webkit-fill-available',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,

    },
    CloseIcon: {
        float: "right",
        color: "white"
    },
    headerText: {
        color: "white",
        fontSize: "20px",
        fontFamily: "serif",
        marginLeft: '10px'
    },

    footer: {
        height: '10%',
        padding: '20px 0px 10px 150px',
        display: 'flex',

    },

    body: {
        fontFamily: "serif",
        padding: "10px 0px"
    },
    bodyMainContainer: {
        padding: "5px 4px"
    },

    dynamicForm: {
        width: '50%',
        height: 'screen!important',
        backgroundColor: "white",
        overflow: "auto",
        flexGrow: 1,
        border: "2px solid #3d8bc3",
        borderRadius: "10px!important", '&::-webkit-scrollbar': {
            width: '1px',
            backgroundColor: 'white',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'white',
            borderRadius: '1px'
        }
    },
    bodyHeader: {
        fontSize: "16px",
        fontFamily: "serif",
        margin: '5px 10px',
        font: 'math'
    },
    stickyForm: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: theme.palette.primary.main,
    },
    defaultColor: {
        color: '#F9AE00',
        fontFamily: 'Open Sans, Semibold',
        fontSize: '14px'
    },
    textColor: {
        color: '#202035',
        fontFamily: 'Open Sans, Semibold',
        fontSize: '14px'
    }



});


class ChangeDefaultModal extends React.Component {
    constructor() {
        super();
        this.state = {
   
        };
        this.inputReference = React.createRef();
    }


     componentDidMount = async()=>{
        let restoreData = this.props.restoreRecords
        if (restoreData.length) {
           this.setState({ restoreData : restoreData })
        }
     }


     handleRestore =(event , _id)=>{
       
            let { restoreData } = this.state
            for (let index = 0; index < restoreData.length; index++) {
                if (JSON.stringify(restoreData[index]._id) === JSON.stringify(_id)) {
                    restoreData[index].isActive = event.target.checked;
                    break;
                }
            }
            this.setState({ restoreData: restoreData })
     }

     onSubmit = async()=>{
        let { restoreData } = this.state
        let activeRecords = restoreData.filter(v => v.isActive === true).map(e=> ({ _id : e._id , isEnabled : true , isDisplay : true }));
        
        if (activeRecords.length) {
            let payload = {  restoreRecords : activeRecords }
            await this.props.restoreAddons(payload)

        }
        this.props.handleRestoreClose()
     }

    render() {
        const { classes } = this.props;
        let props = this.props
        let { restoreData } = this.state


        return (
            <div className={restoreData && restoreData.length > 9 ? classes.dynamicForm : classes.form}>
                <div className={classes.appBarSpacer} >
                    <Grid container className={restoreData && restoreData.length > 9 ? classes.stickyForm : classes.header} spacing={1}>
                        <Grid item xs={8}>
                            <Typography variant="h5" gutterBottom className={classes.headerText}>
                                Restore Addons
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <CloseIcon className={classes.CloseIcon} onClick={() => { props.handleRestoreClose() }} />
                        </Grid>
                    </Grid>
                    <Container className={classes.body}>
                        <Typography className={classes.bodyHeader}>You are Restoring addons in the Group</Typography>
                        <Table size='small'>
                            <TableBody>
                                {
                                    restoreData && restoreData.length ? restoreData.map((row) => {
                                        return (
                                            <TableRow key={row._id} >
                                                <TableCell style={{ padding: '0px 0px 0px 10px' }}>
                                                     <Checkbox
                                                        checked={row.isActive}
                                                        onClick={(event)=>this.handleRestore(event , row._id)}
                                                    ></Checkbox> </TableCell>
                                                <TableCell style={{ padding: '0px 0px 0px 0px', minWidth: '270px' }}>
                                                    {row?.service ? row.service : 'NA'}
                                                </TableCell>
                                                <TableCell style={{ padding: '0px 0px 0px 10px' }}>
                                                    {`${row.cost?.quantity ? row.cost?.quantity : 'per'} ${row.cost?.unit}`}
                                                </TableCell>
                                                <TableCell style={{ padding: '5px 0px 0px 10px' }}>
                                                    {`${`₹`} ${row.cost?.fixedCost ? row.cost?.fixedCost : 'NA'}`}
                                                </TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        )
                                    }) : null
                                }
                            </TableBody>
                        </Table>


                    </Container>
                    <Container style={{ display: "flex", maxWidth: "60px" }}>


                        <Button
                            fullWidth
                            variant="contained"
                            margin="normal"
                            color="default"
                            onClick={() => { props.handleRestoreClose() }}
                            style={{ margin: "10px", padding: '5px 50px' }}
                        >
                            CANCEL
                        </Button>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            margin="normal"
                            style={{ margin: "10px", padding: '5px 50px' }}
                            onClick={this.onSubmit}
                            color="primary">
                            SAVE
                        </Button>

                    </Container>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
       
    };
};
export default connect(mapStateToProps, {
    restoreAddons
})(withStyles(styles)(ChangeDefaultModal));

