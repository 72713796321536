import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import BlockIcon from '@material-ui/icons/Block';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';

const styles = theme => ({
    root: {
        height: '100%',
        background: 'radial-gradient( lightblue, lightsteelblue)'
    },
    title: {
      fontWeight: 700
    },
    avatar: {
      backgroundColor: 'aliceblue',
      height: 56,
      width: 56
    },
    icon: {
      height: 32,
      width: 32
    },

});

class StatisticCard extends Component {

  renderIcon = (icon, classes) => {
    if (icon === "pending") {
      return <HourglassFullIcon style={{ backgroundColor: 'blue' }} className={classes.icon} />
    }  else if (icon === "rejected") {
      return <ThumbDownIcon style={{ backgroundColor: 'blue' }} className={classes.icon} />
    } else if (icon === "approved") {
      return <ThumbUpIcon style={{ backgroundColor: 'blue' }} className={classes.icon} />
    } else if (icon === "disabled") {
      return <BlockIcon style={{ backgroundColor: 'blue' }} className={classes.icon} />
    } else if (icon === "consumer"){
      return <PeopleIcon style={{ backgroundColor: 'green' }} className={classes.icon} />
    } else if (icon === "servicerequest") {
      return <ListIcon style={{ backgroundColor: 'orange' }} className={classes.icon} />
    } else if (icon === "serviceproviders") {
      return <PersonIcon style={{ backgroundColor: 'red' }} className={classes.icon} />;
    } else if (icon === "total_consumer"){
      return <PeopleIcon style={{ backgroundColor: 'blue' }} className={classes.icon} />
    }  else {
      return null;
    }
  }

  render() {
    const { classes, ...rest } = this.props;

    return (
        <Card
        className={classes.root}
      >
        <CardContent>
          <Grid
            container
            justify="space-between"
          >
            <Grid item>
              <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  {rest.title}
                </Typography>
                <Typography variant="h3">{rest.num}</Typography>
            </Grid>
            <Grid item>
                <Avatar className={classes.avatar}>
                {this.renderIcon(rest.icon, classes.icon)}
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default connect(null, null)(withStyles(styles)(StatisticCard));