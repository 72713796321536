

import axios from "axios";
import { SIGN_IN, SIGN_OUT, RESET_PASSWORD, SET_ENV } from "./types";
import setAuthToken from "../utils/setAuthToken";


export const fetchEnvs = () => async(dispatch) => {
  const envData = await axios.get(`/api/xirify/env`);
  dispatch({ type: SET_ENV, payload: envData.data });
};

// Login - get user token
export const loginUser = userData => dispatch => {
  let payObj = {};

  axios
    .post("/login/admin", userData)
    .then(async (res) => {
      const token = res.headers['x-auth-token'];
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      payObj.isAuthenticated = true;
      payObj.user = res.data;
      payObj.error = null;
      dispatch({ type: SIGN_IN, payload: payObj });
      const envData = await axios.get(`/api/xirify/env`);
      dispatch({ type: SET_ENV, payload: envData.data });
    }).catch(err => {
      payObj.isAuthenticated = false;
      payObj.user = null;
      if (err.response.status === 401) {
        payObj.error = "Incorrect Username & Password";
      } else if (err.response.status === 404) {
        payObj.error = "User is Inactive";
      } else {
        payObj.error = err.response.statusText;
      }
      dispatch({ type: SIGN_IN, payload: payObj });
    });
};

export const getAdminProfile = () => dispatch => {
  let payObj = {};

  axios
    .get("/api/myprofile/admin")
    .then(res => {
      payObj.isAuthenticated = true;
      payObj.user = res.data;
      payObj.error = null;
      dispatch({ type: SIGN_IN, payload: payObj });
    }).catch(err => {
      payObj.isAuthenticated = false;
      payObj.user = null;
      if (err.response.status === 401) {
        payObj.error = "Incorrect Username or Password";
      } else if (err.response.status === 404) {
        payObj.error = "User is Inactive";
      } else {
        payObj.error = err.response.statusText;
      }
      dispatch({ type: SIGN_IN, payload: payObj })
    });
};

export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  window.localStorage.clear();
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch({ type: SIGN_OUT, payload: null });
};


// Login - get user token
export const forgotPassword = userData => dispatch => {
  let reply = {};
  axios
    .post("/resetpassword/admin", userData)
    .then(res => {
      reply.error = null;
      reply.passwordReset = true;
      dispatch({ type: RESET_PASSWORD, payload: reply });
    }).catch(err => {
      console.log("Error while forgot password - ", err.response);
      reply.error = err.response.statusText;
      reply.passwordReset = false;
      dispatch({ type: RESET_PASSWORD, payload: reply });
    });
};

export const loadForgot = () => dispatch => {
  let reply = {};
  reply.error = null;
  reply.passwordReset = null;
  dispatch({ type: RESET_PASSWORD, payload: reply });
};

// export const fetchEnv = async () => dispatch=>{
//   const envData = await axios.get(/api/xirify/env);
//   dispatch({ type: SET_ENV, payload: envData.data });
// };