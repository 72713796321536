import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { changeHeaderName } from '../../actions/dashboardActions';
import { getAllAdmin, addToAdmin, setAdmin, deleteAdmin, clearSucessMsg, clearErrorMsg, updateAdmin } from '../../actions/adminActions';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import AdminForm from './AdminForm';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  table: {
    minWidth: 650,
  },
  head: {
    fontWeight: 700
  },
  button: {
    marginTop: theme.spacing(3)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px'
  },
  modalContainer: {
    width: '40%',
    height: '90%',
    backgroundColor: theme.palette.background.paper,
    padding: '0px 0px',
  },
  snackClose: {
    padding: theme.spacing(0.5),
  },
  tableRow: {
    cursor: 'pointer' 
  }
});


class Admin extends Component {

  constructor() {
    super();
    this.state = {
      modalOpen: false,
      snackOpen: false,
      modalDeleteOpen: false,
      sucessMsg : null,
      errorMsg : null,
      modalSwitchOpen: false,
    };
  }

  componentDidMount() {
    this.props.changeHeaderName("Admin Management");
    this.props.getAllAdmin();
  }

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  }

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  }

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    this.setState({ modalOpen: false });
    this.setState({ sucessMsg: null });
    this.setState({ errorMsg: null });
    this.props.getAllAdmin();
  }

  addAdmin = (props) => {
    this.props.addToAdmin(props);
    this.setState({ modalOpen: false });
  }

  handleDeleteOpen = (adminObj) => {
    this.setState({ modalDeleteOpen: true });
    this.props.setAdmin(adminObj);
  }

  handleUserFlagSwitchChangeOpen = (adminObj) => {
    this.setState({ modalSwitchOpen: true });
    this.props.setAdmin(adminObj);
  };

  handleDeleteClose = () => {
    this.setState({ modalDeleteOpen: false });
  }

  handleFlagSwitchClose = () => {
    this.setState({ modalSwitchOpen: false });
  };

  handleDeleteAdmin = (adminObj) => {
    this.props.deleteAdmin(adminObj);
    this.setState({ modalDeleteOpen: false });
  }

  handleChangeUserFlag = (adminObj) => {
    let payload = {
      _id: adminObj._id,
      isActive: !adminObj.isActive
    };
    this.props.updateAdmin(payload);
    this.setState({ modalSwitchOpen: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.admin.sucessMsg !== null) {
      this.setState({ sucessMsg : this.props.admin.sucessMsg })
      this.setState({ snackOpen: true });
      this.props.getAllAdmin();
      this.props.clearSucessMsg();
    } else if (this.props.admin.errorMsg !== null) {
      this.setState({ errorMsg : this.props.admin.errorMsg })
      this.setState({ snackOpen: true });
      this.props.getAllAdmin();
      this.props.clearErrorMsg();
    }

  }

  render() {
    const { classes } = this.props;
    let muiAlert = null;

    if (this.state.sucessMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
                    {this.state.sucessMsg}
                 </MuiAlert>
    } else if (this.state.errorMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
                    {this.state.errorMsg}
                 </MuiAlert>
    } 

    return (
      <Container maxWidth="lg">
        <div className={classes.appBarSpacer} />
        <Container>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={() => this.handleModalOpen()}
            >
            Add
          </Button>
          <Modal 
            className={classes.modal}
            open={this.state.modalOpen}
            onClose={this.handleModalClose}
            closeAfterTransition>
              <Container className={classes.modalContainer}>
                <AdminForm onSubmit={this.addAdmin} onClose={this.handleModalClose}/>
              </Container>
          </Modal>
        </Container>
          <Container className={classes.container}>
              <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.head}>
                  <TableRow>
                    <TableCell>User Name</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Contact Number</TableCell>
                    {/* <TableCell> Actions </TableCell> */}
                    <TableCell> Status </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.admin.adminList.map((adminObj,index) => {
                    return (
                    <TableRow className={classes.tableRow} hover key={adminObj.username} button="true">
                      <TableCell component="th" scope="row" align="left">
                        {adminObj.username}
                      </TableCell>
                      <TableCell>{adminObj.email}</TableCell>
                      <TableCell>{adminObj.firstName}</TableCell>
                      <TableCell>{adminObj.lastName}</TableCell>
                      <TableCell>{adminObj.mobileNumber}</TableCell>
                      {/* <TableCell>
                        <DeleteIcon onClick={() => this.handleDeleteOpen(adminObj)} />
                      </TableCell> */}
                      <TableCell>
                        <FormControlLabel
                          control={
                                    <Switch
                                      size="small"
                                      key={`userFlagSwitch_${adminObj._id}_${index}`}
                                      checked={adminObj.isActive === true}
                                      onChange={() => this.handleUserFlagSwitchChangeOpen(adminObj)}
                                      color="primary"
                                    />
                                  }
                                  style={{ fontSize: "10px", color: adminObj.isActive === true ? 'black' : 'red' }}
                                  label={adminObj.isActive === true ? 'Active' : 'Inactive'}
                        />
                      </TableCell>
                    </TableRow>
                    )
                })}
              </TableBody>
              </Table>
            </TableContainer>
          </Container>

          {/* Delete Dialogue to delete the Admin */}
          {/* <Dialog open={this.state.modalDeleteOpen} onClose={this.handleDeleteClose}>
            <DialogTitle>Delete Admin</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Do you want to Delete admin with username {this.props.admin.selectedAdmin.username}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
              <Button onClick={() => this.handleDeleteAdmin(this.props.admin.selectedAdmin)} color="primary" autoFocus> Yes </Button>
            </DialogActions>
          </Dialog> */}

          <Dialog open={this.state.modalSwitchOpen} onClose={this.handleFlagSwitchClose}>
            <DialogTitle>Change Status</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Do you want to change the status of admin with username {this.props.admin.selectedAdmin.username}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleFlagSwitchClose} color="primary" autoFocus> No </Button>
              <Button onClick={()=>this.handleChangeUserFlag(this.props.admin.selectedAdmin)} color="primary"> Yes </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackOpen}
            autoHideDuration={6000}
            onClose={this.handleSnackClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}>
                  {muiAlert} 
            </Snackbar>    
           
      </Container>
    );
  }
}


const mapStateToProps = state => {
  return {
    admin: state.admin,
  }
};
export default connect(mapStateToProps, { changeHeaderName, getAllAdmin, addToAdmin, setAdmin, deleteAdmin, clearSucessMsg, clearErrorMsg, updateAdmin })(withStyles(styles)(Admin));