import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import { addCouponSettlementNotes, getCouponSettlementsNotes } from '../../actions/campaignActions';
import moment from "moment";

const styles = theme => ({
	header: {
		height: '20%',
		margin: '0px 0px',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: "22px",
		backgroundColor: theme.palette.primary.main,
	},
	headGridTwo: {
		float: 'right',
		marginRight: '2px'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0px'
	},
	modalContainer: {
		width: '100%',
		height: '100%',
		padding: '20px',
		backgroundColor: theme.palette.background.paper,
		// margin: '10px 0px',
		// overflow: 'auto',
	},
	mainContainer: {
		width: '40%',
		height: '50%',
		backgroundColor: theme.palette.background.paper,
		padding: '0px 0px',
		margin: '10px 0px',
		overflow: 'auto',

	},
	head: {
		fontWeight: 700,
		fontSize: "12px",
	},
});

const WhiteTextTypography = withStyles({
	root: {
		color: "#FFFFFF"
	}
})(Typography);

class addCouponNotesModal extends Component {
	constructor() {
		super();
		this.state = {
			notes: "",
			settlementNotes: []
		};
	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		if (!_.isEmpty(this.state.error)) {
			this.setState({ error: {} })
		}
	}

	clearData = () => {
		this.setState({
			notes: "",
			settlementNotes: []
		})
	};

	close = () => {
		this.clearData();
		this.props.onClose();
	};
	
	addNotes = async () => {
		if (this.state.notes) {
			let payload = {
				couponSettlementId: this.props.data.couponSettlementId,
				settlementNote: this.state.notes
			}
			await this.props.addCouponSettlementNotes(payload);
			await this.props.getCouponSettlementsNotes({couponSettlementId: this.props.data.couponSettlementId});
			this.clearData();
		}
	};

	render() {
		const { classes } = this.props;
        // console.log("this.props in notes modal--->",this.props)
		return (
			<Modal
				className={classes.modal}
				open={this.props.open}
				closeAfterTransition>
				<Container className={classes.mainContainer}>
					<Grid container className={classes.header} spacing={1} >
						<Grid item xs={8}>
							<WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
								Notes 
								({(this.props.data && this.props.data.rangeStartDate && this.props.data.rangeEndDate) ? moment.utc(this.props.data.rangeStartDate).format('MMM-YYYY') : "--"})
							</WhiteTextTypography>
						</Grid>
						<Grid item xs={4}>
							<WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
								<CloseIcon className={classes.headGridTwo} 
								onClick={() => { this.close() }} 
								/>
							</WhiteTextTypography>
						</Grid>
					</Grid>
					<Container className={classes.modalContainer}>
						{(this.props && this.props.data && this.props.campaign && this.props.campaign.couponSettlementNotesById && this.props.campaign.couponSettlementNotesById.length>0 ) ?
						    this.props.campaign.couponSettlementNotesById.map((row,key)=>
                                <Grid container spacing={1} >
                                    <Grid item xs={8}>
                                        <Typography variant="subtitle2">
                                            {row.user}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style ={{color:"green"}}variant="subtitle2">
                                            {row.userRole}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>
                                            {row.note}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>
                                            {row.noteCreatedTime ? moment(row.noteCreatedTime).format('DD-MMM-YYYY hh:mm A') : "NA"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs ={12}>
                                    <Divider />
                                    </Grid>
                                </Grid>
						    )
                            :
							<Grid item xs={8}>
							    <Typography> No Notes Available </Typography>
							</Grid>
	                    }
	                    <Grid container  spacing={1} alignItems="center">
						    <Grid item xs={10}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    name="notes"
                                    variant="outlined"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.notes}
                                    onChange={this.handleChange}
                                />
						    </Grid>

						    <Grid item xs={2}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={this.addNotes}
                                >
                                    Add
                                </Button>
						    </Grid>
					    </Grid>
					</Container>
				</Container>
			</Modal>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		dashboard: state.dashboard,
		user: state.auth.user,
		admin: state.admin,
		env: state.auth.env,
        campaign: state.campaign,
	};
};
export default connect(mapStateToProps, { addCouponSettlementNotes, getCouponSettlementsNotes })(withStyles(styles)(addCouponNotesModal));