import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';

const headCells = [
    { key: 'businessId', label: 'Business ID' },
    { key: 'businessName', label: 'Business Name' },
    // { key: 'status', label: 'Business Status' },
    { key: 'openUtcDay', label: 'Day' },
    { key: 'openUtcTime', label: 'Business Action Time (IST)' },
    // { key: 'openUtcTime', label: 'Business Open Time' },
    // { key: 'closeUtcTime Time', label: 'Business Close Time' },
    { key: 'resumeBusinessDate', label: 'Business Resume Date' },
    { key: 'resumeBusinessTime', label: 'Business Resume Time (IST)' },
    { key: 'actionStatus', label: 'Action Status' },
    { key: 'createdAt', label: 'Create Time (IST)' },
    { key: 'updatedAt', label: 'Update Time (IST)' },
    { label: 'Manually Open by' },
];

function EnhancedTableHead(props) {
    const {classes,numSelected, totalCount, onSelectAllClick,rowsPerPage} = props;
    
    
    return (
        <TableHead>
            <TableRow>
            <TableCell padding="checkbox">
                <Checkbox
                 //indeterminate={numSelected > 0 && numSelected < totalCount}
                    indeterminate={numSelected > 0 && numSelected < rowsPerPage}
                     //checked={totalCount > 0 && numSelected === totalCount}
                    checked={numSelected ? numSelected : null}
                    onChange={onSelectAllClick}
                    inputProps={{ "aria-label": "select all desserts" }}
                />
            </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        className={classes.tableCellSmall}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Business Open Close Logs ({props.totalCount})
            </Typography>

            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"open_close_business_log.csv"}
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                : <Button
                    variant="contained"
                    margin="normal"
                    style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                    onClick={props.fetchCallIconHitRecordsExcel}
                    >
                    Export
                </Button>
                } 
            </Typography> 
        </Toolbar>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableCellSmall: {
        fontSize: "13px",
        padding: "6px 0px 6px 16px"
    },
    tableRow: {
        // cursor: 'pointer'
    },
    tableCellSmall13: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", 
        width: '100%',
        textAlign: 'left',
        marginRight:"25px",
        marginLeft:"25px",
        maxWidth: 300,
        minWidth:200,
        width: 700,
    },
    tableCellSmall14: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px",
        width: '100%',
        textAlign: 'left',
        marginRight:"25px",
        marginLeft:"25px"
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    // 
    // 

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    let headers = [
    { key: 'businessId', label: 'Business ID' },
    { key: 'businessName', label: 'Business Name' },
    // { key: 'status', label: 'Business Status' },
    { key: 'day', label: 'Day' },
    { key: 'from', label: 'Business Action Time (IST)' },
    // { key: 'from', label: 'Business Open Time' },
    // { key: 'to', label: 'Business Close Time' },
    { key: 'resumeBusinessDate', label: 'Business Resume Date' },
    { key: 'resumeBusinessTime', label: 'Business Resume Time (IST)' },
    { key: 'actionStatus', label: 'Action Status' },
    { key: 'createdAt', label: 'Create Time (IST)' },
    { key: 'updatedAt', label: 'Update Time (IST)' },
    { key: "manuallyOpenBy", label: 'Manually Open By' },

    ];

    let data = []
    props.exportToExcel && props.exportToExcel.forEach(element => {
        data.push({
            businessId : element.businessId,
            businessName : element.businessName,
            // status : element.status,
            day : 'oldValues' in element && 'day' in element.oldValues && element.oldValues.day && element.oldValues.day,
            from : 'oldValues' in element && 'from' in element.oldValues && element.oldValues.from ? element.oldValues.from : 'oldValues' in element && 'to' in element.oldValues && element.oldValues.to ? element.oldValues.to : 'NA',
            resumeBusinessDate : element.resumeBusinessDate ?  moment(element.resumeBusinessDate).format('DD-MM-YYYY') :"NA",
             resumeBusinessTime : element.resumeBusinessTime ? element.resumeBusinessTime :"NA",
            
            actionStatus : element.actionStatus,
            createdAt : element.createdAt,
            updatedAt : element.updatedAt,
            // manuallyOpenBy : element.manuallyOpenByRole && element.manuallyOpenByUserFirstName && element.manuallyOpenByUserLastName  ? `${element.manuallyOpenByUserFirstName} ${element.manuallyOpenByUserLastName} (${element.manuallyOpenByRole==="areaManager"?"Area Manager" :element.manuallyOpenByRole==="superVendor" ? "Super Vendor": element.manuallyOpenByRole==="Admin"?"Admin": element.manuallyOpenByRole==="businessAdmin"?"Business Admin": `${element.manuallyOpenByRole}`}) ${element.updatedAt}`: "NA"
            manuallyOpenBy : element.manuallyOpenByRole && element.manuallyOpenByUserFirstName && element.manuallyOpenByUserLastName  ? `${element.manuallyOpenByRole === "areaManager" ? "Area Manager" : element.manuallyOpenByRole === "superVendor" ? "Business User": element.manuallyOpenByRole === "Admin" ? "Admin User" : element.manuallyOpenByRole === "businessAdmin" ? "Admin User" : `${element.manuallyOpenByRole}`} (${element.manuallyOpenByUserFirstName} ${element.manuallyOpenByUserLastName})`: "NA"
        })
    });

    let totalCount = props.totalCount;
    const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.totalCount - props.page * props.rowsPerPage);

    const isSelected = (_id) => props.selectedOpenCloseBusinessIds.indexOf(_id) !== -1;
    const handleCheckBox = (event, _id) => {
        const selectedIndex = props.selectedOpenCloseBusinessIds.indexOf(_id);
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(props.selectedOpenCloseBusinessIds, _id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(props.selectedOpenCloseBusinessIds.slice(1));
        } else if (selectedIndex === props.selectedOpenCloseBusinessIds.length - 1) {
          newSelected = newSelected.concat(props.selectedOpenCloseBusinessIds.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            props.selectedOpenCloseBusinessIds.slice(0, selectedIndex),
            props.selectedOpenCloseBusinessIds.slice(selectedIndex + 1),
          );
        }
        props.setSelected(newSelected);
      };
    
      const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // console.log("event.target.checked",event.target.checked)
            const openCloseBusiness = props.data.map((n) => n._id);
            //console.log(openCloseBusiness)
            props.setSelected(openCloseBusiness);
            return;
        }
        props.setSelected([]);
      };
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar 
                    headers={headers}
                    totalCount={props.totalCount}
                    data={data}
                    showDownloadIcon={props.showDownloadIcon}
                    fetchCallIconHitRecordsExcel={props.fetchCallIconHitRecordsExcel}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            rowCount={props.totalCount}
                            numSelected={props.selectedOpenCloseBusinessIds.length}
                            onSelectAllClick={handleSelectAllClick}
                            totalCount={props.totalCount}
                            rowsPerPage={props.rowsPerPage}
                        />
                        <TableBody>
                            {
                                props.data && props.data.map((row, index) => {
                                    const isItemSelected = isSelected(row._id)
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                onClick={(event) => handleCheckBox(event, row._id)}
                                                checked={isItemSelected}
                                                aria-checked={isItemSelected}
                                                selectedCouponIds={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall3} >{row.businessId}</TableCell>
                                            {/* <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall} >{row.orderId && row.orderId!=null && row.orderId!="" ? row.orderId : "NA"}</TableCell> */}
                                            <TableCell align="left" className={classes.tableCellSmall3} title={row.businessName} >{row.businessName && row.businessName.length < 27 ? row.businessName : `${row.businessName.slice(0,27)}...`}</TableCell>
                                            {/* <TableCell align="left" className={classes.tableCellSmall} >{row.callerId && row.callerId!=null && row.callerId!="" ? row.callerId : "NA"}</TableCell> */}
                                            {/* <TableCell align="left" className={classes.tableCellSmall} >{row.status && row.status === "ACTIVE" ? "Open" : "Close"}</TableCell> */}
                                            <TableCell align="left" className={classes.tableCellSmall3} >{row.oldValues && 'day' in row.oldValues ? row.oldValues.day : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.oldValues && 'from' in  row.oldValues ? row.oldValues.from : row.oldValues && 'to' in row.oldValues ? row.oldValues.to : 'NA'}</TableCell>
                                            {/* <TableCell align="left" className={classes.tableCellSmall} >{row.oldValues && row.oldValues.to ? row.oldValues.to : "NA"}</TableCell> */}
                                            <TableCell align="left" className={classes.tableCellSmall} >{row.resumeBusinessDate ?
                                             (moment(row.resumeBusinessDate).format('DD-MM-YYYY')) : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} >{row.resumeBusinessTime ? row.resumeBusinessTime : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} >{row.actionStatus ? row.actionStatus : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall13} >{row.createdAt ? row.createdAt : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall13} >{row.updatedAt ? row.updatedAt: "NA"}</TableCell>
                                            {/* <TableCell align="left" className={classes.tableCellSmall13} >{row.manuallyOpenByRole && row.manuallyOpenByUserFirstName && row.manuallyOpenByUserLastName  ? `${row.manuallyOpenByUserFirstName} ${row.manuallyOpenByUserLastName} (${row.manuallyOpenByRole==="areaManager"?"Area Manager" :row.manuallyOpenByRole==="superVendor" ? "Super Vendor": row.manuallyOpenByRole==="Admin"?"Admin": row.manuallyOpenByRole==="businessAdmin"?"Business Admin": `${row.manuallyOpenByRole}`}) ${row.updatedAt}`: "NA"}</TableCell> */}
                                            <TableCell align="left" className={classes.tableCellSmall13} >{ row.manuallyOpenByRole && row.manuallyOpenByUserFirstName && row.manuallyOpenByUserLastName  ? `${row.manuallyOpenByRole === "areaManager" ? "Area Manager" : row.manuallyOpenByRole === "superVendor" ? "Business User": row.manuallyOpenByRole === "Admin" ? "Admin User" : row.manuallyOpenByRole === "businessAdmin" ? "Admin User" : `${row.manuallyOpenByRole}`} (${row.manuallyOpenByUserFirstName} ${row.manuallyOpenByUserLastName})`: "NA" }</TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}