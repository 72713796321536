import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
// import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// import Backdrop from '@material-ui/core/Backdrop';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import SearchIcon from '@material-ui/icons/Search';
// import InputBase from '@material-ui/core/InputBase';
// import IconButton from '@material-ui/core/IconButton';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';

import AddAreaForm from './AddAreaForm';
import EditAreaForm from './EditAreaForm';
import AreaTable from './AreaTable';
import { changeHeaderName } from '../../actions/dashboardActions';
import {
	requestAreas,
	getAllAreas,
	addArea,
	updateArea,
	searchAreas,
	clearMsg
} from '../../actions/areaActions';
import { getRegionalHeadById } from '../../actions/regionalHeadActions';
import { getAllRegions } from '../../actions/regionActions';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { lightBlue } from '@material-ui/core/colors';

const styles = theme => ({
	container: {
		width: '100%',
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(4),
	},
	buttonContainer: {
		width: '100%',
		paddingTop: '10px'
	},
	content: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
	},
	appBarSpacer: theme.mixins.toolbar,
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	formControl: {
		minWidth: 225,
		maxWidth: 300,
	},
	backdrop: {
		zIndex: 9999,
		color: '#fff',
	},
	heading: {
		fontSize: "14px",
		fontWeight: 500
	},
	snackClose: {
		padding: theme.spacing(0.5),
	},
    containerin: {
        width: '100%',
        height: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(5),
        paddingRight: theme.spacing(0),

      },
      modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "150px",
        width: "100%",
      },
      lightBlue: {
        color: theme.palette.getContrastText(lightBlue[50]),
        backgroundColor: lightBlue[50],
        width: theme.spacing(4),
        height: theme.spacing(4),
        cursor: 'pointer'
    },
    
    disabledrow: {
        backgroundColor: '#dcdcdc',
    },
    tableCellSmall: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", //TRBL
        width: '100%',
        textAlign: 'center',
        marginRight:"25px",
        marginLeft:"25px",
      },
      tableCellSmall11: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", //TRBL
        width: '100%',
        textAlign: 'center',
        marginRight:"25px",
        marginLeft:"25px",
        maxWidth: 150,
        minWidth:120,
        width: 150,
      },
      tableCellSmall12: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", //TRBL
        width: '100%',
        textAlign: 'center',
        marginRight:"25px",
        marginLeft:"25px",
        maxWidth: 200,
        minWidth:140,
        width: 200,
      },
      tableCellSmall13: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", //TRBL
        width: '100%',
        textAlign: 'left',
        marginRight:"25px",
        marginLeft:"25px",
        maxWidth: 700,
        minWidth:300,
        width: 700,
      },
    tableRow: {
        cursor: 'pointer'
    },
	modalContainer: {
		width: '60%',
		height: '100%',
		backgroundColor: theme.palette.background.paper,
		padding: '0px 0px',
	},
	searchBox: {
		width: '30%',
		marginTop: '24px'
	},
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

class Area extends Component {
	constructor() {
		super();
		this.state = {
			modalOpen: false,
			snackOpen: false,
			sucessMsg: null,
			errorMsg: null,
			areaSearchvalue: "",
			regionName:"",
			isActive:"",
			pincode:"",
			clearSearch: false,
			order: -1,
			orderBy: 'name',
			page: 0,
			rowsPerPage: 10,
			editMode: false,
			areaToEdit: null,
		};
	}

	async componentDidMount() {
		this.props.changeHeaderName("Manage Areas");
		await this.props.getRegionalHeadById(this.props.user.id);
		await this.getAssociatedRegions();
		this.fetchAreasByQuery();
	}

	getAssociatedRegions = () => {
		this.props.getAllRegions({
			skip: 0,
			limit: 0,
			regionIds: this.props.regionalHead.regionalHead.controlledRegions.join(',')
		});
	}

	handleModalOpen = () => {
		this.setState({ modalOpen: true });
	}

	handleModalClose = () => {
		this.setState({ modalOpen: false, editMode: false });
	}

	openEditAreaModal = (area) => {
		this.setState({ editMode: true, areaToEdit: area, modalOpen: true });
	}

	handleSnackClose = () => {
		this.setState({ snackOpen: false });
		this.setState({ modalOpen: false });
		this.setState({ sucessMsg: null });
		this.setState({ errorMsg: null });
		this.fetchAreasByQuery();
	}

	fetchAreasByQuery = () => {
		this.props.requestAreas();
		let payload = {
			regionids: this.props.regionalHead.regionalHead.controlledRegions.join(','),
			orderBy: this.state.orderBy,
			order: Number(this.state.order),
			skip: this.state.page * this.state.rowsPerPage,
			limit: this.state.rowsPerPage,
			searchkey: this.state.areaSearchvalue,
			regionName:this.state.regionName,
			isActive:this.state.isActive,
			pincode:this.state.pincode.trim()
		}

		// if (this.state.areaSearchvalue || this.state.regionName || this.state.isActive || this.state.pincode) {
		// 	this.props.searchAreas(payload);
		// } else {
			this.props.getAllAreas(payload);
		// }
	}

	handleSearchChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleSearchSubmit = (event) => {
		event.preventDefault();
		if (this.state.areaSearchvalue.trim()) {
			this.setState({
				page: 0,
				clearSearch: true
			}, () => this.fetchAreasByQuery());
		} else {
			this.setState({ clearSearch: false }, () => this.fetchAreasByQuery());
		}
	}

	handleClearButton = () => {
		this.setState({
			page: 0,
			areaSearchvalue: "",
			pincode:"",
			regionName:"",
			isActive:"",
			clearSearch: false,
		}, () => this.fetchAreasByQuery())
	}

	addArea = (props) => {
		this.props.addArea(props);
	}

	updateArea = (payload) => {
		this.props.updateArea(payload);
	}

	setOrder = (order) => {
		this.setState({ order }, () => this.fetchAreasByQuery());
	}

	setOrderBy = (orderBy) => {
		this.setState({ orderBy }, () => this.fetchAreasByQuery());
	}

	setPage = (page) => {
		this.setState({ page }, () => this.fetchAreasByQuery());
	}

	setRowsPerPage = (rowsPerPage) => {
		this.setState({ rowsPerPage });
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.area.sucessMsg) {
			this.setState({ sucessMsg: this.props.area.sucessMsg })
			this.setState({ snackOpen: true });
			this.setState({ modalOpen: false });
			this.props.clearMsg();
			this.fetchAreasByQuery();
		} else if (this.props.area.errorMsg) {
			this.setState({ errorMsg: this.props.area.errorMsg })
			this.setState({ snackOpen: true });
			this.props.clearMsg();
		}
	}

	render() {
		const { classes } = this.props;
		let muiAlert = null;
	
		if (this.state.sucessMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.sucessMsg}
			</MuiAlert>
		} else if (this.state.errorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.errorMsg}
			</MuiAlert>
		}

		let clearSearchButton = null;

		if (this.state.clearSearch) {
			clearSearchButton = <Button variant="contained"
				color="primary"
				onClick={() => this.handleClearButton()}
				className={classes.clearButton}>
				Clear
            </Button>
		}
    //   console.log(" props---->",this.props);
		return (

			<div className={classes.content}>
			<div className={classes.appBarSpacer} />
			<Container className={classes.container}>
			  <Container className={classes.searchPaper}>
			   <ExpansionPanel>
				   <ExpansionPanelSummary
					   expandIcon={<ExpandMoreIcon />}
					   aria-controls="panel1a-content"
					   id="panel1a-header"
				   >
					   <Typography className={classes.heading}>Search Area</Typography>
				   </ExpansionPanelSummary>
				   <ExpansionPanelDetails onKeyDown={this.enterPressed}>
					   <Grid container spacing={2}>
					   
					 <Grid item xs={3}>
                                      <TextField fullWidth margin="dense" name="areaSearchvalue" label="Area Code/Name" value={this.state.areaSearchvalue} variant="outlined" onChange={this.handleSearchChange} />
                                </Grid>
						   <Grid item xs={3} >
						
							   <TextField fullWidth margin="dense" name="regionName" label="Region Name" value={this.state.regionName} variant="outlined" onChange={this.handleSearchChange} />
						   </Grid>
						   <Grid item xs={3} >
							   
							   <TextField fullWidth margin="dense" name="pincode"label="Pincode" value={this.state.pincode} variant="outlined" onChange={this.handleSearchChange} />
						   </Grid>

						   <Grid item xs={3}>
                                    <FormControl fullWidth margin="dense" variant="outlined" className={classes.formControl}>
                                        <InputLabel id="delivery-partner-label"> Status</InputLabel>
                                        <Select
                                            value={this.state.isActive}
                                            name="isActive"
                                            onChange={this.handleSearchChange}
											label="Status"
                                        >
                                            <MenuItem value="true">Active</MenuItem>
                                            <MenuItem value="false">InActive</MenuItem>
                                           
                                        </Select>
                                    </FormControl>
                                </Grid>
								<Grid item xs={8}>

								</Grid>
								<Grid item xs={2} >
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.handleClearButton}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2} >
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                       
                                        onClick={this.fetchAreasByQuery}

                                    >
                                        Search
                                    </Button>
                                </Grid>
						   </Grid>
						   </ExpansionPanelDetails>
					   </ExpansionPanel>
				</Container>
				<Container className={classes.containerin}>
				<Grid item xs={12} >
					{this.props.regionalHead.regionalHead && this.props.regionalHead.regionalHead.controlledRegions && this.props.regionalHead.regionalHead.controlledRegions.length>0 ?
							<Button
								variant="contained"
								color="primary"
								startIcon={<AddIcon />}
								onClick={() => this.handleModalOpen()}
								style={{ "marginRight": "28px", "float": "right" }}
							>
								Add Area
          				</Button>
						  :null}
						</Grid>
						<Modal
						className={classes.modal}
						open={this.state.modalOpen}
						onClose={this.handleModalClose}
						closeAfterTransition>
						<Container className={classes.modalContainer}>
							{
								this.state.editMode ? <EditAreaForm initialValues={_.pick(this.state.areaToEdit, '_id', 'name', 'pincode', 'areaCode', 'regionData','isActive')} regions={this.props.regions.data && this.props.regions.data.data ? this.props.regions.data.data : []} onSubmit={this.updateArea} onClose={this.handleModalClose} />
									: <AddAreaForm regions={this.props.regions.data && this.props.regions.data.data ? this.props.regions.data.data : []} onSubmit={this.addArea} onClose={this.handleModalClose} />
							}

						</Container>
					</Modal>
					<Container className={classes.container}>
						{
							this.props.area.areas && this.props.area.areas.data && this.props.area.areas.data.length>0 ?
								<AreaTable
									rowData={this.props.area.areas}
									// order={this.state.order === 1 ? 'asc' : 'desc'}
									// orderBy={this.state.orderBy}
									page={this.state.page}
									rowsPerPage={this.state.rowsPerPage}
									setOrder={this.setOrder}
									setOrderBy={this.setOrderBy}
									setPage={this.setPage}
									setRowsPerPage={this.setRowsPerPage}
									addArea={this.addArea}
									updateArea={this.updateArea}
									openEditAreaModal={this.openEditAreaModal}
									history={this.props.history}
								/>
								: null
						}
					</Container>

                </Container>
				</Container>
			</div>
			
		// 	<Container className={classes.content}>
		// 	<div className={classes.content}>
		// 		<div className={classes.appBarSpacer} />
		// 		<Container className={classes.container}>
		// 			<Grid container spacing={3} style={{ alignItems: 'center' }}>
		// 				<Grid item xs={8}>
		// 					<Container className={classes.searchPaper}>
		// 						<Paper>
		// 							<form onSubmit={this.handleSearchSubmit}>
		// 								<InputBase
		// 									margin="dense"
		// 									placeholder="Area Code/Area Name"
		// 									value={this.state.areaSearchvalue}
		// 									style={{ width: '240px', margin: '0px 10px' }}
		// 									onChange={this.handleSearchChange}
		// 								/>
		// 								<IconButton type="submit">
		// 									<SearchIcon style={{ float: 'right' }} />
		// 								</IconButton>
		// 							</form>
		// 						</Paper>
		// 						{clearSearchButton}
		// 					</Container>
		// 				</Grid>
		// 				<Grid item xs={3}>
        //                             <TextField fullWidth margin="dense" name="areaSearchvalue" label="Area Code/Area Name" value={this.state.areaSearchvalue} variant="outlined" onChange={this.handleSearchSubmit} />
        //                 </Grid>
		// 				<Grid item xs={4} >
		// 				{this.props.regionalHead.regionalHead && this.props.regionalHead.regionalHead.controlledRegions && this.props.regionalHead.regionalHead.controlledRegions.length>0 ?
		// 					<Button
		// 						variant="contained"
		// 						color="primary"
		// 						startIcon={<AddIcon />}
		// 						onClick={() => this.handleModalOpen()}
		// 						style={{ "marginRight": "28px", "float": "right" }}
		// 					>
		// 						Add Area
        //   				</Button>
		// 				  :null}
		// 				</Grid>
		// 			</Grid>

		// 			<Modal
		// 				className={classes.modal}
		// 				open={this.state.modalOpen}
		// 				onClose={this.handleModalClose}
		// 				closeAfterTransition>
		// 				<Container className={classes.modalContainer}>
		// 					{
		// 						this.state.editMode ? <EditAreaForm initialValues={_.pick(this.state.areaToEdit, '_id', 'name', 'pincode', 'areaCode', 'regionid')} regions={this.props.regions.data && this.props.regions.data.data ? this.props.regions.data.data : []} onSubmit={this.updateArea} onClose={this.handleModalClose} />
		// 							: <AddAreaForm regions={this.props.regions.data && this.props.regions.data.data ? this.props.regions.data.data : []} onSubmit={this.addArea} onClose={this.handleModalClose} />
		// 					}

		// 				</Container>
		// 			</Modal>
		// 			<Container className={classes.container}>
		// 				{
		// 					this.props.area.areas && this.props.area.areas.data && this.props.area.areas.data.length>0 ?
		// 						<AreaTable
		// 							rowData={this.props.area.areas}
		// 							// order={this.state.order === 1 ? 'asc' : 'desc'}
		// 							// orderBy={this.state.orderBy}
		// 							page={this.state.page}
		// 							rowsPerPage={this.state.rowsPerPage}
		// 							setOrder={this.setOrder}
		// 							setOrderBy={this.setOrderBy}
		// 							setPage={this.setPage}
		// 							setRowsPerPage={this.setRowsPerPage}
		// 							addArea={this.addArea}
		// 							updateArea={this.updateArea}
		// 							openEditAreaModal={this.openEditAreaModal}
		// 							history={this.props.history}
		// 						/>
		// 						: null
		// 				}
		// 			</Container>
		// 		</Container>

		// 		<Snackbar
		// 			anchorOrigin={{
		// 				vertical: 'top',
		// 				horizontal: 'center',
		// 			}}
		// 			open={this.state.snackOpen}
		// 			autoHideDuration={6000}
		// 			onClose={this.handleSnackClose}
		// 			ContentProps={{
		// 				'aria-describedby': 'message-id',
		// 			}}>
		// 			{muiAlert}
		// 		</Snackbar>

		// 		<Backdrop className={classes.backdrop} open={this.props.area.isFetching}>
		// 			<CircularProgress color="inherit" />
		// 		</Backdrop>
		// 	</div>
		// </Container>
		);
	}
}

const mapStateToProps = state => {
	return {
		area: state.area,
		regions: state.region.regions,
		regionalHead: state.regionalHead,
		user: state.auth.user
	}
};
export default connect(mapStateToProps, {
	changeHeaderName,
	requestAreas,
	getAllAreas,
	addArea,
	updateArea,
	getAllRegions,
	searchAreas,
	getRegionalHeadById,
	clearMsg
})(withStyles(styles)(Area));