import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from "@material-ui/core/Box";
import Fab from '@material-ui/core/Fab';
import _, { includes } from 'lodash';
import { connect } from "react-redux";
import Modal from '@material-ui/core/Modal';
import SearchIcon from '@material-ui/icons/Search';
import { Avatar, Chip, IconButton } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import { getAllAddonList, createAddonGroup , updateAddonGroup } from '../../../../actions/addonGroupAction'
import { List, ListItem, ListItemIcon } from '@material-ui/core';
import { ReactComponent as Veg } from '../../../../images/veg.svg';
import { ReactComponent as Egg } from '../../../../images/Egg.svg';
import { ReactComponent as Nonveg } from '../../../../images/Nonveg.svg';
import { element } from 'prop-types';

const styles = theme => ({
    form: {
        backgroundColor: '#fff',
        overflow: "hidden",
        flexGrow: 1,
        borderBottom: "0.5px solid #3d8bc3",

    },
    dynamicForm: {
        backgroundColor: "#fff",
        overflow: "auto",
        flexGrow: 1,
        borderBottom: "0.5px solid #3d8bc3",
        height: '590px',
        '&::-webkit-scrollbar': {
            width: '1px',
            backgroundColor: 'white',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'white',
            borderRadius: '1px',
        },
    },
    header: {
        height: '6%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    CloseIcon: {
        float: "right",
        color: "white"
    },
    headerText: {
        color: "white",
        fontSize: "20px",
        fontFamily: "normal normal 600 14px/26px Open Sans"
    },

    footer: {
        height: '10%',
        padding: '20px 0px 10px 150px',
        display: 'flex',
    },

    body: {
        fontFamily: "normal normal 600 14px/26px Open Sans",
        padding: "5px 5px 5px 5px",

    },
    divB: {
        padding: "5px 25px",
    },
    innerBody: {
        display: 'flex!important',
        justifyContent: 'center!important',

    },
    bodyHeaders: {
        fontWeight: 420
    },
    box: {
        height: '90px',
        width: '100%',
        border: '1px dashed grey',
        backgroundColor: '#f6f6f6'
    },
    icon: {
        heigth: '40px',
        width: '40px'
    },
    flexHeader: {
        display: 'flex!important'
    },
    boxContainer1: {
        margin: '5px 300px 0px 300px',

    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: theme.spacing(1),
    },
    searchPaper: {
        padding: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '25px',

    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '45px',
        border: '0.5px solid #d1cdcd'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 2,
    },
    divider: {
        height: 40,
        margin: 4,
    },
    secondaryRow: {
        background: 'none',
        padding: '0px',
        height: 'auto',
        display: 'inline-flex!important',
        color: 'black'
    },
    secondaryPadding: {
        marginLeft: '8px'
    },

    dynamicBox: {
        height: 'fit-content',
        width: '100%',
        border: '1px dashed grey',
        backgroundColor: '#f6f6f6',
        display: "flex", flexWrap: "wrap"
    },
    chipBody: {
        margin: '4px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        backgroundColor: '#FFB300'
    },
    dynamicChipBody: {
        margin: '4px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        backgroundColor: '#15B5D8'
    },
    hiddenItemChipBody: {
        margin: '4px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        backgroundColor: '#CCCCCC'
    },
    linkContainer: {
        padding: "5px 25px",
        display: 'flex!important'
    },
    defaultAndHiddenItem :{
        margin: '4px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        background: 'linear-gradient(90deg, orange 50%, #CCCCCC 50%)',
    }

});


class CreateAddonGroup extends React.Component {
    constructor() {
        super();
        this.state = {
            showSearchField: false,
            addOnName: "",
            fetchedAddonRecords: null,
            selectedAddons: [],
            addOnGroupName: '',
            selectionType: '',
            minSelection: null,
            maxSelection: null,
            applyPrice: '',
            description: '',
            isDefaultIndexes: 0,
            postFetchedAddonRecords: null,
            stateCheck: 0,
            error: {},
            noRecordsAvailable : false


        };

    }


    async componentDidMount() {
        this.setData()
        
    };



    setData = () => {
        if (this.props.groupEditFlag) {
            let initalDetails = this.props.dataToSet
            this.setState({
                showSearchField:  true,
                selectedAddons:  initalDetails.linkedAddOns,
                addOnGroupName:  initalDetails.addOnGroupName,
                selectionType:   initalDetails.selectionType,
                minSelection:    initalDetails.minSelection > 0 ? initalDetails.minSelection :  'NA',
                maxSelection:    initalDetails.maxSelection > 0 ? initalDetails.maxSelection :  'NA',
                applyPrice:      initalDetails.applyPrice  === true ? 'Yes' : 'No',
                description:     initalDetails.description,
                postFetchedAddonRecords :  initalDetails.linkedAddOns,
            });

          }
    };

    
    update = async (value)=>{
        let initalDetails = this.props.dataToSet
        let payload ={
            addonGroupId : this.props.dataToSet._id,
            
        }
        if (value.addOnGroupName !== initalDetails.addOnGroupName) {
            payload.addOnGroupName  =   value.addOnGroupName
        }
        if (value.minSelection !== initalDetails.minSelection) {
            payload.minSelection  =   value.minSelection
        }
        if (value.maxSelection !== initalDetails.maxSelection) {
            payload.maxSelection  =   value.maxSelection
        }
        if (value.applyPrice !== initalDetails.applyPrice) {
            payload.applyPrice  =   value.applyPrice
        }
        if (value.description  !== initalDetails.description) {
            payload.description  =   value.description
        }
            
        payload['linkedAddOns']  = value.linkedAddOns;
        payload['defaultAddOns'] = value.defaultAddOns;
      
        await this.props.updateAddonGroup(payload)
        
    }
    

    componentDidUpdate(prevProps) {
        if (this.props.groupEditFlag && (this.props.groupEditFlag !== prevProps.groupEditFlag)) {
			this.setData()
		}
    };

    reset = () => {
        this.setState({
            showSearchField: false,
            addOnName: "",
            fetchedAddonRecords: null,
            selectedAddons: [],
            addOnGroupName: '',
            selectionType: '',
            minSelection: null,
            maxSelection: null,
            applyPrice: '',
            description: '',
            isDefaultIndexes: 0,
            postFetchedAddonRecords: null,
            stateCheck: 0,
            error: {},
            addOptions: '',
            noRecordsAvailable : false
        });
    };

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        };
        if (event.target.value === 'Single Selection') {
            this.setState({ minSelection: 'NA', maxSelection: 'NA' })
        }
    }

    handleShowSearchBar = () => {
        let hasError = false;
        let error = this.state.error
        if (this.state.selectionType === null || this.state.selectionType === '') {
            error['selectionType'] = "Please Select Selection Type"
            this.setState({ error: error })
            hasError = true
        }
        if (this.state.selectionType === '' || this.state.selectionType === 'Multi Selection') {
            if (this.state.minSelection === null || this.state.minSelection === '') {
                error['minSelection'] = "Please Enter Min Selection"
                this.setState({ error: error })
                hasError = true
            }
            if (this.state.maxSelection === null || this.state.maxSelection === '') {
                error['maxSelection'] = "Please Enter Max Selection"
                this.setState({ error: error })
                hasError = true
            }
        }
        if (hasError) {
            return
        }
        this.setState({ showSearchField: true })
    };

    onClose = async () => {
        this.reset()
        await this.props.handleCreateAddonGroupClose()
    };

    handleOnChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })


    };

    handleSearchAddons = async () => {

        let payload = {
            id: this.props.businessDetails._id,
            addOnName: this.state.addOnName.trim()
        }
        await this.props.getAllAddonList(payload);
        this.setState({ fetchedAddonRecords: this.props.addonGroup.addonList.data, postFetchedAddonRecords: this.props.addonGroup.addonList.data , noRecordsAvailable :  this.props.addonGroup.addonList.noRecordsAvailable })

    }

    handleAddonReset = async () => {
        this.setState({ fetchedAddonRecords: null, addOnName: '' , noRecordsAvailable : false})

    }
    /** Assign default */
    handleAddonSelection = async (item) => {
        let tempArray = [];
        let tempDefaultCount = 1;
        if (item) {
            tempArray.push(...this.state.selectedAddons, item)
        };
        let defaultItemCount = this.state.selectionType === 'Single Selection' ? 1 : Number(this.state.minSelection)
        let selectedArray = new Set(tempArray.map(obj => obj._id))
        let unSelectedArray = this.state.fetchedAddonRecords.filter(obj => !selectedArray.has(obj._id));
        let uniqueSelectedAddons = Array.from(new Map(tempArray.map(element => [element._id, element])).values());
         // Find the first element with isDefault true and either isEnabled false or isDisplay false
        if (this.props.groupEditFlag && item.isDisplay && item.isEnabled) {
        const elementToUpdate = uniqueSelectedAddons.find( (item) => item.isDefault && (!item.isEnabled || !item.isDisplay));
        if (elementToUpdate) { elementToUpdate.isDefault = false; }
        }
        uniqueSelectedAddons.forEach((item, index) => {
            if (defaultItemCount > 0 && item.isDisplay === true && item.isEnabled === true) {

                for (let index = 1; index <= (defaultItemCount && tempDefaultCount <= defaultItemCount); index++) {
                    tempDefaultCount++
                    item['isDefault'] = true;
                }
            }
        });
        uniqueSelectedAddons.sort((a, b) => {
            if (a.isDefault && !b.isDefault) {
                return -1; // a should come before b
            } else if (!a.isDefault && b.isDefault) {
                return 1; // b should come before a
            }
            return 0; // order remains unchanged
        });
        this.setState({ selectedAddons: uniqueSelectedAddons, fetchedAddonRecords: unSelectedArray, error: {} });

    }

    handleRemoveAddons = (_id) => {
        let removeAddonRecord = _id
        let selectedAddons = this.state.selectedAddons
        let defaultItemCount = this.state.selectionType === 'Single Selection' ? 1 : Number(this.state.minSelection)
        let finalAddonRecords = selectedAddons.filter(obj => obj._id !== removeAddonRecord.toString());
        let existingDefaultRecord = finalAddonRecords.filter(v => v.isDefault === true)
        let tempDefaultIndexCount = existingDefaultRecord.length > 0 ? existingDefaultRecord.length : 1
        finalAddonRecords.forEach((item, index) => {

            if (this.state.selectionType === 'Single Selection' && existingDefaultRecord.length === 0) {
                if (defaultItemCount > 0 && (item.isDisplay === true && item.isEnabled === true && item.isDefault !== true) && (defaultItemCount > 1 ? tempDefaultIndexCount < defaultItemCount : tempDefaultIndexCount <= defaultItemCount)) {
                    tempDefaultIndexCount++
                    item['isDefault'] = true;

                }
            } else if (this.state.selectionType === 'Multi Selection') {
                if (defaultItemCount > 0 && (item.isDisplay === true && item.isDefault !== true && item.isEnabled === true) && (defaultItemCount > 1 ? tempDefaultIndexCount < defaultItemCount : tempDefaultIndexCount <= defaultItemCount)) {
                    tempDefaultIndexCount++
                    item['isDefault'] = true;

                }
            }

        });
        let selectedArray = new Set(finalAddonRecords.map(obj => obj._id))
        let unSelectedArray = this.state.postFetchedAddonRecords.filter(obj => !selectedArray.has(obj._id));
        unSelectedArray.forEach((v) => {
            v['isDefault'] = false
        });
        finalAddonRecords.sort((a, b) => {
            if (a.isDefault && !b.isDefault) {
                return -1; // a should come before b
            } else if (!a.isDefault && b.isDefault) {
                return 1; // b should come before a
            }
            return 0; // order remains unchanged
        });
        this.setState({ selectedAddons: finalAddonRecords, fetchedAddonRecords: unSelectedArray, error: {} })

    };


 

    onSubmit = async () => {

        let hasError = false;
        let error = this.state.error

        if (this.state.selectionType === null || this.state.selectionType === '') {
            error['selectionType'] = "Please Select Selection Type"
            this.setState({ error: error })
            hasError = true
        }
        if (this.state.selectionType === '' || this.state.selectionType === 'Multi Selection') {
            if (this.state.minSelection === null || this.state.minSelection === '') {
                error['minSelection'] = "Please Enter Min Selection"
                this.setState({ error: error })
                hasError = true
            }
            if (this.state.maxSelection === null || this.state.maxSelection === '') {
                error['maxSelection'] = "Please Enter Max Selection"
                this.setState({ error: error })
                hasError = true
            }
        }
        if (this.state.addOnGroupName === '' || this.state.addOnGroupName === null) {
            error['addOnGroupName'] = "Please Enter AddonGroup Name"
            this.setState({ error: error })
            hasError = true
        }
        if (this.state.applyPrice === '' || this.state.applyPrice === null) {
            error['applyPrice'] = "Please Select Apply Price"
            this.setState({ error: error })
            hasError = true
        }
        if (this.state.minSelection !== null && this.state.maxSelection !== null) {
            let minSelection = this.state.minSelection === 'NA' ? 0 : this.state.minSelection
            let maxSelection = this.state.maxSelection === 'NA' ? 0 : this.state.maxSelection
            if (Number(maxSelection) < Number(minSelection) && ( minSelection && maxSelection) > 0) {
                error['maxSelection'] = "Max Selection Should be Greater than Min Selection"
                this.setState({ error: error })
                hasError = true
            }

        }
        if (this.state.minSelection !== null) {
            let minSelection = Number(this.state.minSelection);
            let isMinSelectionNA =  isNaN(minSelection)   ?  this.state.minSelection.trim() : minSelection
            if (isMinSelectionNA !== "NA" && isNaN(minSelection)) {
                error['minSelection'] = "Min Selection Should be Either Number or NA"
                this.setState({ error: error })
                hasError = true
            }
        }
        if (this.state.maxSelection !== null) {
            let maxSelection = Number(this.state.maxSelection);
            let isMaxSelectionNA =  isNaN(maxSelection)   ?  this.state.maxSelection.trim() : maxSelection
            if (isMaxSelectionNA !== "NA"  && isNaN(maxSelection)) {
                error['maxSelection'] = "Max Selection Should be Either Number or NA"
                this.setState({ error: error })
                hasError = true
            }
        }
        if (this.state.selectedAddons.length === 0) {
            error['addOptions'] = "Please Select Addons To Link Addon Group"
            this.setState({ error: error })
            hasError = true
        }
        if (this.state.selectionType === 'Single Selection' && this.state.selectedAddons.length > 0) {
            let isDefaultExist = this.state.selectedAddons.filter(value => value.isDefault === true)
            if (isDefaultExist.length !== 1) {
                error['addOptions'] = "Please Select Atleast One Default Addon To Fullfil Single Selection Criteria"
                this.setState({ error: error })
                hasError = true
            }
            if (isDefaultExist.length > 1) {
                error['addOptions'] = "Only One Default Addon is Allowed for Single Selection Criteria"
                this.setState({ error: error })
                hasError = true
            }
        }
        if (this.state.selectionType === 'Multi Selection' && this.state.selectedAddons.length > 0 && Number(this.state.minSelection) > 0) {
            let isDefaultCount = this.state.selectedAddons.filter(value => value.isDefault === true)
            if (isDefaultCount.length !== Number(this.state.minSelection)) {
                error['addOptions'] = "Number of Default Addons Should be Equal to Min Selection Criteria"
                this.setState({ error: error })
                hasError = true
            }
        }
        if ((this.state.selectionType === 'Multi Selection' && this.state.selectedAddons.length > 0 && Number(this.state.minSelection) === 0) ||( this.state.minSelection === 'NA' && this.state.selectionType === 'Multi Selection')) {
            let isDefaultExist = this.state.selectedAddons.filter(element => element.isDefault === true)
            if (Number(this.state.minSelection) === 0 && isDefaultExist.length > 0 || this.state.minSelection === 'NA' && isDefaultExist.length > 0) {
                error['addOptions'] = `Default Addons are not allowed for Not Required Group`
                this.setState({ error: error })
                hasError = true
            }
        }


        if (hasError) {
            return
        }
        let addonsToLink = this.state.selectedAddons.map(element => element._id);
        let defaultAddOns = this.state.selectedAddons.reduce((acc, element) => {
            if (element.isDefault === true) {
                acc.push(element._id);
            }
            return acc;
        }, []);

        let payload = {
            addOnGroupName: this.state.addOnGroupName,
            description: this.state.description,
            minSelection:  Number(this.state.minSelection) > 0 ? Number(this.state.minSelection) : 0,
            maxSelection:  Number(this.state.maxSelection) > 0 ? Number(this.state.maxSelection) : 0,
            applyPrice: this.state.applyPrice === 'Yes' ? true : false,
            isEnabled: false,
            linkedAddOns: addonsToLink,
            defaultAddOns: defaultAddOns,
            businessId: this.props.businessDetails._id,
            selectionType: this.state.selectionType
        }

        if (this.props.groupEditFlag) {
            this.update(payload)
        } else {
            await this.props.createAddonGroup(payload);
        }
        this.onClose();
        this.reset();
        await this.props.fetchAddonGroupRecords()
        




    };
    
    render() {
        const { classes } = this.props;
        let state = this.state
        let addonRecords = this.state.fetchedAddonRecords
        let selectedAddons = this.state.selectedAddons


        return (
            <div>
                <Grid container className={classes.header} spacing={1}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom className={classes.headerText}>
                            Create Addon Group
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.CloseIcon} onClick={() => { this.onClose() }} />
                    </Grid>
                </Grid>
                <div className={state.showSearchField === true || this.state.error !== null ? classes.dynamicForm : classes.form}>

                    <Container className={classes.body}>

                        <Container className={classes.innerBody}>
                            <Grid style={{ marginRight: '20px' }}>
                                <Typography className={classes.bodyHeaders}>
                                    Addon Group Name*
                                </Typography>
                                <TextField style={{ width: "340px" }} margin="dense" name="addOnGroupName" value={this.state.addOnGroupName} variant="outlined" onChange={this.handleFilterChange} placeholder="Enter Addon Group Name.." error={this.state.error.addOnGroupName} helperText={this.state.error.addOnGroupName} />
                            </Grid>

                            <Grid>
                                <Typography className={classes.bodyHeaders}>
                                    Selection Type*
                                </Typography>
                                <FormControl fullWidth margin="dense" variant="outlined" >
                                    <InputLabel id="demo-simple-select-standard-label" variant="outlined">
                                        Select Selection Type
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        name="selectionType"
                                        style={{ width: "340px", maxHeight: "40px" }}
                                        variant="outlined"
                                        value={this.state.selectionType}
                                        label="Select Selection Type"
                                        onChange={this.handleFilterChange}
                                        error={this.state.error.selectionType} helperText={this.state.error.selectionType}
                                        disabled={this.props.groupEditFlag ? true : false}

                                    >
                                        <MenuItem value="Single Selection" style={{ minHeight: "28px", height: "28px" }}>
                                            <ListItemText primary="Single Selection" />
                                        </MenuItem>
                                        <MenuItem value="Multi Selection" style={{ minHeight: "28px", height: "28px" }}>
                                            <ListItemText primary="Multi Selection" />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {this.state.error.selectionType && <div style={{ color: 'red', margin: '0px 0px 0px 5px', fontSize: '12px' }}>{this.state.error.selectionType}</div>}
                            </Grid>
                        </Container>

                        <Container className={classes.innerBody}>
                            <Grid style={{ marginRight: '20px' }}>
                                <Typography className={classes.bodyHeaders}>
                                    Min Selection
                                </Typography>
                                <TextField style={{ width: "340px" }} margin="dense" name="minSelection" value={this.state.minSelection } variant="outlined" placeholder={this.state.selectionType === 'Single Selection' ? 'NA' : this.state.selectionType === 'Multi Selection' && this.state.minSelection !== null  ?  this.state.minSelection : 'Enter Min Selection'}  onChange={this.handleFilterChange}
                                    disabled={this.state.selectionType === 'Single Selection' ? true : false} error={this.state.error.minSelection} helperText={this.state.error.minSelection} />
                            </Grid>

                            <Grid>
                                <Typography className={classes.bodyHeaders}>
                                    Max Selection
                                </Typography>

                                <TextField style={{ width: "340px" }} margin="dense" name="maxSelection" value={this.state.maxSelection} variant="outlined" placeholder={this.state.selectionType === 'Single Selection' ? 'NA' : this.state.selectionType === 'Multi Selection' && this.state.maxSelection !== null  ?  this.state.maxSelection : 'Enter Max Selection'}  onChange={this.handleFilterChange}
                                    disabled={this.state.selectionType === 'Single Selection' ? true : false} error={this.state.error.maxSelection} helperText={this.state.error.maxSelection} />
                            </Grid>
                        </Container>


                        <Grid className={classes.divB}>
                            <Typography className={classes.bodyHeaders}>
                                Apply Price*
                            </Typography>
                            <FormControl fullWidth margin="dense" variant="outlined" >
                                <InputLabel id="demo-simple-select-standard-label" variant="outlined">
                                    Select Apply Price
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    name="applyPrice"
                                    value={this.state.applyPrice}
                                    style={{ width: "340px", maxHeight: "40px" }}
                                    variant="outlined"
                                    label="Select Apply Price"
                                    onChange={this.handleFilterChange}
                                >
                                    <MenuItem value='Yes' style={{ minHeight: "28px", height: "28px" }}>
                                        <ListItemText primary="Yes" />
                                    </MenuItem>
                                    <MenuItem value='No' style={{ minHeight: "28px", height: "28px" }}>
                                        <ListItemText primary="No" />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {this.state.error.applyPrice && <div style={{ color: 'red', margin: '0px 0px 0px 5px', fontSize: '12px' }}>{this.state.error.applyPrice}</div>}
                        </Grid>

                        <Grid className={classes.divB}>
                            <Typography className={classes.bodyHeaders}>
                                Description
                            </Typography>
                            <TextField style={{ width: "100%" }} margin="dense" name="description" value={this.state.description} onChange={this.handleFilterChange} variant="outlined" placeholder="Enter Description" />
                        </Grid>


                        <Container className={classes.divB}>

                            <Grid className={classes.flexHeader}>

                                <Typography className={classes.bodyHeaders} style={{ minWidth: '590px' }}>
                                    Add Option
                                </Typography>

                                <Typography className={classes.bodyHeaders} style={{ marginRight: '5px', color: '#FFB300' }}>*</Typography>
                                <Typography className={classes.bodyHeaders} style={{ fontStyle: 'italic', color: '#FFB300' }}>
                                    Default
                                </Typography>

                            </Grid>



                            {selectedAddons && selectedAddons.length ? <Box className={classes.dynamicBox}>
                                {selectedAddons.map((item, index) => {
                                    const text = `${item.service} ${` ${`₹`} ${item.cost.fixedCost}`} (${item.cost.quantity ? item.cost.quantity : 'per'} ${item.cost.unit})`;
                                    return (
                                        <div style={{ margin: '4px' }}>
                                            <Chip
                                                label={text}
                                                avatar={<IconButton><CloseIcon style={{ color: 'white' }} onClick={() => this.handleRemoveAddons(item._id)} /></IconButton>}
                                                className={(item.isDefault === true  && item.isDisplay === true && item.isEnabled === true) ? classes.chipBody : ( item.isDefault === true && (item.isDisplay === false || item.isEnabled === false)) ?  classes.defaultAndHiddenItem : (item.isDisplay === false || item.isEnabled === false) ? classes.hiddenItemChipBody : classes.dynamicChipBody}
                                            />
                                        </div>
                                    )
                                })}
                            </Box>
                                :
                                <Box className={classes.box} >

                                    {state.showSearchField === false ?
                                        <Fab color='primary' className={classes.boxContainer1} onClick={() => { this.handleShowSearchBar() }}><Typography style={{ fontSize: 'xx-large' }}>+</Typography></Fab >
                                        : <Fab color='default' className={classes.boxContainer1} onClick={() => { this.handleShowSearchBar() }}><Typography style={{ fontSize: 'xx-large' }}>+</Typography></Fab >
                                    }
                                    <Typography style={{ margin: '0px 230px 0px 230px' }}> Add Options To This Group</Typography>

                                </Box>}


                        </Container>
                        <Container className={classes.linkContainer}>
                            <Typography>{selectedAddons.length ? selectedAddons.length : 0} Addons Selected</Typography> <Typography>{this.state.error.addOptions && <div style={{ color: 'red', margin: '0px 0px 0px 5px', fontSize: '12px' }}>{this.state.error.addOptions}</div>}</Typography>
                        </Container>
                        <hr></hr>
                        {
                            state.showSearchField === true ?
                                <Grid className={classes.divB}>

                                    <Grid className={classes.flexHeader}>

                                        <Paper component="form" className={classes.root}>
                                            <InputBase
                                                className={classes.input}
                                                placeholder="Search Addons"
                                                name='addOnName'
                                                value={this.state.addOnName}
                                                inputProps={{ 'aria-label': 'search addons' }}
                                                onChange={this.handleFilterChange}

                                            />

                                            <Button color="primary" onClick={this.handleSearchAddons}> <SearchIcon /></Button>

                                            <Divider className={classes.divider} orientation="vertical" />

                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                                color="default"
                                                onClick={this.handleAddonReset}
                                                style={{ margin: "15px", maxWidth: '100px', backgroundColor: '#f6f6f6' }}>RESET</Button>

                                        </Paper>
                                    </Grid>
                                </Grid>
                                : null
                        }

                        <List>
                            {addonRecords && addonRecords.length
                                ? addonRecords.map((item) => (
                                    <ListItem key={item._id}>
                                        <Avatar>
                                            <img
                                                style={{ borderRadius: '50%', width: '45px', height: '45px' }}
                                                src={item.photos && item.photos[0] && item.photos[0].path ? item.photos[0].path : "https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"}
                                                alt="Service Photo"
                                            />
                                        </Avatar>
                                        <ListItemText
                                            primary={<div style={{ marginLeft: '10px' }}>{item.service}</div>}
                                            secondary={
                                                <div style={{ marginLeft: '10px' }}>
                                                    {item.foodType === 'Veg' && (
                                                        <Avatar className={classes.secondaryRow}>
                                                            <Veg />
                                                        </Avatar>
                                                    )}
                                                    {item.foodType === 'NonVeg' && (
                                                        <Avatar className={classes.secondaryRow}>
                                                            <Nonveg />
                                                        </Avatar>
                                                    )}
                                                    {item.foodType === 'Egg' && (
                                                        <Avatar className={classes.secondaryRow}>
                                                            <Egg />
                                                        </Avatar>
                                                    )}
                                                    {item.foodType}
                                                </div>
                                            }
                                        />
                                        <div>
                                            <ListItemText
                                                primary={`${`₹`} ${item.cost.fixedCost} (${item.cost.quantity ? item.cost.quantity : 'per'} ${item.cost.unit})`}
                                            />
                                            <ListItemText primary={`Addon ID: ${item.serviceId}`} />
                                        </div>
                                        <div>
                                            <Fab
                                                color="primary"
                                                style={{ width: '45px', height: '46px', marginLeft: '20px' }}
                                                onClick={() => {
                                                    this.handleAddonSelection(item);
                                                }}
                                            >
                                                <Typography style={{ fontSize: 'xx-large' }}>+</Typography>
                                            </Fab>
                                        </div>
                                    </ListItem>
                                ))
                                : this.state.noRecordsAvailable === true ? <Typography style={{ margin: "0px 300px" }}>No Results Found </Typography> : null}
                        </List>




                    </Container>

                    <Container style={{ display: "flex", maxWidth: "300px", marginLeft: '400px' }}>
                        <Button
                            fullWidth
                            variant="contained"
                            margin="normal"
                            color="default"
                            onClick={() => { this.onClose() }}
                            style={{ margin: "0px 10px 5px 10px" }}
                        >
                            CANCEL
                        </Button>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            margin="normal"
                            style={{ margin:"0px 10px 5px 10px" }}
                            onClick={this.onSubmit}
                            color="primary">
                            SAVE
                        </Button>

                    </Container>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        addonGroup: state.addonGroup,
        business: state.business,
    };
};
export default connect(mapStateToProps, {

    getAllAddonList, createAddonGroup , updateAddonGroup

})(withStyles(styles)(CreateAddonGroup));