import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from '@material-ui/core/InputLabel';
import { ObjectID } from 'bson';
import {months, weeks, days } from './BusinessAgreementAccountTakeDownData';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = theme => ({
    form: {
        width: '100%',
        height: '100%',
    },
    header: {
        height: '10%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        color:'white',
        width: "100%",
    },
    headerTwo: {
        height: '13%',
        // margin: '0px',
        margin: '15px 10px 0px 10px',
        paddingLeft:'8px',
        paddingRight:'8px',
        // width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: 'red',
        borderRadius:"15px 15px 0px 0px",
        fontSize:"14px",
        // backgroundColor: '#e0e0e0'
        backgroundColor: '#EFEFEF'
    },
    content: {
        height: '75%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '4%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    gridBC: {
        // backgroundColor :'#f5f5f5',
        backgroundColor :'#FAFAFA',
        // paddingLeft: '30px',
        // paddingRight: '30px',
        paddingLeft:'8px',
        paddingRight:'8px',
        margin: '0px 10px 0px 10px',
        borderRadius:"0px 0px 10px 10px",
    }
});


class BusinessAgreementForm extends Component {
    constructor() {
        super();
        this.anchorRef = React.createRef();
        this.bcfRefs = [];
        this.state = {
            merchantName: "",
            legalEntityName: "",
            gstNumber: "",
            fssai: "",
            shopAct: "",
            addressLineOne: "",
            addressLineTwo: "",
            addressCountry: "",
            addressState: "",
            addressCity: "",
            addressPinCode: "",
            businessEmail: "",
            contactPerson: "",
            contactNumber: "",
            contractStartDate: "",
            contractEndDate: "",
            autoRenewalYear: "",
            autoRenewalToggle: true,
            outletEntity: "",
            outletEntitySignUpFee: "",
            outletEntitySignUpFeeError: false,
            outletEntityError: false,
            cancellationFee: "",
            pushNotificationServicesFee: "",
            otherMarketingPackages: "",
            otherMarketingPackagesCost: "",
            otherMarketingPackagesCostError: false,
            otherMarketingPackagesError: false,
            otherMarketingPackagesDescription: "",
            isOnlineBusinessListingOnXirify: false,
            isDeliveryOrders: false,            
            isPickUpOrders: false,
            isServiceAtConsumerLocation: false,
            isServiceAtBusinessLocation: false,
            isSelfDelivery: false,
            isXirifyPartnerDelivery: false,
            isONDCRegistration: false,
            isONDCExclusiveRegistration: false,
            bccPercentage: "",
            effectiveDate: "",
            isNewSignUp: false,
            isExistingMerchantSignUp: false,
            signUpType: "",
            isBccFlat: false,
            isBccSlab: false,
            bccTypeSelection: "",
            slabCount: 0,
            fixedPercentageSelection: "",
            fixedPercentageSelectionError: false,
            bccCharges: null,
            bccChargesError: false,
            slab: [],
            fromSlabCharges: "",
            toSlabCharges: "",
            infinityCheckBox: false,
            newOrExisting: "",
            autoRenewalYearError: false,
            businessConvFees: [],
            months: [],
            weeks: [],
            days: [],
            notifyMerchantMonthSelection: "",
            notifyMerchantWeekSelection: "",
            notifyMerchantDaySelection: "",
            restartMerchantMonthSelection: "",
            restartMerchantWeekSelection: "",
            restartMerchantDaySelection: "",
            btnDisabled: false,
            editFieldsFlag: true,
            editFieldsAutoRenewFlag: true,
            editFieldsOutletFlag: true,
            editFieldsOutletFeeFlag: true,
            editFieldsMarketPackFlag: true,
            editFieldsMarketPackFeeFlag: true,
            editFieldsServiceOptAFlag: true,
            editFieldsServiceOptBFlag: true,
            editFieldsServiceOptCFlag: true,
            editFieldsServiceOptDFlag: true,
            editFieldsServiceOptEFlag: true,
            editFieldsServiceOptFFlag: true,
            editFieldsServiceOptGFlag: true,
            editFieldsServiceOptHFlag: true,
            editFieldsServiceOptIFlag: true,
            editFieldsNotifyATDMFlag: true,
            editFieldsNotifyATDWFlag: true,
            editFieldsNotifyATDDFlag: true,
            editFieldsRestartATDMFlag: true,
            editFieldsRestartATDWFlag: true,
            editFieldsRestartATDDFlag: true,
            fixedPercentageSlabSelection: "",
            slabChargeTypeRequiredError: [],
            slabChargesRequiredError: [],
            slabFromRequiredError: [],
            slabToRequiredError: [],
            slabChargeTypeError: [],
            slabChargesError: [],
            slabFromError: [],
            slabToError: [],
            notifyMerchantTypeSelection: "",
            restartMerchantTypeSelection: "",
        };
    };

    componentDidMount() {
        this.setState({
            isNewSignUp: this.props.createContractFlag && this.props.createContractFlag === true ? true : false,
            isExistingMerchantSignUp: this.props.editContractFlag && this.props.editContractFlag === true ? true : false,
            newOrExisting: this.props.createContractFlag && this.props.createContractFlag === true ? "isNewSignUp" : "isExistingMerchantSignUp"
        });

        if(this.props.editContractFlag) {
            this.setData();
        }
    };

    setData = () => {
        this.setState({
            isOnlineBusinessListingOnXirify: this.props.initialValues.isOnlineBusinessListingOnXirify,
            isDeliveryOrders: this.props.initialValues.isDeliveryOrders,
            isPickUpOrders: this.props.initialValues.isPickUpOrders,
            isServiceAtConsumerLocation: this.props.initialValues.isServiceAtConsumerLocation,
            isServiceAtBusinessLocation: this.props.initialValues.isServiceAtBusinessLocation,
            isSelfDelivery: this.props.initialValues.isSelfDelivery,
            isXirifyPartnerDelivery: this.props.initialValues.isXirifyPartnerDelivery,
            isONDCRegistration: this.props.initialValues.isONDCRegistration,
            isONDCExclusiveRegistration: this.props.initialValues.isONDCExclusiveRegistration,
            autoRenewalToggle: this.props.initialValues.autoRenewalToggle,
            businessConvFees: this.props.initialValues.businessConvFees,
            autoRenewalYear: this.props.initialValues.autoRenewalYear.toString(),
            notifyMerchantTypeSelection: this.props.initialValues.notifyMerchantDaySelection ? "daysnotify" : (this.props.initialValues.notifyMerchantMonthSelection ? "monthsnotify" : (this.props.initialValues.notifyMerchantWeekSelection ? "weeksnotify" : "")),
            restartMerchantTypeSelection: this.props.initialValues.restartMerchantDaySelection ? "daysrestart" : (this.props.initialValues.restartMerchantMonthSelection ? "monthsrestart" : (this.props.initialValues.restartMerchantWeekSelection ? "weeksrestart" : "")),
            notifyMerchantDaySelection: this.props.initialValues.notifyMerchantDaySelection,
            notifyMerchantMonthSelection: this.props.initialValues.notifyMerchantMonthSelection,
            notifyMerchantWeekSelection: this.props.initialValues.notifyMerchantWeekSelection,
            restartMerchantDaySelection: this.props.initialValues.restartMerchantDaySelection,
            restartMerchantMonthSelection: this.props.initialValues.restartMerchantMonthSelection,
            restartMerchantWeekSelection: this.props.initialValues.restartMerchantWeekSelection,
            outletEntity: this.props.initialValues.outletEntity,
            outletEntitySignUpFee: this.props.initialValues.outletEntitySignUpFee,
            otherMarketingPackages: this.props.initialValues.otherMarketingPackages,
            otherMarketingPackagesCost: this.props.initialValues.otherMarketingPackagesCost
        });

        if(this.props.initialValues.businessConvFees && this.props.initialValues.businessConvFees.length>0) {
            let bcf = this.props.initialValues.businessConvFees;
            for(let i = 0; i < bcf.length; i++) {
                let bfcObj = bcf[i];

                if(bfcObj.feeType && bfcObj.feeType === 'flat') {
                    this.setState({bccTypeSelection: 'isBccFlat', isBccFlat: true, isBccSlab: false});

                    if(bfcObj.chargeType && bfcObj.chargeType !== null && bfcObj.chargeType !== undefined && (bfcObj.chargeType === 'fixed' || bfcObj.chargeType === 'percentage')) {
                        this.setState({
                            fixedPercentageSelection: bfcObj.chargeType,
                            bccCharges: bfcObj.value
                        })
                    }
                }
                if(bfcObj.feeType && bfcObj.feeType === 'slab') {
                    this.setState({bccTypeSelection: 'isBccSlab', isBccFlat: false, isBccSlab: true});

                    if(bfcObj.slabs && bfcObj.slabs !== null && bfcObj.slabs !== undefined && bfcObj.slabs.length > 0) {
                        this.setState({
                            slab: bfcObj.slabs
                        })
                    }
                }
            }
        }
    };

    onSubmit = async (formValues) => {
        // console.log("Onsubmit");
        // console.log(formValues);

        if(this.state.autoRenewalYear){
            // console.log("contract end date year is selected");

            if(this.state.outletEntity && !this.state.outletEntitySignUpFee) await this.setState({ outletEntitySignUpFeeError: true });

            if(!this.state.outletEntity && this.state.outletEntitySignUpFee) await this.setState({ outletEntityError: true });

            if(this.state.otherMarketingPackages && !this.state.otherMarketingPackagesCost) await this.setState({ otherMarketingPackagesCostError: true });
            
            if(!this.state.otherMarketingPackages && this.state.otherMarketingPackagesCost) await this.setState({ otherMarketingPackagesError: true });

            if(this.state.bccTypeSelection && !this.state.fixedPercentageSelection && !this.state.bccCharges && this.state.isBccFlat === true) await this.setState({ bccChargesError: true, fixedPercentageSelectionError: true });

            if(this.state.bccTypeSelection && this.state.fixedPercentageSelection && !this.state.bccCharges && this.state.isBccFlat === true) await this.setState({ bccChargesError: true });

            if(this.state.bccTypeSelection && !this.state.fixedPercentageSelection && this.state.bccCharges && this.state.isBccFlat === true) await this.setState({ fixedPercentageSelectionError: true });

            // console.log("state in onSubmit ====> ", this.state);

            if (!this.state.outletEntitySignUpFeeError && !this.state.outletEntityError && !this.state.otherMarketingPackagesCostError && !this.state.otherMarketingPackagesError && !this.state.bccChargesError && !this.state.fixedPercentageSelectionError) {
                let hasError = false;

                if(this.state.bccTypeSelection && this.state.isBccSlab === true) {
                    let slabs = this.state.slab;
                    let bcf = this.state.businessConvFees;
                    let slab = [];
                    let slabChargeTypeRequiredError;
                    let slabChargesRequiredError;
                    let slabFromRequiredError;
                    let slabToRequiredError;
                    let slabChargeTypeError;
                    let slabChargesError;
                    let slabFromError;
                    let slabToError;

                    if(slabs && slabs.length > 0) {
                        for(let i = 0; i < slabs.length; i++) {
                            slab.push(slabs[i]);
                        }
                    }

                    if(bcf && bcf.length > 0) {
                        for(let b = 0; b < bcf.length; b++) {
                            if(bcf[b].slabs && bcf[b].slabs.length > 0) {
                                for(let s = 0; s < bcf[b].slabs.length; s++) {
                                    // for required
                                    if(bcf[b].slabs[s].chargeType.length === 0) { hasError = true; slabChargeTypeRequiredError = this.state.slabChargeTypeRequiredError; slabChargeTypeRequiredError[s] = true; this.setState({ slabChargeTypeRequiredError })}
                                    if(bcf[b].slabs[s].value === null) { hasError = true; slabChargesRequiredError = this.state.slabChargesRequiredError; slabChargesRequiredError[s] = true; this.setState({ slabChargesRequiredError })}
                                    if(bcf[b].slabs[s].from === null) { hasError = true; slabFromRequiredError = this.state.slabFromRequiredError; slabFromRequiredError[s] = true; this.setState({ slabFromRequiredError })}
                                    if(bcf[b].slabs[s].to === null) { hasError = true; slabToRequiredError = this.state.slabToRequiredError; slabToRequiredError[s] = true; this.setState({ slabToRequiredError })}

                                    // for comparing from & to
                                    if(bcf[b].slabs[s].from !== null && bcf[b].slabs[s].to !== null) {
                                        if(bcf[b].slabs[s].to < bcf[b].slabs[s].from) {
                                            hasError = true; slabToError = this.state.slabToError; slabToError[s] = true; this.setState({ slabToError })
                                        }
                                    }

                                    // to check incremental slabs
                                    for (let i = s+1; i < slabs.length; i++) {
                                        if(bcf[b].slabs[s].to > bcf[b].slabs[i].from) {
                                            hasError = true; slabFromError = this.state.slabFromError; slabFromError[i] = true; this.setState({ slabFromError })
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                }

                if(!hasError){
                // console.log("All the flags are false: now can create a new contract");

                formValues.merchantName = formValues.merchantName ? formValues.merchantName : this.state.merchantName;
                formValues.legalEntityName = formValues.legalEntityName ? formValues.legalEntityName : this.state.legalEntityName;
                formValues.gstNumber = formValues.gstNumber ? formValues.gstNumber : this.state.gstNumber;
                formValues.fssai = formValues.fssai ? formValues.fssai : this.state.fssai;  
                formValues.shopAct = formValues.shopAct ? formValues.shopAct : this.state.shopAct;
                formValues.addressLineOne = formValues.addressLineOne ? formValues.addressLineOne : this.state.addressLineOne;
                formValues.addressLineTwo = formValues.addressLineTwo ? formValues.addressLineTwo : this.state.addressLineTwo;
                formValues.addressCountry = formValues.addressCountry ? formValues.addressCountry : this.state.addressCountry;
                formValues.addressState = formValues.addressState ? formValues.addressState : this.state.addressState;
                formValues.addressCity = formValues.addressCity ? formValues.addressCity : this.state.addressCity;
                formValues.addressPinCode = formValues.addressPinCode ? formValues.addressPinCode : this.state.addressPinCode;
                formValues.businessEmail = formValues.businessEmail ? formValues.businessEmail : this.state.businessEmail;
                formValues.contactPerson = formValues.contactPerson ? formValues.contactPerson : this.state.contactPerson;
                formValues.contactNumber = formValues.contactNumber ? formValues.contactNumber : this.state.contactNumber;
                formValues.contractStartDate = formValues.contractStartDate ? formValues.contractStartDate : this.state.contractStartDate;
                // formValues.autoRenewalToggle = formValues.autoRenewalToggle ? formValues.autoRenewalToggle : (this.state.autoRenewalToggle ? this.state.autoRenewalToggle : false);
                formValues.autoRenewalToggle = this.state.autoRenewalToggle;
                formValues.autoRenewalYear = this.state.autoRenewalYear ? this.state.autoRenewalYear : (formValues.autoRenewalYear ? formValues.autoRenewalYear : 0);
                formValues.outletEntity = this.state.outletEntity ? this.state.outletEntity : (formValues.outletEntity ? formValues.outletEntity : "");
                formValues.outletEntitySignUpFee = this.state.outletEntitySignUpFee ? this.state.outletEntitySignUpFee : (formValues.outletEntitySignUpFee ? formValues.outletEntitySignUpFee : "");
                formValues.cancellationFee = formValues.cancellationFee ? formValues.cancellationFee : (formValues.cancellationFee ? formValues.cancellationFee : "");
                formValues.pushNotificationServicesFee = formValues.pushNotificationServicesFee ? formValues.pushNotificationServicesFee : this.state.pushNotificationServicesFee;
                formValues.otherMarketingPackages = this.state.otherMarketingPackages ? this.state.otherMarketingPackages : (formValues.otherMarketingPackages ? formValues.otherMarketingPackages : "");
                formValues.otherMarketingPackagesCost = this.state.otherMarketingPackagesCost ? this.state.otherMarketingPackagesCost : (formValues.otherMarketingPackagesCost ? formValues.otherMarketingPackagesCost : "");
                formValues.otherMarketingPackagesDescription = formValues.otherMarketingPackagesDescription ? formValues.otherMarketingPackagesDescription : this.state.otherMarketingPackagesDescription;
                formValues.isOnlineBusinessListingOnXirify = this.state.isOnlineBusinessListingOnXirify;
                formValues.isDeliveryOrders = this.state.isDeliveryOrders;
                formValues.isPickUpOrders = this.state.isPickUpOrders;
                formValues.isServiceAtConsumerLocation = this.state.isServiceAtConsumerLocation;
                formValues.isServiceAtBusinessLocation = this.state.isServiceAtBusinessLocation;
                formValues.isSelfDelivery = this.state.isSelfDelivery;
                formValues.isXirifyPartnerDelivery = this.state.isXirifyPartnerDelivery;
                formValues.isONDCRegistration = this.state.isONDCRegistration;
                formValues.isONDCExclusiveRegistration = this.state.isONDCExclusiveRegistration;
                formValues.bccPercentage = formValues.bccPercentage ? formValues.bccPercentage : this.state.bccPercentage;
                formValues.effectiveDate = formValues.effectiveDate ? formValues.effectiveDate : this.state.effectiveDate;

                // commented below LOC
                // formValues.notifyMerchantMonthSelection = this.state.notifyMerchantMonthSelection ? this.state.notifyMerchantMonthSelection : (formValues.notifyMerchantMonthSelection ? formValues.notifyMerchantMonthSelection : "");
                // formValues.notifyMerchantWeekSelection = this.state.notifyMerchantWeekSelection ? this.state.notifyMerchantWeekSelection : (formValues.notifyMerchantWeekSelection ? formValues.notifyMerchantWeekSelection : "");
                // formValues.notifyMerchantDaySelection = this.state.notifyMerchantDaySelection ? this.state.notifyMerchantDaySelection : (formValues.notifyMerchantDaySelection ? formValues.notifyMerchantDaySelection : "");
                // formValues.restartMerchantMonthSelection = this.state.restartMerchantMonthSelection ? this.state.restartMerchantMonthSelection : (formValues.restartMerchantMonthSelection ? formValues.restartMerchantMonthSelection : "");
                // formValues.restartMerchantWeekSelection = this.state.restartMerchantWeekSelection ? this.state.restartMerchantWeekSelection : (formValues.restartMerchantWeekSelection ? formValues.restartMerchantWeekSelection : "");
                // formValues.restartMerchantDaySelection = this.state.restartMerchantDaySelection ? this.state.restartMerchantDaySelection : (formValues.restartMerchantDaySelection ? formValues.restartMerchantDaySelection : "");
                // commented above LOC

                formValues.notifyMerchantMonthSelection = this.state.notifyMerchantMonthSelection;
                formValues.notifyMerchantWeekSelection = this.state.notifyMerchantWeekSelection;
                formValues.notifyMerchantDaySelection = this.state.notifyMerchantDaySelection;
                formValues.restartMerchantMonthSelection = this.state.restartMerchantMonthSelection;
                formValues.restartMerchantWeekSelection = this.state.restartMerchantWeekSelection;
                formValues.restartMerchantDaySelection = this.state.restartMerchantDaySelection;
                formValues.businessConvFees = this.state.businessConvFees;

                // console.log("FormValues before calling the submit ====> ", formValues);

                if(this.props.editContractFlag && this.props.editContractFlag === true) {
                    // console.log("Edit Form");
                    formValues.editContractFlag = this.props.editContractFlag && this.props.editContractFlag === true ? true : false;
                } else {
                    // console.log("Create Form");
                    formValues.createContractFlag = this.props.createContractFlag && this.props.createContractFlag === true ? true : false;
                }

                // console.log("Final form values ====> ", formValues);
                this.setState({ btnDisabled: true });
                this.props.onSubmit(formValues);
              }
            } else {
                console.log("All the flags are not false");
            }
        } else {
            // console.log("contract end date not selected");
            if(!this.state.autoRenewalYear) {
                this.setState({ autoRenewalYearError: true});
            }
        }
    };
    
    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    };

    handleContractAutoRenewalYearChange = async (event) => {
        this.setState({ autoRenewalYear: event.target.value, autoRenewalYearError: false, editFieldsFlag: false });
    };

    handleOutletEntityChange = async (event) => {
        this.setState({ outletEntity: event.target.value, outletEntityError: false, editFieldsOutletFlag: false });
    };

    handleOtherMarketingPackagesChange = async (event) => {
        this.setState({ otherMarketingPackages: event.target.value, otherMarketingPackagesError: false, editFieldsMarketPackFlag: false });
    };

    handleSwitchChange = async (e) => {
        // console.log(e.target.name);
        // console.log(e.target.checked);

        if(e.target.name === 'autoRenewalToggle') this.setState({ autoRenewalToggle: e.target.checked, editFieldsAutoRenewFlag: false });
        if(e.target.name === 'isOnlineBusinessListingOnXirify') this.setState({ isOnlineBusinessListingOnXirify: e.target.checked, editFieldsServiceOptAFlag: false });
        if(e.target.name === 'isDeliveryOrders') this.setState({ isDeliveryOrders: e.target.checked, editFieldsServiceOptBFlag: false });
        if(e.target.name === 'isPickUpOrders') this.setState({ isPickUpOrders: e.target.checked, editFieldsServiceOptCFlag: false });
        if(e.target.name === 'isServiceAtConsumerLocation') this.setState({ isServiceAtConsumerLocation: e.target.checked, editFieldsServiceOptDFlag: false });
        if(e.target.name === 'isServiceAtBusinessLocation') this.setState({ isServiceAtBusinessLocation: e.target.checked, editFieldsServiceOptEFlag: false });
        if(e.target.name === 'isSelfDelivery') this.setState({ isSelfDelivery: e.target.checked, editFieldsServiceOptFFlag: false });
        if(e.target.name === 'isXirifyPartnerDelivery') this.setState({ isXirifyPartnerDelivery: e.target.checked, editFieldsServiceOptGFlag: false });
        if(e.target.name === 'isONDCRegistration') this.setState({ isONDCRegistration: e.target.checked, editFieldsServiceOptHFlag: false });
        if(e.target.name === 'isONDCExclusiveRegistration') this.setState({ isONDCExclusiveRegistration: e.target.checked, editFieldsServiceOptIFlag: false });
    };

    handleChange = (event) => {
        if (event.target.name === "outletEntitySignUpFee") { this.setState({ [event.target.name]: event.target.value, outletEntitySignUpFeeError: false, editFieldsOutletFeeFlag: false }); }
        if (event.target.name === "otherMarketingPackagesCost") { this.setState({ [event.target.name]: event.target.value, otherMarketingPackagesCostError: false, editFieldsMarketPackFeeFlag: false }); }
        if (event.target.name === "notifyMerchantMonthSelection") { this.setState({ [event.target.name]: event.target.value, editFieldsNotifyATDMFlag: false, notifyMerchantWeekSelection: "", notifyMerchantDaySelection: "" }); }
        if (event.target.name === "notifyMerchantWeekSelection") { this.setState({ [event.target.name]: event.target.value, editFieldsNotifyATDWFlag: false, notifyMerchantDaySelection: "", notifyMerchantMonthSelection: "" }); }
        if (event.target.name === "notifyMerchantDaySelection") { this.setState({ [event.target.name]: event.target.value, editFieldsNotifyATDDFlag: false, notifyMerchantMonthSelection: "", notifyMerchantWeekSelection: "" }); }
        if (event.target.name === "restartMerchantMonthSelection") { this.setState({ [event.target.name]: event.target.value, editFieldsRestartATDMFlag: false, restartMerchantWeekSelection: "", restartMerchantDaySelection: "" }); }
        if (event.target.name === "restartMerchantWeekSelection") { this.setState({ [event.target.name]: event.target.value, editFieldsRestartATDWFlag: false, restartMerchantDaySelection: "", restartMerchantMonthSelection: "" }); }
        if (event.target.name === "restartMerchantDaySelection") { this.setState({ [event.target.name]: event.target.value, editFieldsRestartATDDFlag: false, restartMerchantMonthSelection: "", restartMerchantWeekSelection: "" }); }
        if (event.target.name === "notifyMerchantTypeSelection") { this.setState({ [event.target.name]: event.target.value }); }
        if (event.target.name === "restartMerchantTypeSelection") { this.setState({ [event.target.name]: event.target.value }); }
    };

    handleBccFixedHandleChane = (event) => {
        if (event.target.name === "fixedPercentageSelection") {

            this.setState({ [event.target.name]: event.target.value, fixedPercentageSelectionError: false });

            // console.log("fixedPercentageSelection");

            let exist = 0;
            let slab = this.state.slab;
            let businessConvFees = this.state.businessConvFees;
            // console.log("businessConvFees ====> ", businessConvFees);
            // console.log("slab ====> ", slab);
            // console.log("event.target.name ===> ", event.target.name);
            // console.log("event.target.value ===> ", event.target.value);
            // console.log("event.target.name._id ===> ", event.target.name._id);

            if (!exist) {
                for (let i = 0; i < businessConvFees.length; i++) {
                    businessConvFees[i].chargeType = event.target.value;
                    businessConvFees[i].feeType = 'flat';

                    this.setState({ businessConvFees });
                }
            }
        }
        if (event.target.name === "bccCharges") { 
            this.setState({ [event.target.name]: event.target.value, bccChargesError: false }); 

            // console.log("bccCharges");

            let exist = 0;
            let slab = this.state.slab;
            let businessConvFees = this.state.businessConvFees;
            // console.log("businessConvFees ====> ", businessConvFees);
            // console.log("slab ====> ", slab);
            // console.log("event.target.name ===> ", event.target.name);
            // console.log("event.target.value ===> ", event.target.value);
            // console.log("event.target.name._id ===> ", event.target.name._id);

            if (!exist) {
                for (let i = 0; i < businessConvFees.length; i++) {
                    businessConvFees[i].value = parseFloat(event.target.value);      
                    this.setState({ businessConvFees });
                }
            }
        }
    };

    handleBccSelectionChange = (e) => {
        // console.log(e.target.name);
        // console.log(e.target.checked);
        // console.log(e.target.value);

        this.setState({ bccTypeSelection: e.target.value });
        let slabCount = this.state.slabCount + 1;

        if(e.target.value === 'isBccFlat') {
            // this.setState({ isBccFlat: true, isBccSlab: false, slab: [], businessConvFees: [], slabCount: 0});
            this.addFlatBcc();
        }
        if(e.target.value === 'isBccSlab') {
            // this.setState({ isBccSlab: true, isBccFlat: false, bccCharges: null, fixedPercentageSelection: "", slabCount: slabCount, slab: [], businessConvFees: [] });
            this.setState({ slabCount: slabCount });
            if(slabCount===1)
                this.addSlabs();
        }
    };

    addFlatBcc = async () => {
        // console.log("===in addFlatBcc ===");

        await this.setState({ isBccFlat: true, isBccSlab: false, slab: [], businessConvFees: [], slabCount: 0});

        let slab = this.state.slab;

        let businessConvFees = this.state.businessConvFees;
        const id = new ObjectID().toHexString();
        let bConvFees = {
            _id: id,
            feeType: "",
            isActive: true,
            chargeType: "",
            value: null,
            slabs: slab
        }
        businessConvFees.push(bConvFees);
        // console.log("bcc at first push ===>",businessConvFees);

        this.setState({ slab, businessConvFees });
    };

    handleSlabChange = (event) => {
        // console.log("handleSlabChange")
        let exist = 0;
        let slab = this.state.slab;
        let businessConvFees = this.state.businessConvFees;
        // console.log("businessConvFees ====> ", businessConvFees);
        // console.log("slab ====> ", slab);
        // console.log("event.target.name ===> ", event.target.name);
        // console.log("event.target.value ===> ", event.target.value);
        // console.log("event.target.name._id ===> ", event.target.name._id);

        if (!exist) {
          for (let i = 0; i < businessConvFees.length; i++) {
                for (let j=0; j<businessConvFees[i].slabs.length; j++) {
                    // console.log("slab data ===>", businessConvFees[i].slabs[j]);
                    if (businessConvFees[i].slabs[j]._id === event.target.name._id) {
                        // console.log("id match");
                        // console.log("charge type ====> ",businessConvFees[i].slabs[j].chargeType);
                        businessConvFees[i].slabs[j].chargeType = event.target.value;
                        businessConvFees[i].isActive = true
                        businessConvFees[i].feeType = 'slab'

                        // console.log("bcc before storing in state ====>> ", businessConvFees[i]);

                        this.setState({ businessConvFees });
                    }
                }
          }
        }
    };

    handleChangeBccSlabChargeTypeValue = (event) => {
        // console.log("=== inside handleChangeBccSlabChargeTypeValue ====");
        let exist = 0;
        let slab = this.state.slab;
        let businessConvFees = this.state.businessConvFees;
        // console.log("businessConvFees ====> ", businessConvFees);
        // console.log("slab ====> ", slab);
        // console.log("event.target.name ===> ", event.target.name);
        // console.log("event.target.value ===> ", event.target.value);
        if (!exist) {
          for (let i = 0; i < businessConvFees.length; i++) {
            for (let j=0; j < businessConvFees[i].slabs.length; j++) {
                // console.log("slab data ===>", businessConvFees[i].slabs[j]);
                if (businessConvFees[i].slabs[j]._id === event.target.name) {
                    // console.log("id match");
                    // console.log("value ====> ",businessConvFees[i].slabs[j].value);
                    businessConvFees[i].slabs[j].value = parseFloat(event.target.value);

                    let slabChargesRequiredError = this.state.slabChargesRequiredError;
                    if(event.target.value.length > 0) { slabChargesRequiredError[j] = false }
                    else { slabChargesRequiredError[j] = true }

                    this.setState({ businessConvFees, slabChargesRequiredError });
                } else {
                    console.log("not match");
                }
            }
          }
        }
    };

    handleChangeBccSlabChargeTypeFrom = (event) => {
        // console.log("==== inside handleChangeBccSlabChargeTypeFrom ====");
        let exist = 0;
        let slab = this.state.slab;
        let businessConvFees = this.state.businessConvFees;
        // console.log("businessConvFees ====> ", businessConvFees);
        // console.log("slab ====> ", slab);
        // console.log("event.target.name ===> ", event.target.name);
        // console.log("event.target.value ===> ", event.target.value);
        // console.log("event.target.name._id ===> ", event.target.name._id);
        if (!exist) {
          for (let i = 0; i < businessConvFees.length; i++) {
            for (let j=0; j < businessConvFees[i].slabs.length; j++) {
                // console.log("slab data ===>", businessConvFees[i].slabs[j]);
                if (businessConvFees[i].slabs[j]._id === event.target.name) {
                    // console.log("id match");
                    // console.log("from ====> ",businessConvFees[i].slabs[j].from);
                    businessConvFees[i].slabs[j].from = parseFloat(event.target.value);

                    let slabFromRequiredError = this.state.slabFromRequiredError;
                    let slabFromError = this.state.slabFromError;
                    let slabToError = this.state.slabToError;

                    if(event.target.value.length > 0) { slabFromRequiredError[j] = false; slabFromError[j] = false; slabToError[j] = false }
                    else { slabFromRequiredError[j] = true; }

                    this.setState({ businessConvFees, slabFromRequiredError, slabFromError, slabToError });
                }
            }
          }
        }
    };

    handleChangeBccSlabChargeTypeTo = (event) => {
        // console.log("==== inside handleChangeBccSlabChargeTypeTo ====");
        let exist = 0;
        let slab = this.state.slab;
        let businessConvFees = this.state.businessConvFees;
        // console.log("businessConvFees ====> ", businessConvFees);
        // console.log("slab ====> ", slab);
        // console.log("event.target.name ===> ", event.target.name);
        // console.log("event.target.value ===> ", event.target.value);
        // console.log("event.target.name._id ===> ", event.target.name._id);
        if (!exist) {
          for (let i = 0; i < businessConvFees.length; i++) {
            for (let j=0; j < businessConvFees[i].slabs.length; j++) {
                // console.log("slab data ===>", businessConvFees[i].slabs[j]);
                if (businessConvFees[i].slabs[j]._id === event.target.name) {
                    // console.log("id match");
                    // console.log("to ====> ",businessConvFees[i].slabs[j].to);
                    businessConvFees[i].slabs[j].to = parseFloat(event.target.value);

                    let slabToRequiredError = this.state.slabToRequiredError;
                    let slabToError = this.state.slabToError;
                    let slabFromError = this.state.slabFromError;

                    if(event.target.value.length > 0) { slabToRequiredError[j] = false; slabToError[j] = false; slabFromError[j] = false;  }
                    else { slabToRequiredError[j] = true; }

                    this.setState({ businessConvFees, slabToRequiredError, slabToError, slabFromError });
                }
            }
          }
        }
    };

    handleChangeBccSlabChargeType = (event) => {
        // console.log("==== inside handleChangeBccSlabChargeType =====");
        let exist = 0;
        let slab = this.state.slab;
        let businessConvFees = this.state.businessConvFees;
        // console.log("businessConvFees ====> ", businessConvFees);
        // console.log("slab ====> ", slab);
        // console.log("event.target.name ===> ", event.target.name);
        // console.log("event.target.value ===> ", event.target.value);
        // console.log("event.target.name._id ===> ", event.target.name._id);
        if (!exist) {
          for (var i = 0; i < businessConvFees.length; i++) {
            for (let j=0; j < businessConvFees[i].slabs.length; j++) {
                // console.log("slab data ===>", businessConvFees[i].slabs[j]);
                if (businessConvFees[i].slabs[j]._id === event.target.name._id) {
                    // console.log("id match");
                    // console.log("to ====> ",businessConvFees[i].slabs[j].chargeType);
                    businessConvFees[i].slabs[j].chargeType = event.target.value;

                    let slabChargeTypeRequiredError = this.state.slabChargeTypeRequiredError;
                    if(event.target.value.length > 0) { slabChargeTypeRequiredError[j] = false }
                    else { slabChargeTypeRequiredError[j] = true }
                    
                    this.setState({ businessConvFees, slabChargeTypeRequiredError });
                }
            }
            businessConvFees[i].isActive = true;
            businessConvFees[i].feeType = 'slab';
            // console.log("businessConvFees again ====> ", businessConvFees[i]);
          }
        }
    };

    // handleInfinity = (event, value, slabIndex, rowsToDeleteCount = 1) => {
    handleInfinity = (event, value) => {
        // console.log("infinity");
        // console.log(value);
        let exist = 0;
        let slab = this.state.slab;
        let businessConvFees = this.state.businessConvFees;
        // console.log("businessConvFees ====> ", businessConvFees);
        // console.log("slab ====> ", slab);
        // console.log("event.target.name ===> ", event.target.name);
        // console.log("event.target.value ===> ", event.target.value);
        // console.log("event.target.name._id ===> ", event.target.name._id); //Number.MAX_SAFE_INTEGER
        // console.log("event.target.checked ===> ", event.target.checked);
        // console.log("slabIndex ===> ", slabIndex);
        // slabIndex += 1;
        // console.log("slabIndex ===> ", slabIndex);
        // console.log("rowsToDeleteCount ===> ", rowsToDeleteCount);

        if (!exist) {
            for (var i = 0; i < businessConvFees.length; i++) {
              for (let j=0; j < businessConvFees[i].slabs.length; j++) {
                //   console.log("slab data ===>", businessConvFees[i].slabs[j]);
                  if (businessConvFees[i].slabs[j]._id === event.target.name) {
                    //   console.log("id match");
                    //   console.log("to ====> ",businessConvFees[i].slabs[j].chargeType);

                      if(event.target.checked === true) {
                        businessConvFees[i].slabs[j].to = Number.MAX_SAFE_INTEGER;
                        businessConvFees[i].slabs[j].infi = true;
                      } else {
                        businessConvFees[i].slabs[j].to = null;
                        businessConvFees[i].slabs[j].infi = false;
                      }
                  }
              }
              this.setState({ businessConvFees });
            }
        }

        // consideer this later - can uncomment it later with more changes - add it in another function

        // for (var i = 0; i < businessConvFees.length; i++) {
        //     if(slab && slab.length > 1) {
        //         let bcf = this.state.businessConvFees;
        //         bcf[i].slabs.splice(slabIndex, rowsToDeleteCount);
        //     }
        //     this.setState({ businessConvFees });
        //   }
       
    };

    addSlabs = async () => {
        // console.log("===in addSlabs ===");

        await this.setState({ isBccSlab: true, isBccFlat: false, bccCharges: null, fixedPercentageSelection: "", slab: [], businessConvFees: [] });

        let slab = this.state.slab;

        let businessConvFees = this.state.businessConvFees;
        const id = new ObjectID().toHexString();
        const idSlab = new ObjectID().toHexString();
        let bConvFeesSlab = {
          from: null,
          to: null,
          chargeType: "",
          value: null,
          _id: idSlab,
          infi: false
        }
        slab.push(bConvFeesSlab);
        // console.log("slab at first push ===>",slab);

        let bConvFees = {
            _id: id,
            feeType: "",
            isActive: true,
            chargeType: "",
            value: null,
            slabs: slab
        }
        businessConvFees.push(bConvFees);
        // console.log("bcc at first push ===>",businessConvFees);

        this.setState({ slab, businessConvFees });
    };

    addMoreSlabs = () => {
        // console.log("===in addMoreSlabs ===");
        let slab = this.state.slab;

        let businessConvFees = this.state.businessConvFees;
        const idSlab = new ObjectID().toHexString();
        let bConvFeesSlab = {
          from: null,
          to: null,
          chargeType: "",
          value: null,
          _id: idSlab,
          infi: false
        }
        slab.push(bConvFeesSlab);
        // console.log("slab at first push ===>",slab);

        if(businessConvFees && businessConvFees.length > 0) {
            let bcfLength = businessConvFees.length;
            businessConvFees[bcfLength - 1].slabs = slab;
        }
        this.setState({ slab, businessConvFees });
    };

    deleteSlab = (value) => {
        // console.log("deleteSlab");
        // console.log(value);
        let slab = this.state.slab;
        let businessConvFees = this.state.businessConvFees;
        let slabChargeTypeRequiredError = this.state.slabChargeTypeRequiredError;
        let slabChargesRequiredError = this.state.slabChargesRequiredError;
        let slabFromRequiredError = this.state.slabFromRequiredError;
        let slabToRequiredError = this.state.slabToRequiredError;
        let slabFromError = this.state.slabFromError;
        let slabToError = this.state.slabToError;

        let index = slab.indexOf(value);
        // console.log("index: " + index);

        slab = slab.filter((unlink) => unlink._id !== value._id);

        if(businessConvFees && businessConvFees.length > 0) {
            let bcfLength = businessConvFees.length;
            businessConvFees[bcfLength - 1].slabs = slab;
        }

        delete slabChargeTypeRequiredError[index];
        delete slabChargesRequiredError[index];
        delete slabFromRequiredError[index];
        delete slabToRequiredError[index];
        delete slabFromError[index];
        delete slabToError[index];

        this.setState({ slab, businessConvFees, slabChargeTypeRequiredError, slabChargesRequiredError, slabFromRequiredError, slabToRequiredError, slabFromError, slabToError});
    };

    render() {
        // console.log("Props ----> ", this.props);
        // console.log("State ----> ", this.state);
        // console.log("month ----> ", months);
        // console.log("weeks ----> ", weeks);
        // console.log("days ----> ", days);

        const { classes } = this.props;

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <Grid container className={classes.header} spacing={3}>
                    <Grid item xs={8}>
                        <Typography variant="h6" gutterBottom>
                           {this.props.editContractFlag && this.props.editContractFlag === true ? 'Edit Contract' : 'Create Contract'} 
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
                    </Grid>
                </Grid>
                
                
                <Container className={classes.content} justify="center">
                    <Grid container className={classes.grid} spacing={1}  alignItems="center">

                        <Grid item xs={12} sm={12}></Grid><Grid item xs={12} sm={12}></Grid>
                        <Grid item xs={12} sm={12}></Grid><Grid item xs={12} sm={12}></Grid>

                        {/* New Sign up / Existing Merchant partner */}

                        {
                            (this.props.businessTag && this.props.businessTag === 'pending') || ((this.props.editContractFlag && this.props.editContractFlag === true) || (this.props.createContractFlag && this.props.createContractFlag === true)) ? null 
                            :                        
                        <Grid container spacing={1} className={classes.headerTwo} >
                            <Grid item xs={12}><Typography gutterBottom>New / Existing Business</Typography></Grid>
                        </Grid>}
                        {
                            (this.props.businessTag && this.props.businessTag === 'pending') || ((this.props.editContractFlag && this.props.editContractFlag === true) || (this.props.createContractFlag && this.props.createContractFlag === true)) ? null 
                            :
                        <Grid container spacing={1} className={classes.gridBC}>                        
                        <Grid item xs={12} sm={6}>
                            <RadioGroup row aria-label="position" name="isNewSignUp" value={this.state.newOrExisting} >
                                <FormControlLabel value="isNewSignUp" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>New Sign Up</Typography>} size="small" disabled={this.state.isExistingMerchantSignUp === true ? true : false} />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RadioGroup row aria-label="position" name="isExistingMerchantSignUp" value={this.state.newOrExisting} >
                                <FormControlLabel value="isExistingMerchantSignUp" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Existing Merchant Partner</Typography>} size="small" disabled={this.state.isNewSignUp === true ? true : false} />
                            </RadioGroup>
                        </Grid>
                        </Grid>}


                        <Grid item xs={12} sm={12}></Grid><Grid item xs={12} sm={12}></Grid>
                        <Grid item xs={12} sm={12}></Grid><Grid item xs={12} sm={12}></Grid>

                        {/* Business Details */}
                        <Grid container spacing={1} className={classes.headerTwo} >
                            <Grid item xs={12}><Typography gutterBottom>Business Details</Typography></Grid>
                        </Grid>
                        <Grid container spacing={1} className={classes.gridBC}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12} sm={6}><Typography variant="subtitle2" gutterBottom> Merchant Name * </Typography></Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2" gutterBottom> Legal Entity Name * </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field fullWidth margin="dense" name="merchantName" label="Merchant Name *" variant="outlined" component={this.renderTextField} disabled={this.props.editContractFlag?true:false} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field fullWidth margin="dense" name="legalEntityName" label="Legal Entity Name *" variant="outlined" component={this.renderTextField} />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2" gutterBottom> GST {/* length of gst number is 15 - alphanumeric */} </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}><Typography variant="subtitle2" gutterBottom> FSSAI </Typography></Grid>

                        <Grid item xs={12} sm={6}>
                            <Field fullWidth margin="dense" name="gstNumber" label="GST" variant="outlined" component={this.renderTextField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field fullWidth margin="dense" name="fssai" label="FSSAI" variant="outlined" component={this.renderTextField}/>
                        </Grid>


                        <Grid item xs={12} sm={12}> <Typography variant="subtitle2" gutterBottom>Shop Act</Typography> </Grid>

                        <Grid item xs={12} sm={12}>
                            <Field fullWidth margin="dense" name="shopAct" label="Shop Act" variant="outlined" component={this.renderTextField}/>
                        </Grid>


                        <Grid item xs={12} sm={12}> <Typography variant="subtitle2" gutterBottom> Address *</Typography> </Grid>

                        <Grid item xs={12} sm={12}>
                            <Field fullWidth margin="dense" name="addressLineOne" label="Line 1" variant="outlined" component={this.renderTextField}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Field fullWidth margin="dense" name="addressLineTwo" label="Line 2" variant="outlined" component={this.renderTextField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field fullWidth margin="dense" name="addressCountry" label="Country" variant="outlined" component={this.renderTextField} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field fullWidth margin="dense" name="addressState" label="State" variant="outlined" component={this.renderTextField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field fullWidth margin="dense" name="addressCity" label="City" variant="outlined" component={this.renderTextField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field fullWidth margin="dense" name="addressPinCode" label="Pincode" variant="outlined" component={this.renderTextField} />
                        </Grid>


                        <Grid item xs={12} sm={12}> <Typography variant="subtitle2" gutterBottom> Email * </Typography></Grid>

                        <Grid item xs={12} sm={12}>
                            <Field fullWidth margin="dense" name="businessEmail" label="Email" variant="outlined" component={this.renderTextField}/>
                        </Grid>


                        <Grid item xs={12} sm={6}><Typography variant="subtitle2" gutterBottom>Contact Person * </Typography></Grid>
                        <Grid item xs={12} sm={6}><Typography variant="subtitle2" gutterBottom>Contact No *</Typography></Grid>

                        <Grid item xs={12} sm={6}>
                            <Field fullWidth margin="dense" name="contactPerson" label="Contact Person *" variant="outlined" component={this.renderTextField} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field fullWidth margin="dense" name="contactNumber" label="Contact No *" variant="outlined" component={this.renderTextField}/>
                        </Grid>

                        
                        <Grid item xs={12} sm={6}><Typography variant="subtitle2" gutterBottom>Contract Start Date *</Typography></Grid>
                        {/* <Grid item xs={12} sm={6}><Typography variant="subtitle2" gutterBottom>Contract Auto Renewal Date *</Typography></Grid> */}
                        <Grid item xs={12} sm={6}><Typography variant="subtitle2" gutterBottom></Typography></Grid>

                        <Grid item xs={12} sm={6}>
                            <Field
								fullWidth
								margin="dense"
								name="contractStartDate"
								type="date"
								variant="outlined"
								InputLabelProps={{shrink: true}}
								component={this.renderTextField}  
                                disabled={this.props.editContractFlag?true:false}
                                label="Contract Start Date *"
							/>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <FormControl fullWidth style={{marginTop:"8px"}} >
                                <Select
                                    labelId="ContractAutoRenewalYear-label"
                                    id="demo-simple-select"
                                    // value={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.autoRenewalYear : this.state.autoRenewalYear}
                                    value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsFlag && this.state.editFieldsFlag === true  ? this.props.initialValues.autoRenewalYear : this.state.autoRenewalYear}
                                    onChange={this.handleContractAutoRenewalYearChange}
                                    // component={this.renderTextField}
                                    name="autoRenewalYear" 
                                    variant="outlined"
                                    margin="dense"
                                    error={this.state.autoRenewalYearError ? true : false}
                                >
                                    <MenuItem value={'1'}>1</MenuItem>
                                    <MenuItem value={'2'}>2</MenuItem>
                                    <MenuItem value={'3'}>3</MenuItem>
                                </Select>
                                {(this.state.autoRenewalYearError)? (
                                    <FormHelperText style={{ color: "red" }}> Required </FormHelperText>
                                ) :  null}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}><Typography variant="subtitle2" gutterBottom style={{marginTop:"14px"}}>Year</Typography></Grid>
                        <Grid item xs={12} sm={2}  style={{ alignItems:"right", marginTop:"14px", float: "right" }}>
                            <Typography variant="subtitle2" gutterBottom >Enable Auto Renew</Typography>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        key={`contractAutoRenewSwitch_ 1`}
                                        // checked={ this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.autoRenewalToggle : (this.state.autoRenewalToggle == true ? true : false)}
                                        checked={ this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsAutoRenewFlag ? this.props.initialValues.autoRenewalToggle : (this.state.autoRenewalToggle == true ? true : false)}
                                        onChange={this.handleSwitchChange}
                                        color="primary"
                                        name='autoRenewalToggle'
                                    />
                                }
                                style={{ alignItems:"right", marginTop:"14px", float: "right" }}
                            />
                        </Grid>
                        </Grid>


                        <Grid item xs={12} sm={12}></Grid><Grid item xs={12} sm={12}></Grid>
                        <Grid item xs={12} sm={12}></Grid><Grid item xs={12} sm={12}></Grid>

                        {/* Payment Schedule Section */}
                        <Grid container spacing={1} className={classes.headerTwo} >
                            <Grid item xs={12}><Typography gutterBottom>Payment Schedule</Typography></Grid>
                        </Grid>

                        <Grid container spacing={1} className={classes.gridBC}>
                        <Grid item xs={12} sm={3}> <Typography variant="subtitle2" gutterBottom> One Time Sign-Up Fee </Typography></Grid>
                        <Grid item xs={12} sm={9}> <Typography variant="string" style={{left:-50, position:"relative"}}> <i>(Please tick any one for  New Sign-Up)</i></Typography></Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth style={{marginTop:"8px"}} >
                                <Select
                                    labelId="OneTimeSignUpFeeEntity-label"
                                    id="demo-simple-select"
                                    // value={this.state.outletEntity}
                                    // value={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.outletEntity : this.state.outletEntity}
                                    value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsOutletFlag && this.state.editFieldsOutletFlag === true ? this.props.initialValues.outletEntity : this.state.outletEntity}
                                    onChange={this.handleOutletEntityChange}
                                    name="outletEntity" 
                                    variant="outlined"
                                    margin="dense"
                                    error={this.state.outletEntityError ? true : false}
                                >
                                    <MenuItem value={'single'}>Single Outlet Entity</MenuItem>
                                    <MenuItem value={'multiple'}>Multi Outlet Entity</MenuItem>
                                </Select>
                                {(this.state.outletEntityError)? (
                                <FormHelperText style={{ color: "red" }}> Required </FormHelperText>
                            ) :  null}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                fullWidth 
                                margin="dense" 
                                name="outletEntitySignUpFee" 
                                label="Rupees" 
                                variant="outlined" 
                                // value={this.state.outletEntitySignUpFee}
                                // value={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.outletEntitySignUpFee : this.state.outletEntitySignUpFee}
                                value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsOutletFeeFlag && this.state.editFieldsOutletFeeFlag === true ? this.props.initialValues.outletEntitySignUpFee : this.state.outletEntitySignUpFee}
                                onChange={this.handleChange}
                                error={this.state.outletEntitySignUpFeeError ? true : false}
                                type='number'
                            />
                            {(this.state.outletEntitySignUpFeeError)? (
                                <FormHelperText style={{ color: "red" }}> Required </FormHelperText>
                            ) :  null}
                        </Grid>

                        <Grid item xs={12}>
							<Divider color="textSecondary" style={{ marginBottom: "5px", marginTop: "10px" }} display="block" />
						</Grid>


                        <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                Business Convenience Charges (BCC) on Transaction on Invoice Value of each order which includes Item cost, Delivery Charges ( Self + Partner) and Xirify Sponsored Coupons if applicable
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <RadioGroup row aria-label="position" name="bccTypeSelection" 
                            onChange={this.handleBccSelectionChange} 
                            value={this.state.bccTypeSelection}>
                            <FormControlLabel value="isBccFlat" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Flat</Typography>} size="small" />
                            <FormControlLabel value="isBccSlab" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Slab</Typography>} size="small" />
                            </RadioGroup>
                        </Grid>

                        {
                            this.state.bccTypeSelection && this.state.bccTypeSelection === 'isBccSlab' ? (
                                this.state.businessConvFees && this.state.businessConvFees.length > 0 ? (
                                    this.state.businessConvFees.map((slabsArr) => (
                                        slabsArr.slabs && slabsArr.slabs.length > 0 ? (
                                            slabsArr.slabs.map((slab, indexNo) => {
                                                return (
                                                    <Grid container spacing={2} direction="row" key={"slabObj_" + indexNo} justify="center" alignItems="center">
                                                        <Grid item xs={12}></Grid>
                                                        <Grid item xs={6}>
                                                            <FormControl fullWidth >
                                                                <Select
                                                                    labelId="fixedPercentageSlabSelection-label"
                                                                    id="chargetype"
                                                                    value={slab.chargeType}
                                                                    onChange={this.handleChangeBccSlabChargeType}
                                                                    name={slab}
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    error={this.state.slabChargeTypeRequiredError[indexNo] ? true : false}
                                                                    style={{marginLeft:"10px"}}
                                                                >
                                                                    <MenuItem value={'percentage'}>Percentage</MenuItem>
                                                                    <MenuItem value={'fixed'}>Fixed</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <TextField 
                                                                fullWidth 
                                                                margin="dense" 
                                                                id="bccslabcharges" 
                                                                label={slab.chargeType === 'percentage' ? "Percentage Value" : (slab.chargeType === 'fixed' ? "Fixed Value" : "Rupees" )}
                                                                variant="outlined" 
                                                                value={slab.value}
                                                                name={slab._id}
                                                                onChange={this.handleChangeBccSlabChargeTypeValue}
                                                                error={this.state.slabChargesRequiredError[indexNo] ? true : false}
                                                                type='number'
                                                            />
                                                        </Grid>

                                                        {/* For error messages */}
                                                        <Grid item xs={6}>
                                                            {(this.state.slabChargeTypeRequiredError[indexNo])? (
                                                                    <FormHelperText style={{ color: "red",marginLeft:"10px" }}>
                                                                        Required
                                                                    </FormHelperText>
                                                                ) :  null}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {(this.state.slabChargesRequiredError[indexNo])? (
                                                                <FormHelperText style={{ color: "red" }}> Required </FormHelperText>
                                                            ) :  null}
                                                        </Grid>

                                                        <Grid item xs={12}></Grid>

                                                        <Grid item xs={3}>
                                                            <TextField 
                                                                fullWidth 
                                                                margin="dense" 
                                                                id="bccslabfrom" 
                                                                label="From Rupees"
                                                                variant="outlined" 
                                                                value={slab.from}
                                                                name={slab._id}
                                                                onChange={this.handleChangeBccSlabChargeTypeFrom}
                                                                error={this.state.slabFromRequiredError[indexNo] ? true : (this.state.slabFromError[indexNo] ? true : false)}
                                                                type='number'
                                                                style={{marginLeft:"10px", width:"100%"}}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <TextField 
                                                                fullWidth 
                                                                margin="dense" 
                                                                id="bccslabto" 
                                                                label="To Rupees"
                                                                variant="outlined" 
                                                                value={slab.infi === true ? "" : slab.to}
                                                                name={slab._id}
                                                                onChange={this.handleChangeBccSlabChargeTypeTo}
                                                                error={this.state.slabToRequiredError[indexNo] ? true : (this.state.slabToError[indexNo] ? true : false)}
                                                                disabled={slab.infi === true ? true : false}
                                                                type='number'
                                                            />                                                            
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <Checkbox
                                                                // onChange={(event) => this.handleInfinity(event, slab, indexNo)}
                                                                onChange={(event) => this.handleInfinity(event, slab)}
                                                                key={"infinityCheckBox"+indexNo}
                                                                name={slab._id}
                                                                checked={slab.infi}
                                                                style={{ paddingRight: "3px",  transform: "scale(0.9)" }}
                                                            /> Infinity
                                                        </Grid>

                                                        <Grid item xs={1}>
                                                            <IconButton style={{backgroundColor:'white', padding: "2px" }} aria-label="delete" size="small">
                                                                <DeleteIcon onClick={() => this.deleteSlab(slab)} fontSize="inherit" />
                                                            </IconButton>
                                                        </Grid>

                                                        {/* For error messages */}
                                                        <Grid item xs={3}>
                                                            {(this.state.slabFromRequiredError[indexNo])? (
                                                                <FormHelperText style={{ color: "red", marginLeft:"10px", marginTop:"0px" }}> Required </FormHelperText>
                                                            ) :  (this.state.slabFromError[indexNo] ? <FormHelperText style={{ color: "red" }}>Slab values are not incremental than previous slab</FormHelperText>  : null)}
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            {(this.state.slabToRequiredError[indexNo])? (
                                                                <FormHelperText style={{ color: "red", marginLeft:"10px", marginTop:"0px" }}> Required </FormHelperText>
                                                            ) :  (this.state.slabToError[indexNo] ? <FormHelperText style={{ color: "red" }}>To Rupees value should be greater than From Rupees value</FormHelperText> : null)}
                                                        </Grid>
                                                        <Grid item xs={6}></Grid>

                                                        <Grid item xs={12}>
                                                            <Divider color="textSecondary" style={{ marginBottom: "5px", marginTop: "10px" }} display="block" />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        )
                                        : null
                                    ))
                                )
                                : null
                            )
                            :
                                (
                                    this.state.bccTypeSelection && this.state.bccTypeSelection === 'isBccFlat' ? 
                                <Grid container direction="row" key={"flatBcc"} justify="center" alignItems="center">
                                    <Grid item xs={6}>
                                        <FormControl fullWidth >
                                            <Select
                                                labelId="fixedPercentageSelection-label"
                                                id="demo-simple-select"
                                                value={this.state.fixedPercentageSelection}
                                                onChange={this.handleBccFixedHandleChane}
                                                name="fixedPercentageSelection" 
                                                variant="outlined"
                                                margin="dense"
                                                error={this.state.fixedPercentageSelectionError ? true : false}
                                                disabled={!this.state.bccTypeSelection ? true : false}
                                                style={{margin:"10px"}}
                                            >
                                                <MenuItem value={'percentage'}>Percentage</MenuItem>
                                                <MenuItem value={'fixed'}>Fixed</MenuItem>
                                            </Select>
                                            {(this.state.fixedPercentageSelectionError)? (
                                                <FormHelperText style={{ color: "red" }}> Required </FormHelperText>
                                            ) :  null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField 
                                            fullWidth 
                                            margin="dense" 
                                            name="bccCharges" 
                                            label={this.state.fixedPercentageSelection === 'percentage' ? "Percentage Value" : (this.state.fixedPercentageSelection === 'fixed' ? "Fixed Value" : "Rupees" )}
                                            variant="outlined" 
                                            value={this.state.bccCharges}
                                            onChange={this.handleBccFixedHandleChane}
                                            error={this.state.bccChargesError ? true : false}
                                            disabled={!this.state.bccTypeSelection ? true : false}
                                            type='number'
                                        />
                                        {(this.state.bccChargesError)? (
                                            <FormHelperText style={{ color: "red" }}> Required </FormHelperText>
                                        ) :  null}
                                    </Grid>
                                </Grid>
                                : null
                                )
                        }

                        {this.state.bccTypeSelection && this.state.bccTypeSelection === 'isBccSlab' ?<Grid item xs={5}></Grid> : null}
                        {
                            this.state.bccTypeSelection && this.state.bccTypeSelection === 'isBccSlab' ? 
                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.addMoreSlabs}
                                        style={{ cursor: "pointer", marginLeft: "0px", alignItems: "center", width: "150%"}}
                                    >
                                        Add More
                                    </Button>
                                </Grid> : null
                        }
                        {this.state.bccTypeSelection && this.state.bccTypeSelection === 'isBccSlab' ? <Grid item xs={5}> </Grid> : null}


                        <Grid item xs={12}>
							<Divider color="textSecondary" style={{ marginBottom: "5px", marginTop: "10px" }} display="block" />
						</Grid>


                        <Grid item xs={12} sm={12}><Typography variant="subtitle2" gutterBottom>Cancellation Fee</Typography></Grid>

                        <Grid item xs={12} sm={6}>
                            <Field fullWidth margin="dense" name="cancellationFee" label="Rupees" variant="outlined" component={this.renderTextField} />
                        </Grid>
                        <Grid item xs={12} sm={6}><Typography variant="subtitle2" gutterBottom style={{marginTop:"14px"}}>Per Cancelled Transaction</Typography></Grid>


                        <Grid item xs={12} sm={12}><Typography variant="subtitle2" gutterBottom>Push Notification Services</Typography></Grid>

                        <Grid item xs={12} sm={6}>
                            <Field fullWidth margin="dense" name="pushNotificationServicesFee" label="Rupees" variant="outlined" component={this.renderTextField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}><Typography variant="subtitle2" gutterBottom style={{marginTop:"14px"}}>Per Push Notification</Typography></Grid>

                        <Grid item xs={12}>
							<Divider color="textSecondary" style={{ marginBottom: "5px", marginTop: "10px" }} display="block" />
						</Grid>



                        <Grid item xs={12} sm={6}><Typography variant="subtitle2" gutterBottom>Other Marketing Packages</Typography></Grid>
                        <Grid item xs={12} sm={6}><Typography variant="subtitle2" gutterBottom>Cost</Typography></Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth style={{marginTop:"8px"}} >
                                <Select
                                    labelId="OnlineMarketingPackages-label"
                                    id="demo-simple-select"
                                    // value={this.state.otherMarketingPackages}
                                    // value={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.otherMarketingPackages : this.state.otherMarketingPackages}
                                    value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsMarketPackFlag && this.state.editFieldsMarketPackFlag === true ? this.props.initialValues.otherMarketingPackages : this.state.otherMarketingPackages}
                                    onChange={this.handleOtherMarketingPackagesChange}
                                    name="otherMarketingPackages" 
                                    variant="outlined"
                                    margin="dense"
                                    error={this.state.otherMarketingPackagesError ? true : false}
                                >
                                    <MenuItem value={'oneTime'}>One Time</MenuItem>
                                    <MenuItem value={'monthly'}>Monthly</MenuItem>
                                    <MenuItem value={'threeMonths'}>3 Months</MenuItem>
                                    <MenuItem value={'sixMonths'}>6 Months</MenuItem>
                                    <MenuItem value={'twelveMonths'}>12 Months</MenuItem>
                                </Select>
                            </FormControl>
                            {(this.state.otherMarketingPackagesError)? (
                                <FormHelperText style={{ color: "red" }}> Required </FormHelperText>
                            ) :  null}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField 
                                fullWidth 
                                margin="dense" 
                                name="otherMarketingPackagesCost" 
                                label="Rupees" 
                                variant="outlined" 
                                // value={this.state.otherMarketingPackagesCost}
                                // value={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.otherMarketingPackagesCost : this.state.otherMarketingPackagesCost}
                                value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsMarketPackFeeFlag && this.state.editFieldsMarketPackFeeFlag === true ? this.props.initialValues.otherMarketingPackagesCost : this.state.otherMarketingPackagesCost}
                                onChange={this.handleChange}
                                error={this.state.otherMarketingPackagesCostError ? true : false}
                                type='number'
                            />
                            {(this.state.otherMarketingPackagesCostError)? (
                                <FormHelperText style={{ color: "red" }}> Required </FormHelperText>
                            ) :  null}
                        </Grid>
                        <Grid item xs={12} sm={2}><Typography variant="subtitle2" gutterBottom style={{marginTop:"14px"}}>For One Time</Typography></Grid>
                        <Grid item xs={12} sm={12}>
                            <Field fullWidth margin="dense" name="otherMarketingPackagesDescription" label="Description" variant="outlined" component={this.renderTextField}/>
                        </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}></Grid><Grid item xs={12} sm={12}></Grid>
                        <Grid item xs={12} sm={12}></Grid><Grid item xs={12} sm={12}></Grid>


                        {/* Services Opted For Section */}
                        <Grid container spacing={1} className={classes.headerTwo} >
                            <Grid item xs={12}><Typography gutterBottom> Services Opted For</Typography></Grid>
                        </Grid>

                        <Grid container spacing={1} className={classes.gridBC}>

                        <Grid item xs={12} sm={1}><Typography variant="subtitle2" gutterBottom>1.</Typography></Grid>
                        <Grid item xs={12} sm={9}><Typography variant="subtitle2" gutterBottom>Online Business Listing On Xirify</Typography></Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        key={`isOnlineBusinessListingOnXirifySwitch_ 1`}
                                        // checked={this.state.isOnlineBusinessListingOnXirify ? true : false}
                                        // checked={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.isOnlineBusinessListingOnXirify : (this.state.isOnlineBusinessListingOnXirify ? true : false)}
                                        checked={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptAFlag && this.state.editFieldsServiceOptAFlag === true ? this.props.initialValues.isOnlineBusinessListingOnXirify : (this.state.isOnlineBusinessListingOnXirify ? true : false)}
                                        onChange={this.handleSwitchChange}
                                        color="primary"
                                        name='isOnlineBusinessListingOnXirify'
                                    />
                                }
                                style={{ alignItems:"right" }}
                                label={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptAFlag && this.state.editFieldsServiceOptAFlag === true ? (this.props.initialValues.isOnlineBusinessListingOnXirify ? "Yes" : "No") : (this.state.isOnlineBusinessListingOnXirify ? "Yes" : "No")}
                            />
                        </Grid>


                        <Grid item xs={12} sm={1}><Typography variant="subtitle2" gutterBottom> 2.</Typography></Grid>
                        <Grid item xs={12} sm={9}><Typography variant="subtitle2" gutterBottom>Delivery Orders</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        key={`isDeliveryOrdersSwitch_ 1`}
                                        // checked={this.state.isDeliveryOrders ? true : false}
                                        // checked={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.isDeliveryOrders : (this.state.isDeliveryOrders ? true : false)}
                                        checked={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptBFlag && this.state.editFieldsServiceOptBFlag === true ? this.props.initialValues.isDeliveryOrders : (this.state.isDeliveryOrders ? true : false)}
                                        onChange={this.handleSwitchChange}
                                        color="primary"
                                        name='isDeliveryOrders'
                                    />
                                }
                                style={{ alignItems:"right" }}
                                label={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptBFlag && this.state.editFieldsServiceOptBFlag === true ? (this.props.initialValues.isDeliveryOrders ? "Yes" : "No") : (this.state.isDeliveryOrders ? "Yes" : "No")}
                            />
                        </Grid>



                        <Grid item xs={12} sm={1}><Typography variant="subtitle2" gutterBottom>3.</Typography></Grid>
                        <Grid item xs={12} sm={9}><Typography variant="subtitle2" gutterBottom>Pick Up Orders</Typography></Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        key={`isPickUpOrdersSwitch_ 1`}
                                        // checked={this.state.isPickUpOrders ? true : false}
                                        // checked={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.isPickUpOrders : (this.state.isPickUpOrders ? true : false)}
                                        checked={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptCFlag ? this.props.initialValues.isPickUpOrders : (this.state.isPickUpOrders ? true : false)}
                                        onChange={this.handleSwitchChange}
                                        color="primary"
                                        name='isPickUpOrders'
                                    />
                                }
                                style={{ alignItems:"right" }}
                                label={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptCFlag ? (this.props.initialValues.isPickUpOrders ? "Yes" : "No") : (this.state.isPickUpOrders ? "Yes" : "No")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={1}><Typography variant="subtitle2" gutterBottom>4.</Typography></Grid>
                        <Grid item xs={12} sm={9}><Typography variant="subtitle2" gutterBottom>Service at Customer Location</Typography></Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        key={`isServiceAtConsumerLocationSwitch_ 1`}
                                        // checked={this.state.isServiceAtConsumerLocation ? true : false}
                                        // checked={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.isServiceAtConsumerLocation : (this.state.isServiceAtConsumerLocation ? true : false)}
                                        checked={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptDFlag ? this.props.initialValues.isServiceAtConsumerLocation : (this.state.isServiceAtConsumerLocation ? true : false)}
                                        onChange={this.handleSwitchChange}
                                        color="primary"
                                        name='isServiceAtConsumerLocation'
                                    />
                                }
                                style={{ alignItems:"right" }}
                                label={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptDFlag ? (this.props.initialValues.isServiceAtConsumerLocation ? "Yes" : "No") : (this.state.isServiceAtConsumerLocation ? "Yes" : "No")}
                            />
                        </Grid>


                        <Grid item xs={12} sm={1}><Typography variant="subtitle2" gutterBottom>5.</Typography></Grid>
                        <Grid item xs={12} sm={9}><Typography variant="subtitle2" gutterBottom>Service at Business Location</Typography></Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        key={`isServiceAtBusinessLocationSwitch_ 1`}
                                        // checked={this.state.isServiceAtBusinessLocation}
                                        // checked={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.isServiceAtBusinessLocation : this.state.isServiceAtBusinessLocation}
                                        checked={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptEFlag ? this.props.initialValues.isServiceAtBusinessLocation : this.state.isServiceAtBusinessLocation}
                                        onChange={this.handleSwitchChange}
                                        color="primary"
                                        name='isServiceAtBusinessLocation'
                                    />
                                }
                                style={{ alignItems:"right" }}
                                label={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptEFlag ? (this.props.initialValues.isServiceAtBusinessLocation ? "Yes" : "No") : (this.state.isServiceAtBusinessLocation ? "Yes" : "No")}
                            />
                        </Grid>


                        <Grid item xs={12} sm={1}><Typography variant="subtitle2" gutterBottom>6.</Typography></Grid>
                        <Grid item xs={12} sm={9}><Typography variant="subtitle2" gutterBottom>Self-Delivery</Typography></Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        key={`isSelfDeliverySwitch_ 1`}
                                        // checked={this.state.isSelfDelivery ? true : false}
                                        // checked={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.isSelfDelivery : (this.state.isSelfDelivery ? true : false)}
                                        checked={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptFFlag ? this.props.initialValues.isSelfDelivery : (this.state.isSelfDelivery ? true : false)}
                                        onChange={this.handleSwitchChange}
                                        color="primary"
                                        name='isSelfDelivery'
                                    />
                                }
                                style={{ alignItems:"right" }}
                                label={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptFFlag ? (this.props.initialValues.isSelfDelivery ? "Yes" : "No") : (this.state.isSelfDelivery ? "Yes" : "No")}
                            />
                        </Grid>


                        <Grid item xs={12} sm={1}> <Typography variant="subtitle2" gutterBottom>7.</Typography></Grid>
                        <Grid item xs={12} sm={9}><Typography variant="subtitle2" gutterBottom>Xirify Partner Delivery</Typography></Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        key={`isXirifyPartnerDeliverySwitch_ 1`}
                                        // checked={this.state.isXirifyPartnerDelivery}
                                        // checked={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.isXirifyPartnerDelivery : this.state.isXirifyPartnerDelivery}
                                        checked={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptGFlag ? this.props.initialValues.isXirifyPartnerDelivery : this.state.isXirifyPartnerDelivery}
                                        onChange={this.handleSwitchChange}
                                        color="primary"
                                        name='isXirifyPartnerDelivery'
                                    />
                                }
                                style={{ alignItems:"right" }}
                                label={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptGFlag ? (this.props.initialValues.isXirifyPartnerDelivery ? "Yes" : "No") : (this.state.isXirifyPartnerDelivery ? "Yes" : "No")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={1}><Typography variant="subtitle2" gutterBottom>8.</Typography></Grid>
                        <Grid item xs={12} sm={9}><Typography variant="subtitle2" gutterBottom>ONDC Registration</Typography></Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        key={`isONDCRegistrationSwitch_ 1`}
                                        // checked={this.state.isONDCRegistration ? true : false}
                                        // checked={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.isONDCRegistration : (this.state.isONDCRegistration ? true : false)}
                                        checked={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptHFlag ? this.props.initialValues.isONDCRegistration : (this.state.isONDCRegistration ? true : false)}
                                        onChange={this.handleSwitchChange}
                                        color="primary"
                                        name='isONDCRegistration'
                                    />
                                }
                                style={{ alignItems:"right" }}
                                label={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptHFlag ? (this.props.initialValues.isONDCRegistration ? "Yes" : "No") : (this.state.isONDCRegistration ? "Yes" : "No")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={1}><Typography variant="subtitle2" gutterBottom>9.</Typography></Grid>
                        <Grid item xs={12} sm={9}><Typography variant="subtitle2" gutterBottom>ONDC exclusive registration</Typography></Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        key={`isONDCExclusiveRegistrationSwitch_ 1`}
                                        // checked={this.state.isONDCExclusiveRegistration ? true : false}
                                        // checked={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.isONDCExclusiveRegistration : (this.state.isONDCExclusiveRegistration ? true : false)}
                                        checked={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptIFlag ? this.props.initialValues.isONDCExclusiveRegistration : (this.state.isONDCExclusiveRegistration ? true : false)}
                                        onChange={this.handleSwitchChange}
                                        color="primary"
                                        name='isONDCExclusiveRegistration'
                                    />
                                }
                                style={{ alignItems:"right" }}
                                label={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsServiceOptIFlag ? (this.props.initialValues.isONDCExclusiveRegistration ? "Yes" : "No") : (this.state.isONDCExclusiveRegistration ? "Yes" : "No")}
                            />
                        </Grid>

                        </Grid>

                        <Grid item xs={12} sm={12}></Grid><Grid item xs={12} sm={12}></Grid>
                        <Grid item xs={12} sm={12}></Grid><Grid item xs={12} sm={12}></Grid>


                        {/* Additional Terms Section */}

                        <Grid container spacing={1} className={classes.headerTwo} >
                            <Grid item xs={12}><Typography gutterBottom>Additional Terms</Typography></Grid>
                        </Grid>

                        <Grid container spacing={1} className={classes.gridBC}>
                        <Grid item xs={12} sm={12}><Typography variant="subtitle2" gutterBottom>Business Convenience Charges</Typography></Grid>

                        <Grid item xs={12} sm={6}>
                            <Field fullWidth margin="dense" name="bccPercentage" label="Enter Percentage" variant="outlined" component={this.renderTextField} InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}/>
                        </Grid>
                        <Grid item xs={12} sm={6}><Typography variant="subtitle2" gutterBottom style={{marginTop:"14px"}}>(GST Inclusive)</Typography></Grid>


                        <Grid item xs={12} sm={12}><Typography variant="subtitle2" gutterBottom>Effective Date</Typography></Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
								fullWidth
								margin="dense"
								name="effectiveDate"
								type="date"
								variant="outlined"
								InputLabelProps={{shrink: true}}
								component={this.renderTextField}
                                label="Effective Date"
							/>
                        </Grid>

                        
                        <Grid item xs={12} sm={6}></Grid>

                        </Grid>

                        <Grid item xs={12} sm={12}></Grid><Grid item xs={12} sm={12}></Grid>
                        <Grid item xs={12} sm={12}></Grid><Grid item xs={12} sm={12}></Grid>

                        {/* Account Take Down */}

                        <Grid container spacing={1} className={classes.headerTwo} >
                            <Grid item xs={12}><Typography gutterBottom>Account Take Down</Typography></Grid>
                        </Grid>

                        <Grid container spacing={1} className={classes.gridBC}>
                            <Grid item xs={12} sm={12}><Typography variant="subtitle2" gutterBottom>Notify the Merchant if account found inactive for</Typography></Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="months-select-outlined-label" style={{margin:"0px 0px 0px 25px"}}>Select Period</InputLabel>
                                    <Select
                                        labelId="notifyMerchantTypeSelection-label"
                                        id="demo-simple-select"
                                        value={this.state.notifyMerchantTypeSelection}
                                        onChange={this.handleChange}
                                        name="notifyMerchantTypeSelection" 
                                        variant="outlined"
                                        margin="dense"
                                        label="Select Period"
                                        style={{margin:"10px"}}
                                    >
                                        <MenuItem value={'daysnotify'}>Days</MenuItem>
                                        <MenuItem value={'weeksnotify'}>Weeks</MenuItem>
                                        <MenuItem value={'monthsnotify'}>Months</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {
                                this.state.notifyMerchantTypeSelection && this.state.notifyMerchantTypeSelection === 'monthsnotify' ? 
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth >
                                    <InputLabel id="months-select-outlined-label" style={{margin:"0px 0px 0px 25px"}}>No of Month/s</InputLabel>
                                    <Select
                                        labelId="notifyMerchantMonth-label"
                                        id="demo-simple-select"
                                        // value={this.state.notifyMerchantMonthSelection}
                                        // value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsNotifyATDMFlag ? this.props.initialValues.notifyMerchantMonthSelection : this.state.notifyMerchantMonthSelection} // was earlier LOC
                                        value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsNotifyATDMFlag ? this.state.notifyMerchantMonthSelection : this.state.notifyMerchantMonthSelection}
                                        onChange={this.handleChange}
                                        name="notifyMerchantMonthSelection" 
                                        variant="outlined"
                                        margin="dense"
                                        label="No of Month/s"
                                        style={{margin:"10px"}}
                                    >
                                        {months.map((month)=>(<MenuItem key={month.index} value={month.month}> {month.month}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            : null }

                            {
                                this.state.notifyMerchantTypeSelection && this.state.notifyMerchantTypeSelection === 'weeksnotify' ?
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth >
                                <InputLabel id="weeks-select-outlined-label" style={{margin:"0px 0px 0px 25px"}}>No of Week/s</InputLabel>
                                    <Select
                                        labelId="notifyMerchantWeek-label"
                                        id="demo-simple-select"
                                        // value={this.state.notifyMerchantWeekSelection}
                                        // value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsNotifyATDWFlag ? this.props.initialValues.notifyMerchantWeekSelection : this.state.notifyMerchantWeekSelection} // was earlier LOC
                                        value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsNotifyATDWFlag ? this.state.notifyMerchantWeekSelection : this.state.notifyMerchantWeekSelection}
                                        onChange={this.handleChange}
                                        name="notifyMerchantWeekSelection" 
                                        variant="outlined"
                                        margin="dense"
                                        style={{margin:"10px"}}
                                        label="No of Week/s"
                                    >
                                        {weeks.map((week)=>(<MenuItem key={week.index} value={week.week}>{week.week}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            : null }

                            {
                                this.state.notifyMerchantTypeSelection && this.state.notifyMerchantTypeSelection === 'daysnotify' ?
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth >
                                    <InputLabel id="days-select-outlined-label" style={{margin:"0px 0px 0px 25px"}}>No of Day/s</InputLabel>
                                    <Select
                                        labelId="notifyMerchantDay-label"
                                        id="demo-simple-select"
                                        // value={this.state.notifyMerchantDaySelection}
                                        // value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsNotifyATDDFlag ? this.props.initialValues.notifyMerchantDaySelection : this.state.notifyMerchantDaySelection} // was earlier LOC
                                        value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsNotifyATDDFlag ? this.state.notifyMerchantDaySelection : this.state.notifyMerchantDaySelection}
                                        onChange={this.handleChange}
                                        name="notifyMerchantDaySelection" 
                                        variant="outlined"
                                        margin="dense"
                                        label="No of Day/s"
                                        style={{margin:"10px"}}
                                    >
                                        {days.map((day)=>(<MenuItem key={day.index} value={day.day}>{day.day}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            : null }

                            
                            <Grid item xs={12} sm={12}><Typography variant="subtitle2" gutterBottom>If the Merchant fails to restart the account within</Typography></Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="months-select-outlined-label" style={{margin:"0px 0px 0px 25px"}}>Select Period</InputLabel>
                                    <Select
                                        labelId="restartMerchantTypeSelection-label"
                                        id="demo-simple-select"
                                        value={this.state.restartMerchantTypeSelection}
                                        onChange={this.handleChange}
                                        name="restartMerchantTypeSelection" 
                                        variant="outlined"
                                        margin="dense"
                                        label="Select Period"
                                        style={{margin:"10px"}}
                                    >
                                        <MenuItem value={'daysrestart'}>Days</MenuItem>
                                        <MenuItem value={'weeksrestart'}>Weeks</MenuItem>
                                        <MenuItem value={'monthsrestart'}>Months</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {
                                this.state.restartMerchantTypeSelection && this.state.restartMerchantTypeSelection === 'monthsrestart' ? 
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth >
                                    <InputLabel id="months-select-outlined-label" style={{margin:"0px 0px 0px 25px"}}>No of Month/s</InputLabel>
                                    <Select
                                        labelId="notifyMerchantMonth-label"
                                        id="demo-simple-select"
                                        // value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsRestartATDMFlag ? this.props.initialValues.restartMerchantMonthSelection : this.state.restartMerchantMonthSelection} // was earlier LOC
                                        value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsRestartATDMFlag ? this.state.restartMerchantMonthSelection : this.state.restartMerchantMonthSelection}
                                        onChange={this.handleChange}
                                        name="restartMerchantMonthSelection" 
                                        variant="outlined"
                                        margin="dense"
                                        label="No of Month/s"
                                        style={{margin:"10px"}}
                                    >
                                        {months.map((month)=>(<MenuItem key={month.index} value={month.month}>{month.month}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            : null }

                            {
                                this.state.restartMerchantTypeSelection && this.state.restartMerchantTypeSelection === 'weeksrestart' ? 
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth >
                                    <InputLabel id="weeks-select-outlined-label" style={{margin:"0px 0px 0px 25px"}}>No of Week/s</InputLabel>
                                    <Select
                                        labelId="restartMerchantWeek-label"
                                        id="demo-simple-select"
                                        // value={this.state.restartMerchantWeekSelection}
                                        // value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsRestartATDWFlag ? this.props.initialValues.restartMerchantWeekSelection : this.state.restartMerchantWeekSelection} // was earlier LOC
                                        value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsRestartATDWFlag ? this.state.restartMerchantWeekSelection : this.state.restartMerchantWeekSelection}
                                        onChange={this.handleChange}
                                        name="restartMerchantWeekSelection" 
                                        variant="outlined"
                                        margin="dense"
                                        style={{margin:"10px"}}
                                        label="No of Week/s"
                                    >
                                        {weeks.map((week)=>(<MenuItem key={week.index} value={week.week}>{week.week}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            : null }

                            {
                                this.state.restartMerchantTypeSelection && this.state.restartMerchantTypeSelection === 'daysrestart' ? 
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth >
                                    <InputLabel id="days-select-outlined-label" style={{margin:"0px 0px 0px 25px"}}>No of Day/s</InputLabel>
                                    <Select
                                        labelId="restartMerchantDay-label"
                                        id="demo-simple-select"
                                        // value={this.state.restartMerchantDaySelection}
                                        // value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsRestartATDDFlag ? this.props.initialValues.restartMerchantDaySelection : this.state.restartMerchantDaySelection} // was earlier LOC
                                        value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsRestartATDDFlag ? this.state.restartMerchantDaySelection : this.state.restartMerchantDaySelection}
                                        onChange={this.handleChange}
                                        name="restartMerchantDaySelection" 
                                        variant="outlined"
                                        margin="dense"
                                        label="No of Day/s"
                                        style={{margin:"10px"}}
                                    >
                                        {days.map((day)=>(<MenuItem key={day.index} value={day.day}>{day.day}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            : null }

                        </Grid>
                    </Grid>
                </Container>

                <Container className={classes.footer}>
                    <Grid item xs={12} sm={6}></Grid>
                    <Grid item xs={12} sm={3}>
                        <Button
                            style={{ marginRight: "10px", backgroundColor: '#EBEBEB', color: 'black' }}
                            onClick={() => { this.props.onClose() }}
                            fullWidth
                            variant="contained"
                            margin="normal"
                            color="primary">
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button
                            style={{ margin: "5px" }}
                            type="submit"
                            disabled={this.state.btnDisabled?true:false}
                            fullWidth
                            variant="contained"
                            margin="normal"
                            color="primary">
                                {this.props.editContractFlag && this.props.editContractFlag === true ? 'Edit Contract' : 'Create Contract'}
                            {/* Create Contract */}
                        </Button>
                    </Grid>
                </Container>
            </form>
        );
    }
}

const validate = values => {

    // console.log("Values => ", values)
    const errors = {};
    let requiredFields = [];

    requiredFields = [ 'merchantName', 'legalEntityName', 'contactPerson', 'businessEmail', 'contractStartDate', 'autoRenewalYear', 'addressLineOne', 'addressCountry', 'addressState', 'addressCity', 'addressPinCode' ];

    requiredFields.forEach(field => { if (!values[field]) { errors[field] = 'Required' } });

    // console.log(errors);

    if (values.legalEntityName) {
        if(values.legalEntityName.trim().length === 0) {
            errors.legalEntityName = 'Required';
        }
    }
    if (values.merchantName) {
        if(values.merchantName.trim().length === 0) {
            errors.merchantName = 'Required';
        }
    }

    if (values.addressLineOne) {
        if(values.addressLineOne.trim().length === 0) {
            errors.addressLineOne = 'Required';
        }
    }
    if (values.addressLineTwo) {
        if(values.addressLineTwo.trim().length === 0) {
            errors.addressLineTwo = 'Only spaces not allowed';
        }
    }

    if (values.addressPinCode) {
        if(!/^[1-9][0-9]{5}$/.test(values.addressPinCode)) {
            errors.addressPinCode = 'Invalid pincode'
        }
    }

    if (values.addressCountry) {
        if (!/^[A-Za-z]+$/i.test(values.addressCountry) ) {
            errors.addressCountry = 'Enter Valid Coutry Name'
        }
    }

    if (values.addressState) {
        if (!/^[A-Za-z]+$/i.test(values.addressState) ) {
            errors.addressState = 'Enter Valid State Name'
        }
    }

    if (values.addressCity) {
        if (!/^[A-Za-z]+$/i.test(values.addressCity) ) {
            errors.addressCity = 'Enter Valid City Name'
        }
    }

    if (values.outletEntitySignUpFee) {
        if (!/^[+]?((\d+(\.\d*)?)|(\.\d+))$/i.test(values.outletEntitySignUpFee) ) {
            errors.outletEntitySignUpFee = 'Enter valid amount'
        }
    }

    if (values.cancellationFee) {
        if (!/^[+]?((\d+(\.\d*)?)|(\.\d+))$/i.test(values.cancellationFee) ) {
            errors.cancellationFee = 'Enter valid amount'
        }
    }

    if (values.pushNotificationServicesFee) {
        if (!/^[+]?((\d+(\.\d*)?)|(\.\d+))$/i.test(values.pushNotificationServicesFee) ) {
            errors.pushNotificationServicesFee = 'Enter valid amount'
        }
    }

    if (values.otherMarketingPackagesCost) {
        if (!/^[+]?((\d+(\.\d*)?)|(\.\d+))$/i.test(values.otherMarketingPackagesCost) ) {
            errors.otherMarketingPackagesCost = 'Enter valid amount'
        }
    }

    if (values.bccPercentage) {
        if (!/^[+]?((\d+(\.\d*)?)|(\.\d+))$/i.test(values.bccPercentage) ) {
            errors.bccPercentage = 'Enter valid percentage value'
        }
    }

    if (values.fssai) {
        // if(!/^[1-9][0-9]{14}$/.test(values.fssai)) { // fssai number starts with 1 or 2
        if(!/^[1-9][0-9]{13}$/.test(values.fssai)) {
            errors.fssai = 'FSSAI License No. should be 14 digits'
        }
    }

    if (values.gstNumber) {
        if(/^[a-z]{15}$/.test(values.gstNumber)) {
            errors.gstNumber = 'GST No. should be 15 digit alphanumeric & uppercase letters'
        } else if(!/^[0-9][0-9A-Z]{14}$/.test(values.gstNumber)) {
            errors.gstNumber = 'GST No. should be 15 digits'
        }
    }

    if (values.shopAct) {
        if(!/^[0-9]{12}$/.test(values.shopAct)) {
            errors.shopAct = 'ShopAct should be a 12 digit number'

        }
    }

    // if (values.contactNumber) {
    //     if(!/^[6-9][0-9]{10}$/.test(values.contactNumber)) {
    //         errors.contactNumber = 'Contact No. should be a 10 digit number'
    //     }
    // }

    if(values.businessEmail) {
        if (values.businessEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.businessEmail)) {
            errors.businessEmail = 'Invalid email address'
        }
    }

    return errors;
}

export default reduxForm({
    form: 'BusinessAgreementForm',
    validate
})(withStyles(styles)(BusinessAgreementForm))