import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        marginTop: '10px',
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MultipleSelect(props) {
    const classes = useStyles();

    const handleChange = (event) => {
        props.assignRegions(event);
    };

    return (
        <div>
            <FormControl variant="outlined" fullWidth className={classes.formControl} style={{ marginTop: !props.avoidErrorMsg ? '10px' : '0px' }}>
                <InputLabel id="demo-mutiple-checkbox-label">{!props.avoidErrorMsg ? "Assign Region(s)" : "Region"}</InputLabel>
                <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={props.assignedRegions}
                    name="assignedRegions"
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {props.regions.map((region) => (
                        <MenuItem key={region._id} value={region.name}>
                            <Checkbox checked={props.assignedRegions.indexOf(region.name) > -1} />
                            <ListItemText primary={region.name} />
                        </MenuItem>
                    ))}
                </Select>
                {
                    // !props.avoidErrorMsg ?
                    //     props.assignedRegions.length < 1 ? <FormHelperText style={{ color: 'red' }}>Required</FormHelperText> : null : null
                }
            </FormControl>
        </div>
    );
}
