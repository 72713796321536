import {
  SET_PAYMENT_SUMMARY_ERROR_MESSAGE,
  SET_PAYMENT_SUMMARY,
  REQUEST_PAYMENT_SUMMARY,
  SET_PAYMENT_SUMMARY_EXCEL,
  SET_PAYMENT_SUMMARY_CASHFREE_SETTLEMENT_DATA,
  CHANGE_AP_ORDERS_SUCCESS_MESSAGE,
  CHANGE_AP_ORDERS_ERROR_MESSAGE,
  SET_TR_NOTE_SUCCESS_MESSAGE,
  SET_TR_NOTE_ERROR_MESSAGE,
  SET_GMV_VALUE_ERROR_MESSAGE,
  SET_GMV_VALUE,
  REQUEST_GMV_VALUE
} from "../actions/types";

const INITIAL_STATE = {
  page: 0,
  skip: 0,
  limit: 10,
  paymentSummary: {},
  isFetching: false,
  sucessMsg: null,
  errorMsg: null,
  ops: "",
  gmvValue: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_PAYMENT_SUMMARY:
      return { ...state, isFetching: action.payload };
    case SET_PAYMENT_SUMMARY_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: action.payload.msg,
        ops: action.payload.ops,
        // isFetching: false
      };
    case SET_PAYMENT_SUMMARY:
      return {
        ...state,
        paymentSummary: action.payload,
        isFetching: false,
      };
    case SET_PAYMENT_SUMMARY_EXCEL:
      return {
        ...state,
        paymentSummaryExcel: action.payload,
        isFetching: false,
      };
    case SET_PAYMENT_SUMMARY_CASHFREE_SETTLEMENT_DATA:
      return {
        ...state,
        paymentSummaryCashfreeSettlementData: action.payload,
        isFetching: false,
      };
    case CHANGE_AP_ORDERS_SUCCESS_MESSAGE:


      return {
        ...state,
        sucessMsg: action.payload
       
      };
    case CHANGE_AP_ORDERS_ERROR_MESSAGE:
      return {        
        ...state,
        errorMsg: action.payloadghbgtgtgtgfdfrgtytyjujhhhyjukilkkjhkyujtuygyjtuyjyt
        
      };
      case SET_TR_NOTE_SUCCESS_MESSAGE:
        return {
            ...state,           
            ops: action.payload.ops,
            isFetching: false
        };
    case SET_TR_NOTE_ERROR_MESSAGE:
        return {
            ...state,
            errorMsg: action.payload.msg,
            ops: action.payload.ops,
            isFetching: false
        };
    
        case REQUEST_GMV_VALUE:
      return { ...state, isFetching: action.payload };
    case SET_GMV_VALUE_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: action.payload.msg,
        ops: action.payload.ops,
        // isFetching: false
      };
    case SET_GMV_VALUE:
      console.log("action.payload ----------->", action.payload)

      return {
        ...state,
        gmvValue: action.payload,
        isFetching: false,
      };
        default:
      return state;
  }
}
