import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment';
// import MomentUtils from '@date-io/moment';
// import DateFnsUtils from '@date-io/date-fns';
// import { MuiPickersUtilsProvider, KeyboardDatePicker, DatePicker } from '@material-ui/pickers';

const styles = theme => ({
    form: {
        width: '100%',
        height: '100%',
    },
    header: {
        height: '10%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '70%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
});

class ContractForm extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedDate: new Date(),
            ammount: 0,
            selectedMode: 'Cash',
            cStartDate: '',
            cEndDate: ''
        };
    }

    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    }

    renderDateField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                type="date"
                defaultValue={new Date()}
                error={touched && invalid}
                helperText={touched && error}
                InputLabelProps={{
                    shrink: true,
                }}
                {...input}
                {...custom}
            />
        );
    }

    onSubmit = formValues => {
        formValues.mode = this.state.selectedMode;
        this.props.onSubmit(formValues)
    }

    handleModeChange = event => {
        this.setState({
            selectedMode: event.target.value
        });
    };

    handleDateChange = (e) => {
        if (e.target.name === 'contractStartDate') {
            this.setState({
                cStartDate: e.target.value
            })
        }
        if (e.target.name === 'contractEndDate') {
            this.setState({
                cEndDate: e.target.value
            })
        }
    }

    render() {
        const { classes } = this.props;
        let period = 0 + ' years ' + 0 + ' months ' + 0 + ' days'

        if (this.state.cStartDate && this.state.cEndDate) {
            let eDate = new Date(this.state.cEndDate);
            let sDate = new Date(this.state.cStartDate);
            if (sDate < eDate) {
                let a = moment(moment(eDate).format('YYYY,MM,DD'));
                let b = moment(moment(sDate).format('YYYY,MM,DD'));
                let years = a.diff(b, 'year');
                b.add(years, 'years');
                let months = a.diff(b, 'months');
                b.add(months, 'months');
                let days = a.diff(b, 'days');
                period = years + ' years ' + months + ' months ' + days + ' days';
            }
        }

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <Grid container className={classes.header} spacing={1}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom>
                            Business Contract
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
                    </Grid>
                </Grid>
                <Container className={classes.content}>
                    {/* <div className={classes.root}> */}
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                {this.props.selectedBusiness.businessName}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item>
                                <Typography variant="caption" display="block" gutterBottom>
                                    Owner
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2" gutterBottom >
                                    {this.props.selectedBusiness.ownerId.firstName} {this.props.selectedBusiness.ownerId.lastName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item>
                                <Typography variant="caption" display="block" gutterBottom>
                                    Contact
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2" gutterBottom >
                                    {this.props.selectedBusiness.primaryContactNumber}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item>
                                <Typography variant="caption" display="block" gutterBottom>
                                    Address
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2" gutterBottom >
                                    {this.props.selectedBusiness.address.shopNo ?
                                        this.props.selectedBusiness.address.shopNo + ', ' : ''}
                                    {this.props.selectedBusiness.address.street ?
                                        this.props.selectedBusiness.address.street + ', ' : ''}
                                    {this.props.selectedBusiness.address.landmark ?
                                        this.props.selectedBusiness.address.landmark + ', ' : ''}
                                    {this.props.selectedBusiness.address.city ?
                                        this.props.selectedBusiness.address.city + ', ' : ''}
                                    {this.props.selectedBusiness.address.state ?
                                        this.props.selectedBusiness.address.state + ', ' : ''}
                                    {this.props.selectedBusiness.address.pinCode ?
                                        this.props.selectedBusiness.address.pinCode : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="contractStartDate" component={this.renderDateField} label="Contract Start Date" onChange={this.handleDateChange} fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="contractEndDate" component={this.renderDateField} label="Contract End Date" onChange={this.handleDateChange} fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item>
                                <Typography variant="caption" display="block" gutterBottom>
                                    Period
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2" gutterBottom >
                                    {period}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="agreedContrctAmount" component={this.renderTextField} label="Agreed Contract Amount" fullWidth variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="description" component={this.renderTextField} label="Description" fullWidth variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="amount" component={this.renderTextField} label="Amount" fullWidth variant="outlined" />
                        </Grid>
                        <Grid item xs={6} style={{ paddingTop: '5px' }}>
                            <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="outlined-age-native-simple">Mode</InputLabel>
                                <Select
                                    native
                                    value={this.state.selectedMode}
                                    onChange={this.handleModeChange}
                                    label="Mode"
                                    inputProps={{
                                        name: 'mode',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    <option value="Cash">Cash</option>
                                    <option value="Cheque">Cheque</option>
                                    <option value="Online">Online</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Field name="note" component={this.renderTextField} label="Payment Details" fullWidth variant="outlined" />
                        </Grid>
                    </Grid>
                    {/* </div> */}
                </Container>
                <Container className={classes.footer}>
                    <div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            margin="normal"
                            color="primary">
                            Submit
                        </Button>
                    </div>
                </Container>
            </form>
        );
    }
}

const validate = values => {
    const errors = {}

    const requiredFields = [
        'contractStartDate',
        'contractEndDate',
        'agreedContrctAmount',
        'amount',
        'mode'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    let eDate = new Date(values.contractEndDate);
    let sDate = new Date(values.contractStartDate);

    if (values.contractStartDate && !moment(values.contractStartDate, 'YYYY-MM-DD', true).isValid()) {
        errors.contractStartDate = 'Invalid date.'
    }
    if (values.contractStartDate && !moment(values.contractEndDate, 'YYYY-MM-DD', true).isValid()) {
        errors.contractEndDate = 'Invalid date.'
    }
    if (values.contractStartDate && values.contractEndDate && sDate >= eDate) {
        errors.contractEndDate = 'Contract end date should be greater than contract start date.'
    }

    if (values.agreedContrctAmount && !/^[0-9]+$/.test(values.agreedContrctAmount)) {
        errors.agreedContrctAmount = 'amount should have only numbers'
    }
    if (values.amount && !/^[0-9]+$/.test(values.amount)) {
        errors.amount = 'amount should have only numbers'
    }
    if (parseInt(values.amount) > parseInt(values.agreedContrctAmount)) {
        errors.amount = 'Amount should be less than or equal to Agreed Contract Amount'
    }

    return errors
}

export default reduxForm({
    form: 'ContractForm',
    validate
})(withStyles(styles)(ContractForm))
