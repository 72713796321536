import React from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: "businessId", disablePadding: true, label: "Business Id" },
    { id: "businessName", disablePadding: true, label: "Business Name" },
    { id: "name", disablePadding: true, label: "Name" },
    { id: "email", disablePadding: false, label: "E-mail" },
    { id: "primaryMobileNumber", disablePadding: false, label: "Mobile Number" },
    { id: "role", disablePadding: false, label: "User Role" },
    { id: "lastActiveTime", disablePadding: false, label: "Last Active On" },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableCellSmall}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: "1 1 100%",
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography
                className={classes.title}
                variant="subtitle2"
                id="tableTitle"
                component="div"
            >
                Active Business Users ({props.total})

      </Typography>

            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"activeBusiness_User.csv"}
                        className="btn btn"
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img
                                alt="Export To Excel"
                                src="../../images/exportToExcel.png"
                                width="25"
                                height="25"
                            />
                        </Tooltip>
                    </CSVLink>
                    :
                    <Button
                        variant="contained"
                        margin="normal"
                        style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                        onClick={props.fetchActiveBusinessUserReportsExcelFile}
                    >
                        Export
                </Button>
                }
            </Typography>
        </Toolbar>
    );
};

/* EnhancedTableToolbar.propTypes = {
    exportToExcel: PropTypes.func.exportToExcel,
    total: PropTypes.number.isRequired
}; */

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px",
    },
    tableRow: {
        // cursor: 'pointer'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();


    const handleRequestSort = (event, property) => {
        const isAsc = props.orderBy === property && props.order === "asc";
        props.setOrder(isAsc ? -1 : 1);
        props.setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    let headers = [
        { key: "businessId", label: "Business Id" },
        { key: "businessName", label: "Business Name" },
        { key: "user.firstName", label: "First Name" },
        { key: "user.lastName", label: "Last Name" },
        { key: "user.email", label: "E-mail" },
        { key: "user.primaryMobileNumber", label: "Mobile Number" },
        { key: "user.role", label: "User Role" },
        { key: "user.lastActiveTime", label: "Last Active On" }
    ];

    props.data && props.data.exportToExcel.forEach(async(element) => {
        // element.user.lastActiveTime = await element.user.lastActiveTime && element.user.lastActiveTime!=undefined ? moment(element.user.lastActiveTime).format("DD-MM-YYYY h:mm a"):"NA"  
        element.user.lastActiveTime = (element.user.lastActiveTime &&  element.user.lastActiveTime !== "Invalid date" && moment( element.user.lastActiveTime)) ? moment( element.user.lastActiveTime).format('DD MMM YYYY hh:mm A') : "NA"
        // element.user.role = (element.user.role == "businessMember") ?  "Member" :( element.user.role === "superVendor"? "Super Vendor" : "Admin")
    });
    const emptyRows =
        props.rowsPerPage -
        Math.min(
            props.rowsPerPage,
            props.data.total - props.page * props.rowsPerPage
        );
        
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    total={props.data.total}
                    headers={headers}
                    data={props.data.exportToExcel}
                    showDownloadIcon={props.showDownloadIcon}
                    fetchActiveBusinessUserReportsExcelFile={props.fetchActiveBusinessUserReportsExcelFile}
                    businessName={props.data.businessName}
                    businessFilter={props.businessFilter}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={props.order}
                            orderBy={props.orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={props.data.total}
                        />
                        <TableBody>

                            {/* stableSort(props.data.data,getComparator(props.order, props.orderBy) ) */}
                            {/* //.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage) */}
                            {props.data.data.map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;


                                return (
                                    <TableRow
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            className={classes.tableCellSmall}
                                        >
                                            {row.businessId}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            className={classes.tableCellSmall}
                                        >
                                            {/* <Avatar
                          alt="Name"
                          src={row.photo}
                          className={classes.small}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      > */}
                                            {row.businessName}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            className={classes.tableCellSmall}
                                        >
                                            {row.user.firstName} {row.user.lastName}
                                        </TableCell>
                                        {/* <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      >
                        {row.user.lastName}
                      </TableCell> */}
                                        <TableCell
                                            align="left"
                                            className={classes.tableCellSmall}
                                        >
                                            {row.user.email}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            className={classes.tableCellSmall}
                                        >
                                            {row.user.primaryMobileNumber}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            className={classes.tableCellSmall}
                                        >
                                            {row.user.role === "businessMember" ?  "Member" :(row.user.role === "superVendor"? "Super Vendor":"Admin") }

                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            className={classes.tableCellSmall}
                                        >
                                            {row.user.lastActiveTime ? moment(row.user.lastActiveTime).format("DD-MM-YYYY h:mm a"):"NA" }
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {/* {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={props.data.total}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}