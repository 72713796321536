import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import ManageBusinessSequenceDragDrop from "./components/ManageBusinessSequenceDragDropComponent";
import {
  HEADLIST,
  SUBHEADLIST,
  SUBHEADSERVICELIST,
  HEADSERVICELIST,
} from "./constants";
import {
  getHeadWithServicesByBusinessId,
  updateSubHeadOrServiceSequence,
  updateHeadSequenceByBusinessId,
} from "../../../../actions/businessActions";

function ManageBusinessSequence({
  businessDetails,
  getHeadWithServicesByBusinessId,
  updateSubHeadOrServiceSequence,
  updateHeadSequenceByBusinessId,
}) {
  const { heads } = useSelector((state) => state.business.HeadsWithServices);
  const [selectedList, setSelectedList] = useState(HEADLIST);
  const [selectedHeadIndex, setSelectedHeadIndex] = useState(0);
  const [selectedSubHeadIndex, setSelectedSubHeadIndex] = useState(0);
  const [selectedHeadObjectId, setSelectedHeadObjectId] = useState("");
  const [selectedSubHeadObjectId, setSelectedSubHeadObjectId] = useState("");
  // const [previousPage, setPreviousPage] = useState("");

  const fetchUpdatedBusinessData = () => {
    getHeadWithServicesByBusinessId(businessDetails._id);
  };

  useEffect(() => {
    fetchUpdatedBusinessData();
  },[])

  const getHeads = () => {
    return heads;
  };

  const getSubHeads = (index) => {
    return heads[index].children;
  };

  const getSubHeadServices = () => {
    return heads[selectedHeadIndex].children[selectedSubHeadIndex].services;
  };

  const getHeadServices = () => {
    return heads[selectedHeadIndex].services;
  };

  const updateHeadSequence = async (updatedArray) => {
    updatedArray = updatedArray.map((ele) => {
      return { _id: ele._id };
    });
    const payload = { heads: updatedArray };
    await updateHeadSequenceByBusinessId(businessDetails._id, payload);
    fetchUpdatedBusinessData();
  };

  const updateSubheadSequence = async (updatedArray) => {
    updatedArray = updatedArray.map((ele) => {
      return { _id: ele._id };
    });
    const payload = { children: updatedArray };
    await updateSubHeadOrServiceSequence(selectedHeadObjectId, payload);
    fetchUpdatedBusinessData();
  };

  const updateSubheadServiceSequence = async (updatedArray) => {
    updatedArray = updatedArray.map((ele) => {
      return { _id: ele._id };
    });
    const payload = { services: updatedArray };
    await updateSubHeadOrServiceSequence(selectedSubHeadObjectId, payload);
    fetchUpdatedBusinessData();
  };

  const updateHeadServiceSequence = async (updatedArray) => {
    updatedArray = updatedArray.map((ele) => {
      return { _id: ele._id };
    });
    const payload = { services: updatedArray };
    await updateSubHeadOrServiceSequence(selectedHeadObjectId, payload);
    fetchUpdatedBusinessData();
  };

  switch (selectedList) {
    case HEADLIST:
      return (
        <ManageBusinessSequenceDragDrop
          key={HEADLIST}
          fetchSequence={() => getHeads()}
          setSequence={(updatedArray) => {
            updateHeadSequence(updatedArray);
          }}
          onItemClick={(index) => {
            setSelectedHeadIndex(index);
            setSelectedHeadObjectId(heads[index]._id);
            if (getSubHeads(index) && getSubHeads(index).length > 0) {
              setSelectedList(SUBHEADLIST);
            } else {
              setSelectedList(HEADSERVICELIST);
            }
          }}
          type={HEADLIST}
        />
      );
    case SUBHEADLIST:
      return (
        <ManageBusinessSequenceDragDrop
          key={SUBHEADLIST}
          fetchSequence={() => getSubHeads(selectedHeadIndex)}
          setSequence={(updatedArray) => {
            updateSubheadSequence(updatedArray);
          }}
          onItemClick={(index) => {
            setSelectedSubHeadIndex(index);
            setSelectedSubHeadObjectId(
              heads[selectedHeadIndex].children[index]._id
            );
            setSelectedList(SUBHEADSERVICELIST);
          }}
          type={SUBHEADLIST}
          onBackClick={() => setSelectedList(HEADLIST)}
        />
      );
    case SUBHEADSERVICELIST:
      return (
        <ManageBusinessSequenceDragDrop
          key={SUBHEADSERVICELIST}
          fetchSequence={() => getSubHeadServices()}
          setSequence={(updatedArray) => {
            updateSubheadServiceSequence(updatedArray);
          }}
          onItemClick={() => {}}
          type={SUBHEADSERVICELIST}
          onBackClick={() => setSelectedList(SUBHEADLIST)}
        />
      );
    case HEADSERVICELIST:
      return (
        <ManageBusinessSequenceDragDrop
          key={HEADSERVICELIST}
          fetchSequence={() => getHeadServices()}
          setSequence={(updatedArray) => {
            updateHeadServiceSequence(updatedArray);
          }}
          onItemClick={() => {}}
          type={HEADSERVICELIST}
          onBackClick={() => setSelectedList(HEADLIST)}
        />
      );
    default:
      return null;
  }
}

export default connect(null, {
  getHeadWithServicesByBusinessId,
  updateSubHeadOrServiceSequence,
  updateHeadSequenceByBusinessId,
})(ManageBusinessSequence);
