import axios from "axios";
import request from 'superagent';

export const specialityList = async () => {
    try {
        const response = await axios.get("/api/specialities");
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
};

export const xirifyEnv = async () => {
    try {
        const response = await axios.get("api/xirify/env");
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
};

export const deleteSpeciality = async (payload) => {
    // console.log("payload", payload);
    try {
        const response = await axios.delete(`/api/specialities/deleteSpeciality/${payload}`);
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
}

export const updateSpeciality = async (payload) => {
    // console.log("payload", payload);
    try {
        const response = await axios.patch(`/api/specialities/updateSpeciality/${payload._id}`, payload);
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
}

export const addSpeciality = async (payload) => {
    // console.log("payload", payload);
    try {
        const response = await axios.post(`/api/specialities/createSpeciality`, payload);
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
}
