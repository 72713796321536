import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {Close, Cancel, Delete, Edit } from '@material-ui/icons';
import { updateBusinessDetails, uploadShopActImage, updateBusiness, deleteExistingAreaManagerBusiness } from "../../../actions/businessActions";
import { getAllMasterOtp } from '../../../actions/dashboardActions';
import { getCategoryTree, getCategoriesByType} from "../../../actions/categoriesActions";
import { Table, Input, TableBody, TableCell, TableContainer, TableHead, Divider, Select, InputLabel, MenuItem, ListItemText, TableRow, List, Switch, FormControlLabel, IconButton, Avatar, Modal, Button, ButtonGroup, Dialog, DialogContent, DialogContentText, DialogTitle, CircularProgress, Backdrop, Snackbar, Chip, ListItemIcon, Checkbox, Fab, Radio, RadioGroup, Tooltip, Container, TextField, TextareaAutosize, Typography, FormControl, FormHelperText, NativeSelect, Grid, DialogActions} from '@material-ui/core';
import { getBusinessById, updateBusinessPhotos, setBusinessConfigLevel, getShopActServPro, deleteBusinessPhotos, updateBusinessVideo, deleteBusinessVideo, editBusinessVideo, clearMsg, requestBusinessDetailsRecords, requestBusinessDetailsRecordsFalse, getConsumerCount } from "../../../actions/businessActions";
import MuiAlert from '@material-ui/lab/Alert';
import { ObjectID } from 'bson';
import EditBusinessTimings from './EditBusinessTimings';
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "../../common/QRCodePDFGenerator";
import UploadPhoto from "./UploadPhoto";
import UploadBusinessCardPhotos from "./UploadBusinessCardPhotos";
import { lightBlue } from '@material-ui/core/colors';
import { fetchEnvs } from '../../../actions/authActions';
import moment from 'moment';
import { unique } from "underscore";
import defaultImage from '../../../images/DefaultImageForBusinessCard.png';
import { ReactComponent as LINK } from '../../../images/Group 33893.svg';
import { ReactComponent as PaymentFeature } from '../../../images/paymentFeature.svg';



const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
 
  snackClose: {
    padding: theme.spacing(0.5),
  },
  backdrop: {
    zIndex: 2002,
    color: "#fff",
  },
  textArea: {
    width: "500px",
  },
  lightBlue: {
    color: theme.palette.getContrastText(lightBlue[50]),
    backgroundColor: lightBlue[50],
    width: theme.spacing(4),
    height: theme.spacing(4),
    cursor: 'pointer'
  },
  icon: {
    height: 32,
    //  width: 32
  },
  mainContainer1: {
    width: '40%',
    height: '50%',
    backgroundColor: theme.palette.background.paper,
    padding: '0px 0px',

  },
  mainContainer11: {
    width: '65%',
    height: '90%',
    backgroundColor: theme.palette.background.paper,
    padding: '0px 0px',

  },
  mainContainer2: {
    width: '40%',
    height: '25%',
    backgroundColor: theme.palette.background.paper,
    padding: '0px 0px',
    // overflow: 'auto',
  },
  mainContainer3: {
    width: '60%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    margin: '10px 0px',
    overflow: 'auto',
  },
  mainContainer4: {
    width: '40%',
    height: '60%',
    backgroundColor: theme.palette.background.paper,
    padding: '0px 0px'
    // margin: '10px 0px',
    // overflow: 'auto',
  },
  header1: {
    height: '15%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  headGridTwo: {
    float: 'right',
    marginRight: '2px'
  },
  gridItem: {
    padding: "10px"
  },
  modalContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  modalContainer2: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    margin: '10px 0px',
    overflow: 'auto',
  },
  modalContainer3: {
    width: '100%',
    height: '80%',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    paddingLeft: "22px",
  },
  modalContainer4: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper  
  },
  header: {
    height: '25%',
    margin: '0px 0px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: "22px",
    backgroundColor: theme.palette.primary.main,
  },
  header3: {
    height: '7%',
    margin: '0px 0px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: "22px",
    backgroundColor: theme.palette.primary.main,
  },
  header2: {
    height: '15%',
    margin: '0px 0px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: "22px",
    backgroundColor: theme.palette.primary.main,
  },
  header4: {
    height: '17%',
    margin: '0px 0px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: "22px",
    backgroundColor: theme.palette.primary.main,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px'
  },
  modaladd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // padding: "150px",
    marginRight: "5px",
    width: "100%",
    height: "100%",
  },
  grid: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: "15px",
    paddingRight: "15px",
    margin: '0px 0px',
  },
  listItemText: {
    'margin': '0px'
  },
  select: {
    height: '38px'
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: '8px',
    backgroundColor: 'white'
  },
  cardImageContainer :{
    width: '100%',
    height: '100%',
    padding: '0px'
   }
  

});
const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF"
  }
})(Typography);

const businessPreparationTime = [
 '5','10','15','20','25','30'
]

const businessPreparationTimeMax = [
  '10','20','30','40','50','60'
 ]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function timeCheck(from, to) {
  let a = from;
  let b = to;
  let fromCheck = a.split(":");
  let toCheck = b.split(":");
  let f = new Date(parseInt("2001", 10), (parseInt("01", 10)) - 1, parseInt("01", 10), parseInt(fromCheck[0], 10), parseInt(fromCheck[1], 10));
  let t = new Date(parseInt("2001", 10), (parseInt("01", 10)) - 1, parseInt("01", 10), parseInt(toCheck[0], 10), parseInt(toCheck[1], 10));
  let df = f.valueOf();
  let dt = t.valueOf();

  if (df < dt)
    return false;
  // {console.log("b is greater");}
  else
    return true;
  // console.log("a is greater");
};

function convertTime12to24(time12h) {
  let convertedTime = moment(time12h, 'hh:mm A').format('HH:mm');
  return convertedTime;
};

function YouTubeGetID(url) {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
}

class BusinessDetailsSection extends Component {
  constructor() {
    super();
    this.anchorRef = React.createRef();
    this.state = {
      editAmenities: false,
      editDetails: false,
      addTimingModalOpen: false,
      editTimingModalOpen: false,
      fromError: [],
      toError: [],
      primaryContactNumber: "",
      pinCode: "",
      landmark: "",
      street: "",
      shopNo: "",
      gstin: "",
      description: "",
      businessContactName: "",
      businessContactNumber: "",
      businessContactNumberError: false,
      businessContactNameError: false,
      businessContactNumberValidationError: false,
      businessName: "",
      category: "",
      businessOpenTillTime: "",
      businessOpenTillTimeSave: false,
      categoryId: "",
      categoryName: "",
      categoryTypeId: "",
      categoryTypeName: "",
      amenities: "",
      FSSAILicNumber: "",
      FSSAILicNumberValidationError: false,
      fileName: "",
      shopActNo: "",
      phoneValidationError: false,
      accountManagerError: false,
      pinCodeValidationError: false,
      petPoojaRestaurantIdError: false,
      packagingChargesError: false,
      cashfreevendorIdError: false,
      businessPhotos: [],
      cataloguePhotos: [],
      holidays: [],
      workingDays: [],
      days: [
        { index: 0, day: "Monday" }, { index: 1, day: "Tuesday" }, { index: 2, day: "Wednesday" }, { index: 3, day: "Thursday" }, { index: 4, day: "Friday" }, { index: 5, day: "Saturday" }, { index: 6, day: "Sunday" }
      ],
      minutes: [],
      firstReminder: "",
      secondReminder: "",
      thirdReminder: "",
      // from: [
      //   {index: 0, from: "12:00 am"}, {index: 1, from: "01:00 am"}, {index: 2, from:"02:00 am"}, {index: 3, from:"03:00 am"}, {index: 4, from:"04:00 am"}, {index: 5, from:"05:00 am"}, {index: 6, from:"06:00 am"}, {index: 7, from:"07:00 am"}, {index: 8, from:"08:00 am"}, {index: 9, from:"09:00 am"}, {index: 10, from:"10:00 am"}, {index: 11, from:"11:00 am"}, {index: 12, from:"12:00 pm"}, {index: 13, from:"01:00 pm"}, {index: 14, from:"02:00 pm"}, {index: 15, from:"03:00 pm"}, {index: 16, from:"04:00 pm"}, {index: 17, from:"05:00 pm"}, {index: 18, from:"06:00 pm"}, {index: 19, from:"07:00 pm"}, {index: 20, from:"08:00 pm"}, {index: 21, from:"09:00 pm"}, {index: 22, from:"10:00 pm"}, {index: 23, from:"11:00 pm"}

      // ],
      from: [
        { index: 0, from: "12:00 am" }, { index: 1, from: "12:30 am" }, { index: 2, from: "01:00 am" }, { index: 3, from: "01:30 am" },
        { index: 4, from: "02:00 am" }, { index: 5, from: "02:30 am" }, { index: 6, from: "03:00 am" }, { index: 7, from: "03:30 am" },
        { index: 8, from: "04:00 am" }, { index: 9, from: "04:30 am" }, { index: 10, from: "05:00 am" }, { index: 11, from: "05:30 am" },
        { index: 12, from: "06:00 am" }, { index: 13, from: "06:30 am" }, { index: 14, from: "07:00 am" }, { index: 15, from: "07:30 am" },
        { index: 16, from: "08:00 am" }, { index: 17, from: "08:30 am" }, { index: 18, from: "09:00 am" }, { index: 19, from: "09:30 am" },
        { index: 20, from: "10:00 am" }, { index: 21, from: "10:30 am" }, { index: 22, from: "11:00 am" }, { index: 23, from: "11:30 am" },
        { index: 24, from: "12:00 pm" }, { index: 25, from: "12:30 pm" }, { index: 26, from: "01:00 pm" }, { index: 27, from: "01:30 pm" },
        { index: 28, from: "02:00 pm" }, { index: 29, from: "02:30 pm" }, { index: 30, from: "03:00 pm" }, { index: 31, from: "03:30 pm" },
        { index: 32, from: "04:00 pm" }, { index: 33, from: "04:30 pm" }, { index: 34, from: "05:00 pm" }, { index: 35, from: "05:30 pm" },
        { index: 36, from: "06:00 pm" }, { index: 37, from: "06:30 pm" }, { index: 38, from: "07:00 pm" }, { index: 39, from: "07:30 pm" },
        { index: 40, from: "08:00 pm" }, { index: 41, from: "08:30 pm" }, { index: 42, from: "09:00 pm" }, { index: 43, from: "09:30 pm" },
        { index: 44, from: "10:00 pm" }, { index: 45, from: "10:30 pm" }, { index: 46, from: "11:00 pm" }, { index: 47, from: "11:30 pm" },
      ],
      to: [
        { index: 0, from: "12:00 am" }, { index: 1, from: "12:30 am" }, { index: 2, from: "01:00 am" }, { index: 3, from: "01:30 am" },
        { index: 4, from: "02:00 am" }, { index: 5, from: "02:30 am" }, { index: 6, from: "03:00 am" }, { index: 7, from: "03:30 am" },
        { index: 8, from: "04:00 am" }, { index: 9, from: "04:30 am" }, { index: 10, from: "05:00 am" }, { index: 11, from: "05:30 am" },
        { index: 12, from: "06:00 am" }, { index: 13, from: "06:30 am" }, { index: 14, from: "07:00 am" }, { index: 15, from: "07:30 am" },
        { index: 16, from: "08:00 am" }, { index: 17, from: "08:30 am" }, { index: 18, from: "09:00 am" }, { index: 19, from: "09:30 am" },
        { index: 20, from: "10:00 am" }, { index: 21, from: "10:30 am" }, { index: 22, from: "11:00 am" }, { index: 23, from: "11:30 am" },
        { index: 24, from: "12:00 pm" }, { index: 25, from: "12:30 pm" }, { index: 26, from: "01:00 pm" }, { index: 27, from: "01:30 pm" },
        { index: 28, from: "02:00 pm" }, { index: 29, from: "02:30 pm" }, { index: 30, from: "03:00 pm" }, { index: 31, from: "03:30 pm" },
        { index: 32, from: "04:00 pm" }, { index: 33, from: "04:30 pm" }, { index: 34, from: "05:00 pm" }, { index: 35, from: "05:30 pm" },
        { index: 36, from: "06:00 pm" }, { index: 37, from: "06:30 pm" }, { index: 38, from: "07:00 pm" }, { index: 39, from: "07:30 pm" },
        { index: 40, from: "08:00 pm" }, { index: 41, from: "08:30 pm" }, { index: 42, from: "09:00 pm" }, { index: 43, from: "09:30 pm" },
        { index: 44, from: "10:00 pm" }, { index: 45, from: "10:30 pm" }, { index: 46, from: "11:00 pm" }, { index: 47, from: "11:30 pm" },
        { index: 48, from: "11:59 pm" }
      ],
      holidaysRequiredError: [],
      holidaysError: [],
      dayRequiredError: [],
      fromRequiredError: [],
      toRequiredError: [],
      invalidTime: [],
      holidayWorkingDayError: [],
      fromTimeOverlap: [],
      toTimeOverlap: [],
      imgfile: {},
      editBankDetailsModal: false,
      editPGSecretKeyModal: false,
      editExotelConfig: false,
      secretKey: "",
      appid: "",
      accountNumber: "",
      ifscCode: "",
      bankName: "",
      branchName: "",
      accountHolderName: "",
      error: {},
      longitude: "",
      latitude: "",
      longitudeError: false,
      latitudeError: false,
      businessVideo: "",
      businessVideos: [],
      businessVideoError: false,
      businessVideoErrorExists: false,
      businessVideoErrorEditExists: false,
      selectedBusinessVideoLink: null,
      selectedBusinessVideoLinkEdit: null,
      modalDeleteOpen: false,
      modalEditOpen: false,
      modalClose: false,
      businessVideoLinkEdit: "",
      firstMintesArray: [],
      snackOpen: false,
      isPetpoojaEnabled: false,
      isPackagingChargesEnabled: false,
      petPoojaRestaurantId: "",
      businessPackagingCharges: "",
      isCashfreeEnabled: false,
      cashfreeVendorId: "",
      firstRequired: false,
      secondRequired: false,
      thirdRequired: false,
      selectedAccountManager: "",
      areaId: "",
      regionId: "",
      specialityList: [],
      selectedSpeciality: [],
      cuisineList: [],
      selectedCuisine: [],
      exotelContactNumbers:[],
      exotelContactNumbersError:false,
      exotelContactNumbersErrorExists:false,
      exotelContactNumbersInvalidError:false,
      exotelTextField : "",
      exotelContactNumberslengthError : false,
      businessPreparationTime :null,
      businessPreparationTimeMax:null,
      orderPreparationTimerModel : false,
      orderPreparationTimerMaxModel : false,
      exotelThirdDelete:true,
      exotelSecondDelete:false,
      exotelSecondAdd:false,
      exotelFirstAdd:false,
      exotelTwoError:false,
      exotelThreeError:false,
      upiId : "",
      accountNumberError: false,
      ifscCodeError: false,
      bankNameError: false,
      branchNameError: false,
      accountHolderNameError: false,
      accountNumberValidationError: false,
      ifscCodeValidationError: false,
      bankNameValidationError: false,
      branchNameValidationError: false,
      accountHolderNameValidationError: false,
      upiIdError: false,
      isBankAccountEnabled: false,
      isUpiEnabled: false ,
      upiIdValidationError: false,
      radius: null,
      radiusError: false,
      isTypeOrderEnabled: false,
      consumerModalOpen: false,
      currentConsumerCount: null,
      currentConsumerCount: null,
      businessRadius: null,     
      calculatedConsumerCountModalOpen: false,
      isBusinessPreparationTimerEnabled: false, 
      businessNote:"",
      businessNoteSave:false,
      selectedBusinessNote:"",
      isEditBusinessNoteFlag:false,
      isAddBusinessNoteFlag:true,
      businessNoteError:"",
      businessTextFieldFlag:false,
      businessNoteSaveFlag:false,
      paymentFeatureFilter : "",
      openPopper: false,
      allowCancelOrders: false
      };
  }
  componentDidUpdate(prevProps) {
    for (let i = 1; i < 60; i++) {
      this.state.minutes.push(i)
    }

    for (let i = 0; i < 60; i++) {
      this.state.firstMintesArray.push(i)
    }
    let payload = {
      businessId: this.props.businessDetails._id,
    };
    if ((this.props && this.props.businessDetails && this.props.businessDetails._id) && (prevProps && prevProps.businessDetails && prevProps.businessDetails._id && prevProps.businessDetails._id.toString() !== this.props.businessDetails._id.toString())) {
      // let payload = {
      //   businessId: this.props.businessDetails._id,
      // };
      this.props.getBusinessById(payload);
    }

    if (this.props.business.sucessMsg) {
      this.setState({ sucessMsg: this.props.business.sucessMsg })
      this.setState({ snackOpen: true });
      this.setState({ modalEditOpen: false, modalDeleteOpen: false });
      this.props.clearMsg();
      this.props.getBusinessById(payload);
    } else if (this.props.business.errorMsg) {
      this.setState({ errorMsg: this.props.business.errorMsg })
      this.setState({ snackOpen: true });
      this.props.clearMsg();
    }

    if (prevProps.cuisineDetails != this.props.cuisineDetails ||
      prevProps.specialityDetails != this.props.specialityDetails) {
      this.getSpecialityCuisineList();
    }
  }
  // secretKey,appid,accountNumber,ifscCode,bankName,branchName,accountHolderName
  async componentDidMount() {   
    let category = {};
    category.type = "category";
    category._id = this.props.businessDetails.categoryType._id;
    this.props.getCategoryTree(category);
    this.setState({      
      businessPhotos: this.props.businessDetails.photos ? this.props.businessDetails.photos : [],
      cataloguePhotos: this.props.businessDetails.cataloguePhotos ? this.props.businessDetails.cataloguePhotos : [],
      businessVideos: this.props.businessDetails.businessVideoLinks ? this.props.businessDetails.businessVideoLinks : [],
     exotelContactNumbers : this.props.businessDetails.exotelContactNumbers ? this.props.businessDetails.exotelContactNumbers:[]
    });
    this.props.getCategoriesByType("category");
    this.setState({ amenities: this.props.businessDetails.amenities });
    this.setState({ FSSAILicNumber: this.props.businessDetails.FSSAILicNumber ? this.props.businessDetails.FSSAILicNumber.trim() : "" });
    this.setState({ businessOpenTillTime: this.props.businessDetails.businessOpenTillTime });
    this.setState({ businessOpenTillTimeSave: this.props.businessDetails.businessOpenTillTime ? true : false })
    this.setState({businessNote:this.state.businessNote ? this.state.businessNote:this.props.businessDetails.businessNote ? this.props.businessDetails.businessNote : ""})
    this.setState({businessNoteSave:this.props.businessDetails.businessNote ? true :false})
    await this.props.getAllMasterOtp();
    if (this.props.business && this.props.business.updatedBusinessData && 'exotelConfig' in this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig && this.props.business.updatedBusinessData.exotelConfig.length > 0) {
      this.setState({
        firstReminder: this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig[0] &&this.props.business.updatedBusinessData.exotelConfig[0].minute ?  this.props.business.updatedBusinessData.exotelConfig[0].minute :null,
        secondReminder:this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig[1] &&this.props.business.updatedBusinessData.exotelConfig[1].minute ?  this.props.business.updatedBusinessData.exotelConfig[1].minute :null,
        thirdReminder: this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig[2] &&this.props.business.updatedBusinessData.exotelConfig[2].minute ?  this.props.business.updatedBusinessData.exotelConfig[2].minute :null,
        exotelThirdDelete: this.props.business.updatedBusinessData &&  this.props.business.updatedBusinessData.exotelConfig && this.props.business.updatedBusinessData.exotelConfig[2] &&this.props.business.updatedBusinessData.exotelConfig[2].minute ?  true :false,
        exotelSecondDelete: this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig[1] && this.props.business.updatedBusinessData.exotelConfig[1].minute ?  true :false,
        exotelSecondAdd: this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig[1] && this.props.business.updatedBusinessData.exotelConfig[1].minute ?  true :false,
        exotelFirstAdd: this.props.business.updatedBusinessData && !this.props.business.updatedBusinessData.exotelConfig[1] && !this.props.business.updatedBusinessData.exotelConfig[2] ?  true :false,
      })
    } else if (this.props && this.props.dashboardDetails && 'data' in this.props.dashboardDetails && this.props?.dashboardDetails?.data.length > 0) {
      this.setState({
        firstReminder: this.props?.dashboardDetails?.data[0]?.exotelConfig[0]?.minute,
        secondReminder: this.props?.dashboardDetails?.data[0]?.exotelConfig[1]?.minute,
        thirdReminder: this.props?.dashboardDetails?.data[0]?.exotelConfig[2]?.minute,
        exotelThirdDelete: this.props?.dashboardDetails?.data[0]?.exotelConfig[2]?.minute ?  true :false,
        exotelSecondDelete: this.props?.dashboardDetails?.data[0]?.exotelConfig[1]?.minute ?  true :false,
        exotelSecondAdd: this.props?.dashboardDetails?.data[0]?.exotelConfig[1]?.minute ?  true :false,
        exotelFirstAdd: !this.props?.dashboardDetails?.data[0]?.exotelConfig[1] && !this.props?.dashboardDetails?.data[0]?.exotelConfig[2] ?  true :false,
      })

    }
    let payload = {
      businessId: this.props.businessDetails._id
    };
    this.props.requestBusinessDetailsRecords();
    this.setData();
    let accountManagerCall = [];
    if (this.props.dashboard.setSelectedBusiness.accountManagerDetails && this.props.dashboard.setSelectedBusiness.accountManagerDetails.length > 0) {
      accountManagerCall.push(this.props.dashboard.setSelectedBusiness.accountManagerDetails && this.props.dashboard.setSelectedBusiness.accountManagerDetails.length > 0 ? this.props.dashboard.setSelectedBusiness.accountManagerDetails[this.props.dashboard.setSelectedBusiness.accountManagerDetails.length - 1] : "")


      this.setState({ selectedAccountManager: accountManagerCall[0].accountManager ? accountManagerCall[0].accountManager : "" })
    }
    else if (this.props.dashboard.setSelectedBusiness.accountManagerDetails) {

      this.setState({ selectedAccountManager: this.props.dashboard.setSelectedBusiness.accountManagerDetails.accountManager ? this.props.dashboard.setSelectedBusiness.accountManagerDetails.accountManager : "" })
    }

    if(this.props.businessDetails && this.props.businessDetails.isUpiEnabled ===false && ((this.state.isBankAccountEnabled === "isBankAccountEnabled===true")  || this.props.businessDetails.isBankAccountEnabled === false) ){  
      this.setState({isBankAccountEnabled: 'isBankAccountEnabled===true'});
      this.setState({isUpiEnabled: false})
     } else if((this.props.businessDetails.isBankAccountEnabled === true && this.props.businessDetails.isUpiEnabled === false)){
      this.setState({isBankAccountEnabled: 'isBankAccountEnabled===true'});
      this.setState({isUpiEnabled: false})
     }else if((this.props.businessDetails.isBankAccountEnabled === false && this.props.businessDetails.isUpiEnabled === false)){
      this.setState({isBankAccountEnabled: 'isBankAccountEnabled===true'});
      this.setState({isUpiEnabled: false})
     }else{
      this.setState({isBankAccountEnabled: "isUpiEnabled===true"})
      this.setState({isUpiEnabled: this.props.businessDetails.isUpiEnabled})
     }

    // console.log("currentAccountManagerCall---->", accountManagerCall, "  ", accountManagerCall.length)
    // if(this.props.businessDetails.businessPreparationTime){
      // this.setState({businessPreparationTime:this.props.businessDetails.businessPreparationTime ? this.props.businessDetails.businessPreparationTime : 5})
      // this.setState({businessPreparationTime:this.props.businessDetails.businessPreparationTime ? this.props.businessDetails.businessPreparationTime : null})
    // }

    if(this.props.businessDetails.isBusinessPreparationTimerEnabled === true){
      // console.log("toggle is on")
      this.setState({businessPreparationTime:this.props.businessDetails.businessPreparationTime ? this.props.businessDetails.businessPreparationTime : 5})
    }

    if(this.props.businessDetails.businessPreparationTimeMax){
      this.setState({businessPreparationTimeMax: this.props.businessDetails.businessPreparationTimeMax ? this.props.businessDetails.businessPreparationTimeMax : null})
    }


    //  console.log("...CDM...payload.businessId..",payload);
    await this.props.getBusinessById(payload);

    this.props.requestBusinessDetailsRecordsFalse();

    this.getSpecialityCuisineList();
  }

  getSpecialityCuisineList() {
    // if (this.props.businessDetails && this.props.businessDetails.specialityIds && this.props.businessDetails.specialityIds.length > 0) {
    //   let specialityArr = [];
    //   if(this.props.specialityDetails && this.props.specialityDetails.length > 0) {
    //     specialityArr = this.props.specialityDetails.filter((speciality)=>this.props.businessDetails.specialityIds.includes(speciality._id))
    //   }

    //   console.log("specialityArr", specialityArr);
    //   this.setState({ selectedSpeciality: specialityArr });
    // }

    // if (this.props.businessDetails && this.props.businessDetails.cuisineIds && this.props.businessDetails.cuisineIds.length > 0) {
    //   let cuisineArr = (this.props.businessDetails.cuisineIds && this.props.businessDetails.cuisineIds.length > 0) ? this.props.businessDetails.cuisineIds.map((i) => i.name) : [];
    //   this.setState({ selectedCuisine: cuisineArr });
    // }
    let specialityArr = [];
    let nonSelectedSpecialities = []
    let specialityArrNames = [];
    if (this.props.businessDetails && this.props.businessDetails.specialityIds && this.props.businessDetails.specialityIds.length > 0) {
      if (this.props.specialityDetails && this.props.specialityDetails.length > 0) {
        specialityArr = this.props.specialityDetails.filter((speciality) => this.props.businessDetails.specialityIds.includes(speciality._id));
        nonSelectedSpecialities = this.props.specialityDetails.filter((speciality) => !this.props.businessDetails.specialityIds.includes(speciality._id));
      }

      specialityArrNames = specialityArr.map((item) => item.name);

    } else {
      if (this.props.specialityDetails && this.props.specialityDetails.length > 0) {
        nonSelectedSpecialities = this.props.specialityDetails;
      }
    }
    this.setState({ selectedSpeciality: specialityArrNames, specialityList: [...specialityArr, ...nonSelectedSpecialities] });

    let cuisineArr = [];
    let nonSelectedCuisine = [];
    let cuisineArrNames = [];

    if (this.props.businessDetails && this.props.businessDetails.cuisineIds && this.props.businessDetails.cuisineIds.length > 0) {
      if (this.props.cuisineDetails && this.props.cuisineDetails.length > 0) {
        cuisineArr = this.props.cuisineDetails.filter((cuisine) => this.props.businessDetails.cuisineIds.includes(cuisine._id));
        nonSelectedCuisine = this.props.cuisineDetails.filter((cuisine) => !this.props.businessDetails.cuisineIds.includes(cuisine._id));
      }
      cuisineArrNames = cuisineArr.map((item) => item.name);

      // console.log("specialityArr", cuisineArr);
    } else {
      if (this.props.cuisineDetails && this.props.cuisineDetails.length > 0) {

        nonSelectedCuisine = this.props.cuisineDetails;
      }
    }

    this.setState({ selectedCuisine: cuisineArrNames, cuisineList: [...cuisineArr, ...nonSelectedCuisine] });

  }



updateBusinessCardImages = async (files, uploadedPhotoType) => {
  // console.log("files updateBusinessPhotos --->", files, uploadedPhotoType)
  let data = new FormData();
  let folderPath = "Marketplace_Business";
  if (!files.length) {
    // console.log('No files found to upload');
    return;
  }
  let temp = files[0].name.split(".");
  let fileName = temp[0] + Math.ceil(Math.random() * 999999) + "." + temp[1];

  data.append("file", files[0]);
  data.append("folder", folderPath);
  data.append(
    "public_id",
    `${this.props.businessDetails._id}/${fileName}`
  );
  data.append(
    "upload_preset",
    this.props.env.cloudinaryBusinessImagesUploadPreset
  );

  let res = await fetch(this.props.env.cloudinaryUploadUrl, {
    method: "POST",
    body: data,
  });

  let file = await res.json();

  ['Default', 'Veg', 'NonVeg'].forEach( foodType => {
    if(uploadedPhotoType === foodType) {
      this.updatePhotos(uploadedPhotoType, file);
    }
  });
  
};

updatePhotos = async(foodType, file) =>{ // default, veg, nonveg
  let payload = {};
  let foundBussImg = [];
  if (this.props.businessDetails.businessCardImages && this.props.businessDetails.businessCardImages.length > 0) {
      // console.log("In update business photos if condition with foodtype", foodType)
      foundBussImg = this.props.businessDetails.businessCardImages.find((ele) => {
        return ele.foodType === foodType;
      })

      if (foundBussImg) {
        foundBussImg.foodType = foodType;
        foundBussImg.path = file.secure_url;
        foundBussImg.public_id = file.public_id;
        this.props.businessDetails.businessCardImages.push({ foundBussImg });


        // console.log("foundBussImg", foundBussImg)
      }
      else if (this.props.businessDetails.businessCardImages && this.props.businessDetails.businessCardImages.length == 0 || !foundBussImg) {
        payload = {
          businessId: this.props.businessDetails._id,
          action: "uploaded",
          data: {
            foodType: foodType,
            path: file.secure_url,
            public_id: file.public_id,
      
          },
        }
        await this.props.updateBusinessPhotos(payload);
        this.props.getBusinessById(payload);
      }
      //  else {
      //   console.log("IN first default else")
      //   this.props.businessDetails.businessCardImages.push({
      //     foodType: foodType,
      //     path: file.secure_url,
      //     public_id: file.public_id,
      //   })
      // }      
      }
  
  payload = {
    businessId: this.props.businessDetails._id,
    action: "uploaded",
    data: {
      foodType: foodType,
      path: file.secure_url,
      public_id: file.public_id,

    },
  }
  await this.props.updateBusinessPhotos(payload);
  this.props.getBusinessById(payload);
}

updateBusinessPhotos = async (files, photoType) => {
    // console.log("files updateBusinessPhotos --->",files,photoType)
    let data = new FormData();
    let folderPath = "Marketplace_Business";
    let temp = files[0].name.split(".");
    let fileName = temp[0] + Math.ceil(Math.random() * 999999) + "." + temp[1];

    data.append("file", files[0]);
    data.append("folder", folderPath);
    data.append(
      "public_id",
      `${this.props.businessDetails._id}/${fileName}`
    );
    data.append(
      "upload_preset",
      this.props.env.cloudinaryBusinessImagesUploadPreset
    );

    let res = await fetch(this.props.env.cloudinaryUploadUrl, {
      method: "POST",
      body: data,
    });

    let file = await res.json();

    let payload = {};
    if (photoType === "Business") {
      this.props.businessDetails.photos.push({
        title: "",
        path: file.secure_url,
        public_id: file.public_id,
      });

      this.setState({
        businessPhotos: this.props.businessDetails.photos,
      });

      payload = {
        businessId: this.props.businessDetails._id,
        action: "uploaded",
        data: {
          $push: {
            photos: {
              title: "",
              path: file.secure_url,
              public_id: file.public_id,
            },
          },
        },
      };
    }
    if (photoType === "Catalogue") {
      if (this.props.businessDetails.cataloguePhotos) {
        this.props.businessDetails.cataloguePhotos.push({
          title: "",
          path: file.secure_url,
          public_id: file.public_id,
        });
      } else {
        let cataloguePhotos = [];
        cataloguePhotos.push({
          title: "",
          path: file.secure_url,
          public_id: file.public_id,
        });

        this.props.businessDetails.cataloguePhotos = cataloguePhotos;
      }

      this.setState({
        cataloguePhotos: this.props.businessDetails.cataloguePhotos,
      });

      payload = {
        businessId: this.props.businessDetails._id,
        action: "uploaded",
        data: {
          $push: {
            cataloguePhotos: {
              title: "",
              path: file.secure_url,
              public_id: file.public_id,
            },
          },
        },
      };
    }

    this.props.updateBusinessPhotos(payload);
    this.props.getBusinessById(payload);
};
deleteBusinessCardImage = async (selectedPhoto, photoType) => {
  let payload = {};
  let foundDeleteBussImg;
  if (this.props.businessDetails.businessCardImages && this.props.businessDetails.businessCardImages.length > 0) {
    foundDeleteBussImg = this.props.businessDetails.businessCardImages.find((ele) => {
      return ele.photoType === photoType;
    })
    if (foundDeleteBussImg) {
      foundDeleteBussImg.path = selectedPhoto.path;      
    }
}
payload = {
  businessId: this.props.businessDetails._id,
  action: "deleted",
  data: {   
    path: selectedPhoto.path,
    _id: selectedPhoto._id,
    public_id:selectedPhoto.public_id   

  },
}
// this.setState({
//   businessCardImages: this.props.businessDetails.businessCardImages  
// });
await this.props.updateBusinessPhotos(payload);
//this.props.requestBusinessDetailsRecords();
this.props.getBusinessById(payload);
//this.props.requestBusinessDetailsRecordsFalse();
};


  deleteBusinessPhoto = async (selectedPhoto, photoType) => {
    let payload = {};

    if (photoType === "Business") {
      this.props.businessDetails.photos = this.props.businessDetails.photos.filter(
        (photo) => photo.path !== selectedPhoto.path
      );

      payload = {
        businessId: this.props.businessDetails._id,
        action: "deleted",
        data: {
          $pull: {
            photos: {
              path: selectedPhoto.path,
            },
          },
        },
      };
    }

    if (photoType === "Catalogue") {
      this.props.businessDetails.cataloguePhotos = this.props.businessDetails.cataloguePhotos.filter(
        (photo) => photo.path !== selectedPhoto.path
      );

      payload = {
        businessId: this.props.businessDetails._id,
        action: "deleted",
        data: {
          $pull: {
            cataloguePhotos: {
              path: selectedPhoto.path,
            },
          },
        },
      };
    }

    this.props.updateBusinessPhotos(payload);
    this.setState({
      businessPhotos: this.props.businessDetails.photos,
      cataloguePhotos: this.props.businessDetails.cataloguePhotos,
    });
    payload = {
      businessId: this.props.businessDetails._id,
      public_id: selectedPhoto.public_id
    };
    this.props.deleteBusinessPhotos(payload);
  };
  convertTime12to24(time12h) {
    let convertedTime = moment(time12h, 'hh:mm A').format('HH:mm');
    return convertedTime;
  };

  updateBusinessVideo = async (data) => {
    let hasError = false;
    if (!this.state.businessVideo || data.trim().length == 0) {
      this.setState({ businessVideoError: true });
      hasError = true;
    }
    if (this.props.businessDetails.businessVideoLinks && this.props.businessDetails.businessVideoLinks.length > 0) {
      this.props.businessDetails.businessVideoLinks.forEach(link => {
        if (link.path.trim() === data.trim()) { this.setState({ businessVideoErrorExists: true }); hasError = true; }
      })
    }
    if (!hasError) {
      this.setState({ businessVideos: this.props.businessDetails.businessVideoLinks ? this.props.businessDetails.businessVideoLinks : [] })
      let linkPath = this.state.businessVideo.trim();
      let youTubeVideoId = YouTubeGetID(linkPath);
      let payload = {
        businessId: this.props.businessDetails._id,
        action: "uploaded",
        data: {
          $push: {
            businessVideoLinks: {
              bussID: this.props.businessDetails.businessId,
              // path: this.state.businessVideo
              path: linkPath,
              videoId: youTubeVideoId
            }
          }

        }
      }
      this.setState({ businessVideo: "" });
      await this.props.updateBusinessVideo(payload);
      await this.props.getBusinessById(payload);

    }
  };


  updateExotelNumber = async(data) =>{

    let hasError = false;
    if (!this.state.exotelTextField) {
      this.setState({ exotelContactNumbersError: true});
      hasError = true;
    }

    if ((this.state.exotelTextField.length != 10 && this.state.exotelTextField.length != 0)) {
      this.setState({ exotelContactNumbersInvalidError:true,});
      hasError = true;
    }
    
   
     if(this.state.exotelTextField &&  this.state.exotelContactNumbers.length > 0){
      this.state.exotelContactNumbers.map((i)=>{
        if(i ==this.state.exotelTextField){
          this.setState({ exotelContactNumbersErrorExists: true });
          hasError = true;
        }
      })
    
     
     }
   
    if(this.state.exotelContactNumbers.length >= 5){
      this.setState({exotelContactNumberslengthError :true})
      hasError = true;
    }
    if (!hasError) {
     
     
      this.setState({exotelContactNumbers:[...this.state.exotelContactNumbers,this.state.exotelTextField]})
      
     
      let payload = {
        businessId: this.props.businessDetails._id,
      
        exotelContactNumbers:this.state.exotelContactNumbers
       
      }
     
      // await this.updateExotelNumber(payload);
      this.setState({ exotelTextField: "" });
      await this.props.getBusinessById(payload);
       

    }
  };

  handleDeleteOpen = (event, row) => {
    this.setState({ modalDeleteOpen: true });
    this.setState({ selectedBusinessVideoLink: row });
  };

  handleEditOpen = (event, row) => {
    this.setState({ modalEditOpen: true });
    this.setState({ selectedBusinessVideoLinkEdit: row });
  };

  handleDeleteClose = () => {
    this.setState({ modalDeleteOpen: false });
  };

  handleLinkClose = () => {
    this.setState({ modalEditOpen: false, selectedBusinessVideoLinkEdit: [], businessVideoLinkEdit: "" });
  };

  deleteBusinessVideo = async (selectedVideo) => {
    let payload = {}
    this.props.businessDetails.businessVideoLinks = this.props.businessDetails.businessVideoLinks.filter(
      (link) => link._id !== selectedVideo._id
    );

    payload = {
      businessId: this.props.businessDetails._id,
      action: "deleted",
      data: {
        $pull: {
          businessVideoLinks: {
            _id: selectedVideo._id,
          },
        },
      },
    };

    await this.props.updateBusinessVideo(payload);
    this.setState({
      businessVideos: this.props.businessDetails.businessVideoLinks,
      modalDeleteOpen: false
    });
    payload = {
      businessId: this.props.businessDetails._id
    };
    await this.props.deleteBusinessVideo(payload);
    await this.props.getBusinessById(payload);
  };

  handleBusinessVideoLinkChange = (event) => {
    this.setState({
      businessVideoLinkEdit: event.target.value,
      businessVideoErrorEditExists: false,
    })
  };

  onEditBusinessVideoLink = async () => {
    let hasError = false;

    if (this.props.businessDetails.businessVideoLinks && this.props.businessDetails.businessVideoLinks.length > 0) {
      this.props.businessDetails.businessVideoLinks.forEach(link => {
        if (link.path.trim() === this.state.businessVideoLinkEdit.trim()) { this.setState({ businessVideoErrorEditExists: true }); hasError = true; }
      })
    }

    if (!hasError) {
      let linksArr = [];
      linksArr = this.props.businessDetails.businessVideoLinks;
      let videoLink = this.state.businessVideoLinkEdit.trim();
      let youTubeVideoId = YouTubeGetID(videoLink);

      if (linksArr.length > 0) {
        for (let i = 0; i < linksArr.length; i++) {
          if (linksArr[i]._id === this.state.selectedBusinessVideoLinkEdit._id) {
            // linksArr[i].path = this.state.businessVideoLinkEdit;
            linksArr[i].path = videoLink;
            linksArr[i].videoId = youTubeVideoId;
          }
        }
      }

      let payload = {
        businessId: this.props.businessDetails._id,
        // newPath: this.state.businessVideoLinkEdit,
        newPath: videoLink,
        // data:{ 
        //   _id: this.state.selectedBusinessVideoLinkEdit._id,
        //   path: this.state.selectedBusinessVideoLinkEdit.path
        // }
        data: linksArr,
      }
      this.setState({ modalEditOpen: false, selectedBusinessVideoLinkEdit: [], businessVideoLinkEdit: "" });
      await this.props.editBusinessVideo(payload);
      await this.props.getBusinessById(payload);
    }
  };

  handleSnackClose = async () => {
    let payload = {
      businessId: this.props.businessDetails._id
    }
    await this.props.getBusinessById(payload);
    this.setState({ snackOpen: false });
    this.setState({ modalDeleteOpen: false });
    this.setState({ sucessMsg: null });
    this.setState({ errorMsg: null, modalEditOpen: false });
  };

  findcategory = async (input) => {
    let category = await this.props.categories.categoriesByType.find((element) => element._id.toString() == input);
    this.setState({
      categoryId: category._id,
      categoryName: category.name,
      categoryTypeId: category.parentId._id,
      categoryTypeName: category.parentId.name
    });
  }
  handleDropdownChange = async (event) => {
    this.findcategory(event.target.value);
  };
  handleDeleteOrderPreparationTime =  () =>{
    this.setState({orderPreparationTimerModel:false,businessPreparationTime:this.props && this.props.businessDetails && this.props.businessDetails.businessPreparationTime ?this.props.businessDetails.businessPreparationTime : null })
 }

 handleDeleteOrderPreparationTimeMax =  () =>{
  this.setState({orderPreparationTimerMaxModel:false,businessPreparationTimeMax:this.props && this.props.businessDetails && this.props.businessDetails.businessPreparationTimeMax ?this.props.businessDetails.businessPreparationTimeMax : null })
}
 

  handleAccountManagerChange = async (event) => {
    this.setState({ selectedAccountManager: event.target.value });
    this.setState({ error: {}, accountManagerError: false })
    // console.log("selectedAccountManagerli-------->", this.state.selectedAccountManager)
    // this.setState({ selectedAccountManager: event.target.value });
  };
  handleChange = (event) => {
    if (event.target.name === "latitude") { this.setState({ [event.target.name]: event.target.value, error: {}, latitudeError: false }); }
    if (event.target.name === "longitude") { this.setState({ [event.target.name]: event.target.value, error: {}, longitudeError: false }); }
    if (event.target.name === "FSSAILicNumber") { this.setState({ [event.target.name]: event.target.value, error: {}, FSSAILicNumberValidationError: false }); }
    if (event.target.name === "pinCode") { this.setState({ [event.target.name]: event.target.value, error: {}, pinCodeValidationError: false }); }
    if (event.target.name === "primaryContactNumber") { this.setState({ [event.target.name]: event.target.value, error: {}, phoneValidationError: false }); }
    if (event.target.name === "businessContactName") { this.setState({ [event.target.name]: event.target.value, error: {}, businessContactNameError: false }); }
    if (event.target.name === "businessContactNumber") { this.setState({ [event.target.name]: event.target.value, error: {}, businessContactNumberError: false, businessContactNumberValidationError: false }); }
    if (event.target.name === "petPoojaRestaurantId") { this.setState({ [event.target.name]: event.target.value, error: {}, petPoojaRestaurantIdError: false }); }
    if (event.target.name === "businessPackagingCharges") { this.setState({ [event.target.name]: event.target.value, error: {}, packagingChargesError: false }); }
    if (event.target.name === "cashfreeVendorId") { this.setState({ [event.target.name]: event.target.value, error: {}, cashfreeVendorIdError: false }); }
    if (event.target.name === "exotelTextField") { 
      
      this.setState({ [event.target.name]: event.target.value, error: {}, exotelContactNumbersError: false,exotelContactNumbersErrorExists:false,exotelContactNumbersInvalidError:false}); 
    }
    if (event.target.name === "businessPreparationTime") { 
      this.setState({ [event.target.name]: event.target.value, error: {},  orderPreparationTimerModel:true , minTimerError: false, maxTimerError: false}); 
    }
    if (event.target.name === "businessPreparationTimeMax") { 
      this.setState({ [event.target.name]: event.target.value, error: {}, orderPreparationTimerMaxModel: true, maxTimerError: false, minTimerError: false }); 
    }
    if (event.target.name === "accountNumber") { this.setState({ [event.target.name]: event.target.value, accountNumberError: false, accountNumberValidationError: false }); }
		if (event.target.name === "ifscCode") { this.setState({ [event.target.name]: event.target.value, ifscCodeError: false, ifscCodeValidationError: false }); }
		if (event.target.name === "bankName") { this.setState({ [event.target.name]: event.target.value, bankNameError: false, bankNameValidationError: false }); }
		if (event.target.name === "branchName") { this.setState({ [event.target.name]: event.target.value, branchNameError: false, branchNameValidationError: false }); }
		if (event.target.name === "accountHolderName") { this.setState({ [event.target.name]: event.target.value, accountHolderNameError: false, accountHolderNameValidationError: false  }); }
		if (event.target.name === "upiId") { this.setState({ [event.target.name]: event.target.value, upiIdError: false, upiIdValidationError: false  }); }
    if (event.target.name === "radius") { this.setState({ radius: Number(event.target.value), error: {}, radiusError: false});
    // console.log("event.target.name---->", event.target.name);
    // console.log("event.target.value---->", event.target.value);
    }
    

    this.setState({
      [event.target.name]: event.target.value,
      // error: {},
      // longitudeError: false,
      // latitudeError: false, 
      // FSSAILicNumberValidationError: false,
      businessVideoError: false,
      businessVideoErrorExists: false,
      businessVideoErrorEditExists: false,
      // businessContactNameError: false,
      // businessContactNumberError: false,
      // businessContactNumberValidationError: false,
    });
  };


  handlePetPoojaEnableChange = (e) => {
    if (e.target.checked == false) {
      this.setState({ isPetpoojaEnabled: false, petPoojaRestaurantId: null });
    } else {
      this.setState({ isPetpoojaEnabled: true });
    }
  }


  handlePackagingChargesEnabledChange = (e) => {
    if (e.target.checked == false) {
      this.setState({ isPackagingChargesEnabled: false, businessPackagingCharges: null });
    } else {
      this.setState({ isPackagingChargesEnabled: true });
    }
  }

  handleCashfreeEnableChange = (e) => {
    if (e.target.checked == false) {
      this.setState({ isCashfreeEnabled: false, cashfreeVendorId: null });
    } else {
      this.setState({ isCashfreeEnabled: true });
    }
  }

  handleFileUpload = (e) => {
    this.setState({ imgfile: e.target.files[0] });
    let splitArray = e.target.files[0].name.split(".");
    let fileExtention = splitArray[splitArray.length - 1];
    let timeStamp = Date.now();
    let fileName = `${this.props.businessDetails.ownerId._id}-${timeStamp}.${fileExtention}`;
    this.setState({ fileName: fileName })
  }

  handleOrderPreparationTimer = async (event, data) => {
    this.props.requestBusinessDetailsRecords();
    let payload = {
      businessId: this.props.businessDetails._id,
      data: {
        isBusinessPreparationTimerEnabled: event.target.checked,
        businessPreparationTime: this.props.businessPreparationTime  != null ? this.props.businessPreparationTime : this.state.businessPreparationTime ? this.state.businessPreparationTime : 5
      }
    }
   
   this.setState({businessPreparationTime:5,isBusinessPreparationTimerEnabled: true})

   if(event.target.checked === false) {
    payload.data.businessPreparationTime = null;
    payload.data.businessPreparationTimeMax = null;
    this.setState({ businessPreparationTime: null, businessPreparationTimeMax: null, maxTimerError:false,isBusinessPreparationTimerEnabled: false})
  } else {
    await this.handleOrderPreparationTime(data);
  }
  //  this.setState({businessPreparationTimeMax:60})
    
    await this.props.updateBusiness(payload);
    this.getBusiness(); 
    this.props.requestBusinessDetailsRecordsFalse();

  };
  handleOrderPreparationTime = async (data) => {
    this.props.requestBusinessDetailsRecords();
    let hasError = false;

    if(this.state.businessPreparationTime && this.state.businessPreparationTime !== null && this.state.businessPreparationTime !== undefined && this.state.businessPreparationTimeMax && this.state.businessPreparationTimeMax !== undefined && this.state.businessPreparationTimeMax !== null && parseInt(this.state.businessPreparationTimeMax) < this.state.businessPreparationTime) {
      hasError = true;
      this.setState({minTimerError: true, orderPreparationTimerModel: false});
    }

    if(!hasError){
      let payload = {
        businessId: this.props.businessDetails._id,
        data: {
          // businessPreparationTime: this.state.businessPreparationTime ? this.state.businessPreparationTime :this.props.businessPreparationTime ? this.props.businessPreparationTime :5  
          businessPreparationTime: this.state.businessPreparationTime ? this.state.businessPreparationTime : null  
        }
      }
      await this.props.updateBusiness(payload);
      this.props.requestBusinessDetailsRecordsFalse();
      this.setState({orderPreparationTimerModel:false});
      this.getBusiness();  
    }
  };
  handleOrderPreparationTimeMax = async (event,data) => {
    this.props.requestBusinessDetailsRecords();
    // console.log(this.state);
    let hasError = false;

    if(this.state.businessPreparationTime && this.state.businessPreparationTime !== null && this.state.businessPreparationTime !== undefined && this.state.businessPreparationTimeMax && this.state.businessPreparationTimeMax !== undefined && this.state.businessPreparationTimeMax !== null && parseInt(this.state.businessPreparationTimeMax) < this.state.businessPreparationTime) {
      hasError = true;
      this.setState({maxTimerError: true, orderPreparationTimerMaxModel: false});

    }

    if(!hasError){
      let payload = {
        businessId: this.props.businessDetails._id,
        data: {
          businessPreparationTimeMax: this.state.businessPreparationTimeMax ? this.state.businessPreparationTimeMax  : null  
        }
      }
     
      await this.props.updateBusiness(payload);
      this.props.requestBusinessDetailsRecordsFalse();
      this.setState({orderPreparationTimerMaxModel:false});
      this.getBusiness();
    }
  };

  handlePaymentChange = async (event,data) => {
    // console.log('event.target.value',event.target.value)
    this.state.isBankAccountEnabled = event.target.value
    this.props.requestBusinessDetailsRecords();
    if((event.target.value) === 'isBankAccountEnabled===true'){  
      this.state.isBankAccountEnabled = event.target.value
      this.state.isUpiEnabled = false
    }
    else
    {
      this.state.isBankAccountEnabled = event.target.value
      this.state.isUpiEnabled = true    
    } 
    this.props.requestBusinessDetailsRecordsFalse();
  }

  handleTypeOrders = async (event, data) => {
    let payload = {
      businessId: this.props.businessDetails._id,
      data: {
        isTypeOrderEnabled: event.target.checked,
      }
    }
    // console.log("Type Order payload",payload)
    await this.props.updateBusiness(payload);
    this.getBusiness();  
  };
  handleAllowCancelOrders = async (event, data) => {
    let payload = {
      businessId: this.props.businessDetails._id,
      data: {
        allowCancelOrders: event.target.checked,
      }
    }
    // console.log("Type Order payload",payload)
    await this.props.updateBusiness(payload);
    this.getBusiness();  
  };
  handleChangePaymentFilter= async (event, data) => {
    this.props.requestBusinessDetailsRecords();
   this.setState({ paymentFeatureFilter : event.target.value})

    let payload = {
      businessId: this.props.businessDetails._id,
      data: {
        paymentFeatureFilter : event.target.value
      }
    }
   

    
    await this.props.updateBusiness(payload);
    this.getBusiness(); 
    this.props.requestBusinessDetailsRecordsFalse();

  };

  setData = () => {
    this.setState({
      businessName: this.props.businessDetails.businessName,
      primaryContactNumber: this.props.businessDetails.primaryContactNumber,
      pinCode: this.props.businessDetails.address.pinCode,
      landmark: this.props.businessDetails.address.landmark,
      street: this.props.businessDetails.address.street,
      shopNo: this.props.businessDetails.address.shopNo,
      gstin: this.props.businessDetails.gstin,
      description: this.props.businessDetails.description,
      categoryId: this.props.businessDetails.category._id,
      categoryName: this.props.businessDetails.category.name,
      shopActNo: this.props.businessDetails.shopAct.number,
      categoryTypeId: this.props.businessDetails.categoryType._id,
      categoryTypeName: this.props.businessDetails.categoryType.name,
      longitude: this.props.businessDetails.address.location.coordinates[0],
      latitude: this.props.businessDetails.address.location.coordinates[1],
      FSSAILicNumber: this.props.businessDetails.FSSAILicNumber ? this.props.businessDetails.FSSAILicNumber.trim() : "",
      businessContactName: this.props.businessDetails.businessContactName,
      businessContactNumber: this.props.businessDetails.businessContactNumber,
      isPetpoojaEnabled: this.props.businessDetails.isPetpoojaEnabled,
      isPackagingChargesEnabled: this.props.businessDetails.isPackagingChargesEnabled,
      petPoojaRestaurantId: this.props.businessDetails.petPoojaRestaurantId,
      businessPackagingCharges: this.props.businessDetails.businessPackagingCharges,
      isCashfreeEnabled: this.props.businessDetails.isCashfreeEnabled,
      cashfreeVendorId: this.props.businessDetails.cashfreeVendorId,
      isBusinessPreparationTimerEnabled:this.props.isBusinessPreparationTimerEnabled,
      businessPreparationTime :this.props.businessPreparationTime,
      businessPreparationTimeMax: this.props.businessPreparationTimeMax,
      upiId:this.props.businessDetails.upiDetails && this.props.businessDetails.upiDetails.upiId ? this.props.businessDetails.upiDetails.upiId : "",
      accountNumber:this.props.businessDetails.pgLinkedAccount && this.props.businessDetails.pgLinkedAccount.accountNumber ? this.props.businessDetails.pgLinkedAccount.accountNumber : "",
      bankName:this.props.businessDetails.pgLinkedAccount && this.props.businessDetails.pgLinkedAccount.bankName ?this.props.businessDetails.pgLinkedAccount.bankName : "",
      branchName:this.props.businessDetails.pgLinkedAccount && this.props.businessDetails.pgLinkedAccount.branchName ? this.props.businessDetails.pgLinkedAccount.branchName : "",
      ifscCode:this.props.businessDetails.pgLinkedAccount && this.props.businessDetails.pgLinkedAccount.ifscCode ? this.props.businessDetails.pgLinkedAccount.ifscCode : "",
      accountHolderName:this.props.businessDetails.pgLinkedAccount && this.props.businessDetails.pgLinkedAccount.accountHolderName ? this.props.businessDetails.pgLinkedAccount.accountHolderName : "",
      isBankAccountEnabled: this.state.isBankAccountEnabled ? this.state.isBankAccountEnabled : this.props.businessDetails.isBankAccountEnabled ? this.props.businessDetails.isBankAccountEnabled : "",
      isUpiEnabled: this.state.isUpiEnabled ? this.state.isUpiEnabled : this.props.businessDetails.isUpiEnabled ? this.props.businessDetails.isUpiEnabled : "",
      radius: this.props.businessDetails.radius,
      allowCancelOrders: this.props.businessDetails.allowCancelOrders,
      isTypeOrderEnabled:this.props.isTypeOrderEnabled,
      paymentFeatureFilter : this.props.businessDetails.isOnlyPostPaymentAllowed ? "isOnlyPostPaymentAllowed" : this.props.businessDetails.isOnlyPrePaymentAllowed ? "isOnlyPrePaymentAllowed" : this.props.businessDetails.isPostPaymentAllowedToLoyalConsumers ? "isPostPaymentAllowedToLoyalConsumers" : ""


    });
  };

  closeAmenities = () => {
    this.setState({ amenities: this.props.businessDetails.amenities });
    this.editAmenitiesFalse();
  };

  saveAmenities = () => {
    let data = {
      _id: this.props.businessDetails._id,
      businessName: this.state.businessName,
      amenities: this.state.amenities,
    };
    this.props.updateBusinessDetails(data);
    this.editAmenitiesFalse();
    let id = {
      businessId: this.props.businessDetails._id,
    };
    this.props.getBusinessById(id);
  };

  editAmenitiesTrue = () => {
    this.setState({ editAmenities: true });
  };
  editAmenitiesFalse = () => {
    this.setState({ editAmenities: false });
  };

  editDetailsFalse = () => {
    this.setState({ editDetails: false });
  };
  editDetailsTrue = () => {
    this.setState({ editDetails: true });
  };
  closeEditDetails = () => {
    this.editDetailsFalse();
    this.setData();
    this.setState({ latitudeError: false, longitudeError: false, phoneValidationError: false, pinCodeValidationError: false, FSSAILicNumberValidationError: false, businessContactNameError: false, businessContactNumberError: false, businessContactNumberValidationError: false, petPoojaRestaurantIdError: false, cashfreeVendorIdError: false, packagingChargesError: false, accountManagerError: false , radiusError: false, exotelContactNumbers : this.props.businessDetails.exotelContactNumbers ? this.props.businessDetails.exotelContactNumbers:[],
      exotelContactNumbersError:false,
      exotelContactNumbersErrorExists:false,
      exotelContactNumbersInvalidError:false,
      exotelTextField : "",
      exotelContactNumberslengthError : false});
    this.getSpecialityCuisineList();
  };
  saveDetails = async () => {   
    console.log("this.state.radius---->", this.state.radius);
    let hasError = false;
    if (!this.state.latitude || !/^\+?[0-9]{1,2}(\.[0-9]*|\.[0-9]+)?$$/i.test(this.state.latitude)) {
      this.setState({ latitudeError: true, });
      hasError = true;
    }
   
    if (!this.state.longitude || !/^\+?[0-9]{1,2}(\.[0-9]*|\.[0-9]+)?$$/i.test(this.state.longitude)) {
      this.setState({ longitudeError: true, });
      hasError = true;
    }

    if (!this.state.primaryContactNumber || (this.state.primaryContactNumber.trim().length != 10 && this.state.primaryContactNumber.trim().length != 0)) {
      this.setState({ phoneValidationError: true, });
      hasError = true;
    }

    if (!this.state.pinCode || !/^[1-9][0-9]{5}$/.test(this.state.pinCode)) {
      this.setState({
        pinCodeValidationError: true,
      });
      hasError = true;
    }
    if ((this.state.FSSAILicNumber && this.state.FSSAILicNumber.trim().length != 14 && this.state.FSSAILicNumber.trim().length != 0)) {
      this.setState({
        FSSAILicNumberValidationError: true,
      });
      hasError = true;
    }

    // businessContactNumber, businessContactName only one is present then
    if (this.state.businessContactName && !this.state.businessContactNumber) {
      hasError = true;
      this.setState({ businessContactNumberError: true })
    }
    if (this.state.businessContactNumber && !this.state.businessContactName) {
      hasError = true;
      this.setState({ businessContactNameError: true });
      if (this.state.businessContactNumber.trim().length != 10 && this.state.businessContactNumber.trim().length != 0) {
        hasError = true;
        this.setState({ businessContactNumberValidationError: true });
      }
    }
    if (this.state.businessContactNumber && this.state.businessContactName) {
      if (this.state.businessContactName.trim().length == 0) {
        hasError = true;
        this.setState({ businessContactNameError: true })
      }
      // if (!/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/i.test(this.state.businessContactNumber )) {
      //   hasError = true;
      //   this.setState({ businessContactNumberValidationError: true });
      // }
      if (this.state.businessContactNumber.trim().length != 10 && this.state.businessContactNumber.trim().length != 0) {
        hasError = true;
        this.setState({ businessContactNumberValidationError: true });
      }
    }
    if (this.state.isPetpoojaEnabled) {
      if (!this.state.petPoojaRestaurantId) {
        hasError = true;
      } else if (this.state.petPoojaRestaurantId.trim() == '' || ! /^[a-zA-Z0-9]*$/.test(this.state.petPoojaRestaurantId)) {
        this.setState({
          petPoojaRestaurantIdError: true,
        });
        hasError = true;
      }
    }


    if (this.state.isPackagingChargesEnabled) {
      if (!this.state.businessPackagingCharges) {
        hasError = true;
      }
      else if (! /^(\d{1,3})(\.\d{1,2})?$/.test(this.state.businessPackagingCharges)) {
        this.setState({
          packagingChargesError: true,
        });
        hasError = true;
      }
    }
    if ( this.state.radius == 0 || !/^([\d]+)(?:\.([\d]{1,3}?))?$/.test(this.state.radius)){     
        this.setState({radiusError: true});
        hasError = true; 
     }
       
      
    if (this.state.isCashfreeEnabled) {
      if (!this.state.cashfreeVendorId) {
        hasError = true;
      } else if (this.state.cashfreeVendorId.trim() == '' || ! /^[a-zA-Z0-9]*$/.test(this.state.cashfreeVendorId)) {
        this.setState({
          cashfreeVendorIdError: true,
        });
        hasError = true;
      }
    }


    if (!hasError) {
      if (this.state.imgfile) {
        let payload = {
          file: this.state.imgfile,
          fileName: this.state.fileName,
          type: "shopact",
          serviceProviderId: this.props.businessDetails.ownerId._id
        };
        this.props.uploadShopActImage(payload);
      }
      this.setState({
        phoneValidationError: false,
        pinCodeValidationError: false,
        FSSAILicNumberValidationError: false,
        latitudeError: false,
        longitudeError: false,
        businessContactNameError: false,
        businessContactNumberError: false, businessContactNumberValidationError: false,
        petPoojaRestaurantIdError: false,
        packagingChargesError: false,
        cashfreeVendorIdError: false,
        accountManagerError: false,
        exotelContactNumbersError:false,
        exotelContactNumberslengthError:false,
        exotelContactNumbersErrorExists: false,
        exotelContactNumbersInvalidError:false,
        radiusError : false
        // selectedAccountManager: ""
      });
      let chosenSpecialities = this.props.specialityDetails.filter((speciality) => this.state.selectedSpeciality.includes(speciality.name));
      let chosenCuisines = this.props.cuisineDetails.filter((cuisine) => this.state.selectedCuisine.includes(cuisine.name));

      let data = {
        _id: this.props.businessDetails._id,
        businessName: this.state.businessName,
        businessId: this.props.businessDetails.businessId,
        primaryContactNumber: this.state.primaryContactNumber,
        gstin: this.state.gstin,
        description: this.state.description,
        businessContactName: this.state.businessContactName,
        businessContactNumber: this.state.businessContactNumber,
        category: this.state.categoryId,
        categoryType: this.state.categoryTypeId,
        "address.pinCode": this.state.pinCode,
        "address.landmark": this.state.landmark,
        "address.street": this.state.street,
        "address.shopNo": this.state.shopNo,
        "shopAct.number": this.state.shopActNo,
        "address.location.coordinates": [parseFloat(this.state.longitude), parseFloat(this.state.latitude)],
        FSSAILicNumber: this.state.FSSAILicNumber,
        isPetpoojaEnabled: this.state.isPetpoojaEnabled,
        petPoojaRestaurantId: this.state.petPoojaRestaurantId,
        isPackagingChargesEnabled: this.state.isPackagingChargesEnabled,
        businessPackagingCharges: this.state.businessPackagingCharges,
        isCashfreeEnabled: this.state.isCashfreeEnabled,
        cashfreeVendorId: this.state.cashfreeVendorId,
        // selectedAccountManager: this.state.selectedAccountManager,
        // areaId: this.props.businessDetails.areaBelongsTo._id,
        // regionId: this.props.businessDetails.regionBelongsTo._id,
        specialityIds: chosenSpecialities.map(i => i._id),
        cuisineIds: chosenCuisines.map(i => i._id),
        exotelContactNumbers :this.state.exotelContactNumbers,
        radius: this.state.radius ? this.state.radius : this.props.businessDetails.defaultBusinessRadius
      };


      if (this.state.fileName) {
        data["shopAct.fileName"] = this.state.fileName;
      }
      // let dataForDeleteExistingAMBusiness = {
      //   businessId: this.props.businessDetails._id,
      //   userId: this.props.dashboard.setSelectedBusiness.areaBelongsTo._id
      // }
      // await this.props.deleteExistingAreaManagerBusiness(dataForDeleteExistingAMBusiness);
      this.props.requestBusinessDetailsRecords();
      await this.props.updateBusinessDetails(data);

      this.setState({exotelTextField:""})
      this.editDetailsFalse();
    this.props.requestBusinessDetailsRecordsFalse();

      let id = { businessId: this.props.businessDetails._id };
      await this.props.getBusinessById(id);
      this.props.getShopActServPro({
        serviceProviderId: this.props.businessDetails.ownerId._id,
        businessId: this.props.businessDetails._id
      });
      this.setData();
    }
    
  };
  editBankDetails = () => {
    this.setState({ editBankDetailsModal: true });
    if((this.props.businessDetails.isBankAccountEnabled === true && this.props.businessDetails.isUpiEnabled === false) || (this.props.businessDetails.isBankAccountEnabled === false && this.props.businessDetails.isUpiEnabled === false)){
  this.setState({
    accountNumber: this.props.businessDetails.pgLinkedAccount && this.props.businessDetails.pgLinkedAccount.accountNumber ? this.props.businessDetails.pgLinkedAccount.accountNumber : "",
    ifscCode: this.props.businessDetails.pgLinkedAccount && this.props.businessDetails.pgLinkedAccount.ifscCode ? this.props.businessDetails.pgLinkedAccount.ifscCode : "",
    bankName: this.props.businessDetails.pgLinkedAccount && this.props.businessDetails.pgLinkedAccount.bankName ? this.props.businessDetails.pgLinkedAccount.bankName : "",
    branchName: this.props.businessDetails.pgLinkedAccount && this.props.businessDetails.pgLinkedAccount.branchName ? this.props.businessDetails.pgLinkedAccount.branchName : "",
    accountHolderName: this.props.businessDetails.pgLinkedAccount && this.props.businessDetails.pgLinkedAccount.accountHolderName ? this.props.businessDetails.pgLinkedAccount.accountHolderName : "",
    isBankAccountEnabled: "isBankAccountEnabled===true",
    isUpiEnabled: false,
    upiId: ""
  })
    }
    else{
      this.setState({
      upiId: this.props.businessDetails.upiDetails && this.props.businessDetails.upiDetails.upiId ? this.props.businessDetails.upiDetails.upiId : "",
      accountNumber: "",
      ifscCode: "",
      bankName: "",
      branchName: "",
      accountHolderName: "",
      isUpiEnabled: true,
      isBankAccountEnabled: "isUpiEnabled===true",
      })
    }
  }
  editPaymentGatewayIntegrations = () => {
    this.setState({ editPGSecretKeyModal: true });
  }
  editExotelTimeConfiguration = async () => {
    await this.props.getAllMasterOtp();
    if (this.props.business && this.props.business.updatedBusinessData && 'exotelConfig' in this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig && this.props.business.updatedBusinessData.exotelConfig.length > 0) {
      this.setState({
        firstReminder: this.props.business.updatedBusinessData.exotelConfig[0].minute,
        secondReminder: this.props.business && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig[1] && this.props.business.updatedBusinessData.exotelConfig[1].minute ?this.props.business.updatedBusinessData.exotelConfig[1].minute :null,
        thirdReminder:this.props.business && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig[2] && this.props.business.updatedBusinessData.exotelConfig[2].minute ?this.props.business.updatedBusinessData.exotelConfig[2].minute :null,
        editExotelConfig: true
      })
    } else {
      this.setState({
        firstReminder: this.props.dashboardDetails.data[0].exotelConfig[0].minute,
        secondReminder: this.props.dashboardDetails.data[0].exotelConfig[1].minute,
        thirdReminder: this.props.dashboardDetails.data[0].exotelConfig[2].minute,
        editExotelConfig: true
      })

    }

  }
  editExotelTimeConfigurationClose = () => {
    this.setState({ editExotelConfig: false,
      // exotelThirdDelete: this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig[2] &&this.props.business.updatedBusinessData.exotelConfig[2].minute ?  true :false,
      // exotelSecondDelete: this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig[1] &&this.props.business.updatedBusinessData.exotelConfig[1].minute ?  true :false,
      // exotelSecondAdd: this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig[1] &&this.props.business.updatedBusinessData.exotelConfig[1].minute ?  true :false,
      // exotelFirstAdd: this.props.business.updatedBusinessData && !this.props.business.updatedBusinessData.exotelConfig[1] && !this.props.business.updatedBusinessData.exotelConfig[2]?  true :false, 
    }
      );
  }
  saveOpenTillTime = async () => {
    let payload = {
      businessId: this.props.businessDetails._id,
      data: {
        businessOpenTillTime: this.state.businessOpenTillTime
      }
    }
    await this.props.updateBusiness(payload);
    this.setState({ sucessMsg: "Business open till updated successfully" })
    this.setState({ snackOpen: true, businessOpenTillTimeSave: true });
    this.getBusiness();
  }

  clearOpenTillTime = async () => {
    this.setState({ businessOpenTillTime: "" })
    let payload = {
      businessId: this.props.businessDetails._id,
      data: {
        businessOpenTillTime: null
      }
    }
    await this.props.updateBusiness(payload);
    this.setState({ sucessMsg: "Business open till cleared successfully" })
    this.setState({ snackOpen: true });
    this.getBusiness();
  }
  saveAccountManagerDetail = async () => {
    let hasError = false;
    if (this.state.selectedAccountManager) {
      let accountManagerCallInSaveDetail = [];
      accountManagerCallInSaveDetail.push(this.props.dashboard.setSelectedBusiness.accountManagerDetails && this.props.dashboard.setSelectedBusiness.accountManagerDetails.length > 0 ? this.props.dashboard.setSelectedBusiness.accountManagerDetails[this.props.dashboard.setSelectedBusiness.accountManagerDetails.length - 1] : "")
      let currentAccountManagerCallinSaveDetail = {};
      currentAccountManagerCallinSaveDetail = accountManagerCallInSaveDetail.map((am) => am.accountManager)
      if (this.state.selectedAccountManager === currentAccountManagerCallinSaveDetail[0]) {
        {
          this.setState({ accountManagerError: true });
          hasError = true;
        }
      }
    }
    this.setState({ accountManagerError: false })

    let data = {
      _id: this.props.businessDetails._id,
      selectedAccountManager: this.state.selectedAccountManager,
      areaId: this.props.businessDetails.areaBelongsTo._id,
      regionId: this.props.businessDetails.regionBelongsTo._id,
      businessName: this.state.businessName,
      businessId: this.props.businessDetails.businessId
    }


    let dataForDeleteExistingAMBusiness = {
      businessId: this.props.businessDetails._id,
      userId: this.props.dashboard.setSelectedBusiness.areaBelongsTo._id
    }
    await this.props.deleteExistingAreaManagerBusiness(dataForDeleteExistingAMBusiness);
    await this.props.updateBusinessDetails(data);
    this.setState({ sucessMsg: "Account Manager Assign Successfully" })
    this.setState({ snackOpen: true });
    this.getBusiness();

  }
  cancelAccountManger = () => {
    let accountManagerCall = [];
    accountManagerCall.push(this.props.dashboard.setSelectedBusiness.accountManagerDetails && this.props.dashboard.setSelectedBusiness.accountManagerDetails.length > 0 ? this.props.dashboard.setSelectedBusiness.accountManagerDetails[this.props.dashboard.setSelectedBusiness.accountManagerDetails.length - 1] : []);
    this.setState({ selectedAccountManager: accountManagerCall[0].accountManager ? accountManagerCall[0].accountManager : "" });
    this.getBusiness();
  }
  onPaymentFeatureMouseOver = () => {
    this.setState({ openPopper: true });
  }
  onPaymentFeatureMouseOut = () => {
    this.setState({ openPopper: false });
  }
  clearData = () => {
    this.setState({
      secretKey: "",
      appid: "",
      accountNumber: "",
      ifscCode: "",
      bankName: "",
      branchName: "",
      accountHolderName: "",
      upiId: ""
    });
  }
  close = () => {
    this.clearData();
    this.setState({
      editBankDetailsModal: false,
      editPGSecretKeyModal: false,
      addTimingModalOpen: false,
      editExotelConfig: false,
      dayRequiredError: [],
      holidaysRequiredError: [],
      holidaysError: [],
      fromError: [],
      toError: [],
      invalidTime: [],
      fromRequiredError: [],
      toRequiredError: [],
      holidayWorkingDayError: [],
      // fromTimeOverlap: [],
      // toTimeOverlap: [],
      editTimingModalOpen: false,
      holidays: [],
      workingDays: [],
      firstRequired: false,
      secondRequired: false,
      thirdRequired: false,
      editExotelConfig: false,
      exotelTwoError:false,
      exotelThreeError:false,  
      accountNumberError: false,
      ifscCodeError: false,
      bankNameError: false,
      branchNameError: false,
      accountHolderNameError: false,
      accountNumberValidationError: false,
      ifscCodeValidationError: false,
      bankNameValidationError: false,
      accountHolderNameValidationError: false,
      branchNameValidationError: false,
      upiIdError: false,
      upiIdValidationError: false,
      exotelSecondDelete: this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig[1] && this.props.business.updatedBusinessData.exotelConfig[1].minute || this.props?.dashboardDetails?.data[0]?.exotelConfig[1]?.minute ?  true :false,
      exotelSecondAdd: this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig[1] && this.props.business.updatedBusinessData.exotelConfig[1].minute || this.props?.dashboardDetails?.data[0]?.exotelConfig[1] ?.minute?  true :false,
      exotelFirstAdd: this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig[0]  && !this.props.business.updatedBusinessData.exotelConfig[1] && !this.props.business.updatedBusinessData.exotelConfig[2] || (!this.props?.dashboardDetails?.data[0]?.exotelConfig[1] && !this.props?.dashboardDetails?.data[0]?.exotelConfig[2])?  true :false,
      exotelThirdDelete: this.props.business.updatedBusinessData &&  this.props.business.updatedBusinessData.exotelConfig && this.props.business.updatedBusinessData.exotelConfig[2] && this.props.business.updatedBusinessData.exotelConfig[2].minute || this.props?.dashboardDetails?.data[0]?.exotelConfig[2]?.minute ?  true :false,
    });
    this.editExotelTimeConfigurationClose()
  }
  savePgIntegrationDetails = async () => {
    let payload = {
      businessId: this.props.businessDetails._id,
      data: {
        secretKey: this.state.secretKey ? this.state.secretKey : this.props.business.updatedBusinessData.secretKey,
        appid: this.state.appid ? this.state.appid : this.props.business.updatedBusinessData.appid,
      }
    }
    let flag = await this.validatePGDetails(payload.data);
    if (parseInt(flag)) {
      await this.props.updateBusiness(payload);
      this.getBusiness();
      this.close();
    }
  }
  validatePGDetails = async (data) => {
    let flag = 1;
    let error = this.state.error;
    if (!data.secretKey) {
      error.secretKey = "Please Enter Secret Key";
      flag = 0;
    }
    if (!data.appid) {
      error.appid = "Please Enter API Key";
      flag = 0;
    }
    await this.setState({ error });
    return flag;
  }


  
 saveBankDetails = async (event,data) => {
  // console.log('event.target.value~~~~~~~~~~~>',event)
  this.props.requestBusinessDetailsRecords();
  if(((this.state.isBankAccountEnabled === ('isBankAccountEnabled===true') && this.state.isUpiEnabled ===false) || this.state.isBankAccountEnabled === ('isBankAccountEnabled===true') && this.state.isUpiEnabled !==false || this.state.isBankAccountEnabled === true)){
  // console.log('data--------->',data)
    let payload = {
      businessId: this.props.businessDetails._id,
      data: {
        pgLinkedAccount: {
          accountNumber: this.state.accountNumber ? this.state.accountNumber : (this.props && this.props.businessDetails && this.props.businessDetails.pgLinkedAccount) ? this.props.businessDetails.pgLinkedAccount.accountNumber : "NA",
          ifscCode: this.state.ifscCode ? this.state.ifscCode : (this.props && this.props.businessDetails && this.props.businessDetails.pgLinkedAccount) ? this.props.businessDetails.pgLinkedAccount.ifscCode : "",
          bankName: this.state.bankName ? this.state.bankName : (this.props && this.props.businessDetails && this.props.businessDetails.pgLinkedAccount) ? this.props.businessDetails.pgLinkedAccount.bankName : "",
          branchName: this.state.branchName ? this.state.branchName : (this.props && this.props.businessDetails && this.props.businessDetails.pgLinkedAccount) ? this.props.businessDetails.pgLinkedAccount.branchName : "",
          accountHolderName: this.state.accountHolderName ? this.state.accountHolderName : (this.props && this.props.businessDetails && this.props.businessDetails.pgLinkedAccount) ? this.props.businessDetails.pgLinkedAccount.accountHolderName : "",
        },
        upiDetails: {
          upiId: ""
        },
        isBankAccountEnabled:  true,
        isUpiEnabled: false
      },
    }
    let hasError= false
    if(!this.state.accountNumber){ 
      // console.log('this.state.accountNumber',this.state.accountNumber)
      hasError = true;
       this.setState({ accountNumberError: true}); }

    if(!this.state.ifscCode){ 
    //  console.log('this.state.ifscCode',this.state.ifscCode)
     hasError = true;
      this.setState({ ifscCodeError: true}); }

    if(!this.state.bankName){ 
      // console.log('this.state.bankName',this.state.bankName)
      hasError = true;
       this.setState({ bankNameError: true}); }

    if(!this.state.branchName){ 
    //  console.log('this.state.branchName',this.state.branchName)
     hasError = true;
      this.setState({ branchNameError: true}); }

    if(!this.state.accountHolderName){ 
    //  console.log('this.state.accountHolderName',this.state.accountHolderName)
     hasError = true;
      this.setState({ accountHolderNameError: true}); }

    let accountNumberRegex = /^\d{9,18}$/;
    let regExcheckBankAccount = await accountNumberRegex.test(this.state.accountNumber);
    // console.log('regExcheckBankAccount',regExcheckBankAccount)
    if (!regExcheckBankAccount) {
      // console.log("inside regExcheckBankAccount",regExcheckBankAccount)
      hasError = true;
      this.setState({ accountNumberValidationError: true});
    }
    let ifscCodeRegex = /[A-Z|a-z]{4}[0][\d]{6}$/;
    let regExcheckIfscCode = await ifscCodeRegex.test(this.state.ifscCode);
    // console.log("inside regExcheckBankAccount",regExcheckIfscCode)
    if (!regExcheckIfscCode) {
      hasError= true;
      this.setState({ ifscCodeValidationError: true});
    }
    let charRegex = /^[a-zA-Z ]*$/;
    let regExcheckBankName = await charRegex.test(this.state.bankName);
    // console.log("inside regExcheckBankAccount",regExcheckBankName)
    if (!regExcheckBankName) {
      hasError= true;
      this.setState({ bankNameValidationError: true});
    }
   let regExcheckAccountHolderName = await charRegex.test(this.state.accountHolderName);
  //  console.log("inside regExcheckBankAccount",regExcheckAccountHolderName)
    if (!regExcheckAccountHolderName) {
      hasError= true;
      this.setState({ accountHolderNameValidationError: true});
    }
    let branchNameRegex = /^[A-Za-z][A-Za-z0-9_-]*[A-Za-z0-9]$/;
    let regExcheckBranchName = await branchNameRegex.test(this.state.branchName);
    // console.log("inside regExcheckBankAccount",regExcheckBranchName)
    if (!regExcheckBranchName) {
      hasError= true;
      this.setState({ branchNameValidationError: true});
    }
    if(!hasError){
      // console.log('hasError000000000',hasError)
      // console.log('this.props.businessDetails.isBankAccountEnabled',this.props.businessDetails.isBankAccountEnabled)
     
      await this.props.updateBusiness(payload);
      this.props.requestBusinessDetailsRecordsFalse();
        // this.setData();

        this.getBusiness()
        this.close();
        this.setState({ isBankAccountEnabled: this.props.businessDetails.isBankAccountEnabled});
        this.setState({ isUpiEnabled: this.props.businessDetails.isUpiEnabled});
    }     
  }else{
    // console.log('inside saveBankDetails upi')
    let payload = {
      businessId: this.props.businessDetails._id,
      data: {
        upiDetails: {
          upiId: this.state.upiId ? this.state.upiId : (this.props && this.props.businessDetails && this.props.businessDetails.upiDetails) ? this.props.businessDetails.upiDetails.upiId : "",
        },
        pgLinkedAccount: {
          accountNumber: "",
          ifscCode: "",
          bankName: "",
          branchName: "",
          accountHolderName: ""
        },
        isUpiEnabled:true,
        isBankAccountEnabled: false
        
      },
    }
    let hasError= false
    if(!this.state.upiId){ 
      // console.log('this.state.upiId',this.state.upiId)
      hasError = true;
       this.setState({ upiIdError: true}); }

  let upiIdRegex = /[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/;  
    let upiIdRegexCheck = await upiIdRegex.test(this.state.upiId)
  // console.log('upiIdRegexCheck----->',upiIdRegexCheck)
  if (!upiIdRegexCheck){
    hasError= true;
    this.setState({ upiIdValidationError: true});
  }
  if(!hasError){
    await this.props.updateBusiness(payload);
    this.props.requestBusinessDetailsRecordsFalse();
      this.getBusiness();
      this.close();
  }
}    
}

  handlePgEnable = async (event, data) => {
    let payload = {
      businessId: this.props.businessDetails._id,
      data: {
        isPGEnabled: event.target.checked
      }
    }
    await this.props.updateBusiness(payload);
    this.getBusiness();
  };
  getBusiness = () => {
    let payload = {
      businessId: this.props.businessDetails._id,
    };
    this.props.getBusinessById(payload);
  };

  openAddTimingModal = () => {
    if ((this.props.businessDetails.availability.workingHrs.length === 0) && (this.props.businessDetails.availability.holidays.length === 0)) {
      this.setState({ addTimingModalOpen: true });
    } else {
      this.openEditTimingModal();
    }

  };
  openEditTimingModal = () => {
    this.setState({ editTimingModalOpen: true });
  };

  addWorkingDay = () => {
    let workingDays = this.state.workingDays;
    const id = new ObjectID().toHexString();
    let day = {
      _id: id,
      day: "",
      from: "",
      to: ""
    }
    workingDays.push(day)
    this.setState({ workingDays })
  };
  addHoliday = () => {
    let holidays = this.state.holidays;
    const id = new ObjectID().toHexString();
    let data = {
      _id: id,
      day: ""
    }
    holidays.push(data)
    this.setState({ holidays })
  };
  deleteWorkingHrs = (value) => {
    let workingDays = this.state.workingDays;
    let dayRequiredError = this.state.dayRequiredError;
    let fromRequiredError = this.state.fromRequiredError;
    let toRequiredError = this.state.toRequiredError;
    let fromError = this.state.fromError;
    let toError = this.state.toError;
    let invalidTime = this.state.invalidTime;

    //overlap timings validations are commented for now
    // let fromTimeOverlap = this.state.fromTimeOverlap;
    // let toTimeOverlap = this.state.toTimeOverlap;

    let index = workingDays.indexOf(value);

    workingDays = workingDays.filter((link) => link._id !== value._id);

    //Code which was before
    // dayRequiredError = dayRequiredError.splice(index,1);
    // fromRequiredError = fromRequiredError.splice(index,1);
    // toRequiredError = toRequiredError.splice(index,1);
    // fromError = fromError.splice(index,1);
    // toError = toError.splice(index,1);
    // invalidTime = invalidTime.splice(index,1);
    // fromTimeOverlap = fromTimeOverlap.splice(index,1);
    // toTimeOverlap = toTimeOverlap.splice(index,1);

    //After - to handle the Textbox error text
    delete dayRequiredError[index];
    delete fromRequiredError[index];
    delete toRequiredError[index];
    delete fromError[index];
    delete toError[index];
    delete invalidTime[index];

    //overlap timings validations are commented for now
    // fromTimeOverlap[index];
    // toTimeOverlap[index];

    // this.setState({ workingDays, dayRequiredError, toRequiredError, fromRequiredError, toError, fromError, invalidTime, fromTimeOverlap, toTimeOverlap })

    this.setState({ workingDays, dayRequiredError, toRequiredError, fromRequiredError, toError, fromError, invalidTime })
  }
  handleChangeWorkingDay = (event) => {
    let exist = 0;
    let workingDays = this.state.workingDays;
    if (!exist) {
      for (var i = 0; i < workingDays.length; i++) {
        if (workingDays[i]._id === event.target.name._id) {
          workingDays[i].day = event.target.value
          let dayRequiredError = this.state.dayRequiredError;
          if (event.target.value.length > 0) { dayRequiredError[i] = false }
          else { dayRequiredError[i] = true; }
          this.setState({ workingDays, dayRequiredError })
        }
      }
    }
  };
  handleOpenTillTime = (event) => {
    if (this.props.businessDetails.businessOpenTillTime === event.target.value) {
      this.setState({ businessOpenTillTime: event.target.value, businessOpenTillTimeSave: true })
    } else {
      this.setState({ businessOpenTillTime: event.target.value, businessOpenTillTimeSave: false });
    }
  }

  handleExotelReminders = (event) => {
    this.setState({ firstRequired: false, secondRequired: false, thirdRequired: false,exotelTwoError:false,exotelThreeError:false })
    this.setState({ [event.target.name]: event.target.value })
  }

  handleChangeWorkingDayFromTime = (event) => {
    let workingDays = this.state.workingDays;
    let fromRequiredError = this.state.fromRequiredError;
    let fromError = this.state.fromError;
    let fromTimeOverlap = this.state.fromTimeOverlap;

    for (var i = 0; i < workingDays.length; i++) {
      if (workingDays[i]._id === event.target.name._id) {
        workingDays[i].from = event.target.value;
        if (event.target.value.length > 0) { fromRequiredError[i] = false; fromError[i] = false; fromTimeOverlap[i] = false; }
        else { fromRequiredError[i] = true; }
      }
    }
    this.setState({ workingDays, fromRequiredError, fromError, fromTimeOverlap })
  };

  handleChangeWorkingDayToTime = (event) => {
    let workingDays = this.state.workingDays;
    let toRequiredError = this.state.toRequiredError;
    let toError = this.state.toError;
    let invalidTime = this.state.invalidTime;
    let toTimeOverlap = this.state.toTimeOverlap;

    for (var i = 0; i < workingDays.length; i++) {
      if (workingDays[i]._id === event.target.name._id) {
        workingDays[i].to = event.target.value;
        if (event.target.value.length > 0) { toRequiredError[i] = false; toError[i] = false; invalidTime[i] = false; toTimeOverlap[i] = false }
        else { toRequiredError[i] = true; }
      }
    }
    this.setState({ workingDays, toRequiredError, toError, invalidTime, toTimeOverlap })
  };

  handleChangeHoliday = (event) => {
    let exist = 0;
    let holidays = this.state.holidays;
    if (!exist) {
      for (var i = 0; i < holidays.length; i++) {
        if (holidays[i]._id === event.target.name) {
          holidays[i].day = event.target.value
          let holidaysRequiredError = this.state.holidaysRequiredError;
          let holidaysError = this.state.holidaysError;
          let holidayWorkingDayError = this.state.holidayWorkingDayError;
          if (event.target.value.length > 0) { holidaysRequiredError[i] = false; holidaysError[i] = false; holidayWorkingDayError[i] = false; }
          else { holidaysRequiredError[i] = true; }
          this.setState({ holidays, holidaysRequiredError, holidayWorkingDayError })
        }
      }
    }
  };

  deleteHoliday = (value) => {
    let holidays = this.state.holidays;
    let holidaysRequiredError = this.state.holidaysRequiredError;
    let holidaysError = this.state.holidaysError;
    let holidayWorkingDayError = this.state.holidayWorkingDayError;

    let index = holidays.indexOf(value);

    holidays.splice(index, 1);
    holidaysRequiredError.splice(index, 1);
    holidaysError.splice(index, 1);
    holidayWorkingDayError.splice(index, 1);

    this.setState({ holidays, holidaysRequiredError, holidaysError, holidayWorkingDayError })
  };

  saveBusinessTimings = async () => {
    let hasError = false;
    let payload = {};
    // let convertedFrom;
    // let convertedTo;
    // let invalidToTimeCheck;

    let holidays = [];
    let holidaysRequiredError;
    let holidaysError;
    let dayRequiredError;
    let fromRequiredError;
    let toRequiredError;
    // let fromError;
    // let toError;
    // let invalidTime;
    let holidayWorkingDayError;
    // let fromTimeOverlap;
    // let toTimeOverlap;

    if (this.state.holidays && this.state.holidays.length > 0) {
      for (let i = 0; i < this.state.holidays.length; i++) {
        holidays.push(this.state.holidays[i].day)
      }
      holidays = unique(holidays);
    }

    if (this.state.holidays.length > 0) {
      for (let k = 0; k < this.state.holidays.length; k++) {
        //for required
        if (this.state.holidays[k].day.length === 0) { hasError = true; holidaysRequiredError = this.state.holidaysRequiredError; holidaysRequiredError[k] = true; this.setState({ holidaysRequiredError }) }

        //to check duplicate holiday
        for (let j = k + 1; j < this.state.holidays.length; j++) {
          if (this.state.holidays[k].day === this.state.holidays[j].day) { hasError = true; holidaysError = this.state.holidaysError; holidaysError[j] = true; holidaysError[k] = true; this.setState({ holidaysError }); }
        }
      }
    }

    if (this.state.workingDays && this.state.workingDays.length > 0) {
      for (let i = 0; i < this.state.workingDays.length; i++) {
        // for required error
        if (this.state.workingDays[i].day.length === 0) { hasError = true; dayRequiredError = this.state.dayRequiredError; dayRequiredError[i] = true; this.setState({ dayRequiredError }); }
        if (this.state.workingDays[i].from.length === 0) { hasError = true; fromRequiredError = this.state.fromRequiredError; fromRequiredError[i] = true; this.setState({ fromRequiredError }); }
        if (this.state.workingDays[i].to.length === 0) { hasError = true; toRequiredError = this.state.toRequiredError; toRequiredError[i] = true; this.setState({ toRequiredError }); }

        // to check for invalid timing (to should be immediate future time)
        // if (this.state.workingDays[i].to.length > 0 && this.state.workingDays[i].from.length > 0) {
        //   convertedFrom = convertTime12to24(this.state.workingDays[i].from);
        //   convertedTo = convertTime12to24(this.state.workingDays[i].to);
        //   invalidToTimeCheck = timeCheck(convertedFrom, convertedTo); //whenevr a is greater, show to time is invalid value
        //   if (invalidToTimeCheck) { hasError = true; invalidTime = this.state.invalidTime; invalidTime[i] = true; this.setState({ invalidTime }); }
        // }

        //to check duplicate from and to timings
        // for (let j = i + 1; j < this.state.workingDays.length; j++) {
        //   if (this.state.workingDays[i].day === this.state.workingDays[j].day) {
        //     //to check duplicate from time
        //     if (this.state.workingDays[i].from === this.state.workingDays[j].from) { hasError = true; fromError = this.state.fromError; fromError[j] = true; fromError[i] = true; this.setState({ fromError }); }

        //     //to check duplicate to time
        //     if (this.state.workingDays[i].to === this.state.workingDays[j].to) { hasError = true; toError = this.state.toError; toError[j] = true; toError[i] = true; this.setState({ toError }); }
        //   }
        // }

        //to check if working day and holiday is duplicate
        if (this.state.holidays.length > 0) {
          for (let k = 0; k < this.state.holidays.length; k++) {
            if (this.state.workingDays[i].day === this.state.holidays[k].day) { hasError = true; holidayWorkingDayError = this.state.holidayWorkingDayError; holidayWorkingDayError[k] = true; this.setState({ holidayWorkingDayError }); }
          }
        }
      }
    }

    // START
    // ----------- Code for Overlapping of business timings is commented -------------- (For time being - uncomment it later when new story related to business overlapping time is created)  --------------------------------
    // //to check for overlapping of time
    // if(this.state.workingDays && this.state.workingDays.length>1){
    //   for(let i=0; i<this.state.workingDays.length; i++){
    //     for(let j = i+1; j < this.state.workingDays.length; j++) {
    //       if(this.state.workingDays[i].day === this.state.workingDays[j].day){
    //         let convertedFromOverlap = convertTime12to24(this.state.workingDays[j].from);
    //         let convertedToOverlap = convertTime12to24(this.state.workingDays[j].to);
    //         let convertedToPreviousOverlap = convertTime12to24(this.state.workingDays[i].to);
    //         let convertedFromPreviousOverlap = convertTime12to24(this.state.workingDays[i].from);

    //         let fromInvalidTimeCheckWithFrom = timeCheck(convertedFromOverlap, convertedFromPreviousOverlap);
    //         let fromInvalidTimeCheckWithFrom1 = timeCheck(convertedFromPreviousOverlap,convertedFromOverlap);
    //         let fromInvalidTimeCheckWithTo = timeCheck(convertedToPreviousOverlap, convertedFromOverlap);
    //         let fromInvalidTimeCheckWithTo1 = timeCheck(convertedFromOverlap,convertedToPreviousOverlap);
    //         let toInvalidTimeCheckWithFrom = timeCheck(convertedToOverlap, convertedFromPreviousOverlap);
    //         let toInvalidTimeCheckWithFrom1 = timeCheck(convertedFromPreviousOverlap,convertedToOverlap);
    //         let toInvalidTimeCheckWithTo = timeCheck(convertedToPreviousOverlap, convertedToOverlap);
    //         let toInvalidTimeCheckWithTo1 = timeCheck(convertedToOverlap,convertedToPreviousOverlap);

    //         if(fromInvalidTimeCheckWithFrom && fromInvalidTimeCheckWithTo){ hasError = true; fromTimeOverlap = this.state.fromTimeOverlap; fromTimeOverlap[j] = true; this.setState({fromTimeOverlap}); }
    //         if(toInvalidTimeCheckWithFrom && toInvalidTimeCheckWithTo){ hasError = true; toTimeOverlap = this.state.toTimeOverlap; toTimeOverlap[j] = true; this.setState({toTimeOverlap}); }

    //         if(fromInvalidTimeCheckWithFrom1 && toInvalidTimeCheckWithTo1){ hasError = true; fromTimeOverlap = this.state.fromTimeOverlap; fromTimeOverlap[i] = true; toTimeOverlap = this.state.toTimeOverlap; toTimeOverlap[i] = true; this.setState({fromTimeOverlap, toTimeOverlap}); }
    //       }
    //     }
    //   }
    // }

    // ----------- Code for Overlapping of business timings is commented -------------- (For time being - uncomment it later when new story related to business overlapping time is created)  --------------------------------
    // END

    if (!hasError) {
      payload = {
        businessId: this.props.businessDetails._id,
        data: {
          availability: {
            status: this.props.businessDetails.availability.status,
            workingHrs: this.state.workingDays,
            holidays: holidays,
          },
        },
      };

      this.setState({ addTimingModalOpen: false, workingDays: [], holidays: [] })
      await this.props.updateBusiness(payload);
      await this.props.getBusinessById(payload);
      this.setState({ holidaysRequiredError: [], holidaysError: [], dayRequiredError: [], fromRequiredError: [], toRequiredError: [], fromError: [], toError: [], invalidTime: [], holidayWorkingDayError: [] }); // , fromTimeOverlap: [], toTimeOverlap: [] 
    }

    // 
  };

  saveExotelBusinessConfig = async () => {
    if (this.state.firstReminder!== "" && this.state.firstReminder!== null && this.state.secondReminder!== "" && this.state.secondReminder!== null && (this.state.firstReminder > this.state.secondReminder || this.state.firstReminder === this.state.secondReminder))
     {
      this.setState({ firstRequired: true })
      return
    } 
    if (this.state.secondReminder !==null && this.state.secondReminder !== "" && this.state.thirdReminder !==null && this.state.thirdReminder !=="" && (this.state.secondReminder > this.state.thirdReminder || this.state.secondReminder < this.state.firstReminder || this.state.secondReminder === this.state.firstReminder || this.state.thirdReminder === this.state.secondReminder)) {
      this.setState({ secondRequired: true })
      return
    } 
    //if(!this.state.thirdReminder === "" && !this.state.thirdRequired){
    if (this.state.thirdReminder!== "" && this.state.secondReminder !== "" && this.state.firstReminder !=="" && this.state.thirdReminder!= null && this.state.secondReminder !== null && this.state.firstReminder !== null &&(this.state.thirdReminder < this.state.secondReminder || this.state.thirdReminder < this.state.firstReminder || this.state.thirdReminder === this.state.secondReminder || this.state.thirdReminder === this.state.firstReminder)) {
      this.setState({ thirdRequired: true })
      return
    }
    if(this.state.exotelSecondAdd && this.state.exotelSecondDelete && (this.state.secondReminder === null ||  this.state.secondReminder === "")){
      this.setState({exotelTwoError:true});
      if(this.state.exotelThirdDelete && (this.state.thirdReminder === null || this.state.thirdReminder === "")){
        this.setState({exotelThreeError:true})
        return
      }
      return
    }
    if(this.state.exotelThirdDelete && (this.state.thirdReminder === null || this.state.thirdReminder === "")){
      this.setState({exotelThreeError:true})
      return
    }
  //}
    const payload3 = {
      businessId: this.props.businessDetails._id,
      data: [
        {
          minute: this.state.firstReminder,
          whichReminder: '1st'
        },
        {
          minute: this.state.secondReminder,
          whichReminder: '2nd'
        },
        {
          minute: this.state.thirdReminder,
          whichReminder: '3rd'
        }
      ]
    }
    const payload2 = {
      businessId: this.props.businessDetails._id,
      data: [
        {
          minute: this.state.firstReminder,
          whichReminder: '1st'
        },
        {
          minute: this.state.secondReminder,
          whichReminder: '2nd'
        }
      ]
    }
    const payload1 = {
      businessId: this.props.businessDetails._id,
      data: [
        {
          minute: this.state.firstReminder,
          whichReminder: '1st'
        }
      ]
    } 
    
    if(this.state.exotelThirdDelete && !this.state.exotelFirstAdd && this.state.exotelSecondAdd && this.state.exotelSecondDelete){
      await this.props.setBusinessConfigLevel(payload3)
    }

    if(!this.state.exotelFirstAdd && this.state.exotelSecondAdd && this.state.exotelSecondDelete && !this.state.exotelThirdDelete){
      await this.props.setBusinessConfigLevel(payload2)
    } 
    // if(!this.state.exotelThirdDelete && this.state.exotelSecondAdd && this.state.exotelSecondDelete){
    //   console.log("....in payload2233")

    //   await this.props.setBusinessConfigLevel(payload2)
    // } 

    if( !this.state.exotelSecondDelete && !this.state.exotelSecondAdd && this.state.exotelFirstAdd)
     {
      await this.props.setBusinessConfigLevel(payload1)
    } 
   
   
    this.editExotelTimeConfigurationClose()
    let payload = {
      businessId: this.props.businessDetails._id
    };
    await this.props.getBusinessById(payload);
  }

  setSelectedSpeciality = async (event) => {
    // console.log("event.target.value", event.target.value);
    const value = event.target.value;

    // let associateSpecilaity = this.props.specialityDetails.filter(i => value.includes(i.name));

    // associateSpecilaity = { specialityIds: associateSpecilaity.map(i => i._id) };

    // let res = await axios.patch(`/api/business/${this.props.businessDetails._id}`, associateSpecilaity);
    // console.log(res);

    let specialityArr = this.props.specialityDetails.filter((speciality) => value.includes(speciality.name));
    // console.log("specialityArr-------------------", specialityArr);
    let nonSelectedSpecialities = this.props.specialityDetails.filter((speciality) => !value.includes(speciality.name));
    // console.log("nonSelectedSpecialities", nonSelectedSpecialities);

    this.setState({ selectedSpeciality: value, specialityList: [...specialityArr, ...nonSelectedSpecialities] });
    // this.getSpecialityCuisineList();
  }

  setSelectedCuisine = async (event) => {
    // console.log("event.target.value", event.target.value);
    const value = event.target.value;

    // let associateCuisine = this.props.cuisineDetails.filter(i => value.includes(i.name));

    // associateCuisine = { cuisineIds: associateCuisine.map(i => i._id) };
    // console.log("associateCuisine", associateCuisine);

    // let res = await axios.patch(`/api/business/${this.props.businessDetails._id}`, associateCuisine);
    // console.log(res);

    let cuisineArr = this.props.cuisineDetails.filter((cuisine) => value.includes(cuisine.name));
    // console.log("cuisineArr-------------------", cuisineArr);
    let nonSelectedCuisine = this.props.cuisineDetails.filter((cuisine) => !value.includes(cuisine.name));
    // console.log("nonSelectedCuisine", nonSelectedCuisine);

    this.setState({ selectedCuisine: value, cuisineList: [...cuisineArr, ...nonSelectedCuisine] });
    // this.getSpecialityCuisineList();
  }

  handleSpecialityDelete = async (specilaity) => {
    // console.log("specilaity==============>", specilaity);
    let filteredSpeciality = this.state.selectedSpeciality.filter(i => i !== specilaity);
    // let associateSpecilaity = this.props.specialityDetails.filter(i => deleteSpeciality.includes(i.name));

    // associateSpecilaity = { specialityIds: associateSpecilaity.map(i => i._id) };

    // let res = await axios.patch(`/api/business/${this.props.businessDetails._id}`, associateSpecilaity);
    // console.log(res);
    this.setState({ selectedSpeciality: filteredSpeciality });

  }

  // Bussiness Note event start
handleBusinessNote = (event) => {
    if (this.props.businessDetails.businessNote === event.target.value) {
      this.setState({ businessNote: event.target.value, businessNoteSave: true,businessNoteError:false })
    } else {
      this.setState({ businessNote: event.target.value, businessNoteSave: false,businessNoteError:false });
    }
  }

saveBusinessNote = async (event, data) => {
  let hasError= false;
    
 
  if(this.state.businessNote.trim() === ""){
    hasError = true;
    this.setState({ businessNoteError: true});  
  }
  if(!hasError){
  let payload = {
    businessId: this.props.businessDetails._id,
    data: {
      businessNote: this.state.businessNote,
    }
  }
  //console.log("Business Note payload",payload)
  await this.props.updateBusiness(payload);
  this.setState({ sucessMsg: "Business Note updated successfully" })
  this.setState({ snackOpen: true, businessNoteSave: true,businessNoteSaveFlag:true,isEditBusinessNoteFlag : false, isAddBusinessNoteFlag : false,businessNoteError:false,businessTextFieldFlag:false});
  this.getBusiness();  
};
};

closeDetails = () => {
  this.setState({
    businessNote: this.props.businessDetails.businessNote ? this.props.businessDetails.businessNote : "" ,isEditBusinessNoteFlag : false,isAddBusinessNoteFlag : false,businessNoteError:false,businessTextFieldFlag:false,businessNoteSaveFlag:false })
};
handleBusinessNoteFlagEnable  = () => {
  this.setState({ isEditBusinessNoteFlag: true,businessTextFieldFlag:true,businessNoteSaveFlag:false})
};

deleteBusinessNote = async () => {
  this.setState({ businessNote: "" })
  let payload = {
    businessId: this.props.businessDetails._id,
    data: {
      businessNote: null
    }
  }
  await this.props.updateBusiness(payload);
  this.setState({ sucessMsg: "Business Note cleared successfully" })
  this.setState({ snackOpen: true ,businessNoteSaveFlag:false});
  this.setState({ isEditBusinessNoteFlag : false,businessTextFieldFlag:true });
  this.setState({ isAddBusinessNoteFlag : true,businessNoteError:false  });
  this.getBusiness();
}
// Bussiness Note event End

  handleCuisineDelete = async (cuisine) => {
    // console.log("cuisine==============>", cuisine);
    let deleteCuisine = this.state.selectedCuisine.filter(i => i !== cuisine);
    // let associateCuisine = this.props.cuisineDetails.filter(i => deleteCuisine.includes(i.name));

    // associateCuisine = { cuisineIds: associateCuisine.map(i => i._id) };

    // let res = await axios.patch(`/api/business/${this.props.businessDetails._id}`, associateCuisine);
    // console.log(res);
    this.setState({ selectedCuisine: deleteCuisine });

  }
  
  handleNumberDelete = async (exotel) => {
    // console.log("cuisine==============>", cuisine);
    let deleteNumber = this.state.exotelContactNumbers.filter(i => i !== exotel);
    // let associateCuisine = this.props.cuisineDetails.filter(i => deleteCuisine.includes(i.name));

    // associateCuisine = { cuisineIds: associateCuisine.map(i => i._id) };

    // let res = await axios.patch(`/api/business/${this.props.businessDetails._id}`, associateCuisine);
    // console.log(res);
    this.setState({exotelContactNumbers: deleteNumber });

  }
 handleDeleteThirdExotel = async () => {
  this.setState({exotelThirdDelete:false,exotelSecondDelete:true,exotelSecondAdd:true,thirdRequired:false,thirdReminder:"",exotelThreeError:false})
 }
 handleDeleteSecondExotel = async () => {
  this.setState({exotelSecondDelete:false,exotelSecondAdd:false,exotelFirstAdd:true,thirdRequired:false,secondRequired:false,thirdReminder:"",secondReminder:"",exotelTwoError:false})
 }
 handleAddSecondExotel = async () =>{
  this.setState({exotelThirdDelete:true})
 }
 handleAddFirstExotel = async () =>{
  this.setState({exotelSecondDelete:true,exotelSecondAdd:true,exotelFirstAdd:false})
 }
 openConsumerCountModalOpen = async() => {
  
   this.setState({ consumerModalOpen: true, currentConsumerCount: null,
         calculatedConsumerCountModalOpen: false,
          openConsumerCountModalOpen: false, businessRadius: this.state.radius});
   }
getConsumerCount = async() => {
  this.props.requestBusinessDetailsRecords();
  let payload = {
    businessId: this.props.businessDetails.businessId,   
    latitude : this.state.latitude,
    longitude: this.state.longitude,
    radius: this.state.businessRadius 
  }; 
 
  
   await this.props.getConsumerCount(payload);
   this.setState({ calculatedConsumerCountModalOpen: true, currentConsumerCount: this.props.business.currentConsumerCount});
   this.props.requestBusinessDetailsRecordsFalse();
 
 }
  

  closeConsumerCountModalOpen = () => {
 
   this.setState({ consumerModalOpen: false, currentConsumerCount: null, openConsumerCountModalOpen: false, calculatedConsumerCountModalOpen:null, businessRadius: null});
 }
 
 handleChangeBusinessRadius = (event) => {      
        // console.log(".....inside amount...",this.state.amount);
        this.setState({businessRadius: event.target.value, calculatedConsumerCountModalOpen: false})
       
    
    }
  render() {  
    const { classes } = this.props;
    console.log("this.state.businessNote",this.state.businessNote)
    console.log("this.state.businessNote",this.props.businessDetails.businessNote)
    let accountManagerCall = [];
    let currentAccountManager = {};
    if (this.props.dashboard.setSelectedBusiness.accountManagerDetails && this.props.dashboard.setSelectedBusiness.accountManagerDetails.length > 0) {
      accountManagerCall.push(this.props.dashboard.setSelectedBusiness.accountManagerDetails && this.props.dashboard.setSelectedBusiness.accountManagerDetails.length > 0 ? this.props.dashboard.setSelectedBusiness.accountManagerDetails[this.props.dashboard.setSelectedBusiness.accountManagerDetails.length - 1] : "")
      currentAccountManager.accountManagerId = accountManagerCall[0].accountManager
    }
    else if (this.props.dashboard.setSelectedBusiness.accountManagerDetails) {
      currentAccountManager.accountManagerId = this.props.dashboard.setSelectedBusiness.accountManagerDetails.accountManager
    }

    // console.log("accountManagerCall---->", accountManagerCall)

    // console.log("currentAccountManager---->", currentAccountManager, typeof(currentAccountManager))


    let paymentMethodOptions = [];
    if (this.props.businessDetails.paymentMethodOptions.cod) {
      paymentMethodOptions.push("COD");
    }
    if (this.props.businessDetails.paymentMethodOptions.upi) {
      paymentMethodOptions.push("UPI");
    }
    if (this.props.businessDetails.paymentMethodOptions.banktransfer) {
      paymentMethodOptions.push("Bank Transfer");
    }
    if (this.props.businessDetails.paymentMethodOptions.cheque) {
      paymentMethodOptions.push("Cheque");
    }

    let amenities = [];
    if (this.props.businessDetails.amenities) {
      amenities = this.props.businessDetails.amenities.split(",");
    }

    let muiAlert = null;

    if (this.state.sucessMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
        {this.state.sucessMsg}
      </MuiAlert>
    } else if (this.state.errorMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
        {this.state.errorMsg}
      </MuiAlert>
    }

    // console.log("this.props in business details section----->",this.props);
    // console.log("working day array---->",this.state.workingDays);
    // console.log("holidays array----->",this.state.holidays);
    // console.log("this.state---->",this.state);
    // console.log("holidaysRequiredError------>",this.state.holidaysRequiredError)
    // console.log("holidaysError------>",this.state.holidaysError);
    // console.log("dayRequiredError------>",this.state.dayRequiredError);
    // console.log("fromRequiredError------>",this.state.fromRequiredError)
    // console.log("toRequiredError------>",this.state.toRequiredError);
    // console.log("fromError------>",this.state.fromError)
    // console.log("toError------>",this.state.toError)
    // console.log("invalidTime------>",this.state.invalidTime)
    // console.log("holidayWorkingDayError------>",this.state.holidayWorkingDayError)
    // console.log("fromTimeOverlap------>",this.state.fromTimeOverlap)
    // console.log("toTimeOverlap------>",this.state.toTimeOverlap)

    const businessOpenTillTime = this.state.businessOpenTillTime;
    const businessOpenTillTimeSave = this.state.businessOpenTillTimeSave;
    const businessNote = this.state.businessNote;
    const businessNoteSave = this.state.businessNoteSave;
    // console.log("specialityDetails", this.props.specialityDetails)
    // console.log("cuisineDetails", this.props.cuisineDetails)

    const isAllSelected =
      this.props.specialityDetails !== null && this.props.specialityDetails !== undefined && this.props.specialityDetails.length > 0 && this.state.selectedSpeciality.length === this.props.specialityDetails.length;

    const foundDefaultBussImg = this.props.businessDetails.businessCardImages ? this.props.businessDetails.businessCardImages.find((ele) => {
        return ele.foodType === "Default";
    }) : '';
    const foundVegBussImg = this.props.businessDetails.businessCardImages ? this.props.businessDetails.businessCardImages.find((ele) => {
      return ele.foodType === "Veg";
    }) : '';
    const foundNonvegBussImg = this.props.businessDetails.businessCardImages ? this.props.businessDetails.businessCardImages.find((ele) => {
      return ele.foodType === "NonVeg";
  }) : '';
    return (

      <Container>
        {this.props.businessDetails ? (
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <b>Business Id</b>
              </Grid>
              <Grid item xs={12} sm={3}>
                {this.props.businessDetails.businessId}
              </Grid>
              <Grid item xs={6} sm={3}>
                <PDFDownloadLink
                  style={{ float: "right" }}
                  document={
                    <MyDocument
                      businessName={this.props.businessDetails.businessName}
                      businessId={this.props.businessDetails.businessId}
                      qrCodeImage={URL.createObjectURL(
                        new Blob([this.props.business.qrCodeImage], {
                          type: "image/jpeg",
                        })
                      )}
                    />
                  }
                  fileName={`${this.props.businessDetails.businessName}_QR_Code.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download QR Code!"
                  }
                </PDFDownloadLink>
              </Grid>
              <Grid item xs={6} sm={3} style={{ float: "right" }}>
                {
                  (this.props.user.role === "areaManager" || this.props.user.role === "regionalHead") && !this.state.editDetails ?
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      onClick={this.editDetailsTrue}
                    >
                      EDIT BUSINESS DETAILS
                    </Button> : null
                }
              </Grid>
              <Grid item xs={12} sm={3}>
                <b>Category Type</b>
              </Grid>
              <Grid item xs={12} sm={9}>
                {(this.state.editDetails && (this.props.user.role === "areaManager")) ?
                  this.state.categoryTypeName : this.props.businessDetails.categoryType.name
                }
              </Grid>

              <Grid item xs={12} sm={3}>
                <b>Category</b>
              </Grid>
              <Grid item xs={12} sm={9}>
                {(this.state.editDetails && (this.props.user.role === "areaManager")) ? (
                  <FormControl className={classes.formControl}>
                    <NativeSelect
                      className={classes.selectEmpty}
                      value={this.state.categoryId}
                      name="category"
                      onChange={this.handleDropdownChange}
                    >
                      {this.props.categories.categoriesByType.map((category, key) => {
                        return (
                          <option key={key} name={category.name} value={category._id}>
                            {category.name}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </FormControl>
                ) : (
                  <Typography>
                    {this.props.businessDetails.category.name}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={3}>
                <b>Shop Name</b>
              </Grid>
              <Grid item xs={12} sm={9}>
                {(this.state.editDetails && (this.props.user.role === "areaManager")) ? (
                  <TextField
                    name="businessName"
                    label="Shop Name"
                    value={this.state.businessName}
                    onChange={this.handleChange}
                    variant="outlined"
                  />
                ) : (
                  <Typography>
                    {this.props.businessDetails.businessName}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={3}>
                <b>Description</b>
              </Grid>
              <Grid item xs={12} sm={9}>
                {(this.state.editDetails && (this.props.user.role === "areaManager")) ? (
                  <TextField
                    name="description"
                    label="Description"
                    value={this.state.description}
                    onChange={this.handleChange}
                    variant="outlined"
                  />
                ) : (
                  <Typography>
                    {this.props.businessDetails.description}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={3}>
                <b>Business Contact Name</b>
              </Grid>
              {this.state.editDetails ? (
                <Grid item xs={12} sm={9}>
                  <TextField
                    name="businessContactName"
                    label="Business Contact Name"
                    value={this.state.businessContactName}
                    onChange={this.handleChange}
                    variant="outlined"
                    error={this.state.businessContactNameError}
                  // helperText={this.state.businessContactNameError?"Enter Business Contact Name":null}
                  />
                  {this.state.businessContactNameError ? (
                    <FormHelperText style={{ color: "red" }}>
                      Enter Business Contact Name
                    </FormHelperText>
                  ) : null
                  }
                </Grid>
              ) : (
                <Grid item xs={12} sm={9}>
                  <Typography>
                    {this.props.businessDetails && this.props.businessDetails.businessContactName && this.props.businessDetails.businessContactName.length > 0 ? this.props.businessDetails.businessContactName : "NA"}
                  </Typography>
                </Grid>
              )}



              <Grid item xs={12} sm={3}>
                <b>Business Contact Number</b>
              </Grid>
              {this.state.editDetails ? (
                <Grid item xs={12} sm={9}>
                  <TextField
                    name="businessContactNumber"
                    label="Business Contact Number"
                    value={this.state.businessContactNumber ? this.state.businessContactNumber.trim().replace(/\D/g, "") : ""}
                    inputProps={{ maxLength: 10 }}
                    onChange={this.handleChange}
                    variant="outlined"
                    error={this.state.businessContactNumberError ? true : (this.state.businessContactNumberValidationError ? true : false)}
                  // helperText={this.state.businessContactNumberError?"Enter Business Contact Number":(this.state.businessContactNumberValidationError?"Invalid Contact Number":false)}
                  />
                  {this.state.businessContactNumberError ? (
                    <FormHelperText style={{ color: "red" }}>
                      Enter Business Contact Number
                    </FormHelperText>
                  ) : (
                    this.state.businessContactNumberValidationError ? (
                      <FormHelperText style={{ color: "red" }}>
                        Contact Number should 10 digits
                      </FormHelperText>
                    ) : null
                  )
                  }
                </Grid>
              ) : (<Grid item xs={12} sm={9}>
                <Typography>
                  {this.props.businessDetails && this.props.businessDetails.businessContactNumber && this.props.businessDetails.businessContactNumber.length > 0 ? this.props.businessDetails.businessContactNumber : "NA"}
                </Typography>
              </Grid>
              )}

              
              <Grid item xs={12} sm={3} >
                <b>Auto Call Reminder</b>
              </Grid>


              {(this.state.editDetails && (this.props.user.role === "areaManager")) ? (
                <Grid item xs={10} sm={9}>
                  {
                  <div className='row'> 
                   <Grid item xs={6} sm={3} >
                        <TextField
                          name="exotelTextField"
                          label="Enter Mobile Number"
                          variant="outlined"
                          onChange={this.handleChange}
                          // value={this.state.businessVideo}
                        value= { this.state.exotelTextField ? this.state.exotelTextField.trim().replace(/\D/g, "") : ""}
                        inputProps={{ maxLength: 10 }}
                         style={{ marginLeft : "15px" ,width : "180px"}}
                          // error={this.state.exotelContactNumbersError ? this.state.exotelContactNumbersError : (this.state.exotelContactNumbersErrorExists)}
                          // helperText={this.state.exotelContactNumbersError ? "Required" : (this.state.exotelContactNumbersErrorExists ? "Contact  Number Already Exists" : null)}
                        />
                          
                          {this.state.exotelContactNumbersError ? (
                            <FormHelperText style={{ color: "red" }}>
                              Required
                            </FormHelperText>
                          ) : null}
                          {this.state.exotelContactNumbersErrorExists ? (
                            <FormHelperText style={{ color: "red" }}>
                              Contact  Number Already Exists
                            </FormHelperText>
                          ) : null}
                             {this.state.exotelContactNumberslengthError ? (
                            <FormHelperText style={{ color: "red" }}>
                              Max 5 Numbers can be added
                            </FormHelperText>
                          ) : null}
                          {this.state.exotelContactNumbersInvalidError ? (
                            <FormHelperText style={{ color: "red" }}>
                              Invalid Contact Number
                            </FormHelperText>
                          ) : null}

                                                  
                    </Grid>
                    {/* {this.state.exotelContactNumbers.length < 5 ? */}
                        <Grid item xs={6} sm={1} >
                           
                              <Button style={{
                                marginLeft: "25px",
                                marginTop: "5px"
                                // fontWeight: 'bold', backgroundColor: '#07c6db', color: '#ffffff', height: "55px", width: "50px",
                                // // marginLeft:"0px", 
                                // fontSize: "1rem"
                              }} variant="contained"
                                onClick={() => { this.updateExotelNumber() }}
                                disabled={this.state.exotelContactNumbers.length >= 5}
                                color="primary"

                              >
                                +
                              </Button>
                            
                    </Grid>
                    <Grid>  {this.state.exotelContactNumbers.length >= 5 ? (
                            <FormHelperText style={{ color: "grey", marginTop:"15px" ,marginLeft:"35px"}}>
                              Max 5 Numbers can be added
                            </FormHelperText>
                          ) : null} </Grid>
                 
           {/* }  */}
                
                </div>
                    
    
                  }
                  {this.state.exotelContactNumbers.length > 0 ?
              
                        <Grid item xs={12} sm={9}>
                          <div style={{ backgroundColor: '#F5F5F5', borderRadius: '5px', padding: '15px', marginTop: '10px' }}>
                            {
                              this.state.exotelContactNumbers.map((exotel, i) => {
                                return (
                                  < Chip
                                    className={classes.chip}
                                    label={exotel}
                                    key={exotel}
                                    variant="outlined"
                                    deleteIcon={<Delete />}
                                    color="primary"
                                    onDelete={() => this.handleNumberDelete(exotel)}
                                  />
                                );
                              })
                            }
                          </div>
                        </Grid>
                        :
                        <Grid item xs={12} sm={3} style={{marginTop:'30px'}}> Not Available</Grid>
                     
                   
                  
                  }
                </Grid>
              ) :

                (this.state.exotelContactNumbers.length > 0) ?
                <Grid item xs={12} sm={6}>
                <div style={{ backgroundColor: '#F5F5F5', borderRadius: '5px', padding: '15px', marginTop: '10px' }}>
                  {
                    this.state.exotelContactNumbers.map((exotel, i) => {
                      return (
                        < Chip
                          className={classes.chip}
                          label={exotel}
                          key={exotel}
                          variant="outlined"
                          
                        />
                      );
                    })
                  }
                </div>
              </Grid>
                  :
                  <Grid item xs={12} sm={9}>Not Available</Grid>

              }


              {/* <Grid item xs={12} sm={3} hidden={this.props.user.role === 'admin' ? true : false}>
                <TextField
                  name="exotelTextField"
                  label="Enter Phone Number"
                  variant="outlined"
                  onChange={this.handleChange}
                  // value={this.state.businessVideo}
                 value={this.state.exotelTextField}
                  // style={{ width: "100px",}}
                  // error={this.state.exotelContactNumbersError ? this.state.exotelContactNumbersError : (this.state.exotelContactNumbersErrorExists)}
                  // helperText={this.state.exotelContactNumbersError ? "Required" : (this.state.exotelContactNumbersErrorExists ? "Contact  Number Already Exists" : null)}
                />
                  
                   {this.state.exotelContactNumbersError ? (
                    <FormHelperText style={{ color: "red" }}>
                      Required
                    </FormHelperText>
                  ) : null}
                                         
              </Grid>

              <Grid item xs={2} sm={2} hidden={this.props.user.role === 'admin' ? true : false}>
                <ButtonGroup className={classes.buttonGroup} size="small" aria-label="small outlined primary button group" fullWidth >
                  <Button style={{
                    fontWeight: 'bold', backgroundColor: '#07c6db', color: '#ffffff', height: "55px", width: "50px",
                    // marginLeft:"0px", 
                    fontSize: "1rem"
                  }} variant="contained"
                    onClick={() => { this.updateExotelNumber() }}
                  >
                    +
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid>
              {this.props.businessDetails.exotelContactNumbers && this.props.businessDetails.exotelContactNumbers.length ?
                    <Grid item xs={12} sm={9}>
                      <div style={{ backgroundColor: '#F5F5F5', borderRadius: '5px', padding: '15px', marginTop: '10px' }}>
                        {
                          this.state.exotelContactNumbers.map((exotel, i) => {
                            return (
                              < Chip
                                className={classes.chip}
                                label={exotel}
                                key={exotel}
                                variant="outlined"
                                deleteIcon={<Cancel />}
                                onDelete={() => this.handleNumberDelete(exotel)}
                              />
                            );
                          })
                        }
                      </div>
                    </Grid>
                    :
                    <Grid item xs={12} sm={3}>No exotel contact available  </Grid>
                  }
              </Grid>
             
          
              <Grid item xs={2} sm={1} hidden={this.props.user.role === 'admin' ? true : false}></Grid>
              */}
                 <Grid item xs={12}></Grid>
              <Grid item xs={12} sm={3}>
                <b>GST Number</b>
              </Grid>
              <Grid item xs={12} sm={9}>
                {(this.state.editDetails && (this.props.user.role === "areaManager")) ? (
                  <TextField
                    name="gstin"
                    label="GST Number"
                    value={this.state.gstin ? this.state.gstin : null}
                    onChange={this.handleChange}
                    variant="outlined"
                  />
                ) : (
                  <Typography>
                    {this.props.businessDetails.gstin
                      ? this.props.businessDetails.gstin
                      : "Not Available"}
                  </Typography>
                )}
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
                className={classes.img}
                style={{ padding: "12px" }}
              >
                <Grid item xs={12} sm={3}>
                  <b>Shop Act</b>
                </Grid>
                {/* {!this.state.editDetails ? (
                  <Grid item xs={12} sm={3} style={{ paddingLeft: "6px" }}>
                    {this.props.businessDetails.shopAct &&
                      this.props.businessDetails.shopAct.number
                      ? this.props.businessDetails.shopAct.number
                      : "Not Available"}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={9} style={{ paddingLeft: "6px" }}>
                    <Input type="file" name="shopAct" inputProps={{ accept: "image/x-png,image/jpeg" }} onChange={this.handleFileUpload} placeholder="White Icon" disableUnderline />
                  </Grid>
                )} */}
                {(this.state.editDetails && (this.props.user.role === "areaManager")) ?
                  (
                    <Grid item xs={12} sm={9} style={{ paddingLeft: "6px" }}>
                      <Input type="file" name="shopAct" inputProps={{ accept: "image/x-png,image/jpeg" }} onChange={this.handleFileUpload} placeholder="White Icon" disableUnderline />
                    </Grid>
                  ) :
                  (
                    <Grid item xs={12} sm={3} style={{ paddingLeft: "6px" }}>
                      {this.props.businessDetails.shopAct &&
                        this.props.businessDetails.shopAct.number
                        ? this.props.businessDetails.shopAct.number
                        : "Not Available"}
                    </Grid>
                  )}
                {(this.state.editDetails && (this.props.user.role === "areaManager")) ?
                  (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3} style={{ marginTop: "15px" }}>
                        <b>Shop Act Number</b>
                      </Grid>
                      <Grid item xs={12} sm={9} style={{ paddingLeft: "6px", marginTop: "15px" }}>
                        <TextField
                          name="shopActNo"
                          label="ShopAct Number"
                          value={this.state.shopActNo}
                          onChange={this.handleChange}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  ) :
                  (
                    <Grid item xs={12} sm={6}>
                      {this.props.business.shopAct ? (
                        <img
                          src={URL.createObjectURL(
                            new Blob([this.props.business.shopAct], {
                              type: "image/jpeg",
                            })
                          )}
                          alt="Shop Act"
                          width="400"
                          height="250"
                        />
                      ) : null}
                    </Grid>
                  )}


                {/* {!this.state.editDetails ? (
                  <Grid item xs={12} sm={6}>
                    {this.props.business.shopAct ? (
                      <img
                        src={URL.createObjectURL(
                          new Blob([this.props.business.shopAct], {
                            type: "image/jpeg",
                          })
                        )}
                        alt="Shop Act"
                        width="400"
                        height="250"
                      />
                    ) : null}
                  </Grid>
                ) : <Grid container spacing={3}>
                  <Grid item xs={12} sm={3} style={{ marginTop: "15px" }}>
                    <b>Shop Act Number</b>
                  </Grid>
                  <Grid item xs={12} sm={9} style={{ paddingLeft: "6px", marginTop: "15px" }}>
                    <TextField
                      name="shopActNo"
                      label="ShopAct Number"
                      value={this.state.shopActNo}
                      onChange={this.handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>} */}
              </Grid>


              <Grid item xs={12} sm={3}>
                <b>FSSAI Lic. No.</b>
              </Grid>

              <Grid item xs={12} sm={9}>
                {(this.state.editDetails && (this.props.user.role === "areaManager")) ? (
                  <Grid item xs={12} sm={9}>
                    <TextField
                      name="FSSAILicNumber"
                      label="FSSAI Lic. No."
                      inputProps={{ maxLength: 14 }}
                      // error={(this.state.FSSAILicNumber && this.state.FSSAILicNumber.trim().length != 14  && this.state.FSSAILicNumber.trim().length != 0) ? "FSSAI License No. should be 14 digits" : null}
                      error={this.state.FSSAILicNumberValidationError}
                      value={this.state.FSSAILicNumber ? this.state.FSSAILicNumber.trim().replace(/\D/g, "") : ""}
                      onChange={this.handleChange}
                      variant="outlined"
                    />

                    {(this.state.FSSAILicNumber && this.state.FSSAILicNumber.trim().length !== 14 && this.state.FSSAILicNumber.trim().length !== 0 && this.state.FSSAILicNumberValidationError) ? (
                      <FormHelperText style={{ color: "red" }}>
                        FSSAI License No. should be 14 digits
                      </FormHelperText>
                    ) : null}
                  </Grid>
                ) : (
                  <Typography>
                    {(this.props.businessDetails.FSSAILicNumber && this.props.businessDetails.FSSAILicNumber.trim()) ? this.props.businessDetails.FSSAILicNumber.trim() : "NA"}
                  </Typography>
                )}
              </Grid>



              <Grid item xs={12} sm={3} style={{ marginTop: "15px" }}>
                <b>Shop Number</b>
              </Grid>
              <Grid item xs={12} sm={9}>
                {(this.state.editDetails && (this.props.user.role === "areaManager")) ? (
                  <TextField
                    name="shopNo"
                    label="Shop Number"
                    value={this.state.shopNo}
                    onChange={this.handleChange}
                    variant="outlined"
                  />
                ) : (
                  <Typography>
                    {this.props.businessDetails.address.shopNo}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={3}>
                <b>Street Name</b>
              </Grid>
              <Grid item xs={12} sm={9}>
                {(this.state.editDetails && (this.props.user.role === "areaManager")) ? (
                  <TextField
                    name="street"
                    label="Street"
                    value={this.state.street}
                    onChange={this.handleChange}
                    variant="outlined"
                  />
                ) : (
                  <Typography>
                    {this.props.businessDetails.address.street}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={3}>
                <b>Landmark</b>
              </Grid>
              <Grid item xs={12} sm={9}>
                {(this.state.editDetails && (this.props.user.role === "areaManager")) ? (
                  <TextField
                    name="landmark"
                    label="Landmark"
                    value={this.state.landmark}
                    onChange={this.handleChange}
                    variant="outlined"
                  />
                ) : (
                  <Typography>
                    {this.props.businessDetails.address.landmark}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={3}>
                <b>City</b>
              </Grid>
              <Grid item xs={12} sm={9}>
                {this.props.businessDetails.address.city}
              </Grid>

              <Grid item xs={12} sm={3}>
                <b>State</b>
              </Grid>
              <Grid item xs={12} sm={9}>
                {this.props.businessDetails.address.state}
              </Grid>

              <Grid item xs={12} sm={3}>
                <b>Pin Code</b>
              </Grid>
              {(this.state.editDetails && (this.props.user.role === "areaManager")) ? (
                <Grid item xs={12} sm={9}>
                  <TextField
                    name="pinCode"
                    label="Pin Code"
                    value={this.state.pinCode}
                    onChange={this.handleChange}
                    variant="outlined"
                    error={!this.state.pinCode ? true : (this.state.pinCodeValidationError ? true : false)}
                  />
                  {!this.state.pinCode ? (
                    <FormHelperText style={{ color: "red" }}>
                      Required
                    </FormHelperText>
                  ) : this.state.pinCodeValidationError ? (
                    <FormHelperText style={{ color: "red" }}>
                      Invalid pincode
                    </FormHelperText>
                  ) : null}
                </Grid>
              ) : (
                <Grid item xs={12} sm={9}>
                  <Typography>
                    {this.props.businessDetails.address.pinCode}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} sm={3}>
                <b>Location Lat/Long</b>
              </Grid>
              {(this.state.editDetails && (this.props.user.role === "areaManager")) ? (
                <Grid item xs={12} sm={4}>
                  <TextField
                    name="latitude"
                    label="Latitude"
                    value={this.state.latitude}
                    onChange={this.handleChange}
                    variant="outlined"
                    error={!this.state.latitude ? true : (this.state.latitudeError ? true : false)}
                  />
                  {!this.state.latitude ? (
                    <FormHelperText style={{ color: "red" }}>
                      Required
                    </FormHelperText>
                  ) : this.state.latitudeError ? (
                    <FormHelperText style={{ color: "red" }}>
                      Invalid latitude
                    </FormHelperText>
                  ) : null}
                </Grid>
              ) : null}
              {(this.state.editDetails && (this.props.user.role === "areaManager")) ? (
                <Grid item xs={12} sm={5}>
                  <TextField
                    name="longitude"
                    label="Longitude"
                    value={this.state.longitude}
                    onChange={this.handleChange}
                    variant="outlined"
                    error={!this.state.longitude ? true : (this.state.longitudeError ? true : false)}
                  />
                  {!this.state.longitude ? (
                    <FormHelperText style={{ color: "red" }}>
                      Required
                    </FormHelperText>
                  ) : this.state.longitudeError ? (
                    <FormHelperText style={{ color: "red" }}>
                      Invalid longitude
                    </FormHelperText>
                  ) : null}
                </Grid>
              ) : (
                <Grid item xs={12} sm={9}>
                  <Typography>
                    {this.props.businessDetails.address.location.coordinates[1]},{this.props.businessDetails.address.location.coordinates[0]}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} sm={3}>
                <b>Phone Number</b>
              </Grid>
              {(this.state.editDetails && (this.props.user.role === "areaManager")) ? (
                <Grid item xs={12} sm={9}>
                  <TextField
                    name="primaryContactNumber"
                    label="Phone Number"
                    value={this.state.primaryContactNumber ? this.state.primaryContactNumber.trim().replace(/\D/g, "") : ""}
                    inputProps={{ maxLength: 10 }}
                    onChange={this.handleChange}
                    variant="outlined"
                    error={!this.state.primaryContactNumber ? true : (this.state.phoneValidationError ? true : false)}
                  />
                  {!this.state.primaryContactNumber ? (
                    <FormHelperText style={{ color: "red" }}>
                      Required
                    </FormHelperText>
                  ) : this.state.phoneValidationError ? (
                    <FormHelperText style={{ color: "red" }}>
                      Invalid Mobile Number
                    </FormHelperText>
                  ) : null}
                </Grid>
              ) : (
                <Grid item xs={12} sm={9}>
                  <Typography>
                    {this.props.businessDetails.primaryContactNumber}
                  </Typography>
                </Grid>
              )}
              {/* is Cashfree Enabled */}
              <Grid item xs={6} sm={3}>
                <b>Is Cashfree enabled?</b>
              </Grid>

              <Grid item xs={6} sm={9}>
                {this.state.editDetails && this.props.user.role === "areaManager" ?
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        //key={`xirifyAvailabilitySwitch_${busObj.businessId}_${index}`}
                        checked={this.state.isCashfreeEnabled}
                        onChange={this.handleCashfreeEnableChange}
                        color="primary"
                      />
                    }
                    style={{ fontSize: "10px", color: this.state.isCashfreeEnabled ? 'black' : 'red' }}
                    label={this.state.isCashfreeEnabled ? 'Yes' : 'No'}
                  /> : this.state.isCashfreeEnabled ? 'Yes' : 'No'
                }
              </Grid>

              {/* Cashfree vendor Id */}
              {this.state.isCashfreeEnabled ?
                <>
                  <Grid item xs={6} sm={3} >
                    <b>Cashfree Vendor ID</b>
                  </Grid>

                  <Grid item xs={6} sm={9}>
                    {this.state.editDetails && this.props.user.role === "areaManager" ?
                      <TextField
                        name="cashfreeVendorId"
                        label="Cashfree Vendor ID"
                        variant="outlined"
                        onChange={this.handleChange}
                        value={this.state.cashfreeVendorId}
                        style={{ width: "265px" }}
                        error={!this.state.cashfreeVendorId ? true : (this.state.cashfreevendorIdError ? true : false)}
                      /> : this.state.cashfreeVendorId
                    }
                    {this.state.editDetails && this.props.user.role === "areaManager" && !this.state.cashfreeVendorId &&
                      (
                        <FormHelperText style={{ color: "red" }}>
                          Required
                        </FormHelperText>
                      )}
                    {this.state.editDetails && this.props.user.role === "areaManager" && this.state.cashfreevendorIdError && (
                      <FormHelperText style={{ color: "red" }}>
                        Invalid Cashfree Vendor ID
                      </FormHelperText>
                    )}

                  </Grid>
                </> : null}
              {/* is petPooja Enabled */}
              <Grid item xs={6} sm={3}>
                <b>Is PetPooja enabled?</b>
              </Grid>

              <Grid item xs={6} sm={9}>
                {this.state.editDetails && this.props.user.role === "areaManager" ?
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        //key={`xirifyAvailabilitySwitch_${busObj.businessId}_${index}`}
                        checked={this.state.isPetpoojaEnabled}
                        onChange={this.handlePetPoojaEnableChange}
                        color="primary"
                      />
                    }
                    style={{ fontSize: "10px", color: this.state.isPetpoojaEnabled ? 'black' : 'red' }}
                    label={this.state.isPetpoojaEnabled ? 'Yes' : 'No'}
                  /> : this.state.isPetpoojaEnabled ? 'Yes' : 'No'
                }
              </Grid>

              {/* petpooja restaurant Id */}
              {this.state.isPetpoojaEnabled ?
                <>
                  <Grid item xs={6} sm={3} >
                    <b>Pet Pooja Restaurant ID</b>
                  </Grid>

                  <Grid item xs={6} sm={9}>
                    {this.state.editDetails && this.props.user.role === "areaManager" ?
                      <TextField
                        name="petPoojaRestaurantId"
                        label="Pet Pooja Restaurant ID"
                        variant="outlined"
                        onChange={this.handleChange}
                        value={this.state.petPoojaRestaurantId}
                        style={{ width: "265px" }}
                        error={!this.state.petPoojaRestaurantId ? true : (this.state.petPoojaRestaurantIdError ? true : false)}
                      /> : this.state.petPoojaRestaurantId
                    }
                    {this.state.editDetails && this.props.user.role === "areaManager" && !this.state.petPoojaRestaurantId &&
                      (
                        <FormHelperText style={{ color: "red" }}>
                          Required
                        </FormHelperText>
                      )}
                    {this.state.editDetails && this.props.user.role === "areaManager" && this.state.petPoojaRestaurantIdError && (
                      <FormHelperText style={{ color: "red" }}>
                        Invalid Petpooja Restaurant ID
                      </FormHelperText>
                    )}

                  </Grid>
                </> : null}

              <Grid item xs={6} sm={3}>
                <b>Is Business Packaging Charges Enabled?</b>
              </Grid>

              <Grid item xs={6} sm={9}>
                {this.state.editDetails && this.props.user.role === "areaManager" ?
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        //key={`xirifyAvailabilitySwitch_${busObj.businessId}_${index}`}
                        checked={this.state.isPackagingChargesEnabled}
                        onChange={this.handlePackagingChargesEnabledChange}
                        color="primary"
                      />
                    }
                    style={{ fontSize: "10px", color: this.state.isPackagingChargesEnabled ? 'black' : 'red' }}
                    label={this.state.isPackagingChargesEnabled ? 'Yes' : 'No'}
                  /> : this.state.isPackagingChargesEnabled ? 'Yes' : 'No'
                }
              </Grid>

              {/* petpooja restaurant Id */}
              {this.state.isPackagingChargesEnabled ?
                <>
                  <Grid item xs={6} sm={3} >
                    <b>Business Packaging Charges</b>
                  </Grid>

                  <Grid item xs={6} sm={9}>
                    {this.state.editDetails && this.props.user.role === "areaManager" ?
                      <TextField
                        name="businessPackagingCharges"
                        label="Business Packaging Charges"
                        variant="outlined"
                        onChange={this.handleChange}
                        value={this.state.businessPackagingCharges}
                        style={{ width: "265px" }}
                        error={!this.state.businessPackagingCharges ? true : (this.state.packagingChargesError ? true : false)}
                      /> : (" ₹ " + this.state.businessPackagingCharges)
                    }
                    {this.state.editDetails && this.props.user.role === "areaManager" && !this.state.businessPackagingCharges &&
                      (
                        <FormHelperText style={{ color: "red" }}>
                          Required
                        </FormHelperText>
                      )}
                    {this.state.editDetails && this.props.user.role === "areaManager" && this.state.packagingChargesError && (
                      <FormHelperText style={{ color: "red" }}>
                        "000.00" format is allowed
                      </FormHelperText>
                    )}

                  </Grid>
                </> : null}
            
                <Grid item xs={6} sm={3} >
                    <b>Business Radius</b>
                  </Grid>

                  <Grid item xs={6} sm={1}>
                    {this.state.editDetails && this.props.user.role === "areaManager" ? 
                      <TextField
                        name="radius"
                        // label="Business Radius"
                        variant="outlined"
                        onChange={this.handleChange}
                        value={this.state.radius}
                        style={{ width: "100px" }}
                        error = {(this.state.radius === 0 || (!/^([\d]+)(?:\.([\d]{1,3}?))?$/.test(this.state.radius))) ?  "Invalid" : null}
                helperText={ (this.state.radius === 0 || (!/^([\d]+)(?:\.([\d]{1,3}?))?$/.test(this.state.radius))) ?  "Invalid" : null}
                      /> : (this.state.radius + " Km")
                    }
                   
                  </Grid>
                  {this.state.editDetails && this.props.user.role === "areaManager" ? 
                  <Grid item xs={6} sm={1} style={{marginTop: "15px", marginLeft:"10px"}}>Km</Grid> : null}
                
                  {this.props.user.role === "areaManager" && this.state.editDetails ? 
                         <Grid item xs={6} sm={6}> 
                                            <IconButton
                                              style={{
                                                float: "center",
                                                padding: "0px",
                                                background: "none",
                                                marginTop: "10px"

                                              }}
                                              onClick={(e) => this.openConsumerCountModalOpen()}
                                            >
                                              <Avatar style={{
                                                background: "none"

                                              }}>
                                                <LINK />
                                              </Avatar>
                                            </IconButton> </Grid>
                                               :<Grid item xs={6} sm={6}></Grid>
                                     }
             
                <Grid item xs={6} sm={3} >
                    <b>Consumer Count</b>
                  </Grid>

                  <Grid item xs={6} sm={9}>                    
                  {this.props.businessDetails.consumersCount}                 
                   
                  </Grid>

              <Grid item xs={12} sm={3}>
                <b>Speciality</b>
              </Grid>

              {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "regionalHead")) ? (
                <Grid item xs={12} sm={9}>
                  {this.props.specialityDetails && this.props.specialityDetails.length &&
                    <Grid item xs={6} sm={9}>
                      <FormControl variant="outlined" style={{ width: '200px' }} className={classes.formControl}>
                        <InputLabel id="mutiple-select-label">{(this.state.selectedSpeciality.length) ? "Speciality" : "NA"}</InputLabel>
                        <Select
                          label="Speciality"
                          labelId="mutiple-select-label"
                          multiple
                          value={this.state.selectedSpeciality}
                          onChange={this.setSelectedSpeciality}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {this.state.specialityList.map((speciality, i) => (
                            <MenuItem key={i} value={speciality.name}>
                              <ListItemIcon>
                                <Checkbox checked={this.state.selectedSpeciality.indexOf(speciality.name) > -1} />
                              </ListItemIcon>
                              <ListItemText primary={speciality.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  }
                  {this.state.selectedSpeciality.length ?
                    <Grid item xs={12} sm={9}>
                      <div style={{ backgroundColor: '#F5F5F5', borderRadius: '5px', padding: '15px', marginTop: '10px' }}>
                        {
                          this.state.selectedSpeciality.map((speciality, i) => {
                            return (
                              < Chip
                                className={classes.chip}
                                label={speciality}
                                key={speciality}
                                variant="outlined"
                                deleteIcon={<Cancel />}
                                onDelete={() => this.handleSpecialityDelete(speciality)}
                              />
                            );
                          })
                        }
                      </div>
                    </Grid>
                    :
                    <Grid item xs={12} sm={9}>No Specialities Available</Grid>
                  }
                </Grid>
              ) :

                (this.state.selectedSpeciality.length) ?
                  <Grid item xs={12} sm={9}>
                    <div style={{ backgroundColor: '#F5F5F5', borderRadius: '5px', padding: '15px', marginTop: '10px' }}>
                      {
                        this.state.selectedSpeciality.map((speciality, i) => {
                          // console.log("speciality", speciality);
                          return (
                            < Chip
                              className={classes.chip}
                              variant="outlined"
                              label={speciality}
                              key={i}
                            />
                          );
                        })
                      }
                    </div>
                  </Grid>
                  :
                  <Grid item xs={12} sm={9}>No Specialities Available</Grid>

              }

              <Grid item xs={12} sm={3}>
                <b>Cuisine</b>
              </Grid>
              {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "regionalHead")) ?

                <Grid item xs={12} sm={9}>
                  {this.props.cuisineDetails && this.props.cuisineDetails.length &&
                    <Grid item xs={6} sm={3}>
                      <FormControl variant="outlined" style={{ width: '200px' }} className={classes.formControl}>
                        <InputLabel id="mutiple-select-label">{(this.state.selectedCuisine.length) ? "Cuisine" : "NA"}</InputLabel>
                        <Select
                          label="Cuisine"
                          labelId="mutiple-select-label"
                          multiple
                          value={this.state.selectedCuisine}
                          onChange={this.setSelectedCuisine}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {this.state.cuisineList.map((cuisine, i) => (
                            <MenuItem key={i} value={cuisine.name}>
                              <ListItemIcon>
                                <Checkbox checked={this.state.selectedCuisine.indexOf(cuisine.name) > -1} />
                              </ListItemIcon>
                              <ListItemText primary={cuisine.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>}
                  {this.state.selectedCuisine.length ?

                    <Grid item xs={12} sm={9}>
                      <div style={{ backgroundColor: '#F5F5F5', borderRadius: '5px', padding: '15px', marginTop: '10px' }}>

                        {
                          this.state.selectedCuisine.map((cuisine, i) => {
                            return (
                              <Chip
                                className={classes.chip}
                                label={cuisine}
                                key={i}
                                variant="outlined"
                                deleteIcon={<Cancel />}
                                // deleteIcon={<Delete />}
                                onDelete={() => this.handleCuisineDelete(cuisine)}
                              />
                            )
                          })
                        }
                      </div>
                    </Grid>
                    :
                    <Grid item xs={12} sm={9}>No Cuisines Available</Grid>
                  }
                </Grid>
                :

                (this.state.selectedCuisine.length) ?
                  <Grid item xs={12} sm={9}>
                    <div style={{ backgroundColor: '#F5F5F5', borderRadius: '5px', padding: '15px', marginTop: '10px' }}>
                      {
                        this.state.selectedCuisine.map((cuisine, i) => {
                          return (
                            <Chip
                              className={classes.chip}
                              label={cuisine}
                              variant="outlined"
                              key={i}
                            />
                          )
                        })
                      }
                    </div>
                  </Grid>
                  :
                  <Grid item xs={12} sm={9}>No Cuisines Available</Grid>


              }

              {
                this.state.editDetails ? (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Button
                      style={{ width: "15%", margin: "10px" }}
                      variant="contained"
                      margin="normal"
                      component="span"
                      onClick={this.closeEditDetails}
                    >
                      CANCEL
                    </Button>
                    <Button
                      style={{ width: "15%", margin: "10px" }}
                      variant="contained"
                      color="primary"
                      margin="normal"
                      component="span"
                      onClick={this.saveDetails}
                    >
                      SAVE
                    </Button>
                  </Grid>
                ) : null
              }

              < Grid item xs={6} sm={3} >
                <b>Timings</b>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Button fullWidth variant="contained" margin="normal" color="primary"
                  onClick={this.openAddTimingModal}
                // disabled = {(this.props.businessDetails.availability.workingHrs.length>0 || this.props.businessDetails.availability.holidays.length>0)}
                >Add/Edit Timings</Button>
              </Grid>
              <Grid item xs={3} sm={1}>
                {/* <Button
                  fullWidth variant="contained" margin="normal" color="primary"
                  onClick={this.openEditTimingModal}
                  // disabled = {(!this.props.businessDetails.availability.workingHrs && !this.props.businessDetails.availability.holidays) || (this.props.businessDetails.availability.workingHrs.length==0 && this.props.businessDetails.availability.holidays.length==0)}
                >Edit Timings</Button> */}
              </Grid>
              <Grid item xs={6} sm={5}></Grid>
              <Grid item xs={6} sm={3}></Grid>
              <Grid item xs={6} sm={9}>
                {(this.props.businessDetails.availability.workingHrs &&
                  this.props.businessDetails.availability.workingHrs.length > 0)
                  ? (
                    <TableContainer>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell><b>Day/Days</b></TableCell>
                            <TableCell align="right"><b>From</b></TableCell>
                            <TableCell align="right"><b>To</b></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.props.businessDetails.availability.workingHrs.map(
                            (entry) => {
                              return (
                                <TableRow key={entry.day}>
                                  <TableCell component="th" scope="row">
                                    {entry.day}
                                  </TableCell>
                                  <TableCell align="right">
                                    {entry.from.toUpperCase()}
                                  </TableCell>
                                  <TableCell align="right">{entry.to.toUpperCase()}</TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    (this.props.businessDetails.availability.holidays &&
                      this.props.businessDetails.availability.holidays.length >
                      0) ? null :
                      "No Business timings available."
                  )}

                {this.props.businessDetails.availability.holidays &&
                  this.props.businessDetails.availability.holidays.length >
                  0 ? (
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      {this.props.businessDetails.availability.workingHrs &&
                        this.props.businessDetails.availability.workingHrs.length === 0 ?
                        <TableHead>
                          <TableRow>
                            <TableCell> <b>Day/Days</b></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                          </TableRow>
                        </TableHead>
                        : null
                      }

                      <TableBody>
                        {this.props.businessDetails.availability.holidays.map(
                          (holiday, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell component="th" scope="row">{holiday}</TableCell>
                                <TableCell align="center" style={{ paddingRight: "30px" }}>Closed</TableCell>
                                <TableCell align="center"></TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3}>
                <b>Business open till</b>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl variant="outlined" fullWidth className={classes.formControl}  >
                  <Select labelId="demo-simple-select-outlined-label" id="businessOpenTill" value={businessOpenTillTime} onChange={this.handleOpenTillTime} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                    {this.state.to.map((index) => {
                      return (
                        <MenuItem key={index} value={index.from} style={{ minHeight: "28px", height: "28px" }}>
                          <Typography noWrap>{index.from.toUpperCase()}</Typography>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} >
                <Button
                  fullWidth
                  style={{ margin: "5px" }}
                  variant="contained"
                  disabled={!businessOpenTillTime || businessOpenTillTimeSave}
                  color="primary"
                  onClick={this.saveOpenTillTime}
                >
                  SAVE
                </Button></Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  fullWidth
                  style={{ margin: "5px" }}
                  variant="contained"
                  color="primary"
                  onClick={this.clearOpenTillTime}
                >
                  CLEAR
                </Button></Grid>
              <Grid item xs={12} sm={3}>
                <b>Account Manager</b>
              </Grid>
              {(this.props.user.role === "regionalHead") ? (

                <Grid item xs={12} sm={3}>

                  <FormControl fullWidth margin="dense" variant="outlined" className={classes.formControl}>
                    <InputLabel>Select Account Manager</InputLabel>

                    <Select

                      labelId="demo-simple-select-outlined-label"
                      id="accountManager"
                      value={this.state.selectedAccountManager}
                      name="accountManager"
                      onChange={this.handleAccountManagerChange}
                      style={{ width: "225px" }}
                      label="Select Account Manager"
                    >


                      {
                        this.props.dashboard.setSelectedBusiness.finalAreaData ?
                          this.props.dashboard.setSelectedBusiness.finalAreaData.map((accountManager) => {
                            return <MenuItem name={accountManager.username} value={accountManager._id}>{(accountManager.firstName).charAt(0).toUpperCase() + (accountManager.firstName).slice(1)}{" "}{(accountManager.lastName).charAt(0).toUpperCase() + (accountManager.lastName).slice(1)}{"("}{(accountManager.username).charAt(0).toUpperCase() + (accountManager.username).slice(1)}{")"}</MenuItem>
                          }) : null

                      }


                    </Select>
                  </FormControl>
                  {this.state.accountManagerError ? (
                    <Typography gutterBottom style={{ color: "red" }}>
                      It is an existing Account Manager
                    </Typography>
                  ) : null}
                </Grid>
              ) : (
                <Grid item xs={12} sm={9}>
                  {accountManagerCall.length === 1 ?

                    accountManagerCall.map((accountManager) => {
                      return `${accountManager.firstName ? (accountManager.firstName).charAt(0).toUpperCase() + (accountManager.firstName).slice(1) : "NA"} ${accountManager.lastName ? (accountManager.lastName).charAt(0).toUpperCase() + (accountManager.lastName).slice(1) : ""}`
                    }

                    )

                    : "NA"
                  }
                </Grid>
              )}

              {(this.props.user.role === "regionalHead") ? (

                <Grid item xs={12} sm={2} >
                  <Button
                    fullWidth
                    style={{ margin: "5px" }}
                    variant="contained"
                    disabled={this.state.selectedAccountManager === currentAccountManager.accountManagerId || (currentAccountManager.accountManagerId === undefined && !this.state.selectedAccountManager)}
                    color="primary"
                    onClick={this.saveAccountManagerDetail}
                  >
                    SAVE
                  </Button>
                </Grid>) : null}

              {(this.props.user.role === "regionalHead") ? (
                <Grid item xs={12} sm={2}>
                  <Button
                    fullWidth
                    style={{ margin: "5px" }}
                    variant="contained"
                    color="primary"
                    disabled={this.state.selectedAccountManager === currentAccountManager.accountManagerId || (currentAccountManager.accountManagerId === undefined && !this.state.selectedAccountManager)}
                    onClick={this.cancelAccountManger}
                  >
                    CANCEL
                  </Button></Grid>

              ) :
                null
              }
              <Grid item xs={12} sm={3}>
                <b>Amenities</b>
              </Grid>
              {!this.state.editAmenities ? (
                <Grid item xs={12} sm={this.props.user.role === "areaManager" ? 6 : 9}>
                  {amenities && amenities.length > 0 ? (
                    <List>
                      {amenities.map((amenity, index) => (
                        <ul className={classes.ul}>
                          <ListItemText key={index} primary={amenity} />
                        </ul>
                      ))}
                    </List>
                  ) : (
                    "No amenities available."
                  )}
                </Grid>
              ) : (
                <Grid item xs={12} sm={9}>
                  <TextareaAutosize
                    name="amenities"
                    className={classes.textArea}
                    rowsMin={10}
                    label="textArea"
                    defaultValue={amenities}
                    onChange={this.handleChange}
                  />
                </Grid>
              )}

              {this.state.editAmenities ? (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Button
                    style={{ width: "15%", margin: "10px" }}
                    variant="contained"
                    margin="normal"
                    component="span"
                    onClick={this.closeAmenities}
                  >
                    CANCEL
                  </Button>
                  <Button
                    style={{ width: "15%", margin: "10px" }}
                    variant="contained"
                    color="primary"
                    margin="normal"
                    component="span"
                    onClick={this.saveAmenities}
                  >
                    SAVE
                  </Button>
                </Grid>
              ) : null}
              {this.props.user.role === "areaManager" && !this.state.editAmenities ? (
                <Grid item xs={12} sm={3}>
                  <Button
                    style={{ float: "right" }}
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={this.editAmenitiesTrue}
                  >
                    Edit Amenities
                  </Button>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={3}>
                <b>Service Modes</b>
              </Grid>
              <Grid item xs={12} sm={9}>
                <List>
                  {this.props.businessDetails.serviceModes.map((mode, index) => (
                    <ul className={classes.ul} key={index}>
                      <ListItemText key={index} primary={mode === "SERVICE_AT_BUSINESS_LOCATION" ? "Business Location" :mode === "SERVICE_AT_CONSUMER_LOCATION" ?"My Location" :mode} />
                    </ul>
                  ))}
                </List>
              </Grid>
              <Grid item xs={12} sm={3}>
                <b>Payment Methods</b>
              </Grid>
              <Grid item xs={12} sm={9}>
                <List>
                  {paymentMethodOptions.map((pyOption, index) => (
                    <ul className={classes.ul} key={index}>
                      <ListItemText primary={pyOption} />
                    </ul>
                  ))}
                </List>
              </Grid>
              <Grid item xs={12} sm={3}>
              </Grid>
              <Grid item xs={3}>
                <Typography>Online Payment Gateway</Typography>
              </Grid>
              <Grid item xs={3}>
                {this.props.user.role === "admin" ?
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        defaultChecked={this.props && this.props.businessDetails && this.props.businessDetails.isPGEnabled}
                        // defaultChecked={this.props && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.isPGEnabled}

                        onChange={this.handlePgEnable}
                        color="primary"
                      />
                    }
                  // style={{ fontSize: "10px", color: busObj.xirifyPromoted ? 'black' : 'red' }}
                  // label={busObj.xirifyPromoted ? 'Yes' : 'No'}
                  />
                  :
                  <Typography>
                    {(this.props && this.props.businessDetails && this.props.businessDetails.isPGEnabled) ? "Enabled" : "Not Enabled"}
                    {/* {(this.props && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.isPGEnabled) ? "Enabled" : "Not Enabled"} */}

                  </Typography>
                }
              </Grid>

              <Grid item xs={3}></Grid>
              {(this.props && this.props.businessDetails && this.props.businessDetails.isPGEnabled) ?
                // {(this.props && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.isPGEnabled) ?

                <Grid item xs={3}></Grid>
                : <Grid item xs={3}></Grid>}
              {(this.props && this.props.businessDetails && this.props.businessDetails.isPGEnabled && (this.props.businessDetails.isBankAccountEnabled === true || this.props.businessDetails.isBankAccountEnabled === false || this.state.isBankAccountEnabled === "isBankAccountEnabled===true") && this.props.businessDetails.isUpiEnabled === false) ?
                // {(this.props && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.isPGEnabled) ?

                <Grid item xs={9}>
                  <Container style={{ backgroundColor: "#E8E7E6" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={9}>
                        <Typography variant="subtitle2" >Bank Details For Online Payment</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        {this.props.user.role === "admin" ?
                          <IconButton className={classes.icon} style={{ float: "right" }}
                            onClick={() => this.editBankDetails()}>
                            <Avatar className={classes.lightBlue}>
                              <Edit color="action" />
                            </Avatar>
                          </IconButton>
                          : null}
                      </Grid>
                      <Grid item xs={6}>Account Number</Grid>
                      <Grid item xs={6}>{this.props && this.props.businessDetails && this.props.businessDetails.pgLinkedAccount ? this.props.businessDetails.pgLinkedAccount.accountNumber : ""}</Grid>
                      {/* <Grid xs={6}>{this.props && this.props.business && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.pgLinkedAccount ? this.props.business.updatedBusinessData.pgLinkedAccount.accountNumber : ""}</Grid> */}
                      <Grid item xs={6}>IFSC code</Grid>
                      <Grid item xs={6}>{this.props && this.props.businessDetails && this.props.businessDetails.pgLinkedAccount ? this.props.businessDetails.pgLinkedAccount.ifscCode : ""}</Grid>
                      {/* <Grid xs={6}>{this.props && this.props.business && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.pgLinkedAccount ? this.props.business.updatedBusinessData.pgLinkedAccount.ifscCode : ""}</Grid> */}
                      <Grid item xs={6}>Bank Name</Grid>
                      <Grid item xs={6}>{this.props && this.props.businessDetails && this.props.businessDetails.pgLinkedAccount ? this.props.businessDetails.pgLinkedAccount.bankName : ""}</Grid>
                      {/* <Grid xs={6}>{this.props && this.props.business && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.pgLinkedAccount ? this.props.business.updatedBusinessData.pgLinkedAccount.bankName : ""}</Grid> */}
                      <Grid item xs={6}>Branch Name</Grid>
                      <Grid item xs={6}>{this.props && this.props.businessDetails && this.props.businessDetails.pgLinkedAccount ? this.props.businessDetails.pgLinkedAccount.branchName : ""}</Grid>
                      {/* <Grid xs={6}>{this.props && this.props.business && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.pgLinkedAccount ? this.props.business.updatedBusinessData.pgLinkedAccount.branchName : ""}</Grid> */}
                      <Grid item xs={6}>Account Holder Name</Grid>
                      <Grid item xs={6}>{this.props && this.props.businessDetails && this.props.businessDetails.pgLinkedAccount ? this.props.businessDetails.pgLinkedAccount.accountHolderName : ""}</Grid>
                      {/* <Grid xs={6}>{this.props && this.props.business && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.pgLinkedAccount ? this.props.business.updatedBusinessData.pgLinkedAccount.accountHolderName : ""}</Grid> */}
                    </Grid>
                  </Container>
                </Grid>
                : 
                
                <Grid item xs={9}>
                <Container style={{ backgroundColor: "#E8E7E6" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      <Typography variant="subtitle2" >UPI Details For Online Payment</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      {this.props.user.role === "admin" ?
                        <IconButton className={classes.icon} style={{ float: "right" }}
                          onClick={() => this.editBankDetails()}>
                          <Avatar className={classes.lightBlue}>
                            <Edit color="action" />
                          </Avatar>
                        </IconButton>
                        : null}
                    </Grid>
                    <Grid item xs={6}>UPI Id</Grid>
                    <Grid item xs={6}>{this.props && this.props.businessDetails && this.props.businessDetails.upiDetails ? this.props.businessDetails.upiDetails.upiId : ""}</Grid>
                    {/* <Grid xs={6}>{this.props && this.props.business && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.pgLinkedAccount ? this.props.business.updatedBusinessData.pgLinkedAccount.accountNumber : ""}</Grid> */}
                  </Grid>
                </Container>
              </Grid>
                }
              {(this.props && this.props.businessDetails && this.props.businessDetails.isPGEnabled) ?
                // {(this.props && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.isPGEnabled) ?
                <Grid item xs={3}></Grid>
                : null}
              {(this.props && this.props.businessDetails && this.props.businessDetails.isPGEnabled) ?
                //  {(this.props && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.isPGEnabled) ?

                <Grid item xs={9}>
                  <Container style={{ backgroundColor: "#E8E7E6", paddingTop: "30px" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={9}>
                        <Typography variant="subtitle2" >Keys For Online Payment</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        {this.props.user.role === "admin" ?
                          <IconButton className={classes.icon} style={{ float: "right" }}
                            onClick={() => this.editPaymentGatewayIntegrations()}>
                            <Avatar className={classes.lightBlue}>
                              <Edit color="action" />
                            </Avatar>
                          </IconButton>
                          : null}
                      </Grid>
                      <Grid item xs={6}>API ID</Grid>
                      <Grid item xs={6}>{this.props && this.props.business && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData ? this.props.business.updatedBusinessData.appid : ""}</Grid>
                      <Grid item xs={6}>SECRET KEY</Grid>
                      <Grid item xs={6}>{this.props && this.props.business && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData ? this.props.business.updatedBusinessData.secretKey : ""}</Grid>
                    </Grid>
                  </Container>
                </Grid>
                : null}

              <Grid item xs={12} sm={3} style={{ marginTop: "15px" }}>
                <b>Exotel call reminder configuration</b>
              </Grid>

              <Grid item xs={9} style={{ marginTop: "15px" }}>
                <Container style={{ backgroundColor: "#E8E7E6", paddingTop: "30px" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      Exotel Reminder Configuration
                    </Grid>
                    <Grid item xs={3}>
                      {(this.props.user.role === "admin" || this.props.user.role === "areaManager") ?
                        <IconButton className={classes.icon} style={{ float: "right" }}
                          onClick={() => this.editExotelTimeConfiguration()}>
                          <Avatar className={classes.lightBlue}>
                            <Edit color="action" />
                          </Avatar>
                        </IconButton>
                        : null}
                    </Grid>
                    {this.props.business && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.exotelConfig && this.props.business.updatedBusinessData.exotelConfig.length > 0 ? this.props.business.updatedBusinessData.exotelConfig.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          <Grid item xs={2}></Grid>
                          <Grid item xs={4}>{item.whichReminder} Reminder</Grid>
                          <Grid item xs={4}>after {item.minute} minute/s</Grid>
                          <Grid item xs={2}></Grid>
                        </Fragment>
                      )
                    }) :
                      this.props.dashboardDetails && this.props.dashboardDetails.data && this.props.dashboardDetails.data.length && this.props.dashboardDetails.data[0].exotelConfig.length && this.props.dashboardDetails.data[0].exotelConfig.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>{item.whichReminder} Reminder</Grid>
                            <Grid item xs={4}>after {item.minute} minute/s</Grid>
                            <Grid item xs={2}></Grid>
                          </Fragment>
                        )
                      })
                    }
                  </Grid>
                </Container>
              </Grid>

              <Grid item xs={6} sm={3} style={{ marginTop: "60px" , display : "flex", flexDirection : "row"}}>
                <b>Payment Features</b>
                <Tooltip 
                  title={
                    <Typography style={{ backgroundColor: "white", color: "black", padding: "5px", border: "black 1px solid", margin: "0px"}}>
                      <div style={{ fontSize: "12px" }}>
                        <strong style={{ fontSize: "14px" }}>Pre-Payment</strong><br />
                        The business will always be in pre-payment even the Xirify Loyal Customers will have to pay before placing the orders.
                      </div>
                      <div style={{ fontSize: "12px" }}>
                        <strong style={{ fontSize: "14px" }}>Post-Payment</strong><br />
                        Business will always be in post-payment even new shoppers who are not eligible for post payments will also be able to pay after ordering.
                      </div>
                      <div style={{ fontSize: "12px" }}>
                        <strong style={{ fontSize: "14px" }}>Post-Payment for Loyal Shoppers</strong><br />
                        Our config logic will be applicable in this case, i.e. new customers will have to do pre-payment and eligible customers will get post payment option. This will be the default status.
                      </div>
                    </Typography>
                  
                  }
                  placement="right-start"
                  arrow
                  style={{ backgroundColor: "inherit", paddingBottom: "12px" }}>
                  <Avatar>  <PaymentFeature /></Avatar>
                </Tooltip>

              </Grid>
          
              <Grid item xs={6} style={{ marginTop: "50px" }}>
              {this.props.user.role === "areaManager" ?
              <RadioGroup row aria-label="position" name="paymentFeatureFilter" onChange={this.handleChangePaymentFilter} value={this.state.paymentFeatureFilter}>
                      <FormControlLabel value="isOnlyPrePaymentAllowed" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Pre-Payment</Typography>} size="small" />
                      <FormControlLabel value="isOnlyPostPaymentAllowed" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Post-Payment</Typography>} size="small" />
                      <FormControlLabel value="isPostPaymentAllowedToLoyalConsumers" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Post-Payment For Loyal Shoppers</Typography>} size="small" />
                    </RadioGroup>
               : 
             
                  <Typography style={{"paddingTop" : "10px"}}>
                {this.props.businessDetails.isOnlyPostPaymentAllowed ? "Post-Payment" : this.props.businessDetails.isOnlyPrePaymentAllowed ? "Pre-Payment" : this.props.businessDetails.isPostPaymentAllowedToLoyalConsumers ? "Post-Payment For Loyal Shoppers" : "" }
                </Typography>
                
                }
               </Grid>
              <Grid item xs={6} sm={3}></Grid>
              
            
             
              <Grid item xs={6} sm={3} style={{ marginTop: "50px" }}>
                <b>Order preparation timer</b>
              </Grid>

              <Grid item xs={6} sm={3} style={{ marginTop: "25px",display: "flex"}} >
                <Grid style={{marginTop: "35px"}}>
                {this.props.user.role === "areaManager" ?
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        defaultChecked={this.props && this.props.businessDetails && this.props.businessDetails.isBusinessPreparationTimerEnabled}
                        onChange={this.handleOrderPreparationTimer}
                        color="primary"
                      />
                    }
                  />
                  :
                  <Typography  style={{ fontSize: "15px",  marginTop:"-5px" }}>
                    {(this.props && this.props.businessDetails && this.props.businessDetails.isBusinessPreparationTimerEnabled) ? "Yes" : "No"}
                  </Typography>
                }
                </Grid>
                
                <Grid>
                {(this.state.isBusinessPreparationTimerEnabled === true || this.props.businessDetails.isBusinessPreparationTimerEnabled === true ) ?
                <Grid item xs={6}>
                  <div className="row">
                  <Grid >
                  <b style={{marginLeft: "130px"}}> Default </b>
                  {this.props.user.role === "areaManager" ?
                    <FormControl fullWidth margin="dense" variant="outlined">
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="fronpm run devm"
                        value={this.state.businessPreparationTime}
                        name="businessPreparationTime"
                        onChange={this.handleChange}
                        style={{ width: "80px" ,marginLeft: "130px"}}
                        error={this.state.minTimerError ? true : false}
                      >
                        {businessPreparationTime.map((index) => {
                          return (
                            <MenuItem key={index} value={index} >
                              <Typography noWrap>{index}</Typography>
                            </MenuItem>
                          )
                        })}
                      </Select>
                      {(this.state.minTimerError)? (
                        <FormHelperText style={{ color: "red", marginLeft: "130px", width: "100px" }}> Default preparation time should be less than max time </FormHelperText>
                      ) :  null}
                    </FormControl> : 
                    <Typography  style={{ fontSize: "15px",marginLeft: "140px",marginTop: "9px"}}>
                    {this.props && this.props.businessDetails && this.props.businessDetails.businessPreparationTime ?this.props.businessDetails.businessPreparationTime : 5 }
                  </Typography>
                  }
                  </Grid> 
                  </div>
                </Grid> : " "
                }
                </Grid>
                
                {this.props.user.role === "areaManager" ?
                <Grid style={{marginTop: "45px"}}>
                {(this.state.isBusinessPreparationTimerEnabled === true ||  this.props.businessDetails.isBusinessPreparationTimerEnabled === true) ?
                  <b style={{marginLeft: "35px"}}>Min</b>
                  : ""
                }
                </Grid>:
                <Grid style={{marginTop: "33px"}}>
                {(this.state.isBusinessPreparationTimerEnabled === true || this.props.businessDetails.isBusinessPreparationTimerEnabled === true) ?
                  <b style={{marginLeft: "0px"}}>Min</b>
                  : ""
                }
                </Grid>}

                <Grid>
                {(this.state.isBusinessPreparationTimerEnabled === true || this.props.businessDetails.isBusinessPreparationTimerEnabled === true) ?
                <Grid item xs={6}>
                  <div className="row">
                  <Grid >
                  <b style={{marginLeft: "140px"}}> Max </b>
                  {this.props.user.role === "areaManager" ?
                  <Grid>
                    <FormControl fullWidth margin="dense" variant="outlined">
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="fronpm run devm"
                        value={this.state.businessPreparationTimeMax}
                        name="businessPreparationTimeMax"
                        onChange={this.handleChange}
                        style={{ width: "80px" ,marginLeft: "130px"}}
                        error={this.state.maxTimerError ? true : false}
                      >
                        {businessPreparationTimeMax.map((index) => {
                          return (
                            <MenuItem key={index} value={index} >
                              <Typography noWrap>{index}</Typography>
                            </MenuItem>
                          )
                        })}
                      </Select>
                      {(this.state.maxTimerError)? (
                        <FormHelperText style={{ color: "red", marginLeft: "130px", width: "100px" }}> Max preparation time should be greater than default time </FormHelperText>
                      ) :  null}
                    </FormControl> 
                    </Grid>
                    : 
                     <Typography  style={{ fontSize: "15px",marginLeft: "140px",marginTop:"9px"}}>
                    {this.props && this.props.businessDetails && this.props.businessDetails.businessPreparationTimeMax ?this.props.businessDetails.businessPreparationTimeMax : 60 }
                  </Typography>
                  }
                  </Grid> 
                  </div>
                </Grid> : " "
                }
                </Grid>

                {this.props.user.role === "areaManager" ?
                <Grid style={{marginTop: "45px"}}>
                {(this.state.isBusinessPreparationTimerEnabled === true || this.props.businessDetails.isBusinessPreparationTimerEnabled === true) ?
                  <b style={{marginLeft: "35px"}}>Min</b>
                  : ""
                }
                </Grid>:
                 <Grid style={{marginTop: "33px"}}>
                 {(this.state.isBusinessPreparationTimerEnabled === true || this.props.businessDetails.isBusinessPreparationTimerEnabled === true) ?
                   <b style={{marginLeft: "10px"}}>Min</b>
                   : ""
                 }
                 </Grid>}
                

              </Grid>

              <Grid item xs={12}></Grid>
              
              <Grid item xs={6} sm={3} >
                <b>Type Order</b>
              </Grid>

              <Grid item xs={6} sm={3}>
                {this.props.user.role === "areaManager" ?
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        defaultChecked={this.props && this.props.businessDetails && this.props.businessDetails.isTypeOrderEnabled}
                        onChange={this.handleTypeOrders}
                        color="primary"
                      />
                    }
                  />
                  :
                  <Typography  style={{ fontSize: "15px",  marginTop:"4px" }}>
                    {(this.props && this.props.businessDetails && this.props.businessDetails.isTypeOrderEnabled) ? "Yes" : "No"}
                  </Typography>
                }
              </Grid>

             <Grid item xs={12}></Grid>
             <Grid item xs={6} sm={3} >
                <b>Allow Cancel Order</b>
              </Grid>

              <Grid item xs={6} sm={3}>
               
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        defaultChecked={this.props && this.props.businessDetails && this.props.businessDetails.allowCancelOrders}
                        onChange={this.handleAllowCancelOrders}
                        color="primary"
                      />
                    }
                  />
                 
              </Grid>
              <Grid item xs={12}></Grid>
              

            {/* Business Note section Start */}
              <Grid item xs={6} sm={3} >
                  <b>Business Note</b>
              </Grid>
              <Grid item xs={6} sm={2} >
              {
                (this.props.user.role === "areaManager" || this.props.user.role === "admin") ?
                (this.props.businessDetails.businessNote && !this.state.businessTextFieldFlag ?  
                  // "IfLoop"
                <Typography  style={{ width: "465px", height: "auto" }}>
                  {this.props.businessDetails.businessNote}
                </Typography>
                ://"else Lopp"
                  (<TextField
                  id="outlined-textarea"
                  name="businessNote"
                  label="Business Note"
                  value={this.state.businessNote}
                  onChange={this.handleBusinessNote}
                  variant="outlined"
                 // multiline
                  error={this.state.businessNoteError ? true : false}
                  style={{ width: "465px", height: "auto" }}
                  disabled={(this.props.businessDetails.businessNote &&
                     this.state.isEditBusinessNoteFlag === false && (this.props.businessDetails.businessNote !=="" ||  this.props.businessDetails.businessNote !== null || this.props.businessDetails.businessNote !== undefined )) ? true  : false}
                  />
                  )
                  )
                :
                <p style={{ width: "820px", height: "auto" }}> {(this.props && this.props.businessDetails && this.props.businessDetails.businessNote &&this.props.businessDetails.businessNote !=null && this.props.businessDetails.businessNote !=undefined  && this.props.businessDetails.businessNote.length > 0 ? this.props.businessDetails.businessNote : "No Business Note  available." )}</p>
              }
              </Grid>
             <Grid item xs={6} sm={3} ></Grid>
              {(this.props && this.props.businessDetails && this.props.businessDetails.businessNote && this.props.businessDetails.businessNote.length > 0 ?
              <Grid item xs={6} sm={3} style={{paddingLeft:"80px",paddingTop:"20px"}} >
                <IconButton className={classes.icon}>
                  <Avatar className={classes.lightBlue} onClick={this.handleBusinessNoteFlagEnable} >
                    <Edit color="action" />
                  </Avatar>
                </IconButton>
                <IconButton className={classes.icon}>
                  <Avatar className={classes.lightBlue} onClick={this.deleteBusinessNote}>
                    <Delete color="action" />
                  </Avatar>
                </IconButton>
              </Grid>:"")}
              {this.state.isEditBusinessNoteFlag ? 
                <><Grid item xs={12} sm={3} ></Grid>
                <Grid item xs={12} sm={5} ><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px'}}> {this.state.businessNoteError?"Enter Valid Note": null} </FormHelperText></Grid>
                <Grid item xs={12} sm={4} ></Grid></>
                :<><Grid item xs={12} sm={4} ></Grid>
                <Grid item xs={12} sm={3} ></Grid>
                <Grid item xs={12} sm={5} ><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px'}}> {this.state.businessNoteError?"Enter Valid Note": null} </FormHelperText></Grid></>
              }
              {
                (this.props.user.role === "areaManager" || this.props.user.role === "admin") ?
                (this.props.businessDetails.businessNote && !this.state.businessTextFieldFlag ?  null :
                 (
                  <>
                    <Grid item xs={12} sm={6} ></Grid>
                    <Grid item xs={12} sm={2}>
                    <Button
                      fullWidth
                      style={{ margin: "5px"}}
                      variant="contained"
                      margin="normal"
                      component="span"
                      onClick={this.deleteBusinessNote}
                      //onClick={this.closeDetails}
                    // disabled={(!this.state.businessNote ? true : (this.props.businessDetails && this.props.businessDetails.businessNote && this.props.businessDetails.businessNote.length && (this.props.businessDetails.businessNote == this.state.businessNote) ? true : false))}
                    >
                      CLEAR
                    </Button>
                    </Grid>
                    {/* <Button
                    style={{ width: "15%", margin: "10px" }}
                    variant="contained"
                    margin="normal"
                    component="span"
                    onClick={this.closeAmenities}
                  >
                    CANCEL
                  </Button> */}
                    <Grid item xs={12} sm={2}>
                    <Button
                      fullWidth
                      style={{ margin: "5px" }}
                      variant="contained"
                      color="primary"
                      disabled={(!this.state.businessNote ? true : (this.props.businessDetails && this.props.businessDetails.businessNote && this.props.businessDetails.businessNote.length && (this.props.businessDetails.businessNote == this.state.businessNote) ? true : (this.state.businessNoteSaveFlag ?true :false)))}
                      onClick={this.saveBusinessNote}
                    >
                      SAVE
                    </Button>
                    </Grid></>) )
                  : null
              }
            {/* Business Note section End */}
              <Grid item xs={12}></Grid>
              <Grid item xs={6} sm={3} style={{ marginTop: "15px" }}>
                <b>Business Video</b>
              </Grid>
              <Grid item xs={3} sm={5} hidden={this.props.user.role === 'admin' ? true : false}>
                <TextField
                  name="businessVideo"
                  label="Business Video Link"
                  variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.businessVideo}
                  style={{ width: "465px", height: "5px" }}
                  error={this.state.businessVideoError ? this.state.businessVideoError : (this.state.businessVideoErrorExists)}
                  helperText={this.state.businessVideoError ? "Required" : (this.state.businessVideoErrorExists ? "Business Video Link Already Exists" : null)}
                />
              </Grid>
              <Grid item xs={2} sm={1} hidden={this.props.user.role === 'admin' ? true : false}></Grid>
              <Grid item xs={2} sm={2} hidden={this.props.user.role === 'admin' ? true : false}>
                <ButtonGroup className={classes.buttonGroup} size="small" aria-label="small outlined primary button group" fullWidth >
                  <Button style={{
                    fontWeight: 'bold', backgroundColor: '#07c6db', color: '#ffffff', height: "55px", width: "50px",
                    // marginLeft:"0px", 
                    fontSize: "1rem"
                  }} variant="contained"
                    onClick={() => { this.updateBusinessVideo(this.state.businessVideo) }}
                  >
                    +
                  </Button>
                </ButtonGroup>
              </Grid>

              {/* To display the video links */}
              <Grid item xs={6} sm={3} hidden={this.props.user.role === 'admin' ? true : false} style={{ marginTop: "15px" }}></Grid>
              <Grid item xs={6} sm={9} >
                {this.props.businessDetails.businessVideoLinks && this.props.businessDetails.businessVideoLinks.length > 0
                  ?
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Video Link</TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.businessDetails.businessVideoLinks.map(
                          (path) => {
                            let pathlength = path.path.trim().length;
                            return (
                              pathlength > 0 ?
                                (<TableRow key={path._id}>
                                  <TableCell component="th" scope="row" style={{ fontSize: "15px" }}>
                                    <a href={path.path} target="_blank" rel="noopener noreferrer">{path.path}</a>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <IconButton className={classes.icon} hidden={this.props.user.role === 'admin' ? true : false}
                                      onClick={(e) => this.handleEditOpen(e, path)}
                                    >
                                      <Avatar className={classes.lightBlue}>
                                        <Edit color="action" />
                                      </Avatar>
                                    </IconButton>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <IconButton className={classes.icon} hidden={this.props.user.role === 'admin' ? true : false}
                                      onClick={(e) => { this.handleDeleteOpen(e, path) }}
                                    >
                                      <Avatar className={classes.lightBlue}>
                                        <Delete color="action" />
                                      </Avatar>
                                    </IconButton>
                                  </TableCell>
                                </TableRow>) : null
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  // <div>
                  //   {this.props.businessDetails.businessVideoLinks.map(path=>{
                  //       return(
                  //         <div><a href={path.path} target="_blank" rel="noopener noreferrer">{path.path}</a></div>
                  //       ) 
                  //     })
                  //   }
                  // </div>
                  : <div style={{ marginTop: "15px" }}>No Business Video Links</div>
                }
              </Grid>


              <Grid item xs={6} sm={3}>
                <b>Business Photos</b>
              </Grid>

              <Grid item xs={6} sm={9}>
                <UploadPhoto
                  images={this.state.businessPhotos}
                  photoType="Business"
                  uploadButtonName="UPLOAD BUSINESS PHOTO"
                  updateBusinessPhotos={this.updateBusinessPhotos}
                  deleteBusinessPhoto={this.deleteBusinessPhoto}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <b>Catalogue / Menu Photos</b>
              </Grid>

              <Grid item xs={12} sm={9}>
                <UploadPhoto
                  images={this.state.cataloguePhotos}
                  photoType="Catalogue"
                  uploadButtonName="UPLOAD CATALOGUE PHOTO"
                  updateBusinessPhotos={this.updateBusinessPhotos}
                  deleteBusinessPhoto={this.deleteBusinessPhoto}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <b>Business Card Images</b>
              </Grid>
              


              <Grid item xs={12} sm={2} >
                  <UploadBusinessCardPhotos
                    images={foundDefaultBussImg}
                    photoType="Default"
                    uploadButtonName="EDIT"
                    updateBusinessPhotos={this.updateBusinessCardImages}
                    deleteBusinessPhoto={this.deleteBusinessCardImage}
                    title= "Default"
                    defaultImage={defaultImage}
                  />
              </Grid>

              <Grid item xs={12} sm={2} >
                <UploadBusinessCardPhotos
                    images={foundVegBussImg}
                    photoType="Veg"
                    uploadButtonName="EDIT"
                    updateBusinessPhotos={this.updateBusinessCardImages}
                    deleteBusinessPhoto={this.deleteBusinessCardImage}
                    title= "Veg"

                  />
              </Grid>

              <Grid item xs={12} sm={2} >              
                  <UploadBusinessCardPhotos
                    images={foundNonvegBussImg}
                    photoType="NonVeg"
                    uploadButtonName="EDIT"
                    updateBusinessPhotos={this.updateBusinessCardImages}
                    deleteBusinessPhoto={this.deleteBusinessCardImage}
                    title= "Nonveg"
                  />
              </Grid>
    
              </Grid>
        
          </Container>
        ) : null
        }
         <Modal
          className={classes.modal}
          open={this.state.editBankDetailsModal}
          onClose={this.close}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Container className={classes.mainContainer1}>
            <Grid container className={classes.header2} spacing={1} >
              <Grid item xs={8}>
              <Typography variant="subtitle2" gutterBottom width="1000px"> Online Payment</Typography>
              </Grid>
              <Grid item xs={4}>
                <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
                  <Close className={classes.headGridTwo} onClick={() => { this.close() }} />
                </WhiteTextTypography>
              </Grid>
            </Grid>
            <Container className={classes.modalContainer2}>
              <Grid 
              container className={classes.grid} spacing={3}  
              >
                <Grid item xs={12}  className={classes.gridItem}>
                <Typography variant="subtitle2" gutterBottom width="1000px"> Select Payment Method </Typography>
                    <FormControl fullWidth margin="dense" variant="outlined">
                      <InputLabel id="demo-simple-select-standard-label">Payment Method</InputLabel>
                      <Select
                         labelId="demo-simple-select-standard-label"
                         id="demo-simple-select-standard"
                        value={this.state.isBankAccountEnabled}
                        // name="payment-method"
                        onChange={this.handlePaymentChange}
                        label="Payment Method"
                        style={{ width : "fullWidth" }}
                        MenuProps={this.MenuProps} 
                        // defaultValue={'isBankAccountEnabled===true'}
                      >
                       <MenuItem value={'isBankAccountEnabled===true'} style={{ minHeight: "28px", height: "28px" }}>                      
                        <ListItemText primary="Net Banking" />
      
                       </MenuItem>
                       <MenuItem value={'isUpiEnabled===true'} style={{ minHeight: "28px", height: "28px" }}>
                            <ListItemText primary="UPI" />
                       </MenuItem>
                      </Select>
                    </FormControl>
                </Grid>
                {/* <Grid>UPI

                </Grid> */}
                {(this.state.isBankAccountEnabled ===  ("isBankAccountEnabled===true" ) && this.state.isUpiEnabled === false) ? 
               <Grid container className={classes.grid} spacing={1} alignItems="center" >
                <Grid item xs={5}  >
                <Typography variant="subtitle2" gutterBottom> Account No* </Typography>
							</Grid>
                <Grid item xs={6}  >  <Typography variant="subtitle2" gutterBottom>IFSC No* </Typography>
              </Grid>
                <Grid item xs={6}  >
                 <TextField style={{marginTop:'0px',width:"100%"}} fullWidth margin="dense" name="accountNumber" label="Account Number" variant="outlined" value={this.state.accountNumber}onChange={this.handleChange} error={this.state.accountNumberError ? true : false} />
							</Grid>
							<Grid item xs={6} >
                 <TextField style={{marginTop:'0px',width:"100%"}} fullWidth margin="dense" name="ifscCode" label="IFSC code" variant="outlined" value={this.state.ifscCode}onChange={this.handleChange} error={this.state.ifscCodeError ? true : false} />
							</Grid>
              <Grid item xs={6}><FormHelperText style={{ color: "red"}}> {this.state.accountNumberError?"Required":(this.state.accountNumberValidationError ? "Enter Valid Account Number" : null)} </FormHelperText></Grid>
              <Grid item xs={6}><FormHelperText style={{ color: "red", marginTop:'0px'}}> {this.state.ifscCodeError?"Required":(this.state.ifscCodeValidationError ? "Enter Valid Ifsc Code" : null)} </FormHelperText></Grid>

              <Grid item xs={6}  >
              <Typography variant="subtitle2" gutterBottom>Bank Name* </Typography>
							</Grid>
                <Grid item xs={6}  > <Typography variant="subtitle2" gutterBottom>Branch Name* </Typography>
              </Grid>
                <Grid item xs={6}  >
                <TextField style={{marginTop:'0px', marginBottom:'5px',width:"100%"}} fullWidth margin="dense" name="bankName" label="Bank Name" variant="outlined" value={this.state.bankName}onChange={this.handleChange} error={this.state.bankNameError ? true : false} />
							</Grid>
							<Grid item xs={6}  >
              <TextField style={{marginTop:'0px', marginBottom:'5px',width:"100%"}} fullWidth margin="dense" name="branchName" label="Branch Name" variant="outlined" value={this.state.branchName}onChange={this.handleChange} error={this.state.branchNameError ? true : false} />
							</Grid>
              <Grid item xs={6}><FormHelperText style={{ color: "red", marginTop:'0px', marginBottom:'5px'}}> {this.state.bankNameError?"Required":(this.state.bankNameValidationError ? "Enter Valid Bank Name" : null)} </FormHelperText></Grid>
              <Grid item xs={6}><FormHelperText style={{ color: "red", marginTop:'0px', marginBottom:'5px'}}> {this.state.branchNameError?"Required":(this.state.branchNameValidationError ? "Enter Valid Branch Name" : null)} </FormHelperText></Grid>
              
                  <Grid item xs={12} className={classes.gridItem}>
                  <Typography variant="subtitle2" gutterBottom>Account Holder Name* </Typography>
                    <TextField style={{marginTop:'0px', marginBottom:'5px',width:"100%"}} fullWidth margin="dense" name="accountHolderName" label="Account Holder Name" variant="outlined" value={this.state.accountHolderName}onChange={this.handleChange} error={this.state.accountHolderNameError ? true : false} />
							    <Grid item xs={6}><FormHelperText style={{ color: "red", marginTop:'0px', marginBottom:'5px'}}> {this.state.accountHolderNameError?"Required":(this.state.accountHolderNameValidationError ? "Enter Valid Account Holder Number" : null)} </FormHelperText></Grid>
                  </Grid>
                </Grid>
                :
                <Grid item xs={12} className={classes.gridItem}>
                <Typography variant="subtitle2" gutterBottom> UPI Id* </Typography>
                <TextField style={{marginTop:'0px', marginBottom:'5px',width:"100%"}} fullWidth margin="dense" name="upiId" label="UPI id" variant="outlined" value={this.state.upiId}onChange={this.handleChange} error={this.state.upiIdError ? true : false} />
                <Grid item xs={6}><FormHelperText style={{ color: "red", marginTop:'0px', marginBottom:'5px'}}> {this.state.upiIdError?"Required":(this.state.upiIdValidationError ? "Enter Valid Upi Id" : null)} </FormHelperText></Grid>
                </Grid>
                } 
                <Grid item xs={6} className={classes.gridItem} ></Grid>
                <Grid item xs={3} className={classes.gridItem} spacing={5} >
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={this.close}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={3} className={classes.gridItem} >
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={this.saveBankDetails}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </Modal>
        <Modal
          className={classes.modal}
          open={this.state.editPGSecretKeyModal}
          onClose={this.close}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Container className={classes.mainContainer2}>
            <Grid container className={classes.header} spacing={1} >
              <Grid item xs={8}>
                <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
                  Keys For Online Payment
                </WhiteTextTypography>
              </Grid>
              <Grid item xs={4}>
                <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
                  <Close className={classes.headGridTwo} onClick={() => { this.close() }} />
                </WhiteTextTypography>
              </Grid>
            </Grid>
            <Container className={classes.modalContainer}>
              <Grid container className={classes.grid} spacing={1} alignItems="center" >
                <Grid item xs={6} className={classes.gridItem} >
                  <TextField
                    name="appid"
                    label="API KEY *"
                    fullWidth
                    error={this.state.error.appid} helperText={this.state.error.appid}
                    defaultValue={this.props && this.props.business && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.appid ? this.props.business.updatedBusinessData.appid : ""}
                    onChange={this.handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} className={classes.gridItem}>
                  <TextField
                    name="secretKey"
                    label="SECRET KEY *"
                    error={this.state.error.secretKey} helperText={this.state.error.secretKey}
                    fullWidth
                    defaultValue={this.props && this.props.business && this.props.business.updatedBusinessData && this.props.business.updatedBusinessData.secretKey ? this.props.business.updatedBusinessData.secretKey : ""}
                    onChange={this.handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} className={classes.gridItem}></Grid>
                <Grid item xs={3} className={classes.gridItem} >
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={this.close}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={this.savePgIntegrationDetails}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </Modal>
        <Modal
          className={classes.modal}
          open={this.state.editExotelConfig}
          onClose={this.close}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Container className={classes.mainContainer4}>
            <Grid container className={classes.header4} spacing={1} >
              <Grid item xs={8}>
                <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
                  Exotel Reminder Configuration
                </WhiteTextTypography>
              </Grid>
              <Grid item xs={4}>
                <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
                  <Close className={classes.headGridTwo} onClick={() => { this.close() }} />
                </WhiteTextTypography>
              </Grid>
            </Grid>

            <Container className={classes.modalContainer4}>
             
              <Grid container className={classes.grid} spacing={1} justify="center" alignItems="center" >
                <Grid item xs={3}>
                  1st Reminder
                </Grid>
                <Grid item xs={5}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl} >
                    <Select labelId="demo-simple-select-outlined-label" id="day" value={this.state.firstReminder} name="firstReminder" onChange={this.handleExotelReminders} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                       {this.state.firstMintesArray.map((index) => {
                        return (
                          <MenuItem key={index} value={index} style={{ minHeight: "28px", height: "28px" }}>
                            <Typography noWrap>{index}</Typography>
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                {
                this.state.exotelFirstAdd ?
                <Grid item xs={4}>
                <Fab color="primary" style={{width:"35px",height:"35px",marginLeft:"10px"}} onClick={() => { this.handleAddFirstExotel() }}><Typography style={{ fontSize : 'large'}}>+</Typography></Fab >
                </Grid>   
                :<Grid item xs={4}></Grid>
                }
                <Grid item xs={12}>
                {this.state.firstRequired &&
                  
                  <FormHelperText style={{ color: "red", paddingLeft: '20px' }}>
                    The reminders must be in increasing order of time.
                  </FormHelperText>
                 
                }
                 </Grid>
                  {
                  (this.state.exotelSecondDelete && this.state.exotelSecondAdd ) || this.state.exotelThirdDelete?
                <Grid item  xs={3}>
                  2nd Reminder
                </Grid>
                :null}
                  {
                  (this.state.exotelSecondDelete && this.state.exotelSecondAdd ) || this.state.exotelThirdDelete?
                  <Grid item  xs={5}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl} >
                    <Select labelId="demo-simple-select-outlined-label" id="day" value={this.state.secondReminder} name="secondReminder" onChange={this.handleExotelReminders} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                      {this.state.minutes.map((index) => {
                        return (
                          <MenuItem key={index} value={index} style={{ minHeight: "28px", height: "28px" }}>
                            <Typography noWrap>{index}</Typography>
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                :null
                }

                {
                  this.state.exotelSecondDelete && this.state.exotelSecondAdd && !this.state.exotelThirdDelete ?
                  <Grid item  xs={2}>
                  <Fab color="primary" style={{width:"35px",height:"35px",marginLeft:"10px"}} onClick={() => { this.handleAddSecondExotel() }}><Typography style={{ fontSize : 'large'}}>+</Typography></Fab >
                  </Grid>                      
                  :<Grid item xs={2}></Grid>
                }{
                  this.state.exotelSecondDelete && this.state.exotelSecondAdd && !this.state.exotelThirdDelete ?
                    <Grid item xs = {2}>
                    <Fab color="primary" style={{width:"35px",height:"35px",marginLeft:"1px"}} onClick={() => { this.handleDeleteSecondExotel() }}><Typography style={{ fontSize : 'x-large'}}>-</Typography></Fab >
                    </Grid>    
                   :<Grid item xs={2}></Grid>
                }
                 <Grid item xs={12} >
                {this.state.secondRequired &&                   
                  <FormHelperText style={{ color: "red", paddingLeft: '20px' }}>
                    The reminders must be in increasing order of time.
                  </FormHelperText>                 
                }
                 </Grid>
                 <Grid item xs={12}>
                {this.state.exotelTwoError &&                   
                  <FormHelperText style={{ color: "red", paddingLeft: '20px' }}>
                    2nd Reminder is Required
                  </FormHelperText>                 
                }
                 </Grid>
                {this.state.exotelThirdDelete ?
                <Grid item xs={3}>
                  3rd Reminder
                </Grid> :null
                }
                 {this.state.exotelThirdDelete ?
                <Grid item  xs={5}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl} >
                    <Select labelId="demo-simple-select-outlined-label" id="day" value={this.state.thirdReminder} name="thirdReminder" onChange={this.handleExotelReminders} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                      {this.state.minutes.map((index) => {
                        return (
                          <MenuItem key={index} value={index} style={{ minHeight: "28px", height: "28px" }}>
                            <Typography noWrap>{index}</Typography>
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                :null}
                {
                  this.state.exotelThirdDelete ?              
                <Grid item xs={4}>
                <Fab color="primary" style={{width:"35px",height:"35px",marginLeft:"10px"}} onClick={() => { this.handleDeleteThirdExotel() }}><Typography style={{ fontSize : 'x-large'}}>-</Typography></Fab >
                </Grid>                                         
                 :<Grid item xs={4}></Grid>
                }
                 <Grid item xs={12}>
                {this.state.thirdRequired ?               
                  <FormHelperText style={{ color: "red", paddingLeft: '20px' }}>
                    The reminders must be in increasing order of time.
                  </FormHelperText>
                   : null                  
                }
                </Grid>
                <Grid item xs={12}>
                {this.state.exotelThreeError &&                   
                  <FormHelperText style={{ color: "red", marginLeft: '20px' }}>
                    3rd Reminder is Required
                  </FormHelperText>                 
                }
                 </Grid>
                
                <Grid item xs={12}> <Divider color="textSecondary" style={{ marginBottom: "5px", marginTop: "10px" }} display="block" /> </Grid>
                <Grid item xs={6} className={classes.gridItem} ></Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <Button variant="contained" component="span" onClick={this.close} style={{ margin: "5px" }} fullWidth> Cancel </Button>
                </Grid>
                <Grid item xs={3} className={classes.gridItem} >
                  <Button variant="contained" color="primary" component="span" onClick={this.saveExotelBusinessConfig} style={{ margin: "5px" }} fullWidth > SAVE </Button>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </Modal>

        {/* Modal to add business timings */}
        <Modal className={classes.modaladd} open={this.state.addTimingModalOpen} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" closeAfterTransition >
          <Container className={classes.mainContainer11}>
            <Grid container className={classes.header3} spacing={1} >
              <Grid item xs={8}>
                <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >Add Business Timing</WhiteTextTypography>
              </Grid>
              <Grid item xs={4}>
                <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
                  <Close className={classes.headGridTwo} onClick={() => { this.close() }} />
                </WhiteTextTypography>
              </Grid>
            </Grid>
            <Container className={classes.modalContainer3}>
              <Grid container className={classes.grid} spacing={1} justify="center" alignItems="center" >
                <Grid item xs={6} sm={2}>
                  <Typography variant="subtitle2" gutterBottom><b>Timings</b></Typography>
                </Grid>
                <Grid item xs={3} sm={5}>
                  <Button style={{ float: "right", fontSize: "12px", fontWeight: "bold" }} variant="outlined" htmlFor="photos" color="primary" component="span" onClick={this.addWorkingDay} >ADD</Button>
                </Grid>
                <Grid item xs={3} sm={5}>
                  <Button htmlFor="photos" color="primary" component="span" style={{ float: 'right', fontSize: "12px", fontWeight: "bold" }} variant="outlined" onClick={this.addHoliday} >ADD HOLIDAY</Button>
                </Grid>

                <Grid item xs={4}> <Typography variant="subtitle2" gutterBottom style={{ marginLeft: '7px' }}>{(this.state.workingDays && this.state.workingDays.length === 0) && (this.state.holidays && this.state.holidays.length === 0) ? null : <b>Day/Days</b>}</Typography></Grid>
                <Grid item xs={4}><Typography variant="subtitle2" gutterBottom style={{ marginLeft: '7px' }}>{(this.state.workingDays && this.state.workingDays.length === 0) && (this.state.holidays && this.state.holidays.length === 0) ? null : (this.state.workingDays && this.state.workingDays.length > 0 ? <b>From</b> : null)}</Typography></Grid>
                <Grid item xs={3}><Typography variant="subtitle2" gutterBottom style={{ marginLeft: '7px' }}>{(this.state.workingDays && this.state.workingDays.length === 0) && (this.state.holidays && this.state.holidays.length === 0) ? null : (this.state.workingDays && this.state.workingDays.length > 0 ? <b>To</b> : null)}</Typography></Grid>
                <Grid item xs={1}> <Typography variant="subtitle2" gutterBottom style={{ marginLeft: '7px' }}></Typography> </Grid>

                {this.state.workingDays.map((index, indexNo) => {
                  return (
                    <Grid container direction="row" key={"workingdays_" + index} justify="center" alignItems="center">

                      {/* To Display Day in dropdown */}
                      <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} error={this.state.dayRequiredError[indexNo] ? true : false} >
                          <Select labelId="demo-simple-select-outlined-label" id="day" value={index.day} name={index} onChange={this.handleChangeWorkingDay} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                            {this.state.days.map((index) => {
                              return (
                                <MenuItem key={index} value={index.day} style={{ minHeight: "28px", height: "28px" }}>
                                  <Typography noWrap>{index.day}</Typography>
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      {/* To Display 'From' Timings in 30min slots in dropdown */}
                      <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} error={this.state.fromRequiredError[indexNo] ? true : (this.state.fromError[indexNo] ? true : (this.state.fromTimeOverlap[indexNo] ? true : false))} >
                          <Select labelId="demo-simple-select-outlined-label" id="from" value={index.from} name={index} onChange={this.handleChangeWorkingDayFromTime} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                            {this.state.from.map((index) => {
                              return (
                                <MenuItem key={index} value={index.from} style={{ minHeight: "28px", height: "28px" }}>
                                  <Typography noWrap>{index.from.toUpperCase()}</Typography>
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      {/* To Display 'To Timings in 30min in dropdown - with last timing as 11:59pm */}
                      <Grid item xs={3}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} error={this.state.toRequiredError[indexNo] ? true : (this.state.toError[indexNo] ? true : (this.state.invalidTime[indexNo] ? true : (this.state.toTimeOverlap[indexNo] ? true : false)))} >
                          <Select labelId="demo-simple-select-outlined-label" id="to" value={index.to} name={index} onChange={this.handleChangeWorkingDayToTime} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                            {this.state.to.map((index) => {
                              return (
                                <MenuItem key={index} value={index.from} style={{ minHeight: "28px", height: "28px" }}>
                                  <Typography noWrap>{index.from.toUpperCase()}</Typography>
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={1}>
                        <Button>
                          <Avatar className={classes.lightBlue}>
                            <Delete color="action" className={classes.deleteBtn} onClick={() => this.deleteWorkingHrs(index)} />
                          </Avatar>
                        </Button>
                      </Grid>

                      <Grid item xs={4}><FormHelperText style={{ color: "red", marginLeft: '10px', marginTop: '0px', marginBottom: '10px' }} id="my-helper-text">{this.state.dayRequiredError[indexNo] ? "Required" : null}</FormHelperText></Grid>
                      <Grid item xs={4}><FormHelperText style={{ color: "red", marginLeft: '10px', marginTop: '0px', marginBottom: '10px' }} id="my-helper-text"> {this.state.fromRequiredError[indexNo] ? "Required" : (this.state.fromError[indexNo] ? "Duplicate Timing" : (this.state.fromTimeOverlap[indexNo] ? "There is time overlap" : null))}</FormHelperText></Grid>
                      <Grid item xs={3}><FormHelperText style={{ color: "red", marginLeft: '10px', marginTop: '0px', marginBottom: '10px' }} id="my-helper-text"> {this.state.toRequiredError[indexNo] ? "Required" : (this.state.toError[indexNo] ? "Duplicate Timing" : ((this.state.invalidTime[indexNo] ? "Invalid value" : (this.state.toTimeOverlap[indexNo] ? "There is time overlap" : null))))} </FormHelperText></Grid>
                      <Grid item xs={1}></Grid>
                    </Grid>
                  )
                })}
                {this.state.holidays.map((index, indexNumber) => {
                  return (
                    <Grid container direction="row" key={index + index._id} justify="center" alignItems="center">
                      <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} error={this.state.holidaysRequiredError[indexNumber] ? true : (this.state.holidaysError[indexNumber] ? true : (this.state.holidayWorkingDayError[indexNumber] ? true : false))}>
                          <Select labelId="Subhead-label" id="day" value={index.day} name={index._id} onChange={this.handleChangeHoliday} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                            {this.state.days.map((index) => {
                              return (
                                <MenuItem key={index} value={index.day} style={{ minHeight: "28px", height: "28px" }}>
                                  <Typography noWrap>{index.day}</Typography>
                                </MenuItem>
                              )
                            })}
                          </Select>

                        </FormControl>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography variant="subtitle2" gutterBottom style={{ marginLeft: "15px" }}> CLOSED</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Button>
                          <Avatar className={classes.lightBlue}>
                            <Delete color="action" className={classes.deleteBtn} onClick={() => this.deleteHoliday(index)} />
                          </Avatar>
                        </Button>
                      </Grid>
                      <Grid item xs={1}></Grid>

                      <Grid item xs={4}><FormHelperText style={{ color: "red", marginLeft: '10px', marginTop: '0px', marginBottom: '10px' }} id="my-helper-text">{this.state.holidaysRequiredError[indexNumber] ? "Required" : (this.state.holidaysError[indexNumber] ? "Duplicate holiday" : (this.state.holidayWorkingDayError[indexNumber] ? "Working day cannot be a holiday" : false))}</FormHelperText></Grid>
                      <Grid item xs={8}></Grid>
                    </Grid>
                  )
                })}

                <Grid item xs={12}> <Divider color="textSecondary" style={{ marginBottom: "5px", marginTop: "10px" }} display="block" /> </Grid>
                <Grid item xs={6} className={classes.gridItem} ></Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <Button variant="contained" component="span" onClick={this.close} style={{ margin: "5px" }} fullWidth> Cancel </Button>
                </Grid>
                <Grid item xs={3} className={classes.gridItem} >
                  <Button variant="contained" color="primary" component="span" onClick={this.saveBusinessTimings} style={{ margin: "5px" }} fullWidth > Save </Button>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </Modal>

        {/* Modal to edit business timings */}
        {
          this.state.editTimingModalOpen ?
            <EditBusinessTimings
              data={this.props}
              open={this.state.editTimingModalOpen}
              onClose={this.close}
            />
            : null
        }

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
          ContentProps={{ 'aria-describedby': 'message-id' }}>
          {muiAlert}
        </Snackbar>
        <Backdrop className={classes.backdrop} open={this.props.business.isFetching}> <CircularProgress color="inherit" /> </Backdrop>

        {/* Deleting the business link */}
        <Dialog open={this.state.modalDeleteOpen} onClose={this.handleDeleteClose}>
          <DialogTitle>Delete Business Video Link</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete this Business Video Link?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
            <Button onClick={() => this.deleteBusinessVideo(this.state.selectedBusinessVideoLink)} color="primary" autoFocus> Yes </Button>
          </DialogActions>
        </Dialog>

        {/* Edit the business link */}
        <Dialog open={this.state.modalEditOpen} onClose={this.handleLinkClose} aria-labelledby="form-dialog-title" >
          <DialogTitle className="dialogTitle" style={{ backgroundColor: "#14B7DD" }}>
            Edit/Modify Business Video Link
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle2" gutterBottom>
              Business Video Link
            </Typography>
            <TextField
              required
              autoFocus
              margin="dense"
              id="businessVideoLinkEdit"
              placeholder="Business Video Link"
              fullWidth
              defaultValue={this.state.selectedBusinessVideoLinkEdit != null ? this.state.selectedBusinessVideoLinkEdit.path : null}
              style={{ width: "500px" }}
              onChange={this.handleBusinessVideoLinkChange}
              error={this.state.businessVideoErrorEditExists}
              helperText={this.state.businessVideoErrorEditExists ? "Business Video Link already exists" : null}
            />
            <DialogContentText>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleLinkClose} variant="contained" color="secondary">Close</Button>
            <Button disabled={!(this.state.businessVideoLinkEdit)}
              onClick={this.onEditBusinessVideoLink}
              variant="contained" color="primary">Save</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.orderPreparationTimerModel} onClose={this.handleDeleteClose}>
          <DialogTitle> Order Preparation timer</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to Set Default Preparation Time ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteOrderPreparationTime} color="primary"> No </Button>
            <Button onClick={() => this.handleOrderPreparationTime(this.state.businessPreparationTime)} color="primary" autoFocus> Yes </Button>
          </DialogActions>
        </Dialog>
      
        {/* <Dialog open={this.state.consumerModalOpen} onClose={this.closeConsumerCountModalOpen}>
        <DialogTitle className="dialogTitle" style={{ backgroundColor: "#14B7DD" }}> Consumer Count</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Consumer Count for radius {this.state.radius} is {this.state.currentConsumerCount}.
            </DialogContentText>
           
          </DialogContent>
          
        </Dialog> */}
        <Dialog open={this.state.consumerModalOpen} onClose={this.closeConsumerCountModalOpen}>
          <DialogTitle className="dialogTitle" style={{ backgroundColor: "#14B7DD", textAlign: "center" }}>
            {this.props.businessDetails.businessId}   {this.props.businessDetails.businessName}</DialogTitle>
          <DialogContent style={{ "display": "flex" }}>
            <Grid item xs={4}>
              <Typography style={{ paddingTop: '25px', marginLeft: "60px" }}>Radius(km)</Typography>
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth margin="dense" variant="outlined" className={classes.formControl} style={{ width: "225px", marginLeft: "60px" }}>
                <TextField margin="dense" name="businessRadius" variant="outlined"
                  pattern="[0-9]"
                  style={{ width: "100px" }}
                  inputmode="numeric"
                  value={this.state.businessRadius}
                  onChange={this.handleChangeBusinessRadius}
                  error = {(this.state.businessRadius === 0 || (!/^([\d]+)(?:\.([\d]{1,3}?))?$/.test(this.state.businessRadius))) ?  "Invalid" : null}
                  helperText={ (this.state.businessRadius === 0 || (!/^([\d]+)(?:\.([\d]{1,3}?))?$/.test(this.state.businessRadius))) ?  "Invalid" : null}

                />
              </FormControl>
            </Grid>
            <Grid item xs={5}></Grid>



          </DialogContent>

          {this.state.calculatedConsumerCountModalOpen ? <Container style={{  marginleft : "75px", padding: "17px 41px", width: "auto"}}>Consumer Count for radius {this.state.businessRadius} is {this.state.currentConsumerCount}</Container> : null}

          <Container style={{ padding: '10px 142px' }}>

            <Button onClick={this.getConsumerCount} variant="contained" color="primary" >
              Get Consumer Count
            </Button>
          </Container>


        </Dialog>
          
     
      

        <Dialog open={this.state.orderPreparationTimerMaxModel} onClose={this.handleDeleteClose}>
          <DialogTitle> Order Preparation timer</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to Set Max Preparation Time ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteOrderPreparationTimeMax} color="primary"> No </Button>
            <Button onClick={() => this.handleOrderPreparationTimeMax(this.state.businessPreparationTimeMax)} color="primary" autoFocus > Yes </Button>
          </DialogActions>
        </Dialog>
      </Container >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard,
    business: state.business,
    user: state.auth.user,
    admin: state.admin,
    categories: state.categories,
    env: state.auth.env
  };
};
export default connect(mapStateToProps, {
  updateBusiness,
  updateBusinessDetails,
  deleteBusinessPhotos,
  getCategoryTree,
  getBusinessById,
  updateBusinessPhotos,
  setBusinessConfigLevel,
  getAllMasterOtp,
  uploadShopActImage,
  getShopActServPro,
  getCategoriesByType,
  fetchEnvs,
  updateBusinessVideo,
  deleteBusinessVideo,
  editBusinessVideo,
  clearMsg,
  deleteExistingAreaManagerBusiness,
  requestBusinessDetailsRecordsFalse,
  requestBusinessDetailsRecords,
  getConsumerCount
})(withStyles(styles)(BusinessDetailsSection));
