import * as actions from "../actions/types";

const INITIAL_STATE = {
  appMaintenance: [],
  appMaintenanceDetails: {},
  sucessMsg: null,
  errorMsg: null,
  ops: "",
  isFetching: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.GET_APPMAINTENANCE_DATA:
      return { ...state, appMaintenance: action.payload, isFetching: false };

    case actions.SET_APPMAINTENANCE_DATA_SUCCESS_MESSAGE:
      return {
        ...state,
        sucessMsg: action.payload.msg,
        ops: action.payload.ops,
        isFetching: false,
      };
    case actions.SET_APPMAINTENANCE_DATA_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: action.payload.msg,
        ops: action.payload.ops,
        isFetching: false,
      };
    default:
      return state;
  }
}
