import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import {
  createConsumerTag,
  deleteconsumerTag,
  updateconsumerTag,
  getConsumerTagList,
} from "../../actions/consumerTagAction";
import { CSVLink } from "react-csv";
import Tooltip from "@material-ui/core/Tooltip";
import { changeHeaderName } from "../../actions/dashboardActions";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)",
  }),
});

const styles = (theme) => ({
  // appBarSpacer: theme.mixins.toolbar,
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    width: "168px",
  },
  secondaryButton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    border: "1px solid #14B7DD",
    fontWeight: "bold",
  },
  closeBadge: {
    color: "grey",
    cursor: "pointer",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "400px",
  },
  deleteBtn: {
    cursor: "pointer",
    color: "grey",
    margin: "0px 10px",
  },
  editBtn: {
    cursor: "pointer",
    color: "grey",
    margin: "0px 10px",
  },
  arrowFwdBtn: {
    cursor: "pointer",
    color: "grey",
    margin: "0px 10px",
  },
  avatarGrey: {
    width: theme.spacing(10),
    height: theme.spacing(6),
  },
  img: {
    width: "43px",
    height: "43px",
    // paddingLeft: '10px',
    // paddingTop: '3px'
  },
  containerTable: {
    padding: "0px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    marginTop: theme.spacing(3),
  },
  table: {
    justifyContent: "left",
  },
  tableRow: {
    cursor: "pointer",
    height: "60px",
  },
  tableBtn: {
    padding: "0px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  form: {
    margin: "5% 10%",
    maxWidth: "350px",
    // width: '80%',
    // height: '60%',
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    height: "20%",
    margin: "0px 0px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
  },
  headerBar: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  headGridTwoButton: {
    float: "right",
    marginTop: "4px",
    marginLeft:"20px"
  },
  content: {
    // height: '50%',
    margin: "10px 0px",
    overflow: "auto",
  },
  footer: {
    height: "10%",
    margin: "20px 0px 10px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridItem: {
    margin: "0px 20px",
  },
  title: {
    fontSize: "20px",
    textAlign: "left",
    marginBottom: "10px",
    textAlign: "left",
  },
});

class ConsumerTag extends Component {
  constructor() {
    super();
    this.state = {
      value: 0,
      modalAddOpen: false,
      modalEditOpen: false,
      modalDeleteOpen: false,
      snackOpen: false,
      noDelMsg: null,
      sucessMsg: null,
      showExcel: false,
      errorMsg: null,
      selectedConsumerTag: null,
      consumerTagExists: null,
      inputErrorMessage: null,
      consumerTagName: "",
      consumerTagList: [],
      isDisabled: true,
      excelDownloadList: [],
    };
    this.onDragEndNew = this.onDragEndNew.bind(this);
  }

  onDragEndNew = async (result) => {
    if (!result.destination) {
      return;
    }
    const consumerTagList = reorder(
      this.state.consumerTagList,
      result.source.index,
      result.destination.index
    );

    this.setState({
      consumerTagList,
    });
  };

  componentDidMount() {
    this.props.changeHeaderName("Consumer Tag");
    this.getconsumerTagList();
  }

  getconsumerTagList = async () => {
    let response = await getConsumerTagList();
    if(response){
      this.setState({ consumerTagList: response.data });
      let excelList = response.data?.map((item) => ({
        name: item.name,
        consumerTagId: item.consumerTagId,
      }));
      this.setState({ excelDownloadList: excelList });
    }
   
  };

  handleChange = async (e) => {
    const {
      target: { value },
    } = e;
    this.setState({ consumerTagName: value });
    if (!value.trim()) {
      this.setState({ inputErrorMessage: "Consumer Tag Cannot Be Empty" });
      this.setState({ consumerTagName: "", isDisabled: true });
    } else {
      this.setState({ inputErrorMessage: null, isDisabled: false });
    }
  };

  handleModalAddOpen = () => {
    this.setState({ modalAddOpen: true });
    this.setState({ consumerTagName: "" });
    this.setState({ selectedConsumerTag: null });
  };

  handleModalAddClose = () => {
    this.setState({ modalAddOpen: false, inputErrorMessage: null });
    this.setState({ consumerTagName: "" });
  };

  handleRank = async () => {
    let payload = [];

    this.state.consumerTagList.forEach((e, i) => {
      // console.log("i", i);
      e.rank = i + 1;
      payload.push(e);
    });

    let response = await updateconsumerTag(payload);

    if (response) {
      this.setState({ sucessMsg: "Updated Rank Successfully" });
    } else {
      this.setState({ errorMsg: "Error in updating the Rank" });
    }
  };

  handleDialogueDeleteOpen = (consumerTag) => {
    this.setState({ modalDeleteOpen: true });
    this.setState({ selectedConsumerTag: consumerTag });
  };

  handleDialogueDeleteClose = () => {
    this.setState({ modalDeleteOpen: false });
  };

  handleEditModalOpen = (consumerTag) => {
    this.setState({ selectedConsumerTag: { ...consumerTag } });
    this.setState({ consumerTagName: consumerTag.name });
    this.setState({ modalEditOpen: true });
  };

  handleEditModalClose = () => {
    this.setState({ modalEditOpen: false });
    this.setState({ consumerTagName: "" });
    this.setState({ inputErrorMessage: "" });
  };

  handleEditSubmit = async (event) => {
    event.preventDefault();

    if (!this.state.consumerTagName) {
      this.setState({ errorMsg: "Consumer Tag Name Cannot Be Empty" });
      this.setState({ snackOpen: true });
    } else {
      for (let i = 0; i < this.state.consumerTagList.length; i++) {
        if (
          this.state.selectedConsumerTag._id !==
            this.state.consumerTagList[i]._id &&
          this.state.consumerTagName.trim().toLowerCase() ===
            this.state.consumerTagList[i].name.trim().toLowerCase()
        ) {
          this.setState({
            inputErrorMessage: "Consumer Tag With the Same Name Already Exists",
          });
          return;
        }
      }

      let payload = {
        _id: this.state.selectedConsumerTag._id,
        name: this.state.consumerTagName,
      };
      let response = await updateconsumerTag(payload);

      if (response) {
        this.setState({ sucessMsg: "Updated Speciality Successfully" });
        this.setState({ modalEditOpen: false });
        this.getconsumerTagList();
      } else {
        this.setState({ errorMsg: "Error in updating the Speciality" });
      }
    }
  };

  handleAddSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isDisabled: true });
    if (!this.state.consumerTagName) {
      this.setState({ errorMsg: "Consumer Tag Name Cannot Be Empty" });
      this.setState({ snackOpen: true });
      this.setState({ isDisabled: false });
    } else {
      for (let i = 0; i < this.state.consumerTagList.length; i++) {
        if (
          this.state.consumerTagName.trim().toLowerCase() ===
          this.state.consumerTagList[i].name.trim().toLowerCase()
        ) {
          this.setState({
            inputErrorMessage: "Consumer Tag With the Same Name Already Exists",
          });
          return;
        }
      }

      let payload = {
        name: this.state.consumerTagName,
      };

      let response = await createConsumerTag(payload);

      if (response) {
        this.setState({ sucessMsg: "Added Consumer Tag Successfully" });
        this.setState({ modalAddOpen: false, consumerTagName: "" });
        this.getconsumerTagList();
      } else {
        this.setState({ errorMsg: "Error in Adding the Consumer Tag" });
      }
    }
  };

  handleDeleteCustomerTag = async () => {
    // request began and thus showing the spinner
    let response = await deleteconsumerTag(this.state.selectedConsumerTag._id);
    if (response) {
      this.setState({ modalDeleteOpen: false });
      this.getconsumerTagList();
    }
  };

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    this.setState({ noDelMsg: null });
    this.setState({ errorMsg: null });
    this.setState({ sucessMsg: null });
  };

  showExcel = async () => {
    this.getconsumerTagList();
    this.setState({ showExcel: true });
  };

  render() {
    const { classes } = this.props;
    let muiAlert = null;
    let headers = [
      { label: "ConsumerTag Name", key: "name" },
      { label: "ConsumerTag UniqueId", key: "consumerTagId" },
    ];

    if (this.state.sucessMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="success"
        >
          {this.state.sucessMsg}
        </MuiAlert>
      );
    } else if (this.state.errorMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="error"
        >
          {this.state.errorMsg}
        </MuiAlert>
      );
    } else if (this.state.noDelMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="error"
        >
          {this.state.noDelMsg}
        </MuiAlert>
      );
    } else if (this.state.consumerTagExists) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="error"
        >
          {this.state.categoryExists}
        </MuiAlert>
      );
    }

    return (
      <Container style={{ marginTop: "80px", marginBottom: "8px" ,marginLeft:"10px"}}>
        <Grid style={{ marginBottom: "20px" }}>
          <Typography
            className={classes.title}
            variant="subtitle1"
            id="consumerTagTitle"
            component="div"
          >
            Consumer Tag
          </Typography>
        </Grid>
        <Grid container alignContent="center" justify="space-between">
          <Grid item style={{ paddingRight: "460px" }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={this.handleModalAddOpen}
            >
              CONSUMER TAG
            </Button>
          </Grid>
          <Grid item>
          <Grid container alignContent="center" justify="space-between">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleRank}
            >
              SAVE SEQUENCE
            </Button>
          </Grid>
          <Grid item style={{marginLeft:"10px"}}>
            {this.state.showExcel ? (
              this.state.excelDownloadList.length > 0 ? (
                <CSVLink
                  data={this.state.excelDownloadList}
                  headers={headers}
                  filename={"consumertag.csv"}
                  className="btn btn"
                  target="_blank"
                >
                  <Tooltip title="Export to Excel">
                    <img
                      alt="Export To Excel"
                      src="../../../images/exportToExcel.png"
                      width="25"
                      height="25"
                    />
                  </Tooltip>
                </CSVLink>
              ) : null
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.showExcel()}
              >
                DOWNLOAD LIST
              </Button>
            )}
          </Grid>
          </Grid>
          </Grid>
        </Grid>
        {this.state.consumerTagList?.length > 0 && (
          <Container className={classes.containerTable}>
            <TableContainer className={classes.table} component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead
                  style={{
                    width: "960px",
                    height: "58px",
                    backgroundColor: "#F5F5F5",
                  }}
                >
                  <TableRow>
                    <TableCell style={{ fontSize: "18px", textAlign: "left" }}>
                      Tag Title
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody component={DroppableComponentNew(this.onDragEndNew)}>
                  {this.state.consumerTagList?.map((eachConsumerTag, index) => {
                    return (
                      <TableRow
                        component={DraggableComponentNew(
                          eachConsumerTag._id,
                          index
                        )}
                        className={classes.tableRow}
                        hover
                        key={eachConsumerTag.name}
                      >
                        <TableCell align="left">
                          <Typography
                            color="textPrimary"
                            variant="subtitle1"
                            gutterBottom
                          >
                            {eachConsumerTag.name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.tableBtn}
                          style={{ width: "100px" }}
                        >
                          <div>
                            <EditIcon
                              className={classes.editBtn}
                              onClick={() =>
                                this.handleEditModalOpen(eachConsumerTag)
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.tableBtn}
                          style={{ width: "100px" }}
                        >
                          <DeleteIcon
                            className={classes.deleteBtn}
                            onClick={() =>
                              this.handleDialogueDeleteOpen(eachConsumerTag)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        )}
        <Dialog
          className={classes.modal}
          open={this.state.modalAddOpen}
          onClose={this.handleModalAddClose}
          closeAfterTransition
          fullWidth={true}
          maxWidth={false}
        >
          <DialogTitle className={classes.headerBar} id="form-dialog-title">
            <div>
              Add Consumer Tag
              <CloseIcon
                className={classes.headGridTwoButton}
                onClick={this.handleModalAddClose}
              />
            </div>
          </DialogTitle>
          <form className={classes.form} onSubmit={this.handleAddSubmit}>
            <Grid container className={classes.content}>
              <TextField
                name="addconsumerTag"
                label="ConsumerTag Name"
                value={this.state.consumerTagName}
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={this.handleChange}
                error={Boolean(this.state.inputErrorMessage)}
                helperText={this.state.inputErrorMessage}
              />
            </Grid>

            <Container className={classes.footer}>
              <div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  ///style={{ padding: "20px" }}
                  margin="normal"
                  color="primary"
                  disabled={this.state.isDisabled}
                  onClick={this.handleAddSubmit}
                >
                  Confirm
                </Button>
              </div>
            </Container>
          </form>
        </Dialog>
        <Dialog
          className={classes.modal}
          open={this.state.modalEditOpen}
          onClose={this.handleEditModalClose}
          closeAfterTransition
          fullWidth={true}
          maxWidth={false}
        >
          <DialogTitle className={classes.headerBar} id="form-dialog-title">
            <div>
              Edit Consumer Tag
              <CloseIcon
                className={classes.headGridTwoButton}
                onClick={this.handleEditModalClose}
              />
            </div>
          </DialogTitle>
          <form className={classes.form} onSubmit={this.handleEditSubmit}>
            <Grid container className={classes.content}>
              <TextField
                name="editConsumerTag"
                label="Consumer Tag Name"
                value={this.state.consumerTagName}
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={this.handleChange}
                error={Boolean(this.state.inputErrorMessage)}
                helperText={this.state.inputErrorMessage}
              />
            </Grid>
            <Container className={classes.footer}>
              <div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  ///style={{ padding: "20px" }}
                  margin="normal"
                  color="primary"
                  onClick={this.handleEditSubmit}
                >
                  Confirm
                </Button>
              </div>
            </Container>
          </form>
        </Dialog>
        <Dialog
          open={this.state.modalDeleteOpen}
          onClose={this.handleDialogueDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to delete the Consumer Tag?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={this.handleDialogueDeleteClose}
              className={classes.secondaryButton}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={this.handleDeleteCustomerTag}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          {muiAlert}
        </Snackbar>
      </Container>
    );
  }
}

const DraggableComponentNew = (id, index) => (props) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

const DroppableComponentNew = (onDragEnd) => (props) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={"1"} direction="vertical">
        {(provided) => {
          return (
            <TableBody
              ref={provided.innerRef}
              {...provided.droppableProps}
              {...props}
            >
              {props.children}
              {provided.placeholder}
            </TableBody>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { changeHeaderName })(
  withStyles(styles)(ConsumerTag)
);
