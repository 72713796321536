import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    Typography,
    Divider,
    Avatar,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    avatar: {
        marginLeft: 'auto',
        height: 110,
        width: 100,
        flexShrink: 0,
        flexGrow: 0
    },
    divider: {
        margin: "8px 0px"
    }
});

class BusinessCard extends Component {

    render() {
        const { classes, business } = this.props;
        let address = business.address;
        let busiAddress = address.shopNo + ", " + address.street + ", "
            + address.city + ", " + address.pinCode + ", "
            + address.state + ", " + address.landmark;
        let serviceProvider = business.ownerId;

        return (
            <div >
                <Card className={classes.root}>
                    <CardContent>
                        <Grid container spacing={4}>
                            <Grid item lg={9} md={6} xl={9} xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Business Details
                        </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <b>Name</b> : {this.props.business.businessName}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <b>Mobile</b> : {business.primaryContactNumber}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <b>Address</b> : {busiAddress}
                                </Typography>
                            </Grid>
                            <Grid item lg={3} md={6} xl={3} xs={12}>
                                {
                                    business.photos.length > 0 && business.photos[0] ?
                                        <Avatar
                                            className={classes.avatar}
                                            src={business.photos[0].path}
                                        >{this.props.business.businessName}
                                        </Avatar> : null
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider className={classes.divider} />
                    <CardContent>
                        <Grid container spacing={4}>
                            <Grid item lg={9} md={6} xl={9} xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Service Provider Details
                        </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <b>Name</b> : {serviceProvider.firstName + " " + serviceProvider.lastName}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <b>Mobile</b> : {serviceProvider.primaryMobileNumber}
                                </Typography>
                            </Grid>
                            <Grid item lg={3} md={6} xl={3} xs={12}>
                                {
                                    serviceProvider.photo ?
                                        <Avatar
                                            className={classes.avatar}
                                            src={serviceProvider.photo}>
                                            {serviceProvider.firstName + " " + serviceProvider.lastName}
                                        </Avatar> : null
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        );
    }
}


export default connect(null, null)(withStyles(styles)(BusinessCard));