import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    searchPanel: {
        padding: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '25px',
    },
    clearButton: {
        marginLeft : '25px'
    }
});

class Rbsearch extends Component {
  

    render() {
        const { classes, searchVal, onSubmit,
                handleClearButton, clearSearch, onChange } = this.props;
        let clearSearchButton = null;

        if (clearSearch) {
            clearSearchButton =  <Button variant="contained" color="primary" 
                                    onClick={() => handleClearButton()}
                                    className={classes.clearButton}>
                                        Clear
                                </Button>
        }

        return (
            <Container className={classes.searchPanel}>
                <Paper>
                    <form onSubmit={onSubmit}>
                        <InputBase
                            placeholder="Search"
                            value={searchVal}
                            style={{ width : '240px', margin: '0px 10px' }}
                            onChange={onChange}
                        />
                        <IconButton type="submit">
                            <SearchIcon style={{float : 'right'}}/>
                        </IconButton>
                    </form>
                </Paper>
                {clearSearchButton}
            </Container>
    );
  }
}

export default (withStyles(styles)(Rbsearch));
