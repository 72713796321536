import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from '@material-ui/core/InputLabel';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';




const headers = [
  { id: "firstName", disablePadding: false, label: "First Name" },
  { id: "lastName", disablePadding: false, label: "Last Name" },
  { id: "primaryMobileNumber", disablePadding: false, label: "Contact Number" },
  { id: "role", disablePadding: false, label: "Role" },
  { id: "status", disablePadding: false, label: "Status" }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props; 
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCellSmall}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableCellSmall: {
    // fontSize: "12px",
    padding: "6px 0px 6px 16px",
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  let editStatusFlag = false;
  let selectedNewRole = "";
  let updatedObj = {};
  const handleRequestSort = (event, property) => {
    const isAsc = props.orderBy === property && props.order === "asc";
    props.setOrder(isAsc ? -1 : 1);
    props.setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  const onEditButtonClick=(data,i)=>{
    editStatusFlag = true;
    props.changeEditStatusFlag(editStatusFlag,i);
};

const handleFilterChange = (event,row,i) => {
  // console.log("handleFilterChange --->",event.target.value, row,"---i",i)
  selectedNewRole = event.target.value;
  
  row.associatedBusiness.map(a => {
    if (a.businessId === props.rowData.businessId) {
      a.role = event.target.value
    }
    return a;
  });
 
  props.changeNewRole(selectedNewRole,row,i);
  props.updateRoleStatus(row,selectedNewRole);
};
  /* const emptyRows =
    props.rowsPerPage -
    Math.min(
      props.rowsPerPage,
      props.rowData.totalcount - props.page * props.rowsPerPage
    ); */

  const handleServiceProviderStatusChange = (index, event) => {

    let status = event.target.checked;

    index.associatedBusiness.map(a => {
      if (a.businessId === props.rowData.businessId) {
        a.status = (status) ? 'ACTIVE' : "INACTIVE"
      }
      return a;
    });

    let toBeUpdatedObj = index;

    props.handleBusinessMemberStatus(props.rowData, toBeUpdatedObj);
  };
  
  const isChecked = (id) => {
    let userObj = props.rowData.data.find(i => i._id === id);
    let associatedBusinessMemberObj = userObj.associatedBusiness.find(i => i.businessId === props.rowData.businessId);
    let status = (associatedBusinessMemberObj.status === 'ACTIVE') ? true : false;
    return status;
  };

  const getLabel = (row, isColor) => {
    let associatedBusiness = row.associatedBusiness.find((i) => i.businessId === props.rowData.businessId);
    if (associatedBusiness != null) {
      if (isColor) {
        return (associatedBusiness.status === "ACTIVE") ? 'black' : 'red'
      }
      return (associatedBusiness.status === "ACTIVE") ? 'ACTIVE' : 'INACTIVE'
    }
  };

  const getRole = (row) => {
    let findRole = row.associatedBusiness.find(i => i.businessId === props.rowData.businessId).role;
    let roleNameMapping;
      if (findRole === 'superVendor') roleNameMapping = 'Supervendor'
      if (findRole === 'businessAdmin') roleNameMapping = 'Business Admin'
      if (findRole === 'businessMember') roleNameMapping = 'Business Member'
      // console.log("roleNameMapping --->",roleNameMapping)

      return roleNameMapping;
    
  }
  // console.log("props.rowData ---->",props.rowData) 


  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={props.order}
              orderBy={props.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.rowData.totalcount}
            />
            <TableBody>
              {
                props.rowData && props.rowData.data ?
                  props.rowData.data.map((row, index) => {
                    // console.log("only row --->",row,index)
                    if (getRole(row)) {
                      let selectedStatus = props.selectedStatus && props.selectedStatus!="" && props.selectedStatus!=null && props.selectedStatus!=undefined ? props.selectedStatus : getRole(row);
                      // console.log("selectedStatus kya he ---->",selectedStatus)
                      return (
                        <TableRow
                          hover
                          className={classes.tableRow}
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell align="left" className={classes.tableCellSmall}>
                            {row.firstName}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCellSmall}>
                            {row.lastName}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCellSmall}>
                            {row.primaryMobileNumber}
                          </TableCell>
                          {/* <TableCell align="left" className={classes.tableCellSmall}>
                            {row.role = getRole(row)}
                          </TableCell> */}
                             {props.editStatusFlag && props.editIndex === index ? 
                                                <TableCell align="left" className={classes.tableCellSmall}>
                                                    <div style={{display:"flex", flexDirection:"row",alignItems:"flex-end"}}>
                                                        <FormControl fullWidth margin="dense" variant="outlined">
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={selectedStatus === "Supervendor" ? "superVendor" :(selectedStatus === "Business Admin" ? "businessAdmin":(selectedStatus === "Business Member" ? "businessMember" : ""))}
                                                            name="role"
                                                            onChange={(e)=>handleFilterChange(e,row,props.rowData.data[index])}
                                                        //    disabled={ props?.user?.role === "regionalHead" || props?.user?.role === "areaManager" ? true : false}
                                                        >
                                                        <MenuItem value="superVendor" style={{ minHeight: "28px", height: "28px" }}>Supervendor</MenuItem>
                                                        <MenuItem value="businessAdmin" style={{ minHeight: "28px", height: "28px" }}>Business Admin</MenuItem>
                                                        <MenuItem value="businessMember" style={{ minHeight: "28px", height: "28px" }}>Business Member</MenuItem>
                                                        </Select>
                                                        </FormControl>
                                                    </div>
                                                </TableCell>
                                                :
                                                <TableCell align="left" className={classes.tableCellSmall}>
                                                    <div style={{display:"flex", flexDirection:"row",alignItems:"flex-end"}}>
                                                        {getRole(row) ? getRole(row) : "--"}
                                                         {
                                                            props.rowData.userRole === 'admin' && props.role != "Pending Approval Business"? 
                                                             <EditIcon color='primary' style={{marginLeft:"10px"}} onClick={()=>{onEditButtonClick(row,index)}}></EditIcon> 
                                                             : " "
                                                         }
                                                        
                                                    </div>
                                                </TableCell>    
                                            }
                          <TableCell align="left" className={classes.tableCellSmall}>
                            {
                              props.rowData.userRole === 'admin'?
                                <FormControlLabel
                                  control={
                                    <Switch
                                      size="small"
                                      //key={`xirifyAvailabilitySwitch_${busObj.businessId}_${index}`}
                                      checked={isChecked(row._id)}
                                      onChange={(e) => handleServiceProviderStatusChange(props.rowData.data[index], e)}
                                      // disabled =  {row.associatedBusiness.find((i) => i.businessId === props.rowData.businessId).role == 'superVendor'}
                                      color="primary"
                                    />
                                  }
                                  style={{
                                    fontSize: "10px",
                                    color: getLabel(row, 'color')
                                  }}
                                  label={getLabel(row)}
                                /> : <div>{getLabel(row)}</div>
                            }
                          </TableCell>
                        </TableRow>
                      );
                    }
                  }) : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          component="div"
          count={props.rowData.totalcount}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}