import {
    PATCH_REPORTED_SUCCESS_MESSAGE,
    PATCH_REPORTED_ERROR_MESSAGE,
    PROMOCODEREPORT_STATE_CHG_SUCCESS,
    PROMOCODEREPORT_STATE_CHG_ERR,
    SET_PROMOCODEREPORT_SUCCESS_MESSAGE,
    SET_PROMOCODEREPORT_ERROR_MESSAGE,
    SET_PROMOCODEREPORT,
    REQUEST_PROMOCODEREPORT
  } from "../actions/types";
  
  const INITIAL_STATE = {
    page: 0,
    skip: 0,
    limit: 10,
    successMsg: null,
    errorMsg: null,
    showRpd: false,
    promocodesStateChgSuccess: null,
    promocodesStateChgErr: null,
    promocodes: {},
    isFetching: false
  };
  
  export default function (state = INITIAL_STATE, action) {
    
    switch (action.type) {
      case REQUEST_PROMOCODEREPORT:
        return { ...state, isFetching: action.payload };
    
      case PATCH_REPORTED_SUCCESS_MESSAGE:
        return { ...state, successMsg: action.payload };
      case PATCH_REPORTED_ERROR_MESSAGE:
        return { ...state, errorMsg: action.payload };
      case PROMOCODEREPORT_STATE_CHG_SUCCESS:
        return { ...state, promocodesStateChgSuccess: action.payload };
      case PROMOCODEREPORT_STATE_CHG_ERR:
        return { ...state, promocodesStateChgErr: action.payload };
      case SET_PROMOCODEREPORT_SUCCESS_MESSAGE:
        return {
          ...state,
          successMsg: action.payload.msg,
          ops: action.payload.ops,
          // isFetching: false
        };
      case SET_PROMOCODEREPORT_ERROR_MESSAGE:
        return {
          ...state,
          errorMsg: action.payload.msg,
          ops: action.payload.ops,
          // isFetching: false
        };
      case SET_PROMOCODEREPORT:
        return {
          ...state,
          promocodes: action.payload,
          isFetching: false
        };
     
      default:
        return state;
    }
  }