import React from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { CSVLink } from "react-csv";
import Button from "@material-ui/core/Button";

const headCells = [
  { id: "businessId", disablePadding: true, label: "Business Id" },
  { id: "businessName", disablePadding: true, label: "Business Name" },
  { id: "shopperId", disablePadding: true, label: "Shopper Id" },
  { id: "shopperName", disablePadding: true, label: "Shopper Name" },
  { id: "itemId", disablePadding: true, label: "Item Id" },
  { id: "itemName", disablePadding: true, label: "Item Name" },
  { id: "orderId", disablePadding: true, label: "Order Id" },
  { id: "orderRequestDate", disablePadding: true, label: "Order Request Date" },
  {
    id: "frequentlyOrderedCount",
    disablePadding: true,
    label: "Freq. Ordered Count",
  },
  {
    id: "businessServiceItemCount",
    disablePadding: true,
    label: "Business Unique Item Count",
  },
  {
    id: "businessItemCount",
    disablePadding: true,
    label: "Business Item Count",
  },
  {
    id: "isFrequentlyOrdered",
    disablePadding: true,
    label: "Is Frequently Ordered",
  },
  { id: "isItemDisable", disablePadding: true, label: "Is Item Enabled" },
  { id: "excludeItemTags", disablePadding: true, label: "Excluded Item Tags" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            className={classes.tableCellSmall}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar style={{ paddingLeft: "16px" }}>
      <Typography
        className={classes.title}
        variant="subtitle2"
        id="tableTitle"
        component="div"
      >
        Business Item Tag ({props.totalCount})
      </Typography>

      <Typography component="div">
        {props.showDownloadIcon ? (
          <CSVLink
            data={props.data}
            headers={props.headers}
            filename={"Business_Item_Tag.csv"}
            className="btn btn"
            target="_blank"
          >
            <Tooltip title="Export to Excel">
              <img
                alt="Export To Excel"
                src="../../images/exportToExcel.png"
                width="25"
                height="25"
              />
            </Tooltip>
          </CSVLink>
        ) : (
          <Button
            variant="contained"
            margin="normal"
            style={{
              backgroundColor: "green",
              color: "white",
              fontFamily: "bold",
            }}
            onClick={props.fetchBusinessItemTagExcel}
          >
            Export
          </Button>
        )}
      </Typography>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableCellSmall: {
    fontSize: "12px",
    padding: "6px 0px 6px 16px",
  },
  tableRow: {
    // cursor: 'pointer'
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  const headers = [
    { key: "businessId", disablePadding: true, label: "Business Id" },
    { key: "businessName", disablePadding: true, label: "Business Name" },
    { key: "consumerId", disablePadding: true, label: "Shopper Id" },
    {
      key: "shopperName",
      disablePadding: true,
      label: "Shopper Name",
    },
    { key: "serviceId", disablePadding: true, label: "Item Id" },
    { key: "serviceName", disablePadding: true, label: "Item Name" },
    { key: "serviceRequestId", disablePadding: true, label: "Order Id" },
    {
      key: "serviceRequestDate",
      disablePadding: true,
      label: "Order Request Date",
    },
    {
      key: "frequentlyOrderedCount",
      disablePadding: true,
      label: "Freq. Ordered Count",
    },
    {
      key: "businessServiceUniqueOrderCount",
      disablePadding: true,
      label: "Business Unique Item Count",
    },
    {
      key: "businessServiceOrderCount",
      disablePadding: true,
      label: "Business Item Count",
    },
    {
      key: "isFrequentlyOrdered",
      disablePadding: true,
      label: "Is Frequently Ordered",
    },
    { key: "isMenuEnable", disablePadding: true, label: "Is Item Enabled" },
    {
      key: "excludeItemTags",
      disablePadding: true,
      label: "Excluded Item Tags",
    },
  ];

  let data = [];
  props.exportToExcel &&
    props.exportToExcel.forEach((element) => {
      let item = element;
      item.businessId = item.businessId;
      item.businessName = item.businessName;
      item.consumerId = item.consumerId;
      item.shopperName   =  item.shopperName
      item.serviceId = item.serviceId;
      item.serviceName = item.serviceName;
      item.serviceRequestId = item.serviceRequestId;
      item.serviceRequestDate = item.serviceRequestDate;
      item.frequentlyOrderedCount = item.frequentlyOrderedCount;
      item.businessServiceUniqueOrderCount =
        item.businessServiceUniqueOrderCount;
      item.businessServiceOrderCount = item.businessServiceOrderCount;
      item.isFrequentlyOrdered = item.isFrequentlyOrdered;
      item.isMenuEnable = item.isMenuEnable;
      item.excludeItemTags = item.excludeItemTags;
      data.push(item);
    });
  const emptyRows =
    props.rowsPerPage -
    Math.min(
      props.rowsPerPage,
      props.totalCount - props.page * props.rowsPerPage
    );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          headers={headers}
          totalCount={props.totalCount}
          data={data}
          showDownloadIcon={props.showDownloadIcon}
          fetchBusinessItemTagExcel={props.fetchBusinessItemTagExcel}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={props.order}
              orderBy={props.orderBy}
              rowCount={props.totalCount}
            />
            <TableBody>
              {props.data &&
                props.data.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      className={classes.tableRow}
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall13}
                      >
                        {row.businessId &&
                        row.businessId !== null &&
                        row.businessId !== ""
                          ? row.businessId
                          : "NA"}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        className={classes.tableCellSmall}
                      >
                        {row.businessName &&
                        row.businessName !== null &&
                        row.businessName !== ""
                          ? row.businessName
                          : "NA"}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      >
                        {row.consumerId &&
                        row.consumerId !== null &&
                        row.consumerId !== ""
                          ? row.consumerId
                          : "NA"}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      >
                        {row.consumerFirstName} {row.consumerLastName}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      >
                        {row.serviceId &&
                        row.serviceId !== null &&
                        row.serviceId !== ""
                          ? row.serviceId
                          : "NA"}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      >
                        {row.serviceName &&
                        row.serviceName !== null &&
                        row.serviceName !== ""
                          ? row.serviceName
                          : "NA"}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      >
                        {row.serviceRequestId &&
                        row.serviceRequestId !== null &&
                        row.serviceRequestId !== ""
                          ? row.serviceRequestId
                          : "NA"}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      >
                        {moment(row.serviceRequestDate).format("DD-MMM-YYYY")}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      >
                        {row.frequentlyOrderedCount &&
                        row.frequentlyOrderedCount !== null &&
                        row.frequentlyOrderedCount !== ""
                          ? row.frequentlyOrderedCount
                          : "NA"}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      >
                        {row.businessServiceUniqueOrderCount &&
                        row.businessServiceUniqueOrderCount !== null &&
                        row.businessServiceUniqueOrderCount !== ""
                          ? row.businessServiceUniqueOrderCount
                          : "NA"}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      >
                        {row.businessServiceOrderCount &&
                        row.businessServiceOrderCount !== null &&
                        row.businessServiceOrderCount !== ""
                          ? row.businessServiceOrderCount
                          : "NA"}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      >
                        {row.isFrequentlyOrdered === true ||
                        row.isFrequentlyOrdered === false
                          ? row.isFrequentlyOrdered === true
                            ? "Yes"
                            : "No"
                          : "NA"}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      >
                        {row.isMenuEnable === true || row.isMenuEnable === false
                          ? row.isMenuEnable === true
                            ? "Yes"
                            : "No"
                          : "NA"}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      >
                        {row.excludeItemTags === true ||
                        row.excludeItemTags === false
                          ? row.excludeItemTags === true
                            ? "Yes"
                            : "No"
                          : "NA"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
          component="div"
          count={props.totalCount}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
