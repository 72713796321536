import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { useEffect, useRef } from 'react'

const useStyles = makeStyles(theme => ({
    container: {
        bottom: 0
    },
    bubbleContainer: {
        width: "100%",
        display: "grid"
    },
    bubble: {
        border: "0.5px solid #F1F1F1",
        borderRadius: "10px",
        margin: "5px",
        padding: "10px",
        display: "inline-block",
        background:"#F1F1F1"
    }
}));

const SPConverstionLayout = (props) => {
    const classes = useStyles();

    

    const messageRef = useRef();
    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest'
                })
        }
    })

   

    const chatBubbles = props.data.map((obj, i = 0) => (
        <div className={`${classes.bubbleContainer} ${obj.direction}`} key={i} ref={messageRef}>
            <div key={i++} className={classes.bubble} >
               
                
                { obj.text && obj.text!=null && obj.text!=undefined ? obj.text.split("\n").map((i,key) => {
                    return <div key={key} >{i}</div>;
                    }) : null
                }
            </div>
            
                    <div style={{ display: "flex" }}>
                        <Typography variant="caption" style={{ marginLeft: "15em",marginRight: "7px" }} gutterBottom>
                            { obj.userType && obj.userType !== "" ? obj.userName + " " +"(" + obj.userType+")" : obj.userName + ","} 
                        </Typography>
                        <Typography variant="caption" style={{ marginRight: "7px" }} display="block" gutterBottom>
                            {moment(obj.time).format('DD MMM YYYY hh:mm A')}
                        </Typography>
                    </div>
                  

        </div>
    ));
    return <div className={classes.container}>{chatBubbles}</div>;
};

export default SPConverstionLayout;

