import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
	{ label: "Issued", value: "Issued" },
	{ label: "Consumed", value: "Consumed" },
	{ label: "Settled", value: "Settled" },
	{ label: "Invalid", value: "Invalid" },
	{ label: "Expired", value: "Expired" },
	{ label: "GettingConsumed", value: "GettingConsumed" }

];

export default function MultipleSelect(props) {
    const classes = useStyles();

    const handleChange = (event) => {
        props.handleFilterChange(event);
    };

    return (
        <div>
            <FormControl variant="outlined" fullWidth className={classes.formControl} >
                <InputLabel id="demo-mutiple-checkbox-label">Status</InputLabel>
                <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={props.status}
                    name="status"
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {names.map((name) => (
                        <MenuItem key={name} value={name.value} style={{ minHeight: "28px", height: "28px" }}>
                            <Checkbox checked={props.status.indexOf(name.value) > -1} />
                            <ListItemText primary={name.label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
