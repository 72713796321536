import {
    SET_ADMIN_LIST,
    SELECT_ADMIN,
    SET_ADMIN_SUCCESS_MESSAGE,
    SET_ADMIN_ERROR_MESSAGE,
    REQUEST_ADMIN,
    GET_OTP_RECORDS
} from "../actions/types";

const INITIAL_STATE = {
    adminList: [],
    selectedAdmin: {},
    sucessMsg: null,
    errorMsg: null,
    isFetching: false,
    otpRecords: {}
};


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_ADMIN_LIST:
            return { ...state, adminList: action.payload };
        case SELECT_ADMIN:
            return { ...state, selectedAdmin: action.payload };
        case REQUEST_ADMIN:
            return { ...state, isFetching: action.payload };
        case SET_ADMIN_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload,
            };
        case SET_ADMIN_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload,
            };
        case GET_OTP_RECORDS:
            return {
                ...state,
                otpRecords: action.payload
            };
        default:
            return state;
    }
}