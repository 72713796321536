import React, { useState, useEffect, useImperativeHandle, useRef } from 'react';
import TransactionChargesRow from "./TransactionChargesRow";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    
});

const TransactionCharges = React.forwardRef((props, ref)=> {
    const { classes, convFees, convFeesIndex, openDelTxnChargesDialog, handleChgFeeType, 
            txnChargestype, editTxnChargesRow, addTxnChargesRow, openDelConfirmDialog,
            changeTxnChargesRow, handleConvFeesActiveState, openSubsequentTxnRowDelConfirmDialog
          } = props;
    const [anchorEl, setAnchorE1] = useState(null);
    const txnRowsRef = useRef();
    // console.log("convFees = ", convFees);

    const handleMenuClick = event => {
        setAnchorE1(event.currentTarget);
    }

    const saved = () => {
      if(txnRowsRef && txnRowsRef.current) {
        txnRowsRef.current.saved();
      }
    }

    useImperativeHandle(ref, () => ({
      saved
    }));

    const handleMenuClose = () => {
        setAnchorE1(null);
    };

    return (
        <div class="txnCharges">
            <Paper style={{ margin: "16px 0px", padding: "16px", backgroundColor: convFees.isActive?'aliceblue':'#d3d3d32b' }}>
                <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={3}>
                        <FormControl className={classes.formControl} fullWidth variant="outlined" disabled>
                            <Select
                                labelId="txn-charges-select-outlined-label"
                                id="txn-charges-select-outlined"
                                autoWidth
                                value={txnChargestype === "businessConvFees" ? 2 : 1}
                            >
                                <MenuItem value={1}>Charges to Consumer</MenuItem>
                                <MenuItem value={2}>Charges to Business</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} >
                        <RadioGroup row aria-label="position" name="position" value={convFees.feeType}
                            onChange={(e) => handleChgFeeType(convFeesIndex, e.target.value, txnChargestype)}>
                            <FormControlLabel value="flat" control={<Radio color="primary" />} label="Flat" />
                            <FormControlLabel value="slab" control={<Radio color="primary" />} label="Slab" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={5}>
                        <div style={{float: 'right', borderRadius:'5px', padding:'5px 0', width:'80px', backgroundColor:convFees.isActive === true ? 'forestgreen':'#bdbdbd'}}>
                            <Typography style={{ textAlign:'center',  color: 'white', fontSize:'12px' }}>{convFees.isActive === true ? "ACTIVE" : "INACTIVE"}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            style={{ float: 'right' }}
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => 
                                {
                                    handleMenuClose();
                                    openDelTxnChargesDialog(convFeesIndex, txnChargestype);
                                }
                            }>Delete</MenuItem>
                            <MenuItem>Active
                                <Switch
                                    checked={convFees.isActive}
                                    onChange={(e)=> {
                                        handleMenuClose();
                                        handleConvFeesActiveState(e.target.checked, convFeesIndex, txnChargestype)}
                                    }
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    color="primary"
                                />
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
                <TransactionChargesRow convFees={convFees}
                    ref={txnRowsRef}
                    convFeesIndex={convFeesIndex}
                    txnChargestype={txnChargestype}
                    editTxnChargesRow={editTxnChargesRow}
                    addTxnChargesRow={addTxnChargesRow}
                    openDelConfirmDialog={openDelConfirmDialog}
                    changeTxnChargesRow={changeTxnChargesRow}
                    openSubsequentTxnRowDelConfirmDialog={openSubsequentTxnRowDelConfirmDialog}
                    />
            </Paper>
            {/* <div>
                <TransactionChargesRow convFees={convFees}
                    convFeesIndex={convFeesIndex}
                    txnChargestype={txnChargestype}
                    editTxnChargesRow={editTxnChargesRow}
                    addTxnChargesRow={addTxnChargesRow}
                    delTxnChargesRow={delTxnChargesRow}
                    changeTxnChargesRow={changeTxnChargesRow}
                />
            </div> */}
        </div>
    );
})

export default withStyles(styles)(TransactionCharges);