import React, { Component } from "react";
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import UploadCampaignPhoto from "./uploadCampaignPhoto";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import DeleteIcon from '@material-ui/icons/Delete';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from "@material-ui/core/FormHelperText";
import MUIRichTextEditor from "mui-rte";
import { stateToHTML } from "draft-js-export-html";
import { convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
        marginTop: '100px',
        width: '100%',
        height: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        height: '60px',
        marginBottom: '25px'
    },
    paper2: {
        padding: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        height: '150px',
        marginBottom: '25px'
    },
    snackClose: {
        padding: theme.spacing(0.5),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px",
        width: "100%",
    },
    form: {
    //   width: '70%',
    //   height: '170%',
        // width: '100%',
        // height: '100%',
        marginTop: "300px",
        width: '90%',
        height: '200%',

    },
    header: {
    //   height: '9%',
        height: '7%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    content: {
        height: '60%',
        // margin: '10px 0px',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: '100%',
        height:'100%'
    },
    gridElement: {
        margin: '10px 5px'
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    icon: {
        color: 'white',
    },
    gridborder:{
        border: "1px solid black",
        borderRadius: "25px",
        alignSelf: 'center',
        paddingLeft:"10px",
        paddingRight:"10px",
        paddingTop:"10px",
        paddingBottom:"10px",
        marginRight:"10px",
    
    },
    headGridThree: {
        float: 'right',
        alignSelf: 'center',
        alignItems: "center",
        marginLeft: '8px',
        marginRight: '4px',
        fontSize: '20px'
    },
});

let dateCurrent = new Date();
let currentDate = new Date();
currentDate = moment(currentDate).format('YYYY-MM-DD');

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

class OfferCodeCampaignEditForm extends Component {
    constructor(props) {
        super(props);

        const tempString=this?.props?.initialValues?.couponDescription || ""
        const contentHTML = convertFromHTML(tempString)
        const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
        const contentString = JSON.stringify(convertToRaw(state))

        this.state = {
            snackOpen: false,
            sucessMsg: null,
            value: 0,
            date: "",
            error: {},
            modalOpen: false, 
            rewardType: "",
            couponName: "",
            couponTotalAmountUser: "",
            numberofCoupons: "",
            valueperCoupon: "",
            minOrderValuePerCoupon: "",
            couponValidityDays: "",
            type: "",
            modalDeleteOpen: false,
            selectedPhoto: null,
            typePhoto: "", //imageUrl
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            campaignName: "",
            businessID: "",
            // couponDescription: "",
            couponDescription: props.initialValues?.couponDescription || "",
            termsAndConditions: "",
            campaignTimeDateRangeStart: "",
            campaignTimeDateRangeEnd: "",
            campaignImage: "",
            // richTextError: false,
            richTextInitialValue:contentString,
            sponsoredBy: "",
            sponsoredByError: false,
            saveBtn: false,
            infinityCheck: false,
            offerCode: "",
            shopperStatus: "",
            shopperStatusError: false,
            saveBtnFlag: false,
        }
    }

    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    };

    renderTextFieldCouponName({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
                inputProps={{ maxLength: 18 }}
            />
        );
    };

    renderTextFieldCheckBox({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <Checkbox
                label={label}
                placeholder={label}
                // error={touched && invalid}
                // helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    };
    
    renderNewTextFiled({type, label, input, meta: { touched, invalid, error }, ...custom }) {   //added for the defect AM-5788
        return (
            <TextField
                label={label}
                type="date"
                placeholder="dd/mm/yyyy"
                error={touched && invalid}
                helperText={touched && error}
                {...custom}
                disabled={true}
            />
        );
    };

    componentWillReceiveProps(nextProps) {
        const { change, couponTotalAmountUser, numberofCoupons } = nextProps
        if (this.props.couponTotalAmountUser !== couponTotalAmountUser || this.props.numberofCoupons !== numberofCoupons) {
          change('valueperCoupon', calculateValuePerCoupon(couponTotalAmountUser,numberofCoupons))
        }
    }

    onSubmit = formValues => {
        if(this.state.couponDescription && this.state.sponsoredBy && this.state.shopperStatus){
            this.setState({saveBtn:true});
            // console.log("if condition")
            formValues.campaignImage = this.state.campaignImage;
            formValues.couponDescription = this.state.couponDescription;
            formValues.sponsoredBy = this.state.sponsoredBy;
            this.props.editFlag === true ? formValues.editFlag = this.props.editFlag  : formValues.duplicateFlag = this.props.duplicateFlag;
            formValues.infinityCheck = this.state.infinityCheck;
            formValues.shopperStatus = this.state.shopperStatus;
            // console.log("formvalues---->",formValues);
            this.props.showLoader();
            this.props.onSubmit(formValues);
        }else{
            // console.log("else condition")
            if (!this.state.couponDescription) { this.setState({ richTextError: true });}
            if (!this.state.sponsoredBy && this.state.sponsoredBy === "") {this.setState({ sponsoredByError: true });}
            if (!this.state.shopperStatus && this.state.shopperStatus === "" && (this.state.shopperStatus !=="new" || this.state.shopperStatus!=="existing")) {
                this.setState({ shopperStatusError: true });
            }
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (!_.isEmpty(this.state.error)) { this.setState({ error: {} }) }
    }

    handleDeleteOpen = (photo) => {
        this.setState({ modalDeleteOpen: true });
        this.setState({ selectedPhoto: photo });
    }

    handleDeleteClose = () => {this.setState({ modalDeleteOpen: false });};

    componentDidMount(){
        this.setState({
            campaignImage:  this.props.campaignData.campaignImage ? this.props.campaignData.campaignImage : this.props.images,
            sponsoredBy: this.props.initialValues?.sponsoredBy?this.props.initialValues.sponsoredBy:"",
            infinityCheck: this.props.initialValues?.infinityCheck?this.props.initialValues.infinityCheck:false,
            campaignTimeDateRangeEnd: this.props.initialValues?.campaignTimeDateRangeEnd?this.props.initialValues.campaignTimeDateRangeEnd:"",
            shopperStatus: this.props.initialValues?.allExistingShoppersFlag && this.props.initialValues?.allExistingShoppersFlag===true? "existing":(this.props.initialValues?.onlyNewShoppersFlag && this.props.initialValues?.onlyNewShoppersFlag===true?"new":""),
            saveBtnFlag:this.props.initialValues?.allExistingShoppersFlag && this.props.initialValues?.allExistingShoppersFlag===true? true:(this.props.initialValues?.onlyNewShoppersFlag && this.props.initialValues?.onlyNewShoppersFlag===true?true:false)
        });
    }

    updateCampaignPhotos = async (files, photoType) => {
        let data = new FormData();
        let folderPath = "Marketplace_Business";
        let temp = files[0].name.split(".");
        let fileName = temp[0] + Math.ceil(Math.random() * 999999) + "." + temp[1];
    
        data.append("file", files[0]);
        data.append("folder", folderPath);
        data.append(
            "public_id",
            `${this.props.campaignData._id}/${fileName}`
        );
        data.append(
            "upload_preset",
            this.props.envs.env.cloudinaryBusinessImagesUploadPreset
        );
        let res = await fetch(this.props.envs.env.cloudinaryUploadUrl, {
            method: "POST",
            body: data,
        });
        let file = await res.json();
        if (photoType === "CampaignPromoCode") {
        let campaignImage = file.secure_url;    
        this.setState({ campaignImage });
        this.setState({ isUploading: false, typePhoto: photoType });
        }
    };

    deleteCampaignPhoto = async (selectedPhoto, photoType) => {    
        if(!photoType) {photoType = 'CampaignPromoCode'}
        let path;
        if (photoType === "CampaignPromoCode") {
            this.props.campaignData.campaignImage = "";
            path = this.props.campaignData.campaignImage;
            this.setState({
            campaignImage: path,
            modalDeleteOpen: false 
            });
        }
    };

    handleRichTextData = editorState => {
        if (editorState.getCurrentContent().getPlainText()) {
            this.setState({
                couponDescription: stateToHTML(editorState.getCurrentContent()),
                richTextError: false
            })
        } else {
            this.setState({
                couponDescription: "",
                richTextError: true
            })
        }
    };

    handleSponsoredByChange = async (event) => {
        this.setState({
            sponsoredBy: event.target.value,
            sponsoredByError: false
        });
    };

    handleCheckBoxInfinity = async (event) => {
        this.setState({ infinityCheck: event.target.checked });
        this.props.initialValues.infinityCheckFlag = event.target.checked;
        // console.log("intial values before sending---->",this.props.initialValues)
        // validate(this.props.initialValues);
    };

    handleFilterChange = (event) => {
        // console.log(event.target.name);
        if(event.target.name==="shopperType") {
            this.setState({shopperStatus:event.target.value, shopperStatusError:false, saveBtnFlag:false});
        }
    };

    render() {
        const { classes } = this.props;

        let campaignStatusNew = (this.props.initialValues.campaignTimeDateRangeEnd > currentDate && this.props.initialValues.campaignStatus) 
        ? "live" 
        : (this.props.initialValues.campaignTimeDateRangeEnd < currentDate && this.props.initialValues.campaignStatus) 
           ? "completed" 
           : "offline";
        this.props.initialValues.rewardType = 'Coupons';

        dateCurrent = (this.props.editFlag) ? moment(this.props.initialValues.campaignTimeDateRangeStart).format('YYYY-MM-DD')
                                            : moment(dateCurrent).format('YYYY-MM-DD') ;
        
        // console.log("Props in edit offer code campaign form-------->",this.props);
        // console.log("state in edit offer code campaign form-------->",this.state);

        return (
            <form 
            onSubmit={this.props.handleSubmit(this.onSubmit)} 
            className={classes.form}>
                
                <Grid container className={classes.header} spacing={3}>
                <Grid item xs={8}>
                    <WhiteTextTypography variant="h6" color="inherit" gutterBottom >
                        Add / Edit / Duplicate Campaign
                    </WhiteTextTypography>
                </Grid>
                <Grid item xs={4}>
                    <WhiteTextTypography variant="h6" color="inherit" gutterBottom >
                        <CloseIcon className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
                    </WhiteTextTypography>
                </Grid>
                </Grid>

                <Container className={classes.content}>
                    <Grid container className={classes.grid} spacing={1} justify="center" alignItems="center" >
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12} sm={4}> <Typography variant="subtitle2" gutterBottom> Campaign Name * </Typography> </Grid>
                        <Grid item xs={12} sm={4}> <Typography variant="subtitle2" gutterBottom> Start Date * </Typography> </Grid>
                        <Grid item xs={12} sm={3}> <Typography variant="subtitle2" gutterBottom> End Date * </Typography> </Grid>
                        <Grid item xs={12} sm={1}> <Typography variant="subtitle2" gutterBottom> Infinity </Typography></Grid>
                        <Grid></Grid>

                        {/* TextFields and Date */}
                         <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="campaignName" label="Campaign Name *" variant="outlined" component={this.renderTextField}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="campaignTimeDateRangeStart" type="date" variant="outlined" InputLabelProps={{shrink: true}} component={this.renderTextField} disabled = {campaignStatusNew !== 'offline'}/>
                        </Grid>
                       
                        {
                        this.state.infinityCheck === true ?
                        <Grid item xs={12} sm={3}>
                        <Field
                                    fullWidth
                                    margin="dense"
                                    name="campaignTimeDateRangeEnd"
                                    variant="outlined"
                                    className={classes.textField}
                                    component={this.renderNewTextFiled}
                                    InputLabelProps={{shrink: true}}
                                    disabled = { this.state.infinityCheck === true ? true : false }
                        />
                        </Grid> 
                          :
                         <Grid item xs={12} sm={3}>
                          <Field
                          fullWidth
                          margin="dense"
                          name="campaignTimeDateRangeEnd"
                          type="date"
                          variant="outlined"
                          // className={classes.textField}
                          InputLabelProps={{shrink: true}}
                          component={this.renderTextField}
                      />
                         </Grid> 
                
                        }
                        
                        <Grid item xs={12} sm={1}>
                            {/* <Checkbox
                                onClick={(event) => this.handleCheckBoxInfinity(event)}
                                    // value={reason.isAvailableForAdmin}
                                name="infinityCheck"
                                    // checked={reason.isAvailableForAdmin}
                                /> */}
                                <Field
									fullWidth
									margin="dense"
									name="infinityCheck"
									variant="outlined"
									InputLabelProps={{shrink: true}}
									component={this.renderTextFieldCheckBox}
                                    onClick={(event) => this.handleCheckBoxInfinity(event)}
                                    checked={this.state.infinityCheck}
								/>
                        </Grid>

                        <Grid item xs={12} sm={4}> <Typography variant="subtitle2" gutterBottom> Sponsored By * </Typography> </Grid>
                        <Grid item xs={12} sm={4}> <Typography variant="subtitle2" gutterBottom> Reward Type * </Typography> </Grid>
                        <Grid item xs={12} sm={4}> <Typography variant="subtitle2" gutterBottom> Coupon Name * </Typography> </Grid>

                        {/* TextFields and drop down*/}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth >
                                <Select
                                    labelId="SponsoredBy-label"
                                    id="demo-simple-select"
                                    value={this.props.initialValues.sponsoredBy}
                                    onChange={this.handleSponsoredByChange}
                                    // component={this.renderTextField}
                                    name="sponsoredBy" 
                                    variant="outlined"
                                    margin="dense"
                                    error={this.state.sponsoredByError ? true : false}
                                    disabled={this.props.editFlag && this.props.editFlag === true}
                                >
                                    <MenuItem value={'Business'}>Business</MenuItem>
                                    <MenuItem value={'Xirify'}>Xirify</MenuItem>
                                </Select>
                                {(this.state.sponsoredByError)? (
                                            <FormHelperText style={{ color: "red" }}>
                                            Required
                                            </FormHelperText>
                                    ) :  null}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth  >
                                <Select
                                    // labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.props.initialValues.rewardType}
                                    // label="Reward Type"
                                    onChange={this.handleChange}
                                    // component={this.renderTextField}
                                    name="rewardType" 
                                    variant="outlined"
                                    margin="dense"
                                >
                                    <MenuItem value={'Coupons'}>Coupons</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="couponName" label="Coupon Name *" variant="outlined" component={this.renderTextFieldCouponName} 
                            disabled={ this.props.editFlag ? true : false }
                            />
                        </Grid>


                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom> Coupon Total Amount/User ({'\u20B9'}) </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}> <Typography variant="subtitle2" gutterBottom> Number of Coupons * </Typography> </Grid>
                        <Grid item xs={12} sm={4}> <Typography variant="subtitle2" gutterBottom> Value per Coupon </Typography> </Grid>

                        {/* TextFields and drop down*/}
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="couponTotalAmountUser" label="Coupon Total Amount/User" variant="outlined" component={this.renderTextField} disabled = {this.props.editFlag === true} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="numberofCoupons" label="Number of Coupons *" variant="outlined" component={this.renderTextField} disabled = {this.props.editFlag === true} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="valueperCoupon" label="Value per Coupon" variant="outlined" component={this.renderTextField} disabled />
                        </Grid>


                        <Grid item xs={12} sm={4}> 
                            <Typography variant="subtitle2" gutterBottom> Min. Order Value (Per Coupon) * </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom> Coupon Validity (Days) * </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>

                        {/* TextFields and drop down*/}
                         <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="minOrderValuePerCoupon" label="Min. Order Value (Per Coupon) *" variant="outlined" component={this.renderTextField} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="couponValidityDays" label="Coupon Validity (Days) *" variant="outlined" component={this.renderTextField} inputProps={{ maxLength: 3 }} />
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>

                        <Grid item xs={12}> <Typography variant="subtitle2" gutterBottom> Shopper Selection * </Typography> </Grid>
                            {
                                // this.props.editFlag == true && this.state.shopperStatus == "" && this.state.saveBtnFlag == false
                                (this.props.editFlag === true || this.props.duplicateFlag === true) && this.state.saveBtnFlag === false
                                ?
                                <Grid item xs={12}>
                                    <RadioGroup row aria-label="position" name="shopperType" 
                                    onChange={this.handleFilterChange} value={this.state.shopperStatus} >
                                        <FormControlLabel value="existing" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>All existing shoppers</Typography>} size="small" />
                                        <FormControlLabel value="new" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Only new shoppers</Typography>} size="small" />
                                    </RadioGroup>
                                </Grid>
                                :
                                <Grid item xs={12} sm={4}>
                                    <TextField fullWidth margin="dense" name="shopperType" label="Shopper Selection" variant="outlined" value={this.state.shopperStatus==="existing"?"All Existing Shoppers":"Only New Shoppers"} disabled />
                                </Grid>
                            }
                            {
                                this.state.shopperStatus === ""
                                ? null
                                :
                                <Grid item xs={12} sm={8}>
                                </Grid>
                            }
                        
                        <Grid item xs={12}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.shopperStatusError===true?"Shopper Selection Required":null} </FormHelperText></Grid>

                        <Grid item xs={4}> <Typography variant="subtitle2" gutterBottom> T&C * </Typography> </Grid>
                        <Grid item xs={4}> <Typography variant="subtitle2" gutterBottom> {this.props.duplicateFlag && this.props.duplicateFlag === true ? "" : "Offer Code"}  </Typography> </Grid>
                        <Grid item xs={4}> </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="termsAndConditions" label="Terms And Conditions *" variant="outlined" component={this.renderTextField} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {
                                this.props.duplicateFlag && this.props.duplicateFlag === true ? "" : <Field fullWidth margin="dense" name="offerCode" label="Offer Code" variant="outlined" component={this.renderTextField} disabled />
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid> <Grid item xs={12} sm={4}></Grid>

                        <Grid item xs={12}> <Typography variant="subtitle2" gutterBottom> Coupon Description * </Typography> </Grid>
                
                        {/* TextFields and drop down*/}
                        <Grid item xs={12}>
                            <div>
                                {this.state.richTextError ? 
                                <FormHelperText style={{ color: "red" }}> Coupon Description Required </FormHelperText>
                                : null
                                }
                            </div>
                            <MUIRichTextEditor
                                name="couponDescription"
                                label="Coupon Description"
                                toolbarButtonSize="small"
                                defaultValue={this.state.richTextInitialValue}
                                value={this.state.richTextInitialValue}
                                inlineToolbar={true}
                                error={this.state.richTextError}
                                onChange={this.handleRichTextData}
                                controls={['bold', 'italic', 'underline', 'strikethrough', 'link', 'numberList', 'bulletList', 'media', 'undo', 'redo']}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}></Grid><Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}><Typography variant="subtitle2" gutterBottom></Typography></Grid>
                        <Grid item xs={12} sm={4}></Grid><Grid item xs={12} sm={4}></Grid>

                        {/* Upload Campaign Image button */}
                        <Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Image
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <UploadCampaignPhoto
                                uploadButtonName = "UPLOAD IMAGE"
                                updateCampaignPhotos = {this.updateCampaignPhotos}
                                photoType="CampaignPromoCode"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {(this.state.campaignImage !== "" ) ?
                                <GridList cols={7} cellHeight={80} className={classes.gridList}>
                                    <GridListTile
                                        className={classes.gridElement} 
                                            // key={images._id}
                                    >
                                        <a href={this.state.campaignImage} target="_blank" rel="noopener noreferrer">
                                            <img border="0" className={classes.img} src={this.state.campaignImage} alt="" />
                                        </a>
                                        <GridListTileBar  
                                            titlePosition="bottom"
                                            actionIcon={
                                                <IconButton className={classes.icon} 
                                                onClick={() => this.handleDeleteOpen(this.state.campaignImage)}
                                                >
                                                <DeleteIcon />
                                                </IconButton>
                                            }
                                            actionPosition="right"
                                        />
                                    </GridListTile>
                                </GridList >
                            : null}
                        
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12}> 
                            <Divider color="textSecondary" style={{ marginBottom: "5px", marginTop: "10px" }} display="block" />
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                style={{ margin: "5px" }}
                                fullWidth
                                variant="contained"
                                onClick={() => { this.props.onClose() }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                fullWidth
                                style={{ margin: "5px" }}
                                variant="contained"
                                color="primary"
                                type="submit"
                                // disabled={this.state.saveBtn}
                            >
                                ADD / SAVE
                            </Button>
                        </Grid> 
                    </Grid>
                </Container>

                <Dialog open={this.state.modalDeleteOpen} onClose={this.handleDeleteClose}>
                    <DialogTitle>Delete Photo</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        Do you want to delete this photo?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
                        <Button onClick={() => this.deleteCampaignPhoto(this.state.selectedPhoto, this.state.typePhoto)} color="primary" autoFocus> Yes </Button>
                    </DialogActions>
                </Dialog>
            </form>
        );
    }
}

const validateValuePerCoupon = (couponTotalAmountUser,valueperCoupon) => {
    if(couponTotalAmountUser && valueperCoupon) {
        let numberOfCoupon = parseInt(couponTotalAmountUser)/parseInt(valueperCoupon);
        if (numberOfCoupon % 1===0) {
            return ""
        } else {
            return "Value per coupon should be a whole number"}
    }
}

const calculateValuePerCoupon = (couponTotalAmountUser,valueperCoupon) => {
    if (couponTotalAmountUser && valueperCoupon) {
        let numberOfCoupon = parseInt(couponTotalAmountUser)/parseInt(valueperCoupon);
        if (numberOfCoupon % 1===0) {
            return numberOfCoupon
        } else return ""
    } else {
        return ""
    }
}

const validate = values => {
    // console.log("validate values: ",values);
    // console.log("validate values infinityCheckFlag: ",values.infinityCheck);
    const errors = {}
    let requiredFields = [];
    // const requiredFields = ['campaignName', 'rewardType','couponName','couponTotalAmountUser', 'numberofCoupons', 'valueperCoupon', 'minOrderValuePerCoupon', 'couponValidityDays', 'couponDescription','termsAndConditions', 'campaignTimeDateRangeStart', 'campaignTimeDateRangeEnd']

    // requiredFields.forEach(field => {
    //     if (!values[field]) {
    //         errors[field] = 'Required'
    //     }
    // });

    if (values.infinityCheck) {
        // console.log("infinity check flag");
        requiredFields = ['campaignName', 'rewardType', 'couponName', 'couponTotalAmountUser', 'numberofCoupons', 'valueperCoupon', 'minOrderValuePerCoupon', 'couponValidityDays', 'couponDescription', 'campaignTimeDateRangeStart', 'termsAndConditions', 'sponsoredBy']
        requiredFields.forEach(field => {
            if (!values[field] && field !== 'sponsoredBy') {
                errors[field] = 'Required'
            }
        }); //4284
    } else {
        requiredFields = ['campaignName', 'rewardType', 'couponName', 'couponTotalAmountUser', 'numberofCoupons', 'valueperCoupon', 'minOrderValuePerCoupon', 'couponValidityDays', 'couponDescription', 'campaignTimeDateRangeStart', 'termsAndConditions', 'sponsoredBy', 'campaignTimeDateRangeEnd']
        requiredFields.forEach(field => {
            if (!values[field] && field !== 'sponsoredBy') {
                errors[field] = 'Required'
            }
        }); //4284
    }

    if (values.campaignTimeDateRangeStart) {
        if (values.campaignTimeDateRangeStart < dateCurrent ){//&&  !values.editFlag && !values.duplicateFlag) {
            errors.campaignTimeDateRangeStart = 'Campaign Start date cannot be less than current date'
        }
    }
    if (values.infinityCheck === true) {
        // console.log("flag is true----")
        errors.campaignTimeDateRangeEnd = "";
    } else {
        // console.log("flag is false----")
        if (values.campaignTimeDateRangeEnd) {
            if (values.campaignTimeDateRangeEnd < dateCurrent || values.campaignTimeDateRangeEnd < values.campaignTimeDateRangeStart ) {
                errors.campaignTimeDateRangeEnd = 'Campaign End date cannot be less than current date or start date'
            }
        }
    }
    // if (values.campaignTimeDateRangeEnd) {
    //     if (values.campaignTimeDateRangeEnd < dateCurrent || values.campaignTimeDateRangeEnd < values.campaignTimeDateRangeStart ) {
    //         errors.campaignTimeDateRangeEnd = 'Campaign End date cannot be less than current date or start date'        }
    // }
    if (values.couponTotalAmountUser) {
        if (values.couponTotalAmountUser < 1 || !/^[0-9]+$/i.test(values.couponTotalAmountUser) ) {
            errors.couponTotalAmountUser = 'Invalid Amount'
        }
    }
    if (values.numberofCoupons) {
        if (values.numberofCoupons < 1 || !/^[0-9]+$/i.test(values.numberofCoupons) ) {
            errors.numberofCoupons = 'Invalid Number of Coupons'
        }
    }
    if (values.valueperCoupon) {
        if (values.valueperCoupon < 0 || !/^[0-9]+$/i.test(values.valueperCoupon) ) {
            errors.valueperCoupon = 'Invalid Amount'
        }
        if (values.minOrderValuePerCoupon && parseInt(values.valueperCoupon) >= parseInt(values.minOrderValuePerCoupon)) {
            errors.minOrderValuePerCoupon = 'Min. Order Value cannot be less than or equal to Coupon Amount'
        }
    }
    errors.valueperCoupon=validateValuePerCoupon(values.couponTotalAmountUser,values.numberofCoupons);
    
    if(!errors.numberofCoupons && !values.valueperCoupon)
        errors.numberofCoupons=validateValuePerCoupon(values.couponTotalAmountUser,values.numberofCoupons);
    
    if(!errors.couponTotalAmountUser && !values.valueperCoupon)
        errors.couponTotalAmountUser=validateValuePerCoupon(values.couponTotalAmountUser,values.numberofCoupons);
    
    if (values.minOrderValuePerCoupon) {
        if (values.minOrderValuePerCoupon < 0 || !/^[0-9]+$/i.test(values.minOrderValuePerCoupon) ) {
            errors.minOrderValuePerCoupon = 'Invalid Amount'
        }
        if (values.valueperCoupon && parseInt(values.valueperCoupon) >= parseInt(values.minOrderValuePerCoupon)) {
            errors.minOrderValuePerCoupon = 'Min. Order Value cannot be less than or equal to Coupon Amount'
        }
    }
    if (values.couponValidityDays) {
        if (values.couponValidityDays < 0 || !/^[0-9]+$/i.test(values.couponValidityDays) ) {
            errors.couponValidityDays = 'Invalid Number of Days'
        }
    }

    if(values.couponName) {
        if (values.duplicateFlag && values.couponName.trim().length > 18) {
            errors.couponName = 'Coupon Name must be at least 18 characters'
        }
        if (values.couponName.trim().length === 0) {
            errors.couponName = 'Required'
        }
    }

    // console.log("errors---->", errors)
    return errors
}

const makeMapStateToProps = () => {
    const mapStateToProps = (state, props) => {
        // console.log("state----->",state)
        let numberofCoupons = state.form?.CampaignAddForm?.values?.numberofCoupons 
        let couponTotalAmountUser = state.form?.CampaignAddForm?.values?.couponTotalAmountUser
        let infinityCheckFlag = state.form?.CampaignAddForm?.values?.infinityCheckFlag 
        if (state.form?.CampaignAddForm?.values) 
            state.form.CampaignAddForm.values.duplicateFlag = props.duplicateFlag 
      return {
        campaign: state.campaign,
        numberofCoupons: numberofCoupons,
        couponTotalAmountUser:couponTotalAmountUser,
        infinityCheckFlag: infinityCheckFlag
      }
    }
    return mapStateToProps
}

OfferCodeCampaignEditForm = reduxForm(
    {
        form: 'CampaignAddForm',
        validate
    },
    makeMapStateToProps
)(withStyles(styles)(OfferCodeCampaignEditForm))

OfferCodeCampaignEditForm= connect(makeMapStateToProps)(OfferCodeCampaignEditForm)

export default OfferCodeCampaignEditForm