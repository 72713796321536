import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import 'bootstrap-daterangepicker/daterangepicker.css';
import CloseIcon from "@material-ui/icons/Close";
// import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { ReactComponent as EllipseHistory } from "../../../images/EllipseHistory.svg";
import { ReactComponent as LineHistoryFlow } from "../../../images/LineHistoryFlow.svg";
import { ReactComponent as GroupHistoryFlowLinesHori } from "../../../images/GroupHistoryFlowLinesHori.svg";
// import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
// import StepButton from '@material-ui/core/StepButton';
// import {
//     StepLabel,
//     StepConnector,
//     makeStyles
// } from "@material-ui/core";

const styles = (theme) => ({
    gridBC: {
        // backgroundColor :'#f5f5f5',
        backgroundColor :'#FAFAFA',
        paddingLeft:'8px',
        paddingRight:'8px',
        margin: '0px 10px 0px 10px',
        borderRadius:"0px 0px 10px 10px",
    },
    gridMain: {
        // backgroundColor :'#f5f5f5',
        backgroundColor :'#FAFAFA',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius:"0px px 10px 10px",
    },
    paymentScheduleBG: {
        // height: '13%',
        margin: '20px 20px 20px 20px',
        // padding: '20px 20px 20px 20px', //top right bottom left
        // width: '100%',
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
        // backgroundColor: 'red',
        borderRadius:"15px 15px 15px 15px",
        // fontSize:"14px",
        // overflowwrap: 'break-word',
        wordWrap:"normal",
        // backgroundColor: '#e0e0e0'
        backgroundColor: '#EFEFEF'
    },
    chargebackBG: {
        // height: '13%',
        margin: '20px 50px 20px 70px',
        // padding: '20px 20px 20px 20px', //top right bottom left
        // width: '100%',
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
        // backgroundColor: 'red',
        borderRadius:"15px 15px 15px 15px",
        // fontSize:"14px",
        // overflowwrap: 'break-word',
        wordWrap:"normal",
        // backgroundColor: '#e0e0e0'
        backgroundColor: '#EFEFEF'
    },
    additionaltermsBG: {
        // height: '13%',
        margin: '20px 20px 20px 20px',
        // padding: '20px 20px 20px 20px', //top right bottom left
        // width: '100%',
        display: 'flex',
        flexDirection: "row",
        alignItems: 'left',
        justifyContent: 'left',
        // backgroundColor: 'red',
        borderRadius:"15px 15px 15px 15px",
        // fontSize:"14px",
        // overflowwrap: 'break-word',
        wordWrap:"normal",
        // backgroundColor: '#e0e0e0'
        // backgroundColor: '#EFEFEF'
        border: "3px solid #EFEFEF",
    },
    additionaltermsBGSecond: {
        // height: '13%',
        margin: '20px 20px 20px 20px',
        // padding: '20px 20px 20px 20px', //top right bottom left
        // width: '100%',
        display: 'flex',
        flexDirection: "row",
        alignItems: 'left',
        justifyContent: 'left',
        // backgroundColor: 'red',
        borderRadius:"15px 15px 15px 15px",
        // fontSize:"14px",
        // overflowwrap: 'break-word',
        wordWrap:"normal",
        // backgroundColor: '#e0e0e0'
        // backgroundColor: '#EFEFEF'
        border: "3px solid #EFEFEF",
    },
    headerOne: {
        height: '13%',
        margin: '20px 0px 0px 0px',
        padding: '15px 0px 15px 20px', //top right bottom left
        // width: '100%',
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
        // backgroundColor: 'red',
        borderRadius:"15px 15px 0px 0px",
        fontSize:"14px",
        // backgroundColor: '#e0e0e0'
        backgroundColor: '#EFEFEF'
    },
    headerOneHist: {
        height: '13%',
        margin: '20px 0px 0px 0px',
        padding: '15px 0px 15px 20px', //top right bottom left
        // width: '100%',
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
        // backgroundColor: 'red',
        borderRadius:"15px 15px 0px 0px",
        fontSize:"14px",
        // backgroundColor: '#e0e0e0'
        backgroundColor: '#EFEFEF'
    },
    // paper: {
    //   padding: "0px 0px",
    //   display: "flex",
    //   alignItems: "center",
    //   height: "60px",
    //   marginBottom: "25px",
    // },
    // snackClose: {
    //   padding: theme.spacing(0.5),
    // },
    // backdrop: {
    //   zIndex: theme.zIndex.drawer + 1,
    //   color: "#fff",
    // },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px",
        margin: "0px",
        flexDirection: "column",
        width: "85%",
        // width: "75%",
        height: "150%",
        overflow: "auto"
    },
    modalHistory: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px",
        margin: "0px 0px 0px -15px",
        flexDirection: "column",
        // width: "30%",
        width: "33%",
        height: "150%",
        overflow: "auto",
        position: "relative", 
        // top: -2365, 
        top: 0, 
        right: 40, 
    },
    modalBG: {
        // flexGrow: 1,
        // justifyContent: "space-between",
        display: "flex",
        alignItems: "left",
        justifyContent: "left",
        padding: "0px",
    //   flexDirection: "column",
        // width: "130%",
        width: "100%",
        height: "150%",
        // overflow: "auto",
        // backgroundColor: "red"
        marginLeft: "-20px"
    },
    // header: {
    //   //   height: '9%',
    //   // height: "7%",
    //   margin: "0px 0px",
    //   width: "100%",
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "center",
    //   backgroundColor: theme.palette.primary.main,
    // },
    // headerWhite: {
    //   margin: "0px 0px",
    //   width: "100%",
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "center",
    //   backgroundColor: "white",
    //   padding: "10px",
    // },
    headGridTwo: {
      float: "right",
      marginRight: "2px",
      fontSize: "20px",
    },
    content: {
        // height: "60%",
        margin: '0px 0px 0px 0px',
        padding: "20px 50px 20px 30px",
        //   overflow: "auto",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    contentHist: {
        // height: "60%",
        margin: '0px 0px 0px 0px',
        padding: "20px 0px 20px 0px",
        //   overflow: "auto",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    headerTwo: {
        height: '13%',
        // margin: '0px',
        paddingLeft:'8px',
        paddingRight:'8px',
        margin: '15px 10px 0px 10px',
        // width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: 'red',
        borderRadius:"15px 15px 0px 0px",
        fontSize:"14px",
        // backgroundColor: '#e0e0e0'
        backgroundColor: '#EFEFEF'
    },
    btn: {
        textTransform: 'capitalize',
        transform: 'rotate(90deg)',
        height: "25px",
        alignItems: 'center',
        // width: 10,
        position: "relative", 
        // top: -1880, 
        top: 95, 
        // top: -2010, 
        right: 20, 
        backgroundColor: theme.palette.primary.main,
        fontWeight: 700,
        color: "white"
        // zIndex: 2000
    },
    btnHist: {
        textTransform: 'capitalize',
        transform: 'rotate(90deg)',
        height: "25px",
        alignItems: 'center',
        // width: 10,
        position: "relative", 
        // top: -2100, 
        // top: -1995,
        top: 91, // increse = will move downwards, decrease = will move upwards
        // right: -2365, 
        // right: -250, 
        right:-310, //decrease = will go towards right, increase = will go towards left
        // backgroundColor: theme.palette.primary.main,
        color: "white",
        fontWeight: 700,
        // zIndex: 2000
    },
    circle: {
        top: "325px",
        left: "1026px",
        width: "12px",
        height: "12px",
        // border: "1px solid",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "1px solid #14B7DD",
        opacity: 1,
        borderRadius: "50%",
    },
    circledoted: {
        top: "34px",
        left: "1046px",
        width: "12px",
        borderRadius: "50%",
        height: "12px",
        // border: "1px dashed",
        border: "1px dashed #14B7DD",
        opacity: 1,
      },
    lines: {
        top: "332px",
        left: "1032px",
        width: "0px",
        height: "14px",
        // border: "1px dashed",
        border: "1px dashed #14B7DD",
        opacity: 1,
    },
    horlines: {
        top: "351px",
        left: "1033px",
        width: "14px",
        height: "0px",
        // border:"1px dashed",
        border:"1px dashed #14B7DD",
        opacity: 1,
    }
});

// const WhiteTextTypography = withStyles({
//     root: {
//       color: "#FFFFFF",
//     },
// })(Typography);

// const useQontoStepIconStyles = makeStyles({
//     root: {
//         color: '#2e5bff',
//         display: 'flex',
//         height: 28,
//         marginLeft: 8,
//         alignItems: 'center',
//     },
//     active: {
//         color: '#2e5bff',
//     },
//     circle: {
//         zIndex: 2,
//         width: 8,
//         height: 8,
//         borderRadius: '50%',
//         backgroundColor: 'currentColor',
//     },
//     completed: {
//         color: '#2e5bff',
//         zIndex: 1,
//         fontSize: 18,
//     },
//   });

// function QontoStepIcon(props) {
//     const classes = useQontoStepIconStyles();

//     return (
//       <div
//         className={classes.root}
//       >
//         <div style={{color: props.color}} className={classes.circle} />
//       </div>
//     );
//   }


// const QontoConnector = withStyles({
//     alternativeLabel: {
//       top: 10,
//       left: 'calc(-50% + 16px)',
//       right: 'calc(50% + 16px)',
//     },
//     lineVertical: {
//         height: 40
//     },
//     active: {
//       '& $line': {
//         borderColor: '#2e5bff',
//       },
//     },
//     completed: {
//       '& $line': {
//         borderColor: '#2e5bff',
//       },
//     },
//     line: {
//       borderColor: '#eaeaf0',
//       borderTopWidth: 3,
//       borderRadius: 1,
//     },
//   })(StepConnector);

function BusinessAgreementDetails (props) {
    const { classes } = props;
    // console.log("inside BusinessAgreementDetails");
    // console.log("props in details screen ===>", props);
    

    const [openHistoryModalFlag, openHistoryModal] = useState(false);
    const [businessAgreementObj, setBusinessAgreementDetails] = useState(props.businessAgreementDetails[0]);
    const [bcfObj, setBcfObj] = useState({});
    const [bcfSlabs, setBcfSlabs] = useState([]);
    const [separatorFlag, setSeparatorFlag] = useState(false);

    // console.log("businessAgreementObj in details screen ===>", businessAgreementObj);
    // console.log("bcfObj in details screen ===>", bcfObj);
    // console.log("bcfSlabs in details screen ===>", bcfSlabs);

    useEffect(() => {
        // console.log("useEffect");
        setBusinessAgreementDetails(props.businessAgreementDetails[0]);
        if(props.businessAgreementDetails && props.businessAgreementDetails[0] && props.businessAgreementDetails[0].businessConvFees && props.businessAgreementDetails[0].businessConvFees.length>0) {
            setBcfObj(props.businessAgreementDetails[0].businessConvFees[0]);
            setBcfSlabs(props.businessAgreementDetails[0].businessConvFees[0].slabs);
            if(props.businessAgreementDetails[0].businessConvFees[0].slabs.length>1) setSeparatorFlag(true);
        }
    }, [props.businessAgreementDetails[0]]);

    return (
        <div className={classes.modalBG}>
        <div className={classes.modal}>
            <Container className={classes.headerOne}><b>MERCHANT PARTNER AGREEMENT FOR ONLINE ORDERING SERVICES</b></Container>
            <Container className={classes.content} justify="center">

                {/* Business Details */}
                <Grid container spacing={1} className={classes.headerTwo}>
                    <Grid item xs={12}><Typography gutterBottom style={{fontSize:14}}><b>Business Details</b></Typography></Grid>
                </Grid>
                <Grid container spacing={1} className={classes.gridBC}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} sm={3}><Typography variant="subtitle2" gutterBottom> Merchant Name: </Typography></Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.merchantName||"NA"}</Typography></Grid>
                    <Grid item xs={12} sm={3}><Typography variant="subtitle2" gutterBottom >Legal Entity Name: </Typography></Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.legalEntityName||"NA"}</Typography></Grid>
                    <Grid item xs={12} sm={3}><Typography variant="subtitle2" gutterBottom >GST: </Typography></Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.gstNumber||"NA"}</Typography></Grid>
                    <Grid item xs={12} sm={3}><Typography variant="subtitle2" gutterBottom >FSSAI: </Typography></Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.FSSAILicNumber||"NA"}</Typography></Grid>
                    <Grid item xs={12} sm={3}><Typography variant="subtitle2" gutterBottom >Shop Act: </Typography></Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.shopActNumber||"NA"}</Typography></Grid>
                    <Grid item xs={12}sm={3}> <Typography variant="subtitle2" gutterBottom>Address: </Typography> </Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.address && (businessAgreementObj.address.lineOne || businessAgreementObj.address.lineTwo) && businessAgreementObj.address.city && businessAgreementObj.address.state && businessAgreementObj.address.country && businessAgreementObj.address.pinCode ? businessAgreementObj.address.lineOne + ' ' + businessAgreementObj.address.lineTwo + ' ' + businessAgreementObj.address.city + ' ' + businessAgreementObj.address.state + ' ' + businessAgreementObj.address.country + ' ' + businessAgreementObj.address.pinCode : "NA"}</Typography></Grid>
                    <Grid item xs={12}sm={3}> <Typography variant="subtitle2" gutterBottom>Email: </Typography></Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.businessEmailId||"NA"}</Typography></Grid>
                    <Grid item xs={12} sm={3}><Typography variant="subtitle2" gutterBottom>Contact Person: </Typography></Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.contactPerson||"NA"}</Typography></Grid>
                    <Grid item xs={12} sm={3}><Typography variant="subtitle2" gutterBottom>Contact No: </Typography></Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.contactNumber||"NA"}</Typography></Grid>
                </Grid>

                {/* Contract Details */}

                <Grid container spacing={1} className={classes.headerTwo}>
                    <Grid item xs={12}><Typography gutterBottom style={{fontSize:14}}><b>Contract Details</b></Typography></Grid>
                </Grid>
                <Grid container spacing={1} className={classes.gridBC}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} sm={3}><Typography variant="subtitle2" gutterBottom>Contract Start Date: </Typography></Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.startDate ? moment(businessAgreementObj.startDate).format('D MMMM YYYY') : "NA"}</Typography></Grid>
                    <Grid item xs={12} sm={3}><Typography variant="subtitle2" gutterBottom >Contract End Date: </Typography></Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>
                        {businessAgreementObj.isAutoRenewal && businessAgreementObj.isAutoRenewal === true ? (businessAgreementObj.autoRenewalDate && businessAgreementObj.autoRenewalDate.years ?( businessAgreementObj.autoRenewalDate.years) + " year - Auto Renewal" : "NA") : (businessAgreementObj.autoRenewalDate && businessAgreementObj.autoRenewalDate.years ? (businessAgreementObj.autoRenewalDate.years) + " year" : "NA")}
                    </Typography></Grid>
                    {/* <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.autoRenewalDate && businessAgreementObj.autoRenewalDate.years ? businessAgreementObj.autoRenewalDate.years : 0} year auto renewal</Typography></Grid> */}
                    <Grid item xs={12}sm={3}> <Typography variant="subtitle2" gutterBottom>Contract Number: </Typography> </Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.businessAgreementId||"NA"}</Typography></Grid>
                </Grid>


                {/* Payment Schedule */}
                {/* '∞' */}

                <Grid container spacing={1} className={classes.headerTwo}>
                    <Grid item xs={12}><Typography gutterBottom style={{fontSize:14}}><b>Payment Schedule</b></Typography></Grid>
                </Grid>
                <Grid container spacing={1} className={classes.gridBC}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} sm={12}><Typography variant="subtitle2" gutterBottom style={{fontSize:13, color: "#15B5D8"}}><i>The Merchant Partner shall be liable to pay Xirify the amounts set out below</i></Typography></Grid>
                    <Grid item xs={12} sm={3}><Typography variant="subtitle2" gutterBottom>a. One Time Sign-Up Fee: </Typography></Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.oneTimeSignUpFee && businessAgreementObj.oneTimeSignUpFee.outletEntity && businessAgreementObj.oneTimeSignUpFee.rupees !== null && businessAgreementObj.oneTimeSignUpFee.rupees !== undefined ? ((businessAgreementObj.oneTimeSignUpFee.outletEntity === "multiple" ? "Multi" : "Single") + " Outlet Entity " + `${'\u20B9'}` + `${businessAgreementObj.oneTimeSignUpFee.rupees}`) : "NA"}</Typography></Grid>
                    <Grid item xs={12} sm={3}><Typography variant="subtitle2" gutterBottom >b. Business Convenience Charges: </Typography></Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>
                            Business Convenience Charges (BCC) on Transaction {
                                bcfSlabs && bcfSlabs.length === 0 ? (bcfObj && bcfObj.chargeType === 'percentage' ? bcfObj.value + '%' : (bcfObj && bcfObj.chargeType === 'fixed' ? '₹' + bcfObj.value.toFixed(2) : "NA")) : (
                                        bcfSlabs.map((slab) => {
                                            let separator = separatorFlag ? ' , ' : ' ';
                                            if (slab.chargeType === 'percentage') {
                                                
                                                if(!slab.infi){
                                                    return(slab.from + ' to ' + slab.to + ' =   ' + slab.value + '%' + separator);
                                                } else{
                                                    return(slab.from + ' to ' + `${'\u221E'}` + ' =   ' + slab.value + '%' + separator)
                                                }
                                            } else if (slab.chargeType === 'fixed') {
                                                if(!slab.infi){
                                                    return(slab.from.toFixed(2) + ' to ' + slab.to.toFixed(2) + ' =   ' + '₹' + slab.value.toFixed(2) + separator);
                                                } else{
                                                    return(slab.from.toFixed(2) + ' to ' + `${'\u221E'}` + ' =   ' + '₹' + slab.value.toFixed(2) + separator)
                                                }
                                            }

                                        })
                                    ) 
                                } on Invoice Value of each order which includes item cost, Delivery Charges ( Self + Partner) and Xirify Sponsored Coupons if applicable
                        </Typography>
                    </Grid>
                    <Grid item xs={12}sm={3}> <Typography variant="subtitle2" gutterBottom>c. Cancellation Fee: </Typography> </Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>Cancellation Fee {businessAgreementObj.cancellationFeePerTransaction && businessAgreementObj.cancellationFeePerTransaction.cost !== null && businessAgreementObj.cancellationFeePerTransaction.cost !== undefined ? '\u20B9' + businessAgreementObj.cancellationFeePerTransaction.cost : "NA"} per cancelled transactions</Typography></Grid>
                    <Grid item xs={12}sm={3}> <Typography variant="subtitle2" gutterBottom>d. Push Notification: </Typography> </Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>Push Notification Services {businessAgreementObj.pushNotificationServiceCharges && businessAgreementObj.pushNotificationServiceCharges.cost !== null && businessAgreementObj.pushNotificationServiceCharges.cost !== undefined ? '\u20B9' + businessAgreementObj.pushNotificationServiceCharges.cost : "NA"} per PN</Typography></Grid>
                    <Grid item xs={12}sm={3}> <Typography variant="subtitle2" gutterBottom>e. Other Marketing Packages: </Typography> </Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.otherMarketingPackages && businessAgreementObj.otherMarketingPackages.type ? (businessAgreementObj.otherMarketingPackages.type === 'monthly' ? 'Monthly' : (businessAgreementObj.otherMarketingPackages.type === 'threeMonths' ? 'Three Months' : (businessAgreementObj.otherMarketingPackages.type === 'sixMonths' ? 'Six Months' : (businessAgreementObj.otherMarketingPackages.type === 'twelveMonths' ? 'Twelve Months' : (businessAgreementObj.otherMarketingPackages.type === 'oneTime' ? 'One Time' : "NA") )))) : "NA" }</Typography></Grid>
                    <Grid item xs={12}sm={3}> <Typography variant="subtitle2" gutterBottom>f. Cost: </Typography> </Grid>
                    <Grid item xs={12} sm={9}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}> {businessAgreementObj.otherMarketingPackages && businessAgreementObj.otherMarketingPackages.cost !== null && businessAgreementObj.otherMarketingPackages.cost !== undefined ? '\u20B9' + businessAgreementObj.otherMarketingPackages.cost + (businessAgreementObj.otherMarketingPackages && businessAgreementObj.otherMarketingPackages.type && businessAgreementObj.otherMarketingPackages.type === 'monthly' ? ' per month' : (businessAgreementObj.otherMarketingPackages.type === 'threeMonths' ? " for 3 months" : (businessAgreementObj.otherMarketingPackages.type === 'sixMonths' ? " for 6 months" : (businessAgreementObj.otherMarketingPackages.type === 'twelveMonths' ? " for 12 months" : (businessAgreementObj.otherMarketingPackages.type === 'oneTime' ? " one time" : " "))))) : "NA"} </Typography></Grid>
                    <Grid item xs={12} sm={12} className={classes.paymentScheduleBG}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:12, padding: '15px 15px 15px 15px'}}>All charges (Delivery Charges + Business Convenience Charges) will be deducted by Xirify for each transaction in case of online payment. For order / transactions where shoppers pay directly to the merchant for order generated through Xirify, Merchant Partner is liable to pay Xirify all charges for consecutive orders / transactions or via online payment (Xirify representative will share the link or UPI ID for online payment with the Merchant Partner).</Typography></Grid>
                </Grid>


                {/* Services Opted For */}

                <Grid container spacing={1} className={classes.headerTwo}>
                    <Grid item xs={12}><Typography gutterBottom style={{fontSize:14}}><b>Services Opted For</b></Typography></Grid>
                </Grid>
                <Grid container spacing={1} className={classes.gridBC}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} sm={4}><Typography variant="subtitle2" gutterBottom>a. Online Business Listing On Xirify: </Typography></Grid>
                    <Grid item xs={12} sm={8}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.servicesOpted && businessAgreementObj.servicesOpted.isOnlineBusinessListingOnXirify ? 'Yes': 'No'}</Typography></Grid>
                    <Grid item xs={12} sm={4}><Typography variant="subtitle2" gutterBottom >b. Delivery Orders: </Typography></Grid>
                    <Grid item xs={12} sm={8}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.servicesOpted && businessAgreementObj.servicesOpted.isDeliveryOrders ? 'Yes' : 'No'}</Typography></Grid>
                    <Grid item xs={12}sm={4}> <Typography variant="subtitle2" gutterBottom>c. Pick Up Orders: </Typography> </Grid>
                    <Grid item xs={12} sm={8}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.servicesOpted && businessAgreementObj.servicesOpted.isPickUpOrders ? 'Yes' : 'No'}</Typography></Grid>
                    <Grid item xs={12}sm={4}> <Typography variant="subtitle2" gutterBottom>d. Service at Customer Location : </Typography> </Grid>
                    <Grid item xs={12} sm={8}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.servicesOpted && businessAgreementObj.servicesOpted.isServiceAtConsumerLocation ? 'Yes' : 'No'}</Typography></Grid>
                    <Grid item xs={12}sm={4}> <Typography variant="subtitle2" gutterBottom>e. Service and Business Location: </Typography> </Grid>
                    <Grid item xs={12} sm={8}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.servicesOpted && businessAgreementObj.servicesOpted.isServiceAtBusinessLocation ? 'Yes' : 'No'}</Typography></Grid>
                    <Grid item xs={12}sm={4}> <Typography variant="subtitle2" gutterBottom>f. Self-Delivery: </Typography> </Grid>
                    <Grid item xs={12} sm={8}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.servicesOpted && businessAgreementObj.servicesOpted.isSelfDelivery ? 'Yes' : 'No'}</Typography></Grid>
                    <Grid item xs={12}sm={4}> <Typography variant="subtitle2" gutterBottom>g. Xirify Partner Delivery: </Typography> </Grid>
                    <Grid item xs={12} sm={8}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.servicesOpted && businessAgreementObj.servicesOpted.isXirifyPartnerDelivery ? 'Yes' : 'No'}</Typography></Grid>
                    <Grid item xs={12}sm={4}> <Typography variant="subtitle2" gutterBottom>h. ONDC registration: </Typography> </Grid>
                    <Grid item xs={12} sm={8}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.servicesOpted && businessAgreementObj.servicesOpted.isONDCRegistration ? 'Yes' : 'No'}</Typography></Grid>
                    <Grid item xs={12}sm={4}> <Typography variant="subtitle2" gutterBottom>i. ONDC exclusive registration: </Typography> </Grid>
                    <Grid item xs={12} sm={8}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.servicesOpted && businessAgreementObj.servicesOpted.isONDCExclusiveRegistration ? 'Yes' : 'No'}</Typography></Grid>
                </Grid>


                {/* Additional Terms */}

                <Grid container spacing={1} className={classes.headerTwo}>
                    <Grid item xs={12}><Typography gutterBottom style={{fontSize:14}}><b>Additional Terms</b></Typography></Grid>
                </Grid>
                <Grid container spacing={1} className={classes.gridBC}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} sm={4}><Typography variant="subtitle2" gutterBottom>a. Business Convenience Charge: </Typography></Grid>
                    <Grid item xs={12} sm={8}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.businessConvCharges && businessAgreementObj.businessConvCharges.percentage !== null && businessAgreementObj.businessConvCharges.percentage !== undefined ? businessAgreementObj.businessConvCharges.percentage + '%' : "NA"} (GST Inclusive)</Typography></Grid>

                    <Container className={classes.additionaltermsBG}>
                        <Grid container>
                            <Grid item xs={12} sm={3} style={{margin: '10px 0px 0px 0px', padding: '0px 0px 15px 20px'}}><Typography variant="subtitle2" gutterBottom>1. Effective Date: </Typography></Grid>
                            <Grid item xs={12} sm={9} style={{margin: '10px 0px 0px 0px', padding: '0px 0px 15px 20px'}}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>{businessAgreementObj.effectiveDate ? moment(businessAgreementObj.effectiveDate).format('D MMMM YYYY') : "NA"}</Typography></Grid>
                            <br/>
                            <Grid item xs={12} sm={12} className={classes.chargebackBG}>
                            <span style={{width:"25px", height:"75px", margin:"0px", backgroundColor:"#DCDCDC",borderRadius:"12px 0px 0px 12px"}}></span>
                            <Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:12,margin: '20px 20px 20px 20px'}}>For New Sign-Up shall mean the date the Merchant Partner receives its first order</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} className={classes.chargebackBG}>
                                <span style={{width:"30px", height:"75px", margin:"0px", backgroundColor:"#DCDCDC",borderRadius:"12px 0px 0px 12px"}}></span>
                                <Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:12,margin: '20px 20px 20px 20px'}}>For Existing Merchant Partner shall mean from the execution date of this form or as per the notification sent by Xirify to the Merchant Partner</Typography>
                            </Grid>
                        </Grid>
                    </Container>

                    
                    <Container className={classes.additionaltermsBG}>
                        <Grid item xs={12} sm={1} style={{margin: '10px 0px 0px 0px', padding: '0px 0px 0px 20px', fontSize:14}}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>2. </Typography></Grid>
                        <Grid item xs={12} sm={11} style={{margin: '10px 0px 0px 0px', padding: '0px 0px 0px 20px', fontSize:14}}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>The contract shall be automatically renewed at the end of every Term at the sole discretion of Xirify for the same period as the previous Term. In the event, the Merchant Partner does not wish to automatically renew the Term for an additional period, the Merchant Partner shall provide Xirify a written notice of 7 days prior to the expiry of the Term.</Typography></Grid>
                        <br/>
                    </Container>


                    <Grid item xs={12} sm={12}> <Typography variant="subtitle2" gutterBottom>b. Logistics Services and Charges: </Typography> </Grid>
                    <Container className={classes.additionaltermsBG}>
                        <Grid item xs={12} sm={1} style={{margin: '10px 0px 0px 0px', padding: '0px 0px 0px 20px', fontSize:14}}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>1. </Typography></Grid>
                        <Grid item xs={12} sm={11} style={{margin: '10px 0px 0px 0px', padding: '0px 0px 0px 20px', fontSize:14}}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>The Logistics Services shall be rendered in accordance with the Terms</Typography></Grid>
                        <br/>
                    </Container>
                    <Container className={classes.additionaltermsBG}>
                        <Grid item xs={12} sm={1} style={{margin: '10px 0px 0px 0px', padding: '0px 0px 0px 20px', fontSize:14}}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>2. </Typography></Grid>
                        <Grid item xs={12} sm={11} style={{margin: '10px 0px 0px 0px', padding: '0px 0px 0px 20px', fontSize:14}}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>The Merchant Partner agrees it shall not charge delivery charges from Customers for Orders for which Xirify undertakes Logistics Services</Typography></Grid>
                        <br/>
                    </Container>
                    <Container className={classes.additionaltermsBG}>
                        <Grid item xs={12} sm={1} style={{margin: '10px 0px 0px 0px', padding: '0px 0px 0px 20px', fontSize:14}}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>3. </Typography></Grid>
                        <Grid item xs={12} sm={11} style={{margin: '10px 0px 0px 0px', padding: '0px 0px 0px 20px', fontSize:14}}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14}}>Xirify has no control, in any manner whatsoever, with respect to the ratings or reviews received by the Merchant Partner for the Logistics Services availed through Xirify, as such ratings are dependent on multiple factors including but not limited to each customers experience.</Typography></Grid>
                        <br/>
                    </Container>

                    <Grid item xs={12} sm={12}> <Typography variant="subtitle2" gutterBottom>c. Taxes: </Typography> </Grid>
                    <Grid item xs={12} sm={12} style={{fontSize:14, margin:"0px 0px 0px 18px"}}><Typography variant="string" gutterBottom variantMapping="body2" >All amounts payable herein shall be inclusive of applicable taxes, which shall be payable by the Merchant Partner</Typography></Grid>
                    <Grid item xs={12} sm={12}> <Typography variant="subtitle2" gutterBottom>d. Pickup Order: </Typography> </Grid>
                    <Grid item xs={12} sm={12}style={{fontSize:14, margin:"0px 0px 0px 18px"}}><Typography variant="string" gutterBottom variantMapping="body2" >In the event Merchant Partner has signed up for Pickup, the Merchant Partner agrees to be bound by the Terms for Pickup, as set out in the terms</Typography></Grid>
                    <Grid item xs={12} sm={12}> <Typography variant="subtitle2" gutterBottom>e. Merchant Partner Compensation: </Typography> </Grid>
                    <Grid item xs={12} sm={12}style={{fontSize:14, margin:"0px 0px 0px 18px"}}><Typography variant="string" gutterBottom variantMapping="body2" >As applicable and in accordance to the Terms</Typography></Grid>
                </Grid>


                {/* Payment Settlement Process */}

                <Grid container spacing={1} className={classes.headerTwo}>
                    <Grid item xs={12}><Typography gutterBottom style={{fontSize:14}}><b>Payment Settlement Process</b></Typography></Grid>
                </Grid>
                <Grid container spacing={1} className={classes.gridBC}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} style={{fontSize:14}}><b>Xirify shall transfer, the Order Value received, to the Merchant Partner after deduction of the following amounts, as applicable:</b></Grid>
                    <Grid item xs={12} sm={12} style={{margin: '20px 20px 8px 50px',fontSize:14}}><Typography variant="string" gutterBottom variantMapping="body2">1. Business Convenience Charges (BCC)</Typography></Grid>
                    <Grid item xs={12} sm={12} style={{margin: '0px 20px 8px 50px',fontSize:14}}><Typography variant="string" gutterBottom variantMapping="body2">2. Payment Mechanism Fee if applicable</Typography></Grid>
                    <Grid item xs={12} sm={12} style={{margin: '0px 20px 8px 50px',fontSize:14}}><Typography variant="string" gutterBottom >3. One Time Sign-up Fee if applicable</Typography></Grid>
                    <Grid item xs={12} sm={12} style={{margin: '0px 20px 8px 50px',fontSize:14}}><Typography variant="string" gutterBottom variantMapping="body2">4. Cancellation Fee applicable</Typography></Grid>
                    <Grid item xs={12} sm={12} style={{margin: '0px 20px 8px 50px',fontSize:14}}><Typography variant="string" gutterBottom variantMapping="body2">5. Any other amounts, charges that are due to Xirify</Typography></Grid>

                    <Grid item xs={12} sm={12} className={classes.paymentScheduleBG}>
                        <Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:12, padding: '15px 15px 15px 15px'}}>The Parties acknowledge and agree that after the deduction of the aforementioned amounts, Xirify shall remit the order value due to the Merchant Partner. <br /> <br /> Within 48hrs (excluding bank holidays), after allowing reasonable time for adjustments towards orders for which the customers have either refused to pay or have claimed a refund, as applicable (but in accordance with the guidelines prescribed by Reserve Bank of India for payment systems and nodal account). <br /> <br /> Xirify allows post-payments from Shoppers. For any fulfilled and undisputed delivery orders Xirify Systems Pvt.Ltd. takes full responsibility of collecting or compensating the merchant for any unpaid order by the 15th of next month. <br /> <br /> Xirify shall remit the order value due to the Merchant Partner within a period of 3 bank working days from the date the payment of the order value is received by Xirify. <br /> <br /> For the avoidance of doubt, for all order value received by Xirify on COD/Direct Payment method, the Merchant Partner must mark the order as paid on the App. Xirify will not take any liability for pickup orders if the shoppers confirms he/she has paid the merchant directly. The Merchant Partner shall thereafter transfer all amounts payable towards the applicable (a) BCC (b) Delivery Charges if collected form shoppers on Xirify partner delivery service (c) Cancellation Fee (f) Any other amounts, charges that are due to Xirify or the same shall be adjusted by Xirify against online paid orders.</Typography>
                    </Grid>
                </Grid>


                {/* Account Take Down */}

                <Grid container spacing={1} className={classes.headerTwo}>
                    <Grid item xs={12}><Typography gutterBottom style={{fontSize:14}}><b>Account Take Down</b></Typography></Grid>
                </Grid>
                <Grid container spacing={1} className={classes.gridBC}>
                    <Grid item xs={12}></Grid>
                    {/* <Grid item xs={12} sm={12} className={classes.additionaltermsBG}><Typography variant="string" gutterBottom style={{fontSize:14,margin: '20px 20px 10px 20px'}}><b>1.</b> If the account of the merchant is found to be inactive for a period of {businessAgreementObj.notifyMerchantATD ? ((businessAgreementObj.notifyMerchantATD.monthlyNotify ? businessAgreementObj.notifyMerchantATD.monthlyNotify : "NA") + '/' + (businessAgreementObj.notifyMerchantATD.weeklyNotify ? businessAgreementObj.notifyMerchantATD.weeklyNotify : "NA") + '/' + (businessAgreementObj.notifyMerchantATD.dailyNotify ? businessAgreementObj.notifyMerchantATD.dailyNotify : "NA") ) : 'NA/NA/NA'} (Months/Weeks/Days), same will be notified to the merchant. If the merchant failes to restart use of the account within {businessAgreementObj.restartMerchantATD ? ((businessAgreementObj.restartMerchantATD.monthlyRestart ? businessAgreementObj.restartMerchantATD.monthlyRestart : "NA") + '/' + (businessAgreementObj.restartMerchantATD.weeklyRestart ? businessAgreementObj.restartMerchantATD.weeklyRestart : "NA") + '/' + (businessAgreementObj.restartMerchantATD.dailyRestart ? businessAgreementObj.restartMerchantATD.dailyRestart : "NA") ) : 'NA/NA/NA'} (Months/Weeks/Days) days of the notification, the account of the merchant will be taken down from the application and the agreement betweeen the company and the merchant will stand dissolved</Typography></Grid> */}
                    <Grid item xs={12} sm={12} className={classes.additionaltermsBG}><Typography variant="string" gutterBottom style={{fontSize:14,margin: '20px 20px 10px 20px'}}><b>1.</b> If the account of the merchant is found to be inactive for a period of {businessAgreementObj.notifyMerchantATD ? businessAgreementObj.notifyMerchantATD.monthlyNotify ? businessAgreementObj.notifyMerchantATD.monthlyNotify + ' Month/s' : (businessAgreementObj.notifyMerchantATD.weeklyNotify ? businessAgreementObj.notifyMerchantATD.weeklyNotify + ' Week/s' : (businessAgreementObj.notifyMerchantATD.dailyNotify ? businessAgreementObj.notifyMerchantATD.dailyNotify + ' Day/s' : "NA")) : "NA"}, same will be notified to the merchant. If the merchant failes to restart use of the account within {businessAgreementObj.restartMerchantATD ? businessAgreementObj.restartMerchantATD.monthlyRestart ? businessAgreementObj.restartMerchantATD.monthlyRestart + ' Month/s' : (businessAgreementObj.restartMerchantATD.weeklyRestart ? businessAgreementObj.restartMerchantATD.weeklyRestart + ' Week/s' : (businessAgreementObj.restartMerchantATD.dailyRestart ? businessAgreementObj.restartMerchantATD.dailyRestart + ' Day/s' : "NA")) : "NA"} days of the notification, the account of the merchant will be taken down from the application and the agreement betweeen the company and the merchant will stand dissolved</Typography></Grid>
                    <Grid item xs={12} sm={12} className={classes.additionaltermsBG}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14,margin: '10px 20px 10px 20px'}}><b>2.</b> If the merchant is found to be non-responsive on orders of the users, the same procedure as mentioned in above subclause will be followed with deletion of merchant account being the final action.</Typography></Grid>
                    <Grid item xs={12} sm={12} className={classes.additionaltermsBG}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14,margin: '10px 20px 10px 20px'}}><b>3.</b> All the rights for the Merchant's account take down at any level are reserved with the Xirify. </Typography></Grid>
                </Grid>


                {/* Declaration */}

                <Grid container spacing={1} className={classes.headerTwo}>
                    <Grid item xs={12}><Typography gutterBottom style={{fontSize:14}}><b>Declaration</b></Typography></Grid>
                </Grid>
                <Grid container spacing={1} className={classes.gridBC}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} sm={12} style={{fontSize:14,margin: '20px 20px 20px 20px'}} ><Typography variant="string" gutterBottom >I/We affirm, confirm, and undertake that I/we have read and understood the Terms for usage of Online Ordering Services offered by Xirify Systems Pvt.Ltd. (“Xirify”) which are displayed on Xirify contract in Xirify for Business App maintained by Xirify.  <br /> <br /> Further, I/we accept and agree with the Terms for usage of Online Ordering Services of Xirify and all my/our rights, obligations and liabilities would be governed by the said Terms by my/our act of accepting customer orders received through Xirify Online Ordering Services.  <br /> <br /> I/We thereby agree to be subject to and comply with all the provisions of the Terms displayed on <a href="https://www.xirify.com/terms-policy" style={{color:"#15B5D8"}}>https://www.xirify.com/terms-policy</a> , as may be amended from time to time, which are incorporated by reference herein and deemed to be part of this form to the same extent as if such provisions had been set forth in full herein.  <br /> <br /> Hard copy of the Terms is also available upon request. The Terms and asset handover form should be read along with this form and shall be binding on the Merchant Partner. The Terms not defined herein shall have meaning to them as ascribed in the Terms. Except as expressly set- out herein, the Parties hereby agree that this form supersedes and replaces any and all previous agreements/forms between the Parties for the provision of Online Ordering Services.</Typography></Grid>
                </Grid>


                {/* Prohibited Services */}

                <Grid container spacing={1} className={classes.headerTwo}>
                    <Grid item xs={12}><Typography gutterBottom style={{fontSize:14}}><b>Prohibited Services</b></Typography></Grid>
                </Grid>
                <Grid container spacing={1} className={classes.gridBC}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} sm={12}style={{fontSize:14,margin: '20px 20px 20px 20px'}}><Typography variant="string" gutterBottom>a. Alcohol or alcoholic goods and services which includes pornography and other sexually suggestive materials (including literature, imagery and other media); escort or prostitution services.</Typography></Grid>
                    <Grid item xs={12} sm={12}style={{fontSize:14,margin: '10px 20px 20px 20px'}}><Typography variant="string" gutterBottom variantMapping="body2">b. Alcohol which includes alcohol or alcoholic beverages such as beer, liquor, wine or champagne.</Typography></Grid>
                    <Grid item xs={12} sm={12}style={{fontSize:14,margin: '10px 20px 20px 20px'}}><Typography variant="string" gutterBottom >c. Copyright unlocking devices which includes Modchips or other devices designed to circumvent copyright protection.</Typography></Grid>
                    <Grid item xs={12} sm={12}style={{fontSize:14,margin: '10px 20px 20px 20px'}}><Typography variant="string" gutterBottom variantMapping="body2">d. Illegal goods which includes materials, products or information promoting illegal goods or enabling illegal acts.</Typography></Grid>
                    <Grid item xs={12} sm={12}style={{fontSize:14,margin: '10px 20px 20px 20px'}}><Typography variant="string" gutterBottom variantMapping="body2">e. Miracle cures which includes unsubstantiated cures, remedies or other items marketed as quick health fixes.</Typography></Grid>
                    <Grid item xs={12} sm={12}style={{fontSize:14,margin: '10px 20px 20px 20px'}}><Typography variant="string" gutterBottom variantMapping="body2">f. Offensive goods or images which includes literature, products or other materials that: a) Defame or slander any person or groups of people based on race, ethnicity, national origin, religion, sex or other factors b) Encourage or incite violent acts c) Promote intolerance or hatred.</Typography></Grid>
                    <Grid item xs={12} sm={12}style={{fontSize:14,margin: '10px 20px 20px 20px'}}><Typography variant="string" gutterBottom variantMapping="body2">g. Any goods or services prohibited by Gov for Online Selling.</Typography></Grid>
                    <Grid item xs={12} sm={12}style={{fontSize:14,margin: '10px 20px 20px 20px'}}><Typography variant="string" gutterBottom variantMapping="body2">h. Copyrighted media which includes unauthorized copies of books, music, movies, images and other licensed or protected materials</Typography></Grid>
                </Grid>



                {/* CHARGEBACK/DISPUTES/FRAUDULENT TRANSACTION AND REFUND */}

                <Grid container spacing={1} className={classes.headerTwo}>
                    <Grid item xs={12}><Typography gutterBottom style={{fontSize:14}}><b>Chargeback/Disputes/Fradulent Transaction and Refund</b></Typography></Grid>
                </Grid>
                <Grid container spacing={1} className={classes.gridBC}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} sm={12}><Typography variant="subtitle2" gutterBottom style={{fontSize:14}}><b>Refund and Recourse</b></Typography></Grid>
                    <Grid item xs={12} sm={12} style={{fontSize:14,margin: '0px 20px 20px 20px'}}><Typography variant="string" gutterBottom variantMapping="body2">Events when the price of Customer Order is to be credited back in the Customer Bank Account:</Typography></Grid>
                    <Grid item xs={12} sm={12} className={classes.additionaltermsBG} ><Typography variant="body2" gutterBottom style={{fontSize:14,margin: '20px 20px 20px 20px'}} >1. Merchant for whatsoever reason expresses inability to deliver and/or perform the customer order fully or partially after capturing the transaction and before the delivery but post settlement.</Typography></Grid>


                    <Container className={classes.additionaltermsBG}>
                        <Grid container>
                            {/* <Grid item xs={12} sm={12} className={classes.additionaltermsBGSecond} style={{borderBlockEndStyle:"double"}}></Grid> */}
                            <Grid item xs={12} sm={12} style={{margin: '10px 0px 0px 0px', padding: '0px 0px 15px 20px'}}><Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:14,margin: '20px 20px 20px 20px'}}>2. Merchant accepts the customer claims/disputes for crediting his account back post delivery and settlement. Merchant must follow as below:</Typography></Grid>

                            <Grid item xs={12} sm={12} className={classes.chargebackBG}>
                                <span style={{width:"25px", height:"75px", margin:"0px", backgroundColor:"#DCDCDC",borderRadius:"12px 0px 0px 12px"}}></span>
                                <Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:12,margin: '20px 20px 20px 20px'}}>Merchant shall not process a Refund transaction and/or make a cash refund directly to Customer. 
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} className={classes.chargebackBG}>
                                <span style={{width:"30px", height:"75px", margin:"0px", backgroundColor:"#DCDCDC",borderRadius:"12px 0px 0px 12px"}}></span>
                                <Typography variant="string" gutterBottom variantMapping="body2" style={{fontSize:12,margin: '20px 20px 20px 20px'}}>In situations where any dispute or refund request is not mutually agreed between the Merchant and the Shopper. Xirify decision will be final.</Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>

            </Container>
        </div>
        {
            openHistoryModalFlag && openHistoryModalFlag === true ?
                <Button className={classes.btnHist}
                    // onClick={() => openHistoryModal(false)}
                    disabled
                    style={{opacity:openHistoryModalFlag?"0.5":"1", backgroundColor:openHistoryModalFlag?"#A3A3A3":"#15B5D8"}}
                > 
                    HISTORY 
                </Button>
            : 
                <Button className={classes.btn}
                    onClick={() => openHistoryModal(true)}
                > 
                    HISTORY 
                </Button>
        }

        {
            openHistoryModalFlag && openHistoryModalFlag === true ? 
                <div className={classes.modalHistory}>
                    <Container className={classes.headerOneHist}>
                        {/* <b>History</b> */}
                        <Grid item xs={10}>
                            <b>History</b>
                        </Grid>
                        <Grid item xs={2}>
                            <CloseIcon
                                className={classes.headGridTwo}
                                onClick={() => openHistoryModal(false)}
                            />
                        </Grid>
                    </Container> 
                    <Container className={classes.contentHist} justify="center">
                    <Grid container spacing={1}>

                    {/* <Stepper orientation="vertical" connector={<QontoConnector />}> */}
                        {props.updatehistory && props.updatehistory.length > 0 && props.updatehistory.map((step, index) => {
                            // console.log(index);
                            // let updateHistoryLen = props.updatehistory.length - 1;
                            return(
                                <div>
                                    <EllipseHistory style={{height:40,position:"relative",top:15,right:-10.5}}/>
                                    <span style={{fontSize:11,position:"relative",right:-19.5,top:-2}}>{moment(step.date).format('D MMMM YYYY')}</span>
                            {/* // <Step  */}
                            {/* //     // key={step._id} */}
                            {/* // > */}
                                {/* <StepLabel
                                            optional={<Typography className={classes.description} style={{color:"#15B5D8"}}>{step.date}</Typography>}
                                            // StepIconComponent={() => <QontoStepIcon color={step.color}/>}
                                >
                               
                                </StepLabel> */}
                                 {/* {
                                    step.history && step.history.length > 0 && step.history.map((history, i) => (
                                        
                                            <Typography className={classes.title} style={{fontSize:11}}>
                                                Edited by {history.firstName} {history.lastName} at {moment(history.addedOn).format('h:mm A')}
                                            </Typography>
                                        
                                    ))
                                } */}
                                {
                                    step.history && step.history.length > 0 && step.history.map((history, i) => {
                                        return(<div>
                                            
                                        <LineHistoryFlow style={{height:40,position:"relative",top:-7,right:-15.5}}/>
                                        {/* {
                                            props.updatehistory && props.updatehistory.length > 1 && updateHistoryLen === props.updatehistory.length -1 ? <LineHistoryFlow style={{height:40,position:"relative",top:36,right:-14,width:1}}/>
                                            : (
                                                step.history && step.history.length > 1 ? <LineHistoryFlow style={{height:10,position:"relative",top:0,right:-14,width:1}}/> : null
                                            )
                                        } */}

                                        <LineHistoryFlow style={{height:10,position:"relative",top:0,right:-14,width:1}}/>
                                        
                                        <GroupHistoryFlowLinesHori style={{height:40,position:"relative",top:12,right:-15.5}} 
                                        // label={`Edited by ${history.firstName} ${history.lastName} at ${moment(history.addedOn).format('h:mm A')}`} 
                                        />
                                        {/* <Typography style={{fontSize:11}}> */}
                                                <span style={{fontSize:11,position:"relative",right:-19.5,top:-6}}> {history.createdOn ? 'Created by ' : 'Edited by '} {history.firstName || "--"} {history.lastName || "--"} at {history.createdOn ? moment(history.createdOn).format('h:mm A') : moment(history.addedOn).format('h:mm A')}</span>
                                        {/* </Typography> */}
                                        
                                        </div>)
                                    })
                                }
                                {/* {
                                    index = index + 1 ? 
                                        <LineHistoryFlow style={{height:40,position:"relative",top:-7,right:-15.5}}/>
                                        : null
                                } */}
                                
                               

                                {/* <LineHistoryFlow style={{height:40,position:"relative",top:-15,right:-10.5}}/> */}
                                {/* <GroupHistoryFlowLinesHori style={{height:40,position:"relative",top:5,right:-10.5}} label={} /> */}
                                
                            {/* // </Step> */}
                            </div>
                            )

                            
                        })}
                    {/* </Stepper> */}

                        {/* <Grid item xs={12}></Grid>
                        <Grid item xs={3} style={{overflow:"auto",display:"flex"}}> */}
                            {/* <Typography className={classes.circle}></Typography>
                            <Typography className={classes.lines}></Typography>
                            <Typography className={classes.horlines}></Typography>
                            <Typography className={classes.circledoted}></Typography> */}
                            {/* <Typography><img src={historyFlowSectionOne} style={{width: 40, height: 43}}></img></Typography> */}
                            {/* <Typography><img src={historyFlowOne} style={{width: 40, height: 40}}></img></Typography>
                            <Typography><img src={historyFlowSectionDoted} style={{width: 45, height: 30, marginLeft: "1px", top:-61}}></img></Typography>
                            <Typography><img src={historyFlowDotedLineSection} style={{width: 10, height: 40, marginLeft: "4px"}}></img></Typography> */}

                            {/* <EllipseHistory/> */}
                            
                            
                            
                        {/* </Grid> */}
                        {/* <Grid item xs={9} style={{fontSize:12, }}>edited by aditi devanand lotliker at 11:30 am</Grid> */}
                        {/* <Grid item xs={10}></Grid> */}
                        {/* <Grid item xs={2}> */}
                            {/* <LineHistoryFlow style={{height:40,position:"relative",top:-15,right:-5.5}}/> */}
                            {/* <LineHistoryFlow style={{transform:'rotate(90deg'}}/> */}
                        {/* </Grid> */}
                        {/* <Grid item xs={10}></Grid>
                        <Grid item xs={2}>
                            <GroupHistoryFlowLinesHori/>
                        </Grid>
                        <Grid item xs={10}></Grid> */}
                        {/* <Grid className={classes.lines}><Typography display={"inline"}></Typography></Grid>
                        <Grid className={classes.horlines}><Typography display={"inline"}></Typography></Grid>
                        <Grid className={classes.asapdoted} ><Typography display={"inline"}></Typography></Grid> */}
                        
                    </Grid>
                    </Container>
                </div>
           
            : null
        }
        
        </div>
    )
}

const mapStateToProps = state => {
	return {
		business: state.business,
        user: state.auth.user,
        dashboard: state.dashboard
	}
};

export default connect(mapStateToProps, null)(withStyles(styles)(BusinessAgreementDetails));
// export default withStyles(styles)(BusinessAgreementDetails);