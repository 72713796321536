import React, { Component } from "react";
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import UploadCampaignPhoto from "./uploadCampaignPhoto";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import DeleteIcon from '@material-ui/icons/Delete';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormHelperText from "@material-ui/core/FormHelperText";
import MUIRichTextEditor from "mui-rte";
import { stateToHTML } from "draft-js-export-html";
import { checkPromoCode } from '../../actions/campaignActions';
import { unique } from 'underscore';
import { convertToRaw, ContentState, convertFromHTML } from 'draft-js'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
      marginTop: '100px',
      width: '100%',
      height: '100%',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: '0px 0px',
      display: 'flex',
      alignItems: 'center',
      height: '60px',
      marginBottom: '25px'
    },
    paper2: {
      padding: '0px 0px',
      display: 'flex',
      alignItems: 'center',
      height: '150px',
      marginBottom: '25px'
    },
    snackClose: {
      padding: theme.spacing(0.5),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px",
      width: "100%",
    },
    form: {
    //   width: '70%',
    //   height: '170%',
        // width: '100%',
        // height: '100%',
        marginTop: "300px",
        width: '90%',
        height: '200%',

    },
    header: {
    //   height: '9%',
    height: '7%',
      margin: '0px 0px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
  },
  headGridTwo: {
    float: 'right',
    marginRight: '2px'
  },
  content: {
    height: '60%',
    // margin: '10px 0px',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
      width: '100%',
      height:'100%'
  },

  gridElement: {
      margin: '10px 5px'
  },

  img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
  },
  icon: {
      color: 'white',
  },
  buttonGroup: {
    // "height": "40px",
    // "marginTop": "2px",
    // "width": "0px"
    "height": "39px",
    "marginTop": "8px",
    "width": "0px",
    // "marginRight":"1px"
    "marginLeft":"8px"
},

gridborder:{
    border: "1px solid black",
    borderRadius: "25px",
    alignSelf: 'center',
    paddingLeft:"10px",
    paddingRight:"10px",
    paddingTop:"10px",
    paddingBottom:"10px",
    marginRight:"10px",
   
},

headGridThree: {
    float: 'right',
    alignSelf: 'center',
    alignItems: "center",
    marginLeft: '8px',
    marginRight: '4px',
    fontSize: '20px'
  },

});

let dateCurrent = new Date();
let currentDate = new Date();
currentDate = moment(currentDate).format('YYYY-MM-DD');

let arrayList = [];
let totalCount;
let arrayL = [];
let arrayI = [];

function promoCodeAssign(data){
    if(arrayList.length === 0){
        arrayList.push(data);
        return arrayList
    }
    else if(arrayList.length === 1){
        if(arrayList[0].promocode === data.promocode){ return 1;}
        else{arrayList.push(data);return arrayList;}
    }
    else{
        for(let i = 0; i < arrayList.length; i++){
            if(data.promocode === arrayList[i].promocode){ return 1; }
        }
        arrayList.push(data);
        return arrayList
    }
}

function hasWhiteSpace(s) {
    if(/\s/g.test(s)){
        return true;
    }else{
        return false;
    }
}

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

class campaignEditForm extends Component {
    constructor(props) {
        super(props);

        const tempString=this?.props?.initialValues?.couponDescription || ""
        const contentHTML = convertFromHTML(tempString)
        const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
        const contentString = JSON.stringify(convertToRaw(state))

        this.state = {
            snackOpen: false,
            sucessMsg: null,
            value: 0,
            date: "",
            error: {},
            modalOpen: false, 
            rewardType: "",
            couponName: "",
            couponTotalAmountUser: "",
            numberofCoupons: "",
            valueperCoupon: "",
            minOrderValuePerCoupon: "",
            couponValidityDays: "",
            type: "",
            modalDeleteOpen: false,
            selectedPhoto: null,
            typePhoto: "", //imageUrl
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            campaignName: "",
            campaignTotalBudget: "",
            businessID: "",
            // couponDescription: "",
            couponDescription: props.initialValues?.couponDescription || "",
            termsAndConditions: "",
            campaignTimeDateRangeStart: "",
            campaignTimeDateRangeEnd: "",
            campaignImage: "",
            promoCode: "",
            promocode: [],
            initalPromocodes: [],
            submitFlag: false,
            // promoCode: [],
            richTextError: false,
            promoFlag: false,
            responsePromoCodeError: "",
            richTextInitialValue:contentString,
            sponsoredBy: "",
            sponsoredByError: false,
        }
    }

    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    };

    renderTextFieldCouponName({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
                inputProps={{ maxLength: 18 }}
            />
        );
    };

    onDeletePromoCode = async (code) => {
        if(code.num){
            arrayL = arrayList;
            arrayL.splice(arrayL.findIndex(a=>a.num===code.num),1);
            this.setState({promocode: arrayL});
        }
        else{
            arrayI = this.state.initalPromocodes;
            arrayI.splice(arrayI.findIndex(a=>a._id===code._id),1);
            this.setState({initalPromocodes: arrayI});
        }
        if(this.state.promocode.length === 0 && this.state.initalPromocodes.length === 0){
            this.setState({promoFlag: true})
        }
    };
    
    componentWillReceiveProps(nextProps) {
        const { change, couponTotalAmountUser, numberofCoupons } = nextProps
        if (this.props.couponTotalAmountUser !== couponTotalAmountUser || this.props.numberofCoupons !== numberofCoupons) {
          change('valueperCoupon', calculateValuePerCoupon(couponTotalAmountUser,numberofCoupons))
        }
    }

    onSubmit = formValues => {
        if((arrayList.length !== 0 || this.state.initalPromocodes.length !== 0)){
            if(this.state.sponsoredBy !== "" && this.state.couponDescription){
                // console.log("if condition of submit")
            formValues.campaignImage = this.state.campaignImage;
            formValues.couponDescription = this.state.couponDescription;
            this.props.editFlag === true ? formValues.editFlag = this.props.editFlag  : formValues.duplicateFlag = this.props.duplicateFlag;
            formValues.arrayList = arrayList;
            formValues.initalPromocodes = this.state.initalPromocodes;
            formValues.sponsoredBy = this.state.sponsoredBy;
            // formValues.busi = 
            // console.log("form values--->", formValues);
            this.props.showLoader();
            this.props.onSubmit(formValues);
            }else{
                // console.log("else condition of if condition ofsubmit");
                if (!this.state.sponsoredBy && this.state.sponsoredBy === "") {
                    this.setState({ sponsoredByError: true });
                }
                if (!this.state.couponDescription) {
                    this.setState({ richTextError: true });
                }
            }
        }
        else{
            // console.log("else condition of submit")
            if(arrayList.length === 0){
                this.setState({submitFlag:true})
            }
            // if (!this.state.couponDescription) {
            //     this.setState({ richTextError: true });
            // }
        }    
    }

    handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value
        });
        // if(event.target.name==="promoCode" && this.state.responsePromoCodeError !==""){
        //     this.setState({responsePromoCodeError:""})
        // }
        // if (!_.isEmpty(this.state.error)) {
        //         this.setState({ error: {} })
        //     }

        //     let response =  this.props.responsePromoCodeError 
        //     response= "";
        //     this.setState({submitFlag: false, promoFlag: false});
    };

    handleChangePromoCode = (event) => {
        // console.log("value---->",event.target.value.toUpperCase())
        
        this.setState({
          [event.target.name]: event.target.value.toUpperCase()
        });
        if(event.target.name==="promoCode" && this.state.responsePromoCodeError !==""){
            this.setState({responsePromoCodeError:""})
        }
        if (!_.isEmpty(this.state.error)) {
                this.setState({ error: {} })
            }
        
        let response =  this.props.responsePromoCodeError 
        response= "";
        this.setState({submitFlag: false, promoFlag: false});
        // console.log("name---->",event.target.name)
    }

    handleDeleteOpen = (photo) => {
        this.setState({ modalDeleteOpen: true });
        this.setState({ selectedPhoto: photo });
    }
    handleDeleteClose = () => {
        this.setState({ modalDeleteOpen: false });
    }

    componentDidMount(){
        this.setState({
            campaignImage:  this.props.campaignData.campaignImage ? this.props.campaignData.campaignImage : this.props.images,
            promocode: this.props.initialValues?.promo?[...this.props.initialValues.promo]:[],
            initalPromocodes: this.props.initialValues?.promo?[...this.props.initialValues.promo]:[],
            sponsoredBy: this.props.initialValues?.sponsoredBy?this.props.initialValues.sponsoredBy:""
        });
        arrayList = [];
    }

    updateCampaignPhotos = async (files, photoType) => {
        let data = new FormData();
        let folderPath = "Marketplace_Business";
        let temp = files[0].name.split(".");
        let fileName = temp[0] + Math.ceil(Math.random() * 999999) + "." + temp[1];
    
        data.append("file", files[0]);
        data.append("folder", folderPath);
        data.append(
            "public_id",
            `${this.props.campaignData._id}/${fileName}`
        );
        data.append(
            "upload_preset",
            this.props.envs.env.cloudinaryBusinessImagesUploadPreset
        );
        let res = await fetch(this.props.envs.env.cloudinaryUploadUrl, {
            method: "POST",
            body: data,
        });
        let file = await res.json();
        if (photoType === "CampaignPromoCode") {
        let campaignImage = file.secure_url;    
        this.setState({ campaignImage });
        this.setState({ isUploading: false, typePhoto: photoType });
        }
    };

    deleteCampaignPhoto = async (selectedPhoto, photoType) => {    
        if(!photoType) {photoType = 'CampaignPromoCode'}
        let path;
        if (photoType === "CampaignPromoCode") {
            this.props.campaignData.campaignImage = "";
            path = this.props.campaignData.campaignImage;
            this.setState({
            campaignImage: path,
            modalDeleteOpen: false 
            });
        }
    };

    handleRichTextData = editorState => {
        if (editorState.getCurrentContent().getPlainText()) {
            this.setState({
                couponDescription: stateToHTML(editorState.getCurrentContent()),
                richTextError: false
            })
        } else {
            this.setState({
                couponDescription: "",
                richTextError: true
            })
        }
    };

    handlePromoCodeCheck = async (promoCode) => {
        let whiteSpaceCheck = hasWhiteSpace(promoCode);
        if(whiteSpaceCheck === false){
            // let promocodearray = [];
            let payload = {
                promocode: promoCode
            }
            let answer = await this.props.checkPromoCode(payload);
            if(answer!==undefined && answer.status === 200){
                let arrayPromoCodes = promoCodeAssign(answer.data);
                if(arrayPromoCodes === 1){
                    this.setState({responsePromoCodeError: "Promo Code already exists", promoFlag: false});
                }
                else{
                    this.setState({promocode: arrayPromoCodes, responsePromoCodeError:"", promoFlag: false, promoCode: ""});
                }
            }
            else if(answer === 'Request failed with status code 400'){
                this.setState({responsePromoCodeError:"Promo Code Required", promoFlag: false});
            }
            else{
                this.setState({responsePromoCodeError: "Promo Code already exists", promoFlag: false});
            }
        }

        if(whiteSpaceCheck === true){
            this.setState({responsePromoCodeError: "Promo Code should not contain spaces", promoFlag: false});
        }
    };

    handleSponsoredByChange = async (event) => {
        this.setState({
            sponsoredBy: event.target.value,
            sponsoredByError: false
        });
    };

    render() {
        const { classes } = this.props;
        // console.log(this.state)
        
        // this.state.initalPromocodes = this.props.initialValues && this.props.initialValues.promo && this.props.initialValues.promo.length>0 ? this.props.initialValues.promo : [];

        let campaignStatusNew = (this.props.initialValues.campaignTimeDateRangeEnd > currentDate && this.props.initialValues.campaignStatus) 
        ? "live" 
        : (this.props.initialValues.campaignTimeDateRangeEnd < currentDate && this.props.initialValues.campaignStatus) 
           ? "completed" 
           : "offline";
        this.props.initialValues.rewardType = 'Coupons';

        dateCurrent = (this.props.editFlag) ? moment(this.props.initialValues.campaignTimeDateRangeStart).format('YYYY-MM-DD')
                                            : moment(dateCurrent).format('YYYY-MM-DD') ;
        
        // console.log("Props in edit campaign form-------->",this.props);
        // console.log("state in edit campaign form-------->",this.state);

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                
                <Grid container className={classes.header} spacing={3}>
                <Grid item xs={8}>
                    <WhiteTextTypography variant="h6" color="inherit" gutterBottom >
                        Add / Edit / Duplicate Campaign
                    </WhiteTextTypography>
                </Grid>
                <Grid item xs={4}>
                    <WhiteTextTypography variant="h6" color="inherit" gutterBottom >
                        <CloseIcon
                            className={classes.headGridTwo}
                            onClick={() => { this.props.onClose() }}
                        />
                    </WhiteTextTypography>
                </Grid>
                </Grid>

                <Container className={classes.content}>
                    <Grid container className={classes.grid} spacing={1} justify="center" alignItems="center" >
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Campaign Name *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Start Date *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                End Date *
                            </Typography>
                        </Grid>
                        <Grid></Grid>

                        {/* TextFields and Date */}
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="campaignName" label="Campaign Name *" variant="outlined"
                                component={this.renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field
								fullWidth
								margin="dense"
								name="campaignTimeDateRangeStart"
								type="date"
								variant="outlined"
                                InputLabelProps={{
									shrink: true,
								}}
                                component={this.renderTextField}
                                disabled = {campaignStatusNew !== 'offline'}
							/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field
								fullWidth
								margin="dense"
								name="campaignTimeDateRangeEnd"
								type="date"
								variant="outlined"
								// className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
								component={this.renderTextField}
							/>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Campaign Total Budget ({'\u20B9'})
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Reward Type *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Name *
                            </Typography>
                        </Grid>

                        {/* TextFields and drop down*/}
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="campaignTotalBudget" label="Campaign Total Budget *" variant="outlined" component={this.renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth  >
                                {/* <InputLabel id="demo-simple-select-label">Reward Type</InputLabel> */}
                                <Select
                                    // labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.props.initialValues.rewardType}
                                    // label="Reward Type"
                                    onChange={this.handleChange}
                                    component={this.renderTextField}
                                    name="rewardType" 
                                    variant="outlined"
                                    margin="dense"
                                >
                                    {/* <MenuItem value={''}></MenuItem> */}
                                    <MenuItem value={'Coupons'}>Coupons</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="couponName" label="Coupon Name *" variant="outlined" component={this.renderTextFieldCouponName } 
                            disabled={ this.props.editFlag ? true : false }
                            />
                        </Grid>


                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Total Amount/User ({'\u20B9'})
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Number of Coupons *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Value per Coupon
                            </Typography>
                        </Grid>

                        {/* TextFields and drop down*/}
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="couponTotalAmountUser" label="Coupon Total Amount/User" variant="outlined" component={this.renderTextField} disabled = {this.props.editFlag === true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="numberofCoupons" label="Number of Coupons *" variant="outlined" component={this.renderTextField} disabled = {this.props.editFlag === true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="valueperCoupon" label="Value per Coupon" variant="outlined" component={this.renderTextField} disabled
                            />
                        </Grid>


                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Min. Order Value (Per Coupon) *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Validity (Days) *
                            </Typography>
                        </Grid>
                        {
                            this.props.campaignCode === "promoCode" 
                            ? 
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Sponsored By *
                                    </Typography>
                                </Grid>
                            :
                                <Grid item xs={12} sm={4}> <Typography variant="subtitle2" gutterBottom>{/* Business ID */}</Typography> </Grid>
                        }

                        {/* TextFields and drop down*/}
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="minOrderValuePerCoupon" label="Min. Order Value (Per Coupon) *" variant="outlined" component={this.renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="couponValidityDays" label="Coupon Validity (Days) *" variant="outlined" component={this.renderTextField} inputProps={{ maxLength: 3 }}
                            />
                        </Grid>
                        {
                            this.props.campaignCode === "promoCode" 
                            ? 
                                <Grid item xs={12} sm={4}>
                                     {/* <Field fullWidth margin="dense" name="businessID" label="Business ID" variant="outlined" component={this.renderTextField}
                                     /> */}
                                    <FormControl fullWidth >
                                        {/* <InputLabel id="demo-simple-select-label">Reward Type</InputLabel> */}
                                        <Select
                                            labelId="SponsoredBy-label"
                                            id="demo-simple-select"
                                            // value={this.props.initialValues.sponsoredBy}
                                            value={this.state.sponsoredBy}
                                            onChange={this.handleSponsoredByChange}
                                            // component={this.renderTextField}
                                            name="sponsoredBy" 
                                            variant="outlined"
                                            margin="dense"
                                            error={this.state.sponsoredByError ? true : false}
                                            // helperText={this.state.sponsoredByError ? "Required" : null}
                                            disabled={this.props.editFlag && this.props.editFlag === true}
                                        >
                                            {/* <MenuItem value={''}></MenuItem> */}
                                            <MenuItem value={'Business'}>Business</MenuItem>
                                            <MenuItem value={'Xirify'}>Xirify</MenuItem>
                                        </Select>
                                        {(this.state.sponsoredByError)? (
                                            <FormHelperText style={{ color: "red" }}>
                                            Required
                                            </FormHelperText>
                                        ) :  null}
                                    </FormControl>
                                </Grid>
                            :
                                <Grid item xs={12} sm={4}> <Typography variant="subtitle2" gutterBottom></Typography> </Grid>
                        }

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                T&C *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="termsAndConditions" label="Terms And Conditions *" variant="outlined" component={this.renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>

                        <Grid item xs={12} sm={4}></Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Description *
                            </Typography>
                        </Grid>
                
                        {/* TextFields and drop down*/}
                        <Grid item xs={12}>
                            
                            <div>
                            {this.state.richTextError ? 
                            <FormHelperText style={{ color: "red" }}>
                                Coupon Description Required
                            </FormHelperText>
                            : null
                            }
                            </div>
                            <MUIRichTextEditor
                                name="couponDescription"
                                label="Coupon Description"
                                toolbarButtonSize="small"
                                defaultValue={this.state.richTextInitialValue}
                                value={this.state.richTextInitialValue}
                                inlineToolbar={true}
                                error={this.state.richTextError}
                                onChange={this.handleRichTextData}
                                // controls={[
                                //     'bold',
                                //     'italic',
                                //     'underline',
                                //     'strikethrough',
                                //     'link',
                                //     'numberList',
                                //     'bulletList',
                                //     'quote',
                                //     'media',
                                //     'undo',
                                //     'redo',
                                // ]}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}><Typography variant="subtitle2" gutterBottom></Typography></Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}></Grid>

                        {/* Upload Campaign Image button */}
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Image
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <UploadCampaignPhoto
                                uploadButtonName = "UPLOAD IMAGE"
                                updateCampaignPhotos = {this.updateCampaignPhotos}
                                photoType="CampaignPromoCode"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {(this.state.campaignImage !== "" ) ?
                                <GridList cols={7} cellHeight={80} className={classes.gridList}>
                                    <GridListTile
                                        className={classes.gridElement} 
                                            // key={images._id}
                                    >
                                        <a href={this.state.campaignImage} target="_blank" rel="noopener noreferrer">
                                            <img border="0" className={classes.img} src={this.state.campaignImage} alt="" />
                                        </a>
                                        <GridListTileBar  
                                            titlePosition="bottom"
                                            actionIcon={
                                                <IconButton className={classes.icon} 
                                                onClick={() => this.handleDeleteOpen(this.state.campaignImage)}
                                                >
                                                <DeleteIcon />
                                                </IconButton>
                                            }
                                            actionPosition="right"
                                        />
                                    </GridListTile>
                            ))
                                </GridList >
                            : null}
                        
                        </Grid>

                        <Grid item xs={12} sm={4}></Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                Promo Code *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {/* <Field style={{width:"48%"}} margin="dense" name="promoCode" label="Promo Code *" variant="outlined" 
                            component={this.renderTextField} onChange={this.handleChange} clearSearch={this.props.promocodes!=""}
                            error={
                                this.state.promoFlag == true ? "Promo Code Required" :
                            (this.state.responsePromoCodeError && this.state.responsePromoCodeError!=undefined && this.state.responsePromoCodeError!="")  ? this.state.responsePromoCodeError : ((!this.props.promocodes || (this.props.promocodes.length == 0 && this.state.submitFlag==true) || (this.state.promoFlag == true)) ? "Promo Code Required" : null)
                            }
                            />     */}
                            <TextField fullWidth margin="dense" name="promoCode" label="Promo Code *"  style={{ width: "48%" }} variant="outlined" value={this.state.promoCode} 
                            onChange={this.handleChangePromoCode} 
                            clearSearch={this.props.promocodes!==""}
                            error={
                                this.state.promoFlag === true ? "Promo Code Required" :
                            (this.state.responsePromoCodeError && this.state.responsePromoCodeError!==undefined && this.state.responsePromoCodeError!=="")  ? this.state.responsePromoCodeError : ((!this.props.promocodes || (this.props.promocodes.length === 0 && this.state.submitFlag===true) || (this.state.promoFlag === true)) ? "Promo Code Required" : null)
                            }
                            /> 
							<ButtonGroup className={classes.buttonGroup} size="small" aria-label="small outlined primary button group" fullWidth >
								<Button style={{fontWeight:'bold', backgroundColor:'#07c6db', color:'#ffffff'}} variant="contained" 
                                // name={index.index} //old flow code
                                onClick={() => this.handlePromoCodeCheck(this.state.promoCode)}
                                >+</Button>
							</ButtonGroup>
                            <div>
                            <FormHelperText style={{ color: "red" }}>
                                {/* below is old flow */}
                            {/* {(this.props.responsePromoCodeError && this.props.responsePromoCodeError!=undefined && this.props.responsePromoCodeError!="")  ? this.props.responsePromoCodeError : ((!this.props.promocodes || (this.props.promocodes.length == 0 && this.state.submitFlag==true)) ? "Promo Code Required" : null)} */}

                            {
                                this.state.promoFlag === true ? "Promo Code Required" :
                            (this.state.responsePromoCodeError && this.state.responsePromoCodeError!==undefined && this.state.responsePromoCodeError!=="")  ? this.state.responsePromoCodeError : ((!this.props.promocodes || (this.props.promocodes.length === 0 && this.state.submitFlag===true) || (this.state.promoFlag === true)) ? "Promo Code Required" : null)
                            }
                            </FormHelperText>
                            
                            </div>
						</Grid>
                        
                        {this.state.initalPromocodes && this.state.initalPromocodes !== "" && this.state.initalPromocodes.length !== 0 && this.state.initalPromocodes!==undefined  ? 
                            this.state.initalPromocodes.map((promocode) => { 
                                return(
                                    <Grid className={classes.gridborder} key={promocode.promocode}>
                                        {promocode.promocode}
                                    <CloseIcon
                                    className={classes.headGridThree}
                                    onClick={() => { this.onDeletePromoCode(promocode) }}                        
                                />
                                </Grid>                           
                                )
                            })
                        : null }
                        {arrayList && arrayList !== "" && arrayList.length !== 0 && arrayList!==undefined ? 
                            arrayList.map((promocode) => {
                                return(
                                    <Grid className={classes.gridborder} key={promocode.promocode}>
                                        {promocode.promocode}
                                    <CloseIcon
                                    className={classes.headGridThree}
                                    onClick={() => { this.onDeletePromoCode(promocode) }}
                                />
                                </Grid>
                                )
                            }
                            )
                        : null }

                        <Grid item xs={12}>
                            <Divider
                                color="textSecondary"
                                style={{ marginBottom: "5px", marginTop: "10px" }}
                                display="block"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                style={{ margin: "5px" }}
                                fullWidth
                                variant="contained"
                                onClick={() => { this.props.onClose() }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                fullWidth
                                style={{ margin: "5px" }}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                ADD / SAVE
                            </Button>
                        </Grid>
                    </Grid>
                </Container>

                <Dialog open={this.state.modalDeleteOpen} onClose={this.handleDeleteClose}>
                <DialogTitle>Delete Photo</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    Do you want to delete this photo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
                    <Button onClick={() => this.deleteCampaignPhoto(this.state.selectedPhoto, this.state.typePhoto)} color="primary" autoFocus> Yes </Button>
                </DialogActions>
                </Dialog>
            </form>
        );
    }
}

const validateValuePerCoupon = (couponTotalAmountUser,valueperCoupon) => {
    if(couponTotalAmountUser && valueperCoupon){
        let numberOfCoupon = parseInt(couponTotalAmountUser)/parseInt(valueperCoupon);
        if(numberOfCoupon % 1===0) {
            return ""
        } else{
            return "Value per coupon should be a whole number"}
    }
}

const calculateValuePerCoupon=(couponTotalAmountUser,valueperCoupon)=>{
    if(couponTotalAmountUser && valueperCoupon){
        let numberOfCoupon = parseInt(couponTotalAmountUser)/parseInt(valueperCoupon);
        if(numberOfCoupon % 1===0) {
            return numberOfCoupon
        } else return ""
    } else {
        return ""
    }
}

const validate = values => {
    // console.log("Validate values : ", values);
    const errors = {}
    const requiredFields = [
        'campaignName',
        'campaignTotalBudget',
        'rewardType',
        'couponName',
        'couponTotalAmountUser',
        'numberofCoupons',
        'valueperCoupon',
        'minOrderValuePerCoupon',
        'couponValidityDays',
        'couponDescription',
        'termsAndConditions',
        'promocode',
        'campaignTimeDateRangeStart',
        'campaignTimeDateRangeEnd',
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });
    
    if (values.campaignTotalBudget) {
        if (values.campaignTotalBudget < 1 || !/^[0-9]+$/i.test(values.campaignTotalBudget) ) {
            errors.campaignTotalBudget = 'Invalid Amount'
        }
    }
    if (values.campaignTimeDateRangeStart) {
        if (values.campaignTimeDateRangeStart < dateCurrent ){//&&  !values.editFlag && !values.duplicateFlag) {
            errors.campaignTimeDateRangeStart = 'Campaign Start date cannot be less than current date'
        }
    }
    if (values.campaignTimeDateRangeEnd) {
        if (values.campaignTimeDateRangeEnd < dateCurrent || values.campaignTimeDateRangeEnd < values.campaignTimeDateRangeStart ) {
            errors.campaignTimeDateRangeEnd = 'Campaign End date cannot be less than current date or start date'        }
    }
    if (values.couponTotalAmountUser) {
        if (values.couponTotalAmountUser < 1 || !/^[0-9]+$/i.test(values.couponTotalAmountUser) ) {
            errors.couponTotalAmountUser = 'Invalid Amount'
        }
    }
    if (values.numberofCoupons) {
        if (values.numberofCoupons < 1 || !/^[0-9]+$/i.test(values.numberofCoupons) ) {
            errors.numberofCoupons = 'Invalid Number of Coupons'
        }
    }
    if (values.valueperCoupon) {
        if (values.valueperCoupon < 0 || !/^[0-9]+$/i.test(values.valueperCoupon) ) {
            errors.valueperCoupon = 'Invalid Amount'
        }
        if (values.minOrderValuePerCoupon && parseInt(values.valueperCoupon) >= parseInt(values.minOrderValuePerCoupon)) {
            errors.minOrderValuePerCoupon = 'Min. Order Value cannot be less than or equal to Coupon Amount'
        }
    }
    errors.valueperCoupon=validateValuePerCoupon(values.couponTotalAmountUser,values.numberofCoupons);
    
    if(!errors.numberofCoupons && !values.valueperCoupon)
        errors.numberofCoupons=validateValuePerCoupon(values.couponTotalAmountUser,values.numberofCoupons);
    
    if(!errors.couponTotalAmountUser && !values.valueperCoupon)
        errors.couponTotalAmountUser=validateValuePerCoupon(values.couponTotalAmountUser,values.numberofCoupons);
    
    if (values.minOrderValuePerCoupon) {
        if (values.minOrderValuePerCoupon < 0 || !/^[0-9]+$/i.test(values.minOrderValuePerCoupon) ) {
            errors.minOrderValuePerCoupon = 'Invalid Amount'
        }
        if (values.valueperCoupon && parseInt(values.valueperCoupon) >= parseInt(values.minOrderValuePerCoupon)) {
            errors.minOrderValuePerCoupon = 'Min. Order Value cannot be less than or equal to Coupon Amount'
        }
    }
    if (values.couponValidityDays) {
        if (values.couponValidityDays < 0 || !/^[0-9]+$/i.test(values.couponValidityDays) ) {
            errors.couponValidityDays = 'Invalid Number of Days'
        }
    }

    if(values.couponName) {
        if (values.duplicateFlag && values.couponName.trim().length > 18) {
            errors.couponName = 'Coupon Name must be at least 18 characters'
        }
        if (values.couponName.trim().length === 0) {
            errors.couponName = 'Required'
        }
    }
    // console.log("erros in edit---->",errors)
    return errors
}

const makeMapStateToProps = () => {
    const mapStateToProps = (state, props) => {
        let numberofCoupons = state.form?.CampaignAddForm?.values?.numberofCoupons 
        let couponTotalAmountUser = state.form?.CampaignAddForm?.values?.couponTotalAmountUser
        if (state.form?.CampaignAddForm?.values) 
            state.form.CampaignAddForm.values.duplicateFlag = props.duplicateFlag 
      return {
        campaign: state.campaign,
        numberofCoupons: numberofCoupons,
        couponTotalAmountUser:couponTotalAmountUser,
      }
    }
    return mapStateToProps
  }

  campaignEditForm = reduxForm(
    {
        form: 'CampaignAddForm',
        validate
    },
    makeMapStateToProps
  )(withStyles(styles)(campaignEditForm))

  campaignEditForm= connect(makeMapStateToProps, {checkPromoCode})(campaignEditForm)

  export default campaignEditForm
