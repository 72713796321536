import React from 'react'
import { Field, reduxForm } from 'redux-form'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { editCategory, getCategoryTree, getAllCategoryType, editFoodType, getAllCategories } from "../../actions/categoriesActions";
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import UploadIcon from './UploadIcon';
import Avatar from '@material-ui/core/Avatar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const styles = theme => ({
  form: {
    paddingBottom: '20px',
    width: '40%',
    height: '60%',
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    height: '20%',
    margin: '0px 0px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  headGridTwoButton: {
    float: 'right',
  },
  content: {
    height: '70%',
    margin: '10px 0px',
    overflow: 'auto',
  },
  footer: {
    height: '10%',
    margin: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridItem: {
    margin: '0px 20px',
  },
  avatarGrey: {
    width: theme.spacing(10),
    height: theme.spacing(6),
  },
  avatarWhite: {
    width: theme.spacing(10),
    height: theme.spacing(6),
    backgroundColor: theme.palette.primary.main,
  },
});

class CategoryForm extends React.Component {
  constructor() {
    super();
    this.state = {
      foodType: false,
      categoryType: ""
    };
  }
  renderInput = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {

    return (

      <TextField
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        autoComplete="off"
        {...input}
        {...custom}
      />
    )
  }

  onSubmit = formValues => {
    let payload = {}
    if (this.props.hasOwnProperty("editForm")) {
      formValues._id = this.props.initialValues._id;
      formValues.changedCategoryType = this.state.categoryType
    }
    this.props.onSubmit(formValues);
    if (this.props.categories.selectedCategory.foodType !== this.state.foodType) {
      payload = {
        _id: this.props.categories.selectedCategory._id,
        foodType: this.state.foodType,
        name: this.props.categories.selectedCategory.name
      }
      this.props.editFoodType(payload);
    }
  }

  handleSwitchChange = (event, data) => {
    this.setState({
      foodType: event.target.checked
    })
  }
  handleChangeCategoryType = async (event) => {
    this.setState({
      categoryType: event.target.value
    });
  }
  componentDidMount() {
    this.setState({
      foodType: this.props.categories.selectedCategory.foodType
    });
    this.props.getAllCategories();
  }
  render() {
    const { classes } = this.props;
    let formTitle = null;
    let option = {};
    let catType = null;
    let formContent = null;
    let iconGrey;
    let iconWhite;
    if (this.props.hasOwnProperty("editForm")) {
      option = { type: "edit" };
      catType = this.props.categories.selectedCategory.type;
      iconGrey = "2.0/" + this.props.categories.selectedCategory.icon.grey + ".svg";
      iconWhite = "2.0/" + this.props.categories.selectedCategory.icon.white + ".svg";
      formTitle = <Typography variant="h5" gutterBottom>
        EDIT {this.props.categories.selectedCategory.name}
      </Typography>
    } else if (this.props.hasOwnProperty("addForm")) {
      option = { type: "add" };
      catType = this.props.type;
      formTitle = <Typography variant="h5" gutterBottom>
        Add {this.props.type}
      </Typography>
    }

    if (this.props.hasOwnProperty("editForm")) {
      formContent = <Grid container className={classes.content}>
        <Grid container>
          <Grid item xs={12} style={{ padding: '20px 20px' }}>
            <Field name="name" component={this.renderInput} label={catType + " name"}
              fullWidth variant="outlined" margin="normal" />
          </Grid>
        </Grid>
        {this.props.user.role === "admin" && (catType === "category" || catType === "subcategory") ?
          <Grid container spacing={3} style={{ padding: '20px 32px' }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" gutterBottom>Enable Food Type</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={this.state.foodType}
                    onChange={(e) => this.handleSwitchChange(e, this.props.categories.selectedCategory)}
                    color="primary"
                  />
                }
                style={{ fontSize: "10px", color: this.state.foodType ? 'green' : 'red' }}
                label={this.state.foodType ? 'Active' : 'Inactive'}
              />
            </Grid>
          </Grid> : null
        }
        {this.props.user.role === "admin" && (catType === "category") ?
          <Grid container spacing={3} style={{ padding: '20px 32px' }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" gutterBottom>Change Category Type</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {this.props.categories.categoryTypes && this.props.categories.categoryTypes.length > 0 ?
                <FormControl variant="outlined" fullWidth className={classes.formControl} >
                  <Select
                    defaultValue={this.props.categories.selectedCategory.parentId}
                    name="categoryType"
                    onChange={this.handleChangeCategoryType}
                    MenuProps={MenuProps}
                    className={classes.select}
                  >
                    <MenuItem value="" disabled>
                      Change Category Type
                    </MenuItem>
                    {this.props.categories.categoryTypes && this.props.categories.categoryTypes.length > 0 && this.props.categories.categoryTypes.map((categoryType) =>
                      <MenuItem key={categoryType._id} value={categoryType._id} style={{ minHeight: "28px", height: "28px" }}>
                        <ListItemText className={classes.listItemText} primary={categoryType.name} />
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
                : null}
            </Grid>
          </Grid> : null
        }
        <Grid container>
          <Grid item xs={6} style={{ padding: '20px 20px' }}>
            <Grid container direction="row">
              <Typography variant="subtitle1" gutterBottom>Current Grey Icon</Typography>
            </Grid>
            <Grid container direction="row" style={{ marginTop: '10px' }}>
              <Avatar imgProps={{ style: { "object-fit": "contain" } }} variant="square"
                alt={this.props.categories.selectedCategory.name} className={classes.avatarGrey} src={this.props.categories.selectedCategory.icon.grey} />
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ padding: '20px 20px' }}>
            <Field type="file" name="icon.greyImgIcon" label="Grey Icon" component={UploadIcon}
              fullWidth variant="outlined" margin="normal" props={option} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} style={{ padding: '20px 20px' }}>
            <Grid container direction="row">
              <Typography variant="subtitle1" gutterBottom>Current White Icon</Typography>
            </Grid>
            <Grid container direction="row" style={{ marginTop: '10px' }}>
              <Avatar imgProps={{ style: { "object-fit": "contain" } }} variant="square"
                alt={this.props.categories.selectedCategory.name} className={classes.avatarWhite} src={this.props.categories.selectedCategory.icon.white} />
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ padding: '20px 20px' }}>
            <Field type="file" name="icon.whiteImgIcon" label="White Icon" component={UploadIcon}
              fullWidth variant="outlined" margin="normal" props={option} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} style={{ padding: '20px 20px' }}>
            <Grid container direction="row">
              <Typography variant="subtitle1" gutterBottom>Current Coloured Icon</Typography>
            </Grid>
            <Grid container direction="row" style={{ marginTop: '10px' }}>
              <Avatar imgProps={{ style: { "object-fit": "contain" } }} variant="square"
                alt={this.props.categories.selectedCategory.name} className={classes.avatarWhite} src={this.props.categories.selectedCategory.icon.coloured} />
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ padding: '20px 20px' }}>
            <Field type="file" name="icon.colouredImgIcon" label="Coloured Icon" component={UploadIcon}
              fullWidth variant="outlined" margin="normal" props={option} />
          </Grid>
        </Grid>
      </Grid>
    } else if (this.props.hasOwnProperty("addForm")) {
      formContent = <Grid container className={classes.content}>
        <Grid item xs={12} className={classes.gridItem}>
          <Field name="name" component={this.renderInput} label={catType + " name"}
            fullWidth variant="outlined" margin="normal" />
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Field type="file" name="icon.greyImgIcon" label="Grey Icon" component={UploadIcon}
            fullWidth variant="outlined" margin="normal" props={option} />
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Field type="file" name="icon.whiteImgIcon" label="White Icon" component={UploadIcon}
            fullWidth variant="outlined" margin="normal" props={option} />
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Field type="file" name="icon.colouredImgIcon" label="Coloured Icon" component={UploadIcon}
            fullWidth variant="outlined" margin="normal" props={option} />
        </Grid>
      </Grid>
    }

    return (
      <form className={classes.form} onSubmit={this.props.handleSubmit(this.onSubmit)}>
        {/* Header of the FORM */}
        <Grid container className={classes.header} spacing={3}>
          <Grid item xs={11}>
            {formTitle}
          </Grid>
          <Grid item xs={1} >
            <CloseIcon className={classes.headGridTwoButton} onClick={() => { this.props.onClose() }} />
          </Grid>
        </Grid>
        {/* Content of the FORM */}

        {formContent}

        {/* Button for  FORM */}
        <Container className={classes.footer}>
          <div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              ///style={{ padding: "20px" }}
              margin="normal"
              color="primary">
              Confirm
            </Button>
          </div>
        </Container>
      </form>

    )
  }
}

const validate = formValues => {
  const errors = {}

  if (!formValues.name) {
    errors.name = 'You must enter a name for the category'
  }

  if (!formValues.icon) {
    errors.icon = 'You must enter a icon for the category'
  }

  return errors
}

const mapStateToProps = state => {
  return {
    categories: state.categories,
    user: state.auth.user,
  }
};

CategoryForm = connect(
  mapStateToProps,
  {
    editCategory,
    getAllCategoryType,
    getCategoryTree,
    editFoodType,
    getAllCategories
  }
)(CategoryForm);

export default reduxForm({ form: 'streamForm', validate })(withStyles(styles)(CategoryForm));