import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Paper } from "@material-ui/core";
import {
    getHeadSubheadHierarchy
} from '../../../actions/businessActions';
const styles = (theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
        width: '100%'
    },
    heading: {
        fontSize: "17px",
        fontWeight: 500
    },
    paper: {
        marginTop: theme.spacing(2),
        width: "100%",
        marginBottom: theme.spacing(2),
    }
});

class ManageHeadAndSubHead extends Component {
    constructor() {
        super();
        this.state = {
            head: "",
            subhead: "",
            initialData:[]
        };
    }
    handleFilterChange = (event) => {

        this.setState({ [event.target.name]: event.target.value });

    }
    fetchOrdersByQuery = async() => {
        if(this.state.head==="" && this.state.subhead==="") this.resetFilter()
        else{
            let subhead = [];
        let subheadIds = []
        let headArray=[]
        if(this.state.initialData) headArray=this.state.initialData
        else headArray= this.props.data
        headArray.map((head) => {
            subhead.push(head.children.find(subhead => subhead.name.includes(this.state.subhead)));
        })
        subhead.forEach(element => {
            if (element && element._id) {
                subheadIds.push(element._id)
            }
        });

        let data = {
            head: this.state.head,
            subhead: subheadIds
        }
        await this.props.headSubheadFilter(data)
        this.props.setSearchedSubhead(this.state.subhead);
        this.props.setSearchedHead(this.state.head);
        }
    }
    resetFilter = async () => {
        this.setState({
            head: "",
            subhead: ""
        })
        await this.props.clearData();
        await this.props.getHeadSubheads();
        this.props.setSearchedSubhead("")
    }
    componentDidMount() {
        this.setState({initialData:this.props.data});
        this.fetchOrdersByQuery();
        // this.resetFilter();
    }

    render() {
        const { classes } = this.props;
        
        return (
            <Paper className={classes.paper}>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography className={classes.heading}>Search Heads & Sub-Heads</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                        <Grid container spacing={3} style={{ alignItems: "center" }}>
                            <Grid item xs={4}>
                                <TextField fullWidth margin="dense" name="head" label="Head" value={this.state.head} variant="outlined" onChange={this.handleFilterChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth margin="dense" name="subhead" label="Sub-Head" value={this.state.subhead} variant="outlined" onChange={this.handleFilterChange} />
                            </Grid>
                            <Grid item xs={2} >
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    margin="normal"
                                    onClick={this.resetFilter}> Reset
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    margin="normal"
                                    color="primary"
                                    onClick={this.fetchOrdersByQuery}>Search
                                </Button>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Paper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dashboard: state.dashboard,
        business: state.business,
        user: state.auth.user,
        admin: state.admin,
        categories: state.categories,
        env: state.auth.env
    };
};
export default connect(mapStateToProps, { getHeadSubheadHierarchy })(withStyles(styles)(ManageHeadAndSubHead));
