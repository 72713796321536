import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import { changeHeaderName } from '../../actions/dashboardActions';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CampaignsCouponSettlementTable from './CampaignsCouponSettlementTable';
import {requestCouponSettlement, getCampaignSummaryReport, getCampaignSummaryReportExcel} from '../../actions/campaignActions';


const styles = theme => ({
	container: {
		width: '100%',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	buttonContainer: {
		width: '100%',
		paddingTop: '10px'
	},
	content: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
	},
	appBarSpacer: theme.mixins.toolbar,
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	formControl: {
		minWidth: 225,
		maxWidth: 300,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	heading: {
		fontSize: "14px",
		fontWeight: 500
	},
	snackClose: {
		padding: theme.spacing(0.5),
	},
    containerin: {
        width: '90%',
        height: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(5),
        paddingRight: theme.spacing(0),
      },
      modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "150px",
        width: "100%",
      },
      tableRow: {
        cursor: 'pointer'
    },
});


class CampaignsCouponSettlement extends Component {
    constructor() {
		super();
		this.state = JSON.parse(window.localStorage.getItem('businessCouponSettlementDetails')) ||{
			modalOpen: false,
			snackOpen: false,
			sucessMsg: null,
			errorMsg: null,
			regionSearchvalue: "",
			clearSearch: false,
			order: -1,
			orderBy: 'name',
			page: 0,
			rowsPerPage: 10,
			editMode: false,
			regionToEdit: null,
            campaignId: "",
            campaignName: "",
            businessId: "",
            campaignTimeDateRangeEnd: "",
            campaignTimeDateRangeStart: "",
            campaignStatus: "",
            showDownloadIcon: false,
            headers: [
                { key: 'businessId', label: 'Business ID' },
                { key: 'businessName', label: 'Business Name' },
                { key: 'honouredCnt', label: 'No Of Coupons Honoured' },
                { key: 'honouredAmount', label: 'Total Amt Of Coupons Honoured' },
                { key: 'settledCnt', label: 'Settled Coupons Count'},
                { key: 'settledAmount', label: 'Settled Coupons Amt'},
                { key: 'notSettledCnt', label: 'Not Settled Coupons'},
                { key: 'notSettledAmt', label: 'No tSettled Coupons Amt'},
                { key: 'campId', label: 'Campaign ID'},
                { key: 'campaignName', label: 'Campaign Name' },
            ],
            businessName: "",
            business: "",
            rows:[],
            preparexportToExcel: false,
		}; 
	}

    componentDidMount() {
        this.props.changeHeaderName("Business Coupon Settlement Report");
        this.resetFilter()
        window.addEventListener("beforeunload", () => localStorage.removeItem('businessCouponSettlementDetails'));
    };

    componentDidUpdate(prevProps){
        if(prevProps.campaign.getCouponSummaryReport !== this.props.campaign.getCouponSummaryReport){
        let rows = this.props.campaign.getCouponSummaryReport.data && this.props.campaign.getCouponSummaryReport.data.length>0 && 
        this.props.campaign.getCouponSummaryReport.data.map((item)=>{
            item.businessID=item.businessId;
            item.settledCoupons=item.settledCnt;
            item.settledCouponsAmt=item.settledAmount;
            item.notSettledCoupons=item.notSettledCnt;
            item.notSettledCouponsAmt=item.notSettledAmt;
            return item
        })
        this.setState({rows:rows})   
    }

    }
    fetchFilter = async (isSearchButtonClick) => {
        this.props.requestCouponSettlement();
        let page= this.state.page
        if(isSearchButtonClick){
            this.setState({page:0}) 
            page=0
        }
        let payload = {
            skip: page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            businessName: this.state.businessName.trim(),
        }
        this.props.getCampaignSummaryReport(payload);
        this.setState({showDownloadIcon: false });
        if (this.state.preparexportToExcel) {
            await this.setState({ preparexportToExcel: false });
        }

        localStorage.setItem("businessCouponSettlementDetails", JSON.stringify(this.state));
    };

    resetFilter = async()=>{
        this.props.requestCouponSettlement();
        this.setState({businessName:"",page:0})
        let payload = {
            skip: 0,
            limit: this.state.rowsPerPage,
            businessName: "",
        }
        this.props.getCampaignSummaryReport(payload);
        
    }

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetchFilter());
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage }, () => this.fetchFilter());
    }

    fetchCampaignSummaryExcelFile = async () => {
        this.props.requestCouponSettlement();
        let payload = {
            businessName: this.state.businessName.trim(),
        }
        await this.props.getCampaignSummaryReportExcel(payload);
        await this.setState({ preparexportToExcel: true });
    }

    render() { 
        const { classes } = this.props;


        return(
           <div className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <Container className={classes.searchPaper}>
                        <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography style={{ fontSize:'17px'}}>Search/Filter</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                                    <Grid container spacing={1}>
                                    <Grid item xs={3} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Business ID / Name
                                        </Typography>
                                        <TextField fullWidth margin="dense" name="businessName" label="Business ID / Name" value={this.state.businessName} variant="outlined" onChange={this.handleFilterChange} />
                                    </Grid>                                    
                                    <Grid item xs={9}></Grid>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={2}>
                                        <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                        >
                                        Reset
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        onClick={()=>this.fetchFilter(true)}
                                        >
                                        Search
                                        </Button>
                                    </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Container>

                     <Container className={classes.container}>
                        <CampaignsCouponSettlementTable 
                            data={this.state.rows}
                            totalCount={this.props.campaign.getCouponSummaryReport.totalCount}
                            exportToExcel={this.props.campaign.getCouponSummaryReportExcel}
                            page={this.state.page}
                            rowsPerPage={this.state.rowsPerPage}
                            setPage={this.setPage}
                            setRowsPerPage={this.setRowsPerPage}
                            history={this.props.history}
                            fetchCampaignSummaryExcelFile={this.fetchCampaignSummaryExcelFile}
                            showDownloadIcon={this.state.preparexportToExcel}
                        />

                    </Container>
                </Container>
                <Backdrop className={classes.backdrop} open={this.props.campaign.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

const mapStateToProps = state => {
	return {
		coupon: state.coupon,
        campaign: state.campaign,
        business: state.business,
        serviceRequest: state.serviceRequest,
        user: state.auth.user,
        categories: state.categories.categoriesByType
	}
};

export default connect(mapStateToProps, {requestCouponSettlement, changeHeaderName,getCampaignSummaryReport,getCampaignSummaryReportExcel })(withStyles(styles)(CampaignsCouponSettlement));
