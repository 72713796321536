import axios from "axios";

export const cuisineList = async () => {
    try {
        const response = await axios.get("/api/cuisines");
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
};

export const xirifyEnv = async () => {
    try {
        const response = await axios.get("api/xirify/env");
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
};

export const deleteCuisine = async (payload) => {
    // console.log("payload", payload);
    try {
        const response = await axios.delete(`/api/cuisines/deleteCuisine/${payload}`);
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
}

export const updateCuisine = async (payload) => {
    // console.log("payload", payload);
    try {
        const response = await axios.patch(`/api/cuisines/updateCuisine/${payload._id}`, payload);
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
}

export const addCuisine = async (payload) => {
    // console.log("payload", payload);
    try {
        const response = await axios.post(`/api/cuisines/createCuisine`, payload);
        // console.log("response", response);
        return response;
    } catch (err) {
        console.log(err);
    }
}
