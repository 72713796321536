import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
    form: {
        width: '100%',
        height: '100%',
    },
    header: {
        height: '15%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '60%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    }
});

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

class EditRegionForm extends React.Component {
    constructor() {
        super();
        this.state = {
            referAndEarn: false,
            referralAmountError: null,
            referralMinOrderError: null
        }
    }

    handleReferAndEarnSwitchChange = (event) => {
        this.setState({
            referAndEarn: event.target.checked
        })
    };

    componentDidMount() {
        if (this.props.initialValues && this.props.initialValues.tempReferAndEarn) {
            this.setState({
                referAndEarn: this.props.initialValues.tempReferAndEarn
            })
        } else {
            this.setState({
                referAndEarn: this.props.initialValues.referAndEarn
            })
        }
    }

    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    }

    onSubmit = formValues => {
        formValues._id = this.props.initialValues._id;
        if (this.state.referAndEarn) {
            if (formValues.referralAmount && (!isNaN(formValues.referralAmount) || /^[0-9]+$/.test(Number(formValues.referralAmount)))) {
                formValues.referAndEarn = this.state.referAndEarn;
                this.props.onSubmit(formValues);
                this.setState({
                    referralAmountError: null
                })
            } else if (formValues.referralAmount && (isNaN(formValues.referralAmount) || !/^[0-9]+$/.test(Number(formValues.referralAmount)))) {
                this.setState({
                    referralAmountError: 'Invalid Amount.'
                });
            } else {
                this.setState({
                    referralAmountError: 'Required'
                });
            }
            if (formValues.referralMinOrder && (!isNaN(formValues.referralMinOrder) || /^[0-9]+$/.test(Number(formValues.referralMinOrder)))) {
                formValues.referAndEarn = this.state.referAndEarn;
                this.props.onSubmit(formValues);
                this.setState({
                    referralMinOrderError: null,
                })
            } else if (formValues.referralMinOrder && (isNaN(formValues.referralMinOrder) || !/^[0-9]+$/.test(Number(formValues.referralMinOrder)))) {
                this.setState({
                    referralMinOrderError: 'Invalid Amount.'
                });
            } else {
                this.setState({
                    referralMinOrderError: 'Required'
                });
            }

        } else {
            formValues.referAndEarn = this.state.referAndEarn;
            this.props.onSubmit(formValues);
            this.setState({
                referralAmountError: null,
                referralMinOrderError: null
            })
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <Grid container className={classes.header} spacing={3}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom>
                            Edit Region
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
                    </Grid>
                </Grid>
                <Container className={classes.content}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Field name="name" component={this.renderTextField} label="Region Name" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="pincode" component={this.renderTextField} label="Pin Code" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={6} style={{ alignSelf: "center" }}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Refer & Earn</FormLabel>
                                <Typography component="div">
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>Off</Grid>
                                        <Grid item>
                                            <AntSwitch checked={this.state.referAndEarn} onChange={(e) => this.handleReferAndEarnSwitchChange(e)} name="referAndEarn" />
                                        </Grid>
                                        <Grid item>On</Grid>
                                    </Grid>
                                </Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <Field disabled={!this.state.referAndEarn} name="referralAmount" component={this.renderTextField} label="Referral Amount" error={this.state.referralAmountError ? this.state.referralAmountError : ''} fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={3}>
                            <Field disabled={!this.state.referAndEarn} name="referralMinOrder" component={this.renderTextField} label="Minimum Order Value" error={this.state.referralMinOrderError ? this.state.referralMinOrderError : ''} fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="regionCode" component={this.renderTextField} disabled label="Region Code " fullWidth variant="outlined" margin="normal" />
                        </Grid>
                    </Grid>
                </Container>
                <Container className={classes.footer}>
                    <Grid container spacing={2} >
                        <Grid item xs={8}></Grid>
                        <Grid item xs={2}>
                            <Button
                                fullWidth
                                variant="contained"
                                margin="normal"
                                color="default"
                                onClick={() => { this.props.onClose() }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                margin="normal"
                                color="primary">
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        );
    }
}

const validate = values => {
    const errors = {}

    const requiredFields = [
        'name',
        'pincode'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });

    if (values.pincode && !/^[1-9][0-9]{5}$/.test(values.pincode)) {
        errors.pincode = 'Invalid Pincode.'
    }

    return errors
}

export default reduxForm({
    form: 'EditRegionForm',
    validate
})(withStyles(styles)(EditRegionForm))
