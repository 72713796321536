import React, { useState, useEffect, useImperativeHandle } from 'react';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
})

const getSlabRow = (feeType, slabRow, changeTxnChargesRow, convFeesIndex, txnChargestype, 
                    slabIndex, editTxnChargesRow, addTxnChargesRow, openDelConfirmDialog,
                    editTxnChargesRowFunc, fieldsReadOnly, clickInfinityButton, infinityChecked, setInfinityChecked, setfieldsReadOnly,
                    numSlabs) => {
    // console.log("getSlabRow", feeType, convFeesIndex, slabRow, txnChargestype, slabIndex);
    return (
      <div>
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
            {feeType === "slab" &&
                <>
                    <Grid item xs={2}>
                        <TextField
                            id="outlined-FromRupees"
                            label="From Rupees"
                            variant="outlined"
                            type="number"
                            required
                            // disabled={readOnly[slabIndex]}
                            inputProps={{pattern:"[0-9]$", readOnly : fieldsReadOnly[slabIndex]}}
                            value={slabRow.from}
                            onChange={(e) => {
                                changeTxnChargesRow(e.target.value !== '' ? Number(e.target.value) : '', txnChargestype,
                                    convFeesIndex, feeType, slabIndex, "from");
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            id="outlined-ToRupees"
                            label="To Rupees"
                            variant="outlined"
                            type="number"
                            value={slabRow.to === Number.MAX_SAFE_INTEGER?'':slabRow.to}
                            disabled={infinityChecked[slabIndex]}
                            inputProps={{readOnly : fieldsReadOnly[slabIndex]}}
                            onChange={(e) => {
                                changeTxnChargesRow(e.target.value !== '' ? Number(e.target.value) : '', txnChargestype,
                                    convFeesIndex, feeType, slabIndex, "to");
                            }}
                        />
                    </Grid>
                </>
            }
            <Grid item xs={2}>
                <FormControl fullWidth variant="outlined" required>
                    <InputLabel id="txn-charges-select-outlined-label">Charges</InputLabel>
                    <Select label="Charges"
                        labelId="txn-charges-select-outlined-label" 
                        id="txn-charges-select-outlined" 
                        style={{backgroundColor:feeType === "slab" ? (fieldsReadOnly[slabIndex]?'inherit':'white'):(fieldsReadOnly?'inherit':'white')}}
                        value={slabRow.chargeType}
                        // disabled={rowDisabled[slabIndex]}
                        inputProps={{disabled : feeType === "slab" ? fieldsReadOnly[slabIndex] : fieldsReadOnly, required : true}}
                        onChange={(e) => {
                            changeTxnChargesRow(e.target.value, txnChargestype,
                                convFeesIndex, feeType, slabIndex, "chargeType");
                        }}
                    >
                      <MenuItem value={"percentage"}>Percentage</MenuItem>
                      <MenuItem value={"fixed"}>Fixed</MenuItem>
                      
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id="outlined-Value"
                    type="number"
                    label={slabRow.chargeType?(slabRow.chargeType.charAt(0).toUpperCase()+slabRow.chargeType.substring(1)) + " Value"
                                                :'' + " Value" }
                    required
                    variant="outlined"
                    // disabled={rowDisabled[slabIndex]}
                    inputProps={{readOnly : feeType === "slab" ? fieldsReadOnly[slabIndex] : fieldsReadOnly}}
                    value={slabRow.value}
                    onChange={(e) => {
                        changeTxnChargesRow(e.target.value !== '' ? Number(e.target.value) : '', txnChargestype,
                            convFeesIndex, feeType, slabIndex, "value");
                    }}
                />
            </Grid>
            {feeType === "slab" &&  <Grid item xs={2}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item>
                        <Radio
                            checked={infinityChecked[slabIndex]=== true}
                            value={infinityChecked[slabIndex]}
                            key={"radio-infinity-button_"+slabIndex}
                            onChange={(e) => clickInfinityButton(slabIndex, numSlabs, txnChargestype,
                                                                    convFeesIndex, feeType)}
                            name={"radio-infinity-button"}
                            disabled={fieldsReadOnly[slabIndex]}
                        />
                    </Grid>
                    <Grid item>
                        <AllInclusiveIcon />
                    </Grid>
                </Grid>
            </Grid> 
            }

            {feeType === "flat" &&  <>
                <Grid item xs={2}></Grid> 
                <Grid item xs={2}></Grid>
                <Grid item xs={2}></Grid>
            </>
            }

            <Grid item xs={2}>
                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                    <Grid item style={{padding:(feeType === "slab")?"8px":"8px 18px"}}>
                        <IconButton style={{backgroundColor:'white', padding: "2px" }} 
                                    aria-label="edit" size="small"
                                    >
                            <EditIcon onClick={ () => editTxnChargesRowFunc(slabIndex, feeType) } fontSize="inherit" />
                        </IconButton>

                    </Grid>
                    {feeType === "slab" && <>
                        <Grid item>
                            <IconButton style={{backgroundColor:'white', padding: "2px" }} 
                                aria-label="add" size="small" disabled={infinityChecked[slabIndex]}>
                                <AddIcon onClick={() => {
                                     addTxnChargesRow(txnChargestype, convFeesIndex, slabIndex);
                                     let newInfinityChecked = [...infinityChecked];
                                     newInfinityChecked.splice(slabIndex+1,0,false);
                                     setInfinityChecked(newInfinityChecked);
                                     infinityChecked = newInfinityChecked;
                                     let newfieldsReadOnly = [...fieldsReadOnly];
                                     newfieldsReadOnly.splice(slabIndex+1, 0, false);
                                     setfieldsReadOnly(newfieldsReadOnly);
                                     }} fontSize="inherit" />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton style={{backgroundColor:'white', padding: "2px" }} 
                                aria-label="delete" size="small">
                                <DeleteIcon onClick={() => { openDelConfirmDialog(txnChargestype, convFeesIndex, slabIndex, ()=> {
                                    let newInfinityChecked = [...infinityChecked];
                                    newInfinityChecked.splice(slabIndex,1);
                                    setInfinityChecked(newInfinityChecked);
                                    let newfieldsReadOnly = [...fieldsReadOnly];
                                    newfieldsReadOnly.splice(slabIndex,1);
                                    setfieldsReadOnly(newfieldsReadOnly);
                                }) }} fontSize="inherit" />
                            </IconButton>
                        </Grid>
                    </>}

                    {feeType === "flat" &&  <>
                        <Grid item xs={2}></Grid> 
                        <Grid item xs={2}></Grid>
                    </>}
                </Grid>
            </Grid>
        </Grid>
        {slabRow.error!=null && <div style={{fontSize:'12px', color:'red', marginBottom:'15px'}}>{slabRow.error}</div>}
        </div>
    );
}

const TransactionChargesRow = React.forwardRef((props, ref)=> {
    const { classes, convFees = {}, convFeesIndex,
        txnChargestype, editTxnChargesRow, addTxnChargesRow,
        openDelConfirmDialog, changeTxnChargesRow, openSubsequentTxnRowDelConfirmDialog } = props;
    const { slabs = [] } = convFees;
    
    const [flatReadOnly, setFlatReadOnly] = useState(true);
    const [fieldsReadOnly, setfieldsReadOnly] = useState(Array(slabs.length).fill(true));
    const [infinityChecked, setInfinityChecked] = useState(Array(slabs.length).fill(false));
    
    const saved = () => {
      if(convFees.feeType === 'slab') {
        setfieldsReadOnly(Array(slabs.length).fill(true));
      }else {
        setFlatReadOnly(true);
      }
    }
    useImperativeHandle(ref, () => ({
      saved
    }));

    useEffect(()=>{
        let newInfinityChecked = [...infinityChecked];
        slabs.forEach((slab, index)=> {
            if(slab.to === Number.MAX_SAFE_INTEGER) {
                newInfinityChecked[index] = true;
            } 
        })
        setInfinityChecked(newInfinityChecked);
        if(convFees.feeType === 'flat' && convFees.isNew === true) {
          setFlatReadOnly(false);
        }
        
    }, []);

    useEffect(()=>{
      let newInfinityChecked = [...infinityChecked];
      let newFieldsReadOnly = [...fieldsReadOnly];
      if(convFees.feeType === 'slab'){
        slabs.forEach((slab, index)=> {
            if(slab.to === Number.MAX_SAFE_INTEGER) {
                newInfinityChecked[index] = true;
            }
            if(slab.isNew === true) {
              newFieldsReadOnly[index] = false;
            }
        });
        setfieldsReadOnly(newFieldsReadOnly);
      }else {
        newInfinityChecked = [false];
      }
      setInfinityChecked(newInfinityChecked);
      if(convFees.feeType === 'flat' && convFees.isNew === true) {
        setFlatReadOnly(false);
      }
    },[convFees.feeType]);
    
    const editTxnChargesRowFunc = (index, feeType) => {
        if (feeType === "slab") {
            let newfieldsReadOnly = [...fieldsReadOnly];
            newfieldsReadOnly[index] = !newfieldsReadOnly[index];
            setfieldsReadOnly(newfieldsReadOnly);
        } else if(feeType === "flat") {
            setFlatReadOnly(!flatReadOnly);
        }
    }
    
    const clickInfinityButton = (slabIndex, slabLength, txnChargestype, convFeesIndex, feeType) => {
        // console.log("Clicked on infinity button", slabIndex, slabLength);
        openSubsequentTxnRowDelConfirmDialog(txnChargestype, convFeesIndex, slabIndex, (delTxnChargesRow) => {
            let newInfinityChecked = [...infinityChecked];
            // Unset all the transactions row infinity button
            newInfinityChecked = newInfinityChecked.map(nicObj => nicObj = false);
            // set the infinity button for the checked index
            newInfinityChecked[slabIndex] = !newInfinityChecked[slabIndex];
            setInfinityChecked(newInfinityChecked);

            // console.log("Clicked on infinity button", txnChargestype, convFeesIndex, feeType);
            changeTxnChargesRow(Number.MAX_SAFE_INTEGER, txnChargestype,
                convFeesIndex, feeType, slabIndex, "to");
            let indexToDeleteStart = slabIndex + 1;
            if (indexToDeleteStart < slabLength) {
                //for (let i = slabIndex + 1; i <= slabLength; i++) {
                let rowsToDeleteCount = slabLength - indexToDeleteStart;
                delTxnChargesRow(txnChargestype, convFeesIndex, indexToDeleteStart, rowsToDeleteCount);
                infinityChecked.splice(indexToDeleteStart, rowsToDeleteCount);
                fieldsReadOnly.splice(indexToDeleteStart, rowsToDeleteCount);
                //}
            }
        })
    }

    const getRows = () => {
        if (convFees.feeType === "slab") {
            // In this case we will get a key:slabs
            // slabs : { from: NUM, to : NUM, chargeType: "Percentage/Fixed", value : NUM }
            return slabs.map((slab, slabIndex) => getSlabRow("slab", slab, 
                                                    changeTxnChargesRow, convFeesIndex, 
                                                    txnChargestype, slabIndex, 
                                                    editTxnChargesRow, addTxnChargesRow, 
                                                    openDelConfirmDialog,  editTxnChargesRowFunc,
                                                    fieldsReadOnly, clickInfinityButton, 
                                                    infinityChecked, setInfinityChecked, setfieldsReadOnly, slabs.length));
        } else if (convFees.feeType === "flat") {
            // In this case we will get a key:slabs
            // slabs : { chargeType: "Percentage/Fixed", value : NUM }
            return getSlabRow("flat", convFees, changeTxnChargesRow, convFeesIndex, 
                                txnChargestype, 0, null, null, null, editTxnChargesRowFunc,
                                flatReadOnly)
        }
    }

    return (
        <>
            {getRows()}
        </>
    );
});

export default withStyles(styles)(TransactionChargesRow);