import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
    form: {
        width: '100%',
        height: '100%',
    },
    header: {
        height: '15%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '70%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
});

let pendingAmountP = 0;
class PaymentForm extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedDate: new Date(),
            ammount: 0,
            selectedMode: 'Cash'
        };
    }

    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    }

    handleModeChange = event => {
        this.setState({
            selectedMode: event.target.value
        });
    };

    onSubmit = formValues => {
        let payload = {
            amount: formValues.amount,
            mode: this.state.selectedMode,
            note: formValues.note
        }
        this.props.onSubmit(payload);
    }

    render() {
        const { classes } = this.props;
        let totalPaidAmount = 0;
        let pendingAmount = 0
        this.props.selectedContract.payments.forEach(payment => {
            totalPaidAmount += Number(payment.amount);
        });

        pendingAmount = Number(this.props.selectedContract.agreedAmount) - Number(totalPaidAmount);
        pendingAmountP = pendingAmount;
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <Grid container className={classes.header} spacing={3}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom>
                            Payment Received
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
                    </Grid>
                </Grid>
                <Container className={classes.content}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Grid item>
                                <Typography variant="caption" display="block" gutterBottom>
                                    Agreed Contract Amount
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2" gutterBottom >
                                    {this.props.selectedContract.agreedAmount}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item>
                                <Typography variant="caption" display="block" gutterBottom>
                                    Pending Amount
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2" gutterBottom >
                                    {pendingAmount}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="amount" component={this.renderTextField} label="Amount" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={6} style={{ paddingTop: '20px' }}>
                            <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="outlined-age-native-simple">Mode of Payment</InputLabel>
                                <Select
                                    native
                                    value={this.state.selectedMode}
                                    onChange={this.handleModeChange}
                                    label="Mode of Payment"
                                    inputProps={{
                                        name: 'mode',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    <option value="Cash">Cash</option>
                                    <option value="Cheque">Cheque</option>
                                    <option value="Online">Online</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Field name="note" component={this.renderTextField} label="Payment Details" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                    </Grid>
                    {/* </div> */}
                </Container>
                <Container className={classes.footer}>
                    <div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            margin="normal"
                            color="primary">
                            Submit
                        </Button>
                    </div>
                </Container>
            </form>
        );
    }
}

const validate = values => {
    const errors = {}
    
    const requiredFields = [
        'amount',
        'mode'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (values.agreedContrctAmount && !/^[0-9]+$/.test(values.agreedContrctAmount)) {
        errors.agreedContrctAmount = 'amount should have only numbers'
    }
    if (values.amount && !/^[0-9]+$/.test(values.amount)) {
        errors.amount = 'amount should have only numbers'
    }
    if(parseInt(values.amount) > pendingAmountP) {
        errors.amount = "Amount should be less than or equal to pendingAmount"
    }

    return errors
}

export default reduxForm({
    form: 'PaymentForm',
    validate
})(withStyles(styles)(PaymentForm))
