import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import Divider from '@material-ui/core/Divider';
import { lightBlue } from '@material-ui/core/colors';
// import _ from 'lodash';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from "@material-ui/core/ListItemText";
import FormHelperText from '@material-ui/core/FormHelperText';


const styles = theme => ({
	grid: {
		width: '100%',
		alignItems: 'center',
		paddingLeft: "15px",
		paddingRight: "15px",
		margin: '0px 0px',
	},
	header: {
        height: '10%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: "22px",
        backgroundColor: theme.palette.primary.main,
      },
	headGridTwo: {
		float: 'right',
		marginRight: '2px'
	},
	modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px'
      },
  modalContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
	icon: {
		color: 'white',
	},
  mainContainer1: {
    width: '40%',
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: '0px 0px', 
  },
	lightBlue: {
		color: theme.palette.getContrastText(lightBlue[50]),
		backgroundColor: lightBlue[50],
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
});

const WhiteTextTypography = withStyles({
	root: {
		color: "#FFFFFF"
	}
})(Typography);

class AddExpenseForm extends Component {
    constructor(props){
        super(props)
        this.state = {
            invoiceNumber : "",
            invoiceDate : "",
            expenseName : "",
            expenseVendorName : "",
            expenseDescription : "",
            isGstEnabled : false,
            totalInvoiceAmount : "",
            expenseHeads : "",
            expenseNameError : "",
            invoiceAmountError : "",
            expenseHeadsError : "",
            expenseNameValidationError : "",
            invoiceAmountValidationError : "",
            expenseDescriptionValidationError : "",
            expenseVendorNameValidationError: ""
        } 
    }
    componentDidMount(){
        this.setState({status : "SUCCESS"});
        if(this.props.edit){
            this.setData();
        }
    }

    setData() {
      this.setState({
        invoiceNumber: this.props.selectedRowEdit.invoiceNumber ? this.props.selectedRowEdit.invoiceNumber : "",
        invoiceDate: this.props.selectedRowEdit.invoiceDate ? this.props.selectedRowEdit.invoiceDate : "",
        expenseName: this.props.selectedRowEdit.expenseName ? this.props.selectedRowEdit.expenseName : "",
        expenseVendorName: this.props.selectedRowEdit.expenseVendorName ? this.props.selectedRowEdit.expenseVendorName : "",
        expenseDescription: this.props.selectedRowEdit.expenseDescription ? this.props.selectedRowEdit.expenseDescription : "",
        isGstEnabled: this.props.selectedRowEdit.isGstEnabled ? this.props.selectedRowEdit.isGstEnabled : "",
        totalInvoiceAmount: this.props.selectedRowEdit.totalInvoiceAmount ? this.props.selectedRowEdit.totalInvoiceAmount : "",
        expenseHeads:this.props.selectedRowEdit.expenseHeads ? this.props.selectedRowEdit.expenseHeads : ""
      })
    }

    handleChange = (event) => {
      if (event.target.name === "expenseName") { this.setState({ [event.target.name]: event.target.value, expenseNameError: false, expenseNameValidationError: false}); }
      if (event.target.name === "expenseVendorName") { this.setState({ [event.target.name]: event.target.value, expenseVendorNameValidationError: false}); }
      if (event.target.name === "expenseDescription") { this.setState({ [event.target.name]: event.target.value, expenseDescriptionValidationError: false}); }
      if (event.target.name === "totalInvoiceAmount") { this.setState({ [event.target.name]: event.target.value, invoiceAmountError:false, invoiceAmountValidationError: false}); }
      if (event.target.name === "expenseHeads") { this.setState({ [event.target.name]: event.target.value, expenseHeadsError:false}); }
      }

	close = () => {
		this.props.onClose();
  this.setState({
    expenseNameError: false,
    expenseNameValidationError: false,
    invoiceAmountError: false,
    invoiceAmountValidationError: false,
    expenseHeadsError: false,
    expenseDescriptionValidationError: false,
    expenseVendorNameValidationError: false
  })
	}

  handleGST = (event) => {
    if(event.target.checked === true){
      this.setState({ isGstEnabled: true });  
    }else{
      this.setState({ isGstEnabled: false });  
    }
	}

 

  saveExpenseDetails = async(e,data) => {
    let hasError= false;
    
      if(!this.state.expenseName){ 
        hasError = true;
         this.setState({ expenseNameError: true}); }
  
      if(!this.state.totalInvoiceAmount){ 
       hasError = true;
        this.setState({ invoiceAmountError: true}); }
  
      if(!this.state.expenseHeads){ 
        hasError = true;
         this.setState({ expenseHeadsError: true}); }

      let charRegex =  /[a-zA-Z][a-zA-Z\s]*$/;
      // let regEx = /^[A-Za-z]+$/;
      let regExcheckExpenseName = await charRegex.test(this.state.expenseName);
      if (!regExcheckExpenseName) {
        hasError= true;
        this.setState({ expenseNameValidationError : true});
      }else if(this.state.expenseName.trim() === ""){
        hasError = true;
        this.setState({ expenseNameError: true});  
      }

      let regExcheckDescription =/[a-zA-Z0-9_@.!%^$*:<>~=/()#&+-][a-zA-Z0-9_@.!%^$*:<>~=/()#&+-]*$/.test(this.state.expenseDescription)
      // let regDescCheck = await regEx.test(this.state.expenseDescription)
       if(this.state.expenseDescription === ""){
        // hasError= false;
        this.setState({ expenseDescriptionValidationError : false});
      }else {
        if (!regExcheckDescription) {
            hasError= true;
            this.setState({ expenseDescriptionValidationError : true});
          }
      }
     
      let regExcheckVendorName =  /[a-zA-Z0-9_@.!%^$*:<>~=/#&][a-zA-Z0-9_@.!%^$*:<>~=/#&]*$/.test(this.state.expenseVendorName)
      // let regVenNameCheck = await regEx.test(this.state.expenseVendorName)
      if(this.state.expenseVendorName === ""){ 
        // console.log('regExcheckDescription%%%%%%',regExcheckDescription)
        // hasError= false;
        this.setState({ expenseVendorNameValidationError : false}); 
        // console.log('regExcheckDescription222222',regExcheckDescription)
      }else {
        if (!regExcheckVendorName) {
          // console.log('regExcheckDescription111111',regExcheckDescription)
            hasError= true;
            this.setState({ expenseVendorNameValidationError : true});
          }
      }

      let invoiceAmountRegex = /^\d*(\.\d+)?$/;
      let regExcheckInvoiceAmount = await invoiceAmountRegex.test(this.state.totalInvoiceAmount);
      if (!regExcheckInvoiceAmount) {
      hasError = true;
      this.setState({ invoiceAmountValidationError: true});
    }

      
      if(this.props.edit){
        let payload = {
         expenseId: this.props.selectedRowEdit._id,
         data: {
          expenseName : this.state.expenseName ? this.state.expenseName.trim() : "",
          expenseVendorName : this.state.expenseVendorName ? this.state.expenseVendorName.trim() : "",
          expenseDescription : this.state.expenseDescription ? this.state.expenseDescription.trim() : "",
          totalInvoiceAmount : this.state.totalInvoiceAmount ? this.state.totalInvoiceAmount  : "",
          isGstEnabled : this.state.isGstEnabled === true ? true : false,
          expenseHeads: this.state.expenseHeads ? this.state.expenseHeads : ""
        }
        }
        if(!hasError){
          this.props.updateExpense(payload);
        }
			}else{
        
        let payload = {
           invoiceNumber : this.state.invoiceNumber ? this.state.invoiceNumber.trim() :  "",
           invoiceDate : this.state.invoiceDate ? this.state.invoiceDate.trim() : "",
           expenseName : this.state.expenseName ? this.state.expenseName.trim() : "",
           expenseVendorName : this.state.expenseVendorName ? this.state.expenseVendorName.trim() : "",
           expenseDescription : this.state.expenseDescription ? this.state.expenseDescription.trim() : "",
           totalInvoiceAmount : this.state.totalInvoiceAmount ? this.state.totalInvoiceAmount  : "",
           isGstEnabled : this.state.isGstEnabled === true ? true : false,
           expenseHeads: this.state.expenseHeads ? this.state.expenseHeads : ""
         }
    if(!hasError){
				this.props.addExpense(payload);
    }	
			}    
  }

    render (){ 
		// console.log('this.props from addExpenseForm',this.props)
    // console.log('this.state===>',this.state)
        const {classes} = this.props;
        return(
            <Modal
				className={classes.modal}
				open={this.props.open}
				closeAfterTransition >
				<Container className={classes.mainContainer1}>
					<Grid container className={classes.header} spacing={1} >
          <Grid item xs={8}>
							<WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
								{!this.props.edit ? "Add " : "Edit "} Expense
							</WhiteTextTypography>
						</Grid>
						<Grid item xs={4}>
							<WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
								<CloseIcon className={classes.headGridTwo} onClick={() => { this.close() }} />
							</WhiteTextTypography>
						</Grid>
					</Grid>

          <Container className={classes.modalContainer}>
					<Grid container className={classes.grid} spacing={1} alignItems="center" >
           
          
           {!this.props.edit ?null : <Grid item xs={6} style={{marginTop: "10px"}}> <Typography variant="subtitle2" gutterBottom> Invoice Number </Typography></Grid> }
           {!this.props.edit ?null :<Grid item xs={6} style={{marginTop: "10px"}}> <Typography variant="subtitle2" gutterBottom>Invoice Date </Typography> </Grid> }
          <Grid item xs={6} style={{marginTop: "0px"}}> <Typography variant="subtitle1" gutterBottom>{this.state.invoiceNumber ? this.state.invoiceNumber : ""}</Typography></Grid>
          <Grid item xs={12} sm={6} style={{marginTop: "0px"}}> <Typography variant="subtitle1" gutterBottom>	{this.state.invoiceDate ? moment(this.state.invoiceDate).format('DD MMM YYYY ') : ""}	</Typography></Grid> 
        
          <Grid item xs={5} style={{marginTop: "0px"}}> <Typography variant="subtitle2" gutterBottom> Name* </Typography></Grid>
          <Grid item xs={6} style={{marginTop: "0px",marginLeft: "36px"}}> <Typography variant="subtitle2" gutterBottom>Vendor Name </Typography> </Grid>

          <Grid item xs={6}><TextField style={{marginTop:'0px',width:"100%"}} fullWidth margin="dense" name="expenseName" label="Name" variant="outlined" value={this.state.expenseName}onChange={this.handleChange} error={this.state.expenseNameError ? true : false} /></Grid>
				  <Grid item xs={6} > <TextField style={{marginTop:'0px',width:"100%"}} fullWidth margin="dense" name="expenseVendorName" label="Vendor Name" variant="outlined" value={this.state.expenseVendorName}onChange={this.handleChange}/></Grid>
          <Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px'}}> {this.state.expenseNameError?"Required":(this.state.expenseNameValidationError ? "Enter Valid Name" : null)} </FormHelperText></Grid>
          <Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px'}}> {this.state.expenseVendorNameValidationError? "Enter valid vendor name": null} </FormHelperText></Grid>

          <Grid item xs={12} className={classes.gridItem}> <Typography variant="subtitle2" gutterBottom>Description </Typography> </Grid>
          <TextField style={{marginTop:'0px', marginBottom:'5px',width:"100%"}} fullWidth margin="dense" name="expenseDescription" label="Description" variant="outlined" value={this.state.expenseDescription}onChange={this.handleChange} error={this.state.accountHolderNameError ? true : false} />
          <Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px'}}> {this.state.expenseDescriptionValidationError? "Enter valid description": null} </FormHelperText></Grid>
          
          <Grid item xs={12}> <Typography variant="subtitle2" gutterBottom> Total Invoice Amount* </Typography></Grid>

          <Grid style={{display:"flex"}}>
          <Grid item xs={6}><TextField style={{marginTop:'0px',width:"100%"}} fullWidth margin="dense" name="totalInvoiceAmount" label="Total Invoice Amount" variant="outlined" value={this.state.totalInvoiceAmount}onChange={this.handleChange} error={this.state.invoiceAmountError ? true : false} /></Grid>
              <Grid item xs={5} style={{paddingLeft: "80px",paddingTop:"8px"}}> <Typography variant="subtitle2" gutterBottom>GST Bill </Typography> </Grid>
             <Grid item xs={1} style={{paddingLeft:"2px"}}>  <Checkbox
                                        onClick={(event) => this.handleGST(event)}
                                        value={this.state.isGstEnabled}
                                        name="GST"
                                        checked={this.state.isGstEnabled}
                                        style={{ paddingRight: "3px", marginBottom: "8px" }}
                                    />
                                    </Grid>
                      </Grid>
          <Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px'}}> {this.state.invoiceAmountError?"Required":(this.state.invoiceAmountValidationError ? "Should be a number" : null)} </FormHelperText></Grid>
                <Grid item xs={12}> <Typography variant="subtitle2" gutterBottom> Heads* </Typography></Grid>
                    <FormControl fullWidth margin="dense" variant="outlined">
                      <InputLabel id="demo-simple-select-standard-label">Heads</InputLabel>
                      <Select
                         labelId="demo-simple-select-standard-label"
                         id="demo-simple-select-standard"
                        value={this.state.expenseHeads}
                        name="expenseHeads"
                        onChange={this.handleChange}
                        label="Heads"
                        style={{ width : "fullWidth" }}
                        MenuProps={this.MenuProps}
                      >
                       <MenuItem value={'Shopper Acquisition cost'} style={{ minHeight: "28px", height: "28px" }}>                      
                        <ListItemText primary="Shopper Acquisition cost" />
                       </MenuItem>
                       <MenuItem value={'Shopper Retention Cost'} style={{ minHeight: "28px", height: "28px" }}>
                        <ListItemText primary="Shopper Retention Cost" />
                       </MenuItem>
                       <MenuItem value={'Merchant Acquisition Cost'} style={{ minHeight: "28px", height: "28px" }}>                      
                        <ListItemText primary="Merchant Acquisition Cost" />
                       </MenuItem>
                       <MenuItem value={'Merchant Retention Cost'} style={{ minHeight: "28px", height: "28px" }}>                      
                        <ListItemText primary="Merchant Retention Cost" />
                       </MenuItem>
                       <MenuItem value={'CSR'} style={{ minHeight: "28px", height: "28px" }}>                      
                        <ListItemText primary="CSR" />
                       </MenuItem>
                       <MenuItem value={'Branding'} style={{ minHeight: "28px", height: "28px" }}>                      
                        <ListItemText primary="Branding" />
                       </MenuItem>
                      </Select>
                    </FormControl>
          <Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.expenseHeadsError?"Required" : null} </FormHelperText></Grid>
                <Grid item xs={1} style={{marginTop: "10px",paddingLeft: "0px"}} className={classes.gridItem} spacing={5} >
                  <Button
                    variant="contained"
                    component="span"
                    onClick={this.close}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={3} style={{marginTop: "10px",paddingLeft: "80px"}} className={classes.gridItem} >
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={this.saveExpenseDetails}
                  >
                    Save
                  </Button>
                </Grid>
                </Grid>
                    </Container>
				</Container>
			</Modal >
        )
    }
}

const mapStateToProps = (state) => {
	return {
		env: state.auth.env
	};
};
export default connect(mapStateToProps, {})(withStyles(styles)(AddExpenseForm));