import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    Typography,
    Avatar,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    avatar: {
        marginLeft: 'auto',
        height: 110,
        width: 100,
        flexShrink: 0,
        flexGrow: 0
    }
});

class CustomerCard extends Component {

    render() {
        const { classes, consumer } = this.props;

        return (
            <div >
                <Card className={classes.root}>
                    <CardContent>
                        <Grid container spacing={4}>
                            <Grid item lg={9} md={6} xl={9} xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Consumer Details
                        </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <b>Name</b> : {consumer.firstName + " " + consumer.lastName}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <b>Mobile</b> :{consumer.primaryMobileNumber}
                                </Typography>
                            </Grid>
                            <Grid item lg={3} md={6} xl={3} xs={12}>
                                {
                                    consumer.photo ?
                                        <Avatar
                                            className={classes.avatar}
                                            src={consumer.photo}>
                                            {consumer.firstName + " " + consumer.lastName}
                                        </Avatar> : null
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        );
    }
}


export default connect(null, null)(withStyles(styles)(CustomerCard));