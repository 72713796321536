import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';

const styles = theme => ({
    header: {
        height: '10%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '70%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px'
    },
    modalContainer: {
        width: '70%',
        height: '90%',
        backgroundColor: theme.palette.background.paper,
        padding: '0px 0px',
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    head: {
        fontWeight: 700,
        fontSize: "12px",
    },
});


class History extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        const { classes, release } = this.props;

        if (release) {
            return (
                <Modal
                    className={classes.modal}
                    open={this.props.open}
                    closeAfterTransition>
                    <Container className={classes.modalContainer}>
                        <Grid container className={classes.header} spacing={1}>
                            <Grid item xs={8}>
                                <Typography variant="h5" gutterBottom>
                                    Release History
                            </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <CloseIcon className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
                            </Grid>
                        </Grid>
                        <Container className={classes.content}>
                            <Grid container spacing={4}>
                                <Grid item>
                                    <Typography variant="subtitle2" color="secondary" gutterBottom>
                                        App Name: {this.props.release.appname}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="secondary" gutterBottom>
                                        Platform: {this.props.release.platform}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead className={classes.head}>
                                            <TableRow>
                                                <TableCell>Version</TableCell>
                                                <TableCell>Release Date</TableCell>
                                                <TableCell>Created By</TableCell>
                                                <TableCell>Force Upgrade</TableCell>
                                                <TableCell>Clear App Data</TableCell>
                                                <TableCell>Remark</TableCell>
                                                <TableCell>Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.props.release.history.map(release => {
                                                return (
                                                    <TableRow className={classes.tableRow} hover key={release._id} button={true}>
                                                        <TableCell className={classes.tableCellSmall}>{release.versioncode}</TableCell>
                                                        <TableCell className={classes.tableCellSmall}>{moment(release.releasedOn).format('DD-MMM-YYYY')}</TableCell>
                                                        <TableCell className={classes.tableCellSmall}>{release?.createdBy?.firstName} {release?.createdBy?.lastName}</TableCell>
                                                        <TableCell className={classes.tableCellSmall}>{release.force ? 'Yes' : 'No'}</TableCell>
                                                        <TableCell className={classes.tableCellSmall}>{release?.isClearAppData ? 'Yes' : 'No'}</TableCell>
                                                        <TableCell className={classes.tableCellSmall}>{release.remark}</TableCell>
                                                        <TableCell className={classes.tableCellSmall}>{release.description}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Container>
                        <Container className={classes.footer}>
                            <div>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    margin="normal"
                                    color="secondary"
                                    onClick={this.props.onClose}
                                    >
                                    Close
                                </Button>
                            </div>
                        </Container>
                    </Container>
                </Modal>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
}

export default connect()(withStyles(styles)(History));