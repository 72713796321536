import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
    Checkbox: {
        fontSize: 20
    },
    grid: {
        width: '100%',
        //display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: "15px",
        paddingRight: "15px",
        margin: '0px 0px',

    },
    deleteBtn: {
        cursor: 'pointer',
        color: 'grey',
        //marginTop: "7px"
    },
    header: {
        paddingLeft: "10px",
        height: '10%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '70%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px',

    },
    modalContainer: {
        width: '100%',
        height: '100%',
        padding: '0px 0px',
        backgroundColor: theme.palette.background.paper,
        margin: '10px 0px',
        overflow: 'auto',
    },
    gridElement: {
        margin: '10px 5px'
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    icon: {
        color: 'white',
    },
    gridList: {
        width: '100%',
        height: '100%'
    },
    mainContainer: {
        width: '50%',
        height: '80%',
        backgroundColor: theme.palette.background.paper,
        padding: '0px 0px',


    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    head: {
        fontWeight: 700,
        fontSize: "12px",
    },
    input: {
        height: 30,
        padding: '0px'
    },
    button: {
        height: 30
    },
    newarrival: {
		borderRadius: "3px",
		alignSelf: 'center',
		paddingLeft:"10px",
		paddingRight:"10px",
		// paddingTop:"10px",
		// paddingBottom:"10px",
		marginRight:"10px",
        backgroundColor: "#FFA347",
		// fontColor: "#FFFFFF"
    },
	musttry: {
		borderRadius: "3px",
		alignSelf: 'center',
		paddingLeft:"10px",
		paddingRight:"10px",
		// paddingTop:"10px",
		// paddingBottom:"10px",
		marginRight:"10px",
        // backgroundColor: "#FF4767",
        backgroundColor: "#FF8197",
		// fontColor: "#FFFFFF"
    },
	bestseller: {
		borderRadius: "3px",
		alignSelf: 'center',
		paddingLeft:"10px",
		paddingRight:"10px",
		// paddingTop:"10px",
		// paddingBottom:"10px",
		marginRight:"10px",
        backgroundColor: "#AF7BFF",
		// fontColor: "#FFFFFF"
    }
});

const WhiteTextTypography = withStyles({
    root: {
        color: "#FFFFFF"
    }
})(Typography);
class ShowService extends Component {
    constructor() {
        super();
        this.state = {
        };
    }
    close = () => {
        this.props.onClose();
    }
    hasGstFields = () => {
      return this.props.data.cost.gstConf.sgst || this.props.data.cost.gstConf.cgst || this.props.data.cost.gstConf.igst 
    }
    render() {
        const { classes } = this.props;
        // console.log(this.props.data);
        let finalAmount;
        let activeDiscount
        let discountsArray;
        let sgstAmount, cgstAmount, igstAmount;
        discountsArray = this.props.data.serviceOffers && this.props.data.serviceOffers.discount && this.props.data.serviceOffers.discount.offers && this.props.data.serviceOffers.discount.offers.length > 0 ? this.props.data.serviceOffers.discount.offers : null
            // console.log("discountsArray--------------->",discountsArray)
        activeDiscount = discountsArray !=null ? discountsArray.find((discountItem) => discountItem.isActive == true) : null;
            // console.log("activeDiscount--------->",activeDiscount)

        if (this.props.data.cost && this.props.data.cost.costType === "Fixed" && this.props.data.cost.gstConf) {
          let itemAmount = this.props.data.cost.fixedCost;
          
          if (this.props.data.serviceOffers && this.props.data.serviceOffers.discount && this.props.data.serviceOffers.discount.offers && this.props.data.serviceOffers.discount.offers.length > 0) {

            if (activeDiscount) {
              if (activeDiscount.discountType === 'FLAT') {
                itemAmount = itemAmount - activeDiscount.off;
              } else {
                //percentage
                let discountAmount = (activeDiscount.off / 100) * itemAmount;
                if (activeDiscount.maxDiscountCap && discountAmount > activeDiscount.maxDiscountCap) {
                  discountAmount = activeDiscount.maxDiscountCap;
                }
                itemAmount = itemAmount - discountAmount;
              }
            }
          }
          sgstAmount = ((this.props.data.cost.gstConf.sgst || 0) / 100) * itemAmount;
          cgstAmount = ((this.props.data.cost.gstConf.cgst || 0) / 100) * itemAmount;
          igstAmount = ((this.props.data.cost.gstConf.igst || 0) / 100) * itemAmount;
          finalAmount = (parseFloat(itemAmount) + parseFloat(sgstAmount) + parseFloat(cgstAmount) + parseFloat(igstAmount)).toFixed(2);
		
        }
        return (
            <Modal
                className={classes.modal}
                open={this.props.open}
                closeAfterTransition
                border={0}>
                <Container className={classes.mainContainer}>
                    <Grid container className={classes.header} spacing={0} >
                        <Grid item xs={8} >
                            <WhiteTextTypography variant="h5" gutterBottom>
                                View Service
                            </WhiteTextTypography>
                        </Grid>
                        <Grid item xs={4}>
                            <WhiteTextTypography variant="h5" gutterBottom>
                                <CloseIcon className={classes.headGridTwo} onClick={() => { this.close() }} />
                            </WhiteTextTypography>
                        </Grid>
                    </Grid>
                    <Container className={classes.modalContainer}>
                        <Grid container className={classes.grid} spacing={0}>
                            <Grid item xs={12} sm={6} >
                                <Typography variant="subtitle1" gutterBottom>
                                    <b>Category</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <Typography variant="subtitle1" gutterBottom>
                                    <b>Sub-Category *</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <Typography variant="subtitle1" gutterBottom>

                                    {this.props.dashboard.setSelectedBusiness.category.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                {this.props.data.subcategory ? this.props.data.subcategory.name : "NA"}
                            </Grid>
                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px" }}
                                    display="block"
                                />
                            </Grid>
                            <Grid item xs={6} >
                                {
									this.props.data.mustTry !==undefined && this.props.data.mustTry === true 
										? 
											<Grid style={{display:"flex", alignItems:"center"}} item ><Typography variant="subtitle1" gutterBottom color="inherit"><b>Service</b></Typography><div style={{marginRight:"5px", marginLeft:"5px", color:"white"}} className={classes.musttry}>Must Try</div></Grid> 
										: 
											( 
												this.props.data.newArrival !== undefined && this.props.data.newArrival === true 
												? 
													<Grid style={{display:"flex", alignItems:"center"}} item ><Typography variant="subtitle1" gutterBottom color="inherit"><b>Service</b></Typography><div style={{marginRight:"5px", marginLeft:"5px", color:"white"}} className={classes.newarrival}>New</div></Grid>
												: 
													( 
														this.props.data.bestSeller !== undefined && this.props.data.bestSeller 
														? 
															<Grid style={{display:"flex", alignItems:"center"}} item ><Typography variant="subtitle1" color="inherit" gutterBottom><b>Service</b></Typography><div style={{marginRight:"5px", marginLeft:"5px", color:"white"}} className={classes.bestseller}>Best Seller</div></Grid> 
														: 
															<Grid style={{display:"flex", alignItems:"center"}} item ><Typography variant="subtitle1" color="inherit" gutterBottom><b>Service</b></Typography></Grid> 
													)
										    ) 
								}
                            </Grid>
                            <Grid item xs={2} >
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b>Is Enabled</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={2} >
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b>Availability</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b>Food Type</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{ whiteSpace: "initial", textOverflow: "ellipsis", }}>
                                <Typography variant="subtitle1" color="inherit" display="block" gutterBottom >
                                    {this.props.data.service}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} >
                                <Typography variant="subtitle1" color="inherit" display="block" gutterBottom >
                                    {this.props.data.isEnabled && this.props.data.isEnabled ? "Enabled" : "Disabled"}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} >
                                <Typography variant="subtitle1" color="inherit" display="block" gutterBottom >
                                    {this.props.data.availability && this.props.data.availability.isActive ? "Available" : "Unavailable"}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} >
                                <Typography variant="subtitle1" color="inherit" display="block" gutterBottom >
                                    {this.props.data.foodType}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.grid} spacing={0}>
                            <Grid item xs={12} >
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b>Description</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    {this.props.data.description}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                    variant="caption" />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b>Cost Type</b>
                                </Typography>
                            </Grid>

                            {this.props.data.cost && this.props.data.cost.fixedCost && this.props.data.cost.costType === "Fixed" ?
                                <Grid item xs={3}><Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b> Amount </b>
                                </Typography>
                                </Grid> : null
                            }
                            {this.props.data.cost && this.props.data.cost.from && this.props.data.cost.costType === "Approx" ?
                                <Grid item xs={3}><Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b> From</b>
                                </Typography>
                                </Grid> : null
                            }
                            {this.props.data.cost && this.props.data.cost.to && this.props.data.cost.costType === "Approx" ?
                                <Grid item xs={3}><Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b> To</b>
                                </Typography>
                                </Grid> : null
                            }
                             {this.props.data.cost && this.props.data.cost.costType === "Fixed" ?
                                <Grid item xs={3}><Typography variant="subtitle1" color="inherit" gutterBottom >
                                <b>Quantity</b>
                            </Typography>
                            </Grid> : null
                            }

                            <Grid item xs={3}><Typography variant="subtitle1" color="inherit" gutterBottom >
                                <b>Unit</b>
                            </Typography>
                            </Grid>
                            

                            <Grid item xs={3}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    {this.props.data.cost && this.props.data.cost.costType ? this.props.data.cost.costType : null}
                                </Typography>
                            </Grid>

                            {this.props.data.cost && this.props.data.cost.fixedCost && this.props.data.cost.costType === "Fixed" ?
                                <Grid item xs={3}><Typography variant="subtitle1" color="inherit" gutterBottom >
                                    {this.props.data.cost.fixedCost}
                                </Typography>
                                </Grid> : null
                            }
                            {this.props.data.cost && this.props.data.cost.from && this.props.data.cost.costType === "Approx" ?
                                <Grid item xs={3}><Typography variant="subtitle1" color="inherit" gutterBottom >
                                    {this.props.data.cost.from}
                                </Typography>
                                </Grid> : null
                            }
                            {this.props.data.cost && this.props.data.cost.to && this.props.data.cost.costType === "Approx" ?
                                <Grid item xs={3}><Typography variant="subtitle1" color="inherit" gutterBottom >
                                    {this.props.data.cost.to}
                                </Typography>
                                </Grid> : null
                            }
                             {this.props.data.cost && this.props.data.cost.costType === "Fixed" ?
                            <Grid item xs={3}><Typography variant="subtitle1" color="inherit" gutterBottom >
                                {this.props.data.cost && this.props.data.cost.quantity ? this.props.data.cost.quantity : "NA"}
                            </Typography>
                            </Grid>:null
                            }
                            <Grid item xs={3}><Typography variant="subtitle1" color="inherit" gutterBottom >
                                {this.props.data.cost && this.props.data.cost.unit ? this.props.data.cost.unit : null}
                            </Typography>
                            </Grid>
                            {this.props.data.cost && this.props.data.cost.costType === "Fixed" ?
                            <Grid item xs={12} >
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                <b> Allow sale in loose quantity</b>
                                </Typography>
                            </Grid>:null}
                            {this.props.data.cost && this.props.data.cost.costType === "Fixed" ?
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                {this.props.data.allowLooseQuantity ? "Yes" : "No"}
                                </Typography>
                            </Grid>: null
    }
                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                    variant="caption" />
                            </Grid>

                            {this.props.data.cost && this.props.data.cost.costType === "Fixed" && this.props.data.cost.gstConf && this.hasGstFields() ?
                                <Grid container direction="column">
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle1" color="inherit" gutterBottom >
                                            <b>GST Details</b>
                                        </Typography>
                                    </Grid>
                                    {this.props.data.cost.gstConf.gst!==null && this.props.data.cost.gstConf.gst !== 0 && <Grid item xs={6}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', width: '80%' }}>
                                            <Typography variant="subtitle1" color="inherit"> GST {this.props.data.cost.gstConf.gst + '%'} </Typography>
                                            <Typography variant="subtitle1" color="inherit">  {'₹ ' + ((this.props.data.cost.gstConf.gst / 100) * this.props.data.cost.fixedCost).toFixed(2)} </Typography>
                                        </div>
                                    </Grid>}
                                    {this.props.data.cost.gstConf.cgst!==null && this.props.data.cost.gstConf.cgst !== 0 && <Grid item xs={6}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', width: '80%' }}>
                                            <Typography variant="subtitle1" color="inherit"> CGST {this.props.data.cost.gstConf.cgst + '%'} </Typography>
                                            <Typography variant="subtitle1" color="inherit">  {'₹ ' + ((this.props.data.cost.gstConf.cgst / 100) * this.props.data.cost.fixedCost).toFixed(2)} </Typography>
                                        </div>
                                    </Grid>}
                                    {this.props.data.cost.gstConf.sgst!==null && this.props.data.cost.gstConf.sgst !== 0 && <Grid item xs={6}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', width: '80%' }}>
                                            <Typography variant="subtitle1" color="inherit"> SGST {this.props.data.cost.gstConf.sgst + '%'} </Typography>
                                            <Typography variant="subtitle1" color="inherit">  {'₹ ' + ((this.props.data.cost.gstConf.sgst / 100) * this.props.data.cost.fixedCost).toFixed(2)} </Typography>
                                        </div>
                                    </Grid>}
                                    
                                    {this.props.data.cost.gstConf.igst!==null && this.props.data.cost.gstConf.igst !== 0 && <Grid item xs={6}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', width: '80%' }}>
                                            <Typography variant="subtitle1" color="inherit"> IGST {this.props.data.cost.gstConf.igst + '%'} </Typography>
                                            <Typography variant="subtitle1" color="inherit">  {'₹ ' + ((this.props.data.cost.gstConf.igst / 100) * this.props.data.cost.fixedCost).toFixed(2)} </Typography>
                                        </div>
                                    </Grid>}
                                    <Grid item xs={6}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', width: '80%' }}>
                                            <Typography variant="subtitle1" color="inherit"> Final Cost : </Typography>
                                            <Typography variant="subtitle1" color="inherit">  {'₹ ' + finalAmount} </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                                : null
                            }

                            
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b>Item Tag </b>
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b>Exclude Item Tags </b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    {this.props.data.mustTry !== undefined && this.props.data.mustTry === true ? "Must Try" : ( this.props.data.newArrival !== undefined && this.props.data.newArrival === true ? "New" : ( this.props.data.bestSeller !== undefined && this.props.data.bestSeller ? "Best Seller" : "NA" ))}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    {this.props.data.excludeItemTags !== undefined && this.props.data.excludeItemTags === true ? "Enabled" : "Disabled"}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}></Grid>

                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                    variant="caption" />
                            </Grid>

                            <Grid item xs={12}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b>Item Packaging Charges </b>
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >

                                    {this.props.data.servicesPackagingCharges  ? ( " ₹ " + this.props.data.servicesPackagingCharges) : (" ₹ " + 0)}
                                </Typography>
                            </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                    variant="caption" />
                            </Grid>
                            <Grid item xs={12}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b>Discounts </b>
                                </Typography>
                            </Grid>
                          {
                             activeDiscount && activeDiscount.isActive === true && activeDiscount.discountType == 'FLAT' ? 
                                <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Discount Type</TableCell>
                                            <TableCell align="left" style={{paddingRight:"100px"}}>Off</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                            <TableRow >
                                                <TableCell align="left">
                                                    {activeDiscount && activeDiscount.isActive === true && activeDiscount.discountType}
                                                </TableCell>
                                                <TableCell align="left" style={{marginRight:"65px"}}>{activeDiscount && activeDiscount.isActive === true && activeDiscount.off}</TableCell>
                                            </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                                : 
                             activeDiscount && activeDiscount.isActive === true && activeDiscount.discountType == 'PERCENTAGE' ?
                            <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Discount Type</TableCell>
                                            {
                                                activeDiscount && activeDiscount.isActive === true && activeDiscount.maxDiscountCap ? <TableCell align="left" style={{paddingRight:"60px"}}>off</TableCell> :
                                                <TableCell align="left" style={{paddingRight:"100px"}}>off</TableCell>
                                            }
                                            {/* <TableCell align="left" style={{paddingRight:"60px"}}>off</TableCell> */}
                                            <TableCell align="left" >{activeDiscount && activeDiscount.isActive === true && activeDiscount.maxDiscountCap ? "max" : null}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                        <TableBody>
                                        <TableRow >
                                                <TableCell align="left">
                                                    {activeDiscount && activeDiscount.isActive === true && activeDiscount.discountType}
                                                </TableCell>
                                                <TableCell align="left" style={{marginRight:"65px"}}>{activeDiscount && activeDiscount.isActive === true && activeDiscount.off}</TableCell>
                                                <TableCell align="left">{activeDiscount && activeDiscount.isActive === true && activeDiscount.maxDiscountCap}</TableCell>
                                            </TableRow>
                                           
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : "No Offer"
                          }
                            </Grid>

                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                    variant="caption" />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b> Heads/ Sub-Heads </b>
                                </Typography>
                            </Grid>
                            <Grid item xs={9}></Grid>
                            {this.props.data.heads ?
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Head</TableCell>
                                                <TableCell align="left">Sub-Head</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.props.data.heads.map((head, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="left">
                                                        {/* <Grid item xs={5}> */}
                                                        {head.name}
                                                        {/* </Grid> */}
                                                    </TableCell>
                                                    <TableCell align="left">{this.props.data.subHeads[index] && this.props.data.subHeads[index].name ? this.props.data.subHeads[index].name : null}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : null}

                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                    variant="caption" />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b> Timings </b>
                                </Typography>
                            </Grid>
                            <Grid item xs={9}></Grid>
                            {this.props.data.availability && (this.props.data.availability.holidays || this.props.data.availability.workingHrs) ?
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Day</TableCell>
                                                <TableCell align="left">From</TableCell>
                                                <TableCell align="left">To</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.props.data.availability.workingHrs.map((day, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="left">{day.day}</TableCell>
                                                    <TableCell align="left">{day.from}</TableCell>
                                                    <TableCell align="left">{day.to}</TableCell>
                                                </TableRow>
                                            ))}
                                            {this.props.data.availability.holidays.map((day, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="left">{day}</TableCell>
                                                    <TableCell align="left">CLOSED</TableCell>
                                                    <TableCell align="left"></TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : null}

                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                    variant="caption" />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b> Contact Person </b>
                                </Typography>
                            </Grid>
                            <Grid item xs={9}></Grid>
                            {this.props.data.contactId ?
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell align="left">{this.props.data.contactId.firstName + " " + this.props.data.contactId.lastName}</TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : null}
                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                    variant="caption" />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" color="inherit" gutterBottom >
                                    <b> Photos </b>
                                </Typography>
                            </Grid>
                            <Grid item xs={9}></Grid>
                            <Grid item xs={12}>
                                {this.props.data.photos ?
                                    <GridList cols={7} cellHeight={80} className={classes.gridList}>
                                        {this.props.data.photos.map(img => (
                                            <GridListTile
                                                className={classes.gridElement} key={img._id}>
                                                <a href={img.path} target="_blank" rel="noopener noreferrer">
                                                    <img border="0" className={classes.img} src={img.path} alt="" />
                                                </a>
                                            </GridListTile>
                                        ))
                                        }
                                    </GridList > : <Typography>
                                        No Photos Available
                                    </Typography>}
                            </Grid>
                            <Grid item xs={12}>
                                <Divider color="textSecondary"
                                    style={{ marginBottom: "5px", marginTop: "10px" }}
                                    display="block"
                                    variant="caption" />
                            </Grid>
                            <Grid item xs={9}></Grid>
                            <Grid item xs={3}>
                                <Button
                                    fullWidth
                                    margin="normal"
                                    style={{ float: "right", margin: "5px" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.close}
                                >OK
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Modal >
        );
    }
}
const mapStateToProps = (state) => {

    return {
        dashboard: state.dashboard,
        business: state.business,
        user: state.auth.user,
        admin: state.admin,
        categories: state.categories,
        env: state.auth.env
    };
};
export default connect(mapStateToProps, {

})(withStyles(styles)(ShowService));