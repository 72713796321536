import axios from "axios";
import {

    GET_ADDONLIST_AG,
    CREATE_ADDON_GROUP_SUCCESS,
    CREATE_ADDON_GROUP_ERROR,
    GET_ALL_ADDON_GROUP_RECORDS,
    ADDON_GROUP_BULKUPLOAD_SUCCESS_MESSAGE,
    ADDON_GROUP_BULKUPLOAD_SUCCESS_ERROR,
    REQUEST_ADDON_GROUP_DOWNLOAD_TOEXCEL,
    UPLOAD_ADDON_GROUP,
    ADDON_GROUP_DOWNLOAD_TOEXCEL_COMPLETED,
    UPDATE_ADDON_GROUP_RECORD_SUCCESS,
    UPDATE_ADDON_GROUP_RECORD_ERROR,
    RESTORE_ADDONS_RECORDS_SUCCESS,
    RESTORE_ADDONS_RECORDS_ERROR,
    ADDON_GROUP_DELETION_ERROR,
    ADDON_GROUP_DELETION_SUCCESS,
    ADDON_GROUP_RECORDS_TOLINK,
    LINK_ADDON_GROUP_TOITEM_SUCCESS,
    LINK_ADDON_GROUP_TOITEM_ERROR,
    UNLINK_ADDON_GROUP_FROM_ITEM_SUCCESS,
    UNLINK_ADDON_GROUP_FROM_ITEM_ERROR,
    GET_ADDONGROUPS_TO_UPDATE_LINKEDITEM
  
} from "./types";

export const clearAddonGroupMsg = () => async dispatch => {  
  let data = {};
  data.msg = null;
  data.ops = null;
  dispatch({ type: CREATE_ADDON_GROUP_SUCCESS, payload: data });
  dispatch({ type: CREATE_ADDON_GROUP_ERROR, payload: data });
  dispatch({ type: ADDON_GROUP_BULKUPLOAD_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: ADDON_GROUP_BULKUPLOAD_SUCCESS_ERROR, payload: data });
  dispatch({ type: UPDATE_ADDON_GROUP_RECORD_SUCCESS, payload: data });
  dispatch({ type: UPDATE_ADDON_GROUP_RECORD_ERROR, payload: data });
  dispatch({ type: RESTORE_ADDONS_RECORDS_SUCCESS, payload: data });
  dispatch({ type: RESTORE_ADDONS_RECORDS_ERROR, payload: data });
  dispatch({ type: ADDON_GROUP_DELETION_ERROR, payload: data });
  dispatch({ type: ADDON_GROUP_DELETION_SUCCESS, payload: data });
  dispatch({ type: ADDON_GROUP_RECORDS_TOLINK, payload: data });
  dispatch({ type: LINK_ADDON_GROUP_TOITEM_SUCCESS, payload: data });
  dispatch({ type: LINK_ADDON_GROUP_TOITEM_ERROR, payload: data });
  dispatch({ type: UNLINK_ADDON_GROUP_FROM_ITEM_SUCCESS, payload: data });
  dispatch({ type: UNLINK_ADDON_GROUP_FROM_ITEM_ERROR, payload: data });
 
};

export const getAllAddonList = (payload) => async (dispatch) => {
    try {
      const response = await axios.get("/api/addonGroup/addonlist/" + payload.id , {
        params: payload,
      });
      dispatch({ type: GET_ADDONLIST_AG, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_ADDONLIST_AG, payload: error });
    }
};

export const createAddonGroup = (payload) => async (dispatch) => {

  try {
    let response = await axios.post("/api/addonGroup/createAddonGroup", payload);
    dispatch({ type: CREATE_ADDON_GROUP_SUCCESS, payload: { ops: "ADD", msg: "Addon  Group Created Succesfully." } });
  } catch (err) {
    dispatch({ type: CREATE_ADDON_GROUP_ERROR, payload: { ops: "ADD", msg: "Failed to Create Addon Group" } });
  }

  
};

export const getAllAddOnGroups = (payload) =>  async dispatch => {
  try {
    const response = await axios.get("/api/addonGroup/addonGroupRecords/" + payload.id , {
      params: payload,
    });
    dispatch({ type: GET_ALL_ADDON_GROUP_RECORDS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_ALL_ADDON_GROUP_RECORDS, payload: error });
  }
};

export const uploadAddOnGroup = (payload) => async (dispatch) => {
  try {
    dispatch({ type: UPLOAD_ADDON_GROUP, payload: true });

    const formData = new FormData();
    formData.append("file", payload.file);
    await axios.post(
      `/api/addonGroup/excel/upload/addOnGroups/${payload.businessId}`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    dispatch({ type: UPLOAD_ADDON_GROUP, payload: false });
    dispatch({
      type: ADDON_GROUP_BULKUPLOAD_SUCCESS_MESSAGE,
      payload: { ops: "Bulk Upload for addOnGroup", msg: "Addon group uploaded successfully." },
    });
  } catch (err) {
    dispatch({ type: UPLOAD_ADDON_GROUP, payload: false });
    dispatch({
      type: ADDON_GROUP_BULKUPLOAD_SUCCESS_ERROR,
      payload: { ops: "Bulk Upload of addOnGroup", msg: "Failed to upload AddOnGroup." },
    });
  }
};

export const downloadAddOnGroup = (payload) => async (dispatch) => {
 
  dispatch({ type:  REQUEST_ADDON_GROUP_DOWNLOAD_TOEXCEL});
  const response = await axios.get("/api/addonGroup/excel/download/addOnGroups/" + payload.businessId,
  { params: payload.data });
  dispatch({ type: ADDON_GROUP_DOWNLOAD_TOEXCEL_COMPLETED, payload: response.data.exportToExcel });
  
};
export const updateAddonGroup = (payload) => async (dispatch) => {
  try {
    const response = await axios.patch("/api/addonGroup/update/addonGroupRecord/" + payload.addonGroupId , payload);
    dispatch({ type: UPDATE_ADDON_GROUP_RECORD_SUCCESS, payload: { ops: "ADD", msg: "Addon  Group Updated Succesfully." } });
  } catch (error) {
    dispatch({ type: UPDATE_ADDON_GROUP_RECORD_ERROR, payload: { ops: "ADD", msg: "Failed to Update Addon Group" } });
  }
};
export const restoreAddons = (payload) => async (dispatch) => {
  try {
    const response = await axios.patch("/api/addonGroup/restore/addons", payload);
    dispatch({ type: RESTORE_ADDONS_RECORDS_SUCCESS, payload: { ops: "ADD", msg: "Addons Restored Successfully." } });
  } catch (error) {
    dispatch({ type: RESTORE_ADDONS_RECORDS_ERROR, payload: { ops: "ADD", msg: "Failed to Restore Addons" } });
  }
};
export const deleteAddonGroup = (payload) => async (dispatch) => {
  try {
    const response = await axios.patch("/api/addonGroup/deleteAddonGroup/business/" + payload.businessId, payload);
    dispatch({ type: ADDON_GROUP_DELETION_SUCCESS, payload: { ops: "ADD", msg: "Addons Group Deleted Successfully." } });
  } catch (error) {
    dispatch({ type: ADDON_GROUP_DELETION_ERROR, payload: { ops: "ADD", msg: "Failed to Deleted Addon Group" } });
  }
};
export const fetchAddonGroupToLink = (payload) =>  async dispatch => {
  try {
    const response = await axios.get("/api/addonGroup/link/addonGroupRecords/" + payload.businessId , {
      params: payload,
    });
    dispatch({ type: ADDON_GROUP_RECORDS_TOLINK, payload: response.data });
  } catch (error) {
    dispatch({ type: ADDON_GROUP_RECORDS_TOLINK, payload: error });
  }
};
export const linkAddonGroupToItem = (payload) => async (dispatch) => {

  try {
    let response = await axios.patch("/api/addonGroup/linkAddonGroup/item/", payload);
    dispatch({ type: LINK_ADDON_GROUP_TOITEM_SUCCESS, payload: { ops: "ADD", msg: "Addon Group Linked Succesfully." } });
  } catch (err) {
    {
      dispatch({ type: LINK_ADDON_GROUP_TOITEM_ERROR, payload: { ops: "ADD", msg: "Failed to Link Addon Group" } });
    }
  }

  
};

export const unLinkItemFromAddonGroup = (payload) => async (dispatch) => {
  try {
    const response = await axios.patch("/api/addonGroup/unLinkItems/addonGroup/", payload);
    dispatch({type: UNLINK_ADDON_GROUP_FROM_ITEM_SUCCESS,  payload: { ops: "ADD", msg: "Items Unlinked Successfully." }});
     
  } catch (error) {
    dispatch({type: UNLINK_ADDON_GROUP_FROM_ITEM_ERROR,  payload: { ops: "ADD", msg: "Failed To Unlink  Items" }});

  }
}
export const updateGroupLinkedItem = (payload) => async (dispatch) => {
  try {
    const response = await axios.get("/api/addonGroup/updateGroupLinkedItem/addonGroups/" + payload.businessId , {
      params: payload,
    });
    dispatch({ type: GET_ADDONGROUPS_TO_UPDATE_LINKEDITEM, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_ADDONGROUPS_TO_UPDATE_LINKEDITEM, payload: error });
  }
};
