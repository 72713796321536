import React from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from "@material-ui/core/Checkbox";
import TextField from '@material-ui/core/TextField'
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from "@material-ui/core/styles";



export function EnhancedTableToolbar(props) {

    const { onSelectAllClick, totalCount, numSelectedItems, showUnselectedRecords, showSelectedRecords , selectedCount ,unSelectedCount ,isSelectAllChecked } = props;

    return (
        <Toolbar style={{ backgroundColor: "#e3e3e3", display: "flex!important" }}>

            <Grid style={{ padding: "0px" }}>
                <Typography align="left">
                    <Checkbox
                        indeterminate={numSelectedItems > 0 && (numSelectedItems < totalCount && numSelectedItems !== totalCount)}
                        checked={numSelectedItems ? numSelectedItems : null}
                        onChange={onSelectAllClick}
                        onClick={(event)=> props.handleItemSelectCount(event)}
                        inputProps={{ "aria-label": "select all desserts" }}
                    />
                    Select All
                </Typography>

            </Grid>

            {showSelectedRecords === false ?
                <Grid style={{ padding: "0px 0px 0px 90px" }}>

                    <Typography >
                        <Checkbox
                            onChange={() => { props.handleShowUnselected(!props.showUnselectedRecords) }}
                            checked={showUnselectedRecords}

                        />
                    Show Unselected({   unSelectedCount > 0  || isSelectAllChecked === true ? unSelectedCount  :  ( props.totalCount > 0  ? props.totalCount : `00`)})
                    </Typography>

                </Grid> : null}


            {showUnselectedRecords === false ?

                <Grid style={{ padding: "0px 0px 0px 90px" }}>
                    <Typography >
                        <Checkbox
                            onChange={() => { props.handleShowSelected(!props.showSelectedRecords) }}
                            checked={showSelectedRecords}
                        />
                        Show Selected({ selectedCount > 0  ? selectedCount :`00`})
                    </Typography>

                </Grid>

                : null
            }


            <Grid>
                <Typography style={{ padding: "0px 0px 2px 100px" }}>

                    Results Found({props.totalCount})
                </Typography>
            </Grid>
        </Toolbar>
    );
}

export function EnhancedTableHead(props) {

    return (
        <TableHead style={{ backgroundColor: "white" }}>
            <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>Item Id</TableCell>
                <TableCell>Item Name</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Item Tag</TableCell>
                <TableCell>Food Type</TableCell>
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '50%',
        height: "fit-content",
        backgroundColor: "white",
        overflow: "hidden",
        flexGrow: 1,
        border: "2px solid #3d8bc3",
        borderRadius: "10px!important"
    },
    dynamicForm: {
        width: '50%',
        maxHeight: "fit-screen",
        backgroundColor: "white",
        overflow: "auto",
        flexGrow: 1,
        border: "2px solid #3d8bc3",
        borderRadius: "10px!important"
    },
    header: {
        height: '8%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    CloseIcon: {
        float: "right",
        color: "white"
    },
    headerText: {
        color: "white",
        fontSize: "20px",
        fontFamily: "serif"
    },

    footer: {
        height: '10%',
        display: 'flex',
        padding: "10px 0px 5px 630px"

    },

    searchBody: {
        fontFamily: "serif",
        padding: "10px 5px"
    },
    bodyMainContainer: {
        padding: "5px 4px"
    },
    cellPadding: {
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    paper: {
        minHeight: 96,
        textAlign: 'center',
        fontSize: 12,

    }



}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    let selectedItems = props.selected



    const isSelected = (_id) => {
        return props.selected.indexOf(_id) !== -1;
    };



    const handleItemClick = (event, _id) => {
        if (event.target.checked) {
            selectedItems.push(_id);
        } else {
            selectedItems = selectedItems.filter((id) => id !== _id);
        }
        props.setSelectedItems(selectedItems);
    };



    const handleSelectAllClick = async (event) => {
        if (event.target.checked) {
            const allSearchedItemIds = props.searchedRecords.map((n) => n._id);
            props.setSelectedItems(allSearchedItemIds);
            return;
        }
        props.setSelectedItems([]);
    };



    return (
        <div className={ props.searchedRecords && props.searchedRecords.length < 5 ? classes.form : classes.dynamicForm } >
            <div className={classes.appBarSpacer} >
                <Grid container className={classes.header} spacing={1}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom className={classes.headerText}>
                            Link Items To Variation Group
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.CloseIcon} onClick={() => { props.closeAddVariation() }} />
                    </Grid>
                </Grid>
                <Container className={classes.searchBody}>
                    <Typography style={{ marginLeft: "22px", fontWeight: "bold" }}>
                        Group Name : "{props.groupNameHeader}"
                    </Typography>
                    <Grid className={classes.bodyItemsContainer} >
                        <Grid className={classes.bodyMainContainer}>
                            <Grid className={classes.bodyMainContainer}>
                                <Typography style={{ marginLeft: "20px" }}>
                                    Search Items to Assign
                                </Typography>
                                <Container style={{ display: "flex!important" }}>
                                    <TextField margin="dense" name="itemId" value={props.itemId} variant="outlined" onChange={props.handleLinkFilterChange} placeholder="Item Id | Item Name" style={{ width: "80%" }} />
                                    <Button
                                        variant="contained"
                                        margin="normal"
                                        style={{ maxWidth: "50px", margin: "8px 0px 0px 10px" }}
                                        onClick={props.fetchItemsToLinkVariationGroup}
                                        color='primary'>
                                        Search
                                    </Button>
                                    <Button
                                        variant="contained"
                                        margin="normal"
                                        style={{ maxWidth: "50px", margin: "8px 0px 0px 10px" }}
                                        onClick={props.resetAddVariation}
                                        color='prdefaultmary'>
                                        Reset
                                    </Button>
                                </Container>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>

                {props.finalRecords && props.finalRecords.length ?

                    <Paper className={classes.paper}>
                        <EnhancedTableToolbar
                            totalCount={props.totalSearchedCount > 0 ? props.totalSearchedCount : "00"}
                            onSelectAllClick={handleSelectAllClick}
                            numSelectedItems={props.selected.length}
                            handleShowUnselected={props.handleShowUnselected}
                            handleShowSelected={props.handleShowSelected}
                            showUnselectedRecords={props.showUnselectedRecords}
                            showSelectedRecords={props.showSelectedRecords}
                            selected={props.selected}
                            finalCount={props.finalCount}
                            selectedCount={props.selectedCount}
                            unSelectedCount={props.unSelectedCount}
                            handleItemSelectCount={props.handleItemSelectCount}
                            isSelectAllChecked={props.isSelectAllChecked}
                        />
                        <TableContainer>
                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >
                                <EnhancedTableHead
                                   />
                                <TableBody>
                                    {props.searchedRecords && props.searchedRecords.length ? props.searchedRecords.map((row, index) => {

                                        let itemTags = row.excludeItemTags === true ? "NA" : ( row.mustTry === true ? "Must Try" : ( row.newArrival === true ? "New" : ( row.bestSeller ? "Best Seller" : "NA" ) ) );
                                        const isItemsSelected = isSelected(row._id);
                                        // console.log(`------- isItemsSelected>`, isItemsSelected)
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow
                                                hover
                                                className={classes.tableRow}
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <TableCell align='left' className={classes.cellPadding} style={{ paddingLeft: "25px" }} >
                                                    <Checkbox
                                                        onClick={(event) => handleItemClick(event, row._id)}
                                                        checked={isItemsSelected}
                                                        aria-checked={isItemsSelected}
                                                        selected={isItemsSelected}
                                                        inputProps={{ "aria-labelledby": labelId }}
                                                        onChange={(event)=> props.handleItemSelection(event , row._id)}
                                                    />
                                                </TableCell>
                                                <TableCell align="left" style={{ padding : '0px'}}>
                                                    {row.photos && row.photos[0] && row.photos[0].path ?
                                                        <Avatar src={row.photos[0].path} variant="square"  ></Avatar>
                                                        : <Avatar variant="square" src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.serviceId ? row.serviceId : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.service ? row.service : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.cost.fixedCost ? row.cost.fixedCost : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.cost.quantity ? row.cost.quantity : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.cost?.unit ? row.cost?.unit : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {itemTags}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.foodType ? row.foodType : "--"}
                                                </TableCell>

                                            </TableRow>
                                        )
                                    }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper> :  props.noRecordsFound === true ?  <Typography style={{ margin: "30px 300px" }}>No Results Found / Item exist in another group.</Typography> : null }



                <Container className={classes.footer} style={{ display: "flex", maxWidth: "100px" }}  >
                    <Button
                        fullWidth
                        variant="contained"
                        margin="normal"
                        color="default"
                        onClick={() => { props.closeAddVariation() }}
                        style={{ margin: "10px" }}
                    >
                        CANCEL
                    </Button>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        margin="normal"
                        style={{ margin: "10px" }}
                        onClick={() => { props.onAddVariationSubmit() }}
                        color="primary">
                        SAVE
                    </Button>

                </Container>
            </div>
        </div>
    );
}
