import React, { Component } from "react";
// import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';
import { ObjectID } from 'bson';
import {getBusinessById, updateBusiness } from "../../../actions/businessActions";
import Modal from '@material-ui/core/Modal';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import FormHelperText from "@material-ui/core/FormHelperText";
import moment from 'moment';
import { unique } from "underscore";


const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  lightBlue: {
    color: theme.palette.getContrastText(lightBlue[50]),
    backgroundColor: lightBlue[50],
    width: theme.spacing(4),
    height: theme.spacing(4),
    cursor: 'pointer'
  },
  mainContainer1: {
    width: '65%',
    height: '90%',
    backgroundColor: theme.palette.background.paper,
    padding: '0px 0px',

  },
  headGridTwo: {
    float: 'right',
    marginRight: '2px'
  },
  gridItem: {
    padding: "10px"
  },
  modalContainer3: {
    width: '100%',
    height: '80%',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    paddingLeft: "22px",
  },
  header3: {
    height: '7%',
    margin: '0px 0px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: "22px",
    backgroundColor: theme.palette.primary.main,
  },
  modaladd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight:"5px",
    width: "100%",
    height: "100%",
  },
  grid: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: "15px",
    paddingRight: "15px",
    margin: '0px 0px',
  },
  select: {
		height: '38px'
	},
});

const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF"
    }
  })(Typography);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
      PaperProps: {
          style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
          },
      },
  };

  // Commented below 2 functions as the main function call is commented out
/*
function timeCheck (from,to) {
  let a = from;
  let b = to;
  let fromCheck = a.split(":");
  let toCheck = b.split(":");
  let f = new Date(parseInt("2001",10),(parseInt("01",10))-1,parseInt("01",10),parseInt(fromCheck[0],10),parseInt(fromCheck[1],10));
  let t=new Date(parseInt("2001",10),(parseInt("01",10))-1,parseInt("01",10),parseInt(toCheck [0],10),parseInt(toCheck [1],10));
  let df=f.valueOf();
  let dt=t.valueOf();

  if(df<dt)
    return false;
  // {console.log("b is greater");}
  else 
    return true;
  // console.log("a is greater");
};

function convertTime12to24 (time12h){
  let convertedTime = moment(time12h, 'hh:mm A').format('HH:mm');
  return convertedTime;
};
*/

class EditBusinessTimings extends Component {
  constructor() {
    super();
    this.state = {
      editTimingModalOpen: false,
      fromError: [],
      toError: [],
      holidays: [],
      workingDays: [],
      days: [
        { index: 0, day: "Monday" }, { index: 1, day: "Tuesday" }, { index: 2, day: "Wednesday" }, { index: 3, day: "Thursday" }, { index: 4, day: "Friday" }, { index: 5, day: "Saturday" }, { index: 6, day: "Sunday" }
      ],
      // from: [
      //   {index: 0, from: "12:00 am"}, {index: 1, from: "01:00 am"}, {index: 2, from:"02:00 am"}, {index: 3, from:"03:00 am"}, {index: 4, from:"04:00 am"}, {index: 5, from:"05:00 am"}, {index: 6, from:"06:00 am"}, {index: 7, from:"07:00 am"}, {index: 8, from:"08:00 am"}, {index: 9, from:"09:00 am"}, {index: 10, from:"10:00 am"}, {index: 11, from:"11:00 am"}, {index: 12, from:"12:00 pm"}, {index: 13, from:"01:00 pm"}, {index: 14, from:"02:00 pm"}, {index: 15, from:"03:00 pm"}, {index: 16, from:"04:00 pm"}, {index: 17, from:"05:00 pm"}, {index: 18, from:"06:00 pm"}, {index: 19, from:"07:00 pm"}, {index: 20, from:"08:00 pm"}, {index: 21, from:"09:00 pm"}, {index: 22, from:"10:00 pm"}, {index: 23, from:"11:00 pm"}
      // ],
      from: [
        {index: 0, from: "12:00 am"}, {index: 1, from: "12:30 am"}, {index: 2, from: "01:00 am"}, {index: 3, from: "01:30 am"}, 
        {index: 4, from: "02:00 am"}, {index: 5, from: "02:30 am"}, {index: 6, from: "03:00 am"}, {index: 7, from: "03:30 am"}, 
        {index: 8, from: "04:00 am"}, {index: 9, from: "04:30 am"}, {index: 10, from: "05:00 am"}, {index: 11, from: "05:30 am"}, 
        {index: 12, from: "06:00 am"}, {index: 13, from: "06:30 am"}, {index: 14, from: "07:00 am"}, {index: 15, from: "07:30 am"}, 
        {index: 16, from: "08:00 am"}, {index: 17, from: "08:30 am"}, {index: 18, from: "09:00 am"}, {index: 19, from: "09:30 am"}, 
        {index: 20, from: "10:00 am"}, {index: 21, from: "10:30 am"}, {index: 22, from: "11:00 am"}, {index: 23, from: "11:30 am"}, 
        {index: 24, from: "12:00 pm"}, {index: 25, from: "12:30 pm"}, {index: 26, from: "01:00 pm"}, {index: 27, from: "01:30 pm"}, 
        {index: 28, from: "02:00 pm"}, {index: 29, from: "02:30 pm"}, {index: 30, from: "03:00 pm"}, {index: 31, from: "03:30 pm"}, 
        {index: 32, from: "04:00 pm"}, {index: 33, from: "04:30 pm"}, {index: 34, from: "05:00 pm"}, {index: 35, from: "05:30 pm"}, 
        {index: 36, from: "06:00 pm"}, {index: 37, from: "06:30 pm"}, {index: 38, from: "07:00 pm"}, {index: 39, from: "07:30 pm"}, 
        {index: 40, from: "08:00 pm"}, {index: 41, from: "08:30 pm"}, {index: 42, from: "09:00 pm"}, {index: 43, from: "09:30 pm"}, 
        {index: 44, from: "10:00 pm"}, {index: 45, from: "10:30 pm"}, {index: 46, from: "11:00 pm"}, {index: 47, from: "11:30 pm"},
      ],
      to: [
        {index: 0, from: "12:00 am"}, {index: 1, from: "12:30 am"}, {index: 2, from: "01:00 am"}, {index: 3, from: "01:30 am"}, 
        {index: 4, from: "02:00 am"}, {index: 5, from: "02:30 am"}, {index: 6, from: "03:00 am"}, {index: 7, from: "03:30 am"}, 
        {index: 8, from: "04:00 am"}, {index: 9, from: "04:30 am"}, {index: 10, from: "05:00 am"}, {index: 11, from: "05:30 am"}, 
        {index: 12, from: "06:00 am"}, {index: 13, from: "06:30 am"}, {index: 14, from: "07:00 am"}, {index: 15, from: "07:30 am"}, 
        {index: 16, from: "08:00 am"}, {index: 17, from: "08:30 am"}, {index: 18, from: "09:00 am"}, {index: 19, from: "09:30 am"}, 
        {index: 20, from: "10:00 am"}, {index: 21, from: "10:30 am"}, {index: 22, from: "11:00 am"}, {index: 23, from: "11:30 am"}, 
        {index: 24, from: "12:00 pm"}, {index: 25, from: "12:30 pm"}, {index: 26, from: "01:00 pm"}, {index: 27, from: "01:30 pm"}, 
        {index: 28, from: "02:00 pm"}, {index: 29, from: "02:30 pm"}, {index: 30, from: "03:00 pm"}, {index: 31, from: "03:30 pm"}, 
        {index: 32, from: "04:00 pm"}, {index: 33, from: "04:30 pm"}, {index: 34, from: "05:00 pm"}, {index: 35, from: "05:30 pm"}, 
        {index: 36, from: "06:00 pm"}, {index: 37, from: "06:30 pm"}, {index: 38, from: "07:00 pm"}, {index: 39, from: "07:30 pm"}, 
        {index: 40, from: "08:00 pm"}, {index: 41, from: "08:30 pm"}, {index: 42, from: "09:00 pm"}, {index: 43, from: "09:30 pm"}, 
        {index: 44, from: "10:00 pm"}, {index: 45, from: "10:30 pm"}, {index: 46, from: "11:00 pm"}, {index: 47, from: "11:30 pm"},
        {index: 48, from: "11:59 pm"}
      ],
      holidaysRequiredError: [],
      holidaysError: [],
      dayRequiredError: [],
      fromRequiredError: [],
      toRequiredError: [],
      invalidTime: [],
      holidayWorkingDayError: [],
      fromTimeOverlap: [],
      toTimeOverlap: [],
    };
  };

  componentDidMount() {
    this.editBusinessTimingsSet();
  };

  editBusinessTimingsSet = () => {
      this.setState({holidays: [],workingDays: []})
      let time = {};
      let wrkhrs = [];
      let id;
      let data = {};
      let holidays = [];
      this.props.data.businessDetails.availability.workingHrs.map((day) => {
        time = {
          _id: day._id,
          day: day.day,
          from: day.from,
          to: day.to,
        }
        wrkhrs.push(time)
      })
      this.props.data.businessDetails.availability.holidays.map((holiday) => {
        id = new ObjectID().toHexString();
        data = {
          _id: id,
          day: holiday
        }
        holidays.push(data)
      })
      this.setState({holidays: holidays,workingDays: wrkhrs})
  };

  addWorkingDay = () => {
		let workingDays = this.state.workingDays;
		const id = new ObjectID().toHexString();
		let day = {
			_id: id,
			day: "",
			from: "",
			to: ""
		}
		workingDays.push(day)
		this.setState({ workingDays})
	};

  addHoliday = () => {
		let holidays = this.state.holidays;
		const id = new ObjectID().toHexString();
		let data = {
			_id: id,
			day: ""
		}
		holidays.push(data)
		this.setState({ holidays})
	};

  handleChangeWorkingDay = (event) => {
      let exist = 0;
      let workingDays = this.state.workingDays;
      if (!exist) {
        for (var i = 0; i < workingDays.length; i++) {
          if (workingDays[i]._id === event.target.name._id) {
            workingDays[i].day = event.target.value
            let dayRequiredError = this.state.dayRequiredError;
            if(event.target.value.length>0){dayRequiredError[i]=false}
            else{dayRequiredError[i] = true;}
            this.setState({ workingDays, dayRequiredError})
          }
        }
      }
  };

  handleChangeWorkingDayFromTime = (event) => {
    let workingDays = this.state.workingDays;
    let fromRequiredError = this.state.fromRequiredError;
    let fromError = this.state.fromError;
    let fromTimeOverlap = this.state.fromTimeOverlap;
    
		for (var i = 0; i < workingDays.length; i++) {
			if (workingDays[i]._id === event.target.name._id) {
				workingDays[i].from = event.target.value;
        if(event.target.value.length>0){fromRequiredError[i]=false; fromError[i]=false; fromTimeOverlap[i]=false;}
        else{fromRequiredError[i] = true;}
			}
		}
		this.setState({ workingDays, fromRequiredError, fromError, fromTimeOverlap })
  };

  handleChangeWorkingDayToTime = (event) => {
		let workingDays = this.state.workingDays;
    let toRequiredError = this.state.toRequiredError;
    let toError = this.state.toError;
    let invalidTime = this.state.invalidTime;
    let toTimeOverlap = this.state.toTimeOverlap;

		for (var i = 0; i < workingDays.length; i++) {
			if (workingDays[i]._id === event.target.name._id) {
				workingDays[i].to = event.target.value;
        if(event.target.value.length>0){toRequiredError[i]=false; toError[i]=false; invalidTime[i]=false; toTimeOverlap[i]=false;}
        else{toRequiredError[i] = true;}
			}
		}
		this.setState({ workingDays, toRequiredError, toError, invalidTime, toTimeOverlap })
	};

  deleteWorkingHrs = (value) => {
    let workingDays = this.state.workingDays;
    let dayRequiredError = this.state.dayRequiredError;
    let fromRequiredError = this.state.fromRequiredError;
    let toRequiredError = this.state.toRequiredError;
    let fromError = this.state.fromError;
    let toError = this.state.toError;
    let invalidTime = this.state.invalidTime;

    //overlap timings validations are commented for now
    // let fromTimeOverlap = this.state.fromTimeOverlap;
    // let toTimeOverlap = this.state.toTimeOverlap;

    let index = workingDays.indexOf(value);

    workingDays = workingDays.filter((link) => link._id !== value._id);


    // dayRequiredError = dayRequiredError.splice(index,1);
    // fromRequiredError = fromRequiredError.splice(index,1);
    // toRequiredError = toRequiredError.splice(index,1);
    // fromError = fromError.splice(index,1);
    // toError = toError.splice(index,1);
    // invalidTime = invalidTime.splice(index,1);
    // fromTimeOverlap = fromTimeOverlap.splice(index,1);
    // toTimeOverlap = toTimeOverlap.splice(index,1);

    //After - to handle the Textbox error text
    delete dayRequiredError[index];
    delete fromRequiredError[index];
    delete toRequiredError[index];
    delete fromError[index];
    delete toError[index];
    delete invalidTime[index];

		this.setState({ workingDays, dayRequiredError, toRequiredError, fromRequiredError, toError, fromError, invalidTime }); //, fromTimeOverlap, toTimeOverlap
  };

  handleChangeHoliday = (event) => {
    let exist = 0;
		let holidays = this.state.holidays;
		if (!exist) {
			for (var i = 0; i < holidays.length; i++) {
				if (holidays[i]._id === event.target.name) {
					holidays[i].day = event.target.value
          let holidaysRequiredError = this.state.holidaysRequiredError;
          let holidaysError = this.state.holidaysError;
          let holidayWorkingDayError = this.state.holidayWorkingDayError;
          if(event.target.value.length>0){holidaysRequiredError[i]=false; holidaysError[i]=false; holidayWorkingDayError[i]=false;}
          else{holidaysRequiredError[i] = true;}
					this.setState({ holidays, holidaysRequiredError, holidayWorkingDayError})
				}
			}
		}
	};

  deleteHoliday = (value) => {
		let holidays = this.state.holidays;
		let holidaysRequiredError = this.state.holidaysRequiredError;
		let holidaysError = this.state.holidaysError;
		let holidayWorkingDayError = this.state.holidayWorkingDayError;

		let index = holidays.indexOf(value);

		holidays.splice(index, 1);
		holidaysRequiredError.splice(index, 1);
		holidaysError.splice(index, 1);
		holidayWorkingDayError.splice(index, 1);

		this.setState({ holidays, holidaysRequiredError, holidaysError, holidayWorkingDayError})
	};

  saveBusinessTimings = async () => {
    let hasError = false;
    let payload = {};
    // let convertedFrom;
    // let convertedTo;
    // let invalidToTimeCheck;

    let holidays = [];
    let holidaysRequiredError;
    let holidaysError;
    let dayRequiredError;
    let fromRequiredError;
    let toRequiredError;
    // let fromError;
    // let toError;
    // let invalidTime;
    let holidayWorkingDayError;
    // let fromTimeOverlap;
    // let toTimeOverlap;

    if(this.state.holidays && this.state.holidays.length>0){
      for(let i = 0; i < this.state.holidays.length; i++){
        holidays.push(this.state.holidays[i].day)
      }
      holidays = unique(holidays);
    }

    if(this.state.holidays.length>0){
      for(let k = 0; k < this.state.holidays.length; k++){
        //for required
        if(this.state.holidays[k].day.length===0){hasError = true; holidaysRequiredError = this.state.holidaysRequiredError; holidaysRequiredError[k] = true; this.setState({holidaysRequiredError}) }

        //to check duplicate holiday
        for(let j = k+1; j < this.state.holidays.length; j++) {
          if(this.state.holidays[k].day === this.state.holidays[j].day){hasError = true; holidaysError = this.state.holidaysError; holidaysError[j] = true; holidaysError[k] = true; this.setState({holidaysError}); }
        }
      }
    }

    if(this.state.workingDays && this.state.workingDays.length>0){
      for(let i = 0; i < this.state.workingDays.length; i++){
        // for required error
        if(this.state.workingDays[i].day.length===0){ hasError = true; dayRequiredError = this.state.dayRequiredError; dayRequiredError[i] = true; this.setState({dayRequiredError});}
        if(this.state.workingDays[i].from.length===0){ hasError = true; fromRequiredError = this.state.fromRequiredError; fromRequiredError[i] = true; this.setState({fromRequiredError});}
        if(this.state.workingDays[i].to.length===0){ hasError = true; toRequiredError = this.state.toRequiredError; toRequiredError[i] = true; this.setState({toRequiredError}); }

        // to check for invalid timing (to should be immediate future time)
        // if(this.state.workingDays[i].to.length>0 && this.state.workingDays[i].from.length>0){
        //   convertedFrom = convertTime12to24(this.state.workingDays[i].from);
        //   convertedTo = convertTime12to24(this.state.workingDays[i].to);
        //   invalidToTimeCheck = timeCheck(convertedFrom,convertedTo); //whenevr a is greater, show to time is invalid value
        //   if (invalidToTimeCheck) { hasError = true; invalidTime = this.state.invalidTime; invalidTime[i] = true; this.setState({invalidTime}); }
        // }

        //to check duplicate from and to timings
        // for(let j = i+1; j < this.state.workingDays.length; j++) {
        //   if(this.state.workingDays[i].day === this.state.workingDays[j].day){
        //     //to check duplicate from time
        //     if(this.state.workingDays[i].from === this.state.workingDays[j].from){ hasError = true; fromError = this.state.fromError; fromError[j] = true; fromError[i] = true; this.setState({fromError}); }

        //     //to check duplicate to time
        //     if(this.state.workingDays[i].to === this.state.workingDays[j].to){hasError = true; toError = this.state.toError; toError[j] = true; toError[i] = true; this.setState({toError}); }
        //   }
        // }

        //to check if working day and holiday is duplicate
        if(this.state.holidays.length>0){
          for(let k = 0; k < this.state.holidays.length; k++){
            if(this.state.workingDays[i].day === this.state.holidays[k].day){ hasError = true; holidayWorkingDayError = this.state.holidayWorkingDayError; holidayWorkingDayError[k] = true; this.setState({holidayWorkingDayError}); }
          }
        }
      }
    }

    // START
    // ----------- Code for Overlapping of business timings is commented -------------- (For time being - uncomment it later when new story related to business overlapping time is created)  --------------------------------
    // //to check for overlapping of time
    // if(this.state.workingDays && this.state.workingDays.length>1){
    //   for(let i=0; i<this.state.workingDays.length; i++){
    //     for(let j = i+1; j < this.state.workingDays.length; j++) {
    //       if(this.state.workingDays[i].day === this.state.workingDays[j].day){
    //         let convertedFromOverlap = convertTime12to24(this.state.workingDays[j].from);
    //         let convertedToOverlap = convertTime12to24(this.state.workingDays[j].to);
    //         let convertedToPreviousOverlap = convertTime12to24(this.state.workingDays[i].to);
    //         let convertedFromPreviousOverlap = convertTime12to24(this.state.workingDays[i].from);

    //         let fromInvalidTimeCheckWithFrom = timeCheck(convertedFromOverlap, convertedFromPreviousOverlap);
    //         let fromInvalidTimeCheckWithFrom1 = timeCheck(convertedFromPreviousOverlap,convertedFromOverlap);
    //         let fromInvalidTimeCheckWithTo = timeCheck(convertedToPreviousOverlap, convertedFromOverlap);
    //         let fromInvalidTimeCheckWithTo1 = timeCheck(convertedFromOverlap,convertedToPreviousOverlap);
    //         let toInvalidTimeCheckWithFrom = timeCheck(convertedToOverlap, convertedFromPreviousOverlap);
    //         let toInvalidTimeCheckWithFrom1 = timeCheck(convertedFromPreviousOverlap,convertedToOverlap);
    //         let toInvalidTimeCheckWithTo = timeCheck(convertedToPreviousOverlap, convertedToOverlap);
    //         let toInvalidTimeCheckWithTo1 = timeCheck(convertedToOverlap,convertedToPreviousOverlap);

    //         if(fromInvalidTimeCheckWithFrom && fromInvalidTimeCheckWithTo){ hasError = true; fromTimeOverlap = this.state.fromTimeOverlap; fromTimeOverlap[j] = true; this.setState({fromTimeOverlap}); }
    //         if(toInvalidTimeCheckWithFrom && toInvalidTimeCheckWithTo){ hasError = true; toTimeOverlap = this.state.toTimeOverlap; toTimeOverlap[j] = true; this.setState({toTimeOverlap}); }

    //         if(fromInvalidTimeCheckWithFrom1 && toInvalidTimeCheckWithTo1){ hasError = true; fromTimeOverlap = this.state.fromTimeOverlap; fromTimeOverlap[i] = true; toTimeOverlap = this.state.toTimeOverlap; toTimeOverlap[i] = true; this.setState({fromTimeOverlap, toTimeOverlap}); }
    //       }
    //     }
    //   }
    // }

    // ----------- Code for Overlapping of business timings is commented -------------- (For time being - uncomment it later when new story related to business overlapping time is created)  --------------------------------
    // END
      
    if(!hasError){
      payload = {
        businessId: this.props.data.businessDetails._id,
        data: {
          availability: {
            status: this.props.data.businessDetails.availability.status,
            workingHrs: this.state.workingDays,
            holidays: holidays,
            },
        },
      };

      this.props.onClose();
      await this.props.updateBusiness(payload);
      await this.props.getBusinessById(payload);
      this.setState({holidaysRequiredError:[], holidaysError:[], dayRequiredError: [], fromRequiredError: [], toRequiredError: [], fromError: [], toError: [], invalidTime: [], holidayWorkingDayError: [] }); // to be added for overlapping validation in setState - (, fromTimeOverlap: [], toTimeOverlap: [])
    }
  };

    render(){
        const { classes } = this.props;
        return(
            <Modal className={classes.modaladd} open={this.props.open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" closeAfterTransition >
              <Container className={classes.mainContainer1}>
                <Grid container className={classes.header3} spacing={1} >
                  <Grid item xs={8}><WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >Edit Business Timing</WhiteTextTypography></Grid>
                  <Grid item xs={4}>
                    <WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
                      <CloseIcon className={classes.headGridTwo} onClick={()=>this.props.onClose()} />
                    </WhiteTextTypography>
                  </Grid>
                </Grid>
                <Container className={classes.modalContainer3}>
                  <Grid container className={classes.grid} spacing={1} justify="center" alignItems="center" >
                    <Grid item xs={6} sm={2}> <Typography variant="subtitle2" gutterBottom><b>Timings</b></Typography> </Grid>
                    <Grid item xs={3} sm={5}>
                      <Button style={{ float: "right", fontSize: "12px", fontWeight: "bold" }} variant="outlined" htmlFor="photos" color="primary" component="span" onClick={this.addWorkingDay}> ADD </Button>
                    </Grid>
                    <Grid item xs={3} sm={5}>
                      <Button htmlFor="photos" color="primary" component="span" style={{ float: 'right', fontSize: "12px", fontWeight: "bold" }} variant="outlined" 
                        onClick={this.addHoliday} > ADD HOLIDAY </Button>
                    </Grid>

                    <Grid item xs={4}><Typography variant="subtitle2" gutterBottom style={{marginLeft:'7px'}}>{(this.state.workingDays && this.state.workingDays.length===0) && (this.state.holidays && this.state.holidays.length===0)?null:<b>Day/Days</b>}</Typography></Grid>
                    <Grid item xs={4}><Typography variant="subtitle2" gutterBottom style={{marginLeft:'7px'}}>{(this.state.workingDays && this.state.workingDays.length===0) && (this.state.holidays && this.state.holidays.length===0)?null:(this.state.workingDays && this.state.workingDays.length>0?<b>From</b>:null)} </Typography></Grid>
                    <Grid item xs={3}><Typography variant="subtitle2" gutterBottom style={{marginLeft:'7px'}}> {(this.state.workingDays && this.state.workingDays.length===0) && (this.state.holidays && this.state.holidays.length===0)?null:(this.state.workingDays && this.state.workingDays.length>0?<b>To</b>:null)}</Typography></Grid>
                    <Grid item xs={1}> <Typography variant="subtitle2" gutterBottom style={{marginLeft:'7px'}}></Typography> </Grid>

                    {this.state.workingDays.map((index,indexNo) => {
                        return (
                          <Grid container direction="row" key={"workingdays_"+index} justify="center" alignItems="center">

                             {/* To Display Day in dropdown */}
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth className={classes.formControl} error={this.state.dayRequiredError[indexNo]?true:false}>
                                <Select labelId="demo-simple-select-outlined-label" id="day" value={index.day} name={index} onChange={this.handleChangeWorkingDay} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                                  {this.state.days.map((index) => {
                                    return (
                                        <MenuItem value={index.day} style={{ minHeight: "28px", height: "28px" }}> <Typography noWrap>{index.day}</Typography> </MenuItem>
                                      )
                                    })}
                                </Select>
                                </FormControl>
                            </Grid>

                            {/* To Display 'From' Timings in 30min slots in dropdown */}
                            <Grid item xs={4}>
                              <FormControl variant="outlined" fullWidth className={classes.formControl} error={this.state.fromRequiredError[indexNo]?true:(this.state.fromError[indexNo]?true:(this.state.fromTimeOverlap[indexNo]?true:false))}>
                                <Select labelId="demo-simple-select-outlined-label" id="from" value={index.from} name={index} onChange={this.handleChangeWorkingDayFromTime} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                                  {this.state.from.map((index) => {
                                    return (
                                      <MenuItem value={index.from} style={{ minHeight: "28px", height: "28px" }}> <Typography noWrap>{index.from.toUpperCase()}</Typography> </MenuItem>
                                    )
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>

                            {/* To Display 'To Timings in 30min in dropdown - with last timing as 11:59pm */}
                            <Grid item xs={3}>
                              <FormControl variant="outlined" fullWidth className={classes.formControl} error = {this.state.toRequiredError[indexNo]?true:(this.state.toError[indexNo]?true:(this.state.invalidTime[indexNo]?true:(this.state.toTimeOverlap[indexNo]?true:false)))}>
                                <Select labelId="demo-simple-select-outlined-label" id="to" value={index.to} name={index} onChange={this.handleChangeWorkingDayToTime} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select}>
                                  {this.state.to.map((index) => {
                                    return (
                                      <MenuItem key={index} value={index.from} style={{ minHeight: "28px", height: "28px" }}><Typography noWrap>{index.from.toUpperCase()}</Typography> </MenuItem>
                                    )
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={1}>
                                <Button>
                                  <Avatar className={classes.lightBlue}>
                                      <DeleteIcon color="action" className={classes.deleteBtn} onClick={() => this.deleteWorkingHrs(index)} />
                                  </Avatar>
                                </Button>
                            </Grid>

                            <Grid item xs={4}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.dayRequiredError[indexNo]?"Required":null} </FormHelperText></Grid>
                            <Grid item xs={4}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px' }}> {this.state.fromRequiredError[indexNo]?"Required":(this.state.fromError[indexNo]?"Duplicate Timing":(this.state.fromTimeOverlap[indexNo]?"There is time overlap":null))} </FormHelperText></Grid>
                            <Grid item xs={3}> <FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px' }}> {this.state.toRequiredError[indexNo]?"Requried":(this.state.toError[indexNo]?"Duplicate Timing": ((this.state.invalidTime[indexNo]?"Invalid value":(this.state.toTimeOverlap[indexNo]?"There is time overlap":null))))}</FormHelperText></Grid>
                            <Grid item xs={1}></Grid>

                          </Grid>
                        )
                    })}

                    {this.state.holidays.map((index,indexNumber) => {
                        return (
                            <Grid container direction="row" key={index+index._id} justify="center" alignItems="center">
                            <Grid item xs={4}>
                              <FormControl variant="outlined" fullWidth error={this.state.holidaysRequiredError[indexNumber]?true:(this.state.holidaysError[indexNumber]?true:(this.state.holidayWorkingDayError[indexNumber]?true:false))}>
                                <Select labelId="Subhead-label" id="day" value={index.day} name={index._id} onChange={this.handleChangeHoliday} MenuProps={MenuProps} style={{ margin: "10px" }} className={classes.select} >
                                  {this.state.days.map((index) => {
                                    return (
                                      <MenuItem key={index} value={index.day} style={{ minHeight: "28px", height: "28px" }}><Typography noWrap>{index.day}</Typography> </MenuItem>
                                    )
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={3}><Typography variant="subtitle2" gutterBottom style={{marginLeft:"15px"}}> CLOSED </Typography></Grid>
                            <Grid item xs={4}>
                              <Button>
                                <Avatar className={classes.lightBlue}>
                                    <DeleteIcon color="action" className={classes.deleteBtn} onClick={() => this.deleteHoliday(index)} />
                                </Avatar>
                              </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={4}><FormHelperText style={{ color: "red", marginLeft:'10px', marginTop:'0px', marginBottom:'10px' }}>{this.state.holidaysRequiredError[indexNumber]?"Required":(this.state.holidaysError[indexNumber]?"Duplicate holiday":(this.state.holidayWorkingDayError[indexNumber]?"Working day cannot be a holiday":false))}</FormHelperText></Grid>
                            <Grid item xs={8}></Grid>
                            </Grid>
                        )
                    })}

                    <Grid item xs={12}> <Divider color="textSecondary" style={{ marginBottom: "5px", marginTop: "10px" }} display="block" /></Grid>
                    <Grid item xs={6} className={classes.gridItem} ></Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                      <Button variant="contained" component="span" onClick={()=>this.props.onClose()} style={{ margin: "5px" }} fullWidth> Cancel</Button>
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem} >
                      <Button variant="contained" color="primary" component="span" onClick={this.saveBusinessTimings} style={{ margin: "5px" }} fullWidth > Save </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Container>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
	return {
		business: state.business,
		user: state.auth.user,
		admin: state.admin
	};
};
export default connect(mapStateToProps, {updateBusiness,getBusinessById})(withStyles(styles)(EditBusinessTimings));