import axios from "axios";
import {
    SET_REGIONS,
    SET_REGION_DETAILS,
    SET_REGION_SUCCESS_MESSAGE,
    SET_REGION_ERROR_MESSAGE,
    REQUEST_REGIONS
} from "./types";

export const requestRegions = () => {
    return {
        type: REQUEST_REGIONS,
        payload: true
    };
};

export const clearMsg = () => async dispatch => {
    let data = {};
    data.msg = null;
    data.ops = null;
    dispatch({ type: SET_REGION_SUCCESS_MESSAGE, payload: data });
    dispatch({ type: SET_REGION_ERROR_MESSAGE, payload: data });
};

export const getAllRegions = (payload) => async dispatch => {
    try {
        let regionsData = await axios.get("/api/region/search", {
            params: payload
        });
        dispatch({ type: SET_REGIONS, payload: regionsData });
    } catch (err) {
        dispatch({ type: SET_REGION_ERROR_MESSAGE, payload: "Failed to get all regions." });
    }
};

export const searchRegions = (payload) => async dispatch => {
    try {
        let regionsData = await axios.get("/api/region/searchkey/search", {
            params: payload
        });
        dispatch({ type: SET_REGIONS, payload: regionsData });
    } catch (err) {
        dispatch({ type: SET_REGION_ERROR_MESSAGE, payload: "Failed to search regions." });
    }
};

export const addRegion = (payload) => async dispatch => {
    try {
        await axios.post("/api/region/", payload);
        dispatch({ type: SET_REGION_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Region added successfully." } });
    } catch (err) {
        dispatch({ type: SET_REGION_ERROR_MESSAGE, payload: { ops: "ADD", msg: err.response.data.error ? err.response.data.error : "Failed to add region." } });
    }
};

export const updateRegion = (payload) => async dispatch => {
    try {
        await axios.patch(`/api/region/${payload._id}`, payload);
        dispatch({ type: SET_REGION_SUCCESS_MESSAGE, payload: { ops: "UPDATE", msg: "Region updated successfully." } });
    } catch (err) {
        dispatch({ type: SET_REGION_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: "Failed to update region." } });
    }
};

export const getRegionDetailsById = (regionId) => async dispatch => {
    try {
        let regionDetails = await axios.get(`/api/region/${regionId}`);
        dispatch({ type: SET_REGION_DETAILS, payload: regionDetails.data });
    } catch (err) {
        dispatch({ type: SET_REGION_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: "Failed to update region." } });
    }
};
