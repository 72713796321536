import React, { Component } from 'react';
import { changeHeaderName, requestCallIconHitRecords,requestOpenCloseBusinessList, requestOpenCloseBusinessExportList, getOpenCloseBusinessExportList, deleteSelectedOpenCloseBusinessByIds, getOpenCloseBusinessList, getCallIconHitRecordsExportToExcel,clearMessage } from '../../actions/dashboardActions';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import BusinessOpenCloseTable from './BusinessOpenCloseTable';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from "@material-ui/core/Button";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Checkbox from '@material-ui/core/Checkbox';
import { getBusinessTagList} from "../../actions/businessTagAction";
const styles = theme => ({
	container: {
		width: '100%',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(4),
	},
    buttonContainer: {
		width: '100%',
		paddingTop: '10px'
	},
	content: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
	},
	appBarSpacer: theme.mixins.toolbar,
	formControl: {
		minWidth: 225,
		maxWidth: 300,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
});

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
class BusinessOpenCloseReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snackOpen: false,
            dateRange:"",
            filterStartDate: "",
            filterEndDate: "",
            page: 0,
            rowsPerPage: 10,
            rows: [],
			modalDeleteOpen: false,
            selectedOpenCloseBusinessIds: [],
            showDownloadIcon: false,
            order: -1,
            businessName: "",
            businessStatus:"",
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            businessTags:[],
            filteredBusinessTags:[]
           
        }
    }

    componentDidMount() {
        this.props.changeHeaderName("Business Open Close Logs");
        this.fetchFilter(); //resetFilter
        this.fetchBusinessTags();
    };
    
    componentDidUpdate(prevProps, prevState){
        if(this.props.dashboard.isFetching ===false){
        if (this.props.dashboard.deleteOpenCloseRecordsBusinessSuccessMsg) {
            this.setState({ sucessMsgDeleteOpenCloseBusinessRecords: this.props.dashboard.deleteOpenCloseRecordsBusinessSuccessMsg, snackOpen: true })
             this.props.clearMessage();
             
          }
          if (this.props.dashboard.deleteOpenCloseRecordsBusinessErrorMsg) {
            this.setState({ errorMsgDeleteOpenCloseBusinessRecords: this.props.dashboard.deleteOpenCloseRecordsBusinessErrorMsg, snackOpen: true })
            this.props.clearMessage()
          }
          //this.fetchFilter();
        }
    };

    resetFilter = async () => {
        this.setState({
            businessName: "",
            businessStatus:"",
            filterStartDate: "",
            filterEndDate: "",
            dateRange:"",
            page: 0,
            filteredBusinessTags:[]
        }, () => {
            this.fetchFilter();
        })
    };

    fetchFilter = async () => {
        this.props.requestOpenCloseBusinessList();
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
        let payload = {
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            businessName: this.state.businessName.trim(),
            businessStatus:this.state.businessStatus,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            filteredBusinessTags:filteredBusinessTagsVar          
        }
        this.props.getOpenCloseBusinessList(payload);
        this.setState({showDownloadIcon: false });
    };

    fetchCallIconHitRecordsExcel = async () => {
        this.props.requestOpenCloseBusinessExportList();
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
        let payload = {
            // callTimeStamp: this.state.callTimeStamp,
            businessName: this.state.businessName.trim(),
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            businessStatus:this.state.businessStatus,
            filteredBusinessTags:filteredBusinessTagsVar     
            }
        await this.props.getOpenCloseBusinessExportList(payload);
        this.setState({ showDownloadIcon: true });
    };

    fetchBusinessTags=async()=>{
        const response=await getBusinessTagList();
        if(response){
          this.setState({businessTags:response.data})
        }else{
          this.setState({businessTags:[]})
        }
        
    }

    handleFilterChange = (event) => {
        this.setState({page: 0})
        this.setState({ [event.target.name]: event.target.value });
    };

    setPage = (page) => {
        this.setState({ page }, () => this.fetchFilter());
    };

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage }, () => this.fetchFilter());
    };

    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        // picker.startDate.add(1, "days");
        this.setState({
            // filterStartDate: picker.startDate.format('DD MMM YYYY'),
            // filterEndDate: picker.endDate.format('DD MMM YYYY')
            filterStartDate: picker.startDate.format(),
            filterEndDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ dateRange: chosenLabel });
    };

    
    deleteOpen = () => {
		this.setState({ modalDeleteOpen: true });
	}

    deleteClose = () => {
		this.setState({ modalDeleteOpen: false, selectedOpenCloseBusinessIds: [] });
	}
    
    deleteSelectedOpenCloseBusinesses = async () => {
        // let payload = {
        //     OpenCloseBusinessByIds: this.state.selectedOpenCloseBusinessIds,
        //     message : 'Selected open close businesses deleted successfully!'
        //   }
		await this.props.deleteSelectedOpenCloseBusinessByIds(this.state.selectedOpenCloseBusinessIds);
		this.setState({ modalDeleteOpen: false });
         this.fetchFilter();
		this.setState({selectedOpenCloseBusinessIds: []});
	}

    setSelected = (selectedOpenCloseBusinessIds) => {
		this.setState({ selectedOpenCloseBusinessIds })
	}

    handleSnackClose = () => {
        this.setState({
          snackOpen: false,
          sucessMsgDeleteOpenCloseBusinessRecords:"",
          errorMsgDeleteOpenCloseBusinessRecords:""
        });
      };
    
    render() { 
        // console.log(this.props)
        const { classes } = this.props;
        let muiAlert = null;
        if (this.state.sucessMsgDeleteOpenCloseBusinessRecords) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
              {this.state.sucessMsgDeleteOpenCloseBusinessRecords}
            </MuiAlert>
          } else if (this.state.errorMsgDeleteOpenCloseBusinessRecords) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="info">
              {this.state.errorMsgDeleteOpenCloseBusinessRecords}
            </MuiAlert>
          }
        return (
            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container  className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography style={{ fontSize:'17px'}}>Search/Filter</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={1}>
                            <Grid item xs={3} >
                                <Typography variant="subtitle2" gutterBottom>
                                    Business Id / Name
                                    </Typography>
                                    <TextField fullWidth margin="dense" name="businessName" style={{ width: "225px" }} value={this.state.businessName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid> 
                                <Grid item xs={3}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Action Status
                                    </Typography>
                                    <FormControl fullWidth margin="dense" variant="outlined" className={classes.formControl}  style={{ width: "225px" }} >
                                        {/* <InputLabel id="delivery-partner-label">Business Status</InputLabel> */}
                                        <Select
                                            value={this.state.businessStatus}
                                            name="businessStatus"
                                            onChange={this.handleFilterChange}                                            
                                        >
                                            <MenuItem value="CLOSED">CLOSED</MenuItem>
                                            <MenuItem value="OPENED">OPENED</MenuItem>
                                            <MenuItem value="RESUMED">RESUMED</MenuItem>
                                            <MenuItem value="TO_BE_CLOSED">TO_BE_CLOSED</MenuItem>
                                            <MenuItem value="TO_BE_OPENED">TO_BE_OPENED</MenuItem>
                                            <MenuItem value="TO_BE_RESUMED">TO_BE_RESUMED</MenuItem>
                                            <MenuItem value="MANUALLY_OPENED">MANUALLY_OPENED</MenuItem>
                                           
                                        </Select>
                                    </FormControl>
                                </Grid>

                                    <Grid item xs={3}>
                                    <Typography variant="subtitle2" gutterBottom>
                                    Update Time (IST)
                                    </Typography>
                                        <DateRangePicker
                                            ranges={this.state.ranges}
                                            onApply={this.handleDateRangeChange}
                                            maxSpan={{ "months": 12 }}
                                            maxDate={moment()}
                                            showDropdowns
                                        >
                                            <TextField fullWidth margin="dense" name="updatedAt"  value={this.state.dateRange}  style={{ width: "225px" }} variant="outlined" />
                                        </DateRangePicker>
                                    </Grid> 
                                    <Grid item xs={3}>
                                    <Typography variant="subtitle2" gutterBottom>Business Tags</Typography>
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Business Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredBusinessTags}
                                            name="filteredBusinessTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {this.state. businessTags.map((eachBusinessTagObj) => (
                                                <MenuItem key={eachBusinessTagObj._id} value={eachBusinessTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredBusinessTags.indexOf(eachBusinessTagObj.name) > -1} />
                                                    <ListItemText primary={eachBusinessTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> 

                                <Grid item xs={9}></Grid> <Grid item xs={8}></Grid><Grid item xs={2}>
                                    <Button type="submit" fullWidth variant="contained" margin="normal" onClick={this.resetFilter}> Reset </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button type="submit" fullWidth variant="contained" margin="normal" color="primary" onClick={this.fetchFilter}> Search </Button>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                <Container className={classes.buttonContainer}>
					<Grid container spacing={3}>
						<Grid item xs={8}>
						</Grid>
						<Grid item xs={3}>
							{/* <Button
								style={{ float: "right", margin: "5px" }}
								variant="contained"
								color="primary"
								disabled={this.state.selectedCouponIds.length < 1}
								onClick={() => this.handleUpdateConfirmModal(true)}
							>
								Settle Coupons
							</Button> */}
						</Grid>
						<Grid item xs={1}>
							<Button
								style={{ float: "right"}}
								variant="contained"
								color="primary"
								disabled={this.state.selectedOpenCloseBusinessIds.length < 1}
								onClick={this.deleteOpen}
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</Container>

                <Container className={classes.container}>
                    {
                        this.props.dashboard.openCloseBusinessList && this.props.dashboard.openCloseBusinessList.data ?
                            <BusinessOpenCloseTable 
                                data={this.props.dashboard.openCloseBusinessList.data}
                                totalCount={this.props.dashboard.openCloseBusinessList.total}
                                exportToExcel={this.props.dashboard.openCloseBusinessExportList}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                setPage={this.setPage}
								setSelected={this.setSelected}
								selectedOpenCloseBusinessIds={this.state.selectedOpenCloseBusinessIds}
                                setRowsPerPage={this.setRowsPerPage}
                                showDownloadIcon={this.state.showDownloadIcon}
                                fetchCallIconHitRecordsExcel={this.fetchCallIconHitRecordsExcel}
                            />
                        : null
                    }
                </Container>
                
                <Container>
					<Dialog open={this.state.modalDeleteOpen} onClose={this.deleteClose}>
						<DialogTitle>Delete Record(s)</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Do you want to delete the record(s)?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.deleteClose} color="primary"> No </Button>
							<Button onClick={() => this.deleteSelectedOpenCloseBusinesses()} color="primary" autoFocus> Yes </Button>
						</DialogActions>
					</Dialog>
				</Container>
                <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
          ContentProps={{ 'aria-describedby': 'message-id' }}
        >
          {muiAlert}
        </Snackbar>
                
                <Backdrop className={classes.backdrop} open={this.props.dashboard.isFetching}> <CircularProgress color="inherit" /> </Backdrop>
               
            </Container>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        dashboard: state.dashboard,
    }
}
export default connect(mapStateToProps, { changeHeaderName, requestCallIconHitRecords, getOpenCloseBusinessExportList, deleteSelectedOpenCloseBusinessByIds, getOpenCloseBusinessList, requestOpenCloseBusinessList, requestOpenCloseBusinessExportList, getCallIconHitRecordsExportToExcel,clearMessage })(withStyles(styles)(BusinessOpenCloseReport));
