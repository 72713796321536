import { SIGN_IN, SIGN_OUT, RESET_PASSWORD, SET_ENV } from "../actions/types";

const INITIAL_STATE = {
  isAuthenticated: null,
  passwordReset: null,
  passResetErr: null,
  loginErr: null,
  user: {},
  env: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
        loginErr: action.payload.error
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: {}
      };
    case RESET_PASSWORD:
      return {
        ...state,
        passwordReset: action.payload.passwordReset,
        passResetErr: action.payload.error,
      };
    case SET_ENV:
      return {
        ...state,
        env: action.payload        
      };
    default:
      return state;
  }
}