import React from "react";
import Button from "@material-ui/core/Button";

const FileUploader = props => {
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        props.handleFile(fileUploaded);
    };
    return (
        <>
            <Button
                onClick={handleClick}
                style={{ float: "right", margin: "5px" }}
                variant="contained"
                color="primary"
            >
                {props.buttonName}
            </Button>
            <input type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
                accept=".zip"
            />
        </>
    );
};
export default FileUploader;