import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import StatisticCard from "./statisticCard/StatisticCard";
import {
  changeHeaderName,
  getXirifyStats,
} from "../../actions/dashboardActions";

const styles = (theme) => ({
  container: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    height: "100%",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.changeHeaderName("Dashboard");
    this.props.getXirifyStats();
  }

  render() {
    const { classes } = this.props;

    return (
      <Container className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container className={classes.container}>
          <Grid container spacing={4}>
            {this.props.user.role === "admin" ? (
              <Grid item xs={3}>
                <StatisticCard
                  title="Total Consumers"
                  num={this.props.dashboard.xirifyStats.total_consumers}
                  icon="total_consumer"
                />
              </Grid>
            ) : null}
            {this.props.user.role === "admin" ? (
              <Grid item xs={3}>
                <StatisticCard
                  title="Active Consumers"
                  num={this.props.dashboard.xirifyStats.consumerCount}
                  icon="consumer"
                />
              </Grid>
            ) : null}
            <Grid item xs={3}>
              <StatisticCard
                title="Service Requests"
                num={this.props.dashboard.xirifyStats.serviceReqCount}
                icon="servicerequest"
              />
            </Grid>
            <Grid item xs={3}>
              <StatisticCard
                title="Service Providers"
                num={this.props.dashboard.xirifyStats.serviceProviderCount}
                icon="serviceproviders"
              />
            </Grid>
            <Grid item xs={3}>
              <StatisticCard
                title="Businesses Pending"
                num={this.props.dashboard.xirifyStats.businessAwaitingCount}
                icon="pending"
              />
            </Grid>
            <Grid item xs={3}>
              <StatisticCard
                title="Businesses Rejected"
                num={this.props.dashboard.xirifyStats.businessRejectedCount}
                icon="rejected"
              />
            </Grid>
            <Grid item xs={3}>
              <StatisticCard
                title="Businesses Approved"
                num={this.props.dashboard.xirifyStats.businessApprovedCount}
                icon="approved"
              />
            </Grid>
            <Grid item xs={3}>
              <StatisticCard
                title="Businesses Disabled"
                num={this.props.dashboard.xirifyStats.businessDisabledCount}
                icon="disabled"
              />
            </Grid>
          </Grid>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { changeHeaderName, getXirifyStats })(
  withStyles(styles)(Dashboard)
);
