import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { changeHeaderName, requestBusinessStats, getAllReleases, addRelease, updateRelease, uploadQRCodeImage, getQRCodeUploadStatus, deleteQRCodeImage } from '../../actions/dashboardActions';
import { clearSucessMsg, clearErrorMsg } from '../../actions/adminActions';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import History from './History';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import HistoryIcon from '@material-ui/icons/History';
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import AddReleaseForm from './AddReleaseForm';
import EditReleaseForm from './EditReleaseForm';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  table: {
    minWidth: 650,
  },
  head: {
    fontWeight: 700
  },
  button: {
    marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(2),
    float: 'right'
  },
  header: {
    marginTop: theme.spacing(3)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px'
  },
  modalContainer: {
    width: '50%',
    height: '90%',
    backgroundColor: theme.palette.background.paper,
    padding: '0px 0px',
  },
  snackClose: {
    padding: theme.spacing(0.5),
  },
  tableRow: {
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});


class Release extends Component {

  constructor() {
    super();
    this.state = {
      modalOpen: false,
      snackOpen: false,
      historyModalOpen: false,
      editModalOpen: false,
      sucessMsg: null,
      errorMsg: null,
      // selectedFlushDataRelease: null,
      // toggleSwitchChange: false
    };
  };

  componentDidMount() {
    this.props.changeHeaderName("App Management");
    this.props.requestBusinessStats();
    this.props.getAllReleases();
    this.props.getQRCodeUploadStatus();
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleHistoryOpen = (release) => {
    this.setState({
      historyModalOpen: true,
      selectedRelease: release
    });
  };

  handleEditOpen = (release) => {
    release.releasedOn = moment(release.releasedOn).format('YYYY-MM-DD')
    this.setState({
      editModalOpen: true,
      selectedRelease: release
    });
  };

  handleEditModalClose = () => {
    this.setState({ editModalOpen: false });
  };

  handleHistoryClose = () => {
    this.setState({ historyModalOpen: false });
  };

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    this.setState({ modalOpen: false, editModalOpen: false });
    this.setState({ sucessMsg: null });
    this.setState({ errorMsg: null });
    // this.setState({ toggleSwitchChange: false, selectedFlushDataRelease: null });
    this.props.getAllReleases();
    this.props.getQRCodeUploadStatus();
  };

  addRelease = (props) => {
    props.createdBy = this.props.user.id;
    this.props.addRelease(props);
  };

  updateRelease = (props) => {
    // this.setState({ toggleSwitchChange: false, selectedFlushDataRelease: null})
    props.releasedOn = new Date(props.releasedOn);
    // props.isDataFlush = this.state.isDataFlushToggle;
    this.props.updateRelease(props);
  };

  onFileChange = event => {
    let type = "qrcodebusiness";
    if (event.target.name === "Consumer") {
      type = "qrcodeconsumer";
    }

    let payload = {
      type: type,
      file: event.target.files[0]
    }
    this.props.uploadQRCodeImage(payload);
  };

  deleteQRCode = event => {
    this.props.deleteQRCodeImage(event);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.admin.sucessMsg !== null) {
      this.setState({ sucessMsg: this.props.admin.sucessMsg })
      this.setState({ snackOpen: true });
      this.props.clearSucessMsg();
    } else if (this.props.admin.errorMsg !== null) {
      this.setState({ errorMsg: this.props.admin.errorMsg })
      this.setState({ snackOpen: true });
      this.props.clearErrorMsg();
    }
  };

  // handleIsDataFlushSwitchChange = async (event, data) => {
  //   let isDataFlushToggle = "";

  //   if (event.target.checked === true) {
  //       isDataFlushToggle = true
  //   }
  //   else {
  //       isDataFlushToggle = false
  //   }

  //   // data.isDataFlush = isDataFlushToggle;
  //   this.setState({ toggleSwitchChange: true, selectedFlushDataRelease: data });
  //   this.setState({ isDataFlushToggle: isDataFlushToggle });
  //   // this.updateRelease(data);
  // };

  // handleToggleSwitchClose = () => {
  //   this.setState({ toggleSwitchChange: false, selectedFlushDataRelease: null });
  // };

  render() {
    const { classes } = this.props;
    let muiAlert = null;

    if (this.state.sucessMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
        {this.state.sucessMsg}
      </MuiAlert>
    } else if (this.state.errorMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
        {this.state.errorMsg}
      </MuiAlert>
    }

    // console.log("Props == ", this.props);
    // console.log("state == ", this.state);

    return (
      <Container maxWidth="lg">
        <div className={classes.appBarSpacer} />
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Typography variant="h6" gutterBottom className={classes.header}>
                Manage Releases
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<AddIcon />}
                onClick={() => this.handleModalOpen()}
                style={{ float: "right" }}
              >
                Add Release
              </Button>
            </Grid>
          </Grid>

          <Modal
            className={classes.modal}
            open={this.state.modalOpen}
            onClose={this.handleModalClose}
            closeAfterTransition>
            <Container className={classes.modalContainer}>
              <AddReleaseForm onSubmit={this.addRelease} onClose={this.handleModalClose} />
            </Container>
          </Modal>
        </Container>
        <Container className={classes.container}>
          {
            this.props.dashboard.releases && this.props.dashboard.releases.length > 0 ?
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.head}>
                    <TableRow>
                      <TableCell>App Name</TableCell>
                      <TableCell>Platform</TableCell>
                      <TableCell>Version</TableCell>
                      <TableCell>Release Date</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Remark</TableCell>
                      <TableCell>Url</TableCell>
                      <TableCell>Force Upgrade</TableCell>
                      <TableCell>Clear App Data</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.dashboard.releases.map((release, index) => {
                      return (
                        <TableRow hover key={release._id} button="true">
                          <TableCell component="th" scope="row" align="left">
                            {release.appname}
                          </TableCell>
                          <TableCell>{release.platform}</TableCell>
                          <TableCell>{release.versioncode}</TableCell>
                          <TableCell>{moment(release.releasedOn).format('DD-MMM-YYYY')}</TableCell>
                          <TableCell>{release.createdBy ? release.createdBy.firstName : ''} {release.createdBy ? release.createdBy.lastName : ''}</TableCell>
                          <TableCell>{release.remark}</TableCell>
                          <TableCell>
                            <a href={release.appurl} target="_blank" rel="noopener noreferrer">
                              Open
                            </a>
                          </TableCell>
                          <TableCell>{release.force ? 'Yes' : 'No'}</TableCell>
                          <TableCell>{release?.isClearAppData ? 'Yes' : 'No'}</TableCell>
                          {/* <TableCell>
                            <FormControlLabel
                                control={
                                    <Switch
                                      onClick={(e) => this.handleIsDataFlushSwitchChange(e, release)}
                                      size="small"
                                      key={`isDataFlushSwitch_${release._id}_${index}`}
                                      checked={release?.isDataFlush}
                                      // onChange={this.props.user.role == "admin" ? (e) => this.handleIsDataFlushSwitchChange(e, release) : null }
                                      color="primary"
                                      
                                    />
                                  }
                                  style={{ fontSize: "10px", color: release?.isDataFlush ? 'On' : 'Off' }}
                                  label={release?.isDataFlush ? 'On' : 'Off'}
                            />
                          </TableCell> */}
                          <TableCell>
                            <HistoryIcon onClick={() => this.handleHistoryOpen(release)} /> &nbsp; &nbsp;
                            <EditIcon onClick={() => this.handleEditOpen(release)} />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              : "No releases found."
          }
        </Container>

        <Container>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Typography variant="h6" gutterBottom className={classes.header}>
                Manage QR Codes
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={classes.container}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell>App Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover button="true">
                  <TableCell component="th" scope="row" align="left">
                    Business
                  </TableCell>
                  <TableCell>{this.props.dashboard.QRCodeStatus && this.props.dashboard.QRCodeStatus.qrcodebusiness.uploadStatus ?
                    <img src={URL.createObjectURL(new Blob([this.props.dashboard.QRCodeStatus.qrcodebusiness.file], { type: 'image/jpeg' }))}
                      alt="Aadhar Card" width="80" height="80" /> : 'Not Uploaded'}
                  </TableCell>
                  {
                    this.props.dashboard.QRCodeStatus && this.props.dashboard.QRCodeStatus.qrcodebusiness.uploadStatus ?
                      <TableCell>
                        <DeleteIcon className={classes.tableRow} onClick={() => this.deleteQRCode("qrcodebusiness")} />
                      </TableCell>
                      : <TableCell>
                        <label htmlFor="myInput"><PublishIcon onClick={this.onFileUpload} className={classes.tableRow} /></label>
                        <input
                          id="myInput"
                          name="Business"
                          style={{ display: 'none' }}
                          type={"file"}
                          onChange={this.onFileChange}
                          accept="image/x-png,image/jpeg"
                        />
                      </TableCell>
                  }
                </TableRow>
                <TableRow hover button="true">
                  <TableCell component="th" scope="row" align="left">
                    Consumer
                  </TableCell>
                  <TableCell>{this.props.dashboard.QRCodeStatus && this.props.dashboard.QRCodeStatus.qrcodeconsumer.uploadStatus ?
                    <img src={URL.createObjectURL(new Blob([this.props.dashboard.QRCodeStatus.qrcodeconsumer.file], { type: 'image/jpeg' }))}
                      alt="Aadhar Card" width="80" height="80" /> : 'Not Uploaded'}
                  </TableCell>
                  {
                    this.props.dashboard.QRCodeStatus && this.props.dashboard.QRCodeStatus.qrcodeconsumer.uploadStatus ?
                      <TableCell>
                        <DeleteIcon className={classes.tableRow} onClick={() => this.deleteQRCode("qrcodeconsumer")} />
                      </TableCell>
                      : <TableCell>
                        <label htmlFor="myInput2"><PublishIcon onClick={this.onFileUpload} className={classes.tableRow} /></label>
                        <input
                          id="myInput2"
                          name="Consumer"
                          style={{ display: 'none' }}
                          type={"file"}
                          onChange={this.onFileChange}
                          accept="image/x-png,image/jpeg"
                        />
                      </TableCell>
                  }
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Container>

        <History
          release={this.state.selectedRelease}
          open={this.state.historyModalOpen}
          onClose={this.handleHistoryClose}
        />
        <Modal
          className={classes.modal}
          open={this.state.editModalOpen}
          onClose={this.handleEditModalClose}
          closeAfterTransition>
          <Container className={classes.modalContainer}>
            <EditReleaseForm
              initialValues={_.pick(this.state.selectedRelease, '_id', 'appname', 'platform', 'versioncode', 'force', 'appurl', 'releasedOn', 'remark', 'description', 'isClearAppData')}
              onSubmit={this.updateRelease}
              onClose={this.handleEditModalClose}
            />
          </Container>
        </Modal>

        {/* <Dialog open={this.state.toggleSwitchChange} onClose={this.handleToggleSwitchClose}>
          <DialogContent>
            <DialogContentText>
              Are you sure to {this.state?.isDataFlushToggle ? "ON" : "OFF"} the Data Flush?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleToggleSwitchClose} color="primary"> No </Button>
            <Button onClick={() => this.updateRelease(this.state.selectedFlushDataRelease)} color="primary" autoFocus> Yes </Button>
          </DialogActions>
        </Dialog> */}

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackOpen}
          autoHideDuration={2000}
          onClose={this.handleSnackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}>
          {muiAlert}
        </Snackbar>
        <Backdrop className={classes.backdrop} open={this.props.dashboard.isFetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}


const mapStateToProps = state => {
  return {
    dashboard: state.dashboard,
    user: state.auth.user,
    admin: state.admin
  }
};
export default connect(mapStateToProps, {
  changeHeaderName,
  requestBusinessStats,
  getAllReleases,
  addRelease,
  updateRelease,
  uploadQRCodeImage,
  getQRCodeUploadStatus,
  clearSucessMsg,
  clearErrorMsg,
  deleteQRCodeImage
})(withStyles(styles)(Release));