import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { lightBlue } from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography } from "@material-ui/core";

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    role,
    numSelected,
    numSelectedSubHead,
    onSelectAllClick,
    rowsPerPage,
    totalCount,
    onSelectAllClickSubHead,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {role === "areaManager" ? (
          <TableCell style={{ padding: "2px 2px 2px 9px" }}>
            <Checkbox
              indeterminate={
                numSelected > 0 && (numSelected < rowsPerPage && numSelected !== totalCount)
              }
              checked={numSelected ? numSelected : null}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        ) : (
          <TableCell> </TableCell>
        )}
        <TableCell>Head</TableCell>
        <TableCell></TableCell>
        {role === "areaManager" ? (
          <TableCell>
            <Checkbox
              indeterminate={
                numSelectedSubHead > 0 &&
                numSelectedSubHead < rowsPerPage &&
                numSelectedSubHead !== totalCount
              }
              checked={numSelectedSubHead ? numSelectedSubHead : null}
              onChange={onSelectAllClickSubHead}
              inputProps={{ "aria-label": "select all desserts" }}
              style={{ padding: "2px 2px 2px 28px" }}
            />
            <span style={{ padding: "2px 0px 2px 27px" }}>Sub-Head</span>
          </TableCell>
        ) : (
          <TableCell style={{ padding: "2px 0px 2px 28px" }}>
            Sub-Head
          </TableCell>
        )}
        <TableCell>Number of Services</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  numSelectedSubHead: PropTypes.number.isRequired,
  onSelectAllClickSubHead: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(2),
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableCellSmall: {
    // fontSize: "12px",
    padding: "2px 2px 2px 16px",
    fontWeight: 500,
    height: "48px",
  },
  lightBlue: {
    color: theme.palette.getContrastText(lightBlue[50]),
    backgroundColor: lightBlue[50],
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  icon: {
    height: 32,
    // width: 32
  },
  tableRow: {
    border: "6px",
    // width: 32
  },
  cell_1: {
    fontSize: "14px",
    inlineSize: "180px",

  },

}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  let selectedServices = props.selected;
  let selectedServicesSubHead = props.selectedSubHead;
  // let selectAll = false;
  const handleRequestSort = (event, property) => {
    const isAsc = props.orderBy === property && props.order === "asc";
    props.setOrder(isAsc ? -1 : 1);
    props.setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  const handleSelectAllClick = async (event) => {
    if (event.target.checked) {
      const newSelecteds = props.rowData.map((n) => n._id);
      props.setSelectedHeadSubHead(newSelecteds);
      return;
    }
    props.setSelectedHeadSubHead([]);
  };

  function handleClick(event, _id) {
    if (event.target.checked) {
      selectedServices.push(_id);
    } else {
      selectedServices = selectedServices.filter((id) => id !== _id);
    }
    props.setSelectedHeadSubHead(selectedServices);
  }

  const isSelected = (_id) => {
    return props.selected.indexOf(_id) !== -1;
  };

  // sub head functions

  const handleSelectAllClickSubHead = async (event) => {
    if (event.target.checked) {
      let value = [];
      let newSelectedSubHeadData = [];
      let data = props.rowData;
      for (let i = 0; i < data.length; i++) {
        if (!data[i].hasOwnProperty("children")) {
          continue;
        }
        let elements = data[i].children;
        let subheadIds = elements.map((n) => n._id);
        value.push(subheadIds);
      }
      for (let index = 0; index < value.length; index++) {
        newSelectedSubHeadData.push(...value[index]);
      }

      props.setSelectedSubHead(newSelectedSubHeadData);
      return;
    }
    props.setSelectedSubHead([]);
  };

  const handleClickSubHead = (event, _id) => {
    if (event.target.checked) {
      selectedServicesSubHead.push(_id);
    } else {
      selectedServicesSubHead = selectedServicesSubHead.filter(
        (id) => id !== _id
      );
    }
    props.setSelectedSubHead(selectedServicesSubHead);
  };
  const isSelectedSubHead = (_id) => {
    return props.selectedSubHead.indexOf(_id) !== -1;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={props.order}
              orderBy={props.orderBy}
              rowsPerPage={props.rowsPerPage}
              totalCount={props.totalCount}
              onRequestSort={handleRequestSort}
              rowCount={props.totalCount}
              selected={props.selected}
              numSelected={props.selected.length}
              onSelectAllClick={handleSelectAllClick}
              selectedSubHead={props.selectedSubHead}
              numSelectedSubHead={props.selectedSubHead.length}
              onSelectAllClickSubHead={handleSelectAllClickSubHead}
              role={props.role}
            />
            <TableBody>
              {props.rowData.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    className={classes.tableRow}
                    tabIndex={-1}
                    key={index}
                  >
                    {props.role === "areaManager" ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row._id)}
                          checked={isItemSelected}
                          aria-checked={isItemSelected}
                          selected={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                    <TableCell>{row.name}</TableCell>
                    {props.role === "areaManager" ? (
                      <TableCell>
                        <IconButton
                          className={classes.icon}
                          onClick={() => props.handleEditOpenHeadSubhead(row)}
                        >
                          <Avatar className={classes.lightBlue}>
                            <EditIcon color="action" />
                          </Avatar>
                        </IconButton>
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                    <TableCell>
                      {row.children && row.children.length > 0 ? (
                        row.children.map((data, index) => {
                          const isItemSelectedforSubHead = isSelectedSubHead(
                            data._id
                          );
                          const labelIdSubHead = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              className={classes.tableRow}
                              tabIndex={-1}
                              key={index}
                            >
                              {props.role === "areaManager" ? (
                                <TableCell style={{ border: "none" }}>
                                  <Checkbox
                                    onClick={(event) =>
                                      handleClickSubHead(event, data._id)
                                    }
                                    checked={isItemSelectedforSubHead}
                                    aria-checked={isItemSelectedforSubHead}
                                    selectedSubHead={isItemSelectedforSubHead}
                                    inputProps={{
                                      "aria-labelledby": labelIdSubHead,
                                    }}
                                    style={{ border: "none" }}
                                  />
                                </TableCell>
                              ) : (
                                <TableCell
                                  style={{ border: "none" }}
                                ></TableCell>
                              )}
                              <TableCell style={{ border: "none" }}>
                                <Typography className={classes.cell_1}>  {data.name} </Typography>
                              </TableCell>
                              {props.role === "areaManager" ? (
                                <TableCell style={{ border: "none" }}>
                                  <IconButton
                                    className={classes.icon}
                                    style={{ float: "right", border: "none" }}
                                    onClick={() =>
                                      props.handleEditOpenHeadSubhead(data)
                                    }
                                  >
                                    <Avatar className={classes.lightBlue}>
                                      <EditIcon color="action" />
                                    </Avatar>
                                  </IconButton>
                                </TableCell>
                              ) : (
                                <TableCell
                                  style={{ border: "none" }}
                                ></TableCell>
                              )}
                              <TableCell
                                style={{ padding: "2px 2px 2px 140px", align: "right", border: "none", position: "absolute" }}
                              >
                                {data.services.length > 0 ? data.services.length : row.services.length}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableCell></TableCell>
                      )}
                    </TableCell>
                    <TableCell>{row.children.length > 0 ? null : row.services.length}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30,]}
          component="div"
          count={props.totalCount}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
