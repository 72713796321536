import {
    PATCH_REPORTED_SUCCESS_MESSAGE,
    PATCH_REPORTED_ERROR_MESSAGE,
    OFFERCODEREPORT_STATE_CHG_SUCCESS,
    OFFERCODEREPORT_STATE_CHG_ERR,
   SET_OFFERCODEREPORT_SUCCESS_MESSAGE,
   SET_OFFERCODEREPORT_ERROR_MESSAGE,
   SET_OFFERCODEREPORT,
   REQUEST_OFFERCODEREPORT
  } from "../actions/types";
  
  const INITIAL_STATE = {
    page: 0,
    skip: 0,
    limit: 10,
    successMsg: null,
    errorMsg: null,
    showRpd: false,
    offerCodesStateChgSuccess: null,
    offerCodesStateChgErr: null,
    offerCodes: {},
    isFetching: false
  };
  
  export default function (state = INITIAL_STATE, action) {
    
    switch (action.type) {
      case REQUEST_OFFERCODEREPORT:
        return { ...state, isFetching: action.payload };
    
      case PATCH_REPORTED_SUCCESS_MESSAGE:
        return { ...state, successMsg: action.payload };
      case PATCH_REPORTED_ERROR_MESSAGE:
        return { ...state, errorMsg: action.payload };
      case OFFERCODEREPORT_STATE_CHG_SUCCESS:
        return { ...state, offerCodesStateChgSuccess: action.payload };
      case OFFERCODEREPORT_STATE_CHG_ERR:
        return { ...state, offerCodesStateChgErr: action.payload };
      case SET_OFFERCODEREPORT_SUCCESS_MESSAGE:
        return {
          ...state,
          successMsg: action.payload.msg,
          ops: action.payload.ops,
          // isFetching: false
        };
      case SET_OFFERCODEREPORT_ERROR_MESSAGE:
        return {
          ...state,
          errorMsg: action.payload.msg,
          ops: action.payload.ops,
          // isFetching: false
        };
      case SET_OFFERCODEREPORT:
        return {
          ...state,
          offerCodes: action.payload,
          isFetching: false
        };
     
      default:
        return state;
    }
  }