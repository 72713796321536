import {
    GET_BUSINESS_AGREEMENT_DETAILS_PDF
} from "../actions/types";

const INITIAL_STATE = {
    businessAgreement:[]
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_BUSINESS_AGREEMENT_DETAILS_PDF:
                return {...state,  businessAgreement: action.payload};
          default:
            return state;
        }
      }