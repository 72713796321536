import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    form: {
        width: '100%',
        height: '100%',
    },
    header: {
        height: '20%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        color:"#FFFFFF"
    },
    content: {
        height: '55%',
        margin: '0px 0px',
        //overflow: 'auto',
        backgroundColor:"#f0f0f0",
        paddingBottom:"15px"
    },
    footer: {
        height: '3%',
        margin: '25px 0px 0px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom:"-15px"
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    }
});

class AddTRNotesForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSaveButtonClicked: false,
        }
    }

    renderTextField({ label, input, meta: { touched, invalid, error,submitFailed }, ...custom }) {
        return (
            <TextField
                label={label}
               // placeholder={label}
                error={submitFailed && touched && invalid}
                helperText={submitFailed && touched && error}
                {...input}
                {...custom}
            />
        );
    }

    onSubmit = formValues => {
        let assignedTRNote = "";
            formValues.finalTRNote = assignedTRNote;
            formValues._id = this.props.initialValues._id;
            this.setState({ isSaveButtonClicked: true });
            this.props.onSubmit(formValues);
    }

    render() {
        const { classes } = this.props;
        const { isSaveButtonClicked } = this.state
        return (
        <>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <Grid container className={classes.header} spacing={3}>
                <Grid item xs={8}>
                    <Typography variant="h5" gutterBottom>
                        Notes
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <CloseIcon 
                    className={classes.headGridTwo} 
                    style={{marginBottom:"15px"}} 
                    onClick={() => { this.props.onClose(); } } />
                </Grid>
            </Grid>
            <Container className={classes.content}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{marginTop:"10px"}}>
                        <Field
                            name="trNote"
                            component={this.renderTextField}
                            label="Notes"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            style={{
                                    backgroundColor: "#FFFFFF",
                                    resize: "vertical",
                                    maxHeight: "200px",
                                    }}
                            multiline
                            rows={4}/>
                        </Grid>
                    </Grid>
                </Container>
                <Container className={classes.footer}>
                    <Grid container spacing={2} >
                        <Grid item xs={5}></Grid>
                        <Grid item xs={2}>
                            <Button
                                fullWidth
                                variant="contained"
                                margin="normal"
                                color="default"
                                style={{padding:"5px 45px"}}
                                onClick={() => { this.props.onClose() }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                margin="normal"
                                style={{padding:"5px 45px"}}
                                color="primary"
                                disabled={isSaveButtonClicked}
                                onClick={() => {
                                    this.props.handleSubmit(this.onSubmit)();
                                  }}>
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Container>
                </form>
                </>
        );
    }
}

const validate = values => {
    const errors = {}
    if (!values.trNote || !values.trNote.trim()) {
        errors.trNote = 'Please capture Notes';
    }
    return errors
}

export default reduxForm({
    form: 'AddTRNotesForm',
    validate
})(withStyles(styles)(AddTRNotesForm))
