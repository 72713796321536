import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
import { withStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import DeleteIcon from '@material-ui/icons/Delete';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
// import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  imageOutermost: {
    width: '100%',
    height: '100%',
    padding: '0px 0px',

  },
  gridElement: {
    margin: '10px 5px'
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  icon: {
    color: 'white',
  },
  gridList: {
    width: '100%',
    height:'100%'
  },


});

class uploadReferralPhoto extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteOpen: false,
      selectedPhoto: null
    };
    this.inputReference = React.createRef();
  }

  fileUploadAction = () => this.inputReference.current.click();
  onFileChange = async event => {
    let files = event.target.files;
    // console.log("files", files);
    this.props.updateReferralPhotos(files, this.props.photoType);
  };

  handleDeleteOpen = (photo) => {
    this.setState({ modalDeleteOpen: true });
    this.setState({ selectedPhoto: photo });
  }

  handleDeleteClose = () => {
    this.setState({ modalDeleteOpen: false });
  }

  // deleteReferralPhoto = () => {
  //   this.props.deleteReferralPhoto(this.state.selectedPhoto, this.props.photoType);
  //   this.setState({ modalDeleteOpen: false });
  // }

  render() {

    let imageListContent = null;
    const { classes } = this.props;
    // console.log("props in upload referral photo file--->",this.props)
    // if (images) {
    //     imageListContent = (
    //     <GridList cols={7} cellHeight={80} className={classes.gridList}>
    //         <GridListTile
    //             className={classes.gridElement} key={images._id}>
    //             <a href={images.path} target="_blank" rel="noopener noreferrer">
    //             <img border="0" className={classes.img} src={images.path} alt="" />
    //             </a>
    //             <GridListTileBar  
                    
    //             titlePosition="bottom"
    //             actionIcon={
    //                 <IconButton className={classes.icon} onClick={() => this.handleDeleteOpen(images)}>
    //                 <DeleteIcon />
    //                 </IconButton>
    //             }
    //             actionPosition="right"
    //             />
    //         </GridListTile>
    //         ))
    //     </GridList >
    //     );
    // } else {
    //     imageListContent = <span>
    //     <Typography>
    //         No Available Photos
    //     </Typography>
    //     </span>;
    // }

    return (
      <Grid>
        <Grid className={classes.imageOutermost}>
          <Grid>
            <Button variant="contained" htmlFor="myInput2" onClick={this.fileUploadAction} color="primary" component="span" >
              {this.props.uploadButtonName}
            </Button>
            <input
              ref={this.inputReference}
              id="myInput2"
              name="Referral"
              style={{ display: 'none' }}
              type={"file"}
              onChange={this.onFileChange}
              accept="image/x-png,image/jpeg"
            />
          </Grid>
          {/* <Grid>
            {imageListContent}
          </Grid> */}
        </Grid>
        {/* <Dialog open={this.state.modalDeleteOpen} onClose={this.handleDeleteClose}>
          <DialogTitle>Delete Photo</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete this photo?
            </DialogContentText>
          </DialogContent>
           <DialogActions>
            <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
            <Button onClick={() => this.deleteReferralPhoto()} color="primary" autoFocus> Yes </Button>
          </DialogActions> 
        </Dialog> */}
      </Grid>
    );
  }
}

// uploadReferralPhoto.propTypes = {
//   images: PropTypes.array.isRequired
// };

export default (withStyles(styles)(uploadReferralPhoto));
