import React, { Component } from 'react';
import { changeHeaderName, requestCallIconHitRecords,deleteSelectedItemsAvailabilityByIds,requestItemsAvailabilityList,requestItemsAvailabilityExportList, getItemsAvailabilityExportList, getItemsAvailabilityList, getCallIconHitRecordsExportToExcel,clearMessage } from '../../actions/dashboardActions';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ItemsAvailabilityTable from './itemsAvailabilityTable';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from "@material-ui/core/Button";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import { getBusinessTagList} from "../../actions/businessTagAction";

const styles = theme => ({
	container: {
		width: '100%',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(4),
	},
    buttonContainer: {
		width: '100%',
		paddingTop: '10px'
	},
	content: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
	},
	appBarSpacer: theme.mixins.toolbar,
	formControl: {
		minWidth: 225,
		maxWidth: 300,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
});
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
class ItemsAvailabilityReport extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            snackOpen: false,
            callTimeStamp: "",
            orderId: "",
            caller: "",
            callerId: "",
            callerName: "",
            callerContactNo: "",
            screenName: "",
            receiver: "",
            receiverId: "",
            receiverName: "",
            receiverContactNo: "",
            categoryType: "",
            filterStartDate: "",
            filterEndDate: "",
            page: 0,
            rowsPerPage: 10,
            rows: [],
			modalDeleteOpen: false,
            selectedItemsAvailabilityIds: [],
            showDownloadIcon: false,
            order: -1,
            businessName: "",
            service:"",
            serviceId:"",
            businessId:"",
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            businessTags:[],
            filteredBusinessTags:[]
        }
    }

    componentDidMount() {
        this.props.changeHeaderName("Items Availability Logs");
        this.fetchFilter(); //resetFilter
        this.fetchBusinessTags();
    };

    componentDidUpdate(prevProps, prevState){
        if(this.props.dashboard.isFetching ===false){
        if (this.props.dashboard.deleteItemsAvailabilitySuccessMsg) {
            this.setState({ sucessMsgDeleteItemsAvailabilityRecords: this.props.dashboard.deleteItemsAvailabilitySuccessMsg, snackOpen: true })
             this.props.clearMessage()
             
          }
          if (this.props.dashboard.deleteItemsAvailabilityErrorMsg) {
            this.setState({ errorMsgDeleteItemsAvailabilityRecords: this.props.dashboard.deleteItemsAvailabilityErrorMsg, snackOpen: true })
            this.props.clearMessage()
          }
         
        }
    };

    resetFilter = async () => {
        this.setState({
            businessName: "",
            service: "",
            serviceId:"",
            businessId:"",
            callTimeStamp: "",
            filterStartDate: "",
            filterEndDate: "",
            orderId: "",
            caller: "",
            callerId: "",
            callerName: "",
            callerContactNo: "",
            screenName: "",
            receiver: "",
            receiverId: "",
            receiverName: "",
            receiverContactNo: "",
            categoryType: "",
            page: 0,
            filteredBusinessTags:[]
        }, () => {
            this.fetchFilter();
        })
    };

    fetchFilter = async () => {
        this.props.requestItemsAvailabilityList();
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
        let payload = {
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            service:this.state.service.trim(),
            businessName: this.state.businessName.trim(),
            filteredBusinessTags:filteredBusinessTagsVar          
            
        }
        this.props.getItemsAvailabilityList(payload);
        this.setState({showDownloadIcon: false });
    };

    fetchCallIconHitRecordsExcel = async () => {
         this.props.requestItemsAvailabilityExportList();
         let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
        let payload = {
            // callTimeStamp: this.state.callTimeStamp,
            businessName: this.state.businessName.trim(),
            businessId:this.state.businessId.trim(),
            service:this.state.service.trim(),
            serviceId:this.state.serviceId.trim(),
            filteredBusinessTags:filteredBusinessTagsVar 
           }
        await this.props.getItemsAvailabilityExportList(payload);
        this.setState({ showDownloadIcon: true });
    };

    fetchBusinessTags=async()=>{
        const response=await getBusinessTagList();
        if(response){
          this.setState({businessTags:response.data})
        }else{
          this.setState({businessTags:[]})
        }
        
    }

    handleFilterChange = (event) => {
        this.setState({page: 0})
        this.setState({ [event.target.name]: event.target.value });
    };

    setPage = (page) => {
        this.setState({ page }, () => this.fetchFilter());
    };

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage }, () => this.fetchFilter());
    };

    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        picker.endDate.add(1, "days");
        this.setState({
            filterStartDate: picker.startDate.format('DD MMM YYYY'),
            filterEndDate: picker.endDate.format('DD MMM YYYY')
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ callTimeStamp: chosenLabel });
    };

   
    
    deleteOpen = () => {
		this.setState({ modalDeleteOpen: true });
	}

    deleteClose = () => {
		this.setState({ modalDeleteOpen: false, selectedItemsAvailabilityIds: [] });
	}
    
    deleteSelectedItemsAvailability = async () => {
		await this.props.deleteSelectedItemsAvailabilityByIds(this.state.selectedItemsAvailabilityIds);
		this.setState({ modalDeleteOpen: false });
        this.fetchFilter();
		this.setState({selectedItemsAvailabilityIds: []});
	}

    setSelected = (selectedItemsAvailabilityIds) => {
		this.setState({ selectedItemsAvailabilityIds })
	}

    handleSnackClose = () => {
        this.setState({
          snackOpen: false,
          sucessMsgDeleteItemsAvailabilityRecords:"",
          errorMsgDeleteItemsAvailabilityRecords:""
        });
      };
    render() {
        const { classes } = this.props;
        let muiAlert = null;
        if (this.state.sucessMsgDeleteItemsAvailabilityRecords) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
              {this.state.sucessMsgDeleteItemsAvailabilityRecords}
            </MuiAlert>
          } else if (this.state.errorMsgDeleteItemsAvailabilityRecords) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="info">
              {this.state.errorMsgDeleteItemsAvailabilityRecords}
            </MuiAlert>
          }
        return (
            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container  className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography style={{ fontSize:'17px'}}>Search/Filter</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={1}>
                            <Grid item xs={3} >
                                <Typography variant="subtitle2" gutterBottom>
                                    Business Id / Name
                                    </Typography>
                                    <TextField fullWidth margin="dense" name="businessName" style={{ width: "225px" }} value={this.state.businessName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={1}/> 

                                <Grid item xs={3} >
                                <Typography variant="subtitle2" gutterBottom>
                                    Service Id / Name
                                    </Typography>
                                    <TextField fullWidth margin="dense" name="service" style={{ width: "225px" }} value={this.state.service} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid> 
                            
                                {/* <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="orderId" label="Order ID" value={this.state.orderId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>    
                                <Grid item xs={3} >
                                    <FormControl variant="filled" fullWidth className={classes.formControl} >
                                        <InputLabel id="Caller-label">Caller</InputLabel>
                                        <Select fullWidth labelId="Caller-label" name="caller" value={this.state.caller} onChange={this.handleFilterChange} input={<Input />} MenuProps={this.MenuProps}>
                                            <MenuItem value={"Business"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Business"/> </MenuItem>
                                            <MenuItem value={"Consumer"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Consumer"/> </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>    
                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="callerId" label="Caller ID" value={this.state.callerId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>    
                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="callerName" label="Caller Name" value={this.state.callerName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>    
                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="callerContactNo" label="Caller Contact No." value={this.state.callerContactNo} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>    
                                <Grid item xs={3} >
                                    <FormControl variant="filled" fullWidth className={classes.formControl} >
                                        <InputLabel id="Screen-Name-label">Screen Name</InputLabel>
                                        <Select fullWidth labelId="Screen-Name-label" name="screenName" value={this.state.screenName} onChange={this.handleFilterChange} input={<Input />} MenuProps={this.MenuProps} >
                                            <MenuItem value={"About"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="About"/> </MenuItem>
                                            <MenuItem value={"Order Placed"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Order Placed"/> </MenuItem>
                                            <MenuItem value={"Order Details"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Order Details"/> </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>    
                                <Grid item xs={3} >
                                    <FormControl variant="filled" fullWidth className={classes.formControl} >
                                        <InputLabel id="Receiver-label">Receiver</InputLabel>
                                        <Select fullWidth labelId="Receiver-label" name="receiver" value={this.state.receiver} onChange={this.handleFilterChange} input={<Input />} MenuProps={this.MenuProps} >
                                            <MenuItem value={"Business"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Business"/> </MenuItem>
                                            <MenuItem value={"Consumer"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Consumer"/> </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>    
                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="receiverId" label="Receiver ID" value={this.state.receiverId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>    
                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="receiverName" label="Receiver Name" value={this.state.receiverName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>    
                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="receiverContactNo" label="Receiver Contact No." value={this.state.receiverContactNo} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>    
                                <Grid item xs={3} >
                                    <FormControl variant="filled" fullWidth className={classes.formControl} >
                                        <InputLabel id="Category-Type-label">Category Type</InputLabel>
                                        <Select fullWidth labelId="Category-Type-label" name="categoryType" value={this.state.categoryType} onChange={this.handleFilterChange} input={<Input />}  MenuProps={this.MenuProps} >
                                            <MenuItem value={"Shopping"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Shopping"/> </MenuItem>
                                            <MenuItem value={"Services"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Services"/> </MenuItem>
                                        </Select>
                                    </FormControl>
        </Grid> */}
                                <Grid item xs={3}>
                                    <Typography variant="subtitle2" gutterBottom>Business Tags</Typography>
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Business Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredBusinessTags}
                                            name="filteredBusinessTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {this.state. businessTags.map((eachBusinessTagObj) => (
                                                <MenuItem key={eachBusinessTagObj._id} value={eachBusinessTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredBusinessTags.indexOf(eachBusinessTagObj.name) > -1} />
                                                    <ListItemText primary={eachBusinessTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> 
                                <Grid item xs={9}></Grid> <Grid item xs={8}></Grid><Grid item xs={2}>
                                    <Button type="submit" fullWidth variant="contained" margin="normal" onClick={this.resetFilter}> Reset </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button type="submit" fullWidth variant="contained" margin="normal" color="primary" onClick={this.fetchFilter}> Search </Button>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                <Container className={classes.buttonContainer}>
					<Grid container spacing={3}>
						<Grid item xs={8}>
						</Grid>
						<Grid item xs={3}>
							{/* <Button
								style={{ float: "right", margin: "5px" }}
								variant="contained"
								color="primary"
								disabled={this.state.selectedCouponIds.length < 1}
								onClick={() => this.handleUpdateConfirmModal(true)}
							>
								Settle Coupons
							</Button> */}
						</Grid>
						<Grid item xs={1}>
							<Button
								style={{ float: "right"}}
								variant="contained"
								color="primary"
								disabled={this.state.selectedItemsAvailabilityIds.length < 1}
								onClick={this.deleteOpen}
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</Container>

                <Container className={classes.container}>
                    {
                        this.props.dashboard.itemsAvailabilityList && this.props.dashboard.itemsAvailabilityList.data ?
                            <ItemsAvailabilityTable 
                                data={this.props.dashboard.itemsAvailabilityList.data}
                                totalCount={this.props.dashboard.itemsAvailabilityList.total}
                                exportToExcel={this.props.dashboard.itemsAvailabilityExportList}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                setPage={this.setPage}
								setSelected={this.setSelected}
								selectedItemsAvailabilityIds={this.state.selectedItemsAvailabilityIds}
                                setRowsPerPage={this.setRowsPerPage}
                                showDownloadIcon={this.state.showDownloadIcon}
                                fetchCallIconHitRecordsExcel={this.fetchCallIconHitRecordsExcel}
                            />
                        : null
                    }
                </Container>
                <Container>
					<Dialog open={this.state.modalDeleteOpen} onClose={this.deleteClose}>
						<DialogTitle>Delete Record(s)</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Do you want to delete the records?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.deleteClose} color="primary"> No </Button>
							<Button onClick={() => this.deleteSelectedItemsAvailability()} color="primary" autoFocus> Yes </Button>
						</DialogActions>
					</Dialog>
				</Container>
                <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
          ContentProps={{ 'aria-describedby': 'message-id' }}
        >
          {muiAlert}
        </Snackbar>
                <Backdrop className={classes.backdrop} open={this.props.dashboard.isFetching}> <CircularProgress color="inherit" /> </Backdrop>
            </Container>
            

        );
    }
}

const mapStateToProps = (state) => {
    return {
        dashboard: state.dashboard,
    }
}
export default connect(mapStateToProps, { deleteSelectedItemsAvailabilityByIds,changeHeaderName,getItemsAvailabilityExportList,getItemsAvailabilityList,requestItemsAvailabilityExportList,requestItemsAvailabilityList, requestCallIconHitRecords, getCallIconHitRecordsExportToExcel,clearMessage })(withStyles(styles)(ItemsAvailabilityReport));
