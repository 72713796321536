import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from "@material-ui/core/Box";
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import _ from 'lodash';
import { connect } from "react-redux";
import Switch from '@material-ui/core/Switch';
import Modal from '@material-ui/core/Modal';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


const styles = theme => ({
    form: {
        width: '45%',
        height: 'fit-content',
        backgroundColor: "white",
        overflow: "hidden",
        flexGrow: 1,
        border: "2px solid #3d8bc3",
        borderRadius: "10px!important"


},
   
    header: {
        height: '8%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    CloseIcon: {
        float: "right",
        color: "white"
    },
    headerText: {
        color: "white",
        fontSize: "20px",
        fontFamily: "serif"
    },

    footer: {
        height: '10%',
        padding: '20px 0px 10px 150px',
        display: 'flex',

    },

    body: {
        fontFamily: "serif",
        padding: "10px 5px"
    },
    bodyMainContainer: {
        padding: "5px 4px"
    },

    dynamicForm: {
        width: '50%',
        maxHeight: "100%",
        backgroundColor: "white",
        overflow: "auto",
        flexGrow: 1,
        border: "2px solid #3d8bc3",
        borderRadius: "10px!important",'&::-webkit-scrollbar': {
            width: '1px',
            backgroundColor: 'white',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'white',
            borderRadius: '1px'
          }
},

});


class CreateVariationGroup extends React.Component {
    constructor() {
        super();
        this.state = {
            variationGroupName: "",
            GST: null,
            foodType: "NA",
            contact: "",
            error: {},
            gstError : null,
            isAvailable: false,
			isEnabled: false,
            isDisplay:true

        };
        this.inputReference = React.createRef();
    }


    componentDidMount() {
        this.setData()
    };


    
    setData = () =>{
      if (this.props.editFlag) {
        this.setState({
            variationGroupName: this.props.dataToSet?.variationGroupName,
            GST: this.props.dataToSet.GST,
            foodType: this.props.dataToSet.foodType,
            contact: this.props.dataToSet.contactId,
            isAvailable:this.props.dataToSet.isAvailable,
            isDisplay:this.props.dataToSet.isDisplay,
            isEnabled:this.props.dataToSet.isEnabled
          })
      }
    };

    
    componentDidUpdate(prevProps) {
		if (this.props.editFlag && (this.props.editFlag !== prevProps.editFlag)) {
			this.setData()
		}
	};

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };



    reset = () => {
        this.setState({
            variationGroupName: "",
            GST: null,
            foodType: "",
            contact: ""
        });
    };

    handleNameFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }
    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleGSTChange = (event) => {
        let error = this.state.error;

        if (event.target.value == '') {
            this.setState({ GST: null, gstError: null });
        } else {
            this.setState({ GST: { gst: event.target.value } });
            if (!this.props.businessDetails.gstin) {
                this.setState({ gstError: 'Please Add GST To Business' });
            }
        }
	};

    handleGSTComponentChange = (event) => {
		this.setState({ GST: { ...this.state.GST, [event.target.name]: event.target.value } });
	};

    hasGstFields = () => {
        return this.state.GST.gst != null || this.state.GST.sgst != null || this.state.GST.cgst != null || this.state.GST.igst != null
    };
    
    handleSwitchChange = (event) => {
		this.setState({
			isAvailable: event.target.checked
		})
	};
	handleIsEnableChange = (event) => {
		this.setState({
			isEnabled: event.target.checked
		})
	};

    handleIsDisplayChange = (event) => {
		this.setState({
			isDisplay: event.target.checked
		})
	};



    onSubmit = async () => {
        let hasError = false

        if (this.state.variationGroupName === null || this.state.variationGroupName.trim() === "" || this.state.variationGroupName === undefined) {
            let error= this.state.error
            error.variationGroupName = "Please Enter Variation Group Name"
            this.setState({ error  : error })
            hasError = true;
        }
   //gstvalidation
        // if(this.state.GST){
        //     if (this.state.GST && !this.props.businessDetails.gstin) {
        //         this.setState({ gstError: 'Please Add GST To Business' });
        //         hasError = true;
        //     } else if ((this.state.GST.igst != null && this.state.GST.igst != 0) &&
        //         ((this.state.GST.cgst != null && this.state.GST.cgst != 0) || (this.state.GST.sgst != null && this.state.GST.sgst != 0))) {
        //         this.setState({ gstError: 'Either IGST or CGST & SGST should be entered' });
        //         hasError = true;
        //     } else if ((this.state.GST.igst != null & this.state.GST.igst != 0) && this.state.GST.igst != this.state.GST.gst) {
        //         this.setState({ gstError: 'IGST should be same as GST' });
        //         hasError = true;
        //     } else if ((this.state.GST.igst == null || this.state.GST.igst == 0) && (parseFloat(this.state.GST.sgst) + parseFloat(this.state.GST.cgst)) != this.state.GST.gst) {
        //         this.setState({ gstError: 'Total GST should be equal to summation of SGST and CGST' });
        //         hasError = true;
        //     }
        // }

        if(this.state.GST){
            if (this.state.GST && !this.props.businessDetails.gstin) {
                this.setState({ gstError: 'Please Add GST To Business' });
                hasError = true;
            } else if ((this.state.GST.igst) && ((this.state.GST.cgst) || (this.state.GST.sgst))) {
                this.setState({ gstError: 'Either IGST or CGST & SGST should be entered' });
                hasError = true;
            } else if ((this.state.GST.igst) && parseFloat(this.state.GST.igst) != parseFloat(this.state.GST.gst)) {
                this.setState({ gstError: 'IGST should be same as GST' });
                hasError = true;
            } else if (!this.state.GST.igst && (parseFloat(this.state.GST.sgst) + parseFloat(this.state.GST.cgst)) != this.state.GST.gst) {
                this.setState({ gstError: 'Total GST should be equal to summation of SGST and CGST' });
                hasError = true;
            }
        }
        
        if(hasError){
            return
        }else{

            const getUserRole = (data) => {

                let findUser = data.find(i => {
                    let associatedBusiness = i.associatedBusiness.find(j => j.businessId === this.props.business.businessMembersDetails.businessId && j.role === 'superVendor');
                    if (associatedBusiness) {
                        return i;
                    }
                });
                return findUser._id;
            }


            let payload = {
              variationGroupName  : this.state.variationGroupName,
              foodType            : this.state.foodType,
              contactId           : this.state.contact ? this.state.contact : getUserRole(this.props.business.businessMembersDetails.data),
              businessId          : this.props.businessDetails._id,
              isAvailable          : this.state.isAvailable,
              isEnabled            : this.state.isEnabled,
              isDisplay            : this.state.isDisplay,
             }

            if (this.state.GST) {
                if ((this.state.GST.gst != null && this.state.GST.sgst != null && this.state.GST.cgst != null) || this.state.GST.igst !=null){
                    payload.GST     = {};
                    payload.GST.gst = this.state.GST.gst ? parseFloat(this.state.GST.gst) : 0;
                    payload.GST.sgst = this.state.GST.sgst ? parseFloat(this.state.GST.sgst) : 0;
                    payload.GST.cgst = this.state.GST.cgst ? parseFloat(this.state.GST.cgst) : 0;
                    payload.GST.igst = this.state.GST.igst ? parseFloat(this.state.GST.igst) : 0;
                }    
            }else{
                payload.GST = null;
            }
            if (this.props.editFlag) {
                this.updateVariationGroup(payload)
            }else{
                this.props.onSubmit(payload);
            }
            
            this.props.closeCreateVariation()
        }
    };

    updateVariationGroup = async(value) =>{
         let payload  = {
            id : this.props.dataToSet._id,
            isAvailable          : this.state.isAvailable,
            isEnabled            : this.state.isEnabled,
            isDisplay            : this.state.isDisplay,
        }

        if (value.variationGroupName !== this.props.dataToSet.variationGroupName) {
			payload.variationGroupName = value.variationGroupName
		}
        if (!_.isEqual(value.GST, this.props.dataToSet.GST)) {
			payload.GST = value.GST
		}
        if (value.foodType !== this.props.dataToSet.foodType) {
			payload.foodType = value.foodType
		}
        if (value.contactId !== this.props.dataToSet.contactId) {
			payload.contactId = value.contactId
		}
        await this.props.onUpdate(payload)
    }

    render() {
        const { classes } = this.props;
        
        return (
            <div className={this.state.GST && this.state.GST != null ? classes.dynamicForm : classes.form }>
                <div className={classes.appBarSpacer} >
                    <Grid container className={classes.header} spacing={1}>
                    <Grid item xs={8}>
                        {this.props.editFlag ?
                            <Typography variant="h5" gutterBottom className={classes.headerText}>
                              Edit Variation Group
                            </Typography> :  <Typography variant="h5" gutterBottom className={classes.headerText}>
                              Create Variation Group
                            </Typography>}
                        </Grid>
                        <Grid item xs={4}>
                            <CloseIcon className={classes.CloseIcon} onClick={() => { this.props.closeCreateVariation() }} />
                        </Grid>
                    </Grid>
                    <Container className={classes.body}>
                        <Grid className={classes.bodyItemsContainer} >
                            <Grid className={classes.bodyMainContainer}>
                                <Typography>
                                    Variation Group Name*
                                </Typography>
                                <TextField fullWidth margin="dense" name="variationGroupName" value={this.state.variationGroupName} variant="outlined" onChange={this.handleNameFilterChange} placeholder="Enter Variation Group Name.." error={this.state.error.variationGroupName} helperText={this.state.error.variationGroupName} />
                            </Grid>
                            <Grid className={classes.bodyMainContainer} >
                                <Typography>
                                    GST
                                </Typography>
                                {this.state.gstError && <div style={{ color: 'red', margin: '10px' }}>{this.state.gstError}</div>
                                }
                                <Box style={{ display: "flex" }}>
                                    <FormControl fullWidth margin="dense" variant="outlined">
                                        <InputLabel id="demo-simple-select-standard-label" variant="outlined">
                                            NA
                                        </InputLabel>

                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            name="GST.gst"
                                            value={this.state.GST ? this.state.GST.gst : ""}
                                            onChange={this.handleGSTChange}
                                            style={{ width: "250px", maxHeight: "40px" }}
                                            variant="outlined"
                                            label="NA"
                                        >
                                            <MenuItem value="" >
                                                <ListItemText primary="N/A" />
                                            </MenuItem>
                                            <MenuItem value="5" >
                                                <ListItemText primary="5%" />
                                            </MenuItem>
                                            <MenuItem value="12" >
                                                <ListItemText primary="12%" />
                                            </MenuItem>
                                            <MenuItem value="18" >
                                                <ListItemText primary="18%" />
                                            </MenuItem>
                                            <MenuItem value="28" >
                                                <ListItemText primary="28%" />
                                            </MenuItem>

                                        </Select>

                                    </FormControl>


                                    <Button
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={() => { this.setState({ GST: null  , gstError : null}) }}
                                        style={{ width: "25px", maxHeight: "40px", border: "1px solid #3d8bc3", background: "white", marginTop: "6px" }}
                                    >
                                        RESET
                                    </Button>

                                </Box>

                            </Grid>

                            {
                                this.state.GST && this.hasGstFields() &&
                                <Grid item xs={8}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '250px', marginLeft : "4px" }}>
                                        <TextField  margin="dense" type="number" name="sgst" label="SGST %*" variant="outlined" value={this.state.GST ? this.state.GST.sgst : ''} error={this.state.error.sgst} helperText={this.state.error.sgst} onChange={this.handleGSTComponentChange} />
                                    </div>
                                </Grid>
                            }{
                                this.state.GST && this.hasGstFields() &&
                                <Grid item xs={8}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '250px',marginLeft : "4px" }}>
                                        <TextField  margin="dense" type="number" name="cgst" label="CGST %*" variant="outlined" value={this.state.GST ? this.state.GST.cgst : ''} error={this.state.error.cgst} helperText={this.state.error.cgst} onChange={this.handleGSTComponentChange} />
                                    </div>
                                </Grid>
                            }
                            {
                                this.state.GST && this.hasGstFields() &&
                                <Grid item xs={8}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '250px', marginLeft : "4px"}}>
                                        <TextField  margin="dense" type="number" name="igst" label="IGST %*" variant="outlined" value={this.state.GST ? this.state.GST.igst : ''} error={this.state.error.igst} helperText={this.state.error.igst} onChange={this.handleGSTComponentChange} />
                                    </div>
                                </Grid>
                            }
                            
                            <Grid className={classes.bodyMainContainer} style={{ padding : "0px 0px 0px 10px", marginBottom: "10px", marginTop: "15px"}}>
                           
								<FormControlLabel
									control={
										<Switch
											size="small"
											checked={this.state.isAvailable}
											 onChange={(e) => this.handleSwitchChange(e)}
											color="primary"
										/>
									}
									style={{ fontSize: "5px" }}
									label={this.state.isAvailable ? 'Available' : 'Unavailable'}
								/>
							
                           
								<FormControlLabel
									control={
										<Switch
											size="small"
											checked={this.state.isEnabled}
											 onChange={(e) => this.handleIsEnableChange(e)}
											color="primary"
										/>
									}
									style={{ fontSize: "5px" }}
									label={this.state.isEnabled ? 'Enable' : 'Disable'}
								/>
							
                            
								<FormControlLabel
									control={
										<Switch
											size="small"
											checked={this.state.isDisplay}
											 onChange={(e) => this.handleIsDisplayChange(e)}
											color="primary"
										/>
									}
									style={{ fontSize: "5px" }}
									label={this.state.isDisplay ? 'Display' : 'Hide'}
								/>
							</Grid>
                            
                            <Grid className={classes.bodyMainContainer} style={{ padding : "0px"}}>
                                <Typography>
                                    Food Type
                                </Typography>
                                <RadioGroup row aria-label="position" name="foodType" onChange={this.handleFilterChange} value={this.state.foodType} >
                                    <FormControlLabel style={{ fontSize: "6px" , padding : "0px" }} value="Veg" control={<Radio color="primary" />} label="Veg" />
                                    <FormControlLabel style={{ fontSize: "6px" }} value="NonVeg" control={<Radio color="primary" />} label="NonVeg" />
                                    <FormControlLabel style={{ fontSize: "6px" }} value="Egg" control={<Radio color="primary" />} label="Egg" />
                                    <FormControlLabel style={{ fontSize: "6px" }} value="NA" control={<Radio color="primary" />} label="NA" />
                                    <FormControlLabel style={{ fontSize: "6px" }} value="Blank" control={<Radio color="primary" />} label="Blank" />
                                </RadioGroup>
                            </Grid>

                            <Grid className={classes.bodyMainContainer}>
                                <Typography>
                                    Tag Contact
                                </Typography>
                                <FormControl fullWidth margin="dense" variant="outlined" >
                                    <InputLabel id="demo-simple-select-standard-label" variant="outlined">
                                        NA
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={this.state.contact}
                                        name="contact"
                                        onChange={this.handleFilterChange}
                                        style={{ width: "250px", maxHeight: "40px" }}
                                        variant="outlined"
                                        label="NA"
                                    >

                                        {this.props.businessMemberDetails && this.props.businessMemberDetails.data ?
                                            this.props.businessMemberDetails.data.map((contact) =>
                                                <MenuItem key={contact._id} value={contact._id} style={{ minHeight: "28px", height: "28px" }}>
                                                    <ListItemText className={classes.listItemText} primary={contact.firstName + " " + contact.lastName} />
                                                </MenuItem>
                                            ) : null
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container style={{ display: "flex", maxWidth: "40px" }}>


                        <Button
                            fullWidth
                            variant="contained"
                            margin="normal"
                            color="default"
                            onClick={() => { this.props.closeCreateVariation() }}
                            style={{ margin: "10px" }}
                        >
                            CANCEL
                        </Button>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            margin="normal"
                            style={{ margin: "10px" }}
                            onClick={this.onSubmit}
                            color="primary">
                            SAVE
                        </Button>

                    </Container>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        business: state.business,
    };
};
export default connect(mapStateToProps, {

})(withStyles(styles)(CreateVariationGroup));