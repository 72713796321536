import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Backdrop from "@material-ui/core/Backdrop";
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
import { useDispatch, useSelector } from "react-redux";
import { getShoppersData, requestShoppers, assignCouponsToShoppers, assignAllShoppersCoupons, requestSuccess, requestSuccessDone } from '../../actions/campaignActions';
import moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { TableHead } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import { getConsumerTagList} from "../../actions/consumerTagAction";

const styles = (theme) => ({
    paper: {
      padding: "0px 0px",
      display: "flex",
      alignItems: "center",
      height: "60px",
      marginBottom: "25px",
    },
    snackClose: {
      padding: theme.spacing(0.5),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px",
      flexDirection: "column",
      width: "85%",
      height: "150%",
    },
    header: {
      //   height: '9%',
      // height: "7%",
      margin: "0px 0px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.primary.main,
    },
    headerWhite: {
      margin: "0px 0px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      padding: "10px",
    },
    headGridTwo: {
      float: "right",
      marginRight: "2px",
    },
    content: {
      // height: "60%",
      // margin: '10px 0px',
      overflow: "auto",
      backgroundColor: theme.palette.background.paper,
    },
  });

  const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
  },
  getContentAnchorEl: null,
};
  
  const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF",
    },
  })(Typography);

function AssignShopperModal(props){
    const { classes } = props;
    const [search, setSearchText] = useState("");
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [orderCountMoreThan, setOrderCountMoreThan] = useState("");
    const [orderCountLessThan, setOrderCountLessThan] = useState("");
    const [shopperStatus, setShopperStatus] = useState("");
   // const [dateRange,setDateRange] = useState("");
    const [filterStartDate,setFilterStartDate] = useState("");
    const [filterEndDate,setFilterEndDate] = useState("");
    const [lastActiveStartDate,setlastActiveStartDate] = useState("");
    const [lastActiveEndDate,setlastActiveEndDate] = useState("");
    const [lastRequestStartDate,setlastRequestStartDate] = useState("");
    const [lastRequestEndDate,setlastRequestEndDate] = useState("");
    const [ranges,setRanges] = useState({  
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
    )
    const [registrationDate, setRegistrationDate] = useState("");
    const [lastActiveDate, setLastActiveDate] = useState("");
    const [lastRequestDate, setLastRequestDate] = useState("");
    const [count, setCount] = useState(0);
    // const [selectAll, setSelectAll] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [showUnselectedShoppers, setShowUnselectedShoppers] = useState(false);
    const [showSelectedShoppers, setShowSelectedShoppers] = useState(false);
    const [assignCouponFlag, setAssignCouponFlag] = useState(false);
    const [assignCouponAllFlag, setAssignCouponAllFlag] = useState(false);
    const [unselectedCount, setUnselectedCount] = useState(0);
    const [isFetching, setIsFetching] = useState(false);
    const [shoppersList, setShoppersList] = useState([]);
    const [selectedShoppersList, setSelectedShoppersList] = useState([]);
    let [countSelectAll, setCountSelectAll] = useState(0);
    let [saveBtn, setSaveBtn] = useState(false);
    let [orderCountLessThanError, setOrderCountLessThanError] = useState(false);
    let [registrationDateError, setRegistrationDateError] = useState(false);
    let [lastActiveDateError, setLastActiveDateError] = useState(false);
    let [lastRequestDateError, setLastRequestDateError] = useState(false);
    let [checkCount, setCheckCount] = useState(0);
    let [consumerTags, setConsumerTags] = useState([]);
    let [filteredConsumerTags, setFilteredConsumerTags] = useState([]);
  
    
    // let [orderCountMoreThanValueError, setOrderCountMoreThanValueError] = useState(false);
    // let [orderCountLessThanValueError, setOrderCountLessThanValueError] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
      setIsFetching(true);
      fetchShoppersWithFilter();
      fetchConsumerTags();
      setIsFetching(false);
    }, []);

    const fetchConsumerTags=async()=>{
      const response=await getConsumerTagList();
      if(response){
        setConsumerTags(response.data)
  }else{
    setConsumerTags([])
    }
  }
    // backup of working use effect
    // useEffect( () => {
    //   console.log("shoppers list in componentDidUpdate------>",props);
    //   // setCount(0);
    //   // setUnselectedCount(0);
    //   let sortedArray = [];
    //   sortedArray =
    //     props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.shoppersList &&
    //     props.campaign.shoppersListData.shoppersList.length > 0 &&
    //     props.campaign.shoppersListData.shoppersList.map((ele) => {
    //       // console.log("shoppers element---->",ele);
    //       let selectedEntry =
    //       props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.selectedConsumerByCampaign &&
    //       props.campaign.shoppersListData.selectedConsumerByCampaign.length > 0 &&
    //       props.campaign.shoppersListData.selectedConsumerByCampaign.filter((item) => {
    //           // console.log("single item------>",item);
    //           // console.log("business element---->",ele);
    //           if (ele._id === item._id) {
    //             item.couponsData = ele.couponsData
    //             return item;
    //           }
    //         });
    //         // console.log("selectedEntry------>",selectedEntry);
    //       if (selectedEntry && selectedEntry[0]) {
    //         // console.log("selectedEntry[0]------>",selectedEntry[0])
    //         return{
    //           agreementAcceptedDate: selectedEntry[0].agreementAcceptedDate,
    //           consumerId: selectedEntry[0].consumerId ? selectedEntry[0].consumerId : "--",
    //           firstName: selectedEntry[0].firstName ? selectedEntry[0].firstName : "--",
    //           lastName: selectedEntry[0].lastName ? selectedEntry[0].lastName : "--",
    //           ordersAtXirify: selectedEntry[0].ordersAtXirify ? selectedEntry[0].ordersAtXirify : 0,
    //           offerCampaigns: selectedEntry[0].offerCampaigns ? selectedEntry[0].offerCampaigns : [],
    //           isSelected: true,
    //           couponsData: selectedEntry[0].couponsData ? selectedEntry[0].couponsData : [],
    //           _id: selectedEntry[0]._id,
    //         }
    //       } else {
    //         return {
    //           agreementAcceptedDate: ele.agreementAcceptedDate,
    //           consumerId: ele.consumerId ? ele.consumerId : "--",
    //           firstName: ele.firstName ? ele.firstName : "--",
    //           lastName: ele.lastName ? ele.lastName : "--",
    //           ordersAtXirify: ele.ordersAtXirify ? ele.ordersAtXirify : 0,
    //           offerCampaigns: ele.offerCampaigns ? ele.offerCampaigns : [],
    //           isSelected: false,
    //           couponsData: ele.couponsData ? ele.couponsData : [],
    //           _id: ele._id,
    //         };
    //       }
    //     });
    //     // console.log("sortedArray------>",sortedArray);
    //     let tempDisplayList = sortedArray;
    //     setShoppersList(tempDisplayList);

    //     setCount(0);
    //     setUnselectedCount(0);
    //     if(props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData &&
    //       props.campaign.shoppersListData.totalCountSelectedConsumerByCampaign &&
    //       props.campaign.shoppersListData.totalCountSelectedConsumerByCampaign>=0){
    //         setCount(props.campaign.shoppersListData.totalCountSelectedConsumerByCampaign)
    //     }
    //     if(props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData &&
    //       props.campaign.shoppersListData.totalCountUnSelectedConsumerByCampaign &&
    //       props.campaign.shoppersListData.totalCountUnSelectedConsumerByCampaign>=0){
    //         setUnselectedCount(props.campaign.shoppersListData.totalCountUnSelectedConsumerByCampaign)
    //     }
    //     // if(props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.unselectedConsumerByCampaign.length===0 && props.campaign.shoppersListData.selectedConsumerByCampaign.length===10){
    //     //   console.log("select all")
    //     //   setSelectAll(true);
    //     //   setCountSelectAll(0);
    //     // }

    // }, [props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.selectedConsumerByCampaign && props.campaign.shoppersListData.shoppersList, props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.selectedConsumerByCampaign]
    // );

    useEffect( () => {
      // setCount(0);
      // setUnselectedCount(0);
      let sortedArray = [];
      sortedArray =
        props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.shoppersList &&
        props.campaign.shoppersListData.shoppersList.length > 0 &&
        props.campaign.shoppersListData.shoppersList.map((ele) => {
          // console.log("shoppers element---->",ele);
          let selectedEntry =
          props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.selectedConsumerByCampaign &&
          props.campaign.shoppersListData.selectedConsumerByCampaign.length > 0 &&
          props.campaign.shoppersListData.selectedConsumerByCampaign.filter((item) => {
              // console.log("single item------>",item);
              // console.log("business element---->",ele);
              if (ele._id === item._id) {
                item.couponsData = ele.couponsData
                return item;
              }
            });
            // console.log("selectedEntry------>",selectedEntry);
          if (selectedEntry && selectedEntry[0]) {
            // console.log("selectedEntry[0]------>",selectedEntry[0])
            return{
              agreementAcceptedDate: selectedEntry[0].agreementAcceptedDate,
              consumerId: selectedEntry[0].consumerId ? selectedEntry[0].consumerId : "--",
              firstName: selectedEntry[0].firstName ? selectedEntry[0].firstName : "--",
              lastName: selectedEntry[0].lastName ? selectedEntry[0].lastName : "--",
              ordersAtXirify: selectedEntry[0].ordersAtXirify ? selectedEntry[0].ordersAtXirify : 0,
              offerCampaigns: selectedEntry[0].offerCampaigns ? selectedEntry[0].offerCampaigns : [],
              isSelected: true,
              couponsData: selectedEntry[0].couponsData ? selectedEntry[0].couponsData : [],
              _id: selectedEntry[0]._id,
            }
          } else {
            // for selectedshoppers list if any is selected
            if(!showSelectedShoppers && !showUnselectedShoppers){
              // console.log("if condition")
              let selectedShoppersEntry =
              selectedShoppersList &&
              selectedShoppersList.length > 0 &&
              selectedShoppersList.filter((item) => {
                  // console.log("single item------>",item);
                  // console.log("business element---->",ele);
                  if (ele._id === item._id) {
                    item.couponsData = ele.couponsData
                    return item;
                  }
                });
                // console.log("selectedEntry------>",selectedEntry);
              if (selectedShoppersEntry && selectedShoppersEntry[0]) {
                // console.log("selectedShoppersEntry[0]------>",selectedShoppersEntry[0])
                return{
                  agreementAcceptedDate: selectedShoppersEntry[0].agreementAcceptedDate,
                  consumerId: selectedShoppersEntry[0].consumerId ? selectedShoppersEntry[0].consumerId : "--",
                  firstName: selectedShoppersEntry[0].firstName ? selectedShoppersEntry[0].firstName : "--",
                  lastName: selectedShoppersEntry[0].lastName ? selectedShoppersEntry[0].lastName : "--",
                  ordersAtXirify: selectedShoppersEntry[0].ordersAtXirify ? selectedShoppersEntry[0].ordersAtXirify : 0,
                  offerCampaigns: selectedShoppersEntry[0].offerCampaigns ? selectedShoppersEntry[0].offerCampaigns : [],
                  isSelected: true,
                  couponsData: selectedShoppersEntry[0].couponsData ? selectedShoppersEntry[0].couponsData : [],
                  _id: selectedShoppersEntry[0]._id,
                }
              }else{
                return {
                  agreementAcceptedDate: ele.agreementAcceptedDate,
                  consumerId: ele.consumerId ? ele.consumerId : "--",
                  firstName: ele.firstName ? ele.firstName : "--",
                  lastName: ele.lastName ? ele.lastName : "--",
                  ordersAtXirify: ele.ordersAtXirify ? ele.ordersAtXirify : 0,
                  offerCampaigns: ele.offerCampaigns ? ele.offerCampaigns : [],
                  isSelected: false,
                  couponsData: ele.couponsData ? ele.couponsData : [],
                  _id: ele._id,
                };
              }
            }else{
              // console.log("else condition")
              return {
                agreementAcceptedDate: ele.agreementAcceptedDate,
                consumerId: ele.consumerId ? ele.consumerId : "--",
                firstName: ele.firstName ? ele.firstName : "--",
                lastName: ele.lastName ? ele.lastName : "--",
                ordersAtXirify: ele.ordersAtXirify ? ele.ordersAtXirify : 0,
                offerCampaigns: ele.offerCampaigns ? ele.offerCampaigns : [],
                isSelected: false,
                couponsData: ele.couponsData ? ele.couponsData : [],
                _id: ele._id,
              };
            }
          }
        });
        // console.log("sortedArray------>",sortedArray);
        let tempDisplayList = sortedArray;
        setShoppersList(tempDisplayList);

        setCount(0);
        setUnselectedCount(0);
        if(props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData &&
          // props.campaign.shoppersListData.totalCountSelectedConsumerByCampaign &&
          props.campaign.shoppersListData.totalCountSelectedConsumerByCampaign>=0){
            // console.log("checkCount---->",checkCount);
            if(showSelectedShoppers || showUnselectedShoppers)
              setCount(props.campaign.shoppersListData.totalCountSelectedConsumerByCampaign)
            else
              setCount(props.campaign.shoppersListData.totalCountSelectedConsumerByCampaign + checkCount)
            // setCount(props.campaign.shoppersListData.totalCountSelectedConsumerByCampaign)
        }
        if(props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData &&
          // props.campaign.shoppersListData.totalCountUnSelectedConsumerByCampaign &&
          props.campaign.shoppersListData.totalCountUnSelectedConsumerByCampaign>=0){
            if(showUnselectedShoppers || showSelectedShoppers)
              setUnselectedCount(props.campaign.shoppersListData.totalCountUnSelectedConsumerByCampaign)
            else
              setUnselectedCount(props.campaign.shoppersListData.totalCountUnSelectedConsumerByCampaign - checkCount)
            // setUnselectedCount(props.campaign.shoppersListData.totalCountUnSelectedConsumerByCampaign)
        }
        // if(props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.unselectedConsumerByCampaign.length===0 && props.campaign.shoppersListData.selectedConsumerByCampaign.length===10){
        //   console.log("select all")
        //   setSelectAll(true);
        //   setCountSelectAll(0);
        // }

    }, [props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.selectedConsumerByCampaign && props.campaign.shoppersListData.shoppersList, props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.selectedConsumerByCampaign]
    );

    const fetchShoppersWithFilter = async (newPage,selectCheck,unSelectCheck) => {
      // console.log("order count more----->", orderCountMoreThan);
      // console.log("order count less----->", orderCountLessThan);
      
      // console.log("inside fetchShoppersWithFilter: ", newPage)
      // console.log("inside fetchShoppersWithFilter: ", selectCheck);
      // setIsFetching(true);

      let hasError = false;

      if((orderCountMoreThan && parseInt(orderCountMoreThan)>=0) && (orderCountLessThan && parseInt(orderCountLessThan)>=0) && (parseInt(orderCountLessThan)<=parseInt(orderCountMoreThan))){
        // console.log("if condition");
        hasError = true;
        setOrderCountLessThanError(true);
      }

      // if(registrationDate){
      //   let regDate = new Date(registrationDate); let currentDate = new Date();
      //   if(regDate>currentDate){ hasError = true; setRegistrationDateError(true); }
      // }

      // if(lastActiveDate){
      //   let lastADate = new Date(lastActiveDate); let currentDate = new Date();
      //   if(lastADate>currentDate){ hasError = true; setLastActiveDateError(true); }
      // }

      // if(lastRequestDate){
      //   let lastRegDate = new Date(lastRequestDate); let currentDate = new Date();
      //   if(lastRegDate>currentDate){ hasError = true; setLastRequestDateError(true); }
      // }

      if(!hasError){
        // console.log("no error")
        dispatch(requestShoppers());
        let _id = props.selectedCampaign._id;
        let allExistingShoppers;
        let newShoppers;
        let all;
        // console.log("showSelectedShoppers inside fetch---",showSelectedShoppers)
        shopperStatus === "" ? (allExistingShoppers = "1") : (shopperStatus === "new" ? newShoppers = "new" : all = "allnew") ;
        let page = newPage !== null && newPage !== undefined && newPage !== "" ? newPage : 0;
        let filteredConsumerTagsVar = filteredConsumerTags.join(', ');
        let payload = {
          // skip: skip,
          skip: limit * page,
          limit: limit,
          // allExistingShoppers: allExistingShoppers,
          // newShoppers: newShoppers,
          // all: all,
          orderCountMoreThan: orderCountMoreThan !== "" ? parseInt(orderCountMoreThan) : "",
          orderCountLessThan: orderCountLessThan !== "" ? parseInt(orderCountLessThan) : "",
          // registrationDate: registrationDate,
          filterStartDate:filterStartDate,
          filterEndDate:filterEndDate,
          lastActiveStartDate:lastActiveStartDate,
          lastActiveEndDate:lastActiveEndDate,
          lastRequestStartDate:lastRequestStartDate,
          lastRequestEndDate:lastRequestEndDate,
          // lastActiveDate: lastActiveDate,
          // lastRequestDate: lastRequestDate,
          search: search,
          showSelectedShoppers: selectCheck && selectCheck !== null && selectCheck !== "" && selectCheck !== undefined && selectCheck === true ? "1" : (unSelectCheck && unSelectCheck !== null && unSelectCheck !== "" && unSelectCheck !== undefined && unSelectCheck === true ? "0" : ""),
          filteredConsumerTags:filteredConsumerTagsVar,
        };
        // console.log("payload before sending--->",payload)
        dispatch(getShoppersData(payload,_id));
        setOrderCountLessThanError(false);
        // setOrderCountMoreThanValueError(false);
        // setOrderCountLessThanValueError(false);
        // setIsFetching(false);
      }
    };

    const handleConfirmAssignCouponsAllShoppers = async () => {
      // console.log("====in handleConfirmAssignCouponsAllShoppers");
      dispatch(requestSuccess());
        let _id = props.selectedCampaign._id;
        let payload = {
          orderCountMoreThan: orderCountMoreThan !== "" ? parseInt(orderCountMoreThan) : "",
          orderCountLessThan: orderCountLessThan !== "" ? parseInt(orderCountLessThan) : "",
          // registrationDate: registrationDate,
          // lastActiveDate: lastActiveDate,
          // lastRequestDate: lastRequestDate,
          filterStartDate:filterStartDate,
          filterEndDate:filterEndDate,
          lastActiveStartDate:lastActiveStartDate,
          lastActiveEndDate:lastActiveEndDate,
          lastRequestStartDate:lastRequestStartDate,
          lastRequestEndDate:lastRequestEndDate,
          search: search,
          // showSelectedShoppers: selectCheck && selectCheck != null && selectCheck != "" && selectCheck != undefined && selectCheck == true ? "1" : (unSelectCheck && unSelectCheck != null && unSelectCheck != "" && unSelectCheck != undefined && unSelectCheck == true ? "0" : "")
        };
        // console.log("Payload for ALL ===> ", payload);
       let answerresponse = await dispatch(assignAllShoppersCoupons(payload,_id));
       console.log("answer---->",answerresponse);
        props.onClose();
        props.fetchCampaignWithFilter();
        dispatch(requestSuccessDone());
    };

    const resetShoppersFilter = () => {
      // console.log("shopperStatus before---->",shopperStatus);
      dispatch(requestShoppers());
      // setIsFetching(true);
      setOrderCountMoreThan("");
      setOrderCountLessThan("");
      setShopperStatus("");
      setRegistrationDate("");
      // setRanges("");
      setFilterEndDate("");
      setFilterStartDate("");  
      setLastActiveDate("");
      setlastActiveStartDate("");
      setlastActiveEndDate("");
      setlastRequestStartDate("");
      setlastActiveEndDate("");
      setLastRequestDate("");
      setSearchText("");
      setOrderCountLessThanError(false);
      setRegistrationDateError(false);
      setLastActiveDateError(false);
      setLastRequestDateError(false);
      setSkip(0);
      setLimit(10);
      setSelectAll(false);
      setSelectedShoppersList([]); 
      setFilteredConsumerTags([]);   

      let _id = props.selectedCampaign._id;
      let payload = {
        skip: 0,
        limit: 10,
        // allExistingShoppers: "1",
        orderCountMoreThan: "",
        orderCountLessThan: "",
        registrationDate: "",
        lastActiveDate: "",
        lastRequestDate: "",
        search: "",
        showSelectedShoppers: ""
      };
      dispatch(getShoppersData(payload,_id));

      // fetchShoppersWithFilter();
      // setIsFetching(false);
      // console.log("shopperStatus after---->",shopperStatus);
    };

    const handleChangePage = (event,newPage) => {
      // console.log("newPage inside handleChangePage----->",newPage);
      // var newskip;
      // setPageNumber(newPage);
      // newskip = limit * newPage;
      setSkip(newPage);
      // console.log("updated skip-----",skip)
      // console.log("select-----",showSelectedShoppers, " unselect----->",showUnselectedShoppers)
      let selected = showSelectedShoppers && showSelectedShoppers === true ? true : "";
      let unselected = showUnselectedShoppers && showUnselectedShoppers === true ? true : "";
      fetchShoppersWithFilter(newPage,selected,unselected);
      
    };

    const  handleRegistrationDateRangeChange = async (event, picker) => {     
      let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
      let sDate = picker.startDate.format();
      let eDate = picker.endDate.format();
      setFilterStartDate(sDate);
      setFilterEndDate(eDate);
      let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
      setRegistrationDate(chosenLabel) 
    };

    const  handleLastActiveDateRangeChange = async (event, picker) => {     
      let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
      let sDate = picker.startDate.format();
      let eDate = picker.endDate.format();
      setlastActiveStartDate(sDate);
      setlastActiveEndDate(eDate);
      let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
      setLastActiveDate(chosenLabel) 
    };

    const  handleLastRequestDateRangeChange = async (event, picker) => {     
      let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
      let sDate = picker.startDate.format();
      let eDate = picker.endDate.format();
      setlastRequestStartDate(sDate);
      setlastRequestEndDate(eDate);
      let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
      setLastRequestDate(chosenLabel) 
    };

    const handleFilterChange = (event) => { 
      if(event.target.name==="orderCountMoreThan") {setOrderCountMoreThan(event.target.value); 
        // setOrderCountMoreThanValueError(false)
      }
      if(event.target.name==="orderCountLessThan") {setOrderCountLessThan(event.target.value); 
        setOrderCountLessThanError(false); 
        // setOrderCountLessThanValueError(false)
      }
      if(event.target.name==="shopperType") setShopperStatus(event.target.value)
      
      // if(event.target.name==="registrationDate") {setRegistrationDate(event.target.value); setRegistrationDateError(false)}
      // if(event.target.name==="lastActiveDate") {setLastActiveDate(event.target.value); setLastActiveDateError(false)}
      // if(event.target.name==="lastRequestDate") {setLastRequestDate(event.target.value); setLastRequestDateError(false)}
      if(event.target.name==="filteredConsumerTags") setFilteredConsumerTags(event.target.value)
    };

    const onSearchTextChange = (value) => { setSearchText(value); };

    const handleConfirmAssignCoupons = async (finalList) => {
      // console.log("inside handleConfirmAssignCoupons----->", finalList);
      dispatch(requestSuccess());
      setSaveBtn(true)
      let _id = props.selectedCampaign._id;
      let consumerIds = [];
      let consumerList = finalList.filter((value) => {
        if(value.isSelected=true) consumerIds.push(value._id)
      });
      // console.log(consumerIds);
      let payload = {
        consumerIds : consumerIds
      }
      // console.log("payload before sending---->",payload)
      let answerresponse = await dispatch(assignCouponsToShoppers(payload,_id));
      console.log("answer---->",answerresponse);
      props.onClose();
      props.fetchCampaignWithFilter();
      dispatch(requestSuccessDone());
    };

    const handleClick =  (value, index, e, objId) => {
      // console.log("value: " , value , " index: " , index, " id: " , objId);
      let notToBeSent = [];
      if (e.target.checked === true) {
        // console.log("if")
        if(value.offerCampaigns.length>0){
          let match =  value.offerCampaigns.filter(item => item.offerCode.indexOf(props.selectedCampaign.offerCode) !== -1);
          if(match && match.length>0){
            // console.log("if condition")
            notToBeSent.push(value);
            value.isSelected = true;
            setCount(count+1);
            setUnselectedCount(unselectedCount-1);
            setCheckCount(checkCount+1);
          }
          else{
              // console.log("else if condition");
              value.isSelected = true;
              selectedShoppersList.push(value);
              const uniqueObjectsNew = [
                ...new Map(selectedShoppersList.map((item) => [item.consumerId, item])).values(),
              ];
              setSelectedShoppersList(uniqueObjectsNew);
              setCount(count+1);
              setCheckCount(checkCount+1);
              setUnselectedCount(unselectedCount-1);
          }
        }else{
          value.isSelected = true;
          selectedShoppersList.push(value);
          const uniqueObjects = [
            ...new Map(selectedShoppersList.map((item) => [item.consumerId, item])).values(),
          ];
          setSelectedShoppersList(uniqueObjects);
          setCount(count+1);
          setCheckCount(checkCount+1);
          setUnselectedCount(unselectedCount-1);
        }
      } 
      else {
        // console.log("else")
        const newSelectedShoppersList = selectedShoppersList.filter(id => id._id !== objId,value.isSelected=false)
        // const newSelectedShoppersList = selectedShoppersList.splice(selectedShoppersList.filter(a=>a._id!==objId,value.isSelected=false),1);
        setSelectedShoppersList(newSelectedShoppersList);
        setUnselectedCount(unselectedCount+1);
        setCount(count-1);
        setCheckCount(checkCount-1);
      }
    };

    // Commented below code as the funtion is not called : as it is also commented out
    /*
    const handleSelectAllClick = (event) => {
      // console.log(" inside handleSelectAllClick function----");
      // console.log(" selectAll flag---->",selectAll)
      // console.log("count---->",countSelectAll);

      // if( countSelectAll == 0 && selectAll == true)
      //   countSelectAll = 1;

      let tempArrayFirstTime = [];
      let tempArraySecondTimeOn = [];
      let notToBeSent = [];
      setSelectAll(event.target.checked);

      if(countSelectAll == 0 || countSelectAll == 1) {
        tempArrayFirstTime = shoppersList && shoppersList.length>0 && shoppersList.map((singleList) => {
          if(event.target.checked === true) {
            // console.log("if condition")
            if(singleList.offerCampaigns.length>0){
              let match =  singleList.offerCampaigns.filter(item => item.offerCode.indexOf(props.selectedCampaign.offerCode) !== -1);
              // console.log("match---->",match);
              if(match && match.length>0){
                // console.log("if condition")
                notToBeSent.push(singleList);
                singleList.isSelected = true;
              }
              else{
                // console.log("else if condition");
                singleList.isSelected = true;
                selectedShoppersList.push(singleList);
                const uniqueObjectsNew = [
                  ...new Map(selectedShoppersList.map((item) => [item.consumerId, item])).values(),
                ];
                setSelectedShoppersList(uniqueObjectsNew)
              }
            }else{
              singleList.isSelected = true;
              selectedShoppersList.push(singleList);
              const uniqueObjects = [
                ...new Map(selectedShoppersList.map((item) => [item.consumerId, item])).values(),
              ];
              setSelectedShoppersList(uniqueObjects);
            }
            setCount(count+(props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.unselectedConsumerByCampaign && props.campaign.shoppersListData.unselectedConsumerByCampaign.length));
            setUnselectedCount(unselectedCount-(props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.unselectedConsumerByCampaign && props.campaign.shoppersListData.unselectedConsumerByCampaign.length));
            return singleList;
          }else{
            // console.log("inside if's else condition------");
            singleList.isSelected = event.target.checked;
            setSelectedShoppersList([]);
            // old code
            setUnselectedCount(unselectedCount+10);
            setCount(count-10);

            // new code
            // setUnselectedCount(unselectedCount+(props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.totalCountUnSelectedConsumerByCampaign));
            // setCount(count-(props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.totalCountUnSelectedConsumerByCampaign));
            return singleList;
          }
        });
      }
      else if(countSelectAll>1){
        // console.log("else part");
        // console.log("total selected count--->",count);
        // console.log("total unselected count--->",unselectedCount);
        tempArraySecondTimeOn = shoppersList && shoppersList.length>0 && shoppersList.map((singleList) => {
          if(event.target.checked) {
            // console.log("inside if condition------");
            if(singleList.offerCampaigns.length>0){
              let match =  singleList.offerCampaigns.filter(item => item.offerCode.indexOf(props.selectedCampaign.offerCode) !== -1);
              // console.log("match---->",match);
              if(match && match.length>0){
                // console.log("if condition")
                notToBeSent.push(singleList);
                singleList.isSelected = true;
              }
              else{
                // console.log("else if condition");
                singleList.isSelected = true;
                  selectedShoppersList.push(singleList);
                  const uniqueObjectsNew = [
                    ...new Map(selectedShoppersList.map((item) => [item.consumerId, item])).values(),
                  ];
                  setSelectedShoppersList(uniqueObjectsNew)
              }
            }else{
              singleList.isSelected = true;
              selectedShoppersList.push(singleList);
              const uniqueObjects = [
                ...new Map(selectedShoppersList.map((item) => [item.consumerId, item])).values(),
              ];
              setSelectedShoppersList(uniqueObjects);
            }
            setCount(count+10);
            setUnselectedCount(unselectedCount-10);
            return singleList;
          }else{
            // console.log("inside else condition------");
            singleList.isSelected = event.target.checked;
            setSelectedShoppersList([]);
            setUnselectedCount(unselectedCount+10);
            setCount(count-10);
            return singleList;
          }
        });
      }
      console.log("not to be sent--->",notToBeSent);
    };
    */

    const closeModal = () => {
      props.onClose();
    };

    return(
        <div className={classes.modal}>
            <Grid container className={classes.header} spacing={3}>
              <Grid item xs={8}>
                <WhiteTextTypography variant="h6" color="inherit" gutterBottom> Assign Shopper </WhiteTextTypography>
              </Grid>
              <Grid item xs={4}>
                <WhiteTextTypography variant="h6" color="inherit" gutterBottom>
                  <CloseIcon
                    className={classes.headGridTwo}
                    // onClick={() => { props.onClose(); resetShoppersFilter(); }}
                    onClick={() => { closeModal(); resetShoppersFilter(); }}
                  />
                </WhiteTextTypography>
              </Grid>
            </Grid>

            <Container className={classes.content}>
              <Grid container className={classes.grid} spacing={1} justify="left" alignItems="left">
                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    {props.selectedCampaign.campaignName + " ( " + props.selectedCampaign.campaignId + " ) "}
                  </Typography>
                </Grid>

             

                {/* Filter section - collapsable */}
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <Typography className={classes.heading}>Filters</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={1}>
                      <Grid item xs={12}>
                    <TextField
                      placeholder="Search By Shopper / Shopper ID / Mobile / Society / Street / Pin Code / City / Email ID"
                      fullWidth margin="dense" name="shopperData" variant="outlined"
                      value={search}
                      onChange={(e) => { onSearchTextChange(e.target.value) }}
                    />
                  </Grid>
                        
                        <Grid item xs={6} >
                          <Typography variant="subtitle2" gutterBottom> Order Count more than </Typography>
                          <TextField
                            placeholder="Enter Order Count" fullWidth variant="outlined" margin="dense" name="orderCountMoreThan"
                            value={orderCountMoreThan ? orderCountMoreThan.trim().replace(/\D/g, "") : ""}
                            onChange={handleFilterChange}
                            // type="number"
                            // error={orderCountMoreThanValueError ? true : false}
                            // helperText={orderCountMoreThanValueError ? "Should be a number" : ""}
                          />
                        </Grid>
                        <Grid item xs={6} >
                          <Typography variant="subtitle2" gutterBottom> Order Count less than </Typography>
                          <TextField
                            placeholder="Enter Order Count" fullWidth margin="dense" name="orderCountLessThan" variant="outlined"
                            value={orderCountLessThan ? orderCountLessThan.trim().replace(/\D/g, "") : ""}
                            onChange={handleFilterChange}
                            // error={orderCountLessThanError ? true : (orderCountLessThanValueError ? true : false)}
                            // helperText={orderCountLessThanError ? "Should be greater than \"Order Count more than\"" : (orderCountLessThanValueError ? "Should be a number" : "")}
                            error={orderCountLessThanError ? true : false}
                            helperText={orderCountLessThanError ? "Should be greater than \"Order Count more than\"" : ""}
                            // type="number"
                          />
                        </Grid> 
                         {/* <Grid item xs={12}>
                          <RadioGroup row aria-label="position" name="shopperType" 
                          onChange={handleFilterChange} value={shopperStatus} >
                            <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>All existing shoppers</Typography>} size="small" />
                            <FormControlLabel value="allnew" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>All existing & new shoppers</Typography>} size="small" />
                            <FormControlLabel value="new" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Only new shoppers</Typography>} size="small" />
                          </RadioGroup>
                        </Grid>  */}
                         <Grid item xs={4}>
                          <Typography variant="subtitle2" gutterBottom> Shopper Registration Date </Typography>
                          {/* <TextField
                            fullWidth margin="dense" name="registrationDate" type="date" variant="outlined" InputLabelProps={{shrink: true}}
                            onChange={handleFilterChange}
                            value={registrationDate}
                            style={{ width: "100%" }}
                            error={registrationDateError ? true : false}
                            helperText={registrationDateError ? "Should be less than or equal to current date" : ""}
                          /> */}
                           <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={ranges}
                                        onApply={handleRegistrationDateRangeChange}           
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth 
                                        margin="dense" name="registrationDate" 
                                        variant="outlined" 
                                        // label="Shopper Registration Date"
                                        placeholder="Enter Shopper Registration Date"
                                        InputLabelProps={{shrink: true}}
                                        value={registrationDate}
                                         error={registrationDateError ? true : false}
                                         helperText={registrationDateError ? "Should be less than or equal to current date" : ""} 
                                         style={{ width: "255px"  }}   />                                     
                                    </DateRangePicker>
                                </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="subtitle2" gutterBottom> Last Active Date </Typography>
                          {/* <TextField
                            fullWidth margin="dense" name="lastActiveDate" type="date" variant="outlined" InputLabelProps={{shrink: true}}
                            onChange={handleFilterChange}
                            value={lastActiveDate}
                            style={{ width: "100%" }}
                            error={lastActiveDateError ? true : false}
                            helperText={lastActiveDateError ? "Should be less than or equal to current date" : ""}
                          /> */}
                          <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={ranges}
                                        onApply={handleLastActiveDateRangeChange}           
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="lastActiveDate" 
                                        variant="outlined" 
                                        // label="Last Active Date"
                                        placeholder="Enter Last Active Date"
                                        InputLabelProps={{shrink: true}}
                                        value={lastActiveDate}
                                         error={lastActiveDateError ? true : false}
                                         helperText={lastActiveDateError ? "Should be less than or equal to current date" : ""} 
                                         style={{ width: "255px"  }}  />                                     
                                    </DateRangePicker>
                                </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="subtitle2" gutterBottom> Last Request Date </Typography>
                          {/* <TextField
                            fullWidth margin="dense" name="lastRequestDate" type="date" variant="outlined" InputLabelProps={{shrink: true}}
                            onChange={handleFilterChange}
                            value={lastRequestDate}
                            style={{ width: "100%" }}
                            error={lastRequestDateError ? true : false}
                            helperText={lastRequestDateError ? "Should be less than or equal to current date" : ""}
                          /> */}
                          <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={ranges}
                                        onApply={handleLastRequestDateRangeChange}           
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="lastRequestDate"
                                        // label="Last Request Date"
                                        placeholder="Enter Last Request Date" 
                                        variant="outlined" 
                                        InputLabelProps={{shrink: true}}
                                        value={lastRequestDate}
                                         error={lastRequestDateError ? true : false}
                                         helperText={lastRequestDateError ? "Should be less than or equal to current date" : ""} 
                                         style={{ width: "255px"  }}  />                                     
                                    </DateRangePicker>
                                </Grid>
                        </Grid>
                        <Grid item xs={4}>
                                <Typography variant="subtitle2" gutterBottom> Consumer Tags</Typography>
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Consumer Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={filteredConsumerTags}
                                            name="filteredConsumerTags"
                                            onChange={handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {consumerTags.map((eachConsumerTagObj) => (
                                                <MenuItem key={eachConsumerTagObj._id} value={eachConsumerTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={filteredConsumerTags.indexOf(eachConsumerTagObj.name) > -1} />
                                                    <ListItemText primary={eachConsumerTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> 
                        <Grid item xs={8}></Grid>
                        <Grid item xs={2}>
                          <Button type="submit" fullWidth variant="contained" margin="normal"
                            onClick={() => {
                              resetShoppersFilter();
                            
                            }}
                          >
                            Reset
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Button type="submit" fullWidth variant="contained" margin="normal" color="primary"
                            onClick={() => fetchShoppersWithFilter()}
                          >
                            Search
                          </Button>
                        </Grid> 
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
                
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                  <Grid container spacing={2} xs={12}>
                    <Grid item xs={7} style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="subtitle1" gutterBottom>{`Selected Shoppers Count (${count})`}</Typography>
                    </Grid>
                    {showUnselectedShoppers ? null : (
                      <>
                        <Grid item xs={1}>
                          <Checkbox
                            edge="start"
                            checked={showSelectedShoppers}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": "selectAll" }}
                            onClick={(e) => { setShowSelectedShoppers(e.target.checked); fetchShoppersWithFilter("", e.target.checked)}}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="subtitle2" gutterBottom>{`Show Only Selected Shoppers`}</Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid container spacing={2} xs={12}>
                    <Grid item xs={7} style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="subtitle1" gutterBottom>{`Unselected Shoppers Count (${unselectedCount})`}</Typography>
                    </Grid>
                    {showSelectedShoppers ? null : (
                      <>
                        <Grid item xs={1}>
                          <Checkbox
                            edge="start"
                            checked={showUnselectedShoppers}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": "selectAll" }}
                            onClick={(e) => {setShowUnselectedShoppers(e.target.checked)
                            fetchShoppersWithFilter("","",e.target.checked)
                          }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="subtitle2" gutterBottom>{`Show Only Unselected Shoppers`}</Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom>  </Typography>
                </Grid>
                <Grid item xs={12}>
                <Paper style={{ margin: "10px -5px", padding: "10px" }}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" display="block" gutterBottom style={{marginTop:"6px"}}>
                      Apply Offer code coupons to all ({props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.totalCount}) Shoppers?
                      <Checkbox
                        style={{marginTop:"0px"}}
                        onClick={(e) => { setAssignCouponAllFlag(true) }}
                        checked={assignCouponAllFlag}
                      />
                    </Typography>
                  </Grid>
                </Paper>
                </Grid>
                
                <Grid item xs={12}>
                    <TableContainer>
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                      >
                        {/* <TableHead>
                          <TableRow>
                            <TableCell>
                              <Checkbox
                                hidden={props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.unselectedConsumerByCampaign.length==0}
                                checked={selectAll}
                                onClick={(e)=>{handleSelectAllClick(e); setCountSelectAll(countSelectAll+1)}}
                                inputProps={{ 'aria-labelledby': 'select all shoppers' }} style={{paddingLeft:"0px"}}
                              /> 
                            </TableCell>
                            <TableCell>{props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.unselectedConsumerByCampaign.length==0 ? null : "Select All"}</TableCell>
                            {/* <TableCell>Select All</TableCell> */}
                           {/* <TableCell></TableCell>
                          </TableRow>
                        </TableHead> */}
                        <TableBody>
                          {
                            shoppersList && shoppersList.length > 0 && shoppersList.map((value, index) => {
                              const labelId = `checkbox-list-label-${value}`;
                              // console.log(value)
                              return (
                                  <TableRow
                                    hover
                                    className={classes.tableRow}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                  >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                          onClick={(event) => handleClick(value, index, event, value._id)}
                                          checked={value.isSelected}
                                          aria-checked={value.isSelected}
                                          selected={value.isSelected}
                                          inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </TableCell> 
                                      <TableCell align="left">
                                        ({value.consumerId}) {value.firstName + " " + value.lastName} {<br></br>} Coupon active: {value.couponsData.length}
                                      </TableCell>
                                      <TableCell align="right">
                                        Order Count: {value.ordersAtXirify} {<br></br>} Registration Date: {value.agreementAcceptedDate ? moment(value.agreementAcceptedDate).format('DD-MMM-YYYY') : "--"}
                                      </TableCell>
                                  </TableRow>
                                );
                              })
                            }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      component="div"
                      count={props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.totalCount}
                      rowsPerPage={limit}
                      page={skip}
                      onChangePage={handleChangePage}
                      style={{ overflow: 'unset' }}
                    />
                </Grid>
              </Grid>
            </Container>

            <Grid container className={classes.headerWhite} spacing={3}>
              <Grid container spacing={2} xs={12}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <Button
                    type="submit" fullWidth variant="contained" margin="normal"
                    onClick={() => {
                      // resetShoppersFilter();
                      // props.onClose();
                      closeModal();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    type="submit" fullWidth variant="contained" margin="normal" color="primary"
                    disabled={selectedShoppersList && selectedShoppersList.length===0 ? true : false}
                    onClick={(e) => { setAssignCouponFlag(true) }}
                  >
                    Edit/Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Dialog open={assignCouponFlag} onClose={() => setAssignCouponFlag(false)} >
              <DialogTitle>Assign Coupons to Shoppers</DialogTitle>
              <DialogContent> <DialogContentText> Do you want to assign coupons to the selected shoppers? </DialogContentText> </DialogContent>
              <DialogActions>
                <Button onClick={() => setAssignCouponFlag(false)} color="primary"> No </Button>
                <Button disabled={saveBtn} onClick={() => handleConfirmAssignCoupons(selectedShoppersList)} color="primary" autoFocus> Yes </Button>
              </DialogActions>
            </Dialog> 

            <Dialog open={assignCouponAllFlag} onClose={() => setAssignCouponAllFlag(false)} >
              <DialogTitle>Assign Coupons to All Shoppers</DialogTitle>
              <DialogContent> <DialogContentText> Do you want to assign coupons to all ({props.campaign && props.campaign.shoppersListData && props.campaign.shoppersListData.totalCount}) Shoppers? </DialogContentText> </DialogContent>
              <DialogActions>
                <Button onClick={() => setAssignCouponAllFlag(false)} color="primary"> No </Button>
                <Button 
                onClick={() => handleConfirmAssignCouponsAllShoppers()} 
                color="primary" autoFocus> Yes </Button>
              </DialogActions>
            </Dialog> 

            <Backdrop className={classes.backdrop} open={isFetching}> <CircularProgress color="inherit" /> </Backdrop>

        </div>
    )
}

const mapStateToProps = state => {
	return {
		campaign: state.campaign,
    user: state.auth.user,
	}
};

export default connect(mapStateToProps, null)(withStyles(styles)(AssignShopperModal));
// export default withStyles(styles)(AssignShopperModal);