import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TabPanel from "../../utils/TabPanel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ManageService from "./ManageService";
import ServiceDetailView from './ServiceDetailView';
import SubHeadDetailView from './SubHeadDetailView';
import HeadDetailView from './HeadDetailView';
import ShowService from './ShowService';
import ManageBusinessSequence from "./ManageBusinessSequence";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    getHeadByBusinessId,
    getHeadSubheadHierarchy,
    deleteHeadSubheadService,
    getServicesByBusinessId,
    uploadServices,
    updateService,
    disableMultipleServices,
    clearMsg,
    deleteSubcategoryButton,
    getServicesMenuExportToExcel,
    requestBusinessDetailsRecords,
    requestBusinessDetailsRecordsFalse,
    getBusinessById

} from '../../../actions/businessActions';
import DiscountTable from './DiscountTable';
import MaxDiscountTable from './MaxDiscountTable'
import CartLevelDiscount from './CartLevelDiscount';

const styles = (theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
        width: "100%",
        //paddingTop: theme.spacing(3),
        //paddingBottom: theme.spacing(4),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    headers: {
        fontSize:'10px'
    }
});

class DiscountSection extends Component {
    constructor() {
        super();
        this.state = {
            value: 0,
            serviceModalOpen: false,
            headModalOpen: false,
            subHeadModalOpen: false,
            edit: false,
            headsubHeadData: {},
            editService: false,
            serviceData: {},
            showService: false,
            selectedService: {},
            order: - 1,
            orderBy: "service",
            page: 0,
            rowsPerPage: 5,
            pageOfHeadSubHead: 0,
            rowsPerPageOfHeadSubHead: 5,
            snackOpen: false,
            sucessMsg: null,
            errorMsg: null,
            parentHead: {},
            serviceFilter: {},
            headSubheadFilter: {},
            numSelected: "",
            selected: [],
            modalDisableDiscountOpen: false,
            showDownloadIcon:false,
            isDiscountDisabledFilter:"",

        };
    }

    componentDidMount() {
        let payload = { businessId:this.props.businessDetails._id}
        this.props.requestBusinessDetailsRecords();        
        this.getHeadSubheads();   
        this.fetchServices();
        this.props.getBusinessById(payload)
        this.props.requestBusinessDetailsRecordsFalse();
    }


    componentDidUpdate(prevProps, prevState) {
        if ((prevState.sucessMsg === null && this.state.sucessMsg) ||
            (prevState.errorMsg === null && this.state.errorMsg))  {
            this.setState({ snackOpen: true });
        }
        if (prevState.sucessMsg !== this.state.sucessMsg) {
            this.props.requestBusinessDetailsRecords();
            this.fetchServices();
        let payload = { businessId:this.props.businessDetails._id}
         this.props.getBusinessById(payload)
            this.props.requestBusinessDetailsRecordsFalse();
        }
    }


    handleSnackClose = () => {
        this.props.clearMsg();
        this.setState({
            snackOpen: false,
            modalDisableDiscountOpen: false,
            errorMsg: null,
            sucessMsg: null
        });
        this.fetchServices();
    }



    handleChangeTab = (event, newValue) => {
        this.setState({ value: newValue });
    };
    openServiceModal = () => {
        this.setState({ serviceModalOpen: true });
    }
    closeServiceModal = () => {
        this.setState({ editService: false, serviceModalOpen: false });
    }
    openHeadModal = () => {
        this.setState({ headModalOpen: true });
    }
    closeHeadModal = () => {
        this.setState({ headModalOpen: false, edit: false });
    }
    openSubheadModal = () => {
        this.props.getHeadByBusinessId(this.props.businessDetails._id)
        this.setState({ subHeadModalOpen: true });
    }
    closeSubheadModal = () => {
        this.setState({ subHeadModalOpen: false, edit: false });
    }
    openShowService = () => {
        this.setState({ showService: true });
    }
    closeShowService = () => {
        this.setState({ showService: false });
    }
    getHeadSubheads = async () => {
        let data = {
            id: this.props.businessDetails._id,
            data: {
                head: this.state.headSubheadFilter && this.state.headSubheadFilter.head ? this.state.headSubheadFilter.head : null,
                subhead: this.state.headSubheadFilter && this.state.headSubheadFilter.subhead ? this.state.headSubheadFilter.subhead : null,
                rowsPerPage: this.state.rowsPerPageOfHeadSubHead,
                skip: this.state.pageOfHeadSubHead * this.state.rowsPerPageOfHeadSubHead
            }
        }
        await this.props.getHeadSubheadHierarchy(data);
    }

    handleDiscountDisableOpen = () => {
        this.setState({
            modalDisableDiscountOpen: true,
        });
    }

    disableDiscountDisableClose = () => {
        this.setState({ modalDisableDiscountOpen: false });
    }

    disableMultiServicesDiscount = async () => {

        let payload = {
            businessId: this.props.businessDetails._id,
            data: {
                serviceIds: this.state.selected
            }
        }
        await this.props.disableMultipleServices(payload)
        this.setState({ selected: [] })
        this.fetchServices();
        this.disableDiscountDisableClose();
    }

    deleteheadSubhead = async (head) => {
        let payload = {
            businessId: head.businessId,
            type: head.type,
            id: head._id
        };
        await this.props.deleteHeadSubheadService(payload);
        await this.getHeadSubheads();
        this.fetchServices();
    }
    editheadSubhead = (head) => {
        var parentsName;
        let data = {};
        this.setState({
            edit: true,
            headsubHeadData: head
        })
        if (head.type === "SubHead") {
            if (this.props.business.Heads) {
                this.props.business.Heads.map((parent) => {
                    if (parent._id.toString() === head.parentId.toString()) {
                        parentsName = parent.name
                    }
                })
            }

            let parentHead = this.props.business && this.props.business.Heads &&
                this.props.business.Heads.find(tempHead => tempHead._id.toString() === head.parentId.toString());
            if (parentsName) {
                data = head
                data.parentsName = parentsName;
            }
            this.setState({
                headsubHeadData: data,
                parentHead: parentHead
            })
            this.openSubheadModal()
        }
        else if (head.type === "Head") {
            this.openHeadModal()
        }
    }
    editService = (service) => {
        this.setState({
            editService: true,
            serviceData: service
        })

        this.openServiceModal()
    }
    handleListItemClick = (data) => {
        this.openShowService();
        this.setState({ selectedService: data })
    }

    setOrder = (order) => {
        this.setState({ order }, () => this.fetchServices());
    };

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetchServices());
    };

    setPage = (page) => {
        this.setState({ page }, () => this.fetchServices());
    };

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    };
    setPageOfHeadSubHead = (page) => {
        this.setState({ pageOfHeadSubHead: page }, () => this.getHeadSubheads());
    };
    setRowsPerPageOfHeadSubHead = (rowsPerPage) => {
        this.setState({ rowsPerPageOfHeadSubHead: rowsPerPage });
    };


    bulkUpload = (file) => {
        let payload = {
            businessId: this.props.businessDetails._id,
            file: file
        }
        this.props.uploadServices(payload);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.business.bulkUploadSuccess) {
            this.setState({ sucessMsg: this.props.business.bulkUploadSuccess })
            this.setState({ snackOpen: true });
            this.props.clearMsg();
            this.getHeadSubheads()
            this.fetchServices()
        } else if (this.props.business.bulkUploadError) {
            this.setState({ errorMsg: this.props.business.bulkUploadError });
            this.setState({ snackOpen: true });
            this.props.clearMsg();
        }else if (this.props.business.deleteSubcategoryMsg){
            this.setState({ sucessMsg: this.props.business.deleteSubcategoryMsg });
            this.setState({ snackOpen: true });
            this.props.clearMsg();
        }
    }

    handleSwitchChange = async (event, service) => {
        let updatePayload = {};
        if (event.target.name === 'availability') {
            let availabilityData = service.availability;
            availabilityData.isActive = event.target.checked;
            updatePayload.availability = availabilityData;
        } else {
            updatePayload[event.target.name] = event.target.checked;
        }

        let payload = {
            serviceId: service._id,
            data: updatePayload
        }
        await this.props.updateService(payload);
        this.fetchServices();
    }

    fetchServices = (data) => {
        let payload = {
            id: this.props.businessDetails._id,
            data: {
                orderBy: this.state.orderBy,
                order: this.state.order,
                rowsPerPage: this.state.rowsPerPage,
                skip: this.state.page * this.state.rowsPerPage,
                service: this.state.serviceFilter && this.state.serviceFilter.service ? this.state.serviceFilter.service : null,
                head: this.state.serviceFilter && this.state.serviceFilter.head ? this.state.serviceFilter.head : null,
                subhead: this.state.serviceFilter && this.state.serviceFilter.subhead ? this.state.serviceFilter.subhead : null,
                availability: this.state.serviceFilter && this.state.serviceFilter.availability,
                isEnabled: this.state.serviceFilter && this.state.serviceFilter.isEnabled,
                isOnlyDiscountedItem: true,
                isDiscountEnabled: this.state.serviceFilter && this.state.serviceFilter.isDiscountEnabled,
            }
        }
        this.props.getServicesByBusinessId(payload);

    }
    clearFilter = async () => {
        await this.setState({
            serviceFilter: {},
            headSubheadFilter: {},
            isDiscountDisabledFilter:false
        })
    }
    filterServices = async (data) => {
        await this.setState({ serviceFilter: data, page: 0 })
        this.fetchServices()
        if(data && data.isDiscountEnabled===false){
            await this.setState( {isDiscountDisabledFilter:true})
        } else {
            await this.setState( {isDiscountDisabledFilter:false})
        }
    }
    setHeadSubheadFilter = async (data) => {
        await this.setState({ headSubheadFilter: data })
        this.getHeadSubheads()
    }
    setSelected = (selected) => {
        this.setState({ selected })
    }
    removeSubcategory = async () => {
        let payload = {
            businessId: this.props.businessDetails._id
        };
        await this.props.deleteSubcategoryButton(payload);
    }

    render() {
        const { classes } = this.props;
        let data = (this.props && this.props.business && this.props.business.servicesMenuExcelRecords) ? this.props.business.servicesMenuExcelRecords : [];
        let muiAlert = null;
        if (this.state.sucessMsg) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
                {this.state.sucessMsg}
            </MuiAlert>
        } else if (this.state.errorMsg) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
                {this.state.errorMsg}
            </MuiAlert>
        }
        return (
            <div>
                <Grid container direction="row" style={{ padding: "0px" }}>
                    <Grid 
                    // item xs={12} sm={6}
                    >
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            // centered
                        >
                            <Tab label={this.props.user.role === "areaManager" ? "Service Level Discount" : "Service Level Discount"} />
                            <Tab label={this.props.user.role === "areaManager" ? "Cart Level Discount" : "Cart Level Discount"} />
                            <Tab label={this.props.user.role === "areaManager" ? "Max Discount Items" : "Max Discount Items"} />
                            
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                    {(this.state.value===0)? <Grid item xs={12}>
                        <Button 
                        style={{ float: "right", margin: "5px" }}
                        variant="contained"
                        color="primary"
                        onClick={this.handleDiscountDisableOpen}
                        disabled={this.state.selected.length < 1}
                        >
                            DISABLE DISCOUNT
                        </Button>     
                    </Grid>:null}
                    <TabPanel value={this.state.value} index={0} classes={{ root: classes.tab }}>
                        <ManageService
                            // data={this.props.business.headSubheadHeirarchy.data}
                            data={this.props.business.headSubheadHeirarchy.headSubheadList}
                            getServices={this.filterServices}
                            clearFilter={this.clearFilter}
                            isDiscountSection={true}
                        />
                        {this.props.business.allServices.data ?
                            <DiscountTable
                                rowData={this.props.business.allServices.data}
                                totalCount={this.props.business.allServices.totalCount}
                                order={this.state.order === 1 ? "asc" : "desc"}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                handleEditOpen={this.editService}
                                handleListItemClick={this.handleListItemClick}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                history={this.props.history}
                                role={this.props.user.role}
                                // handleIsEnabledSwitchChange={this.handleIsEnabledSwitchChange}
                                handleSwitchChange={this.handleSwitchChange}
                                selected={this.state.selected}
                                setSelected={this.setSelected}
                                numSelected={this.state.numSelected}
                                isDiscountDisabledFilter={this.state.isDiscountDisabledFilter}
                            />
                            : null}
                    </TabPanel>
                    <TabPanel
                        style={{ width: "100%" }}
                        value={this.state.value}
                        index={1}
                        classes={{ root: classes.tab }}
                    >
                        <CartLevelDiscount businessData={this.props.businessDetails}/>
                    </TabPanel>
                    {(this.state.value===0)? <Grid item xs={12}>
                        <Button 
                        style={{ float: "right", margin: "5px" }}
                        variant="contained"
                        color="primary"
                        onClick={this.handleDiscountDisableOpen}
                        disabled={this.state.selected.length < 1}
                        >
                            DISABLE DISCOUNT
                        </Button>     
                    </Grid>:null}
                    <TabPanel value={this.state.value} index={2} classes={{ root: classes.tab }}>
                   
                        {this.props.businessDetails.finalDiscountServiceArray ?
                            <MaxDiscountTable
                                rowData={this.props.businessDetails.finalDiscountServiceArray}  
                                totalCount={this.props.businessDetails.finalDiscountServiceArray.length}
                                order={this.state.order === 1 ? "asc" : "desc"}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                handleEditOpen={this.editService}
                                handleListItemClick={this.handleListItemClick}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                history={this.props.history}
                                role={this.props.user.role}
                                // handleIsEnabledSwitchChange={this.handleIsEnabledSwitchChange}
                                handleSwitchChange={this.handleSwitchChange}
                                selected={this.state.selected}
                                setSelected={this.setSelected}
                                numSelected={this.state.numSelected}
                                isDiscountDisabledFilter={this.state.isDiscountDisabledFilter}
                            />
                            : null}
                    </TabPanel>
                </Grid>
                <ServiceDetailView
                    open={this.state.serviceModalOpen}
                    onClose={this.closeServiceModal}
                    edit={this.state.editService}
                    data={this.state.serviceData}
                    businessDetails={this.props.businessDetails}
                    getHeadSubheads={this.getHeadSubheads}
                    getServices={this.fetchServices}
                />
                <HeadDetailView
                    open={this.state.headModalOpen}
                    onClose={this.closeHeadModal}
                    businessDetails={this.props.businessDetails}
                    edit={this.state.edit}
                    data={this.state.headsubHeadData}
                    headsLength={this.props.business.headSubheadHeirarchy.data && this.props.business.headSubheadHeirarchy.data.length > 0 ? this.props.business.headSubheadHeirarchy.data.length : 0}
                    getHeadSubheads={this.getHeadSubheads}
                />
                <SubHeadDetailView
                    open={this.state.subHeadModalOpen}
                    onClose={this.closeSubheadModal}
                    businessDetails={this.props.businessDetails}
                    edit={this.state.edit}
                    data={this.state.headsubHeadData}
                    parentHead={this.state.parentHead}
                    getHeadSubheads={this.getHeadSubheads}
                />
                <ShowService
                    open={this.state.showService}
                    onClose={this.closeShowService}
                    data={this.state.selectedService}
                />

                <Dialog
                    open={this.state.modalDisableDiscountOpen}
                    onClose={this.disableDiscountDisableClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Delete Services</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to disable discount for selected services?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.disableDiscountDisableClose} color="primary">
                            No
                        </Button>
                        <Button onClick={() => this.disableMultiServicesDiscount()} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.snackOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}>
                    {muiAlert}
                </Snackbar>

                <Backdrop className={classes.backdrop} open={this.props.business.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dashboard: state.dashboard,
        business: state.business,
        user: state.auth.user,
        admin: state.admin,
        categories: state.categories,
        env: state.auth.env
    };
};
export default connect(mapStateToProps, {
    getHeadByBusinessId,
    getHeadSubheadHierarchy,
    deleteHeadSubheadService,
    getServicesByBusinessId,
    uploadServices,
    updateService,
    disableMultipleServices,
    clearMsg,
    deleteSubcategoryButton,
    getServicesMenuExportToExcel,
    requestBusinessDetailsRecords,
    requestBusinessDetailsRecordsFalse,
    getBusinessById
})(withStyles(styles)(DiscountSection));
